import CachedIcon from '@mui/icons-material/Cached';
import { Grid, useTheme } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { OPIconButton, OPSemiBoldTypograpghy } from '../styled';
import { AccountSummarySkeleton } from './components/account-summary-skeleton';
import { OrderSummaryTypography } from './components/ts-typography';
import { getAccountBalances } from './services';
import {
  getAccountBalancesState,
  tsAccountBalances,
  tsEnvironmentState,
  tsSelectedAccountBalanceState,
  tsSelectedAccountIdsState,
} from './states/trade-station-states';
import { TsOOPS } from './ts-oops';

export const TSAccountSummaryWidget = () => {
  const theme = useTheme();
  const [isFetching, setIsFetching] = useState(false);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setAccountBalances = useSetRecoilState(tsAccountBalances);
  const accountBalances = useRecoilValue(tsSelectedAccountBalanceState);
  const getAccountBalance = useRecoilValue(getAccountBalancesState);
  const resetAccountBalance = useResetRecoilState(tsAccountBalances);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0 || isFetching) {
      return;
    }
    setIsFetching(true);
    (async () => {
      const response = await getAccountBalances(tsEnv, selectedAccountIds);
      if (response.hasErrors) {
        return;
      }
      setAccountBalances(response.data.balances);
      setIsFetching(false);
    })();
  }, [selectedAccountIds, accountBalances]);

  if (!selectedAccountIds || selectedAccountIds.length === 0) {
    return <TsOOPS name="Account" />;
  }
  if (!getAccountBalance || isFetching) {
    return <AccountSummarySkeleton />;
  }

  const renderSummaryTable = () => {
    return (
      <Grid container xs={12} sm={12} md={10} lg={10} xl={8}>
        <Grid
          sx={{
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: 2,
            paddingY: 1,
            ':hover': {
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
            },
            backgroundColor: theme.palette.header.default,
            boxShadow: 2,
          }}>
          <Grid item container xs={12} rowSpacing={2}>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Account Value"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.accountValue)}`}
                sx={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Market Value"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.marketValue)}`}
                sx={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Cash Balance"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.cashBalance)}`}
                sx={{ color: theme.palette.primary.main }}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Realized P/L"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.realizedPL)}`}
                sx={{
                  color:
                    Number(getAccountBalance.realizedPL) >= 0 ? theme.palette.success.main : theme.palette.error.main,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Unrealized P/L"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.unRealizedPL)}`}
                sx={{
                  color:
                    Number(getAccountBalance.unRealizedPL) >= 0 ? theme.palette.success.main : theme.palette.error.main,
                }}
              />
            </Grid>

            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Commissions"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.commission)}`}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Cost of Positions"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.costOfPosition)}`}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Overnight BP"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.overNightBuyingPower)}`}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Buying Power"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.buyingPower)}`}
                sx={{
                  color:
                    Number(getAccountBalance.buyingPower) >= 0 ? theme.palette.success.main : theme.palette.error.main,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
              <OrderSummaryTypography
                title="Margin"
                value={`${NumberFormatHelper.toCurrency(getAccountBalance.margin)}`}
                sx={{
                  color: Number(getAccountBalance.margin) >= 0 ? theme.palette.success.main : theme.palette.error.main,
                }}
                icon="tsInfo"
                toolTipTitle="This data updates at the end of the day"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item container xs={12} alignItems={'center'}>
        <OPIconButton id="js-technical-ideas-refresh-icon" onClick={resetAccountBalance}>
          <CachedIcon sx={{ color: theme.palette.info.light }} fontSize={'medium'} />
        </OPIconButton>
        <OPSemiBoldTypograpghy sx={{ marginLeft: 1 }}>Real-time</OPSemiBoldTypograpghy>
      </Grid>
      <Grid item xs={12}>
        {renderSummaryTable()}
      </Grid>
    </Grid>
  );
};
