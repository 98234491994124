import { atom, atomFamily, DefaultValue, selector } from 'recoil';
import { configurationState, howWhyRanksDataState } from '..';
import { tradeSimulatorOperationType } from '../../models/enums/enums';

export const guardRecoilDefaultValue = (candidate: any): candidate is DefaultValue => {
  if (candidate instanceof DefaultValue) return true;
  return false;
};

/*
 * Previouly selected state for trade, income and portfolio management tabs.
 * The string keys are same as used in `viewState`. We can have an enum for these.
 * NOTE:
 * In case of portfolio, multiple combinations of a portfolio can have same symbol.
 * For example: client can have portfolio having (a) AAPL (200 shares) (b) AAPL (Long Call Vertical)
 * Hence combination hash is unique and used to track previously selected combination instead of symbol.
 */
export const previousSelectedSymbolState = atomFamily<string | undefined, 'trade' | 'income' | 'portfolioManagement'>({
  key: 'previousSelectedSymbolStateKey',
  default: undefined,
});

export const selectedSymbolState = selector({
  key: 'selectedSymbolStateKey',
  get: ({ get }) => {
    const configuration = get(configurationState);
    const howWhy = get(howWhyRanksDataState);
    if (!configuration) {
      return 'SPY';
    }
    if (howWhy?.symbol) {
      return howWhy.symbol;
    }
    const storageKey = configuration.isEmbeddingPlatform ? 'embedSelectedSymbolStateKey' : 'selectedSymbolStateKey';
    const storageSymbol = window.localStorage && window.localStorage.getItem(storageKey);
    const allowedSecurities = configuration.additionalData?.allowedSecurities?.split(',').map((s) => s.toUpperCase());
    if ((!allowedSecurities || allowedSecurities.length === 0) && storageSymbol) {
      return storageSymbol;
    }
    const isExists = allowedSecurities?.find((s) => s === storageSymbol);
    if (storageSymbol && isExists) {
      return storageSymbol;
    }
    const defaultSymbol = allowedSecurities?.find((s) => s.trim().toUpperCase() === 'XND');
    if (defaultSymbol) {
      return defaultSymbol;
    }
    const first = allowedSecurities && allowedSecurities[0];
    if (first && first.trim() !== '') {
      return first.toUpperCase();
    }
    return configuration?.marketIndexQuoteSymbol.toUpperCase() || 'SPY';
  },
});

export const tradeSimulatorOperationTypeState = atom({
  key: 'tradeSimulatorOperationTypeStateKey',
  default: tradeSimulatorOperationType.PLSimulator,
});

export const flippedState = atom({
  key: 'flippedStateKey',
  default: false,
});
