import BasicCombination from './basic-combination';
import NumberFormatHelper from './number-format-helper';

export class OpScore {
  isBestOpScore: boolean = false;
  score: number = -1;
  scoreFormatted: string = '';
  explanation: string = '';
  css: 'red' | 'orange' | 'green' | undefined;

  private constructor() {}

  static fromData = (combination: BasicCombination, outlookPercentBounds: number[], outlookPriceBounds: number[]) => {
    const model = new OpScore();
    //NOTE: opscore should be rounded integer. Checklist uses this score to show respective info. Check bug 9129 for rounder error details.
    model.score = NumberFormatHelper.roundNumber(
      model.calculate(combination, outlookPercentBounds, outlookPriceBounds),
      0,
    );
    model.scoreFormatted = NumberFormatHelper.toIntString(model.score, 'never');
    model.css = model.scoreCssClass();
    model.explanation = model.scoreExplanation();
    return model;
  };

  calculate = (combination: BasicCombination, outlookPercentBounds: number[], outlookPriceBounds: number[]) => {
    if (!outlookPercentBounds || !outlookPriceBounds) {
      throw new Error('outlookPercentBounds is undefined');
    }
    const outlookPriceBoundsByExpiration = combination.outlookPriceBoundsByExpiration(
      outlookPercentBounds,
      outlookPriceBounds,
    );
    // TODO outlookPriceBounds in trading_strategies is coming different. Need to correct this.
    const lowSpot = outlookPriceBoundsByExpiration[0];
    const highSpot = outlookPriceBoundsByExpiration[1];
    if (!combination.chain) {
      return 250;
    }
    const spots = combination.strikes().filter((strike) => {
      return strike >= lowSpot && strike <= highSpot;
    });
    spots.push(lowSpot);
    spots.push(highSpot);
    const spotPayoffs = spots.map((spot: any) => {
      return combination.payoff(spot, combination.expiration());
    });
    let maxRisk = Math.min(...spotPayoffs);
    maxRisk = maxRisk > 0 ? 0 : Math.abs(maxRisk);
    let maxReward = Math.max(...spotPayoffs);
    maxReward = maxReward < 0 ? 0 : maxReward;
    let opScore =
      maxReward != 0 && maxRisk != 0
        ? (1 + maxReward / maxRisk) * (combination.probabilityOfProfit() as number) * 100
        : maxReward == 0
        ? 0
        : 250;
    if (opScore > 250) {
      opScore = 250;
    }
    if (isNaN(opScore)) {
      opScore = 0;
    }
    return opScore;
  };

  scoreCssClass = () => {
    const opScore = NumberFormatHelper.roundNumber(this.score);
    if (opScore >= 100) {
      return 'green';
    }
    if (opScore >= 80) {
      return 'orange';
    }
    return 'red';
  };

  scoreExplanation = () => {
    const opScore = NumberFormatHelper.roundNumber(this.score);
    if (opScore >= 100) {
      return 'Good';
    }
    if (opScore >= 80) {
      return 'Not Bad';
    }
    return 'Poor';
  };
}
