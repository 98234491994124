import { Grid, useTheme } from '@mui/material';
import logo from '@op/shared/assets/images/contactLogo.png';
import logoBigBlue from '@op/shared/assets/images/logo-big-blue.png';
import logoBigWhite from '@op/shared/assets/images/logo-big-white.png';
import NasdaqLogo from '@op/shared/assets/images/nasdaq.png';
import opCanadaLogo from '@op/shared/assets/images/opCanadaLogo.png';
import { isNordic, isTmx } from '@op/shared/src/models';
import { ImplementationType } from '@op/shared/src/models/enums/enums';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import firsTradeDark from '@op/shared/assets/images/firstrade-logo-dark.png';
import firsTradeLight from '@op/shared/assets/images/firstrade-logo-light.png';
import opLogoSmall from '@op/shared/assets/images/opLogoSmall.png';
import sogoTradeLogo from '../images/sogotrade-logo-inverted.png';
import LocalizationContext from '../react-i18next/localization-context';

interface LogoWidgetProps {
  direction?: 'row' | 'column';
  isHeader?: boolean;
  isMenuItem?: boolean;
}

export const LogoWidget: React.FC<LogoWidgetProps> = ({ direction = 'row', isHeader, isMenuItem }: LogoWidgetProps) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const shareRoute = useMatch('/share/*');
  const customization = useRecoilValue(customizationState);

  const renderNasdaqImage = () => {
    if (!isNordic()) {
      return;
    }
    return (
      <Grid item xs={7} display="flex" justifyContent="center">
        <img alt="NasdaqLogo" src={NasdaqLogo} style={{ width: 200, height: 62, marginTop: 12 }}></img>
      </Grid>
    );
  };

  const renderImageByRegion = () => {
    return (
      <Grid item xs={5}>
        {isTmx() ? (
          <img alt="logo" src={opCanadaLogo} style={{ height: 90 }}></img>
        ) : (
          <img alt="logo" src={logo} style={{ width: 148, height: 82 }}></img>
        )}
      </Grid>
    );
  };

  const routeTo = () => {
    if (shareRoute) {
      return '#';
    }
    return '/';
  };

  const renderHeaderLogo = () => {
    if (!customization) {
      return null;
    }
    if (customization.implementation === ImplementationType.SOGOTRADE) {
      return (
        <img alt="optionsplay-logo" src={sogoTradeLogo} width="200px" height="35px" style={{ marginTop: '8px' }} />
      );
    }
    if (customization.implementation === ImplementationType.FIRSTRADE) {
      return (
        <img
          alt="optionsplay-logo"
          src={theme.palette.mode === 'dark' ? firsTradeDark : firsTradeLight}
          style={{ marginTop: '8px' }}
        />
      );
    }
    if (customization.isEmbeddingPlatform) {
      return (
        <Grid container>
          <Grid item xs={12}>
            {customization.showProvidedByLabel ? (
              <span style={{ color: theme.palette.text.primary, paddingLeft: 4 }}>{t('common.labels.providedBy')}</span>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <img alt="optionsplay-logo" src={opLogoSmall} width="120px" height="26px" />
          </Grid>
        </Grid>
      );
    }

    const getHeaderSourceImage = () => {
      if (isTmx()) {
        return opCanadaLogo;
      }
      return theme.palette.mode === 'light' ? logoBigBlue : logoBigWhite;
    };

    return (
      <>
        <Link to={routeTo()} style={{ display: 'inline-block' }}>
          <img
            alt="optionsplay-logo"
            src={getHeaderSourceImage()}
            width="90px"
            height="45px"
            style={{ marginTop: '8px' }}
          />
        </Link>
      </>
    );
  };

  const renderMenuItemLogo = () => {
    if (customization.implementation === ImplementationType.SOGOTRADE) {
      return <img alt="optionsplay-logo" src={opLogoSmall} width="120px" height="28px" />;
    }
    if (customization.implementation === ImplementationType.FIRSTRADE) {
      return <img alt="optionsplay-logo" src={opLogoSmall} width="120px" height="28px" style={{ paddingRight: 2 }} />;
    }
    return <></>;
  };

  const renderLogos = () => {
    if (isHeader && !isMenuItem) {
      return renderHeaderLogo();
    }
    if (isHeader && isMenuItem) {
      return renderMenuItemLogo();
    }
    return (
      <Grid container spacing={2} justifyContent="center" direction={direction}>
        {renderImageByRegion()}
        {renderNasdaqImage()}
      </Grid>
    );
  };

  return renderLogos();
};
