import {
  ChangePasswordResponse,
  EditPortfolioResponse,
  FileSaveResponse,
  IPortfolioAccount,
  IPortfoliouploadData,
  PortfolioUploadedDataList,
  SavePortfolioRequest,
  SavePortfolioResponce,
} from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';

export class PortfolioPositionsService extends BaseApiService {
  private static _instance: PortfolioPositionsService;

  private constructor() {
    super('/testInvestmentPositions');
    ApiService.instance.init(PortfolioPositionsService.onError, PortfolioPositionsService.onLoading);
  }

  public static get instance(): PortfolioPositionsService {
    if (PortfolioPositionsService._instance == null) {
      PortfolioPositionsService._instance = new PortfolioPositionsService();
    }
    return PortfolioPositionsService._instance;
  }

  private static onError = (errorCode: number): void => {
    console.error('Error in Profile-details-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public InvestmentPositions = async (): Promise<ResponseViewModel<PortfolioUploadedDataList>> => {
    const response = await ApiService.instance.get<PortfolioUploadedDataList>(this.getUrlPath(''));
    const vm = new ResponseViewModel<PortfolioUploadedDataList>();
    vm.data = response.data as unknown as PortfolioUploadedDataList;
    return vm;
  };

  public Upload = async (request: any): Promise<FileSaveResponse | null> => {
    const url = this.getUrlPath('upload');
    const response = await ApiService.instance.postfile<any, FileSaveResponse>(url, request);
    const editReponse = FileSaveResponse.fromJson(response as unknown as FileSaveResponse);
    return editReponse;
  };

  public UploadGlobal = async (request: any): Promise<FileSaveResponse | null> => {
    const url = this.getUrlPath('uploadGlobal');
    const response = await ApiService.instance.postfile<any, FileSaveResponse>(url, request);
    const editReponse = FileSaveResponse.fromJson(response as unknown as FileSaveResponse);
    return editReponse;
  };

  public ChangeState = async (
    request: IPortfoliouploadData,
  ): Promise<ResponseViewModel<ChangePasswordResponse | null>> => {
    const url = this.getUrlPath('changeState');
    const response = await ApiService.instance.post<any, ChangePasswordResponse>(url, request);
    const vm = new ResponseViewModel<ChangePasswordResponse>();
    vm.data = response.data as unknown as ChangePasswordResponse;
    vm.errors = response.errors;
    return vm;
  };

  public DeletePortfolio = async (
    request: IPortfoliouploadData,
  ): Promise<ResponseViewModel<ChangePasswordResponse | null>> => {
    const url = this.getUrlPath('remove');
    const response = await ApiService.instance.post<any, ChangePasswordResponse>(url, request);
    const vm = new ResponseViewModel<ChangePasswordResponse>();
    vm.data = response.data as unknown as ChangePasswordResponse;
    vm.errors = response.errors;
    return vm;
  };

  public editPortfolio = async (
    request: IPortfoliouploadData,
  ): Promise<ResponseViewModel<EditPortfolioResponse[] | undefined>> => {
    const url = this.getUrlPath('edit');
    const response = await ApiService.instance.post<any, EditPortfolioResponse[]>(url, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const vm = new ResponseViewModel<EditPortfolioResponse[]>();
    vm.data = response.data as unknown as EditPortfolioResponse[];
    vm.errors = response.errors;
    return vm;
  };

  public savePortfolioPositions = async (
    request: SavePortfolioRequest,
  ): Promise<ResponseViewModel<SavePortfolioResponce | undefined>> => {
    const url = this.getUrlPath('savePortfolioPositions');
    const response = await ApiService.instance.post<any, SavePortfolioResponce>(url, request);
    const vm = new ResponseViewModel<SavePortfolioResponce>();
    vm.data = response.data as unknown as SavePortfolioResponce;
    vm.errors = response.errors;
    return vm;
  };

  public getAccountStatus = async (): Promise<ResponseViewModel<string>> => {
    const response = await ApiService.instance.get<string>(this.getUrlPath('AccountSyncStatus'));
    const vm = new ResponseViewModel<string>();
    vm.data = response.data as unknown as string;
    return vm;
  };

  public getAccounts = async (code: string): Promise<ResponseViewModel<IPortfolioAccount[] | undefined>> => {
    const response = await ApiService.instance.get<IPortfolioAccount[]>(
      this.getUrlPath(`Accounts${code ? `?code=${code}` : ''}`),
    );
    const vm = new ResponseViewModel<IPortfolioAccount[]>();
    vm.data = response.data as unknown as IPortfolioAccount[];
    return vm;
  };

  public saveAccountsInfo = async (request: string[]): Promise<ResponseViewModel<ChangePasswordResponse | null>> => {
    const url = this.getUrlPath('SaveAccounts');
    const response = await ApiService.instance.post<any, ChangePasswordResponse>(url, request);
    const vm = new ResponseViewModel<ChangePasswordResponse>();
    vm.data = response.data as unknown as ChangePasswordResponse;
    vm.errors = response.errors;
    return vm;
  };

  public getBrokerAccounts = async (refresh: boolean): Promise<ResponseViewModel<IPortfolioAccount[] | null>> => {
    const url = this.getUrlPath(`GetBrokerAccounts${`?refresh=${refresh}`}`);
    const response = await ApiService.instance.get<IPortfolioAccount[]>(url);
    const vm = new ResponseViewModel<IPortfolioAccount[]>();
    vm.data = response.data as unknown as IPortfolioAccount[];
    vm.errors = response.errors;
    return vm;
  };
}
