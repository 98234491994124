import { IBuilderData, IncomeBuilder, IncomeCombination, IncomeStrategies } from '@op/shared/src/models';
import {
  LegType,
  OptionType,
  PriceCalculationMethod,
  tradeSimulatorOperationType,
} from '@op/shared/src/models/enums/enums';
import BasicCombination from '@op/shared/src/models/how/basic-combination';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import {
  costBasisState,
  howDataState,
  incomeCombinationAtomFamilyState,
  incomeCombinationSelectedIdState,
  incomeCombinationUpdaterState,
  incomeStrategiesState,
  incomeStrategyHasShareState,
  resetIncomeState,
  sharesState,
  strategyShareDataState,
  tradeSimulatorOperationTypeState,
} from '@op/shared/src/states';
import { tradingRangeSimulatorState, whatIfSimulatorState } from '@op/shared/src/states/how/how-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SingleStrategyWidget } from '../single-trade';

export const IncomeSingleStrategyWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const strategyShareData = useRecoilValue(strategyShareDataState);
  const [incomeCombinationSelectedId, setIncomeCombinationSelectedId] = useRecoilState(
    incomeCombinationSelectedIdState,
  );
  const incomeCombination = useRecoilValue(incomeCombinationAtomFamilyState(incomeCombinationSelectedId.toString()));
  const setWhatIfSimulator = useSetRecoilState(whatIfSimulatorState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorState);
  const setIncomeStrategies = useSetRecoilState(incomeStrategiesState);
  const setIncomeCombinations = useSetRecoilState(incomeCombinationUpdaterState);
  const setCostBasis = useSetRecoilState(costBasisState);
  const setShares = useSetRecoilState(sharesState);
  const setHasShares = useSetRecoilState(incomeStrategyHasShareState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const resetStrategyShareData = useResetRecoilState(strategyShareDataState);
  const setResetIncome = useSetRecoilState(resetIncomeState);

  //for income share.
  // useEffect(() => {
  //   if (incomeCombination) {
  //     return;
  //   }
  //   //(!shareRoute && !embedderRoute) ||
  //   if (!strategyShareData || isReady || !configuration) {
  //     return;
  //   }
  //   const isIncome = (strategyShareData.combinationType as string) === 'Income';
  //   if (!isIncome) {
  //     return;
  //   }
  //   if (!howData || !howData.chain) {
  //     return;
  //   }
  //   initializeIncomeIfPossible();
  // }, [strategyShareData, howData, configuration]);
  useEffect(() => {
    if (!strategyShareData) {
      return;
    }
    const isIncome = (strategyShareData.combinationType as string) === 'Income';
    if (!isIncome) {
      return;
    }
    if (!howData) {
      return;
    }
    const hasOptionsLegs = strategyShareData.legs.find((i: any) => i.legType !== 'Security');
    if (hasOptionsLegs && hasOptionsLegs.length > 0 && !howData.chain) {
      return;
    }
    initializeIncomeIfPossible();
    return () => {
      resetStrategyShareData();
    };
  }, [strategyShareData]);

  const initializeIncomeIfPossible = () => {
    if (!howData) {
      return;
    }
    const costBasis = strategyShareData.costBasis ? (strategyShareData.costBasis as number) : 0;
    const incomeStrategies = IncomeStrategies.fromData(howData, 100, costBasis);
    const sentiment = incomeStrategies.sentiment;
    if (!sentiment) {
      throw new Error('sentiment  is undefined');
    }
    //TODO: have typed share result.
    const enumKey = formatting.pascalToSnakeCase(strategyShareData.priceCalculationMethod);
    const priceCalculationMethod = PriceCalculationMethod[enumKey as keyof typeof PriceCalculationMethod];
    const targetPrice = strategyShareData.targetPrice as number;
    let targetDate = DateTimeHelper.resolveDate(strategyShareData.targetDate);
    // NOTE: The system works if we do not have datepart to it. Hence, only taking datepart and stripping timepart.
    if (targetDate) {
      targetDate.setHours(0, 0, 0, 0);
    }
    // const symbol = strategyShareData.symbol as string;
    const sharedCombination = new IncomeBuilder(howData as IBuilderData)
      .withLegs(strategyShareData.legs)
      .withPriceCalculationMethod(priceCalculationMethod)
      .build();
    const isCall = strategyShareData.legs.some((l: any) => l.legType.toUpperCase() === 'CALL');
    if (isCall) {
      sharedCombination.isIncomeSpecific = true;
    }
    incomeStrategies.incomeOptionPair.set(isCall ? OptionType.CALL : OptionType.PUT, sharedCombination);
    const newCombinations: BasicCombination[] = incomeStrategies.Combinations.filter(
      (c): c is IncomeCombination => c !== undefined,
    ).map((c) => c);
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, newCombinations);
    const whatIfSimulator = WhatIfSimulator.fromData(howData, sentiment, tradingRangeSimulator);
    whatIfSimulator.whatIfDate = targetDate;
    if (targetPrice) {
      whatIfSimulator.whatIfSPrice = targetPrice;
    }

    const optionLeg = strategyShareData.legs.find((leg: { legType: LegType }) => {
      return leg.legType !== LegType.SECURITY;
    });
    let combinationHasShares = optionLeg.legType === LegType.CALL ? true : false;
    const incomeCombinationSelectedId = !combinationHasShares ? 1 : 0;
    const hasShares = !combinationHasShares ? 1 : 0;

    // TODO : IncomeProfiltLossWidget is not working
    setSimulatorOperation(tradeSimulatorOperationType.ShowRisksandCheckilst);
    setShares(100); //TODO: Check by sharing shares income.
    setCostBasis(costBasis);
    setIncomeStrategies(incomeStrategies);
    setIncomeCombinationSelectedId(incomeCombinationSelectedId);
    setHasShares(hasShares);
    setIncomeCombinations(incomeStrategies.Combinations);
    setTradingRangeSimulator(tradingRangeSimulator);
    setWhatIfSimulator(whatIfSimulator);
    setResetIncome({ reason: 'completed', shouldReset: false });
  };

  return (
    <div id="income_screenshot">
      <SingleStrategyWidget
        combination={incomeCombination}
        shareView="income"
        sharedBy={strategyShareData?.sharedBy as string}
        creationDate={new Date(strategyShareData?.creationDate || new Date())}
      />
    </div>
  );
};
