import { Grid, IconButton, Paper, SwipeableDrawer, useTheme } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { accountState } from '@op/shared/src/states';
import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { OPBoldTypograpghy } from '../styled';
import { getAccounts, getEnviornment, getTSToken, isTermsAndConditionAccepted } from './services';
import {
  TSEnvironmentTypes,
  TSTabTypes,
  prepareAccountData,
  tsAccountIdsSelectorState,
  tsAccountsDataState,
  tsAuthenticationStatusState,
  tsDockState,
  tsEnvironmentState,
  tsSelectedTabState,
  tsTermsAndConditionAcceptState,
} from './states/trade-station-states';
import { TSAccountWidget } from './ts-account-widget';
import { TsInfoWidget } from './ts-info-widget';
import { TSMenuWidget } from './ts-menu-widget';
import { TSTabPanelWidget } from './ts-tab-panel-widget';
import { TSTabsWidget } from './ts-tabs-widget';

export const TradeStationDock: React.FC = () => {
  const theme = useTheme();
  const account = useRecoilValue(accountState);
  const [tsAccountsData, setTSAccountsData] = useRecoilState(tsAccountsDataState);
  const setIds = useSetRecoilState(tsAccountIdsSelectorState);
  const [open, setOpen] = useRecoilState(tsDockState);
  const [tsAuthenticationStatus, setTsAuthenticationStatus] = useRecoilState(tsAuthenticationStatusState);
  const [selectedTab, setSelectedTab] = useRecoilState(tsSelectedTabState);
  const [tsEnv, setTSEnv] = useRecoilState(tsEnvironmentState);
  const setTSTermsAndConditionAccepted = useSetRecoilState(tsTermsAndConditionAcceptState);
  const configRef = useRef(false);

  useEffect(() => {
    if (!account) {
      return;
    }
    if (!account.isAllowTradeStationAccountLinking) {
      return;
    }
    if (configRef.current) {
      return;
    }
    (async () => {
      configRef.current = true;
    })();
  }, [account]);

  useEffect(() => {
    if (!account) {
      return;
    }
    if (!account.isAllowTradeStationAccountLinking) {
      return;
    }
    (async () => {
      const response = await isTermsAndConditionAccepted();
      if (response.hasErrors) {
        return;
      }
      setTSTermsAndConditionAccepted(response.data);
    })();
    (async () => {
      let env = 'none';
      const response = await getEnviornment();
      if (response.hasErrors || response.data === '') {
        return;
      }
      env = response.data === 'Simulation' ? 'sim' : 'live';
      setTSEnv(env as TSEnvironmentTypes);
    })();
  }, [account]);

  useEffect(() => {
    if (tsEnv === 'none') {
      // setTsAuthenticationStatus(false);
      return;
    }
    (async () => {
      const response = await getAccounts(tsEnv);
      if (response.hasErrors) {
        // setTsAuthenticationStatus(false);
        return;
      }
      setTsAuthenticationStatus(true);
      const supportedAccounts = prepareAccountData(response.data.accounts);
      const ids = supportedAccounts.map((a) => a.accountID);
      setTSAccountsData(supportedAccounts);
      setIds(ids);
    })();
  }, [tsEnv]);

  useEffect(() => {
    if (tsEnv === 'none') {
      // setTsAuthenticationStatus(false);
      return;
    }
    if (!tsAccountsData || tsAccountsData.length === 0) {
      return;
    }
    (async () => {
      const response = await getTSToken(tsEnv);
      if (response.hasErrors) {
        // setTsAuthenticationStatus(false);
        return;
      }
      if (!response.data) {
        return;
      }
      try {
        const decodedToken = atob(response.data?.token);
        ApplicationContext.tradeStationToken = decodedToken;
        ApplicationContext.tradeStationBaseURL = response.data?.baseUrl;
      } catch (error) {
        console.log('error', error);
      }
    })();
  }, [tsAccountsData]);

  if (!account || !account.isAllowTradeStationAccountLinking || !tsAuthenticationStatus) {
    return null;
  }

  const onChangeCallBack = (index: TSTabTypes) => {
    setSelectedTab(index);
    setOpen(true);
  };

  const onClickCallBack = (value: string) => {
    if (selectedTab !== value) {
      return;
    }
    setOpen(!open);
  };

  const drawerContent = () => {
    return (
      <Grid container style={{ backgroundColor: theme.palette.background.default }}>
        <Grid item xs={12}>
          {renderHeader(true)}
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '50px' }} padding={1}>
          <TSTabPanelWidget selectedValue={selectedTab} />
        </Grid>
      </Grid>
    );
  };

  const renderSwipeableDrawer = () => {
    return (
      <SwipeableDrawer
        variant="temporary"
        id="account-menu"
        open={open}
        anchor="bottom"
        onClose={() => setOpen(!open)}
        onOpen={() => setOpen(!open)}>
        <div
          style={{
            height: '55vh', //windowSize?.height * 0.55,
            backgroundColor: theme.palette.background.default,
          }}>
          {drawerContent()}
        </div>
      </SwipeableDrawer>
    );
  };

  const renderHeader = (fromSwipeableDrawer: boolean) => {
    return (
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: 0.5,
          boxShadow: 2,
          backgroundColor: theme.palette.header.default,
          borderRadius: 1,
          position: fromSwipeableDrawer && 'fixed',
          zIndex: fromSwipeableDrawer && 1000,
        }}>
        <Grid item container xs={10.5} alignItems="center">
          <Grid item xs={1.5} alignItems={'center'} textAlign={'center'} marginTop={1}>
            <IconComponent name="tradeStation" fill="#4E7BC7" />
          </Grid>
          <Grid
            item
            container
            xs={2.5}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              backgroundColor: theme.palette.primary.light,
              borderRadius: 1,
            }}>
            <Grid item xs={4} textAlign={'center'}>
              <OPBoldTypograpghy>Account</OPBoldTypograpghy>
            </Grid>
            <Grid item xs={8}>
              <TSAccountWidget />
            </Grid>
          </Grid>
          <Grid item xs={7} marginLeft={1}>
            <TSTabsWidget
              onChangeCallBack={onChangeCallBack}
              onClickCallBack={onClickCallBack}
              indexVal={selectedTab}
            />
          </Grid>
        </Grid>
        <Grid item xs={0.7} textAlign={'right'}>
          {tsEnv === 'live' ? (
            <OPBoldTypograpghy sx={{ color: theme.palette.error.main }}>Live</OPBoldTypograpghy>
          ) : (
            <OPBoldTypograpghy sx={{ color: theme.palette.success.main }}>Simulated</OPBoldTypograpghy>
          )}
        </Grid>

        <Grid item xs={0.8} container alignItems="center">
          <Grid item xs={3} textAlign={'right'}>
            <TsInfoWidget />
          </Grid>
          <Grid item xs={4} textAlign={'left'}>
            <TSMenuWidget />
          </Grid>
          <Grid xs={5}>
            <IconButton className="js-trade-station-dock" onClick={() => setOpen(!open)}>
              <IconComponent
                name="upArrowIcon"
                style={{
                  transform: open ? 'rotate(0deg)' : 'rotate(-180deg)',
                }}
                size={15}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper
      sx={{
        marginY: 1,
        border: `2px solid ${theme.palette.secondary.dark}`,
        borderRadius: 1,
        ':hover': {
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 1,
          '& .js-trade-station-dock': {
            backgroundColor: theme.palette.secondary.light,
          },
        },
      }}>
      {renderHeader(false)}
      {renderSwipeableDrawer()}
    </Paper>
  );
};
