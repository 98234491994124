import { SocketConnection } from './socket-connection-base';

export class PowerhouseProHubConnection extends SocketConnection {
  private constructor() {
    super();
  }

  static fromHubName = (token?: string | undefined) => {
    const model = new PowerhouseProHubConnection();
    model.fromHubName('powerhouseProHub', token);
    return model;
  };
}
