import { atom, selector } from 'recoil';
import { ProfileDetails, ResetPassword } from '../../models';
import { ProfileDetailsService } from '../../services';

export const EmaildeliveryState = selector({
  key: 'EmaildeliveryState',
  get: async ({ get }) => {
    const response = await ProfileDetailsService.instance.EmailDeliveryFrequencies();
    return response;
  },
});

export const profileCachedState = atom<ProfileDetails>({
  key: 'profileCachedStateKey',
  default: new ProfileDetails(),
});

export const ResetPasswordState = atom<ResetPassword>({
  key: 'resetpaswordstate',
  default: new ResetPassword(),
});

export const fetchProfile = async () => {
  const response = await ProfileDetailsService.instance.Profile();
  if (response.hasErrors || !response.data) {
    return undefined;
  }
  return response.data;
};
