import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import optionPlayMainLogo from '@op/shared/assets/images/optionPlayMainLogo.png';
import { PortfolioCombination, PortfolioManagementCombination } from '@op/shared/src/models';
import {
  BuyOrSell,
  CombinationType,
  EmbedderEventType,
  EventType,
  LegType,
  RecommendedPrice,
} from '@op/shared/src/models/enums/enums';
import {
  Combination,
  IBuilderData,
  IChecklistItem,
  IncomeBuilder,
  IncomeCombination,
  Leg,
  OptionLeg,
  StrategyHelpers,
  TradeTicket,
  createTradeTicketObject,
} from '@op/shared/src/models/how';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { PortfolioPositionsManagement } from '@op/shared/src/models/portfolio/portfolio-positions-management';
import {
  accountState,
  configurationState,
  customizationState,
  howDataState,
  portfolioSelectedCombinationState,
  sharesState,
  strategyModifyState,
  subViewState,
  tradeTicketCombinationState,
  tradingRangeSimulatorState,
  viewState,
} from '@op/shared/src/states';
import { portfolioAllCombinationsState } from '@op/shared/src/states/portfolio-management-states';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SavePositionsWidget, TradeTicketLoaderWidget } from '.';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';
import { StrategyChecklistWidget } from '../single-trade/strategy-checklist-widget';
import {
  OPBoldTypograpghy,
  OPLightTypograpghy,
  OptionsPlayCurrencyNonNegativeFormatInput,
  OptionsPlayTextField,
  OptionsPlayTradeTableWidget,
} from '../styled';
import { TSAuthenticateWidget } from '../tradestation';

export interface ITradeTicketWidgetProps {}

export const TradeTicketWidget: React.FC<ITradeTicketWidgetProps> = () => {
  const account = useRecoilValue(accountState);
  const howData = useRecoilValue(howDataState);
  const [tradeTicketCombination, setTradeTicketCombination] = useRecoilState(tradeTicketCombinationState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const selectedPortfolioCombination = useRecoilValue(portfolioSelectedCombinationState);
  const allCombinations = useRecoilValue(portfolioAllCombinationsState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const { t } = React.useContext(LocalizationContext);
  const [tradeTicket, setTradeTicket] = useState<TradeTicket | undefined>();
  const [allLegs, setAllLegs] = useState<OptionLeg[]>();
  const theme = useTheme<Theme>();
  const shares = useRecoilValue(sharesState);

  let checkListItems: IChecklistItem[] = [];

  useEffect(() => {
    return () => {
      setStrategyModify(tradeTicketCombination?.combination);
    };
  }, []);

  useEffect(() => {
    if (!allLegs || allLegs.length === 0) {
      return;
    }
    localStorage.removeItem('tradeTicketCombination');
  }, [allLegs]);

  useEffect(() => {
    if (!tradeTicketCombination || !tradeTicketCombination.combination) {
      return;
    }
    const tradeTicket = new TradeTicket();
    tradeTicket.refreshTrade(tradeTicketCombination.combination, tradeTicketCombination?.resultingCombination);
    setTradeTicket(tradeTicket);
  }, [tradeTicketCombination]);

  useEffect(() => {
    if (!tradeTicket) {
      return;
    }
    setAllLegs(tradeTicket.allLegs());
  }, [tradeTicket]);

  const isSaveTradeEnabled = () => {
    if (!configuration || !account || !account.securityModel) {
      return false;
    }
    const isAllowsaveTrade = configuration.allowSaveTrade;
    const isEmbeddingPlatform = configuration.isEmbeddingPlatform;
    const isViewOPPEnabled = account.isViewOPPEnabled;
    return isAllowsaveTrade && isViewOPPEnabled && !isEmbeddingPlatform;
  };

  const combination = tradeTicketCombination?.combination;

  if (!combination || !tradingRangeSimulator || !tradeTicket || !allLegs || allLegs.length === 0) {
    return <TradeTicketLoaderWidget />;
  }

  const daysToExpiry = () => {
    const daysToexpiry = tradeTicket.daysToExpiry;
    if (daysToexpiry === -999999) {
      return 'N/A';
    } else {
      return daysToexpiry;
    }
  };

  const renderExpiry = (date: Date | undefined): string | undefined => {
    if (!date) {
      return;
    }
    const newdate = DateTimeHelper.format(date);
    return newdate as string;
  };

  const renderTradeLegs = () => {
    return allLegs.map((leg: OptionLeg, index: number) => renderTradeLeg(leg, index));
  };

  const updateTradeTicket = () => {
    if (!howData) return;
    const legs = allLegs.map((l, i) => {
      const leg = Leg.fromBasicPosition(
        l.quantity,
        l.type,
        l.expiry,
        l.strike,
        undefined,
        l.quantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL,
      );
      leg.premium = l.price;
      return leg;
    });
    if (combination.isTrade) {
      const newComb = Combination.fromLegs(legs, howData, undefined, combination.priceCalculationMethod);
      setTradeTicketCombination({ combination: newComb, resultingCombination: undefined });
      return;
    }
    if (combination.isIncome) {
      // const securityLegIndex = allLegs.findIndex((l) => l.type === LegType.SECURITY);
      const optionLegIndex = allLegs.findIndex((l) => l.type !== LegType.SECURITY);
      const isCall = allLegs[optionLegIndex].type === LegType.CALL;
      // if (securityLegIndex === -1) {
      //   const securityLeg = Leg.fromBasicPosition(
      //     Math.abs(legs[0].quantity) * 100,
      //     LegType.SECURITY,
      //     undefined,
      //     undefined,
      //     undefined,
      //     BuyOrSell.BUY,
      //   );
      //   legs.push(securityLeg);
      // } else {
      //   legs[securityLegIndex].quantity = Math.abs(legs[optionLegIndex].quantity) * 100;
      // }
      // const priority = (combination as IncomeCombination).priority;
      // const newComb = new IncomeBuilder(howData as IBuilderData)
      //   .withShares(Math.abs(legs[optionLegIndex].quantity) * 100)
      //   .withCostBasisPerUnit(legs[optionLegIndex].costBasisPerUnit)
      //   .withLegs(legs)
      //   .withPriceCalculationMethod(combination.priceCalculationMethod)
      //   .build(priority as number);
      const leg: any = {
        legType: legs[optionLegIndex].legType,
        strike: legs[optionLegIndex].strikePrice,
        expiry: legs[optionLegIndex].expiry,
        premium: legs[optionLegIndex].premium,
      };
      let newComb: IncomeCombination;
      if (isCall) {
        newComb = new IncomeBuilder(howData as IBuilderData)
          .withShares(Math.abs(legs[optionLegIndex].quantity) * 100)
          /**
           * !Important - Below line needs to be analysed,as setting premium will fix the problem.
           */
          .withCostBasisPerUnit(legs[optionLegIndex].premium)
          .withPriceCalculationMethod(combination.priceCalculationMethod)
          .assembleCall(leg);
      } else {
        newComb = new IncomeBuilder(howData as IBuilderData)
          .withShares(Math.abs(legs[optionLegIndex].quantity) * 100)
          .withPriceCalculationMethod(combination.priceCalculationMethod)
          .assemblePut(leg);
      }

      setTradeTicketCombination({
        combination: newComb,
        resultingCombination: undefined,
      });
      return;
    }
    // Below TradeTicket combination is for Portfolio
    if (combination.isPortfolio && selectedPortfolioCombination) {
      const combinationContext = {
        howDataModel: howData,
        computedLegs: undefined,
        combinationType: CombinationType.PORTFOLIO_ADJUSTMENT,
        defaultPriceCalculationMethod: combination.priceCalculationMethod,
        originalCombination: selectedPortfolioCombination as PortfolioCombination,
      };
      const newComb = PortfolioManagementCombination.fromLegsData(legs, combinationContext);
      const positions = resultingPositions(newComb);
      const resultingCombination = PortfolioPositionsManagement.assembleCombination(
        positions,
        CombinationType.PORTFOLIO_RESULTING,
        undefined,
        howData,
        selectedPortfolioCombination,
      );
      resultingCombination.profileSentiments();
      setTradeTicketCombination({
        combination: newComb,
        resultingCombination: resultingCombination,
      });
      return;
    }
    return;
  };

  const resultingPositions = (recommendationCombination: PortfolioManagementCombination) => {
    const currentCombination = allCombinations[0];
    if (!currentCombination) {
      return [];
    }
    const currentPositions = currentCombination.positions;
    if (!recommendationCombination) {
      return currentPositions;
    }
    return currentPositions.concat(recommendationCombination.positions);
  };

  const onKeyPress = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    updateTradeTicket();
  };

  const renderPremiumField = (leg: OptionLeg, index: number) => {
    if (!isSaveTradeEnabled()) {
      return <Typography variant="body1">{NumberFormatHelper.toFractionalString(leg.priceString, 'never')}</Typography>;
    }
    return (
      <OptionsPlayTextField
        variant="outlined"
        fullWidth
        value={leg.price}
        onChange={(event: any) => {
          if (event.values.floatValue === 0) {
            return;
          }
          const newLegs = allLegs.map((l) => l);
          console.log('allLegs', allLegs);
          newLegs[index].price = event.values.floatValue;
          newLegs[index].priceString = event.values.value;
          setAllLegs(newLegs);
        }}
        onKeyPress={(event) => onKeyPress(event.key)}
        onBlur={() => onKeyPress('ENTER')}
        name="premium-value"
        className="op-formatted-textbox"
        id="premium-value"
        InputProps={{
          inputComponent: OptionsPlayCurrencyNonNegativeFormatInput as any,
        }}
      />
    );
  };

  const renderTradeLeg = (leg: OptionLeg, index: number) => {
    const getColor = () => {
      if (leg.getActionName() === 'BUY TO OPEN') return `${theme.palette.success.main}`;
      else return `${theme.palette.error.main}`;
    };
    return (
      <TableRow key={index}>
        <TableCell id="buy-sell" sx={{ width: '20%' }}>
          <Typography variant="body1" fontWeight="bold" color={getColor()}>
            {leg.getActionName()}
          </Typography>
        </TableCell>
        <TableCell id="quantity" sx={{ width: '15%' }}>
          <Typography variant="body1">{Math.abs(leg.quantity)}</Typography>
        </TableCell>
        <TableCell id="expiry" sx={{ width: '15%' }}>
          <Typography variant="body1">{renderExpiry(leg.expiry)}</Typography>
        </TableCell>
        <TableCell id="stike" sx={{ width: '15%' }}>
          <Typography variant="body1">{leg.strike}</Typography>
        </TableCell>
        <TableCell id="type" sx={{ width: '15%' }}>
          <Typography variant="body1">{leg.type}</Typography>
        </TableCell>
        <TableCell id="premium" sx={{ width: '20%' }}>
          {renderPremiumField(leg, index)}
        </TableCell>
        {tradeTicket.showShortCodeInTradeTicket ? (
          <TableCell id="premium">
            <Typography variant="body1">{leg.shortCode}</Typography>
          </TableCell>
        ) : null}
      </TableRow>
    );
  };

  const renderRecommendedPriceCss = (recomendedPrice: string) => {
    if (tradeTicket.recommendedPriceCss === recomendedPrice) {
      return (
        <img
          src={optionPlayMainLogo}
          alt="main logo"
          style={{
            height: 18,
            width: 22,
            verticalAlign: 'Middle',
            paddingRight: 5,
            marginLeft: 4,
          }}
        />
      );
    } else {
      return '';
    }
  };

  const onClose = () => {
    setSubView(undefined);
    if (combination.isTrade) {
      setView('trade');
    } else if (combination.isIncome) {
      setView('income');
    } else if (combination.isPortfolio) {
      setView('portfolioManagement');
    }
    logActivity('BUTTON', 'tradeTicketCloseButton', generateLogActivityObj());
  };

  const canIncreaseQuantity = () => {
    return StrategyHelpers.canIncreaseQuantity(combination, shares);
  };

  const canDecreaseQuantity = () => {
    return StrategyHelpers.canDecreaseQuantity(combination);
  };

  const getIntialLegQuantity = () => {
    let optionLegs = [];
    for (let i = 0; i < allLegs.length; i++) {
      if (allLegs[i].type !== 'Security') {
        optionLegs.push(Math.abs(allLegs[i].quantity));
      }
    }
    return optionLegs;
  };

  //TODO: This needs to fix.
  const quantityChange = (isIncremment: boolean) => {
    if (allLegs.length < 1) {
      return;
    }
    let intialLegQuantity = getIntialLegQuantity();
    let updatedQuantity: number[] = [];
    if (combination.hasOnlyStx()) {
      updatedQuantity = allLegs.map((item) => {
        const quantity = item.quantity;
        const sign = Math.sign(quantity);
        if (!isIncremment && Math.abs(quantity) <= 100) {
          return sign * 100;
        }
        const absQty = isIncremment ? Math.abs(quantity) + 100 : Math.abs(quantity) - 100;
        return sign * absQty;
      });
    } else {
      const gcd = StrategyHelpers.calculateGCD(intialLegQuantity);
      updatedQuantity = allLegs.map((item) => {
        const quantity = item.quantity;
        const sign = Math.sign(quantity);
        if (item.type !== 'Security') {
          return isIncremment ? sign * Math.abs(quantity + quantity / gcd) : sign * Math.abs(quantity - quantity / gcd);
        }
        return isIncremment ? sign * (Math.abs(quantity) + 100) : sign * (Math.abs(quantity) - 100);
      });
    }
    if (!updatedQuantity || updatedQuantity.length === 0) {
      return;
    }
    const newLegs = allLegs.map((newLeg, i) => {
      newLeg.quantity = updatedQuantity[i];
      return newLeg;
    });
    setAllLegs(newLegs);
    updateTradeTicket();
    logActivity('ICON', 'editorQuantityHeaderChange', isIncremment ? 'Right' : 'Left');
  };

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      tradeTicket.combination.symbol as string,
    );
  };

  const renderBrokerPlatformText = () => {
    if (!customization.showTradeInstructions) {
      return;
    }
    return (
      <Typography variant="body1" py={1} sx={{ maxLines: 2, lineHeight: 1 }}>
        {allLegs.length === 1 ? t('common.titles.brokerPlatformText') : t('common.titles.brokerPlatformTextSingle')}
      </Typography>
    );
  };

  const renderPositionTableView = () => {
    return (
      <Grid item xs={12}>
        {renderBrokerPlatformText()}
        <Paper>
          <TableContainer>
            <OptionsPlayTradeTableWidget>
              <TableHead>
                <TableRow color="primary">
                  <TableCell>
                    <Typography variant="body1">{t('common.titles.buySell')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ArrowLeftIcon
                        sx={!canDecreaseQuantity() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        onClick={() => quantityChange(false)}
                      />
                      <Typography variant="body1">{t('common.titles.quanity')}</Typography>
                      <GuideItem selector=".combinationEditorQuantity_helpPinPlaceholder" />
                      <ArrowRightIcon
                        sx={!canIncreaseQuantity() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        onClick={() => quantityChange(true)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{t('common.titles.expiry')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{t('common.titles.strike')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{t('common.titles.type')}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{t('common.titles.premium')}</Typography>
                  </TableCell>
                  {tradeTicket.showShortCodeInTradeTicket ? (
                    <TableCell>
                      <Typography variant="body1">{t('common.titles.shortCode')}</Typography>
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>{renderTradeLegs()}</TableBody>
            </OptionsPlayTradeTableWidget>
          </TableContainer>
        </Paper>
      </Grid>
    );
  };

  /**
   * Due to Common Grid layout handled seaparetly for Avg Premium.
   * In future we can enhance the existing based on inputs.
   */
  const renderItemBox = (
    title: string | React.ReactNode,
    value: number | string | undefined,
    recommendedPrice?: RecommendedPrice,
  ) => {
    return (
      <Grid
        item
        container
        xs={2.9}
        p={1}
        justifyContent="space-between"
        alignItems={'center'}
        style={{
          backgroundColor: theme.palette.info.dark,
          border: `1.5px solid ${theme.palette.secondary.dark}`,
          borderRadius: theme.shape.borderRadius,
        }}>
        <Grid container justifyContent={'space-between'}>
          <OPLightTypograpghy variant="body1" textAlign={'left'}>
            {title}
            {recommendedPrice ? renderRecommendedPriceCss(recommendedPrice) : null}
          </OPLightTypograpghy>
          <OPBoldTypograpghy variant="body1" textAlign={'right'}>
            {recommendedPrice ? <>{NumberFormatHelper.toCurrency(value)}</> : value}
          </OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const renderBidAskView = () => {
    return (
      <Grid container justifyContent="space-around">
        {renderItemBox(t('common.titles.bid'), tradeTicket.netBid(), RecommendedPrice.BidPrice)}
        {renderItemBox(t('common.titles.mid'), tradeTicket.netMid(), RecommendedPrice.MidPrice)}
        {renderItemBox(t('common.titles.ask'), tradeTicket.netAsk(), RecommendedPrice.AskPrice)}
        {renderItemBox(t('common.titles.avgPremium'), NumberFormatHelper.toCurrency(tradeTicket.avgPremium(), 'never'))}
      </Grid>
    );
  };

  const renderTradeData = () => {
    return (
      <Grid container justifyContent="space-around">
        {renderItemBox(t('common.titles.strategy'), tradeTicket.strategyName)}
        {renderItemBox(t('common.titles.reward'), NumberFormatHelper.toCurrency(tradeTicket.reward, 'never'))}
        {renderItemBox(t('common.titles.risk'), NumberFormatHelper.toCurrency(tradeTicket.risk, 'never'))}
        {renderItemBox(t('common.titles.daysToExpiry'), daysToExpiry())}
      </Grid>
    );
  };

  const getStrategyChecklistData = () => {
    if (combination.isPortfolio && tradeTicketCombination.resultingCombination) {
      return tradeTicketCombination.resultingCombination;
    }
    return combination;
  };

  const renderStrategyChecklistView = () => {
    if (
      tradeTicketCombination &&
      tradeTicketCombination.resultingCombination &&
      tradeTicketCombination.resultingCombination.isClosedPosition()
    ) {
      return null;
    }
    return (
      <StrategyChecklistWidget
        data={getStrategyChecklistData()}
        shouldIncludeSentence
        checkListItems={checkListItems}
        showTradeButtonInChecklist={customization.showTradeButtonInChecklist}
        tradeButtonLabel={getTradeButtonTriggerLabel()}
        onClickTradeButton={postTradeTicketToHostIfPossible}
      />
    );
  };

  const generateLogActivityObj = () => {
    const additionalLogObj = {
      title: tradeTicket.title.length > 0 ? tradeTicket.title[0] : '',
      strategyChecklist: lodash.uniqBy(checkListItems, 'type'),
      reward: tradeTicket.combination.maxReward(),
      risk: tradeTicket.combination.maxRisk(),
      showNakedPutWarning: tradeTicket.showNakedPutWarning,
      daysToExpiry: tradeTicket.daysToExpiry,
      isNakedPut: tradeTicket.isNakedPut,
    };
    const log = { ...additionalLogObj, ...generateTradeTicketObj() };
    return log;
  };

  const generateTradeTicketObj = () => {
    return createTradeTicketObject(tradeTicket.combination, customization);
  };

  const postTradeTicketToHostIfPossible = () => {
    if (!customization?.isEmbeddingPlatform || customization.tradeTicketTriggerPoint !== 'tradeTicket') {
      return;
    }
    // const finalObj = {
    //   tradeTicket: generateTradeTicketObj(),
    //   userId: account ? account.widgetUserId : '',
    // };
    logActivity('BUTTON', 'tradeTicketSubmitButton', generateLogActivityObj());
    const message = { event: EmbedderEventType.TradeTicketExecute.toString(), data: generateTradeTicketObj() };
    window.parent.postMessage(JSON.stringify(message), '*');
    onClose();
  };

  const canShowTradeDeskText = () => {
    if (!customization?.isEmbeddingPlatform || customization.tradeTicketTriggerPoint !== 'tradeTicket') {
      return false;
    }
    if (tradeTicket.allLegs().length > 1 && customization.singleLegTradesOnly) {
      return true;
    }
  };

  const getTradeButtonTriggerLabel = () => {
    if (!customization?.isEmbeddingPlatform || customization.tradeTicketTriggerPoint !== 'tradeTicket') {
      return undefined;
    }
    const label =
      customization.tradeTicketLabel === 'trade'
        ? t('tradeTicket.trade')
        : customization.tradeTicketLabel.length > 0
        ? `${customization.tradeTicketLabel}`
        : t('tradeTicket.buyOrSell');
    return label;
  };

  const renderBuyOrSell = () => {
    if (customization.showTradeButtonInChecklist) {
      return;
    }
    if (!customization?.isEmbeddingPlatform || customization.tradeTicketTriggerPoint !== 'tradeTicket') {
      return;
    }
    const label = getTradeButtonTriggerLabel();
    return (
      <Grid container alignItems={'center'}>
        <Grid item xs={9} pl={2}>
          {canShowTradeDeskText() ? (
            <Typography sx={{ color: theme.palette.selectAndTextField.dark }}>
              {t('common.labels.callTradeDeskForComplexTrade')}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Button variant="contained" onClick={postTradeTicketToHostIfPossible} disabled={canShowTradeDeskText()}>
            <Typography sx={{ color: theme.palette.selectAndTextField.dark }} variant="button">
              {label}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container rowSpacing={1} justifyContent="space-between">
      <Grid item container sx={{ backgroundColor: theme.palette.info.dark }} p={0.8} alignItems={'center'}>
        <Grid item xs={11.5} textAlign={'center'}>
          <Typography variant="subtitle1" fontWeight="bold" id="trade-header" lineHeight={1.2}>
            {tradeTicket.title.map((t, index) => (
              <div key={index}>{t}</div>
            ))}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <CloseIcon
            id="tradeTicketCloseButton"
            data-name="tradeTicketCloseButton"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              localStorage.removeItem('tradeTicketCombination');
              onClose();
            }}
          />
        </Grid>
      </Grid>
      <Grid item container p={1} rowSpacing={1}>
        <Grid item xs={12}>
          {renderPositionTableView()}
        </Grid>
        <Grid item xs={12}>
          {renderBidAskView()}
        </Grid>
        <Grid item xs={12}>
          {renderTradeData()}
        </Grid>
        <Grid item xs={12}>
          <SavePositionsWidget combination={combination} isSaveTradeEnabled={isSaveTradeEnabled()} />
        </Grid>
        <Grid item xs={12}>
          {/* If necessary, update tradeTicket local state to recoil */}
          <TSAuthenticateWidget tradeTicket={tradeTicket} />
        </Grid>
        <Grid item xs={12}>
          {renderStrategyChecklistView()}
        </Grid>
      </Grid>
      <Grid item container p={1} style={{ position: 'absolute', bottom: 0, right: 5 }}>
        {renderBuyOrSell()}
      </Grid>
    </Grid>
  );
};
