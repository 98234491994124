import { Card, Grid, Typography, useTheme } from '@mui/material';

import { IconComponent } from './icons';
import React from 'react';

interface IEmptyRowProps {
  message: string;
  hideSearchIcon?: boolean;
}

export const EmptyRowWidget: React.FC<IEmptyRowProps> = ({ message, hideSearchIcon }: IEmptyRowProps) => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="space-between" columnSpacing={1}>
      {[0, 1, 2, 3, 4].map((value) => (
        <Grid item xs={12} key={value}>
          <Card
            sx={{
              height: 90,
              m: 1,
              alignItems: 'center',
              display: 'flex',
              textAlign: 'center',
              p: 1,
            }}>
            {value === 2 && (
              <Grid container alignItems={'center'}>
                {hideSearchIcon && (
                  <Grid item xs={12}>
                    <IconComponent name={'searchIcon'} stroke="#999999" />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography sx={{ color: theme.palette.text.secondary }}>{message}</Typography>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
