export interface ISubscriptionPaymentForms {
  plan?: string;
  cardNumber: string;
  cardName: string;
  cardMonth: string;
  cardYear: string;
  cardCvv: string;
  country?: string;
  address?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  cardNumberMaxLength?: number;
  cardSecurityCodeMaxLength?: number;
  creditCardLastFourNumbers?: number;
  pricingModelCode?: string;
  billingDay?: number;
  billingMonth?: number;
}

export class SubscriptionPaymentForms implements ISubscriptionPaymentForms {
  plan = 'Monthly';
  cardNumber = '';
  cardName = '';
  cardMonth = '';
  cardYear = '';
  cardCvv = '';
  country = 'US';
  address = '';
  city = '';
  state = '';
  postalCode = '';
  phone = '';
  cardNumberMaxLength = 16;
  cardSecurityCodeMaxLength = 4;
  creditCardLastFourNumbers = 0;
  pricingModelCode = 'ALACARTE';
  billingDay = 0;
  billingMonth = 0;

  public static fromJson = (data: SubscriptionPaymentForms): SubscriptionPaymentForms => {
    const paymentForms = new SubscriptionPaymentForms();
    paymentForms.plan = data.plan;
    paymentForms.cardNumber = data.cardNumber;
    paymentForms.cardName = data.cardName;
    paymentForms.cardMonth = data.cardMonth;
    paymentForms.cardYear = data.cardYear;
    paymentForms.cardCvv = data.cardCvv;
    paymentForms.country = data.country;
    paymentForms.address = data.address;
    paymentForms.city = data.city;
    paymentForms.state = data.state;
    paymentForms.postalCode = data.postalCode;
    paymentForms.phone = data.phone;
    paymentForms.cardNumberMaxLength = data.cardNumberMaxLength;
    paymentForms.cardSecurityCodeMaxLength = data.cardSecurityCodeMaxLength;
    paymentForms.creditCardLastFourNumbers = data.creditCardLastFourNumbers;
    paymentForms.pricingModelCode = data.pricingModelCode;
    paymentForms.billingDay = data.billingDay;
    paymentForms.billingMonth = data.billingMonth;
    return paymentForms;
  };
}
