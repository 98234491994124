import { Grid, List, Menu } from '@mui/material';
import {
  OPBoldTypograpghy,
  OptionsPlayDivider,
  StyledDropdownItemWithBorder,
  watchListPortfolioMenuProps,
} from '../styled';
import { accountState, isDataLoadingState } from '@op/shared/src/states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { IconComponent } from '../common';
import React from 'react';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

interface LinkMenuItem {
  id: number;
  symbol: string;
  name: string;
}
interface LinkProps {
  data: LinkMenuItem[];
  filterType: 'portfolio' | 'watchlist';
  title: string;
  noTitle: string;
  icon: string;
  hoverIcon: string;
  stroke?: string;
  showDefault?: boolean;
}
export const OpLinkMenus: React.FC<LinkProps> = ({
  data,
  filterType,
  title,
  noTitle,
  icon,
  hoverIcon,
  stroke,
  showDefault,
}: LinkProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const setIsDataLoading = useSetRecoilState(isDataLoadingState);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const account = useRecoilValue(accountState);

  data = lodash.uniqWith(data, lodash.isEqual);

  if (showDefault) {
    return <IconComponent name={icon} stroke={stroke || 'inherit'} />;
  }

  if (!data.length || !account) {
    return <></>;
  }

  const canAccessPortfolio = account.canWorkWithPortfolios && filterType === 'portfolio';
  const canAccessWatchList = account.canWorkWithWatchLists && filterType === 'watchlist';

  if (!canAccessPortfolio && !canAccessWatchList) {
    return <></>;
  }

  /*
    TODO: reset the url on respective operations perform.
  */
  const navigateTo = (id: number, symbol: string) => {
    const routeValue = filterType === 'portfolio' ? 'portfolio' : 'watchlist';
    navigate(`/${routeValue}?id=${id}&symbol=${symbol}`);
  };

  return (
    <>
      <Grid
        container
        onClick={(e) => {
          e.stopPropagation();
          setAnchor(e.currentTarget);
        }}>
        <IconComponent name={icon} hoverIcon={hoverIcon} width={22} size={20} stroke={stroke || 'inherit'} />
        <span style={{ fontSize: 10, marginTop: 5, color: stroke || 'inherit' }}>{data.length}</span>
      </Grid>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClick={(e) => {
          e.stopPropagation();
          setAnchor(null);
        }}
        PaperProps={watchListPortfolioMenuProps(theme)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <List
          sx={{
            overflow: 'auto',
            maxHeight: 300,
          }}>
          <Grid textAlign={'center'} px={1}>
            <OPBoldTypograpghy>{data.length > 0 ? title : noTitle}</OPBoldTypograpghy>
          </Grid>
          <OptionsPlayDivider />
          {data.map((item) => {
            return (
              <StyledDropdownItemWithBorder
                color="default"
                key={item.id}
                value={item.name}
                isWatchList={true}
                onClick={(e) => {
                  // onSelected(); //TODO: r-idea, why is onSelected here
                  setIsDataLoading(false);
                  navigateTo(item.id, item.symbol);
                }}>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>{item.name}</Grid>
                  <Grid item>
                    <ArrowForwardIosOutlinedIcon sx={{ fontSize: 12, mt: 0.5, color: theme.palette.primary.main }} />
                  </Grid>
                </Grid>
              </StyledDropdownItemWithBorder>
            );
          })}
        </List>
      </Menu>
    </>
  );
};
