import { StrategiesProvider } from '.';
import { BuyOrSell, LegType } from '../enums/enums';
import BasicCombination from './basic-combination';

export class SpecialStrategies {
  static getCallPos = (positions: any[]) => {
    const callPos = positions.filter(function (pos) {
      return pos.legType === LegType.CALL;
    })[0];

    return callPos;
  };

  static getSecPos = (positions: any[]) => {
    const secPos = positions.filter(function (pos) {
      return pos.legType === LegType.SECURITY;
    })[0];

    return secPos;
  };

  static CoveredCallStrategy = (comb: any) => {
    const combination = comb;

    const template = StrategiesProvider.getTemplateByName('Covered Call');

    const isSpecial = true;

    const buyOrSell = undefined;

    // const displayedName = 'Long ' + template.name;
    // const iconName = displayedName.replace(/ /g, '');

    const absQuantity = () => {
      const positions = combination.extractedPositions;
      const callPos = SpecialStrategies.getCallPos(positions);

      return -callPos.quantity;
    };

    const incomeBuyOrSell = () => {
      const positions = combination.extractedPositions;

      if (positions.length === 2) {
        const callPos = SpecialStrategies.getCallPos(positions);
        const secPos = SpecialStrategies.getSecPos(positions);

        if (secPos && callPos && secPos.quantity > 0 && callPos.quantity < 0) {
          if (secPos.quantity / combination.multiplier() >= -callPos.quantity) {
            // Check : secPos.ownedQuantity in app-dev as it is used ,verify the quantity and ownedQuantity
            if (secPos.quantity / combination.multiplier() >= -callPos.quantity) {
              return BuyOrSell.SELL;
            } else {
              return BuyOrSell.BUY;
            }
          }
        }
      }
    };
    return { absQuantity, incomeBuyOrSell, buyOrSell, isSpecial, template };
  };

  static checkSpecialStrategies = (combination: BasicCombination) => {
    let result: any;
    const positions = combination.extractedPositions;
    // check for Covered Call
    if (positions.length === 2) {
      const callPos = SpecialStrategies.getCallPos(positions);
      const secPos = SpecialStrategies.getSecPos(positions);

      if (secPos && callPos && secPos.quantity > 0 && callPos.quantity < 0) {
        if (secPos.quantity / combination.multiplier() >= -callPos.quantity) {
          result = SpecialStrategies.CoveredCallStrategy(combination);
          //result.buyOrSell = enums.BuyOrSell.BUY;
          result.buyOrSell = BuyOrSell.BUY;
        }
      }
    }
    return result;
  };
}
