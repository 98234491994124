import { IRecommendationAlerts, RecomendationDetail } from '.';

export class RecommendationAlerts implements IRecommendationAlerts {
  key = '';
  alert = '';
  alertTitle = '';
  title = '';
  plainEnglish = '';
  adjustmentText = '';
  adjustmentAction = '';
  positionStringName = '';
  shareQuantity = -1;
  recomendationDetails: RecomendationDetail[] = [];
  originalRecomendationDetails: RecomendationDetail[] = [];
  strategyName = '';

  public static fromText = (data: RecommendationAlerts | any): RecommendationAlerts => {
    const value = new RecommendationAlerts();
    value.key = data.key;
    value.alert = data.alert;
    value.alertTitle = data.alertTitle;
    value.title = data.title;
    value.plainEnglish = data.plainEnglish;
    value.adjustmentText = data.adjustmentText;
    value.adjustmentAction = data.adjustmentAction;
    value.positionStringName = data.positionStringName;
    value.shareQuantity = data.shareQuantity;
    value.recomendationDetails = data.recomendationDetails;
    value.originalRecomendationDetails = data.originalRecomendationDetails;
    value.strategyName = data.strategyName;
    return value;
  };
}
