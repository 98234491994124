import { ApiService, powerhouseProApiServiceUrl } from '.';
import { Trades } from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';

const getTradeIdeas = async (): Promise<ResponseViewModel<Trades>> => {
  const url = `${powerhouseProApiServiceUrl}/tradeIdeas`;
  const response = await ApiService.instance.get<Trades>(url);
  return response;
};

const getTradeIdeasAnonymous = async (shareId: string): Promise<ResponseViewModel<Trades>> => {
  if (!shareId || shareId.trim() === '') {
    throw new Error('Please provide a valid shareid');
  }
  const url = `${powerhouseProApiServiceUrl}/anonymous/tradeIdeas?shareId=${shareId}`;
  const response = await ApiService.instance.get<Trades>(url);
  return response;
};

export const fetchTradeIdeas = async (isAnonymous: boolean, shareId?: string) => {
  if (isAnonymous && (!shareId || shareId.trim() === '')) {
    return undefined;
  }
  const response = isAnonymous ? await getTradeIdeasAnonymous(shareId as string) : await getTradeIdeas();
  if (response.hasErrors || !response.data) {
    return undefined;
  }
  return response.data;
};
