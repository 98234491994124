import { Grid, TableRow } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { OPBoldTypograpghy } from '../../styled';
import { TradeStationTable } from '../components/ts-table';
import { IOptionExpiry } from '../models/ts-expiry-strike';
import { IOrderTicketPosition, hasOnlySecurity } from '../models/ts-helpers';
import { getOptionExpirations } from '../services';
import { tsEnvironmentState } from '../states/trade-station-states';
import {
  tsExpiryStrikeState,
  tsOrdetTicketPositionState,
  tsTradeOrderPropsState,
  tsTriggerState,
} from '../states/ts-ticket-states';
import { StyledTableCell, StyledTableRow } from '../ts-styles-component';
import { OrderPositionWidget } from './order-position-widget';

export const OrderTicketTable: React.FC = () => {
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const [tsOrderProps, setTsOrderProps] = useRecoilState(tsTradeOrderPropsState);
  const [tsOrderTicketPositions, setTsOrderTicketPositions] = useRecoilState(tsOrdetTicketPositionState);
  const setTsTrigger = useSetRecoilState(tsTriggerState);
  const [tsOptionExpirations, setTsOptionExpirations] = useState<IOptionExpiry | undefined>();
  const resetExpiryStrike = useResetRecoilState(tsExpiryStrikeState);
  const setNotifications = useSetRecoilState(notificationsState);

  useEffect(() => {
    if (!tsOrderTicketPositions) {
      return;
    }
    resetExpiryStrike();
    const isSecurityPosition = hasOnlySecurity(tsOrderTicketPositions);
    if (isSecurityPosition) {
      setTsTrigger({ target: 'snapshot', actionRequired: true });
      return;
    }

    (async () => {
      const symbol = tsOrderTicketPositions[0].underlyingSymbol;
      const response = await getOptionExpirations(tsEnv, symbol);
      if (response.hasErrors) {
        setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
        return;
      }
      setTsOptionExpirations(response.data);
      setTsTrigger({ target: 'strike', actionRequired: true });
    })();
  }, []);

  const renderTableHeader = () => {
    return (
      <TableRow>
        <StyledTableCell style={{ width: '15%' }}>Description</StyledTableCell>
        <StyledTableCell style={{ width: '15%' }}>Side</StyledTableCell>
        <StyledTableCell style={{ width: '10%' }}>Qty</StyledTableCell>
        <StyledTableCell style={{ width: '5%' }}>Positions</StyledTableCell>
        <StyledTableCell style={{ width: '15%' }}>Expiration</StyledTableCell>
        <StyledTableCell style={{ width: '10%' }}>Strike</StyledTableCell>
        <StyledTableCell style={{ width: '5%' }}>Type</StyledTableCell>
        <StyledTableCell style={{ width: '8%' }}>Term</StyledTableCell>
        <StyledTableCell style={{ width: '12%' }}>Symbol</StyledTableCell>
        <StyledTableCell style={{ width: '5%' }}>Actions</StyledTableCell>
      </TableRow>
    );
  };

  const onRemovePosition = (index: number) => {
    let positions = [...tsOrderTicketPositions];
    positions.splice(index, 1);
    setTsOrderTicketPositions(positions);
    let orderProps = [...tsOrderProps];
    orderProps.splice(index, 1);
    setTsOrderProps(orderProps);
    setTsTrigger({ target: 'snapshot', actionRequired: true });
  };

  const renderTableBody = () => {
    if (!tsOrderTicketPositions || tsOrderTicketPositions.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell align="center" colSpan={10}>
            <OPBoldTypograpghy>No Positions Selected !!!</OPBoldTypograpghy>
          </StyledTableCell>
        </StyledTableRow>
      );
    }
    return (
      <>
        {tsOrderTicketPositions.map((position: IOrderTicketPosition, index: number) => (
          <OrderPositionWidget
            key={`orderPosition+${index}`}
            index={index}
            position={position}
            removePosition={() => onRemovePosition(index)}
            tsOptionExpirations={tsOptionExpirations}
          />
        ))}
      </>
    );
  };

  return (
    <Grid container>
      <TradeStationTable tableHead={renderTableHeader()} tableBody={renderTableBody()}></TradeStationTable>
    </Grid>
  );
};
