//TODO: Should have individual files for all components.

import { Box, Button, Divider, Grid, List, Switch, TextField, styled } from '@mui/material';

export const OptionsPlaySwitch = styled(Switch)(({ theme }) => ({}));

export const OptionsPlayLebal = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 700,
  textAlign: 'right',
}));
export const OptionsPlayTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    color: theme.palette.primary.dark,
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.dark,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.selectAndTextField.light,
    color: theme.palette.selectAndTextField.main,
    '& fieldset': {
      borderColor: theme.palette.secondary.dark,
    },
    '& .Mui-disabled': {
      background: theme.palette.info.dark,
      cursor: 'not-allowed',
      PointerEvent: 'all !important',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '& .Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
      borderWidth: 1,
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: 0,
    },
  },
  '& .MuiFormHelperText-root.Mui-error': {
    height: 0,
  },
}));

export const OptionsPlayStyledList = styled(List)({
  '& .MuiListItem-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
});

export const OutlinedButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const OptionsPlayDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
}));

export const ScrollableBox = styled(Box)(({ theme, height }) => ({
  height: `${height}`,
  scrollbarWidth: 'none',
  overflowY: 'auto',
  overflowX: 'auto',
  margin: 0,
  padding: '0',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,.1)' : theme.palette.info.dark,
      borderRadius: theme.shape.borderRadius,
    },
  },
  '&::-webkit-scrollbar': {
    width: '0.6em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    outline: 'none',
  },
  '&::-webkit-scrollbar-hover': {
    scrollbarWidth: 'thin',
  },
  '& .MuiListItem-root': {
    paddingTop: 0,
  },
}));
