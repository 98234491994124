import { IIdea, SortingType, Sorts } from '..';

export interface Trades {
  tradeIdeas: IIdea[];
  originalTradeIdeasCount: number;
  portfolioPositionsCount: number;
  watchlistQuotesCount: number;
  sectors: string[];
  scans: string[];

  totalIdeas: number;
  tradePortfolioPositionsCount: number;
  tradeWatchlistQuotesCount: number;

  // fillQuotes: (quotes: ExpandedQuote[] | undefined) => void;
  // sort: (sorts: Sorts) => ITrades;
}

// export class Trades implements ICollection<IIdea> {
//   data: IIdea[] = [];
//   //TODO: replace tradeIdeas with data.
//   tradeIdeas: IIdea[] = [];
//   // returned from server
//   originalTradeIdeasCount = 0;
//   portfolioPositionsCount = 0;
//   watchlistQuotesCount = 0;
//   sectors: string[] = [];
//   scans: string[] = [];
//   public constructor(tradeIdeas: IIdea[], trades?: Trades) {
//     this.tradeIdeas = tradeIdeas;
//     this.data = tradeIdeas;
//     this.originalTradeIdeasCount = trades?.originalTradeIdeasCount;
//     this.portfolioPositionsCount = trades?.portfolioPositionsCount;
//     this.watchlistQuotesCount = trades?.watchlistQuotesCount;
//     this.sectors = trades?.sectors;
//     this.scans = trades?.scans;
//   }
//   public static fromSelf = (trades: Trades): Trades => {
//     if (!trades) {
//       throw Error('trades is null or undefined');
//     }
//     const model = new Trades(trades.tradeIdeas, trades);
//     return model;
//   };
//   public get totalIdeas(): number {
//     return this.tradeIdeas.length;
//   }
//   public get tradePortfolioPositionsCount(): number {
//     return this.tradeIdeas.filter((e) => e.portfolios.length > 0).reduce((a, i) => a + i.portfolios.length, 0);
//   }
//   public get tradeWatchlistQuotesCount(): number {
//     return this.tradeIdeas.filter((e) => e.watchlists.length > 0).reduce((a, i) => a + i.watchlists.length, 0);
//   }
//   public fillQuotes = (quotes: ExpandedQuote[] | undefined) => {
//     if (!quotes) {
//       return;
//     }
//     const tradeIdeaMap = new Map<string, IIdea>();
//     for (let trade of this.data) {
//       const symbol = trade.symbol.trim().toUpperCase();
//       if (tradeIdeaMap.has(symbol)) {
//         continue;
//       }
//       tradeIdeaMap.set(symbol, trade);
//     }
//     for (let quote of quotes) {
//       const symbol = quote.symbol.trim().toUpperCase();
//       const trade = tradeIdeaMap.get(symbol);
//       if (!trade) {
//         continue;
//       }
//       trade.expandedQuote = quote;
//     }
//   };
//   public sort = (sorts: Sorts) => {
//     if (!sorts) {
//       return this;
//     }
//     const trades = this.sortByCompanyName(sorts)
//       .sortBySymbol(sorts)
//       .sortBySentiment(sorts)
//       .sortByScore(sorts)
//       .sortByPrice(sorts);
//     return trades;
//   };
//   private sortByCompanyName = (sorts: Sorts): Trades => {
//     if (!sorts) {
//       return this;
//     }
//     for (const sort of sorts.data) {
//       if (sort.name !== 'companyName') {
//         continue;
//       }
//       if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           if (trade1.companyName > trade2.companyName) {
//             return 1;
//           }
//           if (trade1.companyName < trade2.companyName) {
//             return -1;
//           }
//           return 0;
//         });
//       } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           if (trade1.companyName > trade2.companyName) {
//             return -1;
//           }
//           if (trade1.companyName < trade2.companyName) {
//             return 1;
//           }
//           return 0;
//         });
//       }
//     }
//     return this;
//   };
//   private sortBySymbol = (sorts: Sorts): Trades => {
//     if (!sorts) {
//       return this;
//     }
//     for (const sort of sorts.data) {
//       if (sort.name !== 'symbol') {
//         continue;
//       }
//       if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
//         this.data = orderByAscending(this.data, 'symbol');
//       } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
//         this.data = orderByDescending(this.data, 'symbol');
//       }
//     }
//     return this;
//   };
//   private sortBySentiment = (sorts: Sorts): Trades => {
//     if (!sorts) {
//       return this;
//     }
//     for (const sort of sorts.data) {
//       if (sort.name !== 'sentiment') {
//         continue;
//       }
//       if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           if (trade1.sentiment > trade2.sentiment) {
//             return 1;
//           }
//           if (trade1.sentiment < trade2.sentiment) {
//             return -1;
//           }
//           return 0;
//         });
//       } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           if (trade1.sentiment > trade2.sentiment) {
//             return -1;
//           }
//           if (trade1.sentiment < trade2.sentiment) {
//             return 1;
//           }
//           return 0;
//         });
//       }
//     }
//     return this;
//     // if (Trades.sortvalue === 'technicalRank') {
//     //   return item1.technicalRank - item2.technicalRank;
//     // }
//     // return 0;
//   };
//   private sortByScore = (sorts: Sorts): Trades => {
//     if (!sorts) {
//       return this;
//     }
//     for (const sort of sorts.data) {
//       if (sort.name !== 'score') {
//         continue;
//       }
//       if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           let signal1 = trade1.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
//           let signal2 = trade2.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
//           if (signal1 && signal2 && signal1.value > signal2.value) {
//             return 1;
//           }
//           if (signal1 && signal2 && signal1.value < signal2.value) {
//             return -1;
//           }
//           return 0;
//         });
//       } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           let signal1 = trade1.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
//           let signal2 = trade2.signals.find((s) => s?.name?.toUpperCase() === 'TechnicalRank'.toUpperCase());
//           if (signal1 && signal2 && signal1.value > signal2.value) {
//             return -1;
//           }
//           if (signal1 && signal2 && signal1.value < signal2.value) {
//             return 1;
//           }
//           return 0;
//         });
//       }
//     }
//     return this;
//   };
//   private sortByPrice = (sorts: Sorts): Trades => {
//     if (!sorts) {
//       return this;
//     }
//     for (const sort of sorts.data) {
//       if (sort.name !== 'price') {
//         continue;
//       }
//       if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           if (!trade1.price || !trade2.price) {
//             return -1;
//           }
//           if (trade1.price > trade2.price) {
//             return 1;
//           }
//           if (trade1.price < trade2.price) {
//             return -1;
//           }
//           return 0;
//         });
//       } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
//         this.data.sort((trade1, trade2) => {
//           if (!trade1.price || !trade2.price) {
//             return 1;
//           }
//           if (trade1.price > trade2.price) {
//             return -1;
//           }
//           if (trade1.price < trade2.price) {
//             return 1;
//           }
//           return 0;
//         });
//       }
//     }
//     return this;
//   };
// }

// Utility Functions for Sorting

const sortByCompanyName = (data: IIdea[], order: SortingType): IIdea[] => {
  return [...data].sort((a, b) => {
    if (a.companyName > b.companyName) return order === SortingType.ASCENDING ? 1 : -1;
    if (a.companyName < b.companyName) return order === SortingType.ASCENDING ? -1 : 1;
    return 0;
  });
};

const sortBySymbol = (data: IIdea[], order: SortingType): IIdea[] => {
  return [...data].sort((a, b) => {
    if (a.symbol > b.symbol) return order === SortingType.ASCENDING ? 1 : -1;
    if (a.symbol < b.symbol) return order === SortingType.ASCENDING ? -1 : 1;
    return 0;
  });
};

const sortBySentiment = (data: IIdea[], order: SortingType): IIdea[] => {
  return [...data].sort((a, b) => {
    if (a.sentiment > b.sentiment) return order === SortingType.ASCENDING ? 1 : -1;
    if (a.sentiment < b.sentiment) return order === SortingType.ASCENDING ? -1 : 1;
    return 0;
  });
};

const sortByScore = (data: IIdea[], order: SortingType): IIdea[] => {
  return [...data].sort((a, b) => {
    const signalA = a.signals.find((s) => s?.name?.toUpperCase() === 'TECHNICALRANK');
    const signalB = b.signals.find((s) => s?.name?.toUpperCase() === 'TECHNICALRANK');

    if (signalA && signalB) {
      if (signalA.value > signalB.value) return order === SortingType.ASCENDING ? 1 : -1;
      if (signalA.value < signalB.value) return order === SortingType.ASCENDING ? -1 : 1;
    }
    return 0;
  });
};

const sortByPrice = (data: IIdea[], order: SortingType): IIdea[] => {
  return [...data].sort((a, b) => {
    if (!a.price || !b.price) return order === SortingType.ASCENDING ? -1 : 1;

    if (a.price > b.price) return order === SortingType.ASCENDING ? 1 : -1;
    if (a.price < b.price) return order === SortingType.ASCENDING ? -1 : 1;
    return 0;
  });
};

const sortTrades = (data: IIdea[], sorts: Sorts): IIdea[] => {
  let sortedData = [...data];

  sorts.data.forEach((sort) => {
    const order = sort.order === '0' ? SortingType.ASCENDING : SortingType.DESCENDING;
    switch (sort.name) {
      case 'symbol':
        sortedData = sortBySymbol(sortedData, order);
        break;
      case 'companyName':
        sortedData = sortByCompanyName(sortedData, order);
        break;
      case 'sentiment':
        sortedData = sortBySentiment(sortedData, order);
        break;
      case 'score':
        sortedData = sortByScore(sortedData, order);
        break;
      case 'price':
        sortedData = sortByPrice(sortedData, order);
        break;
      default:
        break;
    }
  });

  return sortedData;
};

export const sortedTradeIdeas = (tradeIdeas: IIdea[], sorts: Sorts) => {
  return sortTrades(tradeIdeas, sorts);
};
