import CachedIcon from '@mui/icons-material/Cached';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import {
  MenuProps,
  OPIconButton,
  OPSelectDropDownHeader,
  OPSelectField,
  OptionsPlayDatePickerWidget,
  OptionsPlayTextField,
  StyledDropdownItemWithBorder,
} from '../styled';
import {
  filtersHisOrdersByDateState,
  filtersHistoricalOrdersState,
  tsHistoricalOrderSearchState,
  tsHistoricalOrdersState,
} from './states/trade-station-states';

export const TsHistoricalOrderHeader: React.FC = () => {
  const theme = useTheme();
  const resetHistoricalOrders = useResetRecoilState(tsHistoricalOrdersState);
  const [textSearchFieldInput, setTextSearchFieldInput] = useRecoilState(tsHistoricalOrderSearchState);
  const [historicalOrderFilter, setHistoricalOrderFilter] = useRecoilState(filtersHistoricalOrdersState);
  const [startDate, setStartDate] = useRecoilState(filtersHisOrdersByDateState);
  const resetHistoricalOrderFilter = useResetRecoilState(filtersHistoricalOrdersState);
  const resetDateFilter = useResetRecoilState(filtersHisOrdersByDateState);
  const minDate = DateTimeHelper.PastUTCDate(90);
  const maxDate = DateTimeHelper.PastUTCDate(1);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    return () => {
      setTextSearchFieldInput('');
    };
  }, []);

  const resetSearchValues = () => {
    setTextSearchFieldInput('');
  };

  const resetOrder = () => {
    resetSearchValues();
    resetDateFilter();
    resetHistoricalOrderFilter();
    resetHistoricalOrders();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const renderSearch = () => {
    return (
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <OptionsPlayTextField
          variant="outlined"
          onChange={(e) => {
            setTextSearchFieldInput(e.target.value.toUpperCase());
          }}
          placeholder="Search by Symbol"
          name="searchname"
          value={textSearchFieldInput}
          style={{ width: '100%' }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: !isFocused && (
              <IconButton disableRipple sx={{ '&.MuiIconButton-root': { padding: 0 }, cursor: 'default' }}>
                <IconComponent name="searchIcon" stroke={theme.palette.info.light} size={18} />
              </IconButton>
            ),
            endAdornment: textSearchFieldInput !== '' && (
              <IconButton onClick={() => setTextSearchFieldInput('')} sx={{ '&.MuiIconButton-root': { padding: 0 } }}>
                <ClearOutlinedIcon fontSize={'small'} sx={{ color: theme.palette.selectAndTextField.main }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
    );
  };

  const renderHistoryDateFilter = () => {
    return (
      <Grid item xs={12} container alignItems="center">
        <Grid item xs={2} textAlign={'center'}>
          <Typography>Since</Typography>
        </Grid>
        <Grid container xs={10}>
          <Grid item xs={8} alignItems={'right'}>
            <OptionsPlayDatePickerWidget
              value={DateTimeHelper.format(startDate, 'yyyy-MM-dd')}
              min={DateTimeHelper.toDateFromDateTime(minDate)}
              max={DateTimeHelper.toDateFromDateTime(maxDate)}
              onChangeDate={(e) => setStartDate(new Date(e))}
              onBlur={(e) => setStartDate(new Date(e))}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              component="a"
              onClick={() => resetDateFilter()}
              disabled={startDate < DateTimeHelper.toDateFromDateTime(minDate)}
              sx={{
                textDecoration: 'underline',
                textUnderlineOffset: '2px',
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                  outline: 'none',
                },
              }}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getSelectFieldValue = () => {
    const selectedOrderFilter = historicalOrderFilter.find((s) => s.selected);
    if (!selectedOrderFilter) {
      return '';
    }
    return selectedOrderFilter.value as string;
  };

  const onStatusChange = (status: string) => {
    const clone = cloneDeep(historicalOrderFilter);
    for (const f of clone) {
      f.selected = f.value === status;
    }
    setHistoricalOrderFilter(clone);
  };

  const renderStatusDropdown = () => {
    return (
      <Grid container>
        <OPSelectField
          variant="outlined"
          value={getSelectFieldValue()}
          onChange={(e: any) => {
            onStatusChange(e.target.value);
          }}
          fullWidth
          sx={{
            width: '70%',
            height: 35,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            paddingLeft: 1,
          }}
          MenuProps={MenuProps}>
          <OPSelectDropDownHeader type="filters" />
          {historicalOrderFilter.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item.name} value={item.value} selected={item.selected}>
                {item.title}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  return (
    <Grid container xs={12} alignItems={'center'}>
      <Grid item xs={0.5} justifyContent="flex-start">
        <OPIconButton id="js-technical-ideas-refresh-icon" onClick={() => resetOrder()}>
          <CachedIcon sx={{ color: theme.palette.info.light }} fontSize={'medium'} />
        </OPIconButton>
      </Grid>
      <Grid item container xs={9.5} columnSpacing={1}>
        <Grid item xs={3}>
          {renderHistoryDateFilter()}
        </Grid>
        <Grid item xs={3}>
          {renderStatusDropdown()}
        </Grid>
      </Grid>

      <Grid item justifyContent={'flex-end'} xs={2}>
        {renderSearch()}
      </Grid>
    </Grid>
  );
};
