import { PowerhouseProHub } from '@op/shared/src/hubs/powerhouse-pro-hub';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { isHubInitiatedState } from '@op/shared/src/states';
import { useMatch } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useRegisterHubListeners } from './use-register-hub-listeners';

export const useConnectPowerhouseProHub = () => {
  const registerHubListeners = useRegisterHubListeners();
  const setIsHubConnected = useSetRecoilState(isHubInitiatedState);
  const screenshotRoute = useMatch('/screenshot/:id');
  let id: string | undefined;

  const onDisconnect = async () => {
    setIsHubConnected(false);
    setTimeout(async () => {
      await connectSignalR(id);
    }, 5000);
  };

  const connectPowerhouseProHub = async (shareId: string | undefined) => {
    setIsHubConnected(false);
    const powerhouseProHub =
      shareId && shareId.trim() !== ''
        ? PowerhouseProHub.fromSocketConnection(shareId)
        : PowerhouseProHub.fromToken(ApplicationContext.accessToken || '');
    const isConnected = await powerhouseProHub.socketConnection.connect();
    if (!isConnected) {
      return;
    }
    powerhouseProHub.socketConnection.onDisconnect = onDisconnect;
    ApplicationContext.powerhouseProHub = powerhouseProHub;
    registerHubListeners(ApplicationContext.powerhouseProHub);
    setIsHubConnected(true);
  };

  const connectSignalR = async (shareId?: string) => {
    if (screenshotRoute) {
      return;
    }
    id = shareId;
    await connectPowerhouseProHub(shareId);
  };

  return connectSignalR;
};
