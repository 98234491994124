import { styled, Table } from '@mui/material';

export const OptionsPlayTableWidget = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiTableCell-root': {
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.info.dark,
    padding: '2px 0',
  },
  '& .MuiTableCell-body': {
    textAlign: 'center',
    padding: '2px 0',
  },
}));

export const OptionsPlayPortfolioTableWidget = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiTableCell-root': {
    textAlign: 'center',
  },
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.info.dark,
    padding: '4px 0',
  },
  '& .MuiTableCell-body': {
    textAlign: 'center',
  },
}));

export const OptionsPlayTradeTableWidget = styled(Table)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiTableCell-root': {
    textAlign: 'left',
  },
  '& .MuiTableCell-head': {
    textAlign: 'center',
    padding: '4px 0',
    backgroundColor: theme.palette.info.dark,
  },
  '& .MuiTableCell-body': {
    textAlign: 'center',
    padding: '4px 0',
  },
}));
