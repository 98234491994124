import { Grid } from '@mui/material';
import logo from '@op/shared/assets/images/contactLogo.png';
import React from 'react';
import { useMinWidthBreakpoint } from '../states/use-min-width-breakpoint';
import { HowSkeletonWidget } from '../how/how-skeleton-widget';
import { QuotebarLoaderWidget } from '../quoteBar/quote-bar-loader-widget';
import { SkeletonLoadingWhyWidget } from '../why/mid-panel-skeleton';
import { HeightWidget } from '.';
import { WhatLoaderWidget } from '../ideas/what-loader-widget';

export const PageLoaderWidget: React.FC = () => {
  return (
    <Grid container justifyContent={'center'} alignItems={'center'} sx={{ minHeight: '100vh' }}>
      <img alt="logo" src={logo} style={{ width: 240, height: 140 }}></img>
    </Grid>
  );
};

export const AppSkeletonPageWidget: React.FC = () => {
  const isNormalView = useMinWidthBreakpoint();
  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={isNormalView ? 7 : 6} container columnSpacing={1}>
        <Grid item xs={isNormalView ? 4 : 1}>
          <WhatLoaderWidget />
        </Grid>
        <Grid item xs={isNormalView ? 8 : 11}>
          <HeightWidget noScroll>
            <Grid container>
              <Grid item xs={12}>
                <QuotebarLoaderWidget />
              </Grid>
              <Grid item xs={12}>
                <SkeletonLoadingWhyWidget />
              </Grid>
            </Grid>
          </HeightWidget>
        </Grid>
      </Grid>
      <Grid item xs={isNormalView ? 5 : 6}>
        <HeightWidget>
          <HowSkeletonWidget />
        </HeightWidget>
      </Grid>
    </Grid>
  );
};
