export interface IPortfolioRecommendationsEmailDelivery {
  sendOPPortfolioRecommendationsByEmail: boolean;
  opPortfolioRecommendationsEmailDeliveryFrequency: string;
}

export class PortfolioRecommendationsEmailDelivery implements IPortfolioRecommendationsEmailDelivery {
  sendOPPortfolioRecommendationsByEmail = false;
  opPortfolioRecommendationsEmailDeliveryFrequency = '';

  static fromSelf = (self: IPortfolioRecommendationsEmailDelivery) => {
    const clone = new PortfolioRecommendationsEmailDelivery();
    clone.sendOPPortfolioRecommendationsByEmail = self.sendOPPortfolioRecommendationsByEmail;
    clone.opPortfolioRecommendationsEmailDeliveryFrequency = self.opPortfolioRecommendationsEmailDeliveryFrequency;
    return clone;
  };
}
