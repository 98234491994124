import { Grid, Typography, useTheme } from '@mui/material';
import { useHubExpandedQuote } from '@op/shared/src/hubs/use-hub-expanded-quote';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { accountState, expandedQuoteAtomFamily } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ToExtendedChange } from '../common';

export const MarketIndexesWidget: React.FC = () => {
  const symbol = ApplicationContext.configuration?.marketIndexQuoteSymbol;
  useHubExpandedQuote(symbol);
  const quote = useRecoilValue(expandedQuoteAtomFamily(symbol));
  const account = useRecoilValue(accountState);
  const theme = useTheme();

  if (!quote || !account) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body1" color={theme.palette.text.primary}>
          {quote.name} {NumberFormatHelper.toCurrency(quote.last)}
        </Typography>
        <Typography variant="body1">
          <ToExtendedChange quote={quote} />
        </Typography>
      </Grid>
    </Grid>
  );
};
