export interface IVerifyCredentialsModelProps {
  login: string;
  password: string;
  rememberMe: boolean;
  returnUrl: string;
  userVoiceReturnUrl: string;
  redirectUrl: string;
}

export class VerifyCredentialsModel implements IVerifyCredentialsModelProps {
  login = '';
  password = '';
  rememberMe = true;
  returnUrl = '';
  userVoiceReturnUrl = '';
  redirectUrl = '';

  public static fromJson = (json: IVerifyCredentialsModelProps): VerifyCredentialsModel => {
    const signup = new VerifyCredentialsModel();
    signup.login = json.login;
    signup.password = json.password;
    signup.rememberMe = json.rememberMe;
    signup.returnUrl = json.returnUrl;
    signup.userVoiceReturnUrl = json.userVoiceReturnUrl;
    signup.redirectUrl = json.redirectUrl || '';
    return signup;
  };
}
