import { Grid, Paper, useTheme } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import React from 'react';
import { OPBoldTypograpghy, OPLightTypograpghy } from '../../styled';
import { SummaryTypography } from '../components/ts-typography';
import { IPlaceOrderLeg } from '../models';

interface Props {
  orderTicketLegs: IPlaceOrderLeg[];
}

export const OrderConfirmationLegInfoWidget: React.FC<Props> = ({ orderTicketLegs }: Props) => {
  const theme = useTheme();

  const renderActionColor = (tradeAction: string) => {
    if (tradeAction === 'BUY' || tradeAction === 'BUYTOOPEN' || tradeAction === 'BUYTOCLOSE') {
      return theme.palette.success.main;
    }
    return theme.palette.error.main;
  };

  const renderTypeColor = (actionSymbol: string) => {
    if (actionSymbol === 'Call') {
      return theme.palette.success.main;
    }
    if (actionSymbol === 'Put') {
      return theme.palette.error.main;
    }
    return theme.palette.text.primary;
  };

  const renderStrike = (strike: string) => {
    if (strike.trim() <= '') {
      return <OPLightTypograpghy sx={{ color: '#999' }}>N/A</OPLightTypograpghy>;
    }
    return <OPBoldTypograpghy>{strike}</OPBoldTypograpghy>;
  };
  const renderLegType = (actionSymbol: string) => {
    if (actionSymbol === 'None') {
      return <OPBoldTypograpghy>{`Equity`}</OPBoldTypograpghy>;
    }
    return <OPBoldTypograpghy>{actionSymbol}</OPBoldTypograpghy>;
  };
  const renderExpiry = (expiry: string) => {
    if (expiry.trim() === '') {
      return <OPLightTypograpghy sx={{ color: '#999' }}>N/A</OPLightTypograpghy>;
    }
    return <OPBoldTypograpghy>{DateTimeHelper.toExpiry(expiry)}</OPBoldTypograpghy>;
  };

  const getOptionLegInfo = (orderLeg: IPlaceOrderLeg) => {
    return `${orderLeg.expiryDate}${orderLeg.actionSymbol.charAt(0)}${orderLeg.strikePrice}`;
  };

  return (
    <Grid container xs={12} justifyContent={'space-between'}>
      <Grid item xs={0.5} px={0.5}>
        <OPBoldTypograpghy>Legs</OPBoldTypograpghy>
      </Grid>
      <Grid item container xs={11.5} columnSpacing={1}>
        {orderTicketLegs.map((orderLeg: IPlaceOrderLeg, index: number) => (
          <Grid item container xs={6} md={4} lg={2.5} xl={2.5} key={index}>
            <Paper
              elevation={0}
              sx={{ border: `1px solid ${theme.palette.divider}`, backgroundColor: theme.palette.header.default, padding: 0.5 }}>
              <Grid item container alignItems={'center'}>
                <Grid item container xs={12} marginX={0.5}>
                  <Grid item xs={6}>
                    <OPBoldTypograpghy sx={{ color: renderActionColor(orderLeg.tradeAction) }}>
                      {orderLeg.tradeAction}
                    </OPBoldTypograpghy>
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <span>
                      <OPBoldTypograpghy>{orderLeg.symbol}</OPBoldTypograpghy>
                    </span>
                    {orderLeg.actionSymbol !== 'None' && (
                      <span style={{ marginLeft: '8px' }}>
                        <OPLightTypograpghy>{getOptionLegInfo(orderLeg)}</OPLightTypograpghy>
                      </span>
                    )}
                  </Grid>
                </Grid>
                <Grid item container xs={12} alignItems={'center'}>
                  <Grid item xs={6}>
                    <SummaryTypography title="Qty" value={orderLeg.quantity} />
                  </Grid>
                  <Grid item xs={6}>
                    <SummaryTypography
                      title="Type"
                      sx={{ color: renderTypeColor(orderLeg.actionSymbol) }}
                      value={renderLegType(orderLeg.actionSymbol)}
                      borderNone
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} alignItems={'center'}>
                  <Grid item xs={6}>
                    <SummaryTypography
                      title="Expiration"
                      value={renderExpiry(orderLeg.expiryDate)}
                      labelWithoutValue={orderLeg.expiryDate.trim() === ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SummaryTypography
                      title="Strike"
                      value={renderStrike(orderLeg.strikePrice)}
                      borderNone
                      labelWithoutValue={orderLeg.strikePrice.trim() <= ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
