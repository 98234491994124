import React, { ReactNode } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Theme, useTheme } from '@mui/material';
export interface ICommonListItemIconProps {
  icon: ReactNode;
  text: ReactNode;
  callback?: () => void;
}
export const CommonListItemIcon: React.FC<ICommonListItemIconProps> = ({
  icon,
  text,
  callback,
}: ICommonListItemIconProps) => {
  const theme = useTheme<Theme>();
  return (
    <ListItemButton onClick={callback}>
      <ListItemIcon style={{ minWidth: 35, color: theme.palette.text.primary }}>{icon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ component: 'span' }}
        primary={text}
        style={{ color: theme.palette.text.primary }}
      />
    </ListItemButton>
  );
};
