import { IResetPasswordProps } from './password-interface';

export class ResetPassword implements IResetPasswordProps {
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';

  public static fromJson = (data: ResetPassword): ResetPassword => {
    const password = new ResetPassword();
    password.oldPassword = data.oldPassword;
    password.newPassword = data.newPassword;
    password.confirmPassword = data.confirmPassword;
    return password;
  };
}
