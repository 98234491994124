import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';

export const PLSimulatorSubWidget: React.FC<{
  label: ReactNode;
  box: ReactNode;
  leftLabel: ReactNode;
  slider: ReactNode;
  rightLabel: ReactNode;
}> = ({
  label,
  box,
  leftLabel,
  slider,
  rightLabel,
}: {
  label: ReactNode;
  box: ReactNode;
  leftLabel: ReactNode;
  slider: ReactNode;
  rightLabel: ReactNode;
}) => {
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2 }} justifyContent="space-between" alignItems="center">
      <Grid item container xs={5} alignItems="center">
        <Grid item xs={5}>
          {label}
        </Grid>
        <Grid item xs={7}>
          {box}
        </Grid>
      </Grid>
      <Grid item container xs={7}>
        <Grid item xs="auto">
          {leftLabel}
        </Grid>
        <Grid item xs>
          {slider}
        </Grid>
        <Grid item xs="auto">
          {rightLabel}
        </Grid>
      </Grid>
    </Grid>
  );
};
