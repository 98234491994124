import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { Button, Card, Grid, List, Tooltip, styled, useTheme } from '@mui/material';
import { ISubscriptionPlanPrice } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { accountState, selectedSymbolState, subscriptionPlanPriceState } from '@op/shared/src/states';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPListItem } from '../styled';
import { SubscriptionCardLayout } from './subscription-card-layout';

interface OPSubscriptionStyledHoverCardProps {
  isSelected: boolean;
}

const OPSubscriptionStyledHoverCard = styled(Card)<OPSubscriptionStyledHoverCardProps>(({ theme, isSelected }) => ({
  boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
  outline: '1px solid transparent',
  border: isSelected ? `1.5px solid ${theme.palette.primary.main}` : '1.5px solid transparent',
  transition: 'all 0.2s ease-in-out',
  WebkitTransition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  ':hover': {
    boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
    outline: '0px solid transparent',
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease-in-out',
    WebkitTransition: 'all 0.2s ease-in-out',
  },
}));

interface Props {
  planData: ISubscriptionPlanPrice;
  setIsError?: (value: boolean) => void;
}

const cardHeight = '470px'; // Define cardHeight here

export const ChoosePlanWidget: React.FC<Props> = ({ planData }) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const [subscriptionPricingModels, setSubscriptionPricingModels] = useRecoilState(subscriptionPlanPriceState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  if (!account || !account.securityModel) {
    return null;
  }

  const handleChange = () => {
    const clonePrices = cloneDeep(subscriptionPricingModels);
    const selectedPricingModelIndex = clonePrices.findIndex(
      (p) =>
        p.featureType === planData.featureType &&
        p.featureKey === planData.featureKey &&
        p.planType === planData.planType,
    );
    clonePrices.forEach((model, i) => {
      if (model.featureType === 'Bundle') {
        model.isSelected = i === selectedPricingModelIndex;
      }
    });
    setSubscriptionPricingModels(clonePrices);
    logActivity('INPUT', 'paymentsSelectSubscription');
    logActivity('BUTTON', 'paymentsChooseSubscriptionChooseButton');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const getDiscountString = () =>
    planData.discountedRate > 0 ? ` (${t('subscriptions.afterDiscount')} ${planData.discountedRate * 100}%)` : '';

  const renderCardHeader = () => (
    <Grid
      container
      justifyContent="space-between"
      alignContent="center"
      style={{ color: theme.palette.timeFrame.contrastText }}>
      <Grid item xs={9}>
        <OPBoldTypograpghy variant="subtitle1">{planData.featureKey}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={3} container alignContent="center" justifyContent="right">
        <Tooltip title={t('subscriptions.androidIOSSupport')} placement="top" arrow>
          <PhoneAndroidOutlinedIcon sx={{ marginX: '2px' }} fontSize="small" />
        </Tooltip>
        <Tooltip title={t('subscriptions.desktopApplications')} placement="top" arrow>
          <DesktopWindowsOutlinedIcon fontSize="small" />
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderCardContent = () => (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={12}>
        <OPBoldTypograpghy
          style={{
            paddingLeft: '13px',
            paddingTop: '8px',
            fontSize: '16px',
            fontWeight: 700,
          }}>
          {t('subscriptions.choosePlanList.benefits')}
        </OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} pl={3.2}>
        <List sx={{ listStyleType: 'disc', textAlign: 'left' }}>
          {planData.benefitsList.map((benefit) => (
            <OPListItem key={benefit.description}>{benefit.description}</OPListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );

  const renderCardBottom = () => {
    const saved = planData.planPrice - planData.discountedPrice;
    return (
      <Grid
        item
        container
        xs={12}
        justifyContent={'center'}
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? '#F2F9FE' : '#262D32',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          position: 'absolute',
          bottom: 0,
          pl: 2,
          pr: 2,
        }}>
        <Grid item xs={12} textAlign={'center'}>
          <OPBoldTypograpghy
            style={{
              color: theme.palette.primary.main,
              fontSize: 26,
            }}>
            {`$${planData.discountedPrice}`}
            <span style={{ fontSize: 16 }}> USD</span>
          </OPBoldTypograpghy>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <OPBoldTypograpghy style={{ fontSize: 16 }}>{` ${getDiscountString()}`}</OPBoldTypograpghy>
        </Grid>
        <Grid item container xs={8} textAlign={'center'}>
          <Button
            variant={planData.isSelected ? 'contained' : 'outlined'}
            size="large"
            fullWidth
            disabled={planData.isActivePlan || planData.isUpcoming}
            sx={{
              margin: 1,
              backgroundColor:
                planData.isSelected && theme.palette.mode === 'light'
                  ? '#0049A3'
                  : planData.isSelected && theme.palette.mode === 'dark'
                  ? '#5197EC'
                  : null,
            }}>
            <OPBoldTypograpghy>
              {planData.isSelected ? t('subscriptions.selected') : t('subscriptions.select')}
            </OPBoldTypograpghy>
          </Button>
        </Grid>

        {planData.discountedPrice > 0 && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              background:
                !planData.isRecommeded && theme.palette.mode === 'dark'
                  ? '#11181e'
                  : !planData.isRecommeded && theme.palette.mode === 'light'
                  ? theme.palette.primary.light
                  : `linear-gradient(to right,#0078D4, #77BF45)`,
              marginTop: 'auto',
              height: 35,
              boxShadow: `0px 4px 4px rgba(0,0,0,0.4) inset`,
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
            }}>
            <OPBoldTypograpghy
              variant="body1"
              sx={{
                color: !planData.isRecommeded ? theme.palette.primary.main : theme.palette.timeFrame.contrastText,
              }}>
              {`${t('subscriptions.save')} $${saved} `}
            </OPBoldTypograpghy>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderCurrentPlanLabel = () => {
    if (!planData.isActivePlan) {
      return;
    }
    return (
      <OPBoldTypograpghy sx={{ color: theme.palette.primary.main }}>
        {t('subscriptions.yourCurrentActivePlan')}
      </OPBoldTypograpghy>
    );
  };

  const renderUpcomingPlanLabel = () => {
    if (!planData.isUpcoming) {
      return;
    }
    return (
      <OPBoldTypograpghy sx={{ color: theme.palette.primary.main }}>
        {t('subscriptions.yourUpcomingPlan')}
      </OPBoldTypograpghy>
    );
  };

  return (
    <Grid item xs={12} sx={{ marginX: 2 }}>
      <Grid item sx={{ height: '40px', marginBottom: -2 }} />
      <OPSubscriptionStyledHoverCard
        isSelected={planData.isSelected || planData.isActivePlan || planData.isUpcoming}
        onClick={() => {
          if (planData.isActivePlan || planData.isUpcoming) {
            return;
          }
          handleChange();
        }}>
        <SubscriptionCardLayout
          isRecommended={planData.planType === 'Annual'}
          sx={{
            boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
            '&.MuiCardContent-root': { padding: 0 },
            height: cardHeight,
            position: 'relative',
            backgroundColor: theme.palette.greyShades.light,
            opacity: planData.isActivePlan || planData.isUpcoming ? 0.5 : 1,
          }}
          title={renderCardHeader()}
          content={
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>
                {renderCardContent()}
              </Grid>
              <Grid item xs={12}>
                {renderCardBottom()}
              </Grid>
            </Grid>
          }
        />
      </OPSubscriptionStyledHoverCard>
      {renderCurrentPlanLabel()}
      {renderUpcomingPlanLabel()}
    </Grid>
  );
};
