import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { guardRecoilDefaultValue } from '.';
import { ExpandedQuote } from '../models';
import ApplicationContext from '../models/how/application-context';

export const isHubInitiatedState = atom<boolean>({
  key: 'powerhouseProHubState',
  default: false,
});

export const marketToneHubState = selector<any>({
  key: 'marketToneStateKey',
  get: async ({ get }) => {
    get(isHubInitiatedState); //setting dependency graph
    const powerhouseProHub = ApplicationContext.powerhouseProHub;
    if (!powerhouseProHub) {
      return [];
    }
    const marketTone = await powerhouseProHub.getMarketTone();

    if (!marketTone) {
      return [];
    }
    return marketTone;
  },
});

/**
 * @deprecated Use atomFamily implementation instead.
 */
export const marketWorkTimeDetailsCachedState = atom<any | undefined>({
  key: 'marketWorkTimeDetailsCachedStateKey',
  default: undefined,
});

export const fetchMarketWorkTimeDetails = async () => {
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  const marketWorkTimeDetails = await powerhouseProHub.getMarketWorkTimeDetails();
  if (!marketWorkTimeDetails) {
    return undefined;
  }
  return marketWorkTimeDetails;
};

/* helper functions to subscribe and pull data via hub. This can be move to hub file themselves or as recoil selector */
export const fetchQuotes = async (symbols: string[]) => {
  if (!symbols || symbols.length === 0) {
    return undefined;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  const quotes = await powerhouseProHub.getQuotes(symbols);
  if (!quotes) {
    return undefined;
  }

  return quotes;
};

export const subscribeToQuotes = async (symbols: string[]) => {
  if (!symbols || symbols.length === 0) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return;
  }
  await powerhouseProHub.quotesSubscription.subscribe(symbols);
};

export const unsubscribeFromQuotes = async (symbols: string[]) => {
  if (!symbols || symbols.length === 0) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return;
  }
  await powerhouseProHub.quotesSubscription.unsubscribe(symbols);
};

// export const subscribedSymbolsState = atom<Map<string, string>>({
//   key: 'subscribedSymbolsStateKey',
//   default: new Map(),
// });

// const expandedQuoteSymbolAtomFamilyState = atomFamily<string | undefined, string>({
//   key: 'expandedQuoteSymbolAtomFamilyStateKey',
//   default: undefined,
// });

// export const expandedQuoteSymbolsState = selectorFamily({
//   key: 'expandedQuoteSymbolsStateKey',
//   get:
//     (symbols: string[]) =>
//     ({ get }) => {
//       if (symbols.length === 0) {
//         return undefined;
//       }
//       const hubSymbols: string[] = [];
//       for (let symbol of symbols) {
//         const hubSymbol = get(expandedQuoteSymbolAtomFamilyState(symbol.trim().toUpperCase()));
//         if (!hubSymbol) {
//           continue;
//         }
//         hubSymbols.push(hubSymbol);
//       }
//       return hubSymbols;
//     },
// });

//TODO: Expanded quote should save lighter version
export const expandedQuoteAtomFamily = atomFamily<ExpandedQuote | undefined, string>({
  key: 'expandedQuotesAtomFamily',
  /**
   * {
   *   status: 'initial' | 'InProgress' | 'Completed'
   *   value: ExandedQuote | undefined
   * }
   */
  default: undefined,
});

export const expandedQuotesState = selectorFamily({
  key: 'expandedQuotesStateKey',
  get:
    (symbols: string[]) =>
    ({ get }) => {
      if (!symbols || symbols.length === 0) {
        return [];
      }
      const quotes: ExpandedQuote[] = [];
      for (let symbol of symbols) {
        const s = symbol.trim().toUpperCase();
        const quote = get(expandedQuoteAtomFamily(s));
        if (!quote) {
          continue;
        }
        quotes.push(quote);
      }
      return quotes;
    },
});

export const expandedQuotesUpdater = selector<ExpandedQuote[] | undefined>({
  key: 'expandedQuotesUpdaterKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ set }, newQuotes) => {
    if (!newQuotes || guardRecoilDefaultValue(newQuotes)) {
      //reset(expandedQuotesAtomFamily(symbol));
      return;
    }

    for (let quote of newQuotes) {
      const symbol = quote.symbol.trim().toUpperCase();
      set(expandedQuoteAtomFamily(symbol), quote);
    }
    //Update the internal cache as well with the latest signalR data.
    if (ApplicationContext.powerhouseProHub) {
      ApplicationContext.powerhouseProHub.quotesSubscription.set(newQuotes);
    }
  },
});
