import { ErrorResponse } from './error-response';
import { Messages } from '../../constant';

export class ResponseViewModel<T> {
  public errors: ErrorResponse[] = [];
  public data: T | undefined = undefined;

  static fromData = <T>(data: T) => {
    const response = new ResponseViewModel<T>();
    response.data = data;
    return response;
  };

  static fromErrors = <T>(errors: string[], errorCode?: number) => {
    if (!errors || !errors.length) {
      throw new Error('Errors is undefined');
    }
    const response = new ResponseViewModel<T>();
    response.errors = errors.map((e) => ErrorResponse.fromMessage(e, errorCode));
    return response;
  };

  public get getErrors(): string[] {
    if (this.errors) {
      return this.errors.map((error) => error.message);
    }
    return [Messages.UnknownError];
  }

  public get hasErrors(): boolean {
    return !this.data || (this.errors && this.errors.length > 0); //|| this.hasDataErrors;
  }

  public get errorCode() {
    if (this.errors && this.errors.length > 0) {
      return this.errors[0].ErrorCode;
    }
    return undefined;
  }

  public getNullable<T>(request: ResponseViewModel<T>): ResponseViewModel<T> {
    return request ? request : new ResponseViewModel();
  }

  // For Internal Usage, Used in All TradeStation API's,
  // To support existing hasErrors, extended the same function.
  get hasDataErrors(): boolean {
    return this.data && (this.data as any).errors && (this.data as any).errors.length > 0;
  }
}
