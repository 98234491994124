export class SymbolDetail {
  week52High = -1;
  week52Low = -1;
  beta = -1;
  eps = -1;
  div = -1;
  yield = -1;
  name = '';
  symbol = '';
  volume = -1;
  last = -1;
  change = -1;
  percentageChange = -1;
  peRatio = -1;
  divPayDate = -1;
  sixmTrend = '';
  onemTrend = '';
  sentiment = '';
  technicalRank = -1;
  ivRank = -1;
  bid = -1;
  ask = -1;
  currency = '';

  public static sample = (item: SymbolDetail): SymbolDetail => {
    const data = new SymbolDetail();
    data.week52High = item.week52High;
    data.week52Low = item.week52Low;
    data.beta = item.beta;
    data.eps = item.eps;
    data.div = item.div;
    data.yield = item.yield;
    data.name = item.name;
    data.symbol = item.symbol;
    data.volume = item.volume;
    data.last = item.last;
    data.change = item.change;
    data.percentageChange = item.percentageChange;
    data.peRatio = item.peRatio;
    data.divPayDate = item.divPayDate;
    data.sixmTrend = item.sixmTrend;
    data.onemTrend = item.onemTrend;
    data.sentiment = item.sentiment;
    data.technicalRank = item.technicalRank;
    data.ivRank = item.ivRank;
    data.bid = item.bid;
    data.ask = item.ask;
    data.currency = item.currency;
    return data;
  };
}
