import {
  AccountSelectIconItemWidget,
  OPBoldTypograpghy,
  OPBoldTypograpghyforCreate,
  OPGrid,
  OPIconButton,
  OrangeButton,
  StyledDropdownItemWithBorder,
} from '../styled';
import { Card, Grid, IconButton, Menu, TextField, Tooltip, Typography } from '@mui/material';
import { EventType, WatchListsOperationTypes, WatchListsTypes } from '@op/shared/src/models/enums/enums';
import React, { useEffect, useState } from 'react';
import { WatchList, WatchLists } from '@op/shared/src/models';
import {
  accountState,
  filtersWatchListState,
  selectedSymbolState,
  selectedWatchListIdState,
  selectedWatchListState,
  watchListCrudOperationState,
  watchListsState,
} from '@op/shared/src/states';
import { styled, useTheme } from '@mui/material/styles';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AvatarWithText } from './watchlist-common-layouts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconComponent } from '../common';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { WatchlistService } from '@op/shared/src/services';
import addIcon from '@op/shared/assets/images/addIcon.svg';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { GuideItem } from '../side-menu';

interface IWatchListAccordianProps {
  setWatchListName: (v: string) => void;
  onReset: () => void;
  setWatchListErrorText: (v: string | undefined) => void;
  canManageGlobalB2BWatchList?: boolean;
  allowUserWatchlist?: boolean;
  watchListName: string;
  isChangingName: boolean;
  setIsChangingName: (v: boolean) => void;
  newWatchListlabel: string;
  setNewWatchListLabel: (v: string) => void;
}

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  maxHeight: 250,
  overflowY: 'scroll',
}));

interface InewWatchListAccoridanProps {
  id: any;
  expanded: any;
  setExpanded: any;
  isGlobal: boolean;
  item: WatchList[];
}

export const WatchListAccordian: React.FC<IWatchListAccordianProps> = ({
  setWatchListName,
  onReset,
  watchListName,
  setWatchListErrorText,
  canManageGlobalB2BWatchList,
  allowUserWatchlist,
  isChangingName,
  setIsChangingName,
  newWatchListlabel,
  setNewWatchListLabel,
}: IWatchListAccordianProps) => {
  const theme = useTheme();
  const account = useRecoilValue(accountState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setSelectedWatchListId = useSetRecoilState(selectedWatchListIdState);
  const [watchLists, setWatchLists] = useRecoilState(watchListsState);
  const selectedWatchList = useRecoilValue<WatchList | undefined>(selectedWatchListState);
  const [watchListOperation, setWatchListOperation] = useRecoilState(watchListCrudOperationState);
  const setNotifications = useSetRecoilState(notificationsState);
  const resetGlobalFilters = useResetRecoilState(filtersWatchListState);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [accordian, setAccordian] = React.useState<null | HTMLElement>(null);

  const canCreateNewWatchlist =
    watchListOperation === WatchListsOperationTypes.NEW || watchListOperation === WatchListsOperationTypes.NEWGLOBAL;

  useEffect(() => {
    selectedWatchList && setWatchListName(selectedWatchList.name);
  }, [selectedWatchList]);

  if (!watchLists || !selectedWatchList) {
    return <></>;
  }

  const logActivity = (controlType: string, controlName: string, eventType?: string, value?: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value ? value : '',
      eventType ? eventType : EventType.Click,
      selectedSymbol as string,
    );
  };

  const updateFlagState = async (watchListItem: WatchList) => {
    if (!selectedWatchList || !watchLists) {
      return;
    }
    try {
      const response: any = await WatchlistService.instance.bookmarkWatchList(
        watchListItem.id,
        !watchListItem.isBookmarked,
      );
      if (!response || !response.data) {
        setNotifications([{ type: 'error', content: 'Something wrong happened. Please try again.' }]);
        return;
      }
      const clonedWatchlist = WatchLists.fromSelf(watchLists);
      const watchListData = clonedWatchlist.data.map((w) => {
        w.isBookmarked = w.id === watchListItem.id ? !watchListItem.isBookmarked : false;
        return w;
      });
      clonedWatchlist.data = watchListData;
      setWatchLists(clonedWatchlist);
      setNotifications([{ type: 'success', content: 'Operation successful' }]);
    } catch (error) {
      setNotifications([{ type: 'error', content: 'error Something wrong happened. Please try again.' }]);
    }
  };

  const renderAddWatchlist = () => {
    const iconName = watchListOperation === WatchListsOperationTypes.NEWGLOBAL ? 'globalIcon' : 'userIcon';
    return (
      <OPGrid container alignItems={'center'}>
        <Grid
          item
          xs={2}
          container
          style={{
            height: '100%',
            backgroundColor: theme.palette.info.dark,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <IconComponent name={'watchlistIcon'} stroke={theme.palette.info.light} size={15} />
        </Grid>
        <Grid item xs={1} container>
          <IconComponent name={iconName} stroke={theme.palette.info.light} size={15} />
        </Grid>
        <Grid item xs={7}>
          <TextField
            style={{ width: '100%', margin: 2 }}
            autoFocus
            name="name"
            variant="standard"
            placeholder="Watchlist Name"
            onChange={(e) => {
              setWatchListErrorText(undefined);
              setNewWatchListLabel(e.target.value);
            }}
            value={newWatchListlabel}
            defaultValue={newWatchListlabel}
            autoComplete="off"
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <AvatarWithText backgroundColor={theme.palette.primary.main} text={Number('00')} />
        </Grid>
      </OPGrid>
    );
  };

  const renderGridItem = (watchListItem: WatchList, isGlobal: boolean, isSelected: boolean) => {
    const canShowDeleteIcon =
      ((isGlobal && canManageGlobalB2BWatchList) || !isGlobal) && watchListOperation === WatchListsOperationTypes.EDIT;
    return (
      <Grid
        container
        item
        justifyContent={'space-between'}
        alignItems="center"
        xs={12}
        onClick={() => {
          /*
            If watchListOperation is EDIT & User selects the global watchList
            Operation: set the watchListOperation to NONE
          */
          logActivity('watchListsSelectList', 'watchListsSelectList');
          if (!canShowDeleteIcon) {
            setWatchListOperation(WatchListsOperationTypes.NONE);
          }
          if (watchListOperation === WatchListsOperationTypes.EDIT) {
            setSelectedWatchListId(watchListItem.id);
            setExpanded(false);
            onClose();
            setWatchListErrorText(undefined);
            return;
          }
          setSelectedWatchListId(watchListItem.id);
          setWatchListOperation(WatchListsOperationTypes.NONE);
          resetGlobalFilters();
          setExpanded(false);
          onClose();
        }}>
        <Grid item xs={1}>
          <AccountSelectIconItemWidget isGlobal={isGlobal} isSelected={isSelected} />
        </Grid>
        <Grid
          container
          item
          // TODO: Recheck here the layout
          xs={11}
          justifyContent={'space-between'}
          alignItems="center">
          <Grid item container xs={canShowDeleteIcon ? 6.5 : 8}>
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                '&:hover': { color: theme.palette.mode === 'dark' && 'white' },
              }}
              className={isGlobal ? 'global-label-value' : 'local-label-value'}>
              {watchListItem.name.trim()}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            {/* {!isGlobal && (
              <Tooltip title={!watchListItem.isBookmarked ? 'Set to Default' : ''} placement="top" arrow>
                <IconButton
                  size="medium"
                  onClick={(e) => {
                    updateFlagState(watchListItem);
                  }}>
                  {watchListItem.isBookmarked ? (
                    <IconComponent name={'flagIcon'} stroke={theme.palette.primary.main} />
                  ) : (
                    <IconComponent name={'flagIcon'} stroke={'#999999'} hoverColor="black" />
                  )}
                </IconButton>
              </Tooltip>
            )} */}
          </Grid>
          <Grid item xs={1.5}>
            <Typography className={isGlobal ? 'global-count-value' : 'person-count-value'}>
              {watchListItem.quotes.length}
            </Typography>
          </Grid>
          {!canShowDeleteIcon ? null : (
            <Grid item justifyContent="center" display="grid" alignItems="center" xs={1.5} pl={1}>
              <IconButton
                size="medium"
                onClick={(e) => {
                  setSelectedWatchListId(watchListItem.id);
                  setWatchListOperation(WatchListsOperationTypes.DELETE);
                  setExpanded(false);
                }}>
                <IconComponent name={'deleteIcon'} stroke={'#999999'} hoverColor={theme.palette.error.main} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const getWatchListsItem = (isGlobal: boolean) => {
    const list = watchLists.data.filter((item) =>
      isGlobal ? item.type !== WatchListsTypes.NONE : item.type === WatchListsTypes.NONE,
    );
    return list;
  };

  const toggleEdit = () => {
    const operation =
      watchListOperation === WatchListsOperationTypes.EDIT
        ? WatchListsOperationTypes.NONE
        : WatchListsOperationTypes.EDIT;
    if (operation === WatchListsOperationTypes.NONE) {
      onReset();
    }
    setWatchListOperation(operation);
    logActivity('watchListSaveWatchListEdit', 'watchListSaveWatchListEdit');
  };

  const renderAccordianView = ({ id, expanded, setExpanded, isGlobal, item }: InewWatchListAccoridanProps) => {
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <Grid item xs={12}>
        <Accordion square expanded={expanded === `panel_${id}`} onChange={handleChange(`panel_${id}`)}>
          <AccordionSummary
            style={{ backgroundColor: isGlobal ? theme.palette.success.main : theme.palette.primary.main }}
            aria-controls={`panel_${id}d-content`}
            id={`panel_${id}d-header`}
            expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems={'center'}>
              <Grid item xs={1} container>
                <IconComponent name={'watchlistIcon'} stroke="#ffffff" size={15} />
              </Grid>
              <Grid item xs={1} container>
                <IconComponent name={isGlobal ? 'globalIcon' : 'userIcon'} stroke="white" size={15} />
              </Grid>
              <Grid item xs={6}>
                <OPBoldTypograpghy
                  style={{
                    color: '#fff',
                  }}>
                  {isGlobal ? 'Global' : 'Personal'}
                </OPBoldTypograpghy>
              </Grid>
              <Grid item xs={2}>
                <OPBoldTypograpghy
                  style={{
                    color: '#fff',
                  }}>
                  {item.length}
                </OPBoldTypograpghy>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {item &&
              item.map((item, index) => {
                return (
                  <Tooltip title={item.name} placement="top" arrow>
                    <StyledDropdownItemWithBorder
                      color="default"
                      id={`js-${isGlobal ? 'global' : 'personal'}-watchlist-${index}`}
                      key={item.id}
                      value={item.id}
                      selected={item.id === selectedWatchList?.id}>
                      {renderGridItem(item, isGlobal, item.id === selectedWatchList?.id)}
                    </StyledDropdownItemWithBorder>
                  </Tooltip>
                );
              })}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  const renderCreateWatchListView = (type: WatchListsOperationTypes) => {
    const isGlobal = type === WatchListsOperationTypes.NEWGLOBAL;
    const activity = isGlobal ? 'watchListAddGlobalB2BWatchList' : 'watchListAddWatchList';
    const icon = isGlobal ? 'globalIcon' : 'userIcon';
    const label = isGlobal ? 'Global' : 'Personal';
    if (!allowUserWatchlist || !account || !account.isEditWatchListClickable) {
      return;
    }
    return (
      <Grid item xs={12} alignItems="center">
        <Accordion
          disableGutters={true}
          onClick={() => {
            setWatchListOperation(type);
            logActivity(activity, activity);
            onClose();
          }}>
          <AccordionSummary
            style={{ backgroundColor: '#BFE0FA' }}
            expandIcon={
              <OPIconButton style={{ backgroundColor: '#fff' }}>
                <img src={addIcon} alt="" />
              </OPIconButton>
            }>
            <Grid container>
              <Grid item xs={1}>
                <IconComponent name={icon} stroke="black" size={15} />
              </Grid>
              <Grid item xs={11}>
                <OPBoldTypograpghyforCreate
                  id="js-watchlist-create"
                  style={{ paddingLeft: 5, color: theme.palette.mode === 'dark' && '#000' }}>
                  {`Create A New ${label} Watchlist`}
                </OPBoldTypograpghyforCreate>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      </Grid>
    );
  };

  /*
    TextField for Selected WatchList Name: readonly
    Editable-TextField for Selected WatchList Name: if operation-type is EDIT
  */
  const renderFirstRowTextField = () => {
    if (watchListOperation === WatchListsOperationTypes.EDIT) {
      return (
        <TextField
          id="js-watchlist-name-field"
          sx={{
            width: '100%',
            color: theme.palette.personalSelect.main,
          }}
          variant="standard"
          value={isChangingName ? watchListName : selectedWatchList?.name || ''}
          placeholder="Watchlist Name"
          onFocus={() => logActivity('watchListsNameEditorActivate', 'watchListsNameEditorActivate')}
          onBlur={() =>
            logActivity('watchListsNameEditorDectivate', 'watchListsNameEditorDectivate', 'blur', watchListName)
          }
          onChange={(event) => {
            setWatchListName(event.target.value);
            setIsChangingName(true); //TODO: should false wen return to same name
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      );
    }
    return (
      <TextField
        id="js-watchlist-name-field"
        sx={{
          width: '100%',
          input: {
            color:
              watchListOperation === WatchListsOperationTypes.DELETE
                ? theme.palette.error.main
                : selectedWatchList.type === 'None'
                ? theme.palette.personalSelect.main
                : theme.palette.globalSelect.main,
            textOverflow: 'ellipsis',
          },
        }}
        variant="standard"
        value={selectedWatchList.name}
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
          readOnly: true,
        }}
      />
    );
  };

  const getBackgroundColor = () => {
    if (watchListOperation === WatchListsOperationTypes.DELETE) {
      return theme.palette.error.main;
    }
    if (accordian) {
      return theme.palette.primary.main;
    }
    return theme.palette.secondary.dark;
  };

  const onClose = (event?: { currentTarget: any }) => {
    setAccordian(accordian || !event ? null : event.currentTarget);
  };
  const renderFirstRow = () => {
    return (
      <OPGrid
        container
        alignItems={'center'}
        onClick={() => watchListOperation !== WatchListsOperationTypes.EDIT}
        sx={{
          height: 35,
          borderColor: getBackgroundColor(),
        }}>
        <Grid item xs={1.5} container>
          <div
            style={{
              height: 33, // Height maintaine as 33 bcz total height - 2 * border width
              width: 35,
              backgroundColor: theme.palette.info.dark,
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
            }}>
            <IconComponent
              name={'watchlistIcon'}
              stroke={theme.palette.info.light}
              size={25}
              style={{ padding: '8px 0 0 8px' }}
            />
          </div>
        </Grid>
        <Grid item xs={1} container>
          {watchListOperation === WatchListsOperationTypes.DELETE || selectedWatchList.type === 'None' ? (
            <IconComponent
              name="userIcon"
              stroke={
                watchListOperation === WatchListsOperationTypes.DELETE
                  ? theme.palette.error.main
                  : theme.palette.personalSelect.light
              }
              size={18}
              style={{ margin: 2 }}
            />
          ) : (
            <IconComponent
              name="globalIcon"
              stroke={theme.palette.globalSelect.light}
              size={18}
              style={{ margin: 2 }}
            />
          )}
        </Grid>
        <Grid item xs={6.5}>
          {renderFirstRowTextField()}
        </Grid>
        <Grid item xs={2} id="js-watchlist-quotes-length">
          <AvatarWithText
            backgroundColor={
              watchListOperation === WatchListsOperationTypes.DELETE
                ? theme.palette.error.main
                : selectedWatchList.type === 'None'
                ? theme.palette.primary.main
                : theme.palette.globalSelect.dark
            }
            text={selectedWatchList.quotes.length}
          />
        </Grid>
        <Grid item xs={1} container>
          <ArrowDropDownIcon
            onClick={onClose}
            style={{
              color: theme.palette.selectAndTextField.main,
              transform: accordian ? 'rotate(-180deg)' : 'rotate(0deg)',
            }}
          />
        </Grid>
      </OPGrid>
    );
  };

  const renderWatchListField = () => {
    if (canCreateNewWatchlist) {
      return renderAddWatchlist();
    }
    const isWatchListEditable =
      (selectedWatchList.type === 'None' || canManageGlobalB2BWatchList) && allowUserWatchlist;
    return (
      <Grid container xs={12} justifyContent="space-between">
        <Grid item container xs={isWatchListEditable ? 10 : 12}>
          {renderFirstRow()}
        </Grid>
        {isWatchListEditable && <GuideItem selector=".whatchListNameEditor_helpPinPlaceholder" />}
        <Grid item container justifyContent={'flex-end'} xs={2} id="js-watchlist-toggle-edit-icon">
          {isWatchListEditable && (
            <OrangeButton
              disableRipple={watchListOperation === WatchListsOperationTypes.EDIT}
              disabled={account ? !account.isEditWatchListClickable : false}
              isSelected={watchListOperation === WatchListsOperationTypes.EDIT}
              onClick={toggleEdit}>
              <IconComponent name="editIcon" fill={theme.palette.info.light} />
            </OrangeButton>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      {accordian && (
        <Menu
          anchorEl={accordian}
          open={Boolean(accordian)}
          onClose={onClose}
          PaperProps={{ sx: { '.MuiMenu-paper': { padding: 0 }, overflow: 'visible' } }}
          MenuListProps={{ sx: { py: 0 } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Card style={{ maxWidth: 275, minWidth: 275 }}>
            {renderAccordianView({ id: 0, expanded, setExpanded, isGlobal: true, item: getWatchListsItem(true) })}
            {canManageGlobalB2BWatchList && renderCreateWatchListView(WatchListsOperationTypes.NEWGLOBAL)}
            {allowUserWatchlist &&
              renderAccordianView({ id: 1, expanded, setExpanded, isGlobal: false, item: getWatchListsItem(false) })}
            {renderCreateWatchListView(WatchListsOperationTypes.NEW)}
          </Card>
        </Menu>
      )}
      {renderWatchListField()}
    </Grid>
  );
};
