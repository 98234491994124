import { ICommissionProps } from '.';

export class EditCommission implements ICommissionProps {
  stockTradeCommission = 0;
  perOptionCommission = 0;
  perShareCommission = 0;

  public static fromJson = (data: EditCommission): EditCommission => {
    const model = new EditCommission();
    model.stockTradeCommission = data.stockTradeCommission;
    model.perOptionCommission = data.perOptionCommission;
    model.perShareCommission = data.perShareCommission;
    return model;
  };
}
