import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

export const TradeIdeaLoaderWidget: React.FC = () => {
  return (
    <Grid container columnSpacing={0.5}>
      <Grid item xs="auto">
        <Skeleton animation="wave" variant="rectangular" width={5} height={70} />
      </Grid>
      <Grid container item xs={11} alignItems="stretch">
        <Grid container item xs={12} columnSpacing={1} alignItems="center">
          <Grid item xs={6}>
            <Skeleton animation="wave" variant="text" height={20} />
          </Grid>
          <Grid item xs={5}>
            <Skeleton animation="wave" variant="text" width="70%" height={20} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton animation="wave" variant="rectangular" width={15} height={15} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="text" height={15} width="50%" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="text" height={20} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const WhatLoaderWidget: React.FC = () => {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Skeleton animation="wave" variant="rectangular" height={100} width="100%" />
      </Grid>
      {Array(8)
        .fill(1)
        .map((_, i) => (
          <Grid item xs={12} key={i}>
            <TradeIdeaLoaderWidget />
          </Grid>
        ))}
    </Grid>
  );
};
