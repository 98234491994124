import { guardRecoilDefaultValue } from './trade/trade-states';
import { atom, selector } from 'recoil';
import { Globalization, LanguageType } from '../models';
import ApplicationContext from '../models/how/application-context';

/** @deprecated alias for backwards compatibility. Please use {@link globalizationState} */
export const languageState = atom({
  key: 'language',
  default: LanguageType.English,
});

export const globalizationState = selector<Globalization | undefined>({
  key: 'globalizationStateKey',
  get: ({ get }) => {
    return get(globalizationCachedState);
  },
  set: ({ set }, newGlobalization) => {
    if (guardRecoilDefaultValue(newGlobalization) || !newGlobalization) {
      return;
    }
    ApplicationContext.globalization = newGlobalization.active;
    set(globalizationCachedState, newGlobalization);
  },
});

const globalizationCachedState = atom<Globalization | undefined>({
  key: 'globalizationCachedStateKey',
  default: undefined,
});
