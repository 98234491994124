import { atomFamily, DefaultValue, selector } from 'recoil';
import { PowerhouseProAdditionalPositionDataModel } from '../hubs/powerhouse-pro-additional-position-data-model';
import ApplicationContext from '../models/how/application-context';

export const portolioPositionDataAtomFamilyState = atomFamily<
  PowerhouseProAdditionalPositionDataModel | undefined,
  string
>({
  key: 'portolioPositionDataAtomFamilyStateKey',
  default: undefined,
});

export const portfolioPositionsDataHubState = selector<PowerhouseProAdditionalPositionDataModel[] | undefined>({
  key: 'portfolioPositionsDataHubStateKey',
  get: ({ get }) => {
    throw new Error('This is only updater');
  },
  set: ({ set, get }, newPositionData) => {
    if (!newPositionData || newPositionData instanceof DefaultValue) {
      return;
    }
    for (let positionData of newPositionData) {
      const key = `${positionData.portfolioCombinationHash}_${positionData.symbol}`;
      set(portolioPositionDataAtomFamilyState(key), positionData);
    }
  },
});

export const portfolioSubscribeToAdditionalData = async (
  symbol: string,
  accountId: number | string,
  portfolioCombinationHash: string,
) => {
  if (!symbol) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  await powerhouseProHub.portfolioSubscription.subscribe([
    {
      Symbol: symbol,
      AccountId: accountId,
      PortfolioCombinationHash: portfolioCombinationHash,
    },
  ]);
};

export const portfolioUnsubscribeToAdditionalData = async (
  symbol: string,
  accountId: number | string,
  portfolioCombinationHash: string,
) => {
  if (!symbol) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }

  await powerhouseProHub.portfolioSubscription.unsubscribe([
    {
      symbol: symbol,
      accountId: accountId,
      portfolioCombinationHash: portfolioCombinationHash,
    },
  ]);
};
