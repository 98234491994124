import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme, styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { ScrollableBox } from '../styled';

const OPDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

interface OPDialogWidgetProps {
  open: boolean;
  title: string | ReactNode;
  imgsrc?: string;
  isLoaded?: boolean;
  dialogContent?: ReactNode;
  dialogAction: ReactNode;
  onHandleClose: () => void;
  setLoaded?: (value: boolean) => void;
  dialogContentHeight?: number | undefined;
  dialogTitleStyle?: SxProps<Theme>;
  dialogdividers?: boolean;
  rootPadding?: boolean;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  dialogTitleStyle?: SxProps<Theme>; //TODO Not using Needs to remove
}

const OPDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, dialogTitleStyle, ...other } = props;

  return (
    <DialogTitle
      sx={{
        ...dialogTitleStyle,
        m: 0,
        p: 2,
        textAlign: 'center',
      }}
      {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon sx={{ ...dialogTitleStyle }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const OPDialogWidget: React.FC<OPDialogWidgetProps> = ({
  imgsrc,
  onHandleClose,
  title,
  open,
  isLoaded,
  dialogContent,
  dialogAction,
  setLoaded,
  dialogContentHeight,
  dialogTitleStyle,
  dialogdividers,
  rootPadding,
}: OPDialogWidgetProps) => {
  const theme = useTheme();
  const handleClose = () => {
    onHandleClose();
  };

  return (
    <OPDialog
      sx={{
        '& .MuiDialogActions-root': {
          padding: rootPadding ? theme.spacing(0) : theme.spacing(1),
        },
      }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}>
      <OPDialogTitle dialogTitleStyle={dialogTitleStyle} id="customized-dialog-title" onClose={handleClose}>
        {title}
      </OPDialogTitle>
      <DialogContent dividers={dialogdividers ? false : true}>
        {dialogContent ? (
          <ScrollableBox sx={{ backgroundColor: 'inherit' }} height={dialogContentHeight}>
            {dialogContent}
          </ScrollableBox>
        ) : (
          <div>
            {isLoaded ? null : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CircularProgress />
              </div>
            )}
            <div style={isLoaded ? {} : { display: 'none' }}>
              <img src={imgsrc} width="100%" height="100%" onLoad={() => setLoaded && setLoaded(true)} alt="" />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>{dialogAction}</DialogActions>
    </OPDialog>
  );
};
