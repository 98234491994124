import { IWidgetCustomization } from '../customization';
import Combination from './combination';
import DateTimeHelper from './date-time-helper';
import { IncomeCombination } from './income-combination';
import { TradeTicket } from './trade-ticket';

/*
    Common function to create trade ticket object to be passed in embedder.
*/
export const createTradeTicketObject = (
  combination: Combination | IncomeCombination,
  customization: IWidgetCustomization,
) => {
  let tradeTicket = new TradeTicket();
  tradeTicket.refreshTrade(combination, undefined);

  const options = (tradeTicket.optionLegs || []).map((p) => {
    return {
      ask: p.ask,
      bid: p.bid,
      price: p.price,
      quantity: p.quantity,
      expiry: DateTimeHelper.resolveExpiry(p.expiry).toISOString(),
      strike: p.strike,
      actionName: p.getActionName(),
      isToClose: p.isToClose,
      type: p.type,
      showShortCodeInTradeTicket: p.showShortCodeInTradeTicket,
    };
  });
  const securities = (tradeTicket.securityLegs || []).map((p) => {
    return {
      ask: p.ask,
      bid: p.bid,
      price: p.price,
      quantity: p.quantity,
      actionName: p.getActionName(),
      isToClose: p.isToClose,
      type: p.type,
      showShortCodeInTradeTicket: p.showShortCodeInTradeTicket,
    };
  });
  /**
   * !Important
   * Condition for Income covered call
   * If canadian / US symbol loaded in covered call
   * strategy name should be sent as "Call" as from OP only call leg is sent in post event.
   * Reason is in post event Call option is only sent as
   * in OP asuumption is shares are owned.
   */
  let strategyName = tradeTicket.strategyName;
  if (
    !customization.allowStockPlusOptionforCASymbol &&
    tradeTicket.combination.isIncome &&
    tradeTicket.strategyName === 'Covered Call'
  ) {
    strategyName = 'Call';
  }

  const price = tradeTicket.combination.costWithoutOwned();

  const tradeTicketObj = {
    symbol: tradeTicket.combination.symbol,
    optionLegs: options,
    securityLegs: securities,
    strategyName: strategyName,
    price: price,
    unitPrice: Math.abs(tradeTicket.avgPremium()),
    priceSign: Math.sign(price),
    isCustomStrategy: !!tradeTicket.eventStrategyName.match(/custom strategy/i),
  };

  return tradeTicketObj;
};
