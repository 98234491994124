export class Resources {
  public static freeze = (o: any) => o;

  public resources = new Resources();

  static Combination = Resources.freeze({
    ToOpen: 'to Open',
    ToClose: 'to Close',
    NoChangesMade: 'No Changes Made',

    Roll: {
      // filled Up Later by enums
    },

    PlainEnglishHtml: {
      Common: 'how.plainEnglishPanel.combinationText.plainEnglishHtml.common',
      ExpiryPart: 'how.plainEnglishPanel.combinationText.plainEnglishHtml.expiryPart',
      POPSuffix: 'how.plainEnglishPanel.combinationText.plainEnglishHtml.popSuffix',
    },
  });

  static MonthsNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  static Merrill = Resources.freeze({
    uncoveredIncomeWarning:
      'Please be aware that uncovered option orders are not considered an Income strategy with Merrill Edge.',
    riskAndInvestmentWarning:
      'Max Loss does not consider delivery of shares in the event the option expires in the money.',
    PlainEnglishHtml: {
      Common:
        '<p>This is a <b>{sentimentProfile}</b> strategy with <b>{riskDesc}</b> and <b>{rewardDesc}</b>.</p>' +
        '<p>This strategy will profit if the stock closes <b>{breakevenDesc}</b>{expiryPart}.</p>',
      ExpiryPart: ' by <b>{expiryFormatted}</b>',
      POPSuffix:
        '<p>There is a <b>{probabilityOfProfitDisplayed}</b> probability (See <a href="#" class="linkToMethodology">Methodology</a>) this will happen.</p>',
    },
  });

  static coveredCallAlternativeIncomeFoundWarning =
    "OptionsPlay has not found a {optionType} based on your {defaultTimeFrame} term {defaultAggressiveness} preferences, we're displaying to you a {realTimeFrame} term {realAggressiveness} as the next best alternative.";
}
