import { Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { accountState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { IconComponent, OPDialogWidget } from '../common';

export interface EmailShareProps {
  onHandleClose: () => void;
  title: string;
  imgsrc: string;
  symbol: string;
  sentiment: any;
  screenshotid: any;
}

export const EmailClientShare: React.FC<EmailShareProps> = (props: EmailShareProps) => {
  const { onHandleClose, title, imgsrc, symbol, screenshotid } = props;
  const account = useRecoilValue(accountState);
  const value = title.substring(0, title.indexOf('@'));
  const [loaded, setLoaded] = React.useState(false);
  const theme = useTheme();
  const openEmailClient = () => {
    if (!account || !account.securityModel) {
      return;
    }
    const emailSubject = `${account.securityModel?.displayName} shared a ${symbol} trade with you`;
    const element = 'You can view this trade now by clicking this link';
    const element2 = 'You can also view and download a screenshot of the trade from here:';
    const Shareurl = `${window.location.origin}/share/${screenshotid}`;
    const screenShot = `${window.location.origin}/screenshot/${screenshotid}`;
    const mailto: string = `mailto:?subject= ${emailSubject} &body= ${emailSubject} %0D%0A %0D%0A ${element} %0D%0A %0D%0A ${Shareurl} %0D%0A %0D%0A ${element2} %0D%0A %0D%0A ${screenShot}`;
    window.location.href = mailto;
  };
  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item>
          <Button autoFocus onClick={openEmailClient} variant="contained" color="primary">
            <Typography variant="button">Open Your Email Client</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };
  const renderDialogContent = () => {
    return (
      <div>
        <div>
          {loaded ? null : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          )}
          <img
            style={loaded ? {} : { display: 'none' }}
            src={imgsrc}
            width="100%"
            height="100%"
            onLoad={() => setLoaded(true)}
            alt=""
          />
        </div>
        <br />
        <Typography variant="body1">
          You should see your email client opened. If it does not happen click the following button
        </Typography>
      </div>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={1} md={1}>
          <IconComponent
            name="emailIcon"
            style={{ position: 'absolute', left: 8, top: 16 }}
            stroke={theme.palette.info.light}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1">Share : {value}</Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <OPDialogWidget
        open={true}
        onHandleClose={onHandleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </div>
  );
};
