import { Grid, Paper, SwipeableDrawer, Theme, Typography, useTheme } from '@mui/material';
import { customizationState } from '@op/shared/src/states';
import React, { ReactFragment, ReactNode, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { HeightWidget, NavigationWidget } from '.';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';
import { useMinWidthBreakpoint } from '../states/use-min-width-breakpoint';

interface Props {
  children: ReactNode | ReactFragment;
  title: string;
}
export const ReportsContainer: React.FC<Props> = ({ children, title }: Props) => {
  const isMinWidthBreakpoint = useMinWidthBreakpoint();
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const theme = useTheme<Theme>();
  const customization = useRecoilValue(customizationState);

  const { t } = useContext(LocalizationContext);

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  if (!customization) {
    return;
  }

  const DrawerContent = () => {
    return (
      <Grid container rowSpacing={0.5}>
        <GuideItem selector=".whatPanelSwitcher_helpPinPlaceholder" />
        <Grid item xs={12}>
          <NavigationWidget />
        </Grid>
        <div className="sentiment_helpPinPlaceholder" />
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    );
  };

  if (isMinWidthBreakpoint) {
    return (
      <HeightWidget noScroll height={customization?.maxHeight}>
        {DrawerContent()}
      </HeightWidget>
    );
  }

  return (
    <>
      <Paper
        sx={{
          background: theme.palette.collapsible.main,
          borderColor: theme.palette.collapsible.light,
          borderWidth: 1,
          position: 'relative',
          height: customization?.maxHeight,
          cursor: 'pointer',
          '&:hover': {
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
          },
          width: '40px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
        variant="outlined"
        id="js-open-trade-ideas"
        onClick={handleOpenSettings}>
        <Typography
          variant="h6"
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: 'left',
            position: 'absolute',
            left: 20,
            top: customization.isEmbeddingPlatform ? 500 : 600,
            zIndex: 10,
            textAlign: 'center',
            width: 300,
          }}>
          {t(title)}
        </Typography>
      </Paper>
      <SwipeableDrawer
        variant={'temporary'}
        open={openCollapse}
        anchor="left"
        onClose={() => setOpenCollapse(!openCollapse)}
        onOpen={() => setOpenCollapse(!openCollapse)}
        transitionDuration={{
          enter: 500,
          exit: 500,
        }}
        PaperProps={{
          sx: {
            width: 300,
          },
        }}>
        <HeightWidget noScroll>{DrawerContent()}</HeightWidget>
      </SwipeableDrawer>
    </>
  );
};
