import { UserActivityHubConnection } from './user-activity-hub-connection';

export interface IUserActivity {
  controlType: string;
  controlName: string;
  value: any;
  event: string;
  dateAndTimeOfAccess: Date;
  symbolContext: string;
  version: string;
}

export interface IUserActivityHub {
  connect: () => Promise<any>;
  logActivity: (controlType: string, controlName: string, value: any, event: string, symbol: string) => void;
  pushActivities: () => Promise<void>;
}

export class UserActivityHub implements IUserActivityHub {
  private _socketConnection: UserActivityHubConnection | undefined;
  private readonly ACTIVITY_KEY_NAME = 'activityItem';

  private constructor() {}

  static fromSocketConnection = (token: string) => {
    const model = new UserActivityHub();
    model._socketConnection = UserActivityHubConnection.fromHubName(token);
    return model;
  };

  get socketConnection() {
    if (!this._socketConnection) {
      throw new Error('socketConnection is undefined');
    }
    return this._socketConnection;
  }

  connect = async () => {
    await this.socketConnection.connect();
    const intervalId = setInterval(() => {
      this.pushActivities();
    }, 10 * 1000);
    return intervalId;
  };

  logActivity = (controlType: string, controlName: string, value: any, event: string, symbol: string) => {
    const date = new Date();
    const item: IUserActivity = {
      controlType: controlType,
      controlName: controlName,
      value: value,
      event: event,
      dateAndTimeOfAccess: date,
      symbolContext: symbol,
      version: process.env.REACT_APP_VERSION || '',
    };
    localStorage.setItem(`${this.ACTIVITY_KEY_NAME}${date.getTime()}`, JSON.stringify(item));
  };

  pushActivities = async () => {
    const activityKeys = [];
    const activities: IUserActivity[] = [];
    const localStorageKeys = Object.keys(localStorage);
    for (let key of localStorageKeys) {
      if (key.indexOf(this.ACTIVITY_KEY_NAME) > -1) {
        activityKeys.push(key);
        const activity = localStorage.getItem(key);
        if (!activity) {
          continue;
        }
        activities.push(JSON.parse(activity));
      }
    }
    if (activities.length === 0) {
      return;
    }
    for (let removingKey of activityKeys) {
      localStorage.removeItem(removingKey);
    }
    await this.socketConnection.invoke('SaveActivity', {
      dateAndTimeOfAccess: new Date(),
      list: activities,
    });
  };
}
