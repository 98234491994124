import { Button, Grid, useTheme } from '@mui/material';
import { selectedPlansState, stepperUpdaterState } from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OPBoldTypograpghy } from '../styled';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';
import LocalizationContext from '../react-i18next/localization-context';

interface Props {
  title?: string;
  cardInfo?: boolean;
}

export const SubscriptionHeaderTabWidget: React.FC<Props> = ({ title = '', cardInfo = false }) => {
  const { t } = useContext(LocalizationContext);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const selectedPlans = useRecoilValue(selectedPlansState);
  const theme = useTheme();
  const renderContent = () => {
    return (
      <Grid item xs={12}>
        {title && (
          <Button
            size="large"
            variant="contained"
            disableRipple
            disableElevation
            sx={{ px: 4, cursor: 'text', height: 40, ':hover': { backgroundColor: theme.palette.primary.main } }}>
            <OPBoldTypograpghy variant="body1">{title}</OPBoldTypograpghy>
          </Button>
        )}

        <Button
          variant="text"
          onClick={() => setActiveStep('changePlan')}
          sx={{
            padding: 0,
            ml: 2,
            height: 40,
            color: theme.palette.greyShades.contrastText,
            ':hover': { color: theme.palette.primary.main },
          }}>
          <OPBoldTypograpghy variant="subtitle2">{t('subscriptions.changePlan')}</OPBoldTypograpghy>
        </Button>
        {cardInfo && (
          <Button
            disabled={selectedPlans.addOns.length === 0}
            variant="text"
            sx={{ padding: 0, height: 40 }}
            onClick={() => setActiveStep('addOn')}>
            <OPBoldTypograpghy variant="subtitle2">{`/ ${t('subscriptions.addOn')}`}</OPBoldTypograpghy>
          </Button>
        )}
      </Grid>
    );
  };

  return <SubscriptionHeaderWidget label={renderContent()} />;
};
