import { IEditResponseProps } from '.';

export class EditResponse implements IEditResponseProps {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: EditResponse): EditResponse => {
    const model = new EditResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
