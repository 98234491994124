import { Box, Button, Grid, Typography, debounce, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent, OPDialogWidget } from '../common';
import { OPBoldTypograpghy } from '../styled';
import { ILeg, IOrder } from './models';
import { cancelOrder } from './services';
import { tsEnvironmentState, tsOrdersDataState } from './states/trade-station-states';
type Props = {
  setOpen: (open: boolean) => void;
  selectedOrderId: string;
};

export const TsCancelOrderWidget: React.FC<Props> = ({ setOpen, selectedOrderId }: Props) => {
  const theme = useTheme();
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const resetOrders = useResetRecoilState(tsOrdersDataState);
  const orders = useRecoilValue(tsOrdersDataState);
  const setNotifications = useSetRecoilState(notificationsState);

  if (!orders || orders.length === 0) {
    return;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const renderSelectedOrderTitle = (orders: IOrder[]) => {
    const order = orders.find((o) => o.orderID === selectedOrderId);
    if (!order) {
      return <OPBoldTypograpghy>'Order ID not found'</OPBoldTypograpghy>;
    }
    return <Typography component={'span'}>{generateTitle(order)}</Typography>;
  };

  const renderPrice = (orders: IOrder) => {
    if (orders.limitPrice !== '' && orders.stopPrice !== '') {
      return `Limit Price: ${orders.limitPrice} Stop Price: ${orders.stopPrice}`;
    } else if (orders.limitPrice !== '') {
      return `Limit Price: ${orders.limitPrice}`;
    } else if (orders.stopPrice !== '') {
      return `Stop Price: ${orders.stopPrice}`;
    }
    return `${orders.orderType}`;
  };
  const generateTitle = (order: IOrder) => {
    const price = renderPrice(order);

    const totalstring = order.legs.map(
      (leg: ILeg) => `${leg.buyOrSell} ${leg.quantityOrdered} ${leg.symbol} @ ${price}`,
    );
    return totalstring.map((str, index) => (
      <Typography sx={{ fontWeight: '700' }} key={index} marginY={0.5}>
        {str}
      </Typography>
    ));
  };

  const handleCancelOrder = async () => {
    const response = await cancelOrder(tsEnv, selectedOrderId);
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
      setOpen(false);
      return;
    }
    setNotifications([{ type: 'success', content: response.data.message, isTradeStation: true }]);
    resetOrders();
    setOpen(false);
    return;
  };

  const debouncedOnCancelOrder = debounce(handleCancelOrder, 1000);

  const renderDialogTitle = () => {
    return (
      <Grid container>
        <IconComponent name="tsLogo" />
        <OPBoldTypograpghy variant="h6" style={{ marginLeft: 8, color: '#fff' }}>
          {`Cancel Order - ${selectedOrderId}`}
        </OPBoldTypograpghy>
      </Grid>
    );
  };

  const renderDialogAction = () => {
    return (
      <Grid container marginBottom={2} marginLeft={2}>
        <Grid item xs={6} textAlign={'center'}>
          <Button variant="text" onClick={handleClose}>
            <OPBoldTypograpghy sx={{ color: '#999999' }}>Do Not Cancel</OPBoldTypograpghy>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: theme.palette.error.main,
              ':hover': {
                backgroundColor: theme.palette.error.main,
              },
            }}
            onClick={debouncedOnCancelOrder}>
            <OPBoldTypograpghy>Proceed</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };
  const renderDialogContent = () => {
    return (
      <Box marginTop={2} textAlign={'center'} sx={{ width: '320px', justifyContent: 'center' }}>
        <OPBoldTypograpghy>Would you like to cancel the following order?</OPBoldTypograpghy>
        {renderSelectedOrderTitle(orders)}
      </Box>
    );
  };
  return (
    <Box width={50} height={50}>
      <OPDialogWidget
        open={true}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
        dialogdividers
        dialogTitleStyle={{
          backgroundColor: theme.palette.error.main,
          color: '#fff',
        }}
      />
    </Box>
  );
};
