import { IDeleteProps } from '.';

export class DeleteWatchlist implements IDeleteProps {
  list = -1;

  public static fromJson = (data: DeleteWatchlist): DeleteWatchlist => {
    const model = new DeleteWatchlist();
    model.list = data.list;
    return model;
  };
}
