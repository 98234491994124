import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, useTheme } from '@mui/material';
import { IData, PortfolioCombination } from '@op/shared/src/models';
import { AlertType } from '@op/shared/src/models/enums/enums';
import {
  portfolioAlertsByAccountAndCombinationState,
  portfolioCombinationAlertOrNotificationSelectedState,
  portfolioNotificationsByAccountAndCombinationState,
} from '@op/shared/src/states/portfolio-alerts-hub-states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

export type IPortfolioCombinationAlertWidgetProps = IData<PortfolioCombination>;

export const PortfolioCombinationAlertWidget: React.FC<IPortfolioCombinationAlertWidgetProps> = ({
  data,
}: IPortfolioCombinationAlertWidgetProps) => {
  const combination = data;
  const alerts = useRecoilValue(portfolioAlertsByAccountAndCombinationState(combination.hash));
  const notifications = useRecoilValue(portfolioNotificationsByAccountAndCombinationState(combination.hash));
  const [porfolioCombinationSelectedAlertIcon, setPorfolioCombinationSelectedAlertIcon] = useRecoilState(
    portfolioCombinationAlertOrNotificationSelectedState,
  );
  const theme = useTheme();

  const renderAlertIcon = () => {
    if (!alerts || alerts.length === 0) {
      return null;
    }
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        color={theme.palette.success.main}
        style={{ width: 12 }}
        onClick={() => {
          const isShowing = porfolioCombinationSelectedAlertIcon?.alertType === AlertType.ALERT;
          if (isShowing) {
            setPorfolioCombinationSelectedAlertIcon(undefined);
            return;
          }
          setPorfolioCombinationSelectedAlertIcon({
            alertType: AlertType.ALERT,
            combinationHash: combination.hash,
          });
        }}
      />
    );
  };

  const renderNotificationIcon = () => {
    if (!notifications || notifications.length === 0) {
      return null;
    }
    return (
      <FontAwesomeIcon
        icon={faInfoCircle}
        color={theme.palette.primary.main}
        style={{ width: 12 }}
        onClick={() => {
          let isShowing = porfolioCombinationSelectedAlertIcon?.alertType === AlertType.NOTIFICATION;
          if (isShowing) {
            setPorfolioCombinationSelectedAlertIcon(undefined);
            return;
          }
          setPorfolioCombinationSelectedAlertIcon({
            alertType: AlertType.NOTIFICATION,
            combinationHash: combination.hash,
          });
        }}
      />
    );
  };

  return (
    <Grid container px={1} columnSpacing={1} mt={1}>
      <Grid item>{renderAlertIcon()}</Grid>
      <Grid item>{renderNotificationIcon()}</Grid>
    </Grid>
  );
};
