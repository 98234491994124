import { Filters, IFilter } from '@op/shared/src/models';
import { AlertFilters } from '@op/shared/src/models/portfolio/portfolio-filter';
import {
  filtersPortfoliosState,
  notificationByAccountsState,
  selectedNotificationFilterState,
} from '@op/shared/src/states/portfolio-alerts-hub-states';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OPIconSelectComponentWidget } from '../styled';

export const PortfolioNotificationsCountWidget: React.FC = () => {
  const filterName = 'notification';
  const notificationByAccounts = useRecoilValue(notificationByAccountsState);
  const [portfolioFilters, setPortfolioFilters] = useRecoilState(filtersPortfoliosState);
  const [selectedAlert, setSelectedAlert] = useRecoilState(selectedNotificationFilterState);
  const alertFilters = new AlertFilters([]);
  let filters = portfolioFilters?.data.map((f) => ({ ...f }));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!selectedAlert) {
      return;
    }
    const alertScansData = alertFilters.data.map((d) => d.text);
    const scanFilter: IFilter =
      selectedAlert.value !== '' ? selectedAlert : { name: filterName, value: alertScansData };
    setSelectedAlert(scanFilter);
  }, []);

  const debouncedOnHandleChange = useMemo(() => debounce(setPortfolioFilters, 1000), []);

  if (!notificationByAccounts || !notificationByAccounts.alert) {
    return null;
  }

  const getFilteredByAll = (value: string[]) => {
    /**
     * value can be empty
     * value contains  all
     * value not contain all
     */
    if (value.includes('all')) {
      return notificationByAccounts.alert.map((m) => m.key);
    }
    return [];
  };

  const onFilterChange = (filter: IFilter) => {
    if (!filter || !filters) {
      return;
    }
    if (!filters.length) {
      filters = [filter];
      return;
    }
    const filterIndex = filters.findIndex((f) => f.name === filter.name);
    if (filterIndex === -1) {
      filters = [...filters, filter];
      return;
    }
    filters[filterIndex] = filter;
  };

  const getHashValues = (scanFilter: IFilter) => {
    const formattedFilter = scanFilter.value.toString().toLowerCase().trim().split(',');
    const selectedALert = notificationByAccounts.alert.filter((f) => {
      return formattedFilter.some((rule) => {
        return rule.toLowerCase().trim() === f.key.toLowerCase().trim();
      });
    });
    return selectedALert
      .map((x) => x.value.substring(0, x.value.length - 1).trim())
      .join(',')
      .split(',');
  };

  /**
   * There are three scenarios
   * 1) Selcting one or multiple- handled with "names of alert" as key
   * 2) Selecting All text - handled with 'all' as key
   * 3) Nothing selected equals to intial load/ on click clear button
   *    in dropdown - handled with '' as key
   */
  const handleChange = (selectedFilters: string[]) => {
    const appliedFilters = {
      name: filterName,
      value: selectedFilters.includes('all') ? getFilteredByAll(selectedFilters) : selectedFilters,
    };
    const selectedhashValues = getHashValues(appliedFilters);
    onFilterChange({
      name: filterName,
      value: selectedhashValues,
    });
    setSelectedAlert(appliedFilters);
    debouncedOnHandleChange(new Filters(filters));
  };

  const clearAlertFilter = () => {
    let scanFilter: IFilter = {
      name: filterName,
      value: '',
    };
    setSelectedAlert(scanFilter);
    onFilterChange(scanFilter);
    debouncedOnHandleChange(new Filters(filters));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <OPIconSelectComponentWidget
      type={'notification'}
      open={open}
      data={notificationByAccounts}
      selectedValues={selectedAlert}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleChange={(e) => handleChange(e)}
      onClearFilter={clearAlertFilter}
    />
  );
};
