declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    bncThreePanel: true;
    bncTwoPanel: true;
  }
}

export const OptionsPlayBreakpoints = {
  xs: 0,
  sm: 768,
  md: 1366,
  lg: 1536,
  xl: 1920,
  bncThreePanel: 1240,
  bncTwoPanel: 990,
};

export const fontSizeMediaQuery = (breakpoint: keyof typeof OptionsPlayBreakpoints, minWidth = true) => {
  return `@media (${minWidth ? 'min' : 'max'}-width: ${OptionsPlayBreakpoints[breakpoint]}px)`;
};
