import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { customizationState, flippedState, tradeSimulatorOperationTypeState } from '@op/shared/src/states';
import React, { ReactNode, Suspense, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

const StrategyCardWidgetStyles = (theme: Theme) => ({
  root: {
    '& .MuiCardContent-root': {
      padding: 0,
    },
    height: '100%',
    elevation: 0,
  },
  cardHeader: {
    minHeight: '48px',
    borderRadius: '0px 0px 5px 5px',
    padding: '8px',
    '& .MuiSvgIcon-root': {
      height: '1rem',
      width: '1rem',
    },
    '& .MuiCardHeader-action': {
      marginTop: 0,
    },
  },
  actionArea: {
    minHeight: 145,
    padding: '4px 10px 0 5px',
  },
  cardHeight: {
    minHeight: 270,
    paddingBottom: 8,
  },
  headerBackground: {
    background: theme.palette.info.dark,
  },
  greyheaderBackground: {
    background: theme.palette.action.disabledBackground,
  },

  //Embedder Widgets
  embedderCardHeight: {
    minHeight: 260,
  },
  embedderActionArea: {
    minHeight: 140,
    padding: '4px 10px 0 5px',
  },

  // NASDAQ Related
  nasdaqRoot: {
    backgroundColor: theme.palette.info.dark,
  },
  nasdaqCardheader: {
    background: 'none',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  nasdaqHeaderText: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  nasdaqHeaderBackground: {
    background: 'none',
  },
});

export interface IStrategyCardWidgetProps {
  title: ReactNode;
  actionButton?: ReactNode;
  bestStrategy?: boolean;
  actionArea?: ReactNode;
  chartWidget?: ReactNode;
  strategyControls?: ReactNode;
  disableActionArea?: boolean;
  isActive?: boolean;
}
export const StrategyCardWidget: React.FC<IStrategyCardWidgetProps> = ({
  title,
  actionButton,
  bestStrategy,
  actionArea,
  chartWidget,
  strategyControls,
  disableActionArea,
  isActive,
}: IStrategyCardWidgetProps) => {
  const theme = useTheme();
  const classes = StrategyCardWidgetStyles(theme);
  const [isFlipped, setIsFlipped] = useRecoilState(flippedState);
  const resetFlipped = useResetRecoilState(flippedState);
  const SimulatorOperation = useRecoilValue(tradeSimulatorOperationTypeState);
  const customization = useRecoilValue(customizationState);

  useEffect(() => {
    return () => resetFlipped();
  }, []);

  const getOpacity = () => {
    if (!isActive) {
      if (SimulatorOperation === tradeSimulatorOperationType.Modify) {
        return 0.5;
      } else {
        return 1;
      }
    } else return 1;
  };

  const getBorder = () => {
    if (customization && customization.isNasdaq) {
      return 'none';
    }
    if (bestStrategy) {
      return `2px solid ${theme.palette.primary.main}`;
    }
    return `1px solid ${theme.palette.selection.dark}`;
  };

  const getBackground = () => {
    if (customization && customization.isNasdaq) {
      return classes.nasdaqHeaderBackground;
    }
    if (!isActive) {
      if (SimulatorOperation !== tradeSimulatorOperationType.Modify) {
        return classes.headerBackground;
      } else {
        return classes.greyheaderBackground;
      }
    }
    return classes.headerBackground;
  };

  const isDisabled = () => {
    return disableActionArea ? true : false;
  };

  const getRootCss = () => {
    if (customization && customization.isNasdaq) {
      return { ...classes.root, ...classes.nasdaqRoot };
    }
    return classes.root;
  };

  const getHeaderCss = () => {
    if (customization && customization.isNasdaq) {
      return { ...classes.cardHeader, ...classes.nasdaqCardheader };
    }
    return classes.cardHeader;
  };

  const getHeaderTextCss = () => {
    if (customization && customization.isNasdaq) {
      return classes.nasdaqHeaderText;
    }
    return undefined;
  };

  const getminHeightCss = () => {
    if (customization && customization.isMerrill) {
      return classes.embedderCardHeight;
    }
    return classes.cardHeight;
  };

  const getActionAreaCss = () => {
    if (customization && customization.isEmbeddingPlatform) {
      return classes.embedderActionArea;
    }
    return classes.actionArea;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        ...getRootCss(),
        opacity: getOpacity(),
        border: getBorder(),
      }}>
      <div style={getBackground()}>
        <CardHeader
          sx={getHeaderCss()}
          action={actionButton ? actionButton : undefined}
          subheader={
            title ? (
              <Typography style={getHeaderTextCss()} component="div">
                {title}
              </Typography>
            ) : undefined
          }
        />
      </div>
      <CardContent style={{ ...getminHeightCss(), position: 'relative' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} id="js-strategy-card" className="js-strategy-card" style={{ padding: '0 6px' }}>
            {chartWidget ? (
              <Box sx={{ height: customization && customization.isEmbeddingPlatform ? '90px' : '110px' }}>
                <Suspense fallback={<Skeleton height={130} animation="wave"></Skeleton>}>{chartWidget}</Suspense>
              </Box>
            ) : undefined}
          </Grid>
          <Grid item xs={12}>
            {actionArea ? (
              <CardActionArea
                disableRipple
                style={getActionAreaCss()}
                onClick={() => (isDisabled() ? null : setIsFlipped(!isFlipped))}
                id="card-onClick"
                component="div">
                {actionArea}
              </CardActionArea>
            ) : (
              <div style={getActionAreaCss()}></div>
            )}
          </Grid>
          <Grid item xs={12} sx={{ px: 1, pb: 1 }}>
            {strategyControls}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
