import { ICollection } from '..';
export interface Sector {
  value: string;
  text: string;
  isSelected: boolean;
}

export const defaultSector: Sector = {
  value: '',
  text: '',
  isSelected: false,
};

export interface Sectors extends ICollection<Sector> {
  data: Sector[];
}
