import { BuyOrSell, ClosePositionType, LegType, PriceCalculationMethod } from '@op/shared/src/models/enums/enums';
import { jStat } from 'jstat';
import { ExpandedQuote, Leg } from '.';
import { AccountAdvisor, PortfolioPositionEntity } from '../portfolio';
import ApplicationContext from './application-context';
import DateTimeHelper from './date-time-helper';
import formatting from './formatting';
import { OptionChain } from './option-chain-model';
import { priceIncrementsResolver } from './price-increment-resolver';

export interface IPositionQuote {
  last: number;
  bid: number;
  ask: number;
  shortCode: string;
}

export abstract class BasicPositionModel {
  private readonly SECURITY = LegType.SECURITY;
  private readonly CALL = LegType.CALL;
  private readonly PUT = LegType.PUT;
  _quantity: number = 0;
  buyOrSell: BuyOrSell = BuyOrSell.BUY;
  dividendYield: number = 0;
  defaultRiskFreeRate = 0.002646;
  ownedQuantity: number = 0;
  repeated: boolean = false;
  shortCode: string = '';
  accountAdvisor: AccountAdvisor[] = [];
  multiplier: number = 0;
  closePositionType: ClosePositionType | undefined = undefined;

  // TODO: Vivek for obsolete models
  // if (legData.hasOwnProperty('buyOrSell')) {
  //   legData.quantity *= legData.buyOrSell === BuyOrSell.SELL ? -1 : 1;
  // }

  hash: string | undefined;
  symbol: string = '';
  ulSymbol: string = '';
  exchange: string = '';
  costBasis: number | undefined;
  shares: number | undefined;
  legType: string = '';
  type: string = '';
  strike: number | undefined;
  strikePrice: number | undefined;
  expiry: Date | undefined;
  chain: OptionChain | undefined;
  //legData: Leg = new Leg();
  underlyingQuote: ExpandedQuote = new ExpandedQuote();
  //TODO: make it undefeined/
  positionQuote: ExpandedQuote = new ExpandedQuote();
  optionType: string = 'S';
  selectedPrice: PriceCalculationMethod = PriceCalculationMethod.BID_ASK;
  isPortfolio: boolean = false;
  snd = jStat.normal(0, 1);
  premium: undefined | number;

  fromLeg = (
    legData: Leg,
    underlyingQuote: ExpandedQuote,
    positionQuote: ExpandedQuote,
    optionType: string = 'S',
    selectedPrice: PriceCalculationMethod = PriceCalculationMethod.BID_ASK,
    isPortfolio: boolean = false,
  ) => {
    //const leg = Leg.fromSelf(legData);
    this.underlyingQuote = underlyingQuote;
    this.symbol = underlyingQuote.symbol;
    this.exchange = underlyingQuote.exchange;
    this.positionQuote = positionQuote;
    this.ulSymbol = positionQuote.symbol;
    this.optionType = optionType || 'S';
    this.selectedPrice = selectedPrice;
    this.isPortfolio = isPortfolio;
    this.hash = legData.hash;
    this.costBasis = legData.costBasis || legData.costBasisPerUnit;
    this.legType = legData.legType;
    this.type = legData.legType;
    this.expiry = DateTimeHelper.resolveExpiry(legData.expiry);
    this.strike = legData.strikePrice;
    // TODO : strikeprice is reduntant
    this.strikePrice = legData.strikePrice;
    this.quantity = Math.abs(legData.quantity);
    this.buyOrSell = legData.quantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL;
    this.dividendYield = this.underlyingQuote.yield / 100;
    this.premium = legData.premium;
  };

  fromSelf(position: BasicPositionModel) {
    this.underlyingQuote = position.underlyingQuote;
    this.positionQuote = position.positionQuote;
    this.optionType = position.optionType || 'S';
    this.selectedPrice = position.selectedPrice;
    this.isPortfolio = position.isPortfolio;
    this.chain = position.chain;
    this.hash = position.hash;
    this.symbol = position.underlyingQuote.symbol;
    this.exchange = position.underlyingQuote.exchange;
    this.ulSymbol = position.positionQuote.symbol;
    this.costBasis = position.costBasis;
    this.legType = position.legType;
    this.type = position.legType;
    this.strike = position.strike;
    this.strikePrice = position.strikePrice;
    this.quantity = position.quantity;
    this.buyOrSell = position.buyOrSell;
    this.dividendYield = position.dividendYield;
    this.expiry = position.expiry;
    this.multiplier = position.multiplier;
    this.repeated = position.repeated;
    this.ownedQuantity = position.ownedQuantity;
    this.shortCode = position.shortCode;
    this._quantity = position._quantity;
    this.defaultRiskFreeRate = position.defaultRiskFreeRate;
    this.repeated = position.repeated;
    this.premium = position.premium;
  }

  fromPortfolioPosition = (
    positionData: PortfolioPositionEntity,
    underlyingQuote: ExpandedQuote,
    positionQuote: ExpandedQuote,
  ) => {
    this.underlyingQuote = underlyingQuote;
    this.positionQuote = positionQuote;
    this.optionType = 'S';
    this.selectedPrice = PriceCalculationMethod.MID; // For Portfolio default is MID
    this.isPortfolio = true;
    this.hash = positionData.hash;
    this.symbol = underlyingQuote.symbol;
    this.exchange = underlyingQuote.exchange;
    this.ulSymbol = positionQuote.symbol;
    this.costBasis = positionData.costBasisPerUnit; //positionData.costBasis TODO: cost data does not exist in IPosition.
    this.legType = positionData.legType;
    this.type = positionData.legType;
    this.expiry = DateTimeHelper.resolveExpiry(positionData.expiry);
    this.strike = positionData.strikePrice;
    this.strikePrice = positionData.strikePrice;
    this.quantity = Math.abs(positionData.quantity);
    this.buyOrSell = positionData.quantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL;
    this.dividendYield = this.underlyingQuote.yield / 100;
    // TODO : Premium required to set
  };

  get isIndex() {
    return formatting.checkIndices(this.exchange || '');
  }

  isOwned = () => {
    return !!this.costBasis;
  };

  riskFreeRate = () => {
    return ApplicationContext.configuration.fedFundRate / 100 || this.defaultRiskFreeRate;
  };

  isSpecificType = (legType: any) => {
    return this.type === legType;
  };

  isCallType = () => this.isSpecificType(this.CALL);
  isPutType = () => this.isSpecificType(this.PUT);
  isSecurityType = () => this.isSpecificType(this.SECURITY);

  get isOptionType() {
    return this.isCallType() || this.isPutType();
  }

  premiumMultiplier = (): number => {
    if (this.isSecurityType()) {
      return 1;
    }
    return OptionChain.getSecurityQuantity(this.optionType);
  };

  // this.expiry.subscribe(
  //   function () {
  //
  //   },
  //   this,
  //   'beforeChange',
  // );

  get yearToMaturity() {
    let currentExpiration = this.expiry;
    if (this.isSecurityType() || !currentExpiration) {
      return 0;
    }
    let now = formatting.getCurrentDateTime();
    if (!now) {
      throw new Error('CurrentDateTime is undefined');
    }
    let yearToMaturity = (currentExpiration.getTime() - now.getTime()) / (365 * 24 * 3600 * 1000);
    return yearToMaturity;
  }

  get isBuyPosition() {
    return this.buyOrSell == BuyOrSell.BUY;
  }

  get absQuantity() {
    if (this._quantity <= 0) {
      return 1;
    }
    if (this._quantity >= 2147483647) {
      return 2147483647;
    }
    return this._quantity;
  }

  set absQuantity(newValue: number) {
    this._quantity = newValue;
  }

  displayAbsQuantity = () => {
    let displayAbsQty = this.absQuantity;
    displayAbsQty = this.absQuantity - 99;
    return displayAbsQty;
  };

  get quantity() {
    if (this._quantity === 0) {
      return this._quantity;
    }
    let result = this.isBuyPosition ? this._quantity : -this._quantity;
    return result;
  }

  set quantity(newVal: number) {
    this.absQuantity = Math.abs(newVal);
    this.buyOrSell = newVal > 0 ? BuyOrSell.BUY : BuyOrSell.SELL;
  }

  ask = () => {
    if (this.isSecurityType()) {
      return this.underlyingQuote.ask || this.underlyingQuote.last || 0;
    }

    if (!this.positionQuote) {
      // if (!this.positionQuote && this.legType === 'SECURITY') {
      throw new Error('PositionQuote is undefined');
    }
    let ask = this.positionQuote.ask;
    if (ask) {
      return ask;
    }

    return !this.positionQuote.bid ? this.positionQuote.last : 0;
  };

  bid = () => {
    if (this.isSecurityType()) {
      return this.underlyingQuote.bid || this.underlyingQuote.last || 0;
    }
    if (this.positionQuote === undefined) {
      throw new Error('PositionQuote is undefined');
    }
    let bid = this.positionQuote.bid;
    if (bid) {
      return bid;
    }

    return !this.positionQuote.ask ? this.positionQuote.last : 0;
  };

  netAsk = () => {
    if (this.isSecurityType()) {
      let netAsk = this.underlyingQuote.ask || this.underlyingQuote.last || 0;
      return (netAsk * this.absQuantity) / 100;
    }

    if (!this.positionQuote) {
      // if (!this.positionQuote && this.legType === 'SECURITY') {
      throw new Error('PositionQuote is undefined');
    }
    let ask = this.positionQuote.ask;
    if (ask) {
      return ask * this.absQuantity;
    }

    let netAsk = !this.positionQuote.bid ? this.positionQuote.last : 0;
    return netAsk * this.absQuantity;
  };

  netBid = () => {
    if (this.isSecurityType()) {
      let netBid = this.underlyingQuote.bid || this.underlyingQuote.last || 0;
      return (netBid * this.absQuantity) / 100;
    }
    if (this.positionQuote === undefined) {
      throw new Error('PositionQuote is undefined');
    }
    let bid = this.positionQuote.bid;
    if (bid) {
      return bid * this.absQuantity;
    }
    let netBid = !this.positionQuote.ask ? this.positionQuote.last : 0;
    return netBid * this.absQuantity;
  };

  midPrice = () => {
    const ask = this.ask();
    const bid = this.bid();
    let midPrice = (ask + bid) / 2;
    if (!this.isSecurityType()) {
      midPrice = priceIncrementsResolver.getPriceAdjusted(this.symbol, midPrice);
    }

    return midPrice;
  };

  askBidPrice = () => {
    return this.isBuyPosition ? this.ask() : this.bid();
  };

  oppositeAskBidPrice = () => {
    return this.isBuyPosition ? this.bid() : this.ask();
  };

  netAskBidPrice = () => {
    return this.isBuyPosition ? this.netAsk() : this.netBid();
  };

  netOppositeAskBidPrice = () => {
    return this.isBuyPosition ? this.netBid() : this.netAsk();
  };

  netMidPrice = () => {
    const ask = this.netAsk();
    const bid = this.netBid();
    let midPrice = (ask + bid) / 2;
    if (!this.isSecurityType()) {
      midPrice = priceIncrementsResolver.getPriceAdjusted(this.symbol, midPrice);
    }
    return midPrice;
  };

  // this.shortCode = this.positionQuote.shortCode;
  // this.language = ko.observable(localizer.locale()).extend({ notify: 'always' });
  // this.readUserInputPrice = ko.observable();

  //TODO: the argument `selectedPrice` is not used in the logic. Remove it.
  price = (selectedPrice?: string | undefined) => {
    if (this.premium !== undefined) {
      return this.premium;
    }
    if (this.selectedPrice === PriceCalculationMethod.MID) {
      return this.midPrice();
    } else {
      return this.askBidPrice();
    }
  };

  // localizer.locale.subscribe(function (language) {
  //   this.language(language);
  // });

  // this.mark = this.deferredComputed(function () {
  //   return this.price();
  // });

  totalMarketValue = () => {
    let mark = this.price() || 0;
    return mark * this.premiumMultiplier() * this.absQuantity;
  };

  costBasisTotal = () => {
    if (!this.costBasis) {
      return undefined;
    }
    return this.costBasis * this.premiumMultiplier() * this.absQuantity;
  };

  unrealizedProfitAndLoss = () => {
    let costbasis = this.costBasisTotal();
    if (!costbasis) {
      return 0;
    }
    return Math.sign(this.quantity) * (this.totalMarketValue() - costbasis);
  };

  unrealizedProfitAndLossPercentage = () => {
    let costBasisTotal = this.costBasisTotal();
    if (costBasisTotal) {
      return ((Math.sign(this.quantity) * (costBasisTotal - this.totalMarketValue())) / costBasisTotal) * 100;
    }
    return undefined;
  };

  // this should be set from api.
  get impliedVolatility() {
    return this.getImpliedVolatility();
  }

  // PROTO METHODS
  isCoveredBy = (securityPosition: BasicPositionModel | undefined) => {
    if (securityPosition === undefined || !securityPosition.isSecurityType() || this.isBuyPosition) {
      return false;
    }
    let coveredByQuantity = securityPosition.absQuantity >= this.absQuantity * this.premiumMultiplier();
    if (this.isCallType()) {
      return securityPosition.isBuyPosition && coveredByQuantity;
    }
    return !securityPosition.isBuyPosition && coveredByQuantity;
  };

  isEquivalentPosition = (otherPosition: BasicPositionModel) => {
    let isEquivalent =
      this.symbol === otherPosition.symbol &&
      this.type === otherPosition.legType &&
      (this.isSecurityType() ||
        (formatting.sameDate(this.expiry, otherPosition.expiry) && this.strike === otherPosition.strikePrice));
    return isEquivalent;
  };

  getRawPosition = () => {
    const leg = Leg.fromBasicPosition(
      this.quantity,
      this.type,
      this.expiry,
      this.strikePrice,
      this.costBasis,
      this.buyOrSell,
      this.premium,
    );
    return leg;
  };

  getRawPositionWithoutCostBasis = () => {
    let result = this.getRawPosition();
    result.costBasis = undefined;
    return result;
  };

  getInvertedRawLeg = () => {
    let newLeg = this.getRawPositionWithoutCostBasis();
    newLeg.quantity *= -1;
    return newLeg;
  };

  // #region Black-Scholes Pricing Model
  /**
   * payoff outlook
   * @param spotPrice {string} stock spot price to outlook
   * @param yearToMaturity {numeric} year to maturity, for example: 31.4 / 365
   * @param vol {numeric} volatility of underlying to outlook
   */
  payoff = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    yearToMaturity = typeof yearToMaturity === 'undefined' ? 0 : yearToMaturity;
    let bsPrice = this.bsPrice(spotPrice, yearToMaturity, vol);

    /* NOTE: in accordance with task 3421.
     * It is required to use lastPrice for calculating position payoff
     * for portfolio securities and covered calls.
     */

    /**
     * Below code is commented as Per discussion
     * For security profit calculation should be depends on costbasis not the quote.last
     * Intial for Security type in portfolio, profit calculated w.r.t position.last
     * Now need to be checked with the costbasis.
     */

    if (this.costBasis !== undefined) {
      const a = bsPrice - this.costBasis;

      return a;
    } else {
      const b = bsPrice - this.price();

      return b;
    }
  };

  getImpliedVolatility = () => {
    if (this.type === this.SECURITY) {
      return 0;
    }
    let price = this.price();
    if (!price) {
      return 0;
    }

    if (this.bsPriceCurrent(0) > price) {
      return 0;
    }

    let sigma1 = -1;
    let sigma2 = 5;
    let i = 0;
    while (!formatting.almostEqual(sigma1, sigma2)) {
      let sigma3 = (sigma1 + sigma2) / 2;
      let x1 = this.bsPriceCurrent(sigma1);
      let x3 = this.bsPriceCurrent(sigma3);

      let decisionVar = (x1 - price) * (x3 - price);
      if (decisionVar > 0) {
        sigma1 = sigma3;
      } else if (decisionVar < 0) {
        sigma2 = sigma3;
      }

      if (i++ > 500) {
        break;
      }
    }

    let implVol = (sigma1 + sigma2) / 2;
    return implVol;
  };

  private resolveVolatility = (vol: number | undefined) => {
    if (vol === undefined) {
      return this.impliedVolatility;
    }
    return vol;
  };

  d1 = (spotPrice: number, yearToMaturity: number, vol: number) => {
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;
    if (!this.strikePrice) {
      throw new Error('Stikeprice is undefined');
    }
    let d1 =
      Math.log(spotPrice / this.strikePrice) + (this.riskFreeRate() - divYield + vol * vol * 0.5) * yearToMaturity;
    d1 = d1 / (vol * Math.sqrt(yearToMaturity));
    return d1;
  };

  d2 = (spotPrice: number, yearToMaturity: number, vol: number, d1: number) => {
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;
    d1 = d1 || this.d1(spotPrice, yearToMaturity, vol);
    let d2 = d1 - vol * Math.sqrt(yearToMaturity);
    return d2;
  };

  bsPriceCurrent = (vol: number) => {
    return this.bsPrice(this.underlyingQuote.last, this.yearToMaturity, vol);
  };

  bsPrice = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    if (this.type === this.SECURITY) {
      return spotPrice;
    }
    if (!this.strikePrice) {
      throw new Error('StrikePrice is undefined');
    }
    if (this.strikePrice === null) {
      throw new Error('StrikePrice is null');
    }

    if (yearToMaturity <= 0) {
      switch (this.type) {
        case this.CALL:
          return Math.max(0, spotPrice - this.strikePrice);
        case this.PUT:
          return Math.max(0, this.strikePrice - spotPrice);
        default:
          return spotPrice;
      }
    }
    let result;
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;

    let d1 = this.d1(spotPrice, yearToMaturity, vol);
    let d2 = this.d2(spotPrice, yearToMaturity, vol, d1);
    if (!this.strikePrice) {
      throw new Error('StrikePrice is undefined');
    }
    switch (this.type) {
      case this.CALL:
        result =
          spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.cdf(d1) -
          this.strikePrice * Math.exp(-this.riskFreeRate() * yearToMaturity) * this.snd.cdf(d2);
        break;
      case this.PUT:
        result =
          this.strikePrice * Math.exp(-this.riskFreeRate() * yearToMaturity) * this.snd.cdf(-d2) -
          spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.cdf(-d1);
        break;
      default:
        return 0;
    }

    return result;
  };

  delta = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    if (this.type === this.SECURITY) {
      return 1;
    }
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;
    let d1 = this.d1(spotPrice, yearToMaturity, vol);
    switch (this.type) {
      case this.CALL:
        return Math.exp(-divYield * yearToMaturity) * this.snd.cdf(d1);
      case this.PUT:
        return Math.exp(-divYield * yearToMaturity) * -this.snd.cdf(-d1);
      default:
        return 0;
    }
  };

  gamma = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    if (this.type === this.SECURITY) {
      return 0;
    }
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;
    let d1 = this.d1(spotPrice, yearToMaturity, vol);
    return (Math.exp(-divYield * yearToMaturity) * this.snd.pdf(d1)) / (spotPrice * vol * Math.sqrt(yearToMaturity));
  };

  vega = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    if (this.type === this.SECURITY) {
      return 0;
    }
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;
    let d1 = this.d1(spotPrice, yearToMaturity, vol);
    return (spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.pdf(d1) * Math.sqrt(yearToMaturity)) / 100;
  };

  theta = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    if (this.type === this.SECURITY) {
      return 0;
    }
    vol = this.resolveVolatility(vol);
    let divYield = this.dividendYield;
    let d1 = this.d1(spotPrice, yearToMaturity, vol);
    let d2 = this.d2(spotPrice, yearToMaturity, vol, d1);
    let result = 0;
    if (!this.strikePrice) {
      throw new Error('StrikePrice is undefined');
    }
    switch (this.type) {
      case this.CALL:
        result -=
          (spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.pdf(d1) * vol) / (2 * Math.sqrt(yearToMaturity));
        result -=
          this.riskFreeRate() * this.strikePrice * Math.exp(-this.riskFreeRate() * yearToMaturity) * this.snd.cdf(d2);
        result += divYield * spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.cdf(d1);
        break;
      case this.PUT:
        result -=
          (spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.pdf(d1) * vol) / (2 * Math.sqrt(yearToMaturity));
        result +=
          this.riskFreeRate() * this.strikePrice * Math.exp(-this.riskFreeRate() * yearToMaturity) * this.snd.cdf(-d2);
        result -= divYield * spotPrice * Math.exp(-divYield * yearToMaturity) * this.snd.cdf(-d1);
        break;
      default:
        return 0;
    }
    return result / 365;
  };

  rho = (spotPrice: number, yearToMaturity: number, vol: number | undefined) => {
    if (this.type === this.SECURITY) {
      return 0;
    }
    vol = this.resolveVolatility(vol);
    let d1 = this.d1(spotPrice, yearToMaturity, vol);
    let d2 = this.d2(spotPrice, yearToMaturity, vol, d1);
    let result;
    if (!this.strikePrice) {
      throw new Error('StrikePrice is undefined');
    }
    switch (this.type) {
      case this.CALL:
        result = this.strikePrice * yearToMaturity * Math.exp(-this.riskFreeRate() * yearToMaturity) * this.snd.cdf(d2);
        break;
      case this.PUT:
        result =
          -this.strikePrice * yearToMaturity * Math.exp(-this.riskFreeRate() * yearToMaturity) * this.snd.cdf(-d2);
        break;
      default:
        return 0;
    }
    return result;
  };
  // END OF PROTO
}
