import { accountState } from '@op/shared/src/states';
import React, { ReactFragment, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SessionTimeOut } from './idle-session-logout';
import { PageLoaderWidget } from './skeleton-page-widget';

export interface ProtectedRouteProps {
  children: ReactNode | ReactFragment;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }: ProtectedRouteProps) => {
  const account = useRecoilValue(accountState);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // if (location.pathname === '/subscription' || !account) {
    //   return;
    // }
    // if (!account.canAccessApplication) {
    //   navigate('/subscription', { replace: true });
    //   return;
    // }
    if (location.pathname.includes('profile') || !account) {
      return;
    }
    if (!account.canAccessApplication) {
      navigate('/profile/subscriptions', { replace: true });
      return;
    }
  }, []);

  if (!account || !account.canAccessApplication) {
    return <PageLoaderWidget />;
  }

  return (
    <>
      {children}
      <SessionTimeOut />
    </>
  );
};
