import { CardContent, CardHeader, SxProps, Theme, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ISubscriptionCardLayoutProps {
  sx?: SxProps<Theme>;
  title: ReactNode;
  content: ReactNode;
  // monthlyPlan?: boolean;
  // yearlyPlan?: boolean;
  isRecommended?: boolean;
  cancelled?: boolean;
  isConfirmationScreen?: boolean;
}

export const SubscriptionCardLayout: React.FC<ISubscriptionCardLayoutProps> = ({
  sx,
  title,
  content,
  isRecommended,
  cancelled,
  isConfirmationScreen,
}: ISubscriptionCardLayoutProps) => {
  const theme = useTheme();

  const backgroundColor = () => {
    if (cancelled) {
      return theme.palette.error.main;
    }
    if (isRecommended) {
      return `linear-gradient(to right,#0078D4, #77BF45)`;
    }
    if (!isRecommended) {
      return theme.palette.primary.main;
    }

    return theme.palette.success.main;
  };

  return (
    <div>
      <CardHeader
        style={{
          height: 40,
          background: backgroundColor(),
          width: isConfirmationScreen ? 'calc(100% - 32px)' : '100%',
          borderTopRightRadius: theme.shape.borderRadius,
          borderTopLeftRadius: theme.shape.borderRadius,
          color: 'whitesmoke',
          alignItems: 'center',
          marginLeft: isConfirmationScreen ? 16 : null,
        }}
        title={<>{title}</>}
      />
      <CardContent sx={sx}>{content}</CardContent>
    </div>
  );
};
