import { ApiService, powerhouseProApiServiceUrl } from '.';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { IHow } from '../models';

const _getHow = async (url: string, ruleMatch?: string) => {
  const response = await ApiService.instance.get<IHow>(url, { ruleMatch: ruleMatch });
  const vm = new ResponseViewModel<IHow>();
  vm.data = response.data as unknown as IHow;
  vm.errors = response.errors;
  return response;
};

export const getHow = async (
  symbol: string,
  ruleMatch?: string,
  shareId?: string,
): Promise<ResponseViewModel<IHow>> => {
  if (!symbol) {
    throw new Error('Invalid request');
  }
  let url = `${powerhouseProApiServiceUrl}/how/${symbol}`;
  if (shareId && shareId.trim() !== '') {
    url += `?shareId=${shareId}`;
  }
  const response = await _getHow(url, ruleMatch);
  return response;
};

export const getHowAnonymous = async (
  symbol: string,
  shareId: string,
  ruleMatch?: string,
): Promise<ResponseViewModel<IHow>> => {
  if (!symbol) {
    throw new Error('Invalid request');
  }
  if (!shareId || shareId.trim() === '') {
    throw new Error('Please provide valid share id');
  }
  const url = `${powerhouseProApiServiceUrl}/anonymous/how/${symbol}?shareId=${shareId}`;
  const response = await _getHow(url, ruleMatch);
  return response;
};
