import { Grid, Skeleton } from '@mui/material';
import React from 'react';
export const SkeletonLoadingWhyWidget: React.FC = () => {
  return (
    <Grid item sx={{ pt: 2 }}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((n, i) => (
        <Grid key={i}>
          <Skeleton key={n} animation="wave" variant="text" height={30} />
          <Skeleton animation="wave" variant="text" height={20} width="40%" />
          <Skeleton animation="wave" variant="text" height={10} width="20%" />
        </Grid>
      ))}
    </Grid>
  );
};

export const SkeletonLoadingChartIQ: React.FC = () => {
  return (
    <>
      <Skeleton animation="wave" variant="rectangular" height={412} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid container sx={{ pt: 1 }} columnSpacing={1} justifyContent="center" alignItems="center">
          <Grid item container xs={6} columnSpacing={1}>
            <Grid item xs={6}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height={18} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height={15} />
              <Skeleton animation="wave" variant="rectangular" width="100%" height={15} sx={{ mt: 1 }} />
            </Grid>
          </Grid>
          <Grid item container xs={6} columnSpacing={1}>
            <Grid item xs={6}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height={15} />
              <Skeleton animation="wave" variant="rectangular" width="100%" height={15} sx={{ mt: 1 }} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton animation="wave" variant="rectangular" width="100%" height={18} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export const SkeletonLoadingChartDetails: React.FC = () => {
  return (
    <Grid item sx={{ pt: 1.5 }}>
      <Skeleton animation="wave" variant="text" height={15} width="50%" />
      <Skeleton animation="wave" variant="text" height={15} width="95%" sx={{ mt: 1 }} />
      <Skeleton animation="wave" variant="text" height={15} width="95%" />
      <Skeleton animation="wave" variant="text" height={15} width="55%" />
      <Skeleton animation="wave" variant="text" height={15} width="95%" sx={{ mt: 1 }} />
      <Skeleton animation="wave" variant="text" height={15} width="95%" />
    </Grid>
  );
};
