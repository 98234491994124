import { Box, Button, Checkbox, FormControlLabel, Grid, Link, Typography, useTheme } from '@mui/material';
import { IncomeCombination, Leg, TradeTicket } from '@op/shared/src/models';
import { BuyOrSell } from '@op/shared/src/models/enums/enums';
import { selectedWatchListIdState, viewState, whatIfSimulatorState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { OPDialogWidget } from '../common';
import { OPBoldTypograpghy } from '../styled';
import { getLoginURL } from './services';
import { TSEnvironmentTypes, tsTermsAndConditionAcceptState } from './states/trade-station-states';
import { StyledCheckBox } from './ts-styles-component';

type Props = {
  open?: boolean;
  setOpen: (open: boolean) => void;
  changeEnvironment?: () => void;
  tsCurrentEnv?: string;
  tsChangeEnvironment?: boolean;
  tradeTicket?: TradeTicket | undefined;
};

export const TSEnvSelectionWidget: React.FC<Props> = ({
  open,
  setOpen,
  tradeTicket,
  changeEnvironment,
  tsCurrentEnv,
  tsChangeEnvironment,
}: Props) => {
  const tsTermsAndConditionAccepted = useRecoilValue(tsTermsAndConditionAcceptState);
  const [isAccepted, setIsAccepted] = useState(false);
  const [env, setEnv] = useState<TSEnvironmentTypes>('none');
  const theme = useTheme();

  const view = useRecoilValue(viewState);
  const selectedWatchListId = useRecoilValue(selectedWatchListIdState);
  const watchListRoute = useMatch('/watchlist');
  const whatIfSimulator = useRecoilValue(whatIfSimulatorState);

  useEffect(() => {
    setIsAccepted(tsTermsAndConditionAccepted);
  }, [tsTermsAndConditionAccepted]);

  const handleClose = () => {
    setOpen(false);
  };

  const renderDialogTitle = () => {
    return (
      <Grid item container xs={12} onClick={handleClose} marginTop={1}>
        <img
          src="/images/trade-station.svg"
          style={{
            width: 150,
            height: 25,
            alignItems: 'center',
          }}
          alt="trade_station_logo"
        />
      </Grid>
    );
  };

  const loginTradeStation = async (env: TSEnvironmentTypes) => {
    const loginRes = await getLoginURL(env);
    if (!loginRes) {
      return;
    }
    const url = loginRes.data;
    handleClose();
    /* 
      preserve trade ticket
    */
    const allLegs = tradeTicket.allLegs();
    const legs = allLegs.map((l, i) => {
      const leg = Leg.fromBasicPosition(
        l.quantity,
        l.type,
        l.expiry,
        l.strike,
        undefined,
        l.quantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL,
      );
      leg.premium = l.price;
      return leg;
    });

    const whatIfDate = whatIfSimulator.whatIfDate || new Date();
    const targetDate = new Date(
      Date.UTC(whatIfDate.getFullYear(), whatIfDate.getMonth(), whatIfDate.getDate(), 0, 0, 0),
    );

    const tradeTicketComb = {
      ...(watchListRoute && { id: selectedWatchListId }),
      symbol: tradeTicket.combination.symbol,
      view: view,
      legs: legs,
      priceCalculationMethod: tradeTicket.combination.priceCalculationMethod,
      targetDate: targetDate.toISOString(),
      targetPrice: whatIfSimulator?.whatIfSPrice,
      ...(tradeTicket.combination.isIncome && {
        priority: (tradeTicket.combination as IncomeCombination).priority,
        costBasis: (tradeTicket.combination as IncomeCombination).costBasis(),
        shares: (tradeTicket.combination as IncomeCombination).absQuantity(),
      }),
    };
    localStorage.setItem('tradeTicketCombination', JSON.stringify(tradeTicketComb));
    window.localStorage.setItem('tsEnv', env);
    window.localStorage.setItem('tsRedirectURL', window.location.pathname + window.location.search);
    window.open(url, '_self');
  };

  const onConnect = () => {
    if (tsChangeEnvironment) {
      changeEnvironment();
      setOpen(!open);
      return;
    }
    loginTradeStation(env);
  };

  const renderTerms = () => {
    if (!tsTermsAndConditionAccepted) {
      return (
        <Grid item container xs={12}>
          <Typography style={{ maxWidth: '100%' }}>
            I confirm that I’ve read the
            <Link href="https://www.optionsplay.com/terms" target="_blank" sx={{ marginX: '4px' }}>
              terms of use
            </Link>
            and accept all risks
          </Typography>
        </Grid>
      );
    }
    return (
      <Grid item container xs={12}>
        <Typography style={{ maxWidth: '100%' }}>
          By clicking connect, I confirm that I’ve read the
          <Link href="https://www.optionsplay.com/terms" target="_blank" sx={{ marginX: '4px' }}>
            terms of use
          </Link>
          and accept all risks
        </Typography>
      </Grid>
    );
  };

  const disableConnectButton = () => {
    const envName = tsChangeEnvironment ? tsCurrentEnv : env;
    return !isAccepted || envName === 'none';
  };

  const renderBannerAndRedirection = () => {
    if (changeEnvironment) {
      return;
    }
    return (
      <Box
        sx={{
          marginX: 0,
          paddingY: 1,
          backgroundColor: theme.palette.mode === 'light' ? '#BFE0FA' : '#343A3D',
          textAlign: 'center',
        }}>
        <Grid container alignItems={'center'} justifyContent={'center'} item xs={12}>
          <Grid item xs={12}>
            <OPBoldTypograpghy
              variant="subtitle1"
              sx={{ color: theme.palette.mode === 'light' ? '#66666680' : '#99999980' }}>
              Don't have an account?
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={12}>
            <Link
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 'bold',
              }}
              href="https://www.tradestation.com/promo/optionsplay/"
              target="_blank">
              CLICK HERE
            </Link>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderTermsFormConrolLabel = () => {
    if (!tsTermsAndConditionAccepted) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={isAccepted}
              onChange={(e, checked) => setIsAccepted(checked)}
              icon={<StyledCheckBox />}
              checkedIcon={<StyledCheckBox sx={{ backgroundColor: theme.palette.primary.main }} />}
            />
          }
          label={renderTerms()}
          labelPlacement="end"
        />
      );
    }
    return renderTerms();
  };

  const renderDialogAction = () => {
    return (
      <Box>
        <Box alignItems={'center'} justifyContent={'center'} marginX={2}>
          <Grid item container xs={12} paddingLeft={2}>
            {renderTermsFormConrolLabel()}
          </Grid>
          <Grid item xs={12} marginY={2} px={1}>
            <Button
              variant="contained"
              fullWidth
              disabled={disableConnectButton()}
              size="large"
              onClick={() => onConnect()}
              sx={{ height: '35px' }}>
              <OPBoldTypograpghy>{'Connect'}</OPBoldTypograpghy>
            </Button>
          </Grid>
        </Box>
        {renderBannerAndRedirection()}
      </Box>
    );
  };

  const getDialogButtonStyle = (envValue: string) => {
    const envName = tsChangeEnvironment ? tsCurrentEnv : env;
    return {
      backgroundColor: envName === 'none' || envName === envValue ? '#f3f3f3' : 'default',
      color: envName === 'none' || envName === envValue ? '#000' : '#fff',
      boxShadow: 0,
      ':hover': {
        backgroundColor: envName === 'none' || envName === envValue ? '#f3f3f3' : 'default',
        boxShadow: 0,
      },
    };
  };

  const renderDialogContent = () => {
    return (
      <Grid item container xs={12} spacing={1} paddingX={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={tsChangeEnvironment && tsCurrentEnv === 'live'}
            fullWidth
            size="large"
            onClick={() => setEnv('live')}
            sx={{ ...getDialogButtonStyle(tsChangeEnvironment ? 'live' : 'sim') }}>
            <OPBoldTypograpghy>Live</OPBoldTypograpghy>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={tsChangeEnvironment && tsCurrentEnv === 'sim'}
            fullWidth
            size="large"
            sx={{ ...getDialogButtonStyle(tsChangeEnvironment ? 'sim' : 'live') }}
            onClick={() => setEnv('sim')}>
            <OPBoldTypograpghy>Simulated</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <OPDialogWidget
      open={open}
      onHandleClose={handleClose}
      dialogAction={renderDialogAction()}
      dialogContent={renderDialogContent()}
      title={renderDialogTitle()}
      rootPadding={!changeEnvironment}
      dialogdividers
    />
  );
};
