import { Combination, ILeg, Leg, OptionChain } from '@op/shared/src/models';
import { BuyOrSell, ExchangeCode, LegType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import formatting from '@op/shared/src/models/how/formatting';

const LEGS_MAX_QUANTITY = 4;

const canAddLeg = (legType: LegType, combination: Combination) => {
  const positionsLength = combination.buyablePositions.length;
  const addLegIsPossible = positionsLength < LEGS_MAX_QUANTITY && !!combination.chain;
  if (!addLegIsPossible) {
    return false;
  }
  const hasBuyableStock = combination.buyablePositions.some((position) => {
    return position.type === LegType.SECURITY;
  });
  if (legType === LegType.SECURITY && (hasBuyableStock || !combination.quote || !combination.quote.isTradeable)) {
    return false;
  }

  if (!ApplicationContext.configuration || !ApplicationContext.configuration.strategiesConfiguration) {
    return false;
  }

  const strategiesConfiguration = ApplicationContext.configuration.strategiesConfiguration;
  const maxQuantityDefined = strategiesConfiguration && strategiesConfiguration.legsMaxQuantity != null;
  if (!maxQuantityDefined) {
    return true;
  }

  if (strategiesConfiguration.legsMaxQuantity === null) {
    return false;
  }

  if (positionsLength >= strategiesConfiguration.legsMaxQuantity) {
    return false;
  }

  if (!strategiesConfiguration.forceStockLegForTwoLegged) {
    return true;
  }
  const theOnlyBuyablePosition = combination.buyablePositions[0];
  if (positionsLength === 1 && theOnlyBuyablePosition != null) {
    let positionType = theOnlyBuyablePosition.type;
    return (
      (positionType.trim().toUpperCase() === LegType.SECURITY.toString() &&
        legType.trim().toUpperCase() !== LegType.SECURITY.toString()) ||
      (positionType.trim().toUpperCase() !== LegType.SECURITY.toString() &&
        legType.trim().toUpperCase() === LegType.SECURITY.toString())
    );
  }
  return true;
};

export const addOptionLeg = (legType: LegType, combination: Combination) => {
  if (!combination.chain || combination.chain.rows.length === 0) {
    return;
  }

  const addLegIsPossible = canAddLeg(legType, combination);
  if (!addLegIsPossible) {
    return undefined;
  }

  if (combination.chain === undefined) {
    throw new Error('The chain is undefined');
  }
  let expiry = combination.expiration();

  let strike = combination.chain.findStrike(combination.quote.last, expiry, 0, combination.optionType);
  let securityQuantity = 1;
  const isSecurityType = legType === LegType.SECURITY;
  if (isSecurityType) {
    securityQuantity = OptionChain.getSecurityQuantity(combination.optionType);
  }
  const leg: ILeg = {
    buyOrSell: BuyOrSell.BUY,
    quantity: securityQuantity,
    legType: legType,
    expiry: isSecurityType ? undefined : expiry,
    strikePrice: isSecurityType ? undefined : strike,
  };
  const legs = combination.positions.map((p) => Leg.fromPosition(p));
  legs.push(leg);
  return { action: '', legs: legs };
};

export const canHideLegButton = (combination: Combination, legType: LegType) => {
  const isCanadianSymbol = formatting.getExchange(combination.symbol) === ExchangeCode.XTSE.toString();
  const hasStock = combination.hasStock();
  const hasOption = combination.hasOption();

  if (isCanadianSymbol && legType === LegType.CALL && hasStock) {
    return true;
  }
  if (isCanadianSymbol && legType === LegType.PUT && hasStock) {
    return true;
  }
  if (isCanadianSymbol && legType === LegType.SECURITY && hasOption) {
    return true;
  }
  return false;
};
