export interface IResetModelProps {
  password: string;
  confirmPassword: string;
  hash: string;
  salt: string;
}

export class ResetModel implements IResetModelProps {
  hash = '';
  salt = '';
  password = '';
  confirmPassword = '';

  public static fromJson(json: IResetModelProps): ResetModel {
    const model = new ResetModel();
    model.password = json.password;
    model.confirmPassword = json.confirmPassword;
    model.hash = json.hash;
    model.salt = json.salt;
    return model;
  }
}
