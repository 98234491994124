import { Card, Typography } from '@mui/material';
import { Combination } from '@op/shared/src/models';
import { selectedRecommendationState } from '@op/shared/src/states/portfolio-management-states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { BasicCardWidget } from './basic-card-widget';
import { CombinationHeadingWidget } from './combination-heading-widget';
import { PlainEnglishSentenceOfCombinationWidget } from './plain-english-sentence-of-combination-widget';
import { GuideItem } from '../side-menu';
export interface IPlainEnglishOfCombinationWidgetProps {
  combination: Combination;
  index?: number;
}

export const PlainEnglishOfCombinationWidget: React.FC<IPlainEnglishOfCombinationWidgetProps> = ({
  combination,
  index,
}: IPlainEnglishOfCombinationWidgetProps) => {
  const selectedRecommendation = useRecoilValue(selectedRecommendationState);

  if (!combination) {
    return null;
  }

  const renderCombinationTitle = () => {
    if (!combination) {
      return null;
    }
    if (combination.isPortfolio) {
      return renderPortfolioTitleIfPossible();
    }
    return <CombinationHeadingWidget text={combination.fullNameWithoutSymbol()} />;
  };

  const renderPortfolioTitleIfPossible = () => {
    return (
      <>
        {renderPortfolioCurrentTitleIfPossible()}
        {renderPortfolioAdjustmentTitleIfPossible()}
        {renderPortfolioResultantTitleIfPossible()}
      </>
    );
  };

  const renderPortfolioCurrentTitleIfPossible = () => {
    if (!combination.isCurrent) {
      return null;
    }
    return <CombinationHeadingWidget text={combination.nameInPortfolio} />;
  };

  const renderPortfolioAdjustmentTitleIfPossible = () => {
    if (!combination.isAdjustment || !selectedRecommendation || !selectedRecommendation.isAlert) {
      return null;
    }
    let title = selectedRecommendation.alertTitle;
    if (!title) {
      return undefined;
    }
    if (title.indexOf('_') >= 0) {
      title = title.split('_')[1]; //for Arlington: Arlington_Optimal Covered Call Found;
    }
    return (
      <>
        <Typography variant="subtitle2" lineHeight={1} color="error.main">
          Alert:
        </Typography>
        <CombinationHeadingWidget text={title} />
      </>
    );
  };

  const renderPortfolioResultantTitleIfPossible = () => {
    if (!combination.isResulting) {
      return null;
    }
    if (combination.isClosedPosition()) {
      return <CombinationHeadingWidget text="Closed Position" />;
    }
    return <CombinationHeadingWidget text={combination.nameInPortfolio} />;
  };

  return (
    <React.Fragment>
      <GuideItem selector=".plainEnglish_helpPinPlaceholder" canShow={index === 0} />
      <Card sx={{ flexDirection: 'column', boxShadow: 'none' }}>
        <BasicCardWidget
          title={renderCombinationTitle()}
          content={<PlainEnglishSentenceOfCombinationWidget combination={combination} />}
          sx={{ p: 1, height: '230px', overflow: 'auto' }}
        />
      </Card>
    </React.Fragment>
  );
};
