import { Box, Grid, Tabs } from '@mui/material';
import { configurationState, watchListNewsFeedState } from '@op/shared/src/states';
import React, { Suspense, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { TabPanel } from '../common';
import { ChartWidget } from '../op-chart/chart-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { StyledTab } from '../styled';
import { ChartInformationWidget } from './chart-information-widget';
import { SkeletonLoadingWhyWidget } from './mid-panel-skeleton';
import { NewsFeedPanel } from './news-feed-panel';
import { SupportAndResistanceWidget } from './support-and-resistance-widget';

export const MidTabPanelWidget: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = React.useContext(LocalizationContext);
  const configuration = useRecoilValue(configurationState);
  const newsFeedState = useRecoilValue(watchListNewsFeedState);
  const newsFeedSetState = useSetRecoilState(watchListNewsFeedState);

  useEffect(() => {
    newsFeedState ? setSelectedTab(1) : setSelectedTab(0);
  }, [newsFeedState]);

  const updateTabChange = (value: number) => {
    setSelectedTab(value);
    value === 1 ? newsFeedSetState(true) : newsFeedSetState(false);
  };

  const renderNewsFeedTab = () => {
    if (!configuration) {
      return;
    }
    const showNews = configuration.marketRegion === 'NO' ? false : configuration.showNewsTab;
    if (!showNews) {
      return;
    }
    return <StyledTab id="tab-newsFeed" label={t('why.newsFeed')} isselected={selectedTab === 1 ? 'true' : 'false'} />;
  };

  return (
    <Box sx={{ height: '683px' }}>
      <Grid id="chartiq-advance-chart-container" container>
        <Grid item container xs={12}>
          <Grid item>
            <Tabs value={selectedTab} onChange={(_, value) => updateTabChange(value)}>
              <StyledTab id="tab-charts" label={t('why.charts')} isselected={selectedTab === 0 ? 'true' : 'false'} />
              {renderNewsFeedTab()}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <TabPanel selectedIndex={selectedTab} index={0}>
              <Grid container>
                <Grid item xs={12}>
                  <ChartWidget />
                </Grid>
                <Grid item xs={12}>
                  <SupportAndResistanceWidget />
                </Grid>
                <Grid item xs={12}>
                  <ChartInformationWidget />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel selectedIndex={selectedTab} index={1}>
              <Suspense fallback={<SkeletonLoadingWhyWidget />}>
                <NewsFeedPanel />
              </Suspense>
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
