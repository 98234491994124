import { IPrediction } from '.';
import DateTimeHelper from './date-time-helper';
import helpers from './helpers';
import NumberFormatHelper from './number-format-helper';

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export class PredictionModel {
  symbol = '';
  days: number = 0;
  probs: number[] = [];
  prices: number[] = [];

  constructor(symbol: string, probData: IPrediction) {
    this.symbol = symbol;
    this.days = NumberFormatHelper.floor(probData.daysInFuture);
    this.initialize(probData);
  }

  private initialize = (data: IPrediction) => {
    for (let i = 0; i < data.probability.length; i++) {
      let probability = data.probability[i];
      let price = data.prices[i];
      if (probability > 0.0001) {
        this.probs.push(probability);
        this.prices.push(price);
      }
    }
  };

  /**
   * @desc calculate price based on provided probability.
   * Calculation based on price and probs arrays provided by server API.
   * Used binary search. If requested prob not in the array used linear interpolation
   * @param {float} prob - probability value base on which price calculated. Probability of stock price being less than price returned
   * @return {float} - price according to the probability
   */
  getPriceByProbability = (prob: number) => {
    if (!prob || !this.probs.length || prob < this.probs[0]) {
      return 0;
    }
    let lowBound = 0;
    let highBound = this.probs.length;

    // binary search
    let i = Math.floor((lowBound + highBound) / 2);
    while (i !== lowBound || i !== highBound) {
      let pro = this.probs[i];
      let pri = this.prices[i];

      if (pro.toFixed(2) == prob.toFixed(2)) {
        return pri;
      } else if (i === lowBound || i === highBound) {
        let p = this.interpolate(prob, this.probs, this.prices, i, lowBound);
        return p;
      } else if (pro > prob) {
        highBound = i;
      } else {
        lowBound = i;
      }
      i = Math.floor((lowBound + highBound) / 2);
    }
    return this.prices[this.prices.length - 1];
  };

  /**
   * @desc calculate price with the same probability as provided price.
   * Calculation based on algorithm of binary search and getAccumulatingProbability function
   * Remark: this price could be calculated because for normal distribution one ordinate value could have two abscissa
   * @param float price - price value base on which symmetric price calculated
   * @return float - price
   */
  getSymmetricPrice = (price: number) => {
    let lowBound;
    let highBound;
    let cumulatedProb = this.getCumulativeProbability(price);
    let targetProb = 1 - cumulatedProb;

    lowBound = this.prices[0];
    highBound = this.prices[this.prices.length - 1];

    // binary search
    let targetPrice = (lowBound + highBound) / 2;

    while (!(targetPrice.toFixed(3) == lowBound.toFixed(3) || targetPrice.toFixed(3) == highBound.toFixed(3))) {
      let currentProb = this.getCumulativeProbability(targetPrice);
      if (currentProb.toFixed(4) == targetProb.toFixed(4)) {
        return targetPrice;
      }
      if (currentProb > targetProb) {
        highBound = targetPrice;
      } else {
        lowBound = targetPrice;
      }
      targetPrice = (lowBound + highBound) / 2;
    }
    return targetPrice;
  };

  /**
   * @desc calculate cumulative function for provided price.
   * Calculation based on price and probs arrays provided by server API.
   * Used binary search. If requested price not in the array used linear interpolation
   * Warn: during calculation normal distribution converted to increasing function.
   *        All probabilities in the area of decreasing transformed as follows: newProb = highProb + (highProb - prob). Where highProb = max Probability in this.probs array.
   * @param float price - price value base on which probability calculated
   * @return float - probability
   */
  getCumulativeProbability = (price: number) => {
    if (!price || !this.prices.length || price < this.prices[0]) {
      return 0;
    }

    // binary search
    let lowBound = 0;
    let highBound = this.prices.length - 1;
    let i = Math.ceil(this.prices.length / 2);

    while (i !== lowBound || i !== highBound) {
      let pro = this.probs[i];
      let pri = this.prices[i];

      if (pri.toFixed(2) == price.toFixed(2)) {
        return pro;
      } else if (i === lowBound || i === highBound) {
        let p = this.interpolate(price, this.prices, this.probs, i, lowBound);
        p = p <= 1 ? p : 1;
        return p;
      } else if (pri > price) {
        highBound = i;
      } else {
        lowBound = i;
      }
      i = Math.floor((lowBound + highBound) / 2);
    }
    return 1;
  };

  /**
   * @desc calculate Percentile Rank between two prices.
   * Calculation based on Cumulative Distribution Function
   * Warn: Cumulative Distribution Function is not Distribution Function.
   *        There used only approximated values of this function.
   * @param float price1 - price1 value
   * @param float price2 - price2 value
   * @return float - Percentile Rank
   */
  percentileRank = (price1: number, price2: number) => {
    let res;
    if (price1 == undefined) {
      res = 0;
    } else if (price2 == undefined) {
      res = this.getCumulativeProbability(price1);
    } else {
      res =
        this.getCumulativeProbability(Math.max(price1, price2)) -
        this.getCumulativeProbability(Math.min(price1, price2));
    }

    return res;
  };

  /**
   * @desc make interpolation of y=f(x) function
   * @param float xVal - point for getting approximated value
   * @param float array xs - array of abscissa values
   * @param float array ys - array of ordinate values
   * @param int i - index which comes from binary search
   * @param int lowBound - low Bound index from binary search
   * @return float - Approximate
   */
  // could be moved to separate module. For now no reason for that.
  interpolate = (xVal: number, xs: number[], ys: number[], i: number, lowBound: number) => {
    //linear interpolation
    function linearInterpolation(p0: Point, p1: Point, x: number) {
      let res = ((x - p0.x) * (p1.y - p0.y)) / (p1.x - p0.x) + p0.y;
      return res;
    }

    //quadratic interpolation
    function quadraticInterpolation(p0: Point, p1: Point, p2: Point, x: number) {
      let a =
        (p2.y - (p2.x * (p1.y - p0.y) + p1.x * p0.y - p0.x * p1.y) / (p1.x - p0.x)) /
        (p2.x * (p2.x - p0.x - p1.x) + p0.x * p1.x);
      let b = (p1.y - p0.y) / (p1.x - p0.x) - a * (p0.x + p1.x);
      let c = (p1.x * p0.y - p0.x * p1.y) / (p1.x - p0.x) + a * p0.x * p1.x;

      let res = a * x * x + b * x + c;

      return res;
    }

    // building points to interpolation.
    let point0 = new Point(xs[i], ys[i]);
    let point1;
    let point2;

    if (i === lowBound) {
      point1 = new Point(xs[i + 1], ys[i + 1]);

      if (i >= 1) {
        point2 = new Point(xs[i - 1], ys[i - 1]);
      } else {
        point2 = new Point(xs[i + 2], ys[i + 2]);
      }
    } else {
      point1 = new Point(xs[i - 1], ys[i - 1]);

      if (i + 1 <= this.probs.length - 1) {
        point2 = new Point(xs[i + 1], ys[i + 1]);
      } else {
        point2 = new Point(xs[i - 2], ys[i - 2]);
      }
    }

    let yVal;
    if (i >= xs.length * 0.9 || i <= xs.length * 0.1) {
      // linear interpolation
      yVal = linearInterpolation(point0, point1, xVal);
    } else {
      // quadratic interpolation
      yVal = quadraticInterpolation(point0, point1, point2, xVal);
    }

    return yVal;
  };
}

export class Predictions {
  symbol = '';
  private map = new Map<number, PredictionModel>();

  constructor(symbol: string, probData: IPrediction[]) {
    this.symbol = symbol;
    probData.forEach((data: IPrediction) => {
      let prediction = new PredictionModel(this.symbol, data);
      const days = NumberFormatHelper.floor(data.daysInFuture);
      this.map.set(days, prediction);
    });
  }

  getProbByNumberOfDays = (days: number) => {
    return this.map.get(days) || this.map.get(days - 1) || this.map.get(days + 1);
  };

  getProb = (numOfDaysOrExpiry: number | Date | undefined) => {
    if (helpers.isNumber(numOfDaysOrExpiry)) {
      return this.getProbByNumberOfDays(numOfDaysOrExpiry as number);
    }
    let numOfDays = DateTimeHelper.daysFromNow(numOfDaysOrExpiry);
    return this.getProbByNumberOfDays(numOfDays as number);
  };

  getSymmetricPrice = (price: number, days: number | Date | undefined) => {
    if (!this.map) {
      return 0;
    }
    let prediction = this.getProb(days);
    if (!prediction) {
      return 0;
    }

    return prediction.getSymmetricPrice(price);
  };
}
