import { Grid, styled, Typography } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { ProfitAndLossCalculator } from '@op/shared/src/models/portfolio/profitandloss-calculator';
import { plCalculatorState } from '@op/shared/src/states/portfolio-management-states';
import { portfolioSelectedCombinationState } from '@op/shared/src/states/portfolio-states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BasicCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlaySlider, OptionsPlayTextField, OptionsPlayThumbComponent } from '../styled';

export const PlCalculatorTypography = styled(Typography)(() => ({
  lineHeight: 1.2,
  whiteSpace: 'break-spaces',
}));

export const PLCalculatorWidget: React.FC = () => {
  const portfolioSelectedCombination = useRecoilValue(portfolioSelectedCombinationState);
  const [plCalculator, setPLCalculator] = useRecoilState(plCalculatorState);
  const { t } = React.useContext(LocalizationContext);
  let strategyName = portfolioSelectedCombination.strategyName();
  let [shareQuantity, setShareQuantity] = React.useState(plCalculator.quantity);

  if (!plCalculator || !portfolioSelectedCombination) {
    return <></>;
  }
  const maxQuantity = plCalculator.maxQuantity;
  const minQuantity = plCalculator.minQuantity;
  let sliderTicks: number[] = [];
  let anchor: number[] = [];
  let anchorForContract: number = 0;

  const updateQuantityOnKeypress = (key: string) => {
    let quantity: number = shareQuantity;
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    if (quantity < minQuantity || isNaN(quantity)) {
      quantity = minQuantity;
    } else if (quantity > maxQuantity) {
      quantity = maxQuantity;
    } else {
      quantity = shareQuantity;
    }
    setShareQuantity(quantity);
    updateQuantity(quantity);
  };

  const setPLCalculatorRecoilState = (quantity: number | number[]) => {
    const clonedPLCalculator = ProfitAndLossCalculator.fromSelf(plCalculator);
    clonedPLCalculator.quantity = quantity as number;
    setPLCalculator(clonedPLCalculator);
  };

  const updateQuantity = (quantity: number) => {
    setPLCalculatorRecoilState(quantity);
  };

  const updateQuantityOnSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    setShareQuantity(value as number);
    setPLCalculatorRecoilState(value);
  };
  const renderText = () => {
    if (plCalculator.isShare) {
      if (plCalculator.isShort) {
        return 'covering';
      } else return 'selling';
    } else return 'closing';
  };

  const renderProfitandLossPercentage = () => {
    if (isNaN(plCalculator.realizedProfitAndLossPercentage())) return 0;
    else {
      return NumberFormatHelper.toPercentage(plCalculator.realizedProfitAndLossPercentage());
    }
  };

  const renderDescription = () => {
    return (
      <>
        <PlCalculatorTypography variant="body1">
          {'By'} {renderText()} {plCalculator.quantity} {plCalculator.isShare ? 'shares' : 'contracts'}
          {' of your'}
          {' ' + plCalculator.symbol + ' '}
          {'position, you would realize a '}
        </PlCalculatorTypography>
        <PlCalculatorTypography
          sx={!plCalculator.isProfitable() ? { color: 'red' } : { color: 'green' }}
          variant="body1">
          {NumberFormatHelper.toCurrency(plCalculator.realizedProfitAndLoss())}
        </PlCalculatorTypography>
        <PlCalculatorTypography variant="body1">
          {' '}
          {plCalculator.isProfitable() ? 'profit' : 'loss'}
          {',' + ' a'}
        </PlCalculatorTypography>
        <PlCalculatorTypography
          sx={!plCalculator.isProfitable() ? { color: 'red' } : { color: 'green' }}
          variant="body1">
          {' ' + renderProfitandLossPercentage() + ' '}
        </PlCalculatorTypography>
        <PlCalculatorTypography>{'return.'}</PlCalculatorTypography>
      </>
    );
  };

  const renderTicks = () => {
    if (plCalculator.isShare || maxQuantity > 10) {
      var anchorQuater = Math.ceil(maxQuantity / 4);
      var anchorHalf = Math.ceil(maxQuantity / 2);
      var anchorThreeQuaters = Math.ceil((maxQuantity / 4) * 3);
      anchor = [anchorQuater, anchorHalf, anchorThreeQuaters];
      sliderTicks = [1, anchorQuater, anchorHalf, anchorThreeQuaters, maxQuantity];
    } else {
      for (var i = 1; i <= maxQuantity; i++) {
        sliderTicks.push(i);
      }
      anchorForContract = Math.ceil(maxQuantity / 2);
    }
  };
  renderTicks();

  const sliderMarks = sliderTicks.map((x) => {
    const value: number = x;
    const label: number = x;
    return {
      value,
      label,
    };
  });

  const renderTitle = () => {
    return t('how.toolbox.profitAndLossCalculator.text.selectNumberOfSecurity');
  };

  const renderContent = () => {
    return (
      <Grid container rowSpacing={3}>
        <Grid item container xs={12} justifyContent="center" textAlign="center">
          <OptionsPlaySlider
            components={{ Thumb: OptionsPlayThumbComponent }}
            id="slide-quantity"
            data-name="quantity"
            data-value={shareQuantity}
            value={shareQuantity}
            aria-labelledby="discrete-slider"
            sx={{ width: '55%', pt: 2 }}
            step={1}
            marks={maxQuantity == 1 ? [] : sliderMarks}
            min={minQuantity}
            max={maxQuantity}
            onChange={updateQuantityOnSliderChange}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="center" textAlign="center">
          <Typography component="div" sx={{ display: 'flex' }}>
            <Typography component="span" variant="body1" fontWeight="bold" sx={{ pt: 1, pr: 1 }}>
              {plCalculator.isShare
                ? t('how.toolbox.profitAndLossCalculator.labels.shares')
                : t('how.toolbox.profitAndLossCalculator.labels.contracts')}
            </Typography>
            <OptionsPlayTextField
              variant="outlined"
              type="number"
              id="sharesQty"
              value={shareQuantity}
              onChange={(event) => setShareQuantity(parseFloat(event.target.value))}
              onKeyPress={(event) => {
                updateQuantityOnKeypress(event.key);
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center" textAlign="center">
          {renderDescription()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <BasicCardWidget
        title={
          <Typography variant="subtitle1">
            {renderTitle()?.toString().replace('{SECURITY}', strategyName.toLowerCase())}
          </Typography>
        }
        content={renderContent()}
      />
    </Grid>
  );
};
