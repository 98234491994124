import { Card, CardContent, CardHeader, Grid, SxProps, Theme, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { OPBoldTypograpghy, ScrollableBox } from '../styled';
import { IconComponent } from './icons';

export interface Props {
  sx?: SxProps<Theme>;
  title: ReactNode;
  content: ReactNode;
  isMinimalistic?: boolean;
  isCardElevated?: boolean;
  titleIconName?: string;
  isStepper?: boolean;
}

export const ProfileCardWidget: React.FC<Props> = ({
  sx,
  title,
  content,
  isMinimalistic,
  isCardElevated = true,
  titleIconName,
  isStepper = false,
}: Props) => {
  const theme = useTheme();
  return (
    <Card elevation={isMinimalistic || !isCardElevated ? 0 : 2}>
      {isMinimalistic ? null : (
        <CardHeader
          sx={{ p: 1, backgroundColor: theme.palette.primary.main, height: '45px' }}
          title={
            <Grid item container xs={12} paddingX={1}>
              {titleIconName && <IconComponent name={titleIconName} />}
              <OPBoldTypograpghy variant="subtitle1" sx={{ color: theme.palette.timeFrame.contrastText }}>
                {title}
              </OPBoldTypograpghy>
            </Grid>
          }
        />
      )}
      <CardContent
        sx={sx}
        style={{
          paddingTop: isStepper ? 0 : 4,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: isMinimalistic ? 0 : 12,
          marginTop: isMinimalistic || isStepper ? 0 : 12,
        }}>
        <ScrollableBox>{content}</ScrollableBox>
      </CardContent>
    </Card>
  );
};
