import { INewsFeedProps } from './new-feed-interface';

export class NewsFeed {
  id = '';
  date: Date | undefined;
  headline = '';
  description = '';
  link = '';

  public static fromText = (data: INewsFeedProps): NewsFeed => {
    const value = new NewsFeed();
    value.id = data.id;
    value.date = new Date(data.date);
    value.headline = data.headline;
    value.description = data.description;
    value.link = data.link;
    return value;
  };
}
