import { Slider, SliderThumb, styled } from '@mui/material';
import React from 'react';

export const OptionsPlaySlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.light,
  height: 5,
  padding: '10px 0',
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #fff',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .thumb-bar': {
      height: 8,
      width: 1,
      backgroundColor: '#fff',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.success.main,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.secondary.dark,
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.text.primary,
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#fff',
    height: 8,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: '#fff',
    },
  },
}));
interface OptionsPlayThumbComponentProps extends React.HTMLAttributes<unknown> {}
export function OptionsPlayThumbComponent(props: OptionsPlayThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="thumb-bar" />
      <span className="thumb-bar" />
      <span className="thumb-bar" />
    </SliderThumb>
  );
}
