import { WatchList } from './watch-list';

export class WatchLists {
  data: WatchList[] = [];

  private constructor() {}

  static fromData = (data: any) => {
    if (!data) {
      // throw new Error('Data is undefined');
      return new WatchLists();
    }
    const model = new WatchLists();
    model.data = (data?.watchLists || []).map((w: any) => WatchList.fromData(w));
    return model;
  };

  static fromSelf = (self: WatchLists) => {
    if (!self) {
      throw new Error('Self is undefined');
    }
    const clone = new WatchLists();
    clone.data = self.data.map((wl) => WatchList.fromSelf(wl));
    return clone;
  };

  static fromEmpty = () => {
    return new WatchLists();
  };

  public sort = () => {
    const sortedWatchLists = this.sortByWatchListName().sortByWatchListType();
    return sortedWatchLists;
  };

  private sortByWatchListName = () => {
    this.data.sort((watchList1, watchList2) => (watchList1.name < watchList2.name ? 1 : -1));
    return this;
  };

  // Sort By Global or User(None)
  private sortByWatchListType = () => {
    this.data.sort((watchList1, watchList2) => (watchList1.type > watchList2.type ? 1 : -1));
    return this;
  };

  // public static fromSelf = (lists: WatchLists): WatchLists => {
  //   if (!lists) {
  //     throw Error('Lists is null or undefined');
  //   }
  //   //const model = new WatchLists(lists);
  //   return model;
  // };
}
