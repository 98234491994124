export class ErrorResponse {
  property = '';
  message = '';
  ErrorCode?: number;

  public static fromMessage(msg: string, errorCode?: number): ErrorResponse {
    const response = new ErrorResponse();
    response.message = msg;
    response.ErrorCode = errorCode;
    return response;
  }
}
