import { EventType, WatchListsOperationTypes, WatchListsTypes } from '@op/shared/src/models/enums/enums';
import { Grid, Tooltip, useTheme } from '@mui/material';
import { IconComponent, ViewFiltersByCount2 } from '../common';
import { Quote, WatchList, cloneDeep } from '@op/shared/src/models';
import React, { useState } from 'react';
import {
  accountState,
  deletedWatchlistItemState,
  filtersWatchListState,
  originalWatchListState,
  selectedSymbolState,
  selectedWatchListState,
  tradesCachedState,
  watchListCrudOperationState,
  watchListIdeaAvailableCountState,
  watchListPortfolioPositionsCountState,
} from '@op/shared/src/states';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import ApplicationContext from '@op/shared/src/models/how/application-context';
import { CompanySearchWidget } from '../quoteBar/company-search-widget';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { OPIconButton } from '../styled';
import { WatchlistService } from '@op/shared/src/services';
import { coveredCallDataCachedState } from '@op/shared/src/states/reports/covered-call-states';
import { creditSpreadDataCachedState } from '@op/shared/src/states/reports/credit-spread-states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { shortPutDataCachedState } from '@op/shared/src/states/reports/short-put-states';
import { GuideItem } from '../side-menu';

interface Props {
  canManageGlobalB2BWatchList: boolean;
  allowUserWatchlist: boolean;
}

export const ManagerThirdRow: React.FC<Props> = ({ canManageGlobalB2BWatchList, allowUserWatchlist }: Props) => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const account = useRecoilValue(accountState);
  const linkedPortfoliosCount = useRecoilValue(watchListPortfolioPositionsCountState);
  const linkedIdeasCount = useRecoilValue(watchListIdeaAvailableCountState);
  const setDeletedWatchListItem = useSetRecoilState(deletedWatchlistItemState);
  const setNotifications = useSetRecoilState(notificationsState);
  const resetGlobalFilters = useResetRecoilState(filtersWatchListState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const resetCoveredCalls = useResetRecoilState(coveredCallDataCachedState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);

  const [watchListOperation, setWatchListOperation] = useRecoilState(watchListCrudOperationState);
  const [watchList, setWatchList] = useRecoilState<WatchList | undefined>(selectedWatchListState);
  const [watchListFilters, setWatchListFilters] = useRecoilState(filtersWatchListState);
  const [originalWatchList, setOriginalWatchList] = useRecoilState(originalWatchListState);
  const [companySearch, setCompanySearch] = useState(false);
  const theme = useTheme();

  const canCreateNewWatchlist =
    watchListOperation === WatchListsOperationTypes.NEW || watchListOperation === WatchListsOperationTypes.NEWGLOBAL;

  const canAccessTradeIdeas = account.canWorkWithTradeIdeas;
  const canAccessPortfolio = account.canWorkWithPortfolios;

  // TODO: Recheck the isSSO condition with permissions
  // const isSSO = account.securityModel?.isSSO;
  // const isSSOChild = account.securityModel?.isSSOChild;

  const shouldOptionsBeDisabled = (): boolean => {
    if (canManageGlobalB2BWatchList || !watchList) {
      return false;
    }
    return (
      watchList.type.toUpperCase() === WatchListsTypes.GLOBAL_B2C.toUpperCase() ||
      watchList.type.toUpperCase() === WatchListsTypes.GLOBAL_B2B.toUpperCase()
    );
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const addSymbolAsync = async (symbol: string) => {
    if (!originalWatchList) {
      return;
    }
    const isQuoteExist = originalWatchList.quotes.find(
      (q) => q.symbol.trim().toUpperCase() === symbol.trim().toUpperCase(),
    );
    if (isQuoteExist) {
      setNotifications([{ type: 'error', content: 'Symbol already exist' }]);
      return;
    }
    const quoteResponse = await WatchlistService.instance.getWatchListQuote(symbol);
    if (quoteResponse.hasErrors || !quoteResponse.data) {
      setNotifications([{ type: 'error', content: 'Something went wrong. Please try again.' }]);
      return;
    }
    const clone = WatchList.fromSelf(originalWatchList);
    clone.quotes.push(Quote.fromData(quoteResponse.data));
    const saveResponse = await WatchlistService.instance.save({
      list: watchList.id,
      symbols: clone.quotes.map((q) => q.symbol),
      removed: [],
    });
    if (!saveResponse.data) {
      setNotifications([{ type: 'error', content: 'Something went wrong. Please try again.' }]);
      return;
    }
    setNotifications([{ type: 'success', content: 'Operation successful' }]);
    resetGlobalFilters();
    setWatchList(clone);
    setOriginalWatchList(clone);
    resetTradeIdeas();
    resetCreditSpreads();
    resetCoveredCalls();
    resetShortPuts();
  };

  const onFilterClick = (group: IFilterGroup) => {
    const clone = cloneDeep(watchListFilters);
    let group1 = clone.find((g) => g.name === group.name);
    if (!group1) {
      return;
    }
    const filtered = clone.filter((g) => g.name !== group.name);
    filtered.push(group);
    setWatchListFilters(filtered);
  };

  const getFilter = (name: 'linkedPortfolios' | 'linkedIdeas', count: number) => {
    const f = watchListFilters.find((g) => g.name === name);
    const clone = cloneDeep(f);
    if (clone.filters.length === 0) {
      return;
    }
    clone.filters[0].title = count.toString();
    return clone;
  };

  const removeWatchListfromCompanySearch = (symbol: string | undefined) => {
    if (!symbol || !watchList) {
      return;
    }
    let deletedSymbols: string[] = [];
    const newWatchList = WatchList.fromSelf(watchList);
    newWatchList.quotes = newWatchList.quotes.filter((item) => item.symbol !== symbol);
    deletedSymbols.push(symbol);
    setDeletedWatchListItem(deletedSymbols);
    setWatchListOperation(WatchListsOperationTypes.EDIT);
    setWatchList(newWatchList);
  };

  const renderSearchOrCountView = () => {
    if (companySearch) {
      return (
        <Grid item xs={10}>
          <GuideItem selector=".whatchListSymbolLookup_helpPinPlaceholder" />
          <CompanySearchWidget
            onSymbolSelected={(v) => {
              addSymbolAsync(v);
              logActivity('watchListAddSymbol', 'watchListAddSymbol');
            }}
            sx={{ p: 0 }}
            removeWatchList={(symbol) => removeWatchListfromCompanySearch(symbol)}
            watchList={watchList}
            //TODO : needs to be removed
            isQuoteFound={() => {}}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={6} container columnSpacing={1}>
        {canAccessPortfolio && (
          <Grid item xs={6}>
            <ViewFiltersByCount2
              filter={getFilter('linkedPortfolios', linkedPortfoliosCount)}
              onClick={onFilterClick}
              icon="personalPortfolioIcon"
              stroke={linkedPortfoliosCount ? theme.palette.primary.main : '#999999'}
            />
          </Grid>
        )}
        {canAccessTradeIdeas && (
          <Grid item xs={6}>
            <ViewFiltersByCount2
              filter={getFilter('linkedIdeas', linkedIdeasCount)}
              onClick={onFilterClick}
              icon="tradeHover"
              stroke={linkedIdeasCount ? theme.palette.primary.main : '#999999'}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const canShowThirdRow = () => {
    /*
      1. if isSSO = true
      2. if selectedWatchListWithoutQuotes.type !== WatchListsTypes.NONE
      3. if watchListOperation === WatchListsOperationTypes.NEW
    */
    if (!account || !account.isSearchBarClickable) {
      return false;
    }
    if (watchListOperation === WatchListsOperationTypes.EDIT) {
      return false;
    }
    if (canCreateNewWatchlist) {
      return false;
    }
    if (!allowUserWatchlist) {
      return false;
    }
    if (!shouldOptionsBeDisabled()) {
      return true;
    }
    return false;
  };

  const renderView = () => {
    if (!canShowThirdRow()) {
      return;
    }
    return (
      <Grid item xs={12} justifyContent={'space-between'} container>
        {renderSearchOrCountView()}
        {companySearch ? null : <GuideItem selector=".watchListsAddSymbolButton_helpPinPlaceholder" />}
        <Grid item xs={'auto'}>
          <Tooltip title={!companySearch ? 'Click to open search and add symbol' : null} placement="top" arrow>
            <OPIconButton
              id={'js-search-toggle-icon'}
              disableRipple={companySearch}
              sx={{
                backgroundColor: companySearch ? theme.palette.primary.light : null,
                borderColor: companySearch ? theme.palette.primary.main : null,
              }}
              onClick={() => {
                setCompanySearch(!companySearch);
              }}>
              <IconComponent name={'searchIcon'} stroke={theme.palette.info.light} />
            </OPIconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return renderView();
};
