import { EditPortfolioResponse } from ".";

export interface IPortfoliouploadData {
  enabled: boolean;
  id: number;
  isGlobal: boolean;
  name: string;
  portfolioTypeStatus: string;
}

export class PortfoliouploadData implements IPortfoliouploadData {
  enabled = true;
  id = 0;
  isGlobal = false;
  name = '';
  portfolioTypeStatus = '';

  public static fromJson = (items: PortfoliouploadData): PortfoliouploadData => {
    const model = new PortfoliouploadData();
    model.enabled = items.enabled;
    model.id = items.id;
    model.isGlobal = items.isGlobal;
    model.name = items.name;
    model.portfolioTypeStatus = items.portfolioTypeStatus;
    return model;
  };

  static fromSelf = (data: PortfoliouploadData) => {
    const clone = new PortfoliouploadData();
    clone.enabled = data.enabled;
    clone.id = data.id;
    clone.isGlobal = data.isGlobal;
    clone.name = data.name;
    clone.portfolioTypeStatus = data.portfolioTypeStatus;
    return clone;
  };
}
