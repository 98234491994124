import { Constants } from '@op/shared/src/constant';
import { UserActivityHub } from '@op/shared/src/hubs/user-activity-hub';
import { StrategiesProvider } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { getThemeType } from '@op/shared/src/models/theme-type';
import { getLocales, PowerhouseProService } from '@op/shared/src/services';
import { accountState, configurationState, isConfigurationUpdatedState, themeState } from '@op/shared/src/states';
import React, { useEffect, useRef } from 'react';
import { SharePageWidget } from './share-page-widget';
import { Route, Routes, useLocation, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ProfilePageWidget } from '../accounts';
import { EmbedderPageWidget } from '../embedded/embedder-widget';
import { HeaderWidget } from '../header';
import { CoveredCalls } from '../ideas/covered-call/covered-calls';
import { CreditSpreads } from '../ideas/credit-spread/credit-spreads';
import { ShortPuts } from '../ideas/short-put/short-puts';
import { TechnicalIdeas } from '../ideas/technical-idea/technical-ideas';
import { ForgotPasswordWidget } from '../login/forgot-password-widget';
import { LoginPageWidget } from '../login/login-page-widget';
import { RegistrationPageWidget } from '../login/registration-page-widget';
import { ResetWidget } from '../login/reset';
import { GraphPageWidget } from '../op-chart/graph-page-widget';
import { useConnectPowerhouseProHub } from '../states/use-connect-powerhouse-pro-hub';
import { useFetchAndSetAccount } from '../states/use-fetch-and-set-account';
import { useGlobalization } from '../states/use-globalization';
import { usePreviousLocation } from '../states/use-previous-location';
import { WatchListsWidget } from '../watchlists';
import { AppContainer } from './app-container';
import { PortfolioHomePageWidget } from './portfolio-home-page-widget';
import { ProtectedRoute } from './protected-route';
import { ReportsContainer } from './reports-container';
import { ScreenshotWidget } from './screenshot-widget';
import { SeamlessLoginWidget } from './seamless-login-widget';
import { PageLoaderWidget } from './skeleton-page-widget';
import { TSLoginWidget, TSLogOutWidget } from '../tradestation';
import { SubscriptionPageWidget } from '../subscription';

const RouterWidget = () => {
  const fetchAndSetAccount = useFetchAndSetAccount();
  const connectPowerhouseProHub = useConnectPowerhouseProHub();
  const setGlobalizationLanguage = useGlobalization();
  const [searchParams] = useSearchParams();
  const account = useRecoilValue(accountState);
  const navigate = useNavigate();
  const embeddedRoute = useMatch('/embedded/:widgetName');
  const seamlessLoginRoute = useMatch('/authentication');
  const screenshotRoute = useMatch('/screenshot/:id');
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  const isConfigurationUpdated = useRecoilValue(isConfigurationUpdatedState);
  const setActiveTheme = useSetRecoilState(themeState);
  const location = useLocation();
  usePreviousLocation(location);
  const configRef = useRef(false);

  // Reroute to seamless login if token is passed. Seamless will reroute to destination after logging in.
  const isReroutingRequired = () => {
    //<domain>/authentication?hashToken=nC3MWZyl5ZH64rTRumX8xQXen1ixYR66e3qmY2Q
    if (seamlessLoginRoute) {
      return true;
    }
    const hash = searchParams.get(Constants.hashTokenKey);
    if (!hash || hash.trim() === '') {
      return false;
    }
    //delete the hashToken from url
    searchParams.delete(Constants.hashTokenKey);
    //<domain>/share/624d59a653479e0a384e4968?hashToken=nC3MWZyl5ZH64rTRumX8xQXen1ixYR66e3qmY2Q
    //<domain>/share/624d59a653479e0a384e4968 = pathname required "/share/624d59a653479e0a384e4968"
    //<domain>/share/hash=ixb5w25ervoxakds4nfztstthpbx4uybzdmszscwcmwka6awys3clx4cfialkdol5fp2ibzhwvqctkrv22whnb2wzghdni7a7uulznc4moahursgn7yayp4m2kx33flsvblssxjqn2zpsteqdivj3rcqme
    //<domain>/share/hash=ixb5w25ervoxakds4nfztstthpbx4uybzdmszscwcmwka6awys3clx4cfialkdol5fp2ibzhwvqctkrv22whnb2wzghdni7a7uulznc4moahursgn7yayp4m2kx33flsvblssxjqn2zpsteqdivj3rcqme?hashToken=nC3MWZyl5ZH64rTRumX8xQXen1ixYR66e3qmY2Q
    //<domain>/share/OCC1=LIT&T1=Buy&Q1=100&OCC2=LIT220715C00078000&T2=Sell&Q2=1&view=income
    //<domain>/share/OCC1=LIT&T1=Buy&Q1=100&OCC2=LIT220715C00078000&T2=Sell&Q2=1&view=income?hashToken=nC3MWZyl5ZH64rTRumX8xQXen1ixYR66e3qmY2Q
    //<domain>/authentication?hashToken=nC3MWZyl5ZH64rTRumX8xQXen1ixYR66e3qmY2Q&returnUrl=%2Fshare%2Fhash%3D2coirlqrobhzqdvuzbp2q7ikpigm2j4z3f6ptdkq4x3i7wgci5i52rvt76g75qpwosqmbw2hekgaq3wrtmnlbsudtnx6tsi7uhcubrdkunpl2l5ugtz5ytifdr5uh25t
    const returnUrl = encodeURIComponent(`${window.location.pathname}${searchParams.toString()}`);
    navigate(`/authentication?${Constants.hashTokenKey}=${hash}&${Constants.returnUrlKey}=${returnUrl}`, {
      replace: true,
    });
    return true;
  };

  React.useEffect(() => {
    if (isReroutingRequired()) {
      return;
    }
    if (seamlessLoginRoute || screenshotRoute || embeddedRoute || account) {
      return;
    }
    (async () => {
      await fetchAndSetAccount();
    })();
  }, []);

  useEffect(() => {
    if (screenshotRoute || embeddedRoute) {
      return;
    }
    if (configRef.current) {
      return;
    }
    // need to check before or after login
    (async () => {
      configRef.current = true;
      const configurationResponse = await PowerhouseProService.instance.Configuration();
      const localeResponse = await getLocales();
      const configuration = configurationResponse.data;
      if (!configuration || !localeResponse || !localeResponse.data) {
        return;
      }
      if (configuration.strategiesConfiguration) {
        StrategiesProvider.initialize(configuration.strategiesConfiguration.allowedStrategies);
      }
      const theme = getThemeType(configuration.userSettings?.theme || 'light');
      setConfiguration(configuration);
      setGlobalizationLanguage(localeResponse, configuration, false);
      setActiveTheme(theme);
    })();
  }, []);

  // Only for logged user and allowed routes
  useEffect(() => {
    if (!account || screenshotRoute) {
      return;
    }
    // isConfigurationUpdated === 1 means its updated only once. which is initial load.
    // The configuration ensures that we have default symbol to pull how and why data.
    // TODO: instead of number, lets have fixed enum values to denote that.
    if (isConfigurationUpdated > 2 || isConfigurationUpdated < 1) {
      return;
    }
    // (async () => {
    //   const response = await getPaymentPlans();
    //   if (response.hasErrors || response.data === undefined) {
    //     return;
    //   }
    //   setCurrentSubscription(response.data);
    // })();
    // updateCustomization(configuration, account);
    connectPowerhouseProHub();
  }, [account, isConfigurationUpdated]);

  // connecting user activity hub
  React.useEffect(() => {
    if (!account || screenshotRoute) {
      return;
    }
    (async () => await connectUserActivityHub())();
  }, [account]);

  let userActivityIntervalId: any | undefined;
  const connectUserActivityHub = async () => {
    if (!account) {
      return;
    }
    const userActivityHub = UserActivityHub.fromSocketConnection(ApplicationContext.accessToken || '');
    userActivityIntervalId = await userActivityHub.connect();
    userActivityHub.socketConnection.onDisconnect = () => {
      clearInterval(userActivityIntervalId);
      setTimeout(async () => {
        await connectUserActivityHub();
      }, 5000);
    };
    ApplicationContext.userActivityHub = userActivityHub;
  };

  // ScreenshotRoute needs to be checked as There is no configuration setting is done.
  if (!configuration && !screenshotRoute && !embeddedRoute) {
    return <PageLoaderWidget />;
  }

  return (
    <>
      <HeaderWidget />
      <Routes>
        <Route path="login" element={<LoginPageWidget />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenTradeIdeas">
                  <TechnicalIdeas />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="idea"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenTradeIdeas">
                  <TechnicalIdeas />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="idea/technical-idea"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenTradeIdeas">
                  <TechnicalIdeas />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="idea/credit-spread"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenTradeIdeas">
                  <CreditSpreads />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="idea/covered-call"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenTradeIdeas">
                  <CoveredCalls />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="idea/short-put"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenTradeIdeas">
                  <ShortPuts />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="profile/*"
          element={
            // <ProtectedRoute>
            <ProfilePageWidget />
            // </ProtectedRoute>
          }
        />
        <Route
          path="watchlist"
          element={
            <ProtectedRoute>
              <AppContainer>
                <ReportsContainer title="what.menu.OpenWatchlist">
                  <WatchListsWidget />
                </ReportsContainer>
              </AppContainer>
            </ProtectedRoute>
          }
        />
        <Route
          path="portfolio"
          element={
            <ProtectedRoute>
              <PortfolioHomePageWidget />
            </ProtectedRoute>
          }
        />
        <Route
          path="graph/:symbol"
          element={
            <ProtectedRoute>
              <GraphPageWidget />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile/tradestation/signin"
          element={
            <ProtectedRoute>
              <TSLoginWidget />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile/tradestation/signout"
          element={
            <ProtectedRoute>
              <TSLogOutWidget />
            </ProtectedRoute>
          }
        />
        <Route path="/embedded/:widgetName" element={<EmbedderPageWidget />} />
        <Route path="/share/*" element={<SharePageWidget />} />
        <Route path="/authentication" element={<SeamlessLoginWidget />} />
        <Route path="/registration" element={<RegistrationPageWidget />} />
        <Route path="/forgot" element={<ForgotPasswordWidget />} />
        <Route path="/password/reset" element={<ResetWidget />} />
        <Route path="/subscription" element={<SubscriptionPageWidget />} />
        <Route path="/screenshot/:id" element={<ScreenshotWidget />} />
        <Route path="*" element={<LoginPageWidget />} />
      </Routes>
    </>
  );
};

export default RouterWidget;
