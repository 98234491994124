import { Grid, Skeleton, Typography } from '@mui/material';
import { TSOrderType } from '@op/shared/src/models/enums/enums';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { OptionsPlayTextField } from '../../styled';
import { useFetchandSetOptionQuotesStream } from '../components/useStream';
import { tsRefreshAccountTokenState, tsSelectedAccountIdsState } from '../states/trade-station-states';
import {
  commonOrderRequestState,
  tsBidMidAskValueState,
  tsDualLoginErrorState,
  tsOrdetTicketPositionState,
} from '../states/ts-ticket-states';

const BidMidAskTextFieldStyle = {
  width: '100%',
  borderRadius: '8px',
  backgroundColor: 'D9D9D9',
  '& .MuiInputBase-input': {
    paddingY: '3px',
    paddingX: '8px',
    cursor: 'pointer',
  },
  margin: '1px',
};

interface Props {
  orderType: string;
}

export const BidMidAskWidget: React.FC<Props> = ({ orderType }: Props) => {
  const tsOrderTicketPositions = useRecoilValue(tsOrdetTicketPositionState);
  const bidMidAskValue = useRecoilValue(tsBidMidAskValueState);
  const [commonOrderRequest, setCommonOrderRequest] = useRecoilState(commonOrderRequestState);
  const setTSDualLoginError = useSetRecoilState(tsDualLoginErrorState);

  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const fetchOptionQuotes = useFetchandSetOptionQuotesStream();
  const [refreshAccountToken, setRefreshAccountToken] = useRecoilState(tsRefreshAccountTokenState);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return;
    }
    if (!tsOrderTicketPositions || tsOrderTicketPositions.length === 0) {
      return;
    }
    const optionQuotesStreamController = new AbortController();
    const symbols: any[] = tsOrderTicketPositions.map((p) => ({
      symbol: p.symbol,
      type: p.type,
      ratio: p.qty,
    }));

    (async () => {
      await fetchOptionQuotes(optionQuotesStreamController, symbols);
    })();
    return () => {
      setTimeout(() => {
        try {
          if (optionQuotesStreamController.signal.aborted) {
            return;
          }
          optionQuotesStreamController.abort('executed option quotes abort');
          if (refreshAccountToken) {
            setRefreshAccountToken(true);
          }
        } catch (e) {
          if (e instanceof DOMException && e.name === 'AbortError') {
          } else {
            console.log('Download error: ' + e.message);
          }
        }
      }, 100);
    };
  }, [selectedAccountIds, tsOrderTicketPositions, refreshAccountToken]);

  if (!bidMidAskValue) {
    return (
      <Grid container columnSpacing={1}>
        <Grid item xs={4}>
          <Typography variant="captionSmall">{'Natural'}</Typography>
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={24} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="captionSmall">{'Mid'}</Typography>
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={24} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="captionSmall">{'Far'}</Typography>
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={24} />
        </Grid>
      </Grid>
    );
  }

  const onClickBidMidAsk = (value: string) => {
    if (orderType !== TSOrderType.Limit && orderType !== TSOrderType.StopLimit) {
      return;
    }
    setCommonOrderRequest({ ...commonOrderRequest, limitPrice: value });
  };

  const renderBidMidAsk = () => {
    return (
      <Grid container columnSpacing={1}>
        <Grid item xs={4}>
          <Typography variant="captionSmall">{'Natural'}</Typography>
          <OptionsPlayTextField
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            id="order_ticket_ask_price"
            name="order_ticket_ask_price"
            value={bidMidAskValue.ask}
            variant="filled"
            sx={BidMidAskTextFieldStyle}
            onClick={() => onClickBidMidAsk(bidMidAskValue.ask)}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="captionSmall">{'Mid'}</Typography>
          <OptionsPlayTextField
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            id="order_ticket_mid_price"
            name="order_ticket_mid_price"
            value={bidMidAskValue.mid}
            variant="filled"
            sx={BidMidAskTextFieldStyle}
            onClick={() => onClickBidMidAsk(bidMidAskValue.mid)}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="captionSmall">{'Far'}</Typography>
          <OptionsPlayTextField
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            id="order_ticket_bid_price"
            name="order_ticket_bid_price"
            value={bidMidAskValue.bid}
            variant="filled"
            sx={BidMidAskTextFieldStyle}
            onClick={() => onClickBidMidAsk(bidMidAskValue.bid)}
          />
        </Grid>
      </Grid>
    );
  };

  return renderBidMidAsk();
};
