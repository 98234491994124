import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { INotification, notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IconComponent } from './icons';

export const NotificationWidget: React.FC = () => {
  const notifications = useRecoilValue(notificationsState);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!notifications) {
      setOpen(false);
      return;
    }
    setOpen(notifications.length > 0);
  }, [notifications]);
  if (!notifications) {
    return null;
  }
  return (
    <>
      {notifications.map((alert: INotification, index: number) => (
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          key={index}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert
            key={alert.type}
            severity={alert.type}
            variant="filled"
            iconMapping={{
              success: alert.isTradeStation && <IconComponent name="tsLogo" />,
              info: alert.isTradeStation && <IconComponent name="tsLogo" />,
              warning: alert.isTradeStation && <IconComponent name="tsLogo" />,
              error: alert.isTradeStation && <IconComponent name="tsLogo" />,
            }}
            sx={{
              opacity: '0.8!important',
              width: '100%',
              pl: 2,
              pr: 5,
            }}>
            {alert.content}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
