import { ExpandedQuoteHubWidget, TechnicalScoreWidget } from '../common';
import { Grid, Typography } from '@mui/material';
import { IReportProps, IRowProps, IdeaProps } from './pager';
import { OPBoldTypograpghy } from '../styled';
import { GuideItem } from '../side-menu';

export const SecondRow: React.FC<IdeaProps<IRowProps | IReportProps>> = ({ data, isTechnicalIdea }: IdeaProps<IRowProps | IReportProps>) => {
  let signals = data.signals;

  const renderDTE = () => {
    return (
      <>
        <Typography variant="caption" noWrap>
          DTE
        </Typography>
        <OPBoldTypograpghy variant="caption" pl={0.2}>
          {(data as IReportProps).daysToExpiry}
        </OPBoldTypograpghy>
      </>
    );
  };

  const renderStrength = () => {
    return (
      <>
        {signals && <TechnicalScoreWidget data={signals} isOnlyScore />}
      </>
    );
  };

  return (
    <Grid container xs={12} justifyContent="space-between">
      <Grid item xs={'auto'} pr={0.4}>
        <ExpandedQuoteHubWidget data={data.symbol} />
      </Grid>
      <Grid item xs={'auto'} justifyContent="right">
        {isTechnicalIdea ? renderStrength() : renderDTE()}
      </Grid>
    </Grid>
  );
};
