import { IPortfolioAlertProps } from '.';

export class EditPortfolioAlert implements IPortfolioAlertProps {
  Value = true;

  public static fromJson = (data: EditPortfolioAlert): EditPortfolioAlert => {
    const model = new EditPortfolioAlert();
    model.Value = data.Value;
    return model;
  };
}
