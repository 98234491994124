import { SentimentType } from '@op/shared/src/models';
import { EventType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import TradingStrategies from '@op/shared/src/models/how/trading-strategies';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { customizationState } from './customization-states';
import { howDataState } from './data-states';
import { tradingRangeSimulatorAtom, tradingStrategiesSentimentAtom, tradingStrategiesUpdater, viewState } from './how';
import { notificationsState } from './notification-states';
import { selectedSymbolState, tradeSimulatorOperationTypeState } from './trade';

export const useOnSentimentChange = () => {
  const howData = useRecoilValue(howDataState);
  const customization = useRecoilValue(customizationState);
  const view = useRecoilValue(viewState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setTradingStrategies = useSetRecoilState(tradingStrategiesUpdater);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorAtom);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setStrategySentiment = useSetRecoilState(tradingStrategiesSentimentAtom);
  const setNotifications = useSetRecoilState(notificationsState);
  React.useEffect(() => {}, []);

  const onSentimentChange = (value: string) => {
    if (!howData) {
      return;
    }
    if (!howData.chain || howData.chain.rows.length === 0) {
      setNotifications([{ type: 'info', content: `Symbol ${howData.symbol.toUpperCase()} has no listed options` }]);
    }
    const sentiment = howData.toSentimentType(value);
    if (sentiment === SentimentType.NEUTRAL && !customization?.showNeutralStrategy) {
      return;
    }
    //TODO: Refactor-on change of sentiment, we should not create new instance of trading strategies. we should just fill the combination collection.
    const tradingStrategies = TradingStrategies.fromData(sentiment, howData);
    const combinations = tradingStrategies.allCombinations;
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, combinations);
    setStrategySentiment(sentiment);
    setTradingStrategies(combinations);
    setTradingRangeSimulator(tradingRangeSimulator);
    setSimulatorOperation(
      view === 'income' ? tradeSimulatorOperationType.OptionsGrid : tradeSimulatorOperationType.PLSimulator,
    );
    if (
      (value == SentimentType.BULLISH || value == SentimentType.BEARISH || SentimentType.NEUTRAL) &&
      !selectedSymbol
    ) {
      ApplicationContext.userActivityHub?.logActivity(
        // Bullish? yesBullish : Bearish? yesBearish : Nutral;
        value == SentimentType.BULLISH
          ? 'showBullishStrategies'
          : value == SentimentType.BEARISH
          ? 'showBearishStrategies'
          : 'showNeutralStrategies',
        value == SentimentType.BULLISH
          ? 'showBullishStrategies'
          : value == SentimentType.BEARISH
          ? 'showBearishStrategies'
          : 'showNeutralStrategies',
        '',
        EventType.Click,
        selectedSymbol,
      );
    }
  };

  return onSentimentChange;
};
