import { SentimentType } from '../models';

export class SentimentTerm {
  name: string;
  text: string;
  sentiment: SentimentType;
  secondary: string | undefined;
  value = -99;

  constructor(name: string, text: string, sentitment: SentimentType, value: number, secondary?: string) {
    this.name = name;
    this.text = text;
    this.sentiment = sentitment;
    this.value = value;
    this.secondary = secondary;
  }
}

//{"symbol":"SPY","syrahShortSentiment":4,"syrahLongSentiment":4}
export class SentimentModel {
  symbol: string = '';
  syrahShortSentiment: SentimentTerm | undefined;
  syrahLongSentiment: SentimentTerm | undefined;

  static fromData = (data: { symbol: string; syrahShortSentiment: number; syrahLongSentiment: number }) => {
    const model = new SentimentModel();
    model.symbol = data.symbol;
    model.syrahShortSentiment = model.getSentimentTerm(data.syrahShortSentiment);
    model.syrahLongSentiment = model.getSentimentTerm(data.syrahLongSentiment);
    return model;
  };

  static fromSelf = (self: SentimentModel) => {
    const model = new SentimentModel();
    model.symbol = self.symbol;
    model.syrahShortSentiment = self.syrahShortSentiment; // Create fromSelf for SentimentTerm class.
    model.syrahLongSentiment = self.syrahLongSentiment;
    return model;
  };

  private getSentimentTerm = (value: number) => {
    switch (value) {
      case -4:
        return new SentimentTerm('bearish', 'bearish', SentimentType.BEARISH, value, 'overextended');
      case -3:
        return new SentimentTerm('bearish', 'bearish', SentimentType.BEARISH, value);
      case -2:
        return new SentimentTerm('bearish', 'bearish', SentimentType.BEARISH, value);
      case -1:
        return new SentimentTerm('neutral', 'neutral', SentimentType.NEUTRAL, value);
      case 0:
        return new SentimentTerm('mildlyBearish', 'mildly bearish', SentimentType.BEARISH, value);
      case 1:
        return new SentimentTerm('mildlyBullish', 'mildly bullish', SentimentType.BULLISH, value);
      case 2:
        return new SentimentTerm('neutral', 'neutral', SentimentType.NEUTRAL, value);
      case 3:
        return new SentimentTerm('bullish', 'bullish', SentimentType.BULLISH, value);
      case 4:
        return new SentimentTerm('bullish', 'bullish', SentimentType.BULLISH, value);
      case 5:
        return new SentimentTerm('bullish', 'bullish', SentimentType.BULLISH, value, 'overextended');
      default:
        return undefined;
    }
  };
}
