import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { CardContent, CardHeader, Grid, Typography, useTheme, Menu, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { IconComponent } from '../common';
import { OPBoldTypograpghy } from '../styled';

export const TsInfoWidget: React.FC = () => {
  const theme = useTheme();
  const [showInformationToolTip, setShowInformationToolTip] = React.useState<null | HTMLElement>(null);

  const handleInformationClose = () => {
    setShowInformationToolTip(null);
  };

  const handleInformationToggle = (event: React.MouseEvent<HTMLElement>) => {
    setShowInformationToolTip(showInformationToolTip ? null : event.currentTarget);
  };
  const renderCardHeader = () => {
    return (
      <Grid container alignItems={'center'}>
        <Grid item container xs={11}>
          <IconComponent name="tsLogo" />
          <OPBoldTypograpghy
            variant="subHeader"
            marginX={1}
            sx={{}}>{`TradeStation Important Information`}</OPBoldTypograpghy>
        </Grid>
        <Grid item xs={1} textAlign={'right'}>
          <ClearSharpIcon
            onClick={handleInformationClose}
            fontSize="small"
            sx={{ '&:hover': { cursor: 'pointer' }, textAlign: 'right' }}
          />
        </Grid>
      </Grid>
    );
  };
  const renderCardContent = () => {
    return (
      <Grid>
        <Typography sx={{ fontWeight: 'bold' }}>{`TradeStation market data is provided by TradeStation`}</Typography>
        <Typography>
          {`
OptionsPlay is not responsible for any Loss or Profits resulting from your use or inability to access or use the service.`}
        </Typography>
      </Grid>
    );
  };
  const renderCard = () => {
    return (
      <Grid>
        <CardHeader
          style={{
            height: 40,
            background: theme.palette.primary.main,
            width: '100%',
            borderTopRightRadius: theme.shape.borderRadius,
            borderTopLeftRadius: theme.shape.borderRadius,
            color: 'whitesmoke',
            alignItems: 'center',
          }}
          titleTypographyProps={{ variant: 'subHeader', fontWeight: 'bold' }}
          title={renderCardHeader()}
        />
        <CardContent
          style={{
            backgroundColor: theme.palette.info.dark,
          }}>
          {renderCardContent()}
        </CardContent>
      </Grid>
    );
  };
  return (
    <div>
      <Tooltip title={'TS Doc Info'} placement="top" arrow>
        <IconButton onClick={handleInformationToggle}>
          <IconComponent name="tsInfo" stroke="#999999" size={18} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={showInformationToolTip}
        open={Boolean(showInformationToolTip)}
        onClose={handleInformationClose}
        PaperProps={{
          sx: {
            overflow: 'visible',
            width: '350px',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: '100%',
              right: 12,
              margin: 'auto',
              width: 10,
              height: 10,
              bgcolor: theme.palette.info.dark,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '.MuiMenuList-root': { padding: 0 },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{ sx: { padding: 0 } }}>
        <Grid container>{renderCard()}</Grid>
      </Menu>
    </div>
  );
};
