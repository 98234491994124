import { CacheService } from '../services/cache-service';
import { ExpandedQuote } from '../models';
import { PhpHubEvents } from '../models/enums/enums';
import { HubSubscription } from './hub-subscription';
import { PowerhouseProHubConnection } from './powerhouse-pro-hub-connection';

export class QuoteSubscription extends HubSubscription<ExpandedQuote> {
  private cache: CacheService<ExpandedQuote> | undefined;

  private constructor() {
    super();
  }

  static fromSocketConnection = (
    socketConnection: PowerhouseProHubConnection,
    shareId?: string | undefined,
    token?: string | undefined,
  ) => {
    const model = new QuoteSubscription();
    model.fromSocketConnection(
      socketConnection,
      'subscribeToSymbols',
      'unsubscribeFromSymbols',
      'getQuotes',
      PhpHubEvents.QUOTES,
      shareId,
      token,
    );
    model.cache = CacheService.fromCallback('quote', model.getItems);
    return model;
  };

  mapItemsFromServer = (items: any[]) => {
    if (!items) {
      return [];
    }
    const quotes = items.map((i) => ExpandedQuote.fromSelf(i));
    return quotes;
  };

  get = async (symbols: string[]) => {
    if (!this.cache) {
      throw new Error('cache is undefined');
    }
    const quotes = await this.cache.get(symbols);
    return quotes.filter((q) => q !== undefined);
  };

  set = (models: ExpandedQuote[]) => {
    if (!this.cache) {
      throw new Error('cache is undefined');
    }
    this.cache.set(models);
  };
}
