import { ThemeType } from '@op/shared/src/models';

const questTradeEdgeDesktopLight = {
  name: { theme: 'ChartIQLightTheme' },
  value: {
    ChartIQLightTheme: {
      settings: {
        chart: {
          Background: { color: 'rgba(255,255,255,1)' },
          'Grid Lines': { color: 'rgba(239,239,239,1)' },
          'Grid Dividers': { color: 'rgba(204,204,204,1)' },
          'Axis Text': { color: 'rgba(102,102,102,1)' },
        },
        chartTypes: {
          'Candle/Bar': {
            up: { color: 'rgba(65,164,6,1)', wick: 'rgba(0,0,0,1)', border: 'rgba(0,0,0,1)' },
            down: { color: 'rgba(192,23,23,1)', wick: 'rgba(0,0,0,1)', border: 'rgba(0,0,0,1)' },
            even: { color: null, wick: 'rgba(0,0,0,1)', border: null },
          },
          Line: { color: 'rgba(0,0,0,1)' },
          Mountain: { color: 'rgba(0,102,255,0.5)', basecolor: 'rgba(0,156,255,0.01)' },
        },
      },
      name: 'ChartIQLightTheme',
      builtIn: 'ciq-day',
    },
  },
};

const questTradeEdgeDesktopDark = {
  name: { theme: 'ChartIQDarkTheme' },
  value: {
    ChartIQDarkTheme: {
      settings: {
        chart: {
          Background: {
            color: 'rgba(26,26,26,1)',
          },
          'Grid Lines': {
            color: 'rgba(33,50,63,1)',
          },
          'Grid Dividers': {
            color: 'rgba(37,55,70,1)',
          },
          'Axis Text': {
            color: 'rgba(255,255,255,1)',
          },
        },
        chartTypes: {
          'Candle/Bar': {
            up: {
              color: 'rgba(131,202,49,1)',
              wick: 'rgba(255,255,255,1)',
              border: 'rgba(131,202,49,1)',
            },
            down: {
              color: 'rgba(255,37,37,1)',
              wick: 'rgba(255,255,255,1)',
              border: 'rgba(255,37,37,1)',
            },
            even: {
              color: null,
              wick: 'rgba(255,255,255,1)',
              border: null,
            },
          },
          Line: {
            color: 'rgba(255,255,255,1)',
          },
          Mountain: {
            color: 'rgba(0,156,255,0.5)',
            basecolor: 'rgba(0,102,255,0.01)',
          },
        },
      },
      name: 'ChartIQDarkTheme',
      builtIn: 'ciq-night',
    },
  },
};

const questTradeEdgeDesktopBlue = {
  name: { theme: 'ChartIQBlueTheme' },
  value: {
    ChartIQBlueTheme: {
      settings: {
        chart: {
          Background: {
            color: 'rgba(2,38,64,1)',
          },
          'Grid Lines': {
            color: 'rgba(0,49,84,1)',
          },
          'Grid Dividers': {
            color: 'rgba(37,55,70,1)',
          },
          'Axis Text': {
            color: 'rgba(255,255,255,1)',
          },
        },
        chartTypes: {
          'Candle/Bar': {
            up: {
              color: 'rgba(131,202,49,1)',
              wick: 'rgba(255,255,255,1)',
              border: 'rgba(131,202,49,1)',
            },
            down: {
              color: 'rgba(255,37,37,1)',
              wick: 'rgba(255,255,255,1)',
              border: 'rgba(255,37,37,1)',
            },
            even: {
              color: null,
              wick: 'rgba(255,255,255,1)',
              border: null,
            },
          },
          Line: {
            color: 'rgba(255,255,255,1)',
          },
          Mountain: {
            color: 'rgba(0,156,255,0.5)',
            basecolor: 'rgba(0,102,255,0.01)',
          },
        },
      },
      name: 'ChartIQBlueTheme',
      builtIn: 'ciq-night',
    },
  },
};

const questTradeEdgeWebLight = {
  name: { theme: 'ChartIQWebLightTheme' },
  value: {
    ChartIQWebLightTheme: {
      settings: {
        chart: {
          Background: { color: 'rgba(255,255,255,1)' },
          'Grid Lines': { color: 'rgba(206,214,226,1)' },
          'Grid Dividers': { color: 'rgba(204,204,204,1)' },
          'Axis Text': { color: 'rgba(38,45,51,1)' },
        },
        chartTypes: {
          'Candle/Bar': {
            up: { color: 'rgba(21,154,98,1)', wick: 'rgba(38,45,51,1)', border: 'rgba(38,45,51,1)' },
            down: { color: 'rgba(233,75,65,1)', wick: 'rgba(38,45,51,1)', border: 'rgba(38,45,51,1)' },
            even: { color: null, wick: 'rgba(38,45,51,1)', border: null },
          },
          Line: { color: 'rgba(38,45,51,1)' },
          Mountain: { color: 'rgba(0,102,255,0.5)', basecolor: 'rgba(0,156,255,0.01)' },
        },
      },
      name: 'ChartIQWebLightTheme',
      builtIn: 'ciq-day',
    },
  },
};

export const getChartIQQuestTradeTheme = (theme: ThemeType) => {
  switch (theme) {
    case ThemeType.QUESTRADELIGHT: {
      return questTradeEdgeDesktopLight;
    }
    case ThemeType.QUESTRADEDARK: {
      return questTradeEdgeDesktopDark;
    }
    case ThemeType.QUESTRADEBLUE: {
      return questTradeEdgeDesktopBlue;
    }
    case ThemeType.QUESTRADEWEBLIGHT: {
      return questTradeEdgeWebLight;
    }
    default: {
      return undefined;
    }
  }
};
