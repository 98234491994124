import lodash from 'lodash';
import {
  ISignal,
  LinkedPortfolio,
  LinkedWatchlist,
  SortingType,
  Sorts,
  orderByAscending,
  orderByDescending
} from '..';
import { ICollection } from '../what/collection';

export interface ICoveredCallReport {
  symbol: string;
  companyName: string;
  action: string;
  expiry: string;
  daysToExpiry: number;
  strikePrice: number;
  midPrice: number;
  bidPrice: number;
  askPrice: number;
  impliedVolatilityRank: string;
  earningsDate: string;
  earningsFlag: string;
  stockPrice: number;
  rawReturn: string;
  annualizedReturn: string;
  distanceToStrike: string;
  exDividendYield: number;
  occSymbol: string;
  expiryThreshold: number;
  deltaThreshold: number;
  liquidityRank: number;
  signals: ISignal[];
  portfolios: LinkedPortfolio[];
  watchlists: LinkedWatchlist[];
  url: string;
}

export class CoveredCalls implements ICollection<ICoveredCallReport> {
  data: ICoveredCallReport[] = [];
  coveredCalls: ICoveredCallReport[] = [];
  created = '';
  pricesAt = '';

  public constructor(response: CoveredCalls) {
    this.data = response.coveredCalls;
    this.coveredCalls = response.coveredCalls;
    this.created = response.created;
    this.pricesAt = response.pricesAt;
  }

  public static fromSelf = (coveredCalls: CoveredCalls): CoveredCalls => {
    if (!coveredCalls) {
      throw Error('coveredCalls is null or undefined');
    }
    const model = new CoveredCalls(coveredCalls);
    return model;
  };

  public get coveredCallCount(): number {
    return this.coveredCalls.length;
  }

  public get totalPortfolios(): number {
    return this.coveredCalls
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
  }

  public get totalWatchlists(): number {
    return this.coveredCalls
      .filter((e) => e.watchlists && e.watchlists.length > 0)
      .reduce((a, i) => a + i.watchlists.length, 0);
  }

  public sort = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    const coveredCalls = this.sortByRawReturn(sorts)
      .sortBySymbol(sorts)
      .sortByCompanyName(sorts)
      .sortByPremium(sorts)
      .sortByIVRank(sorts)
      .sortByEarningDate(sorts);
    return coveredCalls;
  };

  private sortByRawReturn = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'rawReturn') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByAscending(this.coveredCalls, 'rawReturn');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByDescending(this.coveredCalls, 'rawReturn');
      }
    }
    return this;
  };

  private sortBySymbol = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'symbol') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByAscending(this.coveredCalls, 'symbol');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByDescending(this.coveredCalls, 'symbol');
      }
    }
    return this;
  };

  private sortByCompanyName = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'companyName') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByAscending(this.coveredCalls, 'companyName');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByDescending(this.coveredCalls, 'companyName');
      }
    }
    return this;
  };

  private sortByPremium = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'premium') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByAscending(this.coveredCalls, 'midPrice');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.coveredCalls = orderByDescending(this.coveredCalls, 'midPrice');
      }
    }
    return this;
  };

  private sortByIVRank = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'ivRank') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.coveredCalls = lodash.orderBy(
          this.coveredCalls,
          [(e) => Number(e.impliedVolatilityRank.replace('%', ''))],
          ['asc'],
        );
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.coveredCalls = lodash.orderBy(
          this.coveredCalls,
          [(e) => Number(e.impliedVolatilityRank.replace('%', ''))],
          ['desc'],
        );
      }
    }
    return this;
  };

  private sortByEarningDate = (sorts: Sorts): CoveredCalls => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'earningDate') {
        continue;
      }
      const clone = this.coveredCalls.slice();
      let yesHasEarnings = clone.filter((e) => e.earningsDate);
      const noHasEarnings = clone.filter((e) => !e.earningsDate);
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        yesHasEarnings = lodash.orderBy(yesHasEarnings, [(e) => new Date(e.earningsDate)], ['asc']);
        this.coveredCalls = [...yesHasEarnings, ...noHasEarnings];
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        yesHasEarnings = lodash.orderBy(yesHasEarnings, [(e) => new Date(e.earningsDate)], ['desc']);
        this.coveredCalls = [...yesHasEarnings, ...noHasEarnings];
      }
    }
    return this;
  };
}
