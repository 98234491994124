import {
  AllowAccessRequest,
  AllowAcessResponce,
  ChangeEmailResponse,
  ChangePasswordResponse,
  CommissionSchedule,
  EditChangeEmail,
  EditChangePassword,
  EditCommission,
  EditForgotPassword,
  EditPortfolioAlert,
  EmailDeliveryFrequency,
  ForgotPasswordResponse,
  IAccountSetting,
  IAllowAccessResponceProps,
  ICancelDeleteAccountResponse,
  ICommissionResponceProps,
  IDeleteAccountResponse,
  IForgotPasswordResponseProps,
  IPortfolioAlertResponseProps,
  IResetResponceProps,
  IUserSetting,
  IUserSettingsProps,
  PortfolioAlertResponse,
  ProfileDetails,
  ResetModel,
  ResetPasswordResponse,
  SecurityModel,
  SharedTradeDeatails,
  UserSettings,
} from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';
// import { AllowAcessResponce, IAllowAccessResponceProps } from 'src/models/account/allow-access-responce';
// import { AllowAccessRequest } from 'src/models/account/allow-access-request';

export class ProfileDetailsService extends BaseApiService {
  private static _instance: ProfileDetailsService;

  private constructor() {
    super('/profile');
    ApiService.instance.init(ProfileDetailsService.onError, ProfileDetailsService.onLoading);
  }

  public static get instance(): ProfileDetailsService {
    if (ProfileDetailsService._instance == null) {
      ProfileDetailsService._instance = new ProfileDetailsService();
    }
    return ProfileDetailsService._instance;
  }

  private static onError = (errorCode: number): void => {
    console.error('Error in Profile-details-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public Profile = async (): Promise<ResponseViewModel<ProfileDetails>> => {
    const response = await ApiService.instance.get<ProfileDetails>('/profile');
    return response;
  };

  public EmailDeliveryFrequencies = async (): Promise<ResponseViewModel<EmailDeliveryFrequency[]>> => {
    const response = await ApiService.instance.get<EmailDeliveryFrequency[]>('/profile/getEmailDeliveryFrequencies');
    return response;
  };

  public Commission = async (): Promise<ResponseViewModel<EditCommission>> => {
    const response = await ApiService.instance.get<EditCommission>('/profile/getCommissions');

    return response;
  };

  public UpdateProfile = async (request: ProfileDetails): Promise<SecurityModel | null> => {
    const url = '/profile';
    const response = await ApiService.instance.post<ProfileDetails, SecurityModel>(url, request);
    const profileReponse = SecurityModel.fromProfileData(response.data as any);
    if (response.errors.length > 0) {
      return null;
    }
    return profileReponse;
  };

  public UpdateCommission = async (request: EditCommission): Promise<ResponseViewModel<CommissionSchedule | null>> => {
    const url = '/profile/saveCommissions';
    const response = await ApiService.instance.post<EditCommission, CommissionSchedule>(url, request);
    const editReponse = CommissionSchedule.fromJson(response as unknown as ICommissionResponceProps);
    const vm = new ResponseViewModel<CommissionSchedule>();
    vm.data = editReponse as unknown as CommissionSchedule;
    vm.errors = response.errors;
    return vm;
  };

  public SetAccess = async (request: any): Promise<AllowAcessResponce | null> => {
    const url = '/profile/setAdminAccessDate';
    const response = await ApiService.instance.post<any, AllowAcessResponce>(url, request);
    const editReponse = AllowAcessResponce.fromJson(response as unknown as IAllowAccessResponceProps);
    if (response.errors) {
      return null;
    }
    return editReponse;
  };
  public GetAccess = async (type: string): Promise<ResponseViewModel<AllowAccessRequest>> => {
    const response = await ApiService.instance.get<AllowAccessRequest>(`/profile/getAdminAccessDate?type=${type}`);

    return response;
  };

  public AcceptDisclaimer = async (request: boolean): Promise<ResponseViewModel<SecurityModel | null>> => {
    const url = '/profile/acceptDisclaimer';
    const response = await ApiService.instance.post<boolean, SecurityModel>(url, request);
    const vm = new ResponseViewModel<SecurityModel>();
    vm.data = response.data as unknown as SecurityModel;
    vm.errors = response.errors;
    return vm;
  };

  public ChangePassword = async (
    request: EditChangePassword,
  ): Promise<ResponseViewModel<ChangePasswordResponse | null>> => {
    const url = '/profile/changePassword';
    const response = await ApiService.instance.post<EditChangePassword, ChangePasswordResponse>(url, request);
    const vm = new ResponseViewModel<ChangePasswordResponse>();
    vm.data = response.data as unknown as ChangePasswordResponse;
    vm.errors = response.errors;
    return vm;
  };

  public ForgotPassword = async (request: EditForgotPassword): Promise<ForgotPasswordResponse | null> => {
    const url = '/Profile/forgotPassword';
    const response = await ApiService.instance.post<EditForgotPassword, ChangePasswordResponse>(url, request);

    const editReponse = ChangePasswordResponse.fromJson(response.data as unknown as IForgotPasswordResponseProps);

    if (response.errors.length > 0) {
      return null;
    }
    return editReponse;
  };

  public PortfolioAlert = async (request: EditPortfolioAlert): Promise<PortfolioAlertResponse | null> => {
    const url = '/api/Powerhouse/Pro/saveConfigurations';
    const response = await ApiService.instance.post<EditPortfolioAlert, PortfolioAlertResponse>(url, request);
    const editReponse = PortfolioAlertResponse.fromJson(response as unknown as IPortfolioAlertResponseProps);
    if (response.errors) {
      return null;
    }
    return editReponse;
  };

  public AcceptedLightbox = async (request: any): Promise<UserSettings | null> => {
    const url = '/Profile/acceptedLightbox';
    const response = await ApiService.instance.postfile<any, ChangePasswordResponse>(url, request);
    const editReponse = UserSettings.fromJson(response as unknown as IUserSettingsProps);
    if (response.errors) {
      return null;
    }
    return editReponse;
  };

  public ChangeEmail = async (request: EditChangeEmail): Promise<ResponseViewModel<ChangeEmailResponse | null>> => {
    const url = '/profile/changeEmailAddress';
    const response = await ApiService.instance.post<EditChangeEmail, ChangeEmailResponse>(url, request);
    const vm = new ResponseViewModel<ChangeEmailResponse>();
    vm.data = response.data as unknown as ChangeEmailResponse;
    vm.errors = response.errors;
    return vm;
  };

  public ResetPassword = async (request: ResetModel): Promise<ResetPasswordResponse | null> => {
    const url = '/profile/resetPassword';
    const response = await ApiService.instance.post<ResetModel, ResetPasswordResponse>(url, request);
    const editReponse = ResetPasswordResponse.fromJson(response.data as unknown as IResetResponceProps);
    if (response.errors.length > 0) {
      return null;
    }

    return editReponse;
  };

  public AccountSettings = async (request: IUserSetting) => {
    const url = '/profile/saveUserSettings';
    const response = await ApiService.instance.post<IUserSetting, IAccountSetting>(url, request);
    //const editReponse = AccountSettingsResponse.fromJson(response as unknown as IAccountSetting);
    const vm = new ResponseViewModel<IAccountSetting>();
    vm.data = response.data as unknown as IAccountSetting;
    vm.errors = response.errors;
    return vm;
  };

  public SharedTrades = async (data: any): Promise<ResponseViewModel<SharedTradeDeatails>> => {
    const response = await ApiService.instance.get<SharedTradeDeatails>(
      `/sharing/sharedTrades?start=${data.start}&length=${data.length}&orderColumn=${data.orderColumn}&orderDirection=${data.orderDirection}&startDate=${data.startDate}&endDate=${data.endDate}&symbol=${data.symbol}&isValid=${data.isValid}&isAnyMessageShown=${data.isAnyMessageShown}`,
    );
    const vm = new ResponseViewModel<SharedTradeDeatails>();
    vm.data = response.data as unknown as SharedTradeDeatails;
    vm.errors = response.errors;
    return vm;
  };

  public static exitBeta: any = async (): Promise<ResponseViewModel<any>> => {
    const Website_to_redirect = 'OptionsPlay';
    const uri = `/profile/generateUserHashToken?websiteName=` + Website_to_redirect;
    const response = await ApiService.instance.post<any, any>(uri, '');
    return response;
  };

  public UpdatePrivacyPolicy = async (request: any): Promise<ProfileDetails | null> => {
    const url = '/profile/updatePrivacyPolicy';
    const response = await ApiService.instance.post<any, ProfileDetails>(url, request);
    const privacyPolicyReponse = ProfileDetails.fromJson(response.data as any);
    if (response.errors.length > 0) {
      return null;
    }
    return privacyPolicyReponse;
  };

  public static generateBromleyUserHashToken: any = async (): Promise<ResponseViewModel<any>> => {
    const Website_to_redirect = 'Bromley';
    const uri = `/profile/generateUserHashToken?websiteName=` + Website_to_redirect;
    const response = await ApiService.instance.post<any, any>(uri, '');
    return response;
  };

  public DeleteAccount = async (): Promise<ResponseViewModel<IDeleteAccountResponse>> => {
    const url = '/profile/DeleteUserAccount';
    const response = await ApiService.instance.post<any, IDeleteAccountResponse>(url, {});
    const viewModel = new ResponseViewModel<IDeleteAccountResponse>();
    viewModel.data = response.data as unknown as IDeleteAccountResponse;
    viewModel.errors = response.errors;
    return viewModel;
  };

  public CancelDeletion = async (): Promise<ResponseViewModel<ICancelDeleteAccountResponse>> => {
    const url = '/profile/CancelAccountDelete';
    const response = await ApiService.instance.post<any, ICancelDeleteAccountResponse>(url, {});
    const viewModel = new ResponseViewModel<ICancelDeleteAccountResponse>();
    viewModel.data = response.data as unknown as ICancelDeleteAccountResponse;
    viewModel.errors = response.errors;
    return viewModel;
  };
}
