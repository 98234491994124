import { Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  accessTokenKey,
  accountState,
  configurationState,
  customizationState,
  selectedSymbolState,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { logOutTradeStation } from '../tradestation/services';
import { tsAuthenticationStatusState, tsEnvironmentState } from '../tradestation';
import { notificationsState } from '@op/shared/src/states/notification-states';

export const SessionTimeOut: React.FC = () => {
  const theme = useTheme();
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const [account, setAccount] = useRecoilState(accountState);
  const [idleTimeout, setIdleTimeout] = useState<number>();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const navigate = useNavigate();
  const promptTimeout = 1000 * 60;
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const [tsAuthenticationStatus, setTsAuthenticationStatus] = useRecoilState(tsAuthenticationStatusState);
  const setNotifications = useSetRecoilState(notificationsState);

  useEffect(() => {
    if (!account || customization?.isEmbeddingPlatform) {
      return;
    }
    if (configuration && configuration.userSettings) {
      setIdleTimeout(configuration.userSettings.timeOutInMinutes * 60 * 1000 - promptTimeout);
    }
  }, [configuration, account]);

  const onPrompt = () => {
    if (!account || customization?.isEmbeddingPlatform) {
      return;
    }
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    if (!account || customization?.isEmbeddingPlatform) {
      return;
    }
    setOpen(false);
    setRemaining(0);
    logOut();
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    stopOnIdle: true,
  });

  const handleKeepMeSignIn = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const tradeStationLogout = async () => {
    if (!account || !account.isAllowTradeStationAccountLinking) {
      return undefined;
    }
    if (!tsAuthenticationStatus) {
      return undefined;
    }
    const logoutRes = await logOutTradeStation(tsEnv);
    if (logoutRes.hasErrors || logoutRes.data === '') {
      setNotifications([
        {
          type: 'error',
          content: logoutRes.errors[0].message,
          isTradeStation: true,
        },
      ]);
      setOpen(false);
      return;
    }
    const url = logoutRes.data;
    window.localStorage.removeItem('tsRedirectURL');
    window.localStorage.removeItem('tsEnv');
    return url;
  };

  const logoutOP = () => {
    setAccount(undefined);
    logActivity('', '');
    localStorage.removeItem('tradeTicketCombination');
    localStorage.removeItem('lightbox_state');
    localStorage.removeItem('additionalData');
    localStorage.removeItem(accessTokenKey);
    setAccount(undefined);
    navigate('/login', { replace: true });
    window.location.reload();
  };

  const logOut = async (): Promise<void> => {
    if (tsAuthenticationStatus) {
      const isTradeStationLoggedOutURL = await tradeStationLogout();
      if (isTradeStationLoggedOutURL !== '') {
        setTsAuthenticationStatus(false);
        window.open(isTradeStationLoggedOutURL, '_self');
        window.localStorage.setItem('opLogoutFromTS', JSON.stringify(true));
      }
      return;
    }
    logoutOP();
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '{ "automatic" : true}',
      EventType.SignOut,
      selectedSymbol as string,
    );
  };

  return (
    <Dialog open={open} style={{ borderRadius: theme.shape.borderRadius }}>
      <DialogContent>
        <Typography variant="subtitle1">
          Your session is about to expire in {remaining} seconds due to inactivity.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleKeepMeSignIn} color="primary" variant="contained">
          <Typography variant="button">Keep me signed in</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
