import { IChangePasswordProps } from './';

export class EditChangePassword implements IChangePasswordProps {
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';

  public static fromJson = (data: EditChangePassword): EditChangePassword => {
    const model = new EditChangePassword();
    model.oldPassword = data.oldPassword;
    model.newPassword = data.newPassword;
    model.confirmPassword = data.confirmPassword;
    return model;
  };
}
