import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import securelogo from '@op/shared/assets/images/secure.gif';
import React from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { OPDialogWidget } from '../common';
import { OutlinedButton } from '../styled/options-play-components';

const LogoStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '90px',
  height: '72px',
};

export const LockIconMenu = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = React.useContext(LocalizationContext);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderDialogContent = () => {
    return (
      <div>
        <Typography gutterBottom>
          We take your privacy and security seriously. So we do everything to secure and protect your data and
          information.
        </Typography>
        <Typography variant="subtitle2"> SSL/TLS Encryption</Typography>
        <Typography gutterBottom>
          All communications with app.optionsplay.com are sent over SSL/TLS connections. Secure Sockets Layer (SSL) and
          Transport Layer Security (TLS) technology (the successor technology to SSL) protect communications by using
          both server authentication and data encryption. This ensures that your data in transit is safe, secure, and
          available only to its intended recipients.
        </Typography>
        <Typography variant="subtitle2"> User Authentication</Typography>
        <Typography gutterBottom>
          User accounts have unique usernames and passwords that must be entered each time a user logs on. OptionsPlay
          issues a session cookie only to record encrypted authentication information for the duration of a specific
          session. The cookie does not include the password of the user.
        </Typography>

        <Typography variant="subtitle2"> User Passwords</Typography>
        <Typography gutterBottom>
          Sensitive user data, such as account passwords, is stored in encrypted format. Passwords are individually
          salted and hashed. You control your password, we don&apos;t.
        </Typography>

        <Typography variant="subtitle2"> Credit Cards</Typography>
        <Typography gutterBottom>
          We do not process or store credit card details on our own network. All data and transactions are passed
          directly through a secure connection to our payment processor,{' '}
          <Link href="//www.authorize.net/" target="_blank">
            Authorize.Net
          </Link>
          ,a leading payment gateway since 1996, who accept credit cards and electronic check payments safely and
          securely for our customers.
        </Typography>

        <Typography gutterBottom>
          The Authorize.Net Payment Gateway manages the complex routing of sensitive customer information through the
          electronic check and credit card processing networks. See an online{' '}
          <Link href="//www.authorize.net/resources/howitworksdiagram.html" target="_blank">
            payments diagram
          </Link>{' '}
          to see how it works.
        </Typography>

        <Typography gutterBottom>
          The company adheres to strict industry standards for payment processing, including:
        </Typography>

        <Typography gutterBottom>
          <ul>
            <li>128-bit Secure Sockets Layer (SSL) technology for secure Internet Protocol (IP) transactions.</li>{' '}
            <li>
              {' '}
              Industry leading encryption hardware and software methods and security protocols to protect customer
              information.
            </li>
            <li>Compliance with the Payment Card Industry Data Security Standard (PCI DSS).</li>
            <li>
              {' '}
              For additional information regarding the privacy of your sensitive cardholder data, please read the
              Authorize.Net{' '}
              <Link rel="noreferrer" href={'https://usa.visa.com/legal/privacy-policy.html'} target="_blank">
                Privacy Policy
              </Link>
              .
            </li>
          </ul>
        </Typography>

        <Typography gutterBottom>
          OptionsPlay is registered with the Authorize.Net Verified Merchant Seal program.
        </Typography>
        <Typography textAlign="center">
          <img src={securelogo} alt="Logo" style={LogoStyle}></img>
          <Link href="//www.authorize.net/" target="_blank" rel="noreferrer">
            Electronic Payments
          </Link>
        </Typography>
      </div>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Typography id="customized-dialog-title" variant="h5" sx={{ fontWeight: 600 }}>
        <SecurityIcon sx={{ fontSize: 30 }} /> Security Information
      </Typography>
    );
  };
  const renderDialogAction = () => {
    return (
      <div>
        <OutlinedButton autoFocus size="small" onClick={handleClose}>
          <Typography variant="button">{t('common.buttons.close')}</Typography>
        </OutlinedButton>
      </div>
    );
  };
  return (
    <div>
      <Typography>
        <CommonListItemIcon
          icon={<LockOutlinedIcon />}
          text={t('footer.labels.securityInformation')}
          callback={handleClickOpen}
          key={'profile'}
        />
      </Typography>

      <OPDialogWidget
        open={open}
        onHandleClose={handleClose}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
        dialogAction={renderDialogAction()}
      />
    </div>
  );
};
