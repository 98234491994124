export interface IBillingTransactionsData {
  amount: number;
  createdOn: string;
  dateSubmitted: string;
  identifier: number;
  status: string;
}

export class BillingTransactionsData implements IBillingTransactionsData {
  amount = 0;
  createdOn = '';
  dateSubmitted = '';
  identifier = 0;
  status = '';

  public static fromJson = (items: BillingTransactionsData): BillingTransactionsData => {
    const model = new BillingTransactionsData();
    model.amount = items.amount;
    model.createdOn = items.createdOn;
    model.dateSubmitted = items.dateSubmitted;
    model.identifier = items.identifier;
    model.status = items.status;
    return model;
  };
}
