import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { ICompanyLookup } from '@op/shared/src/models';
import formatting from '@op/shared/src/models/how/formatting';
import { companyLookup } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import axios, { CancelTokenSource } from 'axios';
import { debounce } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { FlagWidget } from '../common';

interface IPortfolioPositionSearch {
  onSelect: (value: string) => void;
  searchSymbol?: string;
  setSearchSymbol: (value: string) => void;
  onFocusSearch: () => void;
  isSearchDisabled: boolean;
}

export const PortfolioPositionsSearch: React.FC<IPortfolioPositionSearch> = ({
  onSelect,
  searchSymbol,
  setSearchSymbol,
  onFocusSearch,
  isSearchDisabled,
}: IPortfolioPositionSearch) => {
  const [open, setOpen] = useState(false);
  const setNotifications = useSetRecoilState(notificationsState);
  const [searchResults, setSearchResults] = React.useState<ICompanyLookup[]>([]);
  const [loading, setLoading] = useState(false);

  let autoCompleteCancelToken: CancelTokenSource | undefined = undefined;

  const fetchCompanies = async (searchTerm: string) => {
    const fetchedCompanyLookup = await companyLookup(searchTerm, { cancelToken: autoCompleteCancelToken?.token });
    if (fetchedCompanyLookup.hasErrors || !fetchedCompanyLookup.data) {
      return undefined;
    }
    return fetchedCompanyLookup.data;
  };

  const onChangeOfAutoCompleteTextbox = async (searchTerm: string) => {
    //Check if there are any previous pending requests
    if (typeof autoCompleteCancelToken != typeof undefined) {
      autoCompleteCancelToken?.cancel('Operation canceled due to new request.');
    }
    //Save the cancel token for the current request
    autoCompleteCancelToken = axios.CancelToken.source();
    setLoading(true);
    const companies = await fetchCompanies(searchTerm);
    if (companies === undefined) {
      return;
    }
    setSearchResults(companies);
    setLoading(false);
  };

  const debouncedOnChangeOfAutoCompleteTextbox = useMemo(() => debounce(onChangeOfAutoCompleteTextbox, 200), []);

  const clearState = () => {
    setSearchResults([]);
  };

  const onChangeOfAutoCompleteSelection = (companyLookupItem: ICompanyLookup) => {
    if (companyLookupItem && companyLookupItem.symbol) {
      /**
       * TODO : In GetSymbolDotExchange added the condition for appending the exchange code.
       * Need to move the code to some other place.
       */
      const symbolExchangeCode = formatting.getSymbolDotExchange(
        companyLookupItem.symbol,
        companyLookupItem.exchangeCode,
      );
      setSearchSymbol(symbolExchangeCode);
      return;
    }
    setNotifications([{ type: 'error', content: 'Quote Not Found' }]);
  };

  const onPressEnter = async () => {
    if (!searchSymbol || searchSymbol.trim() === '') {
      return;
    }
    onSelect(searchSymbol.toUpperCase());
    //gtmDetails('change', inputValue);
  };

  const onFocusInput = () => {
    setSearchSymbol('');
    onFocusSearch();
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchSymbol(e.target.value);
    debouncedOnChangeOfAutoCompleteTextbox(e.target.value);
  };

  return (
    <Grid item>
      <Autocomplete
        id="search-text"
        disableClearable
        clearOnBlur
        autoHighlight
        size="small"
        sx={{ width: 300 }}
        open={open}
        disabled={isSearchDisabled}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={searchResults}
        getOptionLabel={(option: ICompanyLookup) =>
          option && searchSymbol && searchSymbol.length > 0 && `${option.symbol}+${option.name}`
            ? `${option.symbol}-${option.name}`
            : ''
        }
        onChange={(event, value, reason) => onChangeOfAutoCompleteSelection(value)}
        loading={loading}
        loadingText={'Loading symbols...'}
        noOptionsText={'No symbol found'}
        renderOption={(props, option, state) => (
          <li {...props}>
            <Grid container>
              <Grid item container xs={12} justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold" lineHeight={1}>
                  {option.symbol}
                </Typography>
                <FlagWidget exchange={option.exchangeCode} variant={'square'} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ textTransform: 'uppercase' }} lineHeight={1}>
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            fullWidth
            variant="outlined"
            onFocus={() => onFocusInput()}
            size="small"
            onChange={(e) => onChangeText(e)}
            onBlur={clearState}
            placeholder="symbol"
            name="searchsymbol"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onPressEnter();
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? <CircularProgress color="success" size={15} /> : <SearchIcon color="success" />}
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              style: { textTransform: 'uppercase' },
            }}
          />
        )}
      />
    </Grid>
  );
};
