import { Grid, Typography, useTheme } from '@mui/material';
import { selectedPlansState } from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { BillingDetailsWidget } from './billing-details-widget';
import { CardDetailsWidget } from './card-details-widget';
import { SubscriptionHeaderTabWidget } from './subscription-header-tab-widget';
import { getSubscriptionHeaderTitle } from './subscription-helper';
import LocalizationContext from '../react-i18next/localization-context';

export const CardInfoWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const selectedPlans = useRecoilValue(selectedPlansState);

  const renderContent = () => {
    return (
      <Grid container px={8} m={1} xs={12}>
        <Grid item xs={12}>
          <Typography
            style={{
              color: theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[500],
              fontWeight: 500,
              fontSize: 12,
            }}>
            {t('subscriptions.weNeverSellYourPersonalInfo')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CardDetailsWidget />
        </Grid>
        <Grid item xs={12}>
          <BillingDetailsWidget />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems={'center'} p={2} rowSpacing={1}>
      <Grid item xs={12}>
        <SubscriptionHeaderTabWidget title={getSubscriptionHeaderTitle(selectedPlans, 'cardInfo', t)} cardInfo={true} />
      </Grid>
      <Grid item xs={12}>
        {renderContent()}
      </Grid>
    </Grid>
  );
};
