import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Grid, Step, StepButton, Stepper, Typography, useTheme } from '@mui/material';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  activeStepState,
  paymentCardTypeState,
  paymentDetailsErrorState,
  reverseStepMap,
  selectedPlansState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentPaymentState,
  subscriptionCurrentScreenState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { AddOn } from './add-on';
import { CardInfoWidget } from './card-info-widget';
import { ChangePlan } from './change-plan';
import { SubscriptionConfirmationWidget } from './subscription-confirmation';
import { SubscriptionSummary } from './subscription-summary';
import { ValidateBillingInfo, ValidateCardInfo } from './validation';

export const ChangeSubscriptionWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const cardType = useRecoilValue(paymentCardTypeState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const [paymentDetails, setPaymentDetails] = useRecoilState(subscriptionCurrentPaymentState);
  const activeStep = useRecoilValue(activeStepState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const selectedPlans = useRecoilValue(selectedPlansState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setErrors = useSetRecoilState(paymentDetailsErrorState);
  const [completed] = useState<{
    [k: number]: boolean;
  }>({});
  const theme = useTheme();

  if (!selectedPlans) {
    return;
  }

  const { bundles, addOns } = selectedPlans;

  // useEffect(() => {
  // reset to initial state on reinitializatio of change subscription screen.
  //   setActiveStep('changePlan');
  // }, []);

  const steps = [
    `${t('subscriptions.planSelection')}`,
    `${t('subscriptions.addOn')}`,
    `${t('subscriptions.cardAndBillingAdd')}`,
    `${t('subscriptions.summary')}`,
  ];

  const getStep = (step: number) => {
    return reverseStepMap[step];
  };

  const logActivity = (controlType: string, controlName: string, value: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(getStep(step));
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    logActivity('BUTTON', 'paymentsChangeScreenNextButton', reverseStepMap[activeStep]);
    if (activeStep === 1) {
      const isNoPlanSelected = bundles.length + addOns.length === 0;
      if (isNoPlanSelected) {
        setNotifications([{ type: 'error', content: 'No Plan or AddOn is selected.' }]);
        return;
      }
    }
    if (activeStep === 2) {
      const errors = ValidateCardInfo(paymentDetails, cardType);
      const billingErrors = ValidateBillingInfo(paymentDetails);
      setErrors({ ...errors, ...billingErrors });
      if (Object.keys(errors).length > 0 || Object.keys(billingErrors).length > 0) {
        return;
      }
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(getStep(newActiveStep));
  };

  const handleBack = () => {
    logActivity('BUTTON', 'paymentsChangeScreenBackButton', reverseStepMap[activeStep]);
    if (activeStep === 0) {
      setSubscriptionCurrentScreen('CurrentSubscription');
      return;
    }
    if (activeStep === 2) {
      setPaymentDetails((prev) => ({ ...prev, cardCvv: '' }));
      setErrors((prev) => ({ ...prev, cardCvv: '' }));
    }
    setActiveStep(getStep(activeStep - 1));
  };

  const getStepsContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <ChangePlan />;
      case 1:
        return <AddOn />;
      case 2:
        return <CardInfoWidget />;
      case 3:
        return <SubscriptionSummary />;
      case 4:
        return <SubscriptionConfirmationWidget />;
      default:
        throw new Error('Unknown screen');
    }
  };

  const renderButtons = () => {
    if (activeStep === 3 || activeStep === 4) {
      return;
    }
    return (
      <Grid item xs={12} container justifyContent="flex-end" pr={2} columnSpacing={2}>
        <Grid item>
          <Button onClick={handleBack} sx={{ color: theme.palette.primary.light }} size="large" variant="outlined">
            <ArrowBackIosIcon fontSize="small" sx={{ color: theme.palette.primary.light }} />
            <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
              {t('common.buttons.back')}
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" disabled={!bundles} size="large" color="primary" onClick={handleNext}>
            <OPBoldTypograpghy variant="button"> {t('common.buttons.continue')}</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          sx={{
            backgroundColor: theme.palette.primary.light,
            p: 1,
          }}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={() => handleStep(index)}>
                <OPBoldTypograpghy>{label}</OPBoldTypograpghy>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {getStepsContent(activeStep)}
      </Grid>
      <Grid item xs={12}>
        {renderButtons()}
      </Grid>
    </Grid>
  );
};
