import { ApiService } from './api-service';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { BaseApiService } from './base-api-service';

export class EducationServices extends BaseApiService {
  private static _instance: EducationServices;

  private constructor() {
    super('/education');
    ApiService.instance.init(EducationServices.onError, EducationServices.onLoading);
  }

  public static get instance(): EducationServices {
    if (EducationServices._instance == null) {
      EducationServices._instance = new EducationServices();
    }
    return EducationServices._instance;
  }

  public getEducation = async (type: string): Promise<ResponseViewModel<any>> => {
    const response = await ApiService.instance.get<any>(`/education?type=${type}`);
    const vm = new ResponseViewModel<any>();
    vm.data = (response.data as unknown) as any;
    return vm;
  };

  private static onError = (errorCode: number): void => {
    console.error('Error in Profile-details-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {
    console.info('loading...', isLoading);
  };
}
