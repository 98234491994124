import { Box, Collapse, Grid, useTheme } from '@mui/material';
import { ideaMoreDetailsState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { IVRankWidget, LiquidityRatingWidget } from '../common';
import { OptionsPlayDivider } from '../styled/options-play-components';
import { BuySellStrikeWidget } from './credit-spread/buy-sell-strike';
import { ICreditSpreadsProps, IRowProps } from './pager';

interface Props {
  signals: any;
  data: IRowProps | ICreditSpreadsProps;
}
export const IdeaMoreDetails: React.FC<Props> = ({ data, signals }: Props) => {
  const open = useRecoilValue(ideaMoreDetailsState);
  const theme = useTheme();

  if (!data) {
    return <></>;
  }

  return (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      sx={{
        backgroundColor: theme.palette.secondary.light,
        '&:last-child .js-position-container': {
          border: 'none',
        },
        borderRadius: '0 0 4px 4px',
      }}>
      <OptionsPlayDivider />
      {data.type === 'trade' && (
        <Box pl={1.5}>
          <Box>
            <LiquidityRatingWidget data={signals} showRatings={true} />
          </Box>
          <Box>
            <IVRankWidget data={signals} showIVRankBar={true} />
          </Box>
        </Box>
      )}
      {data.type === 'creditSpread' && (
        <Grid container justifyContent={'space-between'} pl={1.5} py={1}>
          <BuySellStrikeWidget data={data as ICreditSpreadsProps} />
        </Grid>
      )}
    </Collapse>
  );
};
