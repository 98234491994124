import { Grid, Typography } from '@mui/material';
import { SentimentType } from '@op/shared/src/models';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import {
  howDataState,
  tradingRangeSimulatorState,
  tradingStrategiesSentimentAtom,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BasicCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { WhatIfExpiryDateWidget } from './what-if-expiry-date-widget';
import { WhatIfImpliedVolatilityWidget } from './what-if-implied-volatility-widget';
import { WhatIfPriceWidget } from './what-if-price-widget';

export interface IPLSimulatorWidget {}

export const PLSimulatorWidget: React.FC<IPLSimulatorWidget> = () => {
  const howData = useRecoilValue(howDataState);
  const strategySentiment = useRecoilValue(tradingStrategiesSentimentAtom);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const { t } = React.useContext(LocalizationContext);
  const setWhatIfSimulator = useSetRecoilState(whatIfSimulatorState);

  useEffect(() => {
    if (!howData || !tradingRangeSimulator) {
      return;
    }
    const sentiment = strategySentiment ? (strategySentiment as SentimentType) : howData.originalSentiment;
    const whatIfSimulator = WhatIfSimulator.fromData(howData, sentiment, tradingRangeSimulator);
    setWhatIfSimulator(whatIfSimulator);
  }, [tradingRangeSimulator]);

  if (!howData) {
    return null;
  }

  return (
    <Grid container id="pl-sumilator">
      <BasicCardWidget
        title={<Typography variant="subtitle1">{t('how.toolbox.whatIfSimulator.text.thisIsYourProfit')}</Typography>}
        content={
          <Grid container rowSpacing={1}>
            <Grid item container xs={12}>
              <WhatIfPriceWidget />
            </Grid>
            <Grid item xs={12}>
              <WhatIfExpiryDateWidget />
            </Grid>
            <Grid item xs={12}>
              <WhatIfImpliedVolatilityWidget />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};
