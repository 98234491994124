import { defaultScan, defaultSector, Scan, Sector, SortingType } from '@op/shared/src/models';
import { atom } from 'recoil';
import { SentimentType } from '../models';

export const sortState = atom({
  key: 'sortState',
  default: SortingType.ASCENDING,
});

export const sentimentState = atom({
  key: 'sentimentState',
  default: SentimentType.ALL,
});

export const sectorState = atom<Sector>({
  key: 'sectorState',
  default: defaultSector,
});

export const scanState = atom<Scan>({
  key: 'scanState',
  default: defaultScan,
});
