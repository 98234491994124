enum UserVoiceLanguageType {
  ENGLISH = 'en',
  CHINESE = 'cn',
  FRENCH = 'fr',
  SWEDEN = 'sv',
}
export const uservoicegetLanguageType = (text: string): UserVoiceLanguageType => {
  switch (text.toUpperCase()) {
    case 'EN-US':
      return UserVoiceLanguageType.ENGLISH;
    case 'ZH-CN':
      return UserVoiceLanguageType.CHINESE;
    case 'FR-CA':
      return UserVoiceLanguageType.FRENCH;
    case 'SV-SE':
      return UserVoiceLanguageType.SWEDEN;
    default:
      return UserVoiceLanguageType.ENGLISH;
  }
};
