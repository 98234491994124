import React from 'react';

export interface ITabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  selectedIndex: number | string;
  height?: string;
}

export const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps) => {
  const { children, selectedIndex, index, height, ...other } = props;

  return (
    <div role="tabpanel" hidden={selectedIndex !== index} {...other}>
      {
        selectedIndex === index && <div style={{ height: height ? height : 'auto' }}>{children}</div>
        // <Box p={1} style={{ flex: 1, maxHeight: '650px', height: '650px' }}>
        //   <Box>{children}</Box>
        // </Box>
      }
    </div>
  );
};
