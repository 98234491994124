import { Grid, Tabs, Tooltip } from '@mui/material';
import { IData } from '@op/shared/src/models';
import { EventType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { customizationState } from '@op/shared/src/states';
import { selectedSymbolState, tradeSimulatorOperationTypeState } from '@op/shared/src/states/trade/trade-states';
import React, { FC } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { StyledTab } from '../styled';
import { GuideItem } from '../side-menu';

export interface ISumulatorOperationsWidgetProps
  extends IData<Array<{ name: tradeSimulatorOperationType; title: string }>> {}

export const SimulatorOperationsWidget: FC<ISumulatorOperationsWidgetProps> = ({
  data,
}: ISumulatorOperationsWidgetProps) => {
  const operations = data;
  const [simulatorOperation, setSimulatorOperation] = useRecoilState(tradeSimulatorOperationTypeState);
  const customization = useRecoilValue(customizationState);
  const { t } = React.useContext(LocalizationContext);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  if (operations.length === 0) {
    throw new Error('No operations listed');
  }

  const renderFlag = (name: tradeSimulatorOperationType) => {
    const ifOperationExist = operations.some((op) => op.name === name);
    if (!ifOperationExist) {
      return;
    }
    if (name === tradeSimulatorOperationType.IncomeSettings) {
      return (
        <div style={{ paddingLeft: '52%' }}>
          <GuideItem selector=".incomePanelDefaultSettings_helpPinPlaceholder" />
        </div>
      );
    }
    if (name === tradeSimulatorOperationType.OptionsGrid) {
      return <GuideItem selector=".incomePanelOptionsGrid_helpPinPlaceholder" />;
    }

    return undefined;
  };

  const getMerrillPopover = (name: tradeSimulatorOperationType) => {
    if (name === tradeSimulatorOperationType.RiskInvestmentCalculator && customization && customization.isMerrill) {
      return <>*</>;
    }
  };

  const logActivity = (controlType: string, controlName: string, value?: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const handleLogActivity = (value: any) => {
    if (value === 'OptionsGrid') {
      return logActivity('BUTTON', 'incomeStrategiesOptionsGridButton');
    }

    if (value === 'IncomeSettings') {
      return logActivity('BUTTON', 'incomeStrategiesDefaultSettingsButton');
    }
    return '';
  };
  const renderOperations = () => {
    return (
      <>
        {renderFlag(tradeSimulatorOperationType.OptionsGrid)}
        {renderFlag(tradeSimulatorOperationType.IncomeSettings)}
        <Tabs
          value={simulatorOperation}
          allowScrollButtonsMobile
          textColor={customization?.isNasdaq ? 'inherit' : 'primary'}
          onChange={(_, value) => {
            if (value === null) {
              return;
            }
            const operationType = tradeSimulatorOperationType[value as keyof typeof tradeSimulatorOperationType];
            setSimulatorOperation(operationType);
            handleLogActivity(value);
          }}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="trading simulator operations">
          {operations.map((o, index) => {
            return (
              <StyledTab
                key={index}
                value={o.name}
                isselected={(o.name === simulatorOperation).toString()}
                label={
                  <>
                    <Tooltip
                      key={index}
                      title={
                        o.name === tradeSimulatorOperationType.RiskInvestmentCalculator &&
                        customization &&
                        customization.isMerrill
                          ? 'Max Loss does not consider delivery of shares in the event the option expires in the money.'
                          : ''
                      }
                      placement="bottom"
                      arrow>
                      <span>
                        {t(o.title)}
                        {getMerrillPopover(o.name)}
                      </span>
                    </Tooltip>
                  </>
                }
              />
            );
          })}
        </Tabs>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderOperations()}
      </Grid>
    </Grid>
  );
};
