import { CombinationType, PriceCalculationMethod } from '../enums/enums';
import { Leg } from '../how';
import HowDataModel from '../how/how-data-model';
import { PortfolioCombination } from './portfolio-combination-model';
import { PortfolioManagementCombination } from './portfolio-management-combination-model';
import { Recommendation } from './recommendation-model';

export class PortfolioPositionsManagement {
  currentCombination: PortfolioManagementCombination | undefined;
  recommendationCombination: PortfolioManagementCombination | undefined;
  resultingCombination: PortfolioManagementCombination | undefined;
  private constructor() {}

  static fromData = (howDataModel: HowDataModel, combination: PortfolioCombination) => {
    const model = new PortfolioPositionsManagement();
    model.currentCombination = PortfolioPositionsManagement.assembleCombination(
      combination.getRawPositions(),
      CombinationType.PORTFOLIO_CURRENT,
      undefined,
      howDataModel,
      combination,
    );
    model.recommendationCombination = PortfolioPositionsManagement.assembleCombination(
      undefined,
      CombinationType.PORTFOLIO_ADJUSTMENT,
      undefined,
      howDataModel,
      combination,
    );
    model.resultingCombination = PortfolioPositionsManagement.assembleCombination(
      model.resultingPositions,
      CombinationType.PORTFOLIO_RESULTING,
      undefined, //model.resultingPositions,
      howDataModel,
      combination,
    );
    return model;
  };

  static fromSelf = (self: PortfolioPositionsManagement) => {
    const clone = new PortfolioPositionsManagement();
    clone.currentCombination = self.currentCombination
      ? PortfolioManagementCombination.fromSelf(self.currentCombination)
      : undefined;
    clone.recommendationCombination = self.recommendationCombination
      ? PortfolioManagementCombination.fromSelf(self.recommendationCombination)
      : undefined;
    clone.resultingCombination = self.resultingCombination
      ? PortfolioManagementCombination.fromSelf(self.resultingCombination)
      : undefined;
    return clone;
  };

  static fromRecommendation = (
    howDataModel: HowDataModel,
    combination: PortfolioCombination,
    recommendation: Recommendation,
  ) => {
    const model = new PortfolioPositionsManagement();
    model.currentCombination = PortfolioPositionsManagement.assembleCombination(
      combination.getRawPositions(),
      CombinationType.PORTFOLIO_CURRENT,
      undefined,
      howDataModel,
      combination,
    );
    model.recommendationCombination = PortfolioPositionsManagement.assembleCombination(
      recommendation.legs,
      CombinationType.PORTFOLIO_ADJUSTMENT,
      undefined,
      howDataModel,
      combination,
    );
    model.resultingCombination = PortfolioPositionsManagement.assembleCombination(
      model.resultingPositions,
      CombinationType.PORTFOLIO_RESULTING,
      undefined,
      howDataModel,
      combination,
    );
    return model;
  };

  get allCombinations() {
    return [this.currentCombination, this.recommendationCombination, this.resultingCombination];
  }

  get resultingPositions() {
    const currentCombination = this.currentCombination;
    if (!currentCombination) {
      return [];
    }
    const currentPositions = currentCombination.positions;
    if (!this.recommendationCombination) {
      return currentPositions;
    }
    return currentPositions.concat(this.recommendationCombination.positions);
  }

  // **********INIT***********

  // // Adjustments for Alert is moved in PortfolioAlertModel.ts.
  // private createAlertAdjustments = (how: HowDataModel) => {
  //   //TODO: alertsWithReccomentions will come from porfolio signalr
  //   const alertsWithRecommendation: any = [];
  //   // TODO; Complete alerting
  //   //self.alerts().filter(function (alert) {
  //   //   return alert != null && alert.type === AlertType.ALERT;
  //   // });
  //   const defaultTimeFrameForCall = this.configuration.applicationConfiguration.coveredCall.call.timeFrame;
  //   const defaultAggressivenessForCall = this.configuration.applicationConfiguration.coveredCall.call.aggressiveness;
  //   const priorityForCall = CoveredCallExtensions.getPriorityByTimeFrameAndAggressiveness(
  //     defaultTimeFrameForCall,
  //     defaultAggressivenessForCall,
  //   );

  //   let alertAdjustment: Recommendation | undefined;

  //   const legBuilderContext = {
  //     portfolioCombination: self,
  //     chain: how.chain,
  //     quote: how.quote,
  //     stdDev: how.stdDev,
  //     callOptimal: how.callOptimals[0], //coveredCallExtensions.findOptimalCoveredCallByPriority(howDataModel.callOptimals, priorityForCall),
  //     alternativeCall: how.alternativeCall,
  //   };

  //   for (let i = 0; i < alertsWithRecommendation.length; i++) {
  //     var alert = alertsWithRecommendation[i];
  //     var adjustmentLegs = alert.buildAdjustmentLegs(legBuilderContext);
  //     if (adjustmentLegs != null) {
  //       alertAdjustment = Recommendation.fromLegs(alert, adjustmentLegs);
  //       break;
  //     }
  //   }

  //   const recommendations: PorfolioAction[] = [];

  //   if (!alertAdjustment || !alertAdjustment.alert) {
  //     return recommendations;
  //   }

  //   if (alertAdjustment.alert.adjustmentType !== PorfolioAction.CLOSE_POSITION) {
  //     recommendations.push(PorfolioAction.CLOSE_POSITION);
  //   }
  //   if (alertAdjustment.alert.adjustmentType !== PorfolioAction.ROLL_POSITION) {
  //     recommendations.push(PorfolioAction.ROLL_POSITION);
  //   }
  //   return recommendations;
  // };

  static assembleCombination = (
    legs: Leg[] | undefined,
    combinationType: CombinationType,
    resultingPositions: Leg[] | undefined,
    howDataModel: HowDataModel,
    originalCombination: PortfolioCombination,
  ) => {
    const chartOutlookValues = {
      computedLegs: resultingPositions,
      combinationType: combinationType,
      defaultPriceCalculationMethod: PriceCalculationMethod.MID,
      // todo: think about more elegant solution
      originalCombination: originalCombination,
    };
    const combinationContext = { howDataModel, ...chartOutlookValues };
    return PortfolioManagementCombination.fromLegsData(legs || [], combinationContext);
  };
  // ***************** END OF INIT *******************
}
