import { ApiService } from './api-service';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { Globalization, isNordic, isTmx } from '../models';

export const getLocales = async (): Promise<ResponseViewModel<Globalization>> => {
  const response = await ApiService.instance.get<Globalization>('/globalization/locales');
  const vm = new ResponseViewModel<Globalization>();
  const data = isNordic()
    ? Globalization.fromNordicDefault()
    : isTmx()
      ? Globalization.fromTMXDefault()
      : Globalization.fromUSDefault();
  vm.data = response?.data || data;
  vm.errors = response.errors;
  return vm;
};
