import { Avatar, Button, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { OPBoldTypograpghy, OPSemiBoldTypograpghy } from '../styled';

/**
 * SaveChanges View is common layout for the save and cancel button for all actions
 */
type SaveChangesProps = {
  func: React.MouseEventHandler<HTMLButtonElement> | undefined;
  label: string;
  buttonLabel: string;
  reset: () => void;
};

export const SaveChangesView: React.FC<SaveChangesProps> = ({ func, label, buttonLabel, reset }: SaveChangesProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      alignItems={'center'}
      style={{
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        height: 45,
        paddingLeft: 4,
        paddingRight: 4,
      }}>
      <Grid item xs={6} container>
        <Grid item xs={12}>
          <OPBoldTypograpghy style={{ color: 'white' }}>{label}</OPBoldTypograpghy>
        </Grid>
        {buttonLabel === 'Delete' && (
          <Grid item xs={12}>
            <Typography variant="caption" style={{ color: 'white' }}>
              Irreversible action
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          id={`js-watchlist-action-${buttonLabel}`}
          style={{
            color: buttonLabel === 'Delete' ? theme.palette.error.main : theme.palette.primary.main,
            backgroundColor: 'white',
          }}
          onClick={func}>
          <OPBoldTypograpghy>{buttonLabel}</OPBoldTypograpghy>
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id={`js-watchlist-action-cancel`} style={{ color: 'white' }} onClick={() => reset()}>
          <OPSemiBoldTypograpghy>{'Cancel'}</OPSemiBoldTypograpghy>
        </Button>
      </Grid>
    </Grid>
  );
};

/**
 * Used for the Count to show in the Text field and dropdown
 */
type AvatarProps = {
  backgroundColor: string;
  text: number;
};

export const AvatarWithText: React.FC<AvatarProps> = ({ backgroundColor, text }: AvatarProps) => {
  const theme = useTheme();
  return (
    <Avatar
      variant="square"
      style={{
        backgroundColor: backgroundColor,
        width: '100%',
        height: 24,
        borderRadius: theme.shape.borderRadius,
      }}>
      <OPBoldTypograpghy
        style={{
          fontWeight: 'bold',
          fontSize: 10,
          textAlign: 'center',
          color: theme.palette.mode === 'light' ? '#ffffff' : '#000000',
        }}>
        {text}
      </OPBoldTypograpghy>
    </Avatar>
  );
};
