import { Grid, Typography } from '@mui/material';
import formatting from '@op/shared/src/models/how/formatting';
import {
  howDataState,
  incomeCombinationSelectedIdState,
  previousSelectedSymbolState,
  selectedSymbolState,
  strategyShareDataState,
} from '@op/shared/src/states';
import { selectedOptionGridState } from '@op/shared/src/states/how/income-how-states';
import React, { useContext, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { IncomeControlsWidget } from './income-controls-widget';
import { IncomeStrategyWidget } from './income-strategy-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { IncomeLoaderWidget } from './income-loader-widget';

export const IncomeWidget: React.FC = () => {
  const shareRoute = useMatch('/share/*');
  const symbol = useRecoilValue(selectedSymbolState);
  const howData = useRecoilValue(howDataState);
  const previousSelectedSymbol = useRecoilValue(previousSelectedSymbolState('income'));
  const selectedCombinationId = useRecoilValue(incomeCombinationSelectedIdState);
  const resetSelectedOption = useResetRecoilState(selectedOptionGridState(selectedCombinationId));
  const { t } = useContext(LocalizationContext);
  const strategyShareData = useRecoilValue(strategyShareDataState);
  //TODO: Why was this.
  //const [canupdateCostBasis, setCanupdateCostBasis] = useState(false);
  useEffect(() => {
    /*
     * This widget can be returned from shareRoute as well.
     * `SharePageRoute` already initiated all recoil states from the shared data.
     * NOTE: Hence, DO NOT RESET the states again on navigation to this widget.
     */
    if (
      shareRoute &&
      strategyShareData &&
      strategyShareData?.combinationType &&
      strategyShareData?.combinationType === 'Income'
    ) {
      return;
    }
    if (!howData || howData.symbol === previousSelectedSymbol) {
      return;
    }
    //Reset of options grid needs to be here as on change of tab Trade/Portfolio selected option should not change
    resetSelectedOption();
  }, [howData]);

  if (!howData) {
    return <IncomeLoaderWidget />;
  }

  //If there is no Option chain, no suggestion can be made.
  if (!howData.chain || howData.chain.rows.length === 0) {
    return (
      <Grid justifyContent={'center'} alignItems={'center'} p={2}>
        <Typography variant="body1">
          {`${t('how.incomeStrategies.text.noListedOptions')} ${formatting.symbolDotExchangeToSymbol(
            symbol?.toUpperCase() || '',
          )}, ${t('how.incomeStrategies.text.noIncomeStrategies')}`}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container px={1.5}>
      <Grid item xs={12}>
        <IncomeControlsWidget />
      </Grid>
      <Grid item xs={12}>
        <IncomeStrategyWidget />
      </Grid>
    </Grid>
  );
};
