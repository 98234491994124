import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import {
  EditPortfolioResponse,
  OptionsChains,
  PortfolioSaveTradePosition,
  SavePortfolioRequest,
} from '@op/shared/src/models';
import { EventType, LegType } from '@op/shared/src/models/enums/enums';
import {
  OptionsPlayNumberFormatInput,
  OptionsPlayPortfolioTableWidget,
  OptionsPlayTextField,
  ScrollableBox,
} from '../styled';
import { PortfolioPositionsService, PowerhouseProService } from '@op/shared/src/services';
import React, { useEffect, useState } from 'react';
import {
  portfoliosDataState,
  selectedSymbolState,
  tradesCachedState,
  watchListsCachedState,
} from '@op/shared/src/states';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import ApplicationContext from '@op/shared/src/models/how/application-context';
import CloseIcon from '@mui/icons-material/Close';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import LocalizationContext from '../react-i18next/localization-context';
import { PortfolioPositionsSearch } from './portfolio-positions-search-widget';
import { coveredCallDataCachedState } from '@op/shared/src/states/reports/covered-call-states';
import { creditSpreadDataCachedState } from '@op/shared/src/states/reports/credit-spread-states';
import formatting from '@op/shared/src/models/how/formatting';
import helpers from '@op/shared/src/models/how/helpers';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { shortPutDataCachedState } from '@op/shared/src/states/reports/short-put-states';

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const MuiDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, pt: 1, pb: 1, pl: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 10, top: 10 }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface interfaceData {
  strikePrice: number[];
  expiry: string;
  options?: OptionsChains[];
}

export interface SaveTradeDialogProps {
  selectedPortfolioPositions: PortfolioSaveTradePosition | undefined;
  setEditPortfolioData: (value: boolean) => void;
  isNewPortfolio: boolean;
  disableEdit: boolean;
  onCloseDialog: (value: any) => void;
  //onCollapseCallback: (val: boolean) => void;
}

export const SaveTradeDialogs: React.FC<SaveTradeDialogProps> = ({
  selectedPortfolioPositions,
  setEditPortfolioData,
  isNewPortfolio,
  disableEdit,
  onCloseDialog,
}: SaveTradeDialogProps) => {
  const { t } = React.useContext(LocalizationContext);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setNotifications = useSetRecoilState(notificationsState);
  const resetPortfoliosData = useResetRecoilState(portfoliosDataState);
  const resetWatchlists = useResetRecoilState(watchListsCachedState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const resetCoveredCalls = useResetRecoilState(coveredCallDataCachedState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);
  const [symbol, setSymbol] = useState('');
  const [portfolioSymbol, setPortfolioSymbol] = React.useState('Enter new Portfolio Name');
  const [openDialog, setOpenDialog] = React.useState(true);
  const [editData, setEditData] = React.useState<PortfolioSaveTradePosition | undefined>(selectedPortfolioPositions);
  const theme = useTheme<Theme>();
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const dateOptions: any = { month: 'long', day: 'numeric', year: 'numeric' };
  const [isNameError, setIsNameError] = useState(false);

  const borderStyle: any = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  };
  const legTypes = [
    { value: 'Call', label: 'Call' },
    { value: 'Put', label: 'Put' },
    { value: 'Security', label: 'Security' },
  ];

  const newPortfolioPosition = {
    id: null,
    symbol: symbol.toUpperCase(),
    legType: 'Call',
    quantity: '1',
    expiry: '',
    strike: '',
    costBasisTotal: '0',
    strikePriceArray: [] as any,
    expiryDateArray: [] as any,
    errorMessage: '',
  };

  useEffect(() => {
    if (!selectedPortfolioPositions) {
      return;
    }
    setEditData(selectedPortfolioPositions);
    setPortfolioSymbol(selectedPortfolioPositions.name || portfolioSymbol);
  }, [selectedPortfolioPositions]);

  useEffect(() => {
    if (!symbol) {
      return;
    }
    newPortfolioPosition.symbol = symbol.toUpperCase();
  }, [symbol]);

  if (!editData) {
    return <></>;
  }

  const handleClose = () => {
    setOpenDialog(false);
    setIsSearchDisabled(false);
    setEditPortfolioData(false);
    onCloseDialog(selectedPortfolioPositions);
  };

  const portfolioNameCheck = () => {
    if (portfolioSymbol === 'Enter new Portfolio Name' || portfolioSymbol === '') {
      setIsNameError(true);
      setNotifications([{ type: 'error', content: 'Portfolio name is required' }]);
    } else {
      setIsNameError(false);
    }
    ApplicationContext.userActivityHub?.logActivity(
      'portfolioNameEditor',
      'portfolioNameEditor',
      ' ',
      EventType.Click,
      selectedSymbol,
    );
  };
  const changeSymbol = (event: any) => {
    if (event.key === 'Enter') {
      setPortfolioSymbol(event.target.value);
    }
  };

  const addPosition = (value?: string) => {
    if (value?.trim().length === 0 || symbol.trim().length === 0) {
      setNotifications([
        { type: 'error', content: `Please enter the symbol before clicking on 'Add position' button` },
      ]);
      return;
    }
    addPortfolioPosition(value ? value : symbol);
    setSymbol('');
  };

  const addPortfolioPosition = async (pos: string) => {
    if (!pos) {
      return;
    }
    const res = await PowerhouseProService.instance.AddPortfolioPosition(pos);
    if (res.hasErrors || !res.data) {
      setNotifications([{ type: 'error', content: 'Quote Not Found' }]);
      return;
    }
    newPortfolioPosition.symbol = pos;
    let expiryArray = getExpiryArray(res.data);
    newPortfolioPosition.expiryDateArray = expiryArray;
    // Note : Uncomment below lines for bydefault date selection
    // newPortfolioPosition.expiry = expiryArray[0].expiry;
    editData.portfolioPositions.push(newPortfolioPosition as unknown as EditPortfolioResponse);
    editData.portfolioPositions.sort((a, b) => a.symbol.localeCompare(b.symbol));
    setEditData({ ...editData });
    setSymbol('');
  };

  const getExpiryArray = (options: OptionsChains[]) => {
    const groupData: interfaceData[] = [];
    options.map((e) => {
      let p = options.filter((l) => l.expiry === e.expiry);
      let a = e.expiry;
      let b = p.map((c) => c.strikePrice);

      let obj: interfaceData = {
        strikePrice: [],
        expiry: '',
        options: [],
      };
      obj.strikePrice = b;
      obj.expiry = a;

      let hasAleadyObj = groupData.some((o) => o.expiry === obj.expiry);
      if (!hasAleadyObj) {
        groupData.push(obj);
      }
    });
    return groupData;
  };

  const getStrikeArray = (expiryArray: interfaceData[], date: string) => {
    const strikeList: number[] = [];
    expiryArray.map((e: { expiry: string; strikePrice: any[] }) => {
      if (e.expiry === date) {
        e.strikePrice.map((ele: number) => {
          strikeList.push(ele);
        });
      }
    });
    return strikeList[0];
  };

  const handleChangeExpiry = (event: any, index: number, position: any) => {
    editData.portfolioPositions[index].expiry = event.target.value;
    editData.portfolioPositions[index].strike = getStrikeArray(
      position?.expiryDateArray,
      editData.portfolioPositions[index].expiry,
    );
    setIsSearchDisabled(false);
    setEditData({ ...editData });
  };
  const handleChangeStrike = (event: any, index: number) => {
    editData.portfolioPositions[index].strike = event.target.value;
    setEditData({ ...editData });
  };
  const handleChangeLeg = (event: any, index: number) => {
    editData.portfolioPositions[index].legType = event.target.value;
    setEditData({ ...editData });
  };
  const onChangeQuantity = (value: string, index: number) => {
    const pattern = /^[+-]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (pattern.test(value)) {
      editData.portfolioPositions[index].quantity = isNaN(parseFloat(value)) ? '1' : value;
      setEditData({ ...editData });
    }
  };
  const onChangeQuantityBlur = (value: string, index: number) => {
    if (value.trim().length === 0) {
      editData.portfolioPositions[index].quantity = '1';
      setEditData({ ...editData });
    }
  };
  const onChangeCostBasis = (value: string, index: number) => {
    const pattern = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (pattern.test(value)) {
      editData.portfolioPositions[index].costBasisTotal = isNaN(parseFloat(value)) ? '0' : value;
      setEditData({ ...editData });
    }
  };
  const onChangeCostBasisBlur = (value: string, index: number) => {
    if (value.trim().length === 0) {
      editData.portfolioPositions[index].costBasisTotal = '0';
      setEditData({ ...editData });
    }
    setIsSearchDisabled(false);
  };
  const onCheckRowItem = (event: boolean, index: number) => {
    editData.portfolioPositions[index].markedAsDeleted = event;
    setEditData({ ...editData });
  };
  const delRow = (index: number) => {
    editData.portfolioPositions.splice(index, 1);
    setIsSearchDisabled(false);
    setEditData({ ...editData });
  };

  const validateData = () => {
    let costCheck = 'CostBasis cannot be empty/zero';
    let expiryCheck = 'Select Expiry/Strike';
    let isValid = false;
    let duplicatePositions = new Set();
    editData.portfolioPositions.map((p: any, i) => {
      p.errorMessage = '';
      if (p.legType === LegType.SECURITY) {
        if (p.costBasisTotal === 0 || isNaN(p.costBasisTotal)) {
          isValid = true;
          if (p.costBasisTotal === 0 || isNaN(p.costBasisTotal)) {
            p.errorMessage += `${costCheck}`;
          }
          p.errorMessage += ` for the row: ${i + 1}`;
        } else {
          isValid = false;
        }
      } else {
        if (p.costBasisTotal === 0 || isNaN(p.costBasisTotal) || p.expiry === '') {
          isValid = true;
          if (p.expiry === '') {
            p.errorMessage += `${expiryCheck}`;
          }
          if ((p.costBasisTotal === 0 || isNaN(p.costBasisTotal)) && p.expiry === '') {
            p.errorMessage += ` & `;
          }
          if (p.costBasisTotal === 0 || isNaN(p.costBasisTotal)) {
            p.errorMessage += `${costCheck}`;
          }
          p.errorMessage += ` for the row: ${i + 1}`;
        } else {
          isValid = false;
        }
      }
      /**
       * Below check is required as there are two different formats for new and existing positions
       */
      let formattedExpiry = undefined;
      if (p.expiry && helpers.isString(p.expiry)) {
        const expiry = p.expiry?.includes('/') ? p.expiry : formatExpiry(p.expiry);
        formattedExpiry = DateTimeHelper.toExpiryKey(new Date(expiry));
      }
      const key = formatting.customReplaceAll(`${p.symbol}${formattedExpiry}${p.strike}${p.legType}`, 'null', '');
      // const key = `${p.symbol}${formattedExpiry}${p.strike}${p.legType}`.replaceAll('null', '');
      duplicatePositions.add(key);
    });
    setEditData({ ...editData });
    setIsSearchDisabled(isValid);
    if (duplicatePositions.size !== editData.portfolioPositions.length) {
      setNotifications([{ type: 'error', content: 'Duplicate positions found' }]);
      return true;
    }
    return editData.portfolioPositions.some((p: any) => p?.errorMessage.length > 0);
  };

  const formatExpiry = (date: string) => {
    let expiry = date;
    let yyyy = parseInt(expiry.substr(0, 4), 10);
    let mm = parseInt(expiry.substr(4, 2), 10);
    let dd = parseInt(expiry.substr(6, 2), 10);
    return `${mm}/${dd}/${yyyy}`;
  };

  const savePortfolioPositions = async () => {
    let isDataValid = validateData();
    if (portfolioSymbol === 'Enter new Portfolio Name' || portfolioSymbol.trim() === '') {
      setNotifications([{ type: 'error', content: 'Portfolio name is required' }]);
      return;
    }
    if (isDataValid) {
      return;
    }
    let modifiedObj = editData.portfolioPositions.map((i) =>
      i.id == null
        ? ({
            id: '',
            symbol: i.symbol,
            quantity: parseFloat(i.quantity).toFixed(2),
            costBasisTotal: parseFloat(i.costBasisTotal).toFixed(2),
            legType: i.legType,
            expiry: formatExpiry(i.expiry),
            strike: i.strike,
            isNew: true,
            markedAsDeleted: i.markedAsDeleted || false,
          } as unknown as EditPortfolioResponse)
        : i,
    );

    const portfolioSaveInput = {
      portfolioPositions: modifiedObj,
      totalRecords: editData.portfolioPositions.length,
      portfolioName: portfolioSymbol,
      portfolioId: editData.id,
      isManualSelection: true,
    };
    const res = await PortfolioPositionsService.instance.savePortfolioPositions(
      portfolioSaveInput as unknown as SavePortfolioRequest,
    );
    if ((res.data as unknown as string).toLowerCase() !== 'none') {
      setNotifications([{ type: 'error', content: res.data as unknown as string }]);
      return;
    }
    selectedPortfolioPositions.name = portfolioSymbol;
    resetPortfoliosData();
    resetWatchlists();
    resetTradeIdeas();
    resetCreditSpreads();
    resetCoveredCalls();
    resetShortPuts();
    handleClose();
    setNotifications([{ type: 'success', content: 'Your portfolio is now ready' }]);
  };

  const renderExpiryArray = (index: number, position: any) => {
    const expiryList: any[] = [];
    position?.expiryDateArray.map((e: { expiry: any }, index: any) => {
      expiryList.push(e.expiry);
    });
    let isErr = false;
    if (
      position?.errorMessage?.length > 0 &&
      position?.errorMessage.includes('Expiry/Strike') &&
      position?.errorMessage.includes(`${index + 1}`)
    ) {
      isErr = true;
    }

    return (
      <Select
        variant="outlined"
        error={isErr}
        displayEmpty
        value={position.expiry}
        onChange={(e: any) => handleChangeExpiry(e, index, position)}
        sx={{
          width: '100%',
          height: 35,
          borderRadius: 2,
        }}>
        <MenuItem value="">{'Select expiry date'}</MenuItem>
        {expiryList.map((e, index) => (
          <MenuItem key={index} value={e}>
            {DateTimeHelper.format(e)}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderStrikeArray = (index: number, position: any) => {
    const strikeList: number[] = [];
    position?.expiryDateArray.map((e: { expiry: string; strikePrice: any[] }) => {
      if (e.expiry === position.expiry) {
        e.strikePrice.map((ele: number) => {
          strikeList.push(ele);
        });
      }
    });
    if (!position.expiry) {
      return;
    }
    return (
      <Select
        variant="outlined"
        value={position.strike}
        onChange={(e) => handleChangeStrike(e, index)}
        sx={{
          width: '100%',
          height: 35,
          borderRadius: 2,
        }}>
        {strikeList.map((item: any, index: any) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderCostBasis = (index: number, position: any) => {
    let isErr = false;
    if (position?.errorMessage?.length > 0 && position?.errorMessage?.includes((index + 1).toString())) {
      isErr = true;
    }
    return (
      <OptionsPlayTextField
        error={isErr}
        id="costBasisTotal"
        name="costBasisTotal"
        variant="outlined"
        size="small"
        value={position.costBasisTotal}
        style={position.id == null ? null : { ...borderStyle }}
        inputProps={{ style: { textAlign: 'right', paddingRight: 8 } }}
        onChange={(e) => onChangeCostBasis(e.target.value, index)}
        onBlur={(e) => onChangeCostBasisBlur(e.target.value, index)}
        sx={{ width: '60%', height: 35, borderRadius: 2, textAlign: 'right' }}
        InputProps={{
          inputComponent: OptionsPlayNumberFormatInput as any,
        }}
      />
    );
  };

  const renderQuantity = (index: number, position: any) => {
    return (
      <OptionsPlayTextField
        id="quantity"
        name="quantity"
        variant="outlined"
        size="small"
        value={position.quantity}
        style={position.id == null ? null : { ...borderStyle }}
        inputProps={{
          style: { textAlign: 'right', paddingRight: 8 },
        }}
        onChange={(e) => onChangeQuantity(e.target.value, index)}
        onBlur={(e) => onChangeQuantityBlur(e.target.value, index)}
        sx={{ width: '80%', height: 35, borderRadius: 2 }}
        InputProps={{
          inputComponent: OptionsPlayNumberFormatInput as any,
        }}
      />
    );
  };

  const renderUploadedPortfolioPosition = (position: any, index: number) => {
    if (!position.id) {
      return (
        <TableRow key={index}>
          <TableCell sx={{ width: '8%' }}>
            <Typography>{formatting.symbolDotExchangeToSymbol(position.symbol)}</Typography>
          </TableCell>
          <TableCell sx={{ width: '15%' }}>
            <Select
              variant="outlined"
              value={position.legType}
              onChange={(e: any) => handleChangeLeg(e, index)}
              sx={{
                width: '100%',
                height: 35,
                borderRadius: 2,
              }}>
              {legTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell sx={{ width: '17%' }}>{renderQuantity(index, position)}</TableCell>
          <TableCell sx={{ width: '20%' }}>
            {position.legType === LegType.SECURITY ? '' : renderExpiryArray(index, position)}
          </TableCell>
          <TableCell sx={{ width: '15%' }}>
            {position.legType === LegType.SECURITY ? '' : renderStrikeArray(index, position)}
          </TableCell>
          <TableCell sx={{ width: '20%' }}>{renderCostBasis(index, position)}</TableCell>
          <TableCell sx={{ width: '5%' }}>
            <IconButton onClick={() => delRow(index)} sx={{ fontSize: 'small' }}>
              <CloseIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow key={index}>
        <TableCell sx={{ width: '8%' }}>
          <Typography>{formatting.symbolDotExchangeToSymbol(position.symbol)}</Typography>
        </TableCell>
        <TableCell sx={{ width: '15%' }}>
          <Typography>{position.legType}</Typography>
        </TableCell>
        <TableCell sx={{ width: '17%' }}>{disableEdit ? position.quantity : renderQuantity(index, position)}</TableCell>
        <TableCell sx={{ width: '20%' }}>{position.expiry}</TableCell>
        <TableCell sx={{ width: '15%' }}>{position.strike}</TableCell>
        {/* <TableCell sx={{ width: '20%' }}>
            <OptionsPlayTextField
              error={isErr}
              id="costBasisTotal"
              name="costBasisTotal"
              type="number"
              variant="outlined"
              size="small"
              value={position.costBasisTotal}
              onChange={(e) => onChangeCostBasis(e, index)}
              sx={{ width: '60%', height: 35, borderRadius: 2 }}
            />
          </TableCell> */}
        <TableCell sx={{ width: '20%' }}>
          {disableEdit ? position.costBasisTotal : renderCostBasis(index, position)}
        </TableCell>
        {disableEdit ? null : (
          <TableCell sx={{ width: '5%' }}>
            <Checkbox
              size="small"
              checked={position.markedAsDeleted}
              onChange={(e: any) => onCheckRowItem(e.target.checked, index)}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderPortfolioPositions = () => {
    return editData.portfolioPositions.map((p, index) => renderUploadedPortfolioPosition(p, index));
  };

  const renderUploadedPortfolioPositions = () => {
    return (
      <OptionsPlayPortfolioTableWidget>
        <TableHead>
          <TableRow>
            <TableCell>{t('common.titles.symbol')}</TableCell>
            <TableCell>{t('profile.testPortfolio.js.legtypeLabel')}</TableCell>
            <TableCell>{t('how.combinationEditor.labels.quantity')}</TableCell>
            <TableCell>{t('common.labels.expiry')}</TableCell>
            <TableCell>{t('how.combinationEditor.labels.strike')}</TableCell>
            <TableCell>{t('common.labels.totalCost')}</TableCell>
            {disableEdit ? null : <TableCell>{t('profile.testPortfolio.js.markDelete')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{renderPortfolioPositions()}</TableBody>
      </OptionsPlayPortfolioTableWidget>
    );
  };

  const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setIsNameError(true);
    }
    setIsNameError(false);
    setPortfolioSymbol(e.target.value);
  };

  const renderTitle = () => {
    isNewPortfolio && setPortfolioSymbol('');
    ApplicationContext.userActivityHub?.logActivity(
      'portfolioNameEditorDectivate',
      'portfolioNameEditorDectivate',
      ' ',
      EventType.Click,
      selectedSymbol,
    );
  };
  const renderDialogTitle = () => {
    return (
      <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography>{t('modals.savePortfolio.portfolioName')}</Typography>
        <Grid item xs={4}>
          {
            <OptionsPlayTextField
              fullWidth
              id="portfolio-symb"
              name="portfolio symbol"
              type="text"
              variant="standard"
              size="small"
              error={isNameError}
              onBlur={portfolioNameCheck}
              onFocus={renderTitle}
              onChange={(e) => onChangeName(e)}
              value={portfolioSymbol}
              onKeyPress={(event: any) => {
                changeSymbol(event);
              }}
              style={{ maxWidth: '70%' }}
            />
          }
        </Grid>
      </MuiDialogTitle>
    );
  };

  const renderErrorList = () => {
    return (
      <Grid item sx={{ pl: 2 }}>
        {editData.portfolioPositions.map((item: any, i) => {
          return (
            <Typography color="secondary" key={i}>
              {item.errorMessage}
            </Typography>
          );
        })}
      </Grid>
    );
  };

  const renderDialogContent = () => {
    return (
      <DialogContent dividers sx={{ p: 1 }}>
        <TableContainer>
          <ScrollableBox
            sx={{
              minHeight: '40%',
              width: '100%',
              background: theme.palette.background.paper,
            }}>
            {!editData || !editData.portfolioPositions || editData.portfolioPositions.length === 0 ? (
              <Typography textAlign={'center'} variant="h6">
                No positions available
              </Typography>
            ) : (
              renderUploadedPortfolioPositions()
            )}
          </ScrollableBox>
        </TableContainer>
      </DialogContent>
    );
  };

  const renderAddPositionsActions = () => {
    if (!disableEdit) {
      return (
        <Grid container xs={12} columnSpacing={1} sx={{ pl: 2 }}>
          <Grid item>
            <PortfolioPositionsSearch
              onSelect={(s) => addPosition(s)}
              searchSymbol={symbol}
              setSearchSymbol={(v) => setSymbol(v.toUpperCase())}
              isSearchDisabled={isSearchDisabled}
              onFocusSearch={() => validateData()}
            />
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" autoFocus onClick={() => addPosition(symbol)}>
              <Typography variant="button">{t('tradeTicket.addPosition')}</Typography>
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return;
    }
  };

  const renderDialogActions = () => {
    return (
      <DialogActions>
        {renderAddPositionsActions()}

        <Button color="primary" variant="contained" onClick={savePortfolioPositions}>
          <Typography variant="button">{t('common.buttons.save')}</Typography>
        </Button>
        <Button color="primary" variant="contained" onClick={handleClose}>
          <Typography variant="button">{t('common.buttons.cancel')}</Typography>
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      PaperProps={{
        sx: { minWidth: '60%', maxWidth: '60%', minHeight: '70%', maxHeight: '70%', borderRadius: '15px' },
      }}>
      {renderDialogTitle()}
      {renderErrorList()}
      {renderDialogContent()}
      {renderDialogActions()}
    </Dialog>
  );
};
