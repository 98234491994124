export class SaveResponse {
  errorCode = '';
  formattedMessage = '';
  isSuccess = false;

  public static fromJson = (data: SaveResponse): SaveResponse => {
    const model = new SaveResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
