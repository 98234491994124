import { IPosition, YesterdayQuote, AccountAdvisor, PortfolioPositionAccount } from '.';

export class PortfolioPositionEntity implements IPosition {
  symbol = '';
  quotationSymbol = '';
  costBasisPerUnit = -1;
  legType = '';
  expiry = new Date();
  strikePrice = -1;
  positionType = '';
  quantity = -1;
  yesterdayQuote = new YesterdayQuote();
  hash = '';
  accountAdvisor: AccountAdvisor[] = [];
  portfolioPositionAccount = new PortfolioPositionAccount();
  moneyType = '';

  public static fromText = (data: any) => {
    const value = new PortfolioPositionEntity();
    value.symbol = data.symbol;
    value.quotationSymbol = data.quotationSymbol;
    value.costBasisPerUnit = data.costBasisPerUnit;
    value.legType = data.legType;
    value.expiry = data.expiry;
    value.strikePrice = data.strikePrice;
    value.positionType = data.positionType;
    value.quantity = data.quantity;
    value.yesterdayQuote = data.yesterdayQuote;
    value.hash = data.hash;
    value.accountAdvisor = data.accountAdvisor;
    value.portfolioPositionAccount = data.portfolioPositionAccount;
    value.moneyType = data.moneyType;
    return value;
  };

  static fromSelf = (self: PortfolioPositionEntity) => {
    const model = new PortfolioPositionEntity();
    model.symbol = self.symbol;
    model.quotationSymbol = self.quotationSymbol;
    model.costBasisPerUnit = self.costBasisPerUnit;
    model.legType = self.legType;
    model.expiry = self.expiry;
    model.strikePrice = self.strikePrice;
    model.positionType = self.positionType;
    model.quantity = self.quantity;
    model.yesterdayQuote = self.yesterdayQuote;
    model.hash = self.hash;
    model.accountAdvisor = self.accountAdvisor;
    model.portfolioPositionAccount = self.portfolioPositionAccount;
    model.moneyType = self.moneyType;
    return model;
  };
}
