import { debounce } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ICommonOrderRequest, IPlaceOrderLeg, IStopOrderRequest } from '../models';
import { stopPlaceOrderForReview } from '../services';
import { tsEnvironmentState, tsOrderTicketState, tsSelectedAccountState } from '../states/trade-station-states';
import {
  commonOrderRequestState,
  confirmOrderTicketState,
  stopOrderRequestState,
  tsOrdetTicketPositionState,
} from '../states/ts-ticket-states';
import { CommonOrderTicketWidget } from './common-order-ticket-widget';
import { TSOrderType } from '@op/shared/src/models/enums/enums';

interface Props {
  setOrderType: (type: string) => void;
  orderLegs: IPlaceOrderLeg[];
}

export const StopOrderTicketWidget: React.FC<Props> = ({ setOrderType, orderLegs }: Props) => {
  const orderType = TSOrderType.StopMarket;
  const selectedAccount = useRecoilValue(tsSelectedAccountState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setStopOrderRequest = useSetRecoilState(stopOrderRequestState);
  const setConfirmOrderTicketData = useSetRecoilState(confirmOrderTicketState);
  const [commonOrderRequest, setCommonOrderRequest] = useRecoilState(commonOrderRequestState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const tsOrderTicketPositions = useRecoilValue(tsOrdetTicketPositionState);
  const setOrderTicketValue = useSetRecoilState(tsOrderTicketState);

  useEffect(() => {
    const req: ICommonOrderRequest = {
      ...commonOrderRequest,
      limitPrice: undefined,
      stopPrice: commonOrderRequest && commonOrderRequest.stopPrice ? commonOrderRequest.stopPrice : '',
    };
    setCommonOrderRequest(req);
  }, [tsOrderTicketPositions]);

  if (!tsOrderTicketPositions || !commonOrderRequest) {
    return <></>;
  }

  const stopOrderPreview = async (isBuy: boolean) => {
    if (!isBuy) {
      return;
    }
    setOrderTicketValue('isLoading');
    let request: IStopOrderRequest = {
      accountID: selectedAccount[0].accountID,
      buyingPowerWarning: 'Enforce',
      orderType: orderType,
      timeInForce: commonOrderRequest.timeInForce,
      gtdTime: commonOrderRequest.gtdTime,
      route: commonOrderRequest.route,
      stopPrice: commonOrderRequest.stopPrice,
      legs: orderLegs,
    };
    setStopOrderRequest(request);
    const response = await stopPlaceOrderForReview(tsEnv, request);
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
      setOrderTicketValue('default');
      return;
    }
    setConfirmOrderTicketData(response.data);
    setOrderTicketValue('completed');
  };

  const debouncedStopOrderPreview = debounce(stopOrderPreview, 1000);

  const disabledSendOrdersButton = () => {
    if (selectedAccount.length > 1) {
      return true;
    }
    if (commonOrderRequest.timeInForce === '' || commonOrderRequest.stopPrice === '') {
      return true;
    }
    return false;
  };

  return (
    <CommonOrderTicketWidget
      key={'stopOrder'}
      orderType={orderType}
      setOrderType={setOrderType}
      orderLegs={orderLegs}
      isDisabled={disabledSendOrdersButton()}
      onClickSendOrder={(v) => debouncedStopOrderPreview(v)}
    />
  );
};
