import { Grid } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import { BuyOrSell, LegType } from '@op/shared/src/models/enums/enums';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { tsPositionDataState, tsSelectedAccountIdsState } from '..';
import { IconComponent } from '../../common';
import { OptionsPlayTextField } from '../../styled';
import { IOrderTicketPosition, checkExistingPositionByAccountID } from '../models/ts-helpers';
import { tsOrdetTicketPositionState, tsTradeOrderPropsState, tsTriggerState } from '../states/ts-ticket-states';
import { StyledTableCell } from '../ts-styles-component';

interface Props {
  index: number;
  position: IOrderTicketPosition;
}

export const OrderQuantityWidget: React.FC<Props> = ({ index, position }) => {
  const tsTradeOrderProps = useRecoilValue(tsTradeOrderPropsState);
  const [tsOrderTicketPositions, setTsOrderTicketPositions] = useRecoilState(tsOrdetTicketPositionState);
  const setTsTrigger = useSetRecoilState(tsTriggerState);
  const [quantity, setQuantity] = useState(position.qty);
  // const tsPositions = useRecoilValue(getAllPositionsByAccountState);
  const tsPositions = useRecoilValue(tsPositionDataState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);

  const thresholdIncrement = position.type === LegType.SECURITY ? '100' : '1';

  useEffect(() => {
    if (!position) {
      return;
    }
    if (position.qty === quantity) {
      return;
    }
    setQuantity(position.qty);
  }, [position]);

  const updateFun = (value: string, isClose?: boolean) => {
    let clone = cloneDeep(tsOrderTicketPositions);
    let pos = clone[index];
    if (!pos) {
      return;
    }
    pos.qty = value;
    clone[index] = pos;
    setQuantity(pos.qty);
    setTsOrderTicketPositions(clone);
    if (isClose && pos.qty === value) {
      return;
    }
    setTsTrigger({ target: 'snapshot', actionRequired: true });
  };

  const onQtyUp = () => {
    const incrementNum = Number(thresholdIncrement);
    const absQuantity = Math.abs(Number(position.qty));
    let newQuantity = absQuantity + incrementNum;
    const isBuy = position.buyOrSell === BuyOrSell.BUY;
    newQuantity = isBuy ? Math.abs(newQuantity) : -Math.abs(newQuantity);
    if (checkForClose(newQuantity.toString())) {
      return;
    }
    updateFun(newQuantity.toString());
  };

  const onQtyDown = () => {
    const decrementNum = Number(thresholdIncrement);
    const absQuantity = Math.abs(Number(position.qty));
    let newQuantity = absQuantity - decrementNum;
    if (newQuantity < decrementNum) {
      return;
    }
    const isBuy = position.buyOrSell === BuyOrSell.BUY;
    newQuantity = isBuy ? Math.abs(newQuantity) : -Math.abs(newQuantity);
    if (checkForClose(newQuantity.toString())) {
      return;
    }
    updateFun(newQuantity.toString());
  };

  const onChangeQty = (event) => {
    setQuantity(event.target.value);
  };

  const checkForClose = (value: string) => {
    if (!position.isClose) {
      return false;
    }
    const existingPosition = checkExistingPositionByAccountID(tsPositions, selectedAccountIds, position);
    const absQtyFromProps = existingPosition
      ? Math.abs(Number(existingPosition.quantity)).toString()
      : Math.abs(Number(tsTradeOrderProps[index].qty)).toString();
    const isBuy = position.buyOrSell === BuyOrSell.BUY;
    const qtyFromProps = isBuy ? absQtyFromProps : '-' + absQtyFromProps;
    const absValue = Math.abs(Number(value));
    const newValue = isBuy ? absValue : -absValue;
    if (isBuy && newValue > Number(qtyFromProps)) {
      setQuantity(qtyFromProps);
      updateFun(qtyFromProps, true);
      return true;
    }
    if (!isBuy && newValue < Number(qtyFromProps)) {
      setQuantity(qtyFromProps);
      updateFun(qtyFromProps, true);
      return true;
    }
  };

  const onQtyBlur = () => {
    const value = quantity;
    const defaultQty = position.buyOrSell === BuyOrSell.BUY ? thresholdIncrement : '-' + thresholdIncrement;
    if (value === position.qty) {
      return;
    }
    if (checkForClose(value)) {
      return;
    }
    if (value.trim() === '' || Number(value) === 0) {
      updateFun(defaultQty);
      return;
    }
    const absValue = Math.abs(Number(value));
    const newValue = position.buyOrSell === BuyOrSell.BUY ? absValue.toString() : (-absValue).toString();
    updateFun(newValue);
  };

  const renderQuantity = () => {
    return (
      <Grid item container xs={12}>
        <OptionsPlayTextField
          id="outlined-basic"
          type="number"
          value={quantity}
          variant="outlined"
          sx={{ '.MuiOutlinedInput-input': { textAlign: 'center' }, width: '100%' }}
          inputProps={{ style: { height: 15 } }}
          onChange={onChangeQty}
          onBlur={onQtyBlur}
          onKeyDown={(e) => {
            if (e.key.trim().toUpperCase() !== 'ENTER') {
              return;
            }
            onQtyBlur();
          }}
          InputProps={{
            startAdornment: (
              <Grid
                item
                onClick={onQtyUp}
                style={{
                  cursor: 'pointer',
                }}>
                <IconComponent name="upArrowIcon" style={{ transform: 'rotate(180deg)' }} />
              </Grid>
            ),
            endAdornment: (
              <Grid
                item
                onClick={onQtyDown}
                style={{
                  cursor: 'pointer',
                }}>
                <IconComponent name="upArrowIcon" />
              </Grid>
            ),
          }}
        />
      </Grid>
    );
  };

  return (
    <>
      <StyledTableCell>{renderQuantity()}</StyledTableCell>
    </>
  );
};
