import { ApiService } from '@op/shared/src/services';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import {
  ICancelOrder,
  IConfirmOrderTicket,
  IGetOrders,
  ILimitOrderRequest,
  ILimitPlaceOrderRequest,
  IMarketOrderRequest,
  IMarketPlaceOrderRequest,
  IPlaceOrderTicket,
  IStopLimitOrderRequest,
  IStopLimitPlaceOrderRequest,
  IStopOrderRequest,
  IStopPlaceOrderRequest,
  ITradeStationAccounts,
} from '../models';
import { IPositionsAccountBalances } from '../models/getAccountBalance';
import { IGetHistoricalOrders } from '../models/getOrderHistory';
import { ITradeStationPositions } from '../models/getPositions';
import { IDuration, IRoutes } from '../models/order-duration-route';
import { IQuoteSnapshot } from '../models/quote-snapshot';
import { IOptionExpiry, IOptionStrike } from '../models/ts-expiry-strike';
import { TSEnvironmentTypes } from '../states/trade-station-states';
import { getAccountsResponse, getOrdersResponse, getPositionResponse } from './trade-station-response';

const tradeStationURL = '/v1/orders/tradestation';
//TODO Remove after implmentation completed
const isJSON = false;

export const getTradeStationURL = (env: TSEnvironmentTypes) => {
  return `/v1/orders/${env}/tradestation`;
};

export const isTermsAndConditionAccepted = async (): Promise<ResponseViewModel<boolean>> => {
  const url = `${getTradeStationURL('live')}/istncaccepted`;
  const response = await ApiService.instance.post<any, any>(url, null);
  const viewModel = new ResponseViewModel<boolean>();
  viewModel.data = response.data as boolean;
  viewModel.errors = response.errors;
  return viewModel;
};

export const acceptTermsAndConditions = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<boolean>> => {
  const url = `${getTradeStationURL(env)}/accepttermsandcondition`;
  const response = await ApiService.instance.post<any, any>(url, null);
  const viewModel = new ResponseViewModel<boolean>();
  viewModel.data = response.data as boolean;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getLoginURL = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<string>> => {
  const url = `${getTradeStationURL(env)}/getloginurl`;
  const response = await ApiService.instance.get<string>(url);
  const viewModel = new ResponseViewModel<string>();
  viewModel.data = response.data as string;
  viewModel.errors = response.errors;
  return viewModel;
};

export const authenticate = async (env: TSEnvironmentTypes, code: string): Promise<ResponseViewModel<any>> => {
  const url = `${getTradeStationURL(env)}/authenticate?code=${code}&enviornment=${
    env === 'live' ? 'Live' : env === 'sim' ? 'Simulation' : 'None'
  }`;
  const response = await ApiService.instance.get<any>(url);
  const viewModel = new ResponseViewModel<any>();
  viewModel.data = response.data as any;
  // viewModel.data = (response.data as string).replace('https://bromley-dev.optionsplay.com', 'http://localhost:3000'); //response.data as any;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getTSToken = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<any>> => {
  const url = `${getTradeStationURL(env)}/gettokeninfo`;
  const response = await ApiService.instance.post<any, any>(url, null);
  const viewModel = new ResponseViewModel<any>();
  viewModel.data = response.data as any;
  viewModel.errors = response.errors;
  return viewModel;
};

export const logOutTradeStation = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<any>> => {
  const url = `${getTradeStationURL(env)}/logout`;
  const response = await ApiService.instance.get<any>(url);
  const viewModel = new ResponseViewModel<any>();
  viewModel.data = response.data as any;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getEnviornment = async (): Promise<ResponseViewModel<any>> => {
  const url = `${getTradeStationURL('live')}/getenviornment`;
  const response = await ApiService.instance.get<any>(url);
  const viewModel = new ResponseViewModel<any>();
  viewModel.data = response.data as any;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getAccounts = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<ITradeStationAccounts>> => {
  const url = `${getTradeStationURL(env)}/getaccounts`;
  const response = isJSON ? getAccountsResponse : await ApiService.instance.get<ITradeStationAccounts>(url);
  const viewModel = new ResponseViewModel<ITradeStationAccounts>();
  viewModel.data = response.data as unknown as ITradeStationAccounts;
  viewModel.errors = response.errors;
  return viewModel;
};

export const switchToDemo = async (): Promise<ResponseViewModel<any>> => {
  const url = `${getTradeStationURL('sim')}/switchtodemo`;
  const response = await ApiService.instance.put<any, any>(url, {});
  const viewModel = new ResponseViewModel<any>();
  viewModel.data = response.data as unknown as any;
  viewModel.errors = response.errors;
  return viewModel;
};

export const switchToLive = async (): Promise<ResponseViewModel<any>> => {
  const url = `${getTradeStationURL('live')}/switchtolive`;
  const response = await ApiService.instance.put<any, any>(url, {});
  const viewModel = new ResponseViewModel<any>();
  viewModel.data = response.data as unknown as any;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getOrders = async (env: TSEnvironmentTypes, ids: string[]): Promise<ResponseViewModel<IGetOrders>> => {
  const url = `${getTradeStationURL(env)}/getorders`;
  const response = isJSON ? getOrdersResponse : await ApiService.instance.post<string[], IGetOrders>(url, ids);
  const viewModel = new ResponseViewModel<IGetOrders>();
  viewModel.data = response.data as unknown as IGetOrders;
  viewModel.errors = response.errors;
  return viewModel;
};

export const cancelOrder = async (
  env: TSEnvironmentTypes,
  orderId: string,
): Promise<ResponseViewModel<ICancelOrder>> => {
  const url = `${getTradeStationURL(env)}/cancelorder?orderId=${orderId}`;
  const response = await ApiService.instance.delete<ICancelOrder>(url);
  const viewModel = new ResponseViewModel<ICancelOrder>();
  viewModel.data = response.data as unknown as ICancelOrder;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getPositions = async (
  env: TSEnvironmentTypes,
  ids: string[],
): Promise<ResponseViewModel<ITradeStationPositions>> => {
  const url = `${getTradeStationURL(env)}/getpositions`;
  const response = isJSON
    ? getPositionResponse
    : await ApiService.instance.post<string[], ITradeStationPositions>(url, ids);
  const viewModel = new ResponseViewModel<ITradeStationPositions>();
  viewModel.data = response.data as unknown as ITradeStationPositions;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getHistoricalOrders = async (
  env: TSEnvironmentTypes,
  ids: string[],
  days: number,
): Promise<ResponseViewModel<IGetHistoricalOrders>> => {
  const url = `${getTradeStationURL(env)}/gethistoricalorders?days=${days}`;
  const response = await ApiService.instance.post<string[], IGetHistoricalOrders>(url, ids);
  const viewModel = new ResponseViewModel<IGetHistoricalOrders>();
  viewModel.data = response.data as unknown as IGetHistoricalOrders;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getDuration = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<IDuration[]>> => {
  const url = `${getTradeStationURL(env)}/getduration`;
  const response = await ApiService.instance.get<IDuration[]>(url);
  const viewModel = new ResponseViewModel<IDuration[]>();
  viewModel.data = response.data as unknown as IDuration[];
  viewModel.errors = response.errors;
  return viewModel;
};

export const getRoutes = async (env: TSEnvironmentTypes): Promise<ResponseViewModel<IRoutes>> => {
  const url = `${getTradeStationURL(env)}/getroutes`;
  const response = await ApiService.instance.get<IRoutes>(url);
  const viewModel = new ResponseViewModel<IRoutes>();
  viewModel.data = response.data as unknown as IRoutes;
  viewModel.errors = response.errors;
  return viewModel;
};

export const marketPlaceOrderForReview = async (env: TSEnvironmentTypes, request: IMarketOrderRequest) => {
  const url = `${getTradeStationURL(env)}/placemarketorderforreview`;
  const response = await ApiService.instance.put<IMarketOrderRequest, IConfirmOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IConfirmOrderTicket>();
  viewModel.data = response.data as unknown as IConfirmOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const limitPlaceOrderForReview = async (env: TSEnvironmentTypes, request: ILimitOrderRequest) => {
  const url = `${getTradeStationURL(env)}/placelimitorderforreview`;
  const response = await ApiService.instance.put<ILimitOrderRequest, IConfirmOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IConfirmOrderTicket>();
  viewModel.data = response.data as unknown as IConfirmOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const stopPlaceOrderForReview = async (env: TSEnvironmentTypes, request: IStopOrderRequest) => {
  const url = `${getTradeStationURL(env)}/placestoporderforreview`;
  const response = await ApiService.instance.put<IStopOrderRequest, IConfirmOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IConfirmOrderTicket>();
  viewModel.data = response.data as unknown as IConfirmOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const stopLimitPlaceOrderForReview = async (env: TSEnvironmentTypes, request: IStopLimitOrderRequest) => {
  const url = `${getTradeStationURL(env)}/placestoplimitorderforreview`;
  const response = await ApiService.instance.put<IStopLimitOrderRequest, IConfirmOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IConfirmOrderTicket>();
  viewModel.data = response.data as unknown as IConfirmOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const executeMarketOrder = async (env: TSEnvironmentTypes, request: IMarketPlaceOrderRequest) => {
  const url = `${getTradeStationURL(env)}/executemarketorder`;
  const response = await ApiService.instance.put<IMarketPlaceOrderRequest, IPlaceOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IPlaceOrderTicket>();
  viewModel.data = response.data as unknown as IPlaceOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const executeLimitOrder = async (env: TSEnvironmentTypes, request: ILimitPlaceOrderRequest) => {
  const url = `${getTradeStationURL(env)}/executelimitorder`;
  const response = await ApiService.instance.put<ILimitPlaceOrderRequest, IPlaceOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IPlaceOrderTicket>();
  viewModel.data = response.data as unknown as IPlaceOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const executeStopOrder = async (env: TSEnvironmentTypes, request: IStopPlaceOrderRequest) => {
  const url = `${getTradeStationURL(env)}/executestoporder`;
  const response = await ApiService.instance.put<IStopPlaceOrderRequest, IPlaceOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IPlaceOrderTicket>();
  viewModel.data = response.data as unknown as IPlaceOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const executeStopLimitOrder = async (env: TSEnvironmentTypes, request: IStopLimitPlaceOrderRequest) => {
  const url = `${getTradeStationURL(env)}/executestoplimitorder`;
  const response = await ApiService.instance.put<IStopLimitPlaceOrderRequest, IPlaceOrderTicket>(url, request);
  const viewModel = new ResponseViewModel<IPlaceOrderTicket>();
  viewModel.data = response.data as unknown as IPlaceOrderTicket;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getAccountBalances = async (
  env: TSEnvironmentTypes,
  ids: string[],
): Promise<ResponseViewModel<IPositionsAccountBalances>> => {
  const url = `${getTradeStationURL(env)}/getaccountsbalances`;
  const response = isJSON
    ? getPositionResponse
    : await ApiService.instance.post<string[], IPositionsAccountBalances>(url, ids);
  const viewModel = new ResponseViewModel<IPositionsAccountBalances>();
  viewModel.data = response.data as unknown as IPositionsAccountBalances;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getQuoteSnapshots = async (
  env: TSEnvironmentTypes,
  symbols: string[],
): Promise<ResponseViewModel<IQuoteSnapshot>> => {
  const url = `${getTradeStationURL(env)}/getquotessnapshot`;
  const response = await ApiService.instance.post<string[], IQuoteSnapshot>(url, symbols);
  const viewModel = new ResponseViewModel<IQuoteSnapshot>();
  viewModel.data = response.data as unknown as IQuoteSnapshot;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getOptionExpirations = async (
  env: TSEnvironmentTypes,
  symbol: string,
): Promise<ResponseViewModel<IOptionExpiry>> => {
  let url = `${getTradeStationURL(env)}/getexpiries?symbol=${symbol}`;
  const response = await ApiService.instance.post<unknown, IOptionExpiry>(url, undefined);
  const viewModel = new ResponseViewModel<IOptionExpiry>();
  viewModel.data = response.data as unknown as IOptionExpiry;
  viewModel.errors = response.errors;
  return viewModel;
};

export const getOptionStrikes = async (
  env: TSEnvironmentTypes,
  symbol: string,
  expiration: string,
): Promise<ResponseViewModel<IOptionStrike>> => {
  let url = `${getTradeStationURL(env)}/getoptionstrikes?symbol=${symbol}&expiration=${expiration}`;
  const response = await ApiService.instance.post<unknown, IOptionStrike>(url, undefined);
  const viewModel = new ResponseViewModel<IOptionStrike>();
  viewModel.data = response.data as unknown as IOptionStrike;
  viewModel.errors = response.errors;
  return viewModel;
};
