import { PriceCalculationMethod, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import Combination from '@op/shared/src/models/how/combination';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import TradingStrategies from '@op/shared/src/models/how/trading-strategies';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import { howDataState, strategyShareDataState, tradeSimulatorOperationTypeState } from '@op/shared/src/states';
import {
  tradingRangeSimulatorState,
  tradingStrategiesSentimentAtom,
  tradingStrategiesUpdater,
  tradingStrategyAtomFamilyState,
  tradingStrategySelectedStrategyState,
  whatIfSimulatorState,
} from '@op/shared/src/states/how/how-states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SingleStrategyWidget } from '.';

export const TradeSingleStrategyWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const [selectedCombinationId, setSelectedCombinationId] = useRecoilState(tradingStrategySelectedStrategyState);
  const tradeCombination = useRecoilValue(tradingStrategyAtomFamilyState(selectedCombinationId));
  const strategyShareData = useRecoilValue(strategyShareDataState);
  const resetStrategyShareData = useResetRecoilState(strategyShareDataState);
  const setWhatIfSimulator = useSetRecoilState(whatIfSimulatorState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorState);
  const setStrategySentiment = useSetRecoilState(tradingStrategiesSentimentAtom);
  const setNotifications = useSetRecoilState(notificationsState);
  const setTradingStrategiesUpdater = useSetRecoilState(tradingStrategiesUpdater);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);

  //TODO: CS: Vinay please remove after all dev regression complete
  //for trade share.
  // useEffect(() => {
  //   // if (tradeCombination) {
  //   //   return;
  //   // }
  //   //(!shareRoute && !embedderRoute) ||
  //   if (!strategyShareData || isReady || !configuration) {
  //     return;
  //   }
  //   const isTrade = (strategyShareData.combinationType as string) === 'Trade';
  //   if (!isTrade) {
  //     return;
  //   }
  //   if (!howData) {
  //     return;
  //   }
  //   const hasOptionsLegs = strategyShareData.legs.find((i: any) => i.legType !== 'Security');
  //   if (hasOptionsLegs && hasOptionsLegs.length > 0 && !howData.chain) {
  //     return;
  //   }
  //   initializeTradeIfPossible();
  //   return () => {
  //     setIsReady(false);
  //   };
  // }, [howData, configuration, strategyShareData]);

  //for trade share.
  useEffect(() => {
    if (!strategyShareData) {
      return;
    }
    const isTrade = (strategyShareData.combinationType as string) === 'Trade';
    if (!isTrade) {
      return;
    }
    if (!howData) {
      return;
    }
    const hasOptionsLegs = strategyShareData.legs.find((i: any) => i.legType !== 'Security');
    if (hasOptionsLegs && hasOptionsLegs.length > 0 && !howData.chain) {
      return;
    }
    initializeTradeIfPossible();
    return () => {
      resetStrategyShareData();
    };
  }, [strategyShareData]);

  const initializeTradeIfPossible = () => {
    if (!howData) {
      return;
    }
    if (!howData.chain || howData.chain.rows.length === 0) {
      setNotifications([{ type: 'info', content: `Symbol ${howData.symbol.toUpperCase()} has no listed options` }]);
    }
    //TODO: have typed share result.
    const enumKey = formatting.pascalToSnakeCase(strategyShareData.priceCalculationMethod);
    const priceCalculationMethod = PriceCalculationMethod[enumKey as keyof typeof PriceCalculationMethod];
    const targetPrice = strategyShareData.targetPrice as number;
    const targetDate = DateTimeHelper.resolveDate(strategyShareData.targetDate);
    // NOTE: The system works if we do not have datepart to it. Hence, only taking datepart and stripping timepart.
    if (targetDate) {
      targetDate.setHours(0, 0, 0, 0);
    }
    //const costBasis = strategyShareData.costBasis as number; //TODO: where to fill
    //const symbol = strategyShareData.symbol as string;
    const tradingStrategies = TradingStrategies.fromData(howData.originalSentiment, howData);
    const combinations = tradingStrategies.allCombinations;
    const sharedCombination = Combination.fromLegs(strategyShareData.legs, howData, undefined, priceCalculationMethod);
    combinations[0] = sharedCombination;
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, combinations);
    const whatIfSimulator = WhatIfSimulator.fromData(howData, howData.originalSentiment, tradingRangeSimulator);
    whatIfSimulator.whatIfDate = targetDate;
    if (targetPrice) {
      whatIfSimulator.whatIfSPrice = targetPrice;
    }
    setSimulatorOperation(tradeSimulatorOperationType.ShowRisksandCheckilst);
    setStrategySentiment(howData.originalSentiment);
    setTradingStrategiesUpdater(combinations);
    setTradingRangeSimulator(tradingRangeSimulator);
    setWhatIfSimulator(whatIfSimulator);
    setSelectedCombinationId('0');
  };

  return (
    <SingleStrategyWidget
      combination={tradeCombination}
      shareView="trade"
      sharedBy={strategyShareData?.sharedBy as string}
      creationDate={new Date(strategyShareData?.creationDate || new Date())}
    />
  );
};
