import { OptionType } from '../enums/enums';

export class SettingsModel {
  timeFrame: string | undefined;
  aggressiveness: string | undefined;

  private constructor() {}

  static fromData = (timeFrame: string, aggressiveness: string) => {
    const model = new SettingsModel();
    model.aggressiveness = aggressiveness;
    model.timeFrame = timeFrame;
    return model;
  };
}

export class OptionPairModel<T> {
  call: T | undefined; //IAlternativeCall | SettingsModel | undefined;
  put: T | undefined; //IAlternativePut | SettingsModel | undefined;

  fromSelf = (self: OptionPairModel<T>): OptionPairModel<T> => {
    const clone = new OptionPairModel<T>();
    clone.call = self.call;
    clone.put = self.put;
    return clone;
  };

  getOption = (legType: OptionType) => {
    if (legType === OptionType.CALL) {
      return this.call;
    }
    return this.put;
  };

  setOption = (option: T, legType: OptionType) => {
    if (legType === OptionType.CALL) {
      this.call = option; //as IAlternativeCall;
      return;
    }
    this.put = option; //as IAlternativePut;
  };
}
