import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { acceptTermsAndConditions, authenticate, isTermsAndConditionAccepted } from './services';
import {
  TSEnvironmentTypes,
  tsAuthenticationStatusState,
  tsTermsAndConditionAcceptState,
} from './states/trade-station-states';

export const TSLoginWidget: React.FC = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const tsLoginRoute = useMatch('/profile/tradestation/signin');
  const navigate = useNavigate();
  const [tsTermsAndConditionAccepted, setTSTermsAndConditionAccepted] = useRecoilState(tsTermsAndConditionAcceptState);
  const setTSAuthenticationStatus = useSetRecoilState(tsAuthenticationStatusState);
  const setNotifications = useSetRecoilState(notificationsState);

  useEffect(() => {
    if (!tsLoginRoute) {
      return;
    }
    const code = searchParams.get('code');
    if (!code || code.toString().trim() === '') {
      navigate('/', { replace: true });
      return;
    }
    (async () => await processToken(code))();
  }, []);

  const processToken = async (code: string) => {
    const env = window.localStorage.getItem('tsEnv');
    const redirectUrl = window.localStorage.getItem('tsRedirectURL');
    const response = await authenticate(env as TSEnvironmentTypes, code);
    if (response.hasErrors) {
      navigate('/');
      setNotifications([
        {
          type: 'error',
          content: 'Authentication failed.',
          isTradeStation: true,
        },
      ]);
      navigate(redirectUrl ? redirectUrl : '/', { replace: true });
      return;
    }
    setTSAuthenticationStatus(true);
    setNotifications([
      {
        type: 'success',
        content: 'TradeStation Authentication Successfull.',
        isTradeStation: true,
      },
    ]);
    navigate(redirectUrl ? redirectUrl : '/', { replace: true });
    /* 
      To accept terms and conditions:
        1. successful login
        2. check if terms already accepted - API call
        3. if not already accepted, then accept
    */
    const isAccepted = await isAcceptTermsAndConditions();
    if (!isAccepted) {
      await acceptTermsAndCondition(env);
    }
  };

  const isAcceptTermsAndConditions = async () => {
    const response = await isTermsAndConditionAccepted();
    if (response.hasErrors) {
      return;
    }
    return response.data as boolean;
  };

  const acceptTermsAndCondition = async (env: string) => {
    const response = await acceptTermsAndConditions(env as TSEnvironmentTypes);
    if (!response) {
      return;
    }
    setTSTermsAndConditionAccepted(response.data);
  };

  return <></>;
};
