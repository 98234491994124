import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Grid, Link, Typography, useTheme } from '@mui/material';
import { IAddSubscriptionRequest, ISubscriptionPlanPrice, cloneDeep } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { addModernSubscriptionV2 } from '@op/shared/src/services';
import {
  addSubscriptionErrorState,
  paymentDetailsErrorState,
  selectedPlansState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentPaymentState,
  subscriptionCurrentState,
  subscriptionPlanPriceState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { FormEvent, useContext, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { useRefreshToken } from '../states/use-fetch-and-set-refresh-token';
import { OPBoldTypograpghy, OptionsPlayDivider } from '../styled';
import { SubscriptionCardGridView } from './components';
import { CreditCardTypeWidget } from './credit-card-type-widget';
import { calculateNextPaymentDate } from './subscription-helper';
import { SummarySkeleton } from './subscription-skeleton-widget';

export const SubscriptionSummary: React.FC = () => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const userCachedSubscription = useRecoilValue(userSubscriptionFeaturesState);
  const selectedPlan = useRecoilValue(selectedPlansState);
  const [subscriptionPlanPrices, setSubscriptionPlanPrices] = useRecoilState(subscriptionPlanPriceState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useRecoilState(subscriptionCurrentPaymentState);
  const setErrors = useSetRecoilState(paymentDetailsErrorState);
  const setNotifications = useSetRecoilState(notificationsState);
  const fetchAndSetRefreshToken = useRefreshToken();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const currentSubscription = useRecoilValue(subscriptionCurrentState);
  const setAddSubscriptionError = useSetRecoilState(addSubscriptionErrorState);

  if (!userCachedSubscription) return null;

  if (!selectedPlan || !subscriptionPlanPrices) {
    return null;
  }

  if (isLoading) {
    return <SummarySkeleton />;
  }

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const handleChange = (planType: string, featureKey: string, featureType: string) => {
    const updatedPlans = cloneDeep(subscriptionPlanPrices).map((plan) =>
      plan.featureType === featureType && plan.featureKey === featureKey
        ? { ...plan, isSelected: plan.planType === planType }
        : plan,
    );
    setSubscriptionPlanPrices(updatedPlans);
  };

  const makePayment = async (event: FormEvent<Element>) => {
    const features = [...selectedPlan.addOns, ...selectedPlan.bundles].map((feature) => ({
      key: feature.featureKey,
      type: feature.featureType,
      plan: feature.planType,
    }));
    const makePaymentData: IAddSubscriptionRequest = {
      billingAddress: {
        address: paymentDetails.address,
        city: paymentDetails.city,
        state: paymentDetails.state,
        postalCode: paymentDetails.postalCode,
        country: paymentDetails.country,
        phone: paymentDetails.phone,
      },
      creditCard: {
        cardHolder: paymentDetails.cardName,
        cardNumber: paymentDetails.cardNumber,
        cardSecurityCode: paymentDetails.cardCvv,
        expirationMonth: paymentDetails.cardMonth,
        expirationYear: Number(paymentDetails.cardYear),
      },
      features: features,
      pricingModelCode: paymentDetails.pricingModelCode,
    };
    setIsLoading(true);
    // payment-v1
    // const response = await addModernSubscription(makePaymentData);
    // if (response.hasErrors) {
    //   setNotifications([{ type: 'error', content: `${response.errors[0]}` }]);
    //   setIsLoading(false);
    //   return;
    // }
    // setActiveStep('congrats');
    // setIsLoading(false);
    // const isSuccess = await fetchAndSetRefreshToken();
    // if (!isSuccess) {
    //   return console.log('Payment successful but error fetching accound details');
    // }

    // payment-v2
    const response = await addModernSubscriptionV2(makePaymentData);
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: `${response.errors[0].message}` }]);
      setIsLoading(false);
      return;
    }
    const { hasSubscriptionError, userSubscriptionResponses } = response.data;
    if (hasSubscriptionError) {
      const errorResponses = userSubscriptionResponses.filter((u) => !u.isSuccess);
      errorResponses.map((e) => {
        const errorMessage = `${e.featureDetail.key} is failed due to ${e.errorCode}.`;
        setNotifications([{ type: 'error', content: errorMessage }]);
      });
      setIsLoading(false);
      setAddSubscriptionError(true);
      setActiveStep('congrats');
      return;
    }
    setActiveStep('congrats');
    setIsLoading(false);
    const isSuccess = await fetchAndSetRefreshToken();
    if (!isSuccess) {
      return console.log('Payment successful but error fetching accound details');
    }
    event.preventDefault();
    return;
  };

  // NOTE: Don't remove commented code
  const renderSelect = (subscriptionPlan: ISubscriptionPlanPrice) => {
    const subscriptionPlanList = subscriptionPlanPrices.filter((t) => t.featureKey === subscriptionPlan.featureKey);
    const isActivePlan = subscriptionPlanList.some((plan) => plan.isActivePlan);
    // if (isActivePlan || subscriptionPlanList.length <= 1) {
    //   return (
    //     <Grid item xs={10}>
    //       <OPBoldTypograpghy sx={{ textAlign: 'center', color: theme.palette.timeFrame.contrastText }}>
    //         {subscriptionPlan.planType}
    //       </OPBoldTypograpghy>
    //     </Grid>
    //   );
    // }
    return (
      <Grid item xs={10}>
        <OPBoldTypograpghy sx={{ textAlign: 'center', color: theme.palette.timeFrame.contrastText }}>
          {subscriptionPlan.planType}
        </OPBoldTypograpghy>
      </Grid>
    );

    // return (
    //   <Select
    //     variant="standard"
    //     renderValue={() => subscriptionPlan.planType}
    //     value={subscriptionPlan.planType}
    //     fullWidth
    //     disableUnderline
    //     sx={{
    //       fontWeight: '500',
    //       textAlign: 'center',
    //       alignItems: 'center',
    //       padding: 0,
    //       color: theme.palette.timeFrame.contrastText,
    //       '& .MuiSelect-select': {
    //         padding: 0,
    //       },
    //       '& .MuiSelect-icon': {
    //         color: theme.palette.timeFrame.contrastText,
    //       },
    //     }}
    //     onChange={(event) =>
    //       handleChange(event.target.value, subscriptionPlan.featureKey, subscriptionPlan.featureType)
    //     }
    //     >
    //     {subscriptionPlanList.map((plan, index) => (
    //       <MenuItem value={plan.planType} key={index}>
    //         {plan.planType}
    //       </MenuItem>
    //     ))}
    //   </Select>
    // );
  };

  const renderBundles = () => {
    return (
      <Grid item container alignItems={'center'} xs={12} my={0.5}>
        <OPBoldTypograpghy
          sx={{
            color: theme.palette.text.disabled,
            px: 0.5,
          }}>
          {t('subscriptions.subscription')}
        </OPBoldTypograpghy>
        <Grid container item xs={12}>
          {selectedPlan.bundles.map((bundle) => (
            <Grid
              item
              container
              xs={12}
              p={1.5}
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: 1,
              }}>
              <Grid item xs={4}>
                <OPBoldTypograpghy
                  sx={{ color: theme.palette.timeFrame.contrastText }}
                  variant="subtitle2">{`${bundle.featureKey}`}</OPBoldTypograpghy>
              </Grid>
              <Grid item xs={4}>
                {renderSelect(bundle)}
              </Grid>
              <Grid item xs={4} textAlign={'right'}>
                <OPBoldTypograpghy variant="subtitle2" sx={{ color: theme.palette.timeFrame.contrastText }}>
                  {NumberFormatHelper.toCurrency(bundle.discountedPrice)}
                </OPBoldTypograpghy>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <OptionsPlayDivider style={{ marginTop: '8px' }} />
      </Grid>
    );
  };

  const renderAddOns = () => {
    return (
      <Grid item container alignItems={'center'} xs={12} my={0.5}>
        <OPBoldTypograpghy
          sx={{
            color: theme.palette.text.disabled,
            px: 0.5,
          }}>
          {t('subscriptions.addOns')}
        </OPBoldTypograpghy>
        <Grid container item xs={12}>
          {selectedPlan.addOns.map((addOn) => (
            <Grid
              item
              container
              xs={12}
              p={1.5}
              my={0.5}
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderRadius: 1,
              }}>
              <Grid item xs={4}>
                <OPBoldTypograpghy variant="subtitle2" sx={{ color: theme.palette.timeFrame.contrastText }}>
                  {addOn.featureKey}
                </OPBoldTypograpghy>
              </Grid>

              <Grid item xs={4}>
                {renderSelect(addOn)}
              </Grid>
              <Grid item xs={4} textAlign={'right'} sx={{ color: theme.palette.timeFrame.contrastText }}>
                <OPBoldTypograpghy variant="subtitle2">
                  {NumberFormatHelper.toCurrency(addOn.discountedPrice)}
                </OPBoldTypograpghy>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const renderSummaryHeader = () => {
    return (
      <Grid item container xs={12} my={1} px={0.5}>
        <Grid item xs={4}>
          <OPBoldTypograpghy>{t('subscriptions.plans')}</OPBoldTypograpghy>
        </Grid>
        <Grid item xs={4} textAlign={'center'}>
          <OPBoldTypograpghy>{t('subscriptions.billingCycle')}</OPBoldTypograpghy>
        </Grid>
        <Grid item xs={4} textAlign={'right'}>
          <OPBoldTypograpghy>{t('subscriptions.amount')}</OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  const renderSummaryCard = () => {
    return (
      <Grid container>
        {selectedPlan.bundles.length > 0 && (
          <Grid item xs={12}>
            {renderBundles()}
          </Grid>
        )}
        {selectedPlan.addOns.length > 0 && (
          <Grid item xs={12}>
            {renderAddOns()}
          </Grid>
        )}
      </Grid>
    );
  };

  const renderPaymentDetails = () => {
    const totalBundlesAndAddOns = selectedPlan.bundles.length + selectedPlan.addOns.length;
    const totalPrice = [...selectedPlan.bundles, ...selectedPlan.addOns].reduce(
      (total, item) => total + item.discountedPrice,
      0,
    );
    const paymentDate = calculateNextPaymentDate(selectedPlan, currentSubscription);
    const isFutureDate = DateTimeHelper.isFutureDate(paymentDate);
    return (
      <Grid container rowSpacing={1}>
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy
              variant="subHeader"
              style={{
                color: theme.palette.greyShades.contrastText,
              }}>
              {t('subscriptions.paymentDate')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy
              variant="subHeader"
              style={{
                color: isFutureDate ? theme.palette.error.main : theme.palette.greyShades.contrastText,
              }}>
              {DateTimeHelper.format(paymentDate)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
        <SubscriptionCardGridView
          label={t('subscriptions.cardPay')}
          value={<CreditCardTypeWidget cardNumber={paymentDetails.cardNumber} textView="typeWithNumber" />}
        />
        <SubscriptionCardGridView label={t('subscriptions.totalQuantity')} value={totalBundlesAndAddOns} />
        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center" my={1}>
          <Grid item xs={6}>
            <OPBoldTypograpghy variant="subHeader">{t('subscriptions.originalPrices')}</OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy variant="subHeader">{NumberFormatHelper.toCurrency(totalPrice)}</OPBoldTypograpghy>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderButtons = () => (
    <Grid container item columnSpacing={2} xs={12} my={1}>
      <Grid item xs={3}>
        <Button
          onClick={() => {
            logActivity('BUTTON', 'paymentsAddSummaryBackButton');
            setActiveStep('cardInfo');
            setPaymentDetails((prev) => ({ ...prev, cardCvv: '' }));
            setErrors((prev) => ({ ...prev, cardCvv: '' }));
          }}
          sx={{ color: theme.palette.primary.light }}
          size="large"
          fullWidth
          variant="outlined">
          <ArrowBackIosIcon fontSize="medium" sx={{ color: theme.palette.primary.light }} />
          <Typography variant="button" sx={{ color: theme.palette.text.primary }}>
            {t('common.buttons.back')}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={9}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={{
            backgroundColor: theme.palette.success.main,
            ':hover': { backgroundColor: theme.palette.success.main },
          }}
          onClick={(event) => {
            logActivity('BUTTON', 'paymentsAddSummaryPayButton');
            makePayment(event);
          }}>
          <OPBoldTypograpghy variant="button">{t('subscriptions.subscribe')}</OPBoldTypograpghy>

          {/* <OPBoldTypograpghy variant="button"></OPBoldTypograpghy> */}
        </Button>
      </Grid>
    </Grid>
  );

  const renderCardWarning = () => {
    const paymentDate = calculateNextPaymentDate(selectedPlan, currentSubscription);
    if (!DateTimeHelper.isFutureDate(paymentDate)) {
      return;
    }
    return <Typography sx={{ color: theme.palette.error.main }}>{t('subscriptions.creditCardWarning')}</Typography>;
  };

  return (
    <Grid container justifyContent="center" rowSpacing={1} alignItems="center" mt={1}>
      <Grid item xs={12} textAlign="center" mt={1}>
        <OPBoldTypograpghy sx={{ color: theme.palette.primary.main }} variant="subtitle1">
          {t('subscriptions.subscriptionBillSummary')}
        </OPBoldTypograpghy>
      </Grid>
      <Grid container xs={6}>
        <Grid item xs={12}>
          {renderSummaryHeader()}
        </Grid>
        <Grid item xs={12}>
          {renderSummaryCard()}
        </Grid>
        <Grid item xs={12}>
          {renderPaymentDetails()}
        </Grid>
        <Grid item xs={12} px={0.5}>
          <Typography variant="body1">
            {t('subscriptions.acknowledgeClickButton')}
            <b>"{t('subscriptions.subscribe')}"</b>
            {t('subscriptions.acknowledgeAccept')}
            <Link color="primary" href={'https://www.optionsplay.com/subscriptions'} target="_blank" rel="noreferrer">
              {t('subscriptions.acknowledgeAgreement')}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {renderButtons()}
        </Grid>
        <Grid item xs={12} my={1}>
          {renderCardWarning()}
        </Grid>
      </Grid>
    </Grid>
  );
};
