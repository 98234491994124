import { Grid } from '@mui/material';
import React from 'react';
import { IncomeSettingsWidget } from '../income';
import { RiskInvestmentSettingsWidget } from './risk-investment-settings-widget';

interface IUserSettingsWidgetProps {
  showIncomeSettings: boolean;
}

export const UserSettingsWidget: React.FC<IUserSettingsWidgetProps> = ({
  showIncomeSettings,
}: IUserSettingsWidgetProps) => {
  return (
    <Grid container rowSpacing={2}>
      {showIncomeSettings ? (
        <Grid item xs={12}>
          <IncomeSettingsWidget isMinimalistic={false} />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <RiskInvestmentSettingsWidget />
      </Grid>
    </Grid>
  );
};
