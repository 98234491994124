import { ITradingStrategy, StrategyHelpers } from '@op/shared/src/models/how';
import { SentimentType } from '..';
import BasicCombination from './basic-combination';
import Combination from './combination';
import DateTimeHelper from './date-time-helper';
import formatting from './formatting';
import HowDataModel from './how-data-model';

class TradingStrategies {
  symbol: string | undefined;
  allCombinations: Combination[] = [];
  sentiment: SentimentType | undefined;
  hasOption: boolean = false;

  private constructor() {}

  static fromData(sentiment: SentimentType, howDataModel: HowDataModel) {
    const clone = new TradingStrategies();
    clone.symbol = howDataModel.symbol;
    clone.sentiment = sentiment;
    clone.hasOption = howDataModel.hasOption;
    clone.initialize(howDataModel);
    return clone;
  }

  static fromSelf = (self: TradingStrategies) => {
    const clone = new TradingStrategies();
    clone.allCombinations = self.allCombinations.map((c) => Combination.fromSelf(c));
    clone.sentiment = self.sentiment;
    clone.hasOption = self.hasOption;
    return clone;
  };

  private initialize = (howDataModel: HowDataModel) => {
    this.updateTradingStrategies(howDataModel);
  };

  private buildTradingCombination(
    howDataModel: HowDataModel,
    tradingStrategyOrStrategyName: ITradingStrategy | string,
    defaultDaysToFindExpiry: number,
  ) {
    if (!howDataModel) {
      throw new Error('HowDataModel is undefined');
    }

    let comb = StrategyHelpers.assembleCombination(
      tradingStrategyOrStrategyName,
      { howDataModel: howDataModel },
      'Combination',
      {
        defaultDaysToFindExpiry: defaultDaysToFindExpiry,
      },
    );
    return comb;
  }

  private updateTradingStrategies = (howDataModel: HowDataModel) => {
    if (!howDataModel) {
      throw new Error('HowDataModel is undefined');
    }

    if (!this.sentiment) {
      throw new Error('sentiment is undefined');
    }

    let strategiesForSentiment = howDataModel.tradingStrategiesRaw.get(this.sentiment);
    if (!strategiesForSentiment) {
      throw new Error('Strategies are not available');
    }

    const combs: Combination[] = [];
    let days = 30;
    for (let i = 0; i < 3; i++) {
      if (strategiesForSentiment[i] && strategiesForSentiment[i].legs && strategiesForSentiment[i].legs[0].expiry) {
        days = DateTimeHelper.daysFromNow(strategiesForSentiment[i].legs[0].expiry);
      }
    }
    for (let i = 0; i < 3; i++) {
      let tradingStrategy = strategiesForSentiment[i];
      let comb = this.buildTradingCombination(howDataModel, tradingStrategy, days);
      combs.push(comb as Combination);
    }

    this.allCombinations = combs;
  };

  farthestExpiry = () => {
    return TradingStrategies.farthestExpiration(this.allCombinations);
  };

  static farthestExpiration = (combinations: (BasicCombination | undefined)[]) => {
    if (combinations.length === 0) {
      throw new Error('combinations are not available');
    }
    const stockCombination = combinations[0];
    let farthestDate: Date | undefined;
    for (let i = 0; i < combinations.length; i++) {
      if (combinations[i]?.hasOnlyStx()) {
        continue;
      }
      const expiry = combinations[i]?.expiry();
      if (!expiry) {
        continue;
      }
      if (!farthestDate || farthestDate < expiry) {
        farthestDate = expiry;
      }
    }
    return farthestDate || stockCombination?.getExpiryOrDefault() || formatting.getCurrentDate();
  };
}

export default TradingStrategies;
