import { PortfolioAlertSubscription } from './portfolio-alert-subscription';
import { PortfolioSubscription } from './portfolio-subscription';
import { PowerhouseProHubConnection } from './powerhouse-pro-hub-connection';
import { QuoteSubscription } from './quote-subscription';
import { SentimentSubscription } from './sentiment-subscription';

export class PowerhouseProHub {
  private _socketConnection: PowerhouseProHubConnection | undefined;
  private _quotesSubscription: QuoteSubscription | undefined;
  private _sentimentsSubscription: SentimentSubscription | undefined;
  private _portfolioSubscription: PortfolioSubscription | undefined;
  private _portfolioAlertSubscription: PortfolioAlertSubscription | undefined;

  private constructor() {}

  static fromSocketConnection = (shareId?: string | undefined, token?: string | undefined) => {
    const model = new PowerhouseProHub();
    model._socketConnection = PowerhouseProHubConnection.fromHubName(token);
    //model._socketConnection.connect(model.initialize);
    model.initialize(shareId, token);
    return model;
  };

  static fromToken = (token: string) => {
    const model = new PowerhouseProHub();
    model._socketConnection = PowerhouseProHubConnection.fromHubName(token);
    model.initialize(undefined, token);
    return model;
  };

  get socketConnection() {
    if (!this._socketConnection) {
      throw new Error('socketConnection is undefined');
    }
    return this._socketConnection;
  }

  get quotesSubscription() {
    if (!this._quotesSubscription) {
      throw new Error('quotesSubscription is undefined');
    }
    return this._quotesSubscription;
  }

  get sentimentsSubscription() {
    if (!this._sentimentsSubscription) {
      throw new Error('SentimentSubscription is undefined');
    }
    return this._sentimentsSubscription;
  }

  get portfolioSubscription() {
    if (!this._portfolioSubscription) {
      throw new Error('portfolioSubscription is undefined');
    }
    return this._portfolioSubscription;
  }

  get portfolioAlertSubscription() {
    if (!this._portfolioAlertSubscription) {
      throw new Error('portfolioAlertSubscription is undefined');
    }
    return this._portfolioAlertSubscription;
  }

  private initialize = (shareId?: string | undefined, token?: string | undefined) => {
    this._quotesSubscription = QuoteSubscription.fromSocketConnection(this.socketConnection, shareId, token);
    this._sentimentsSubscription = SentimentSubscription.fromSocketConnection(this.socketConnection, shareId, token);
    this._portfolioSubscription = PortfolioSubscription.fromSocketConnection(this.socketConnection);
    this._portfolioAlertSubscription = PortfolioAlertSubscription.fromSocketConnection(this.socketConnection);
  };

  connect = async () => {
    return await this.socketConnection.connect();
  };

  getMarketWorkTimeDetails = async () => {
    var clientDate = new Date();
    var marketWorkTimeDetails = await this.socketConnection.invoke('getMarketWorkTimeDetailsForToday', clientDate);
    if (!marketWorkTimeDetails) {
      return undefined;
    }
    // server and client clock synchronization
    var clientTimeStamp = new Date().getTime();
    marketWorkTimeDetails.responseRecievedTimeStamp = clientTimeStamp;
    var serverClientRequestDiffTime = marketWorkTimeDetails.differenceBetweenServerAndClientTime;
    var serverTimeStamp = new Date(marketWorkTimeDetails.utcNowTime).getTime();
    var serverClientResponseDiffTime = serverTimeStamp - clientTimeStamp;
    var responseTime =
      (serverClientRequestDiffTime - clientTimeStamp + clientDate.getTime() - serverClientResponseDiffTime) / 2;
    var syncedServerTime = new Date(clientTimeStamp + (serverClientResponseDiffTime - responseTime));

    marketWorkTimeDetails.utcNowTime = syncedServerTime;
    if (marketWorkTimeDetails.utcOpenTime != null && marketWorkTimeDetails.utcCloseTime != null) {
      marketWorkTimeDetails.utcOpenTime = new Date(marketWorkTimeDetails.utcOpenTime);
      marketWorkTimeDetails.utcCloseTime = new Date(marketWorkTimeDetails.utcCloseTime);
    }

    // should be called then precise UTC time is needed.
    marketWorkTimeDetails.adjustUtcTime = () => {
      var adjustedTime =
        syncedServerTime.getTime() + (new Date().getTime() - marketWorkTimeDetails.responseRecievedTimeStamp);
      //TODO: clone and update if required.
      //marketWorkTimeDetails.utcNowTime = new Date(adjustedTime);
      return adjustedTime;
    };

    return marketWorkTimeDetails;
  };

  /**
   * Gets quotes from server using socket connection without being notified on updates (without subscription)
   */
  getQuotes = async (symbols: string[]) => await this.quotesSubscription.get(symbols);

  getMarketTone = async () => await this.socketConnection.invoke('getMarketTone');

  /**
   * Gets sentiments from server using socket connection without being notified on updates (without subscription)
   */
  getSentiments = async (itemKeys: string[]) => await this.sentimentsSubscription.get(itemKeys);
}
