import { CIQ } from 'chartiq/js/advanced';

export const opStudiesStoragKey = 'op_studies';
var height: number | undefined = 100;

/* This adds the required studies to localstorage. Can be configured using `NameValueStore` of ChartIQ
 * `defineStudyDefinitions()` set the study description object for chartIQ
 * `addStudies` reads the storage data and add it to the chart instance.
 * This function must be called before `addStudies`
 * Sets the listener of layout chagnes.
 */
//@ts-ignore
export const initOpStudies = (chartEngine: CIQ.ChartEngine, panelHeight?: number) => {
  height = panelHeight;
  defineStudyDefinitions();
  chartEngine.addEventListener('layout', function (data: any) {
    const studies = data.layout.studies;
    const entries: any = {};
    for (const key in studies) {
      const study = studies[key];
      entries[study.name] = { name: study.name, type: study.type };
    }
    window.localStorage.setItem(opStudiesStoragKey, JSON.stringify(entries));
  });
  addStudies(chartEngine);
};
//@ts-ignore
const addStudies = (chartEngine: CIQ.ChartEngine) => {
  const mandatoryStudies = {
    '1m/6m Trend': { name: '1m/6m Trend', type: '1m/6m Trend' },
    cci: { name: 'cci', type: 'CCI' },
    vol: { name: 'vol', type: 'vol undr' },
  };
  const data = window.localStorage.getItem(opStudiesStoragKey);
  if (data !== null && data.trim() !== '') {
    const studies = JSON.parse(data);
    for (const key in studies) {
      //@ts-ignore
      mandatoryStudies[key] = studies[key];
    }
  }
  for (const key in mandatoryStudies) {
    // @ts-ignore
    const study = mandatoryStudies[key];
    CIQ.Studies.addStudy(chartEngine, study.type, { id: study.name });
  }
};

const defineStudyDefinitions = () => {
  trendStudyDefinition();
  williamRStudyDefinition();
  cciStudyDefinition();
  volUndrStudyDefinition();
};

//sd and quotes should be available in masterData to do the calcuation.
const createSyrahSentiments = (stx: any, sd: any, quotes: any) => {
  /* NOTE: stx panels have two names cause when we add the study chartiq liabrary will add the study with the print escape character name */
  const panel = stx.panels['1m/6m Trend'] || stx.panels['‌1m/6m Trend‌'];
  if (!panel) {
    return;
  }
  panel.subholder.style.display = 'none';
  panel.yAxis.noDraw = true;
  const myWidth = stx.layout.candleWidth - 2;
  panel.height = panel.bottom - panel.top;
  const factor = panel.height >= 74 ? 3.5 : 2;
  const y = panel.top + panel.height / factor;

  const panelBearish = sd.outputs['Bearish'];
  const panelBullish = sd.outputs['Bullish'];
  const panelNeutral = sd.outputs['Neutral'];
  const shBearish = sd.outputs['shBearish'];
  const midBearish = sd.outputs['midBearish'];
  const midBullish = sd.outputs['midBullish'];
  const shBullish = sd.outputs['shBullish'];

  const colors = [
    panelBearish,
    panelBearish,
    shBearish,
    panelNeutral,
    midBearish,
    midBullish,
    panelNeutral,
    shBullish,
    panelBullish,
    panelBearish,
  ];

  const drawSentimentBox = (x0: number, y0: number, x1: number, y1: number, fillStyle: string) => {
    stx.chart.context.fillStyle = fillStyle;
    stx.chart.context.fillRect(x0, y0, x1 - x0, y1 - y0);
  };

  const drawSentimentValue = (value: number, yMainOffset: number, yBorderOffset: number, x0: number, x1: number) => {
    const yForThisValue = value == null ? y : y + yMainOffset;
    const color = colors[value + 4];
    drawSentimentBox(x0, y + yBorderOffset, x1, yForThisValue + yBorderOffset, color);
  };

  for (let i = 0; i < quotes.length; i++) {
    if (quotes[i] == null) {
      continue;
    }
    const x0 = i * stx.layout.candleWidth + 1;
    const x1 = x0 + myWidth;
    if (quotes[i]?.syrahSentimentShortTerm?.value || quotes[i]?.syrahSentimentShortTerm?.value === 0) {
      drawSentimentValue(quotes[i]?.syrahSentimentShortTerm?.value, -panel.height / 5, 0, x0, x1);
    }
    if (quotes[i]?.syrahSentimentLongTerm?.value || quotes[i]?.syrahSentimentLongTerm?.value === 0) {
      drawSentimentValue(quotes[i].syrahSentimentLongTerm.value, panel.height / 5, 2, x0, x1);
    }
  }

  const font = stx.chart.context.font.split(' ')[1];
  stx.chart.context.font = '11px ' + font;
  stx.chart.context.lineWidth = 0.5;
  stx.chart.context.strokeText('1m', stx.canvasWidth - 45, y - 10);
  stx.chart.context.strokeText('6m', stx.canvasWidth - 45, y + 10);
  stx.chart.context.globalAlpha = 1;
};

const trendStudyDefinition = () => {
  //@ts-ignore
  CIQ.Studies.studyLibrary['1m/6m Trend'] = {
    name: '1m/6m Trend',
    seriesFN: createSyrahSentiments,
    inputs: {},
    /* TODO ------ Naming */
    outputs: {
      Bullish: 'rgba(0, 169, 79, 1)',
      Bearish: 'rgba(198, 6, 81, 1)',
      Neutral: 'rgba(255, 219, 141, 1)',
      shBearish: 'rgba(198, 6, 81, 0.8)',
      midBearish: 'rgba(198, 6, 81, 0.5)',
      shBullish: 'rgba(0, 169, 79, 0.8)',
      midBullish: 'rgba(0, 169, 79, 0.5)',
    },
  };
};

const williamRStudyDefinition = () => {
  // @ts-ignore
  CIQ.extend(CIQ.Studies.studyLibrary['Williams %R'], {
    parameters: { init: { studyOverBoughtColor: '#C60651', studyOverSoldColor: '#00A94F' } },
  });
};

const cciStudyDefinition = () => {
  // @ts-ignore
  CIQ.extend(CIQ.Studies.studyLibrary['CCI'], {
    panelHeight: height,
    parameters: {
      init: {
        studyOverBoughtColor: '#C60651',
        studyOverSoldColor: '#00A94F',
      },
    },
  });
};

const volUndrStudyDefinition = () => {
  // @ts-ignore
  CIQ.extend(CIQ.Studies.studyLibrary['vol undr'], {
    parameters: {
      init: { 'Up Volume': '#ECECEC', 'Down Volume': '#ECECEC' },
    },
    outputs: { 'Up Volume': '#ECECEC', 'Down Volume': '#ECECEC', 'Up Border': '#ECECEC', 'Down Border': '#ECECEC' },
  });
};
