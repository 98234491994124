import { getConfiguration } from '@op/shared/src/services';
import { configurationState } from '@op/shared/src/states';
import { useSetRecoilState } from 'recoil';
import { StrategiesProvider } from '../../models/how/strategies-provider';

export const useFetchAndSetConfiguration = () => {
  const setConfiguration = useSetRecoilState(configurationState);

  const fetchAndSetConfiguration = async () => {
    const { data: configuration } = await getConfiguration();
    if (!configuration) {
      return;
    }
    if (configuration.strategiesConfiguration) {
      StrategiesProvider.initialize(configuration.strategiesConfiguration.allowedStrategies);
    }
    setConfiguration(configuration);
    return configuration;
  };

  return fetchAndSetConfiguration;
};
