import { PortfolioAlert } from '@op/shared/src/hubs/portfolio-alert';
import { PorfolioAction, RollPositionWhen } from '../enums/enums';
import { Leg } from '../how';
import { IPortfolioAlertResource } from '../configuration';

export class Recommendation {
  legs: Leg[] | undefined;
  adjustmentText = '';
  adjustmentId = '';
  alert: { adjustmentText: string; title: string; adjustmentType: PorfolioAction } | undefined;
  alertTitle = '';
  isAlert = false;
  isRoll = false;
  isClosed = false;
  key = '';
  plainEnglish = '';

  private constructor() {}

  static fromAdjustment = (adjustmentId: PorfolioAction, legs: Leg[] | undefined) => {
    const recommendation = new Recommendation();
    if (legs) {
      recommendation.legs = legs.map((l) => Leg.fromSelf(l)) || [];
    }
    recommendation.adjustmentText = adjustmentId;
    recommendation.adjustmentId = adjustmentId as string;
    recommendation.isAlert = false;
    recommendation.isRoll = adjustmentId === PorfolioAction.ROLL_POSITION;
    recommendation.isClosed = adjustmentId === PorfolioAction.CLOSE_POSITION;

    return recommendation;
  };

  static fromAlert = (
    alert: PortfolioAlert,
    alertResource: IPortfolioAlertResource, //TODO: This should be resource type
    legs: Leg[] | undefined | null,
  ) => {
    const recommendation = new Recommendation();
    if (legs) {
      recommendation.legs = legs.map((l) => Leg.fromSelf(l)) || [];
    }
    recommendation.adjustmentText = Recommendation.getAdjustmentText(alert, alertResource); //alertResource.adjustmentText as string;
    recommendation.key = alertResource.key;
    recommendation.isAlert = true;
    recommendation.alertTitle = Recommendation.getTitle(alert, alertResource);
    recommendation.plainEnglish = Recommendation.getPlainEnglish(alert, alertResource);
    recommendation.isRoll = false; //adjustmentIdOrAlert.adjustmentType === PorfolioAction.ROLL_POSITION;
    recommendation.isClosed = false; //adjustmentIdOrAlert.adjustmentType === PorfolioAction.CLOSE_POSITION;
    return recommendation;
  };

  static fromSelf = (self: Recommendation) => {
    const clone = new Recommendation();
    clone.legs = self.legs?.map((l) => Leg.fromSelf(l));
    clone.adjustmentText = self.adjustmentText;
    clone.adjustmentId = self.adjustmentId;
    clone.alert = self.alert;
    clone.alertTitle = self.alertTitle;
    clone.isAlert = self.isAlert;
    clone.isRoll = self.isRoll;
    clone.isClosed = self.isClosed;
    return clone;
  };

  private static getTitle = (alert: PortfolioAlert, resource: IPortfolioAlertResource) => {
    try {
      if (typeof resource.title === 'string' || resource.title instanceof String) {
        if (resource.title.indexOf('_') >= 0) {
          return resource.title.split('_')[1]; //for Arlington: Arlington_Optimal Covered Call Found;
        }
        return resource.title as string;
      }
      if (typeof resource.title === 'object') {
        const additionalAlertData = alert.additionalAlertData;
        if (resource.key === 'Nashville' || resource.key === 'ParkCity' || resource.key === 'Anchorage') {
          if (additionalAlertData.hasOptimalCall) {
            return resource.title.coveredCallOptimalFound as string;
          }
          if (additionalAlertData.hasAlternativeCall) {
            if (additionalAlertData.rollPositionWhen === RollPositionWhen.OPTIMAL_ONLY) {
              return resource.title.coveredCallAlternativeNotFound as string;
            } else {
              return resource.title.coveredCallOptimalNotFound as string;
            }
          }
          return resource.title.coveredCallAlternativeNotFound as string;
        }
      }
      return resource.title as string;
    } catch (error) {
      console.log(error);
    }
    return '';
  };

  private static getPlainEnglish = (alert: PortfolioAlert, resource: IPortfolioAlertResource) => {
    try {
      if (typeof resource.plainEnglish === 'object') {
        const additionalAlertData = alert.additionalAlertData;
        if (resource.key === 'Nashville' || resource.key === 'ParkCity' || resource.key === 'Anchorage') {
          if (additionalAlertData.hasOptimalCall) {
            return resource.plainEnglish.coveredCallOptimalFound as string;
          }
          if (additionalAlertData.hasAlternativeCall) {
            if (additionalAlertData.rollPositionWhen === RollPositionWhen.OPTIMAL_ONLY) {
              return resource.plainEnglish.coveredCallAlternativeNotFound as string;
            } else {
              return resource.plainEnglish.coveredCallOptimalNotFound as string;
            }
          }
          return resource.plainEnglish.coveredCallAlternativeNotFound as string;
        }
      }
      return resource.plainEnglish as string;
    } catch (error) {
      console.log(error);
    }
    return '';
  };

  private static getAdjustmentText = (alert: PortfolioAlert, resource: IPortfolioAlertResource) => {
    try {
      if (typeof resource.adjustmentText === 'object') {
        const additionalAlertData = alert.additionalAlertData;
        if (resource.key === 'Nashville' || resource.key === 'ParkCity' || resource.key === 'Anchorage') {
          if (additionalAlertData.hasOptimalCall) {
            return resource.adjustmentText.coveredCallOptimalFound as string;
          }
          if (additionalAlertData.hasAlternativeCall) {
            if (additionalAlertData.rollPositionWhen === RollPositionWhen.OPTIMAL_ONLY) {
              return resource.adjustmentText.coveredCallAlternativeNotFound as string;
            } else {
              return resource.adjustmentText.coveredCallOptimalNotFound as string;
            }
          }
          return resource.adjustmentText.coveredCallAlternativeNotFound as string;
        }
      }
      return resource.adjustmentText as string;
    } catch (error) {
      console.log(error);
    }
    return '';
  };
}
