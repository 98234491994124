import { Grid, Typography } from '@mui/material';
import { SubscriptionStatus } from '@op/shared/src/models/enums/enums';
import { accountState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { ProfileCardWidget } from '../common';
import { TableShared } from './table-shared-trades';

export const SharedTrade: React.FC = () => {
  const account = useRecoilValue(accountState);
  const { t } = React.useContext(LocalizationContext);

  const renderContent = () => {
    if (account && account.securityModel?.subscriptionStatus === SubscriptionStatus.EXPIRED_TRIAL) {
      return <Typography variant="body1">{t('profile.sharedTrades.labels.noSharedTradesFound')}</Typography>;
    }
    return (
      <Grid container>
        <TableShared />
      </Grid>
    );
  };

  return <ProfileCardWidget title={t('profile.sharedTrades.titles.sharedTrades')} content={renderContent()} />;
};
