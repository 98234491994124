import { IAccountAdvisor } from '.';

export class AccountAdvisor implements IAccountAdvisor {
  id = -1;
  faNumber = '';
  positionAccountAdvisorName = '';

  public static fromText = (data: AccountAdvisor): AccountAdvisor => {
    const value = new AccountAdvisor();
    value.id = data.id;
    value.faNumber = data.faNumber;
    value.positionAccountAdvisorName = data.positionAccountAdvisorName;
    return value;
  };
}
