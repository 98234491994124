import { PhpHubEvents } from '../models/enums/enums';
import { HubSubscription } from './hub-subscription';
import { PowerhouseProHubConnection } from './powerhouse-pro-hub-connection';

export class PortfolioAlertSubscription extends HubSubscription<any> {
  private constructor() {
    super();
  }

  static fromSocketConnection = (socketConnection: PowerhouseProHubConnection) => {
    const subscription = new PortfolioAlertSubscription();
    subscription.fromSocketConnection(
      socketConnection,
      'subscribeToPortfolioAlerts',
      'unsubscribeFromPortfolioAlerts',
      undefined,
      PhpHubEvents.PORTFOLIO_ALERTS,
    );
    return subscription;
  };

  mapItemsFromServer = (items: any[]) => {
    if (!items) {
      return [];
    }
    const alerts = items.map((i) => i);
    return alerts;
  };
}
