import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Accordion, Button, Card, Grid, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TDAmeritradeIcon from '@op/shared/assets/images/TDAmeritrade.png';
import ErrorIcon from '@op/shared/assets/images/errorIcon.svg';
import { IPortfolioAccount } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { portfolioSelectedFilterSummaryState, configurationState } from '@op/shared/src/states';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { OPBoldTypograpghy, OPLightTypograpghy } from '../styled';

export const PortfolioAccountSummaryWidget: React.FC = () => {
  const portfolioAccountSummary = useRecoilValue(portfolioSelectedFilterSummaryState);
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const configuration = useRecoilValue(configurationState);

  const ameritradeAuthenticate = async () => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // protocol and domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // OR ip (v4) address,  port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
      'i',
    ); // query string
    const redirectUrl = `${window.location.origin}/profile`;
    const tdAuthURL = `${configuration?.ameritradeAuthUrl}?client_id=${
      configuration?.ameritradeConsumerKey
    }%40AMER.OAUTHAP&response_type=code&redirect_uri=${encodeURIComponent(redirectUrl)}`;
    if (!tdAuthURL || !pattern.test(tdAuthURL)) return;
    //expired or doesnot exist
    window.open(tdAuthURL);
  };

  const renderBrokerAccountsFailureMessage = () => {
    if (!portfolioAccountSummary.brokerAccounts?.some((a) => a.syncStatus.trim() != 'Processed')) {
      return null;
    }
    return (
      <Grid item xs={12}>
        <Grid item xs={12}>
          <OPLightTypograpghy>
            <span style={{ color: theme.palette.error.main, fontWeight: 'bold', marginLeft: 4 }}>
              {'Please try to re-authenticate with TD. If that fails contact support.'}
            </span>
          </OPLightTypograpghy>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            id="syncAmeritrade"
            onClick={() => ameritradeAuthenticate()}>
            <Typography variant="button">Reauthenticate Now</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderBrokerAccountsSyncStatus = (acc: IPortfolioAccount) => {
    return (
      <Grid item xs={12}>
        <OPLightTypograpghy>
          <span>
            <img
              alt="logo"
              src={TDAmeritradeIcon}
              style={{ width: 19, height: 19, position: 'relative', top: 5 }}></img>
          </span>

          {acc.syncStatus.trim() != 'Processed' && (
            <span>
              {' '}
              <img
                id="portfolio-error"
                alt="Portfolio Error"
                src={ErrorIcon}
                style={{ width: 19, height: 19, position: 'relative', top: 5 }}></img>
            </span>
          )}
          <span
            style={
              acc.syncStatus.trim() != 'Processed'
                ? { color: theme.palette.error.main, fontWeight: 'bold', marginLeft: 4 }
                : { fontWeight: 'bold', marginLeft: 5 }
            }>
            {acc.accountName}
          </span>
          <span style={{ fontWeight: 'normal' }}>{' last synced at '}</span>
          <span style={{ fontWeight: 'bold' }}> {DateTimeHelper.format(acc.syncTime, 'hh:mm a')}</span>
          <span style={{ fontWeight: 'normal' }}>{' on '}</span>
          <span
            style={
              acc.syncStatus.trim() != 'Processed'
                ? { color: theme.palette.error.main, fontWeight: 'bold' }
                : { fontWeight: 'bold' }
            }>
            {' '}
            {DateTimeHelper.format(acc.syncTime, 'dd MMM yyyy')}
          </span>
        </OPLightTypograpghy>
      </Grid>
    );
  };

  const renderBrokerAccountsData = () => {
    if (portfolioAccountSummary.brokerAccounts && portfolioAccountSummary.brokerAccounts.length > 0) {
      return (
        <Grid
          container
          columnSpacing={1}
          p={2}
          style={{ borderRadius: theme.shape.borderRadius, backgroundColor: theme.palette.info.dark }}>
          {portfolioAccountSummary.brokerAccounts?.map((p) => renderBrokerAccountsSyncStatus(p))}
          {renderBrokerAccountsFailureMessage()}
        </Grid>
      );
    }
  };

  const renderSummaryView = () => {
    if (!expanded) {
      return null;
    }
    return (
      <Accordion expanded={!expanded}>
        <Card
          style={{
            zIndex: 1,
            position: 'absolute',
            width: '100%',
            boxShadow: '0px 3px 3px rgba(217,217,217,0,0.5)',
          }}>
          <Grid
            container
            columnSpacing={1}
            p={2}
            style={{
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.info.dark,
            }}>
            <Grid item xs={12}>
              <OPLightTypograpghy>{'Results filtered by:'}</OPLightTypograpghy>
            </Grid>
            <Grid item container xs={12} columnSpacing={1}>
              <Grid item xs={'auto'}>
                <OPLightTypograpghy>{'Portfolio Selected: '}</OPLightTypograpghy>
              </Grid>
              <Grid item xs={10}>
                <OPBoldTypograpghy sx={{ whiteSpace: 'normal' }}>
                  {portfolioAccountSummary.selectedAccounts}
                </OPBoldTypograpghy>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <OPBoldTypograpghy>
                <span style={{ color: theme.palette.success.main }}>{'Alert Filters: '}</span>
                {portfolioAccountSummary.selectedAlertsLength}
              </OPBoldTypograpghy>
              {portfolioAccountSummary.selectedAlerts
                ?.trim()
                .split(',')
                .map((a, i) => (
                  <OPBoldTypograpghy key={i}>{a}</OPBoldTypograpghy>
                ))}
            </Grid>
            <Grid item xs={6}>
              <OPBoldTypograpghy>
                <span style={{ color: theme.palette.primary.main }}>{'Notification Filters: '}</span>
                {portfolioAccountSummary.selectedNotificationsLength}
              </OPBoldTypograpghy>
              {portfolioAccountSummary.selectedNotifications
                ?.trim()
                .split(',')
                .map((n, i) => (
                  <OPBoldTypograpghy key={i}>{n}</OPBoldTypograpghy>
                ))}
            </Grid>
          </Grid>
          {renderBrokerAccountsData()}
        </Card>
      </Accordion>
    );
  };

  return (
    <div style={{ width: '100%', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
      <Grid
        container
        // justifyContent="flex-end"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.info.dark,
          boxShadow: `0px 2px 2px rgba(0,0,0,0.1) inset`,
          px: 1,
          pt: 0.5,
        }}>
        <Grid item xs={1} justifyContent="flex-start">
          {portfolioAccountSummary.brokerAccounts?.some((a) => a.syncStatus.trim() != 'Processed') && (
            <span style={{ width: 25 }}>
              {' '}
              <img
                id="portfolio-error"
                alt="Portfolio Error"
                src={ErrorIcon}
                style={{ width: 19, height: 19, position: 'relative', top: 3, left: 5 }}></img>
            </span>
          )}
        </Grid>
        <Grid item xs={11} container justifyContent="flex-end" alignItems="center">
          <OPLightTypograpghy>Applied Filters</OPLightTypograpghy>
          <IconButton aria-label="delete" size="small" sx={{ p: 0, pr: 2, color: theme.palette.primary.main }}>
            {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
      {renderSummaryView()}
    </div>
  );
};
