import { selector } from 'recoil';
import { getNewsFeed } from '../services';
import { selectedSymbolState } from './trade';

export const newsFeedState = selector({
  key: 'newsFeedStateKey',
  get: async ({ get }) => {
    const symbol = get(selectedSymbolState);
    if (!symbol) {
      return undefined;
    }
    const response = await getNewsFeed(symbol);
    return response;
  },
});
