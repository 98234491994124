import { ApiService } from './api-service';
import { CoveredCalls } from '../models/reports/covered-call';
import { CreditSpreads } from '../models/reports/credit-spread';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ShortPuts } from '../models/reports/short-put';

const reportServiceUrl = '/v1/reports';

export const getCreditSpreadReports = async (): Promise<ResponseViewModel<CreditSpreads>> => {
  const url = `${reportServiceUrl}/creditspreads/all?showURL=true&showExtended=true`;
  const response = await ApiService.instance.get<CreditSpreads>(url);
  // credit spread data don't have signals array. once API changes done, will be modified.
  response?.data?.creditSpreads.forEach((e) => {
    e.signals = [
      {
        date: '',
        name: 'ImpliedVolatilityRank',
        symbol: e.symbol,
        value: Number(e.impliedVolatilityRank?.replace('%', '')),
      },
    ];
  });
  const viewModel = new ResponseViewModel<CreditSpreads>();
  viewModel.data = response.data as unknown as CreditSpreads;
  viewModel.errors = response.errors;
  return response;
};

export const getCoveredCallReports = async (): Promise<ResponseViewModel<CoveredCalls>> => {
  const url = `${reportServiceUrl}/coveredcalls/all?showURL=true&showExtended=true`;
  const response = await ApiService.instance.get<CoveredCalls>(url);
  // covered call data don't have signals array. once API changes done, will be modified.
  response?.data?.coveredCalls.forEach((e) => {
    e.signals = [
      {
        date: '',
        name: 'ImpliedVolatilityRank',
        symbol: e.symbol,
        value: Number(e.impliedVolatilityRank?.replace('%', '')),
      },
      {
        date: '',
        name: 'Liquidity',
        symbol: e.symbol,
        value: e.liquidityRank,
      },
    ];
  });
  const viewModel = new ResponseViewModel<CoveredCalls>();
  viewModel.data = response.data as unknown as CoveredCalls;
  viewModel.errors = response.errors;
  return response;
};

export const getShortPutReports = async (): Promise<ResponseViewModel<ShortPuts>> => {
  const url = `${reportServiceUrl}/shortputs/all?showURL=true&showExtended=true`;
  const response = await ApiService.instance.get<ShortPuts>(url);
  // short-put data don't have signals array. once API changes done, will be modified.
  response?.data?.shortPuts.forEach((e) => {
    e.signals = [
      {
        date: '',
        name: 'ImpliedVolatilityRank',
        symbol: e.symbol,
        value: Number(e.impliedVolatilityRank?.replace('%', '')),
      },
      {
        date: '',
        name: 'Liquidity',
        symbol: e.symbol,
        value: e.liquidityRank,
      },
    ];
  });
  const viewModel = new ResponseViewModel<ShortPuts>();
  viewModel.data = response.data as unknown as ShortPuts;
  viewModel.errors = response.errors;
  return response;
};

export const downloadReports = async (reportType: string, createdOn: string): Promise<ResponseViewModel<unknown>> => {
  const requestConfig = {
    headers: {
      'content-type': 'text/csv',
    },
  };
  const url = `${reportServiceUrl}/${reportType}/export?createdOn=${createdOn}`;
  const response = await ApiService.instance.get(url, null, requestConfig);
  return response;
};
