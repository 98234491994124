import { Grid, Slider, Theme, Typography, styled } from '@mui/material';

import { IDataProps } from '@op/shared/src/models';
import LocalizationContext from '../react-i18next/localization-context';
import React from 'react';
import { customizationState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';
import { OPSlider } from '../styled';

export interface IIVrankProps extends IDataProps<{ date: string; name: string; symbol: string; value: number }[]> {
  showIVRankBar: boolean;
}

export const IVRankWidget = ({ data, showIVRankBar }: IIVrankProps) => {
  const { t } = React.useContext(LocalizationContext);
  const customization = useRecoilValue(customizationState);
  const isMinimalistic = customization?.isMinimalistic || true;
  const signal = data?.find((s) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase());

  const marks = [
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
  ];

  if (!customization) {
    return null;
  }

  const getBackgroundGradient = (theme: Theme) => {
    const defaultColor = theme.palette.grey[400]; //'gray';
    const primaryColor = theme.palette.primary.main; //'blue';
    const thumbPercentage = 100;
    const carretPercentage = signal?.value;
    return `linear-gradient(90deg, ${primaryColor} ${carretPercentage}%, ${defaultColor} ${carretPercentage}% ${thumbPercentage}%, ${defaultColor} ${thumbPercentage}%)`;
  };

  const renderIVRankValue = () => {
    /**
     * Math.round has done previously as
     * In a case of iv rank less than 0.5 its rounded of to 0 (zero) which is not correct to show in UI.
     * Thus checking intially and showing N/A when values is zero.
     */
    const signalValue = signal && signal.value ? Math.round(signal.value) : 0;
    if (!signalValue) {
      return (
        <Grid container justifyContent="space-between" className="js-trade-idea-iv-rank">
          <Grid item>
            <Typography variant="captionSmall"> {t('why.ImpliedVolatilityRank')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="captionSmall">
              <strong>{t('common.labels.notAvailable')}</strong>
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <>
        <Grid container justifyContent="space-between">
          {isMinimalistic && !showIVRankBar ? (
            <>
              <Grid item className="js-trade-idea-iv-rank">
                <Typography variant={'captionSmall'}>{t('why.ImpliedVolatilityRank')}</Typography>
              </Grid>
              <Grid item className="js-trade-idea-iv-rank">
                <Typography variant={'captionSmall'}>
                  <strong>{signal && signal.value && `${Math.round(signal.value)}/100`}</strong>
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={2.5}>
                <Typography variant={'captionSmall'}>{t('why.ImpliedVolatilityRank')}</Typography>
              </Grid>
              <Grid item xs={7}>
                <OPSlider
                  id="slide-whatIfSPrices"
                  data-name="whatIfSPrice"
                  value={signal.value}
                  min={0}
                  max={100}
                  // step={0.01}
                  marks={marks}
                  valueLabelDisplay="off"
                  track={false}
                  sx={{
                    '& .MuiSlider-rail': {
                      height: 7,
                      background: getBackgroundGradient,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2.5} pl={1}>
                <Typography variant={'captionSmall'}>
                  <strong>{`${Math.round(signal.value)}/100`}</strong>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  const renderIVRank = () => {
    if (!customization.showIV) {
      return;
    }
    return <>{renderIVRankValue()}</>;
  };

  return <>{renderIVRank()}</>;
};
