import { IPortfolioCombinationAccounts, PortfolioCombinationAccount } from '.';

export class PortfolioCombinationAccounts implements IPortfolioCombinationAccounts {
  data: PortfolioCombinationAccount[] = [];
  public constructor() {}

  public static fromData = (responseData: any): PortfolioCombinationAccounts => {
    const porfolioCombinationAcc = new PortfolioCombinationAccounts();
    const accounts = responseData.portfolioCombinationAccounts;
    accounts.forEach((item: any) => {
      porfolioCombinationAcc.data.push(PortfolioCombinationAccount.fromData(item));
    });
    return porfolioCombinationAcc;
  };
}
