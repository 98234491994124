import { MarketRegion } from './enums/enums';

export interface IProvince {
  province: string;
  symbol: string;
  region: MarketRegion;
}

export class Provinces {
  private static provinces: IProvince[] = [
    { province: 'Denmark', symbol: 'DNK', region: MarketRegion.Nordic },
    { province: 'Finland', symbol: 'FIN', region: MarketRegion.Nordic },
    { province: 'Norway', symbol: 'NOR', region: MarketRegion.Nordic },
    { province: 'Sweden', symbol: 'SWE', region: MarketRegion.Nordic },
    { province: 'Other', symbol: 'OTH', region: MarketRegion.Nordic },

    { province: 'Alberta', symbol: 'AB', region: MarketRegion.TMX },
    { province: 'British Columbia', symbol: 'BC', region: MarketRegion.TMX },
    { province: 'Manitoba', symbol: 'MB', region: MarketRegion.TMX },
    { province: '"New Brunswick', symbol: 'NB', region: MarketRegion.TMX },
    { province: 'Newfoundland and Labrador', symbol: 'NL', region: MarketRegion.TMX },
    { province: 'Northwest Territories', symbol: 'NT', region: MarketRegion.TMX },
    { province: 'Nova Scotia', symbol: 'NS', region: MarketRegion.TMX },
    { province: 'Nunavut', symbol: 'NU', region: MarketRegion.TMX },
    { province: 'Ontario', symbol: 'ON', region: MarketRegion.TMX },
    { province: 'Prince Edward Island', symbol: 'PE', region: MarketRegion.TMX },
    { province: 'Quebec', symbol: 'QC', region: MarketRegion.TMX },
    { province: 'Saskatchewan', symbol: 'SK', region: MarketRegion.TMX },
    { province: 'Yukon', symbol: 'YT', region: MarketRegion.TMX },
  ];

  static get nordicProvinces() {
    return Provinces.provinces.filter((c) => c.region === MarketRegion.Nordic);
  }

  static get tmxProvinces() {
    return Provinces.provinces.filter((c) => c.region === MarketRegion.TMX);
  }
}
