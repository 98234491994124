import {
  expandedQuoteAtomFamily,
  expandedQuotesUpdater,
  fetchQuotes,
  isHubInitiatedState,
  subscribeToQuotes,
  unsubscribeFromQuotes,
} from '@op/shared/src/states';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useHubExpandedQuote = (symbol: string) => {
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(symbol));
  const setExpandedQuotes = useSetRecoilState(expandedQuotesUpdater);
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  useEffect(() => {
    if (!isHubInitiated || !symbol) {
      return;
    }
    (async () => {
      if (!expandedQuote) {
        await fetchQuote();
      }
      await subscribeToQuotes([symbol]);
    })();
    //Clearing function . TODO: use with mode.
    // return () => {
    //   (async () => {
    //     await unsubscribeFromQuotes([symbol]);
    //   })();
    // };
  }, [isHubInitiated, symbol]);

  const fetchQuote = async () => {
    const fetchedQuotes = await fetchQuotes([symbol]);
    if (!fetchedQuotes || fetchedQuotes.length === 0) {
      return;
    }
    setExpandedQuotes(fetchedQuotes);
  };
};
