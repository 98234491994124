import { Filters } from '@op/shared/src/models';
import { IAlertsByAccounts } from '@op/shared/src/models/portfolio/alert-filter-model';
import loadash from 'lodash';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  configurationState,
  guardRecoilDefaultValue,
  portfolioAccountsDataState,
  portfolioCombinationsOfSelectedAccountsState,
  portfolioSelectedAccountIdsState,
} from '.';
import { PortfolioAlert } from '../hubs/portfolio-alert';
import { PowerhouseProAlertModel } from '../hubs/powerhouse-pro-alert-model';
import { AlertType } from '../models/enums/enums';
import ApplicationContext from '../models/how/application-context';

export const portfolioCombinationAlertOrNotificationSelectedState = atom<
  | {
      alertType: AlertType;
      combinationHash: string;
    }
  | undefined
>({
  key: 'portfolioCombinationAlertOrNotificationSelectedStateKey',
  default: undefined,
});

export const portfolioAlertByAccountCachedState = atomFamily<PowerhouseProAlertModel | undefined, number>({
  key: 'portfolioAlertsByAccountCachedStateKey',
  default: undefined,
});

// This is used for declaring the types of filters EX: alert,notifications
export const filtersPortfoliosState = atom({
  key: 'filtersPortfoliosStateKey',
  default: Filters.portfolioDefaultFilters,
});

export const selectedAlertFilterState = atom({
  key: 'selectedAlertFilterStateKey',
  default: Filters.portfolioDefaultFilters.data.find((f) => f.name === 'alert'),
});

export const selectedNotificationFilterState = atom({
  key: 'selectedNotificationFilterStateKey',
  default: Filters.portfolioDefaultFilters.data.find((f) => f.name === 'notification'),
});

export const portfolioAlertsState = selector<PowerhouseProAlertModel[] | undefined>({
  key: 'portfolioAlertsStateKey',
  get: () => {
    throw new Error('Not implemented');
  },
  set: ({ set }, newAlerts) => {
    if (!newAlerts || guardRecoilDefaultValue(newAlerts)) {
      return;
    }
    for (let alert of newAlerts) {
      set(portfolioAlertByAccountCachedState(alert.accountId), alert);
    }
  },
});

const portfolioAlertsByAccountsState = selector<PortfolioAlert[] | undefined>({
  key: 'portfolioAlertsByAccountsStateKey',
  get: ({ get }) => {
    const selectedAccountIds = get(portfolioSelectedAccountIdsState);
    const accounts = get(portfolioAccountsDataState);
    if (!selectedAccountIds || selectedAccountIds.length === 0 || !accounts || accounts.length === 0) {
      return undefined;
    }
    let alertsOnCombination: PortfolioAlert[] = [];
    for (let id of selectedAccountIds) {
      const alertsAndNotificationsOfAccount = get(portfolioAlertByAccountCachedState(id));
      if (!alertsAndNotificationsOfAccount || alertsAndNotificationsOfAccount.alerts.length === 0) {
        continue;
      }
      const account = accounts.find((a) => a.portfolioId === id);
      if (!account) {
        continue;
      }
      const alert = alertsAndNotificationsOfAccount.alerts.filter((alert) =>
        account.portfolioCombinations.some(
          (comb) => alert.type === AlertType.ALERT && alert.portfolioCombinationHash === comb.hash,
        ),
      );
      alertsOnCombination = alertsOnCombination.concat(alert);
    }
    return alertsOnCombination;
  },
});

/**
 * @deprecated DO NOT USE IT. REMOVE IT.
 * Changing number to PortfolioALert
 */
export const portfolioAlertsByAccountCountState = selector<number>({
  key: 'portfolioAlertsByAccountCountStateKey',
  get: ({ get }) => {
    const notifications = get(portfolioAlertsByAccountsState);
    return notifications?.length || 0;
  },
});

export const alertsByAccountsState = selector<IAlertsByAccounts | undefined>({
  key: 'alertsByAccountsStateKey',
  get: ({ get }) => {
    const alerts = get(portfolioAlertsByAccountsState);
    const configuration = get(configurationState);
    const combinationEntitiesOfSelectedAccounts = get(portfolioCombinationsOfSelectedAccountsState);
    if (!alerts || !configuration || !configuration.portfolioAlertResources || !combinationEntitiesOfSelectedAccounts) {
      return;
    }
    const alertsByKey = alerts.map((a) => a.key);
    const alertsWithCount = loadash.map(loadash.countBy(alertsByKey), (value, key) => {
      let heading = key;
      let hash: string = '';
      alerts.map((a) => {
        if (a.key === key) {
          hash += a.portfolioCombinationHash.trim() + ',';
        }
      });
      configuration.portfolioAlertResources.map((r) => {
        if (r.key === key) {
          heading = r.heading;
        }
      });
      return { key: key, name: heading || key, value: hash, count: value };
    });
    return { alert: alertsWithCount, totalCount: alerts.length };
  },
});

export const portfolioAlertsByAccountAndCombinationState = selectorFamily<PortfolioAlert[] | undefined, string>({
  key: 'portfolioAlertsByAccountAndCombinationStateKey',
  get:
    (portfolioCombinationHash: string) =>
    ({ get }) => {
      const alerts = get(portfolioAlertsByAccountsState);
      if (!alerts) {
        return undefined;
      }
      return alerts.filter((a) => a.portfolioCombinationHash === portfolioCombinationHash);
    },
});

const portfolioNotificationsByAccountState = selector<PortfolioAlert[] | undefined>({
  key: 'portfolioNotificationsByAccountStateKey',
  get: ({ get }) => {
    const selectedAccountIds = get(portfolioSelectedAccountIdsState);
    const accounts = get(portfolioAccountsDataState);
    if (!selectedAccountIds || selectedAccountIds.length === 0 || !accounts || accounts.length === 0) {
      return undefined;
    }
    let notificationsOnCombination: PortfolioAlert[] = [];
    for (let id of selectedAccountIds) {
      const alertsAndNotificationsOfAccount = get(portfolioAlertByAccountCachedState(id));
      if (!alertsAndNotificationsOfAccount || alertsAndNotificationsOfAccount.alerts.length === 0) {
        continue;
      }
      const account = accounts.find((a) => a.portfolioId === id);
      if (!account) {
        continue;
      }
      const alert = alertsAndNotificationsOfAccount.alerts.filter((alert) =>
        account.portfolioCombinations.some(
          (comb) => alert.type === AlertType.NOTIFICATION && alert.portfolioCombinationHash === comb.hash,
        ),
      );
      notificationsOnCombination = notificationsOnCombination.concat(alert);
    }
    return notificationsOnCombination;
  },
});

/**
 * @deprecated DO NOT USE IT. REMOVE IT.
 * Changing number to PortfolioALert
 */
export const portfolioNotificationsByAccountCountState = selector<number>({
  key: 'portfolioNotificationsByAccountCountStateKey',
  get: ({ get }) => {
    const notifications = get(portfolioNotificationsByAccountState);
    return notifications?.length || 0;
  },
});

//changed
export const notificationByAccountsState = selector<IAlertsByAccounts | undefined>({
  key: 'notificationByAccountsStateKey',
  get: ({ get }) => {
    const notifications = get(portfolioNotificationsByAccountState);
    const configuration = get(configurationState);
    const combinationEntitiesOfSelectedAccounts = get(portfolioCombinationsOfSelectedAccountsState);
    if (
      !notifications ||
      !configuration ||
      !configuration.portfolioAlertResources ||
      !combinationEntitiesOfSelectedAccounts
    ) {
      return;
    }
    const notificationsByKey = notifications.map((a) => a.key);
    const notificationWithCount = loadash.map(loadash.countBy(notificationsByKey), (value, key) => {
      let heading = key;
      let hash: string = '';
      notifications.map((a) => {
        if (a.key === key) {
          hash += a.portfolioCombinationHash.trim() + ',';
        }
      });
      configuration.portfolioAlertResources.map((r) => {
        if (r.key === key) {
          heading = r.heading;
        }
      });
      return { key: key, name: heading || key, value: hash, count: value };
    });
    return { alert: notificationWithCount, totalCount: notifications.length };
  },
});

export const portfolioNotificationsByAccountAndCombinationState = selectorFamily<PortfolioAlert[] | undefined, string>({
  key: 'portfolioNotificationsByAccountAndCombinationStateKey',
  get:
    (portfolioCombinationHash: string) =>
    ({ get }) => {
      const notifications = get(portfolioNotificationsByAccountState);
      if (!notifications) {
        return undefined;
      }
      return notifications.filter((a) => a.portfolioCombinationHash === portfolioCombinationHash);
    },
});

export const subscribePortfolioAlerts = async (accountIds: (number | string)[] | undefined) => {
  if (!accountIds || accountIds.length === 0) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  await powerhouseProHub.portfolioAlertSubscription.subscribe(accountIds);
};

export const unsubscribePortfolioAlerts = async (accountIds: (number | string)[] | undefined) => {
  if (!accountIds || accountIds.length === 0) {
    return;
  }
  const powerhouseProHub = ApplicationContext.powerhouseProHub;
  if (!powerhouseProHub) {
    return undefined;
  }
  await powerhouseProHub.portfolioAlertSubscription.unsubscribe([accountIds]);
};
