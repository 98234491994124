import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  List,
  ListItemText,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { IProvince, isNordic, isTmx, Provinces, SignUpModel } from '@op/shared/src/models';
import React, { FormEvent, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import { PromoCodes } from '@op/shared/src/models/enums/enums';
import { AuthenticationService } from '@op/shared/src/services';
import { configurationState } from '@op/shared/src/states';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LogoWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { RegistrationDialogue } from './registration-popup';

export const FormStyle = styled('form')(({ theme }) => ({
  '& .MuiFormControl-root': {
    width: '80%',
    margin: theme.spacing(2),
  },
}));

export const RegistrationWidget: React.FC = () => {
  const [values, setValues] = useState(new SignUpModel());
  const [errors, setErrors] = useState<any>({});
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const configuration = useRecoilValue(configurationState);
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const location = useLocation();
  const [availableProvinces, setAvailableProvinces] = React.useState<IProvince[] | undefined>();
  const [selectedProvince, setSelectedProvince] = React.useState('');
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (isNordic()) {
      setAvailableProvinces(Provinces.nordicProvinces);
    }
  }, []);

  const validate = (): boolean => {
    const temp = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    if (!values.firstName || values.firstName.trim() === '') {
      temp.firstName = 'First Name is required';
    }
    if (!values.lastName || values.lastName.trim() === '') {
      temp.lastName = 'Last Name is required';
    }
    if (!values.email || values.email.trim() === '') {
      temp.email = 'Email Address is required';
    } else if (!pattern.test(values.email)) {
      temp.email = 'Email Address is Invalid';
    }
    if (!values.password || values.password.trim() === '') {
      temp.password = 'Password is required';
    } else if (values.password.length < 6) {
      temp.password = 'Password needs to be 6 characters or more';
    }
    if (!values.confirmPassword || values.confirmPassword.trim() === '') {
      temp.confirmPassword = 'Confirm Password is required';
    } else if (values.confirmPassword !== values.password) {
      temp.confirmPassword = 'Confirm Password do not match';
    }
    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === '');
  };

  //privacyPolicyAccepted
  const handelInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = (): void => {
    setValues(new SignUpModel());
    setErrors({});
  };

  const checkReturnUrl = (): boolean => {
    if (location.search.includes('returnUrl')) {
      let shareObj = {
        sharedTradeId: location.search.split('/')[1],
        returnUrl: '#' + location.search.split('=')[1],
        isB2B: false, // can be ignored
        marketRegion: configuration?.marketRegion,
        promoCode: PromoCodes.Sharing,
      };

      let mergedValues = { ...values, ...shareObj };
      setValues(mergedValues);
      return true;
    }
    return false;
  };

  const handelSubmit = async (e: FormEvent<Element>): Promise<void> => {
    e.preventDefault();
    checkReturnUrl();
    if (validate()) {
      const response = await AuthenticationService.instance.signUp(values);
      setPopupVisible(true);
      resetForm();
    }
  };
  const handleEmail = () => {
    (async () => {
      if (pattern.test(values.email)) {
        const response = await AuthenticationService.instance.canUseEmail(values.email);
        if (!response.data) {
          setIsVerified(true);
        } else {
          setIsVerified(false);
        }
      }
    })();
  };

  const handleChangeProvince = (event: any) => {
    setSelectedProvince(event.target.value);
    setValues({
      ...values,
      stateOrProvince: event.target.value,
    });
  };

  const handleChangePrivacy = (value: boolean) => {
    setIsChecked(value);
    setValues({
      ...values,
      privacyPolicyAccepted: value,
    });
  };

  const renderCountrySelection = () => {
    if (!availableProvinces) {
      return;
    }
    return (
      <FormControl fullWidth>
        <InputLabel id="country-label">{t('common.inputs.country')}</InputLabel>
        <Select
          labelId="country-label"
          id="country-select"
          variant="outlined"
          value={selectedProvince}
          onChange={handleChangeProvince}>
          {availableProvinces.map((i) => (
            <MenuItem key={i.symbol} value={i.symbol}>
              {i.province}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderRegistrationListItems = () => {
    return (
      <List sx={{ textAlign: 'left' }}>
        <ListItemText>
          <Button
            sx={{ cursor: 'default', ':hover': { backgroundColor: 'transparent' } }}
            disableRipple
            startIcon={<CheckIcon sx={{ color: 'green' }} />}>
            <Typography variant="h6">{t('signUp.text.dailyStockTradeIdeas')}</Typography>
          </Button>
        </ListItemText>
        <ListItemText>
          <Button
            sx={{ cursor: 'default', ':hover': { backgroundColor: 'transparent' } }}
            disableRipple
            startIcon={<CheckIcon sx={{ color: 'green' }} />}>
            <Typography variant="h6">{t('signUp.text.unbiasedAnalytics')}</Typography>
          </Button>
        </ListItemText>
        <ListItemText>
          <Button
            sx={{ cursor: 'default', ':hover': { backgroundColor: 'transparent' } }}
            disableRipple
            startIcon={<CheckIcon sx={{ color: 'green' }} />}>
            <Typography variant="h6">{t('signUp.text.optimizedTradingStrategies')}</Typography>
          </Button>
        </ListItemText>
        <ListItemText>
          <Button
            sx={{ cursor: 'default', ':hover': { backgroundColor: 'transparent' } }}
            disableRipple
            startIcon={<CheckIcon sx={{ color: 'green' }} />}>
            <Typography variant="h6">{t('signUp.text.incomeGenerationPlays')}</Typography>
          </Button>
        </ListItemText>
      </List>
    );
  };

  const renderPrivacyText = () => {
    if (!isTmx()) {
      return;
    }
    return (
      <Grid container p={2} sx={{ textAlign: 'left' }} spacing={1}>
        <Grid item xs={1}>
          <Checkbox
            checked={isChecked}
            onChange={() => handleChangePrivacy(!isChecked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
        <Grid item container xs={11} spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <span className="caption">
                I understand that OptionsPlay might provide my email address to Bourse de Montréal Inc. and expressly
                agree to receive electronic communications from
                <Link href="https://www.m-x.ca" target="_blank">
                  Bourse de Montréal Inc.
                </Link>
                in accordance with the Bourse de Montréal Inc.s
                <Link href="https://m-x.ca/accueil_politique_en.php" target="_blank">
                  Privacy Policy
                </Link>
                , notably for marketing, promotional and other purposes, and understand that I can unsubscribe from such
                communications at any time.
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <span className="caption">
                Please note that any information (including personal information) you may provide to OptionsPlay by
                logging in or using the OptionsPlay Web site may be shared with Bourse de Montréal Inc. in order to,
                among other reasons, establish the standard profile of users of OptionsPlay Web site, to learn about
                your needs and preferences, to inform you about new products and/or services offered by Bourse de
                Montréal Inc., and to answer your questions and enquiries, the whole in accordance with the terms and
                conditions of Bourse de Montréal Inc.s privacy policy available{' '}
                <Link href="https://m-x.ca/accueil_politique_en.php" target="_blank">
                  here
                </Link>
                .
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {isPopupVisible && <RegistrationDialogue />}
      <FormStyle id="signUpForm" onSubmit={handelSubmit}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6} sx={{ py: 2 }}>
            <Grid item xs={12} sx={{ pt: 2 }}>
              <LogoWidget direction="column" />
            </Grid>
            <Grid item>
              <Typography variant="h5">{t('signUp.titles.createYourAccount')}</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="firstName"
                placeholder="First Name"
                variant="outlined"
                name="firstName"
                label={t('common.inputs.firstName')}
                value={values.firstName}
                onChange={handelInputChange}
                helperText={<span>{errors.firstName}</span>}
                error={errors.firstName}
              />
            </Grid>
            <Grid item>
              <TextField
                id="lastName"
                placeholder="Last Name"
                variant="outlined"
                label={t('common.inputs.lastName')}
                name="lastName"
                value={values.lastName}
                onChange={handelInputChange}
                helperText={<span>{errors.lastName}</span>}
                error={errors.lastName}
              />
            </Grid>
            <Grid item>
              <TextField
                id="email"
                placeholder="Email Address"
                variant="outlined"
                label={t('common.inputs.email')}
                name="email"
                autoComplete="off"
                value={values.email}
                onChange={handelInputChange}
                onBlur={handleEmail}
                helperText={
                  <span className="span-error">{isVerified ? 'This email is already used.' : errors.email}</span>
                }
                error={errors.email}
              />
            </Grid>
            <Grid item>{renderCountrySelection()}</Grid>
            <Grid item>
              <TextField
                id="password"
                placeholder="Password"
                variant="outlined"
                label={t('common.inputs.password')}
                type="password"
                name="password"
                value={values.password}
                onChange={handelInputChange}
                error={errors.password}
                helperText={<span>{errors.password}</span>}
              />
            </Grid>
            <Grid item>
              <TextField
                id="confirmPassword"
                placeholder="Confirm Password"
                variant="outlined"
                label={t('common.inputs.confirmPassword')}
                type="password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handelInputChange}
                helperText={<span>{errors.confirmPassword}</span>}
                error={errors.confirmPassword}
              />
            </Grid>
            <Grid item>
              <Button
                id="signUpBtn"
                variant="contained"
                color="primary"
                type="submit"
                disabled={isVerified ? true : false}
                sx={{
                  width: '80%',
                  height: 60,
                  backgroundColor: theme.palette.success.main,
                  '&:hover': {
                    background: theme.palette.success.main,
                  },
                }}>
                <Typography variant="button">{t('signUp.buttons.startYourFreeMonth')}</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              background: theme.palette.background.default,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {renderRegistrationListItems()}
          </Grid>
        </Grid>
      </FormStyle>
    </>
  );
};
