interface IOptionsChains {
  underlying: string;
  strikePrice: number;
  expiry: string;
  optionType: string;
  callOption: Option[];
  putOption: Option[];
}
interface IOptions {
  volume: number;
  delta: number;
  gamma: number;
  theta: number;
  vega: number;
  rho: number;
  impliedVolatility: number;
  occSymbol: string;
  openInterest: number;
  premiumMultiplier: number;
  rootSymbol: string;
  bid: number;
  ask: number;
  shortCode: string;
}

class Option implements IOptions {
  volume = 0;
  delta = 0;
  gamma = 0;
  theta = 0;
  vega = 0;
  rho = 0;
  impliedVolatility = 0;
  occSymbol = '';
  openInterest = 0;
  premiumMultiplier = 0;
  rootSymbol = '';
  bid = 0;
  ask = 0;
  shortCode = '';

  public static fromJson = (data: Option): Option => {
    const model = new Option();
    model.volume = data.volume;
    model.delta = data.delta;
    model.gamma = data.gamma;
    model.theta = data.theta;
    model.vega = data.vega;
    model.rho = data.rho;
    model.impliedVolatility = data.impliedVolatility;
    model.occSymbol = data.occSymbol;
    model.openInterest = data.openInterest;
    model.premiumMultiplier = data.premiumMultiplier;
    model.rootSymbol = data.rootSymbol;
    model.bid = data.bid;
    model.ask = data.ask;
    model.shortCode = data.shortCode;

    return model;
  };
}

export class OptionsChains implements IOptionsChains {
  underlying = '';
  strikePrice = 0;
  expiry = '';
  optionType = '';
  callOption: Option[] = [];
  putOption: Option[] = [];

  static fromSelf = (data: IOptionsChains) => {
    const model = new OptionsChains();
    model.underlying = data.underlying;
    model.strikePrice = data.strikePrice;
    model.expiry = data.expiry;
    model.optionType = data.optionType;
    model.callOption = data && data.callOption ? (data.callOption || []).map((p) => Option.fromJson(p)) : [];
    model.putOption = data && data.putOption ? (data.putOption || []).map((p) => Option.fromJson(p)) : [];

    return model;
  };
}
