import { atom } from 'recoil';
import { Filters, Sorts } from '@op/shared/src/models';

export const filtersTradesState = atom({
  key: 'filtersTradesState',
  default: Filters.TradeIdeasDefaultFilters,
});

export const sortsTradesState = atom({
  key: 'sortsTradesState',
  default: new Sorts([Sorts.TradeIdeasDefaultSorts.data[0]]),
});
