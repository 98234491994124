import { Typography, Theme } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import { tradingRangeSimulatorState, whatIfSimulatorState } from '@op/shared/src/states/how/how-states';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDatePickerWidget, OptionsPlaySlider, OptionsPlayThumbComponent } from '../styled';
import { PLSimulatorSubWidget } from './pl-simulator-sub-widget';
import { selectedSymbolState } from '@op/shared/src/states';

export const WhatIfExpiryDateWidget: React.FC = () => {
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [whatIfSimulator, setWhatIfSimulator] = useRecoilState(whatIfSimulatorState);
  const [whatIfDays, setWhatIfDays] = useState(0);
  const [expiryDateFormatted, setExpiryDateFormatted] = useState<string>('');
  const { t } = React.useContext(LocalizationContext);

  const getDateFormat = (fDate: Date) => {
    return DateTimeHelper.format(fDate, 'yyyy-MM-dd');
  };

  useEffect(() => {
    if (!whatIfSimulator) {
      return;
    }
    const expiry = whatIfSimulator.whatIfDate || DateTimeHelper.getCurrentDate();
    setWhatIfDays(whatIfSimulator.whatIfDays);
    setExpiryDateFormatted(getDateFormat(expiry));
  }, [whatIfSimulator]);

  const onSliderChangeCommittedExpiryDate = (numberOfDays: number | number[]) => {
    if (!whatIfSimulator) {
      return;
    }
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    const date = getModifiedDateAsPerBellSlider(numberOfDays as number);
    newWhatIfSimulator.whatIfDate = date;
    setWhatIfSimulator(newWhatIfSimulator);
    ApplicationContext.userActivityHub?.logActivity(
      'whatIfDateInput',
      'whatIfDateInput',
      `${date}`,
      'slidechange',
      selectedSymbol,
    );
  };

  const debouncedOnSliderChangeForExpiryDate = useMemo(
    () => debounce(onSliderChangeCommittedExpiryDate, 50),
    [whatIfSimulator],
  );

  // WhatIfSimulator can be undefined, if the security does not have an option chain.
  if (!whatIfSimulator || !tradingRangeSimulator) {
    return null;
  }

  const updateWhatIfDate = (date: Date | null): void => {
    if (date === null) {
      return;
    }
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    newWhatIfSimulator.whatIfDate = date as Date;
    setWhatIfSimulator(newWhatIfSimulator);
    setWhatIfDays(DateTimeHelper.daysFromNow(date));
    setExpiryDateFormatted(getDateFormat(date));
    ApplicationContext.userActivityHub?.logActivity(
      'whatIfDateInput',
      'whatIfDateInput',
      `${date}`,
      'blur',
      selectedSymbol,
    );
  };

  const onSliderChangeExpiryDate = (event: Event, value: number | number[], activeThumb: number) => {
    const formattedDate = getExpirationFormatted(value as number);
    setWhatIfDays(value as number);
    setExpiryDateFormatted(formattedDate);
    debouncedOnSliderChangeForExpiryDate(value);
  };

  const getExpirationFormatted = (numberOfDays: number): string => {
    let dateNow = getModifiedDateAsPerBellSlider(numberOfDays);
    if (!dateNow) {
      dateNow = new Date();
    }
    return getDateFormat(dateNow);
  };

  const getModifiedDateAsPerBellSlider = (value: number) => {
    if (tradingRangeSimulator && tradingRangeSimulator.dateOfBellSlider) {
      let modifiedDateOfBellSlider = new Date(tradingRangeSimulator.dateOfBellSlider.toString());
      modifiedDateOfBellSlider.setDate(modifiedDateOfBellSlider.getDate() - tradingRangeSimulator.dateSliderMaxDays());
      modifiedDateOfBellSlider.setDate(modifiedDateOfBellSlider.getDate() + value);
      return modifiedDateOfBellSlider;
    }
  };

  const getBackgroundGradient = (theme: Theme) => {
    const max = tradingRangeSimulator.dateSliderMaxDays();
    const carretPercentage = 100;
    const thumbPercentage = (whatIfDays / max) * 100;
    const defaultColor = theme.palette.slider.main;
    const successColor = theme.palette.success.main;
    return `linear-gradient(90deg, ${defaultColor} ${carretPercentage}%, ${successColor} ${thumbPercentage}%)`;
  };

  return (
    <PLSimulatorSubWidget
      label={
        <Typography variant="body1" id="range-slider">
          {t('how.toolbox.whatIfSimulator.labels.byThisDate')}
        </Typography>
      }
      box={
        /**
         * Below date Picker Format has differnet behaviour on format passed to value
         * Check : https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off/31732581#31732581
         */
        // TODO : Use OptionsplayDatePicker widget
        <OptionsPlayDatePickerWidget
          value={expiryDateFormatted}
          min={DateTimeHelper.getCurrentDate()}
          max={tradingRangeSimulator.dateOfBellSlider}
          onChangeDate={updateWhatIfDate}
          onBlur={(e) => updateWhatIfDate(new Date(e.target.value))}
        />
      }
      leftLabel={
        <Typography variant="body1" sx={{ pr: 1 }}>
          {t('how.toolbox.whatIfSimulator.labels.today')}
        </Typography>
      }
      slider={
        <OptionsPlaySlider
          components={{ Thumb: OptionsPlayThumbComponent }}
          id="slide-whatIfDateInput"
          data-name="whatIfDateInput"
          data-value={whatIfSimulator.whatIfDays}
          value={whatIfDays} //initial value of undefined is treaded as `Uncontrolled component`. Hence default zero value.
          onChange={onSliderChangeExpiryDate}
          min={0}
          max={tradingRangeSimulator.dateSliderMaxDays()}
          step={1}
          marks={false}
          valueLabelDisplay="off"
          sx={{
            '& .MuiSlider-rail': {
              height: 5,
              borderRadius: 0.3,
              background: getBackgroundGradient,
            },
          }}
        />
      }
      rightLabel={
        <Typography variant="body1" textAlign="right" sx={{ pl: 1 }}>
          {t('common.labels.expiry')}
        </Typography>
      }
    />
  );
};
