import { Card, styled } from '@mui/material';
import { IData, PortfolioCombinationEntity } from '@op/shared/src/models';
import { PortfolioCombination } from '@op/shared/src/models/portfolio/portfolio-combination-model';
import { PortfolioCombinationsOfAnAccount } from '@op/shared/src/models/portfolio/portfolio-combinations-of-an-account';
import { fetchQuotes, isHubInitiatedState, portfolioSelectedCombinationState } from '@op/shared/src/states';
import { fetchSentiments } from '@op/shared/src/states/sentiment-hub-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { PortfolioCombinationDetailsWidget } from '.';
import { PortfolioAlertDetailsWidget } from './portfolio-alert-details-widget';
import { PortfolioNotificationDetailsWidget } from './portfolio-notification-details-widget';
import { PortfolioCombinationLoaderWidget } from './portfolio-what-loader-widget';

type OPPortfolioStyledHoverCard = {
  selected: boolean;
};

export const OPPortfolioStyledHoverCard = styled(Card)<OPPortfolioStyledHoverCard>(({ selected, theme }) => ({
  boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
  borderRadius: theme.shape.borderRadius,
  border: selected ? `1.5px solid ${theme.palette.primary.main}` : `1.5px solid transparent`,
  cursor: 'pointer',
  ':hover': {
    boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
    border: `1.5px solid ${theme.palette.primary.main}`,
    '& .js-portfolio-position-info': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  '& .js-portfolio-position-info': {
    backgroundColor: selected ? theme.palette.secondary.light : 'inherit',
  },
}));

export interface IPortfolioCombinationWidgetProps extends IData<PortfolioCombinationEntity> {
  index: number;
}

export const PortfolioCombinationWidget: React.FC<IPortfolioCombinationWidgetProps> = ({
  data,
  index,
}: IPortfolioCombinationWidgetProps) => {
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  const selectedCombination = useRecoilValue(portfolioSelectedCombinationState);
  const [combination, setCombination] = useState<PortfolioCombination | undefined>();
  const [loading, setLoading] = useState(false);
  const [hasQuotes, setHasQuotes] = useState(true);

  useEffect(() => {
    const combination = PortfolioCombinationsOfAnAccount.createCombination(data, undefined, undefined, undefined);
    setCombination(combination);
    setLoading(true);
  }, [data]);

  useEffect(() => {
    if (!isHubInitialized && !loading) {
      return;
    }
    (async () => {
      const symbols = data.symbolsToSubscribe();
      const sentiments = await fetchSentiments(symbols);
      const quotes = await fetchQuotes(symbols);
      if (!quotes || !sentiments) {
        return;
      }
      if (quotes.length == 0) {
        setHasQuotes(false);
      }
      const combination = PortfolioCombinationsOfAnAccount.createCombination(data, quotes, sentiments, quotes);
      setCombination(combination);
    })();
  }, [isHubInitialized, data]);

  const isSelected = () => {
    if (!selectedCombination) {
      return false;
    }
    return selectedCombination.hash === data.hash;
  };

  if (!loading || !combination) {
    return <PortfolioCombinationLoaderWidget />;
  }

  return (
    <OPPortfolioStyledHoverCard
      selected={isSelected()}
      sx={{
        width: '100%',
        background: (theme) => {
          return isSelected()
            ? theme.palette.mode === 'light'
              ? 'rgb(217,217,217,0.3)'
              : 'rgb(17,24,30)'
            : theme.palette.background.paper;
        },
      }}>
      <PortfolioCombinationDetailsWidget data={combination} entity={data} index={index} hasQuotes={hasQuotes} />
      <PortfolioAlertDetailsWidget data={combination} entity={data} />
      <PortfolioNotificationDetailsWidget data={combination} />
    </OPPortfolioStyledHoverCard>
  );
};
