import { Quote } from '.';
import { ICollection } from '..';

export class Quotes implements ICollection<Quote> {
  data: Quote[] = [];

  public constructor(quotes: Quote[]) {
    this.data = [...quotes];
  }

  public static fromSelf = (trades: Quotes): Quotes => {
    if (!trades) {
      throw Error('Quotes is null or undefined');
    }
    const model = new Quotes(trades.data);
    return model;
  };

  public sort = (item: (item1: Quote, item2: Quote) => number): Quotes => {
    const ideas = this.data.sort(item);
    let trades = new Quotes(ideas);
    return trades;
  };
}
