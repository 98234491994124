import { IShardTradesDetailsProps } from './shard-trade-interface';

export class SharedDetails implements IShardTradesDetailsProps {
  timestamp = new Date();
  symbol = '';
  fullName = '';
  shareId = '';

  public static fromJson = (data: SharedDetails): SharedDetails => {
    const trade = new SharedDetails();
    trade.timestamp = data.timestamp;
    trade.symbol = data.symbol;
    trade.fullName = data.fullName;
    trade.shareId = data.shareId;
    return trade;
  };
}
