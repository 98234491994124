import { IEditRequestProps } from '.';

export class EditRequest implements IEditRequestProps {
  list = -1;
  name = '';

  public static fromJson = (data: EditRequest): EditRequest => {
    const model = new EditRequest();
    model.list = data.list;
    model.name = data.name;
    return model;
  };
}
