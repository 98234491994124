export interface IAllowAccessRequestProps {
  type: string;
  isSet: boolean;
}

export class AllowAccessRequest implements IAllowAccessRequestProps {
  type = 'ApplicationAccess';
  isSet = false;

  public static fromJson = (data: AllowAccessRequest): AllowAccessRequest => {
    const model = new AllowAccessRequest();
    model.type = data.type;
    model.isSet = data.isSet;
    return model;
  };
}
