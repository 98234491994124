import React from 'react';
import Grid from '@mui/material/Grid/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Box, useTheme } from '@mui/material';

export const PortfolioManagementSkeletonWidget: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item container xs={12} direction="row" columnSpacing={1} sx={{ px: 0.75 }}>
        {[0, 1, 2].map((value) => (
          <Grid key={value} item xs={4}>
            <Box style={{ border: `1px solid ${theme.palette.grey[500]}`, borderRadius: 4 }}>
              <Skeleton variant="rectangular" height={40} />
              <Skeleton variant="rectangular" height={100} sx={{ mx: 2, my: 1 }} />
              {[0, 1, 2, 3].map((value) => (
                <Box key={value} display="flex" justifyContent="center" sx={{ m: 1 }}>
                  <Skeleton variant="text" width={80} height={20} sx={{ mr: 0.5, ml: 1 }} />
                  <Skeleton variant="text" width={80} height={20} sx={{ mr: 1, ml: 0.5 }} />
                </Box>
              ))}
              <Box display="flex" justifyContent="center" sx={{ m: 1 }}>
                <Skeleton variant="text" width={150} height={20} sx={{ mx: 1, mt: 0.8 }} />
                <Skeleton variant="rounded" width={150} height={30} sx={{ mr: 1, borderRadius: 5 }} />
              </Box>
              <Skeleton variant="text" height={20} sx={{ my: 0.5, mx: 3 }} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} justifyContent="flex-start" alignItems="flex-start">
        {[0, 1, 2, 3].map((value) => (
          <Grid key={value} item xs={3}>
            <Skeleton animation="wave" variant="text" height={25} sx={{ mr: 0.5, ml: 0.5 }} />
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="text" height={50} sx={{ mx: 1 }} />
          <Skeleton animation="wave" variant="rectangular" height={250} sx={{ mx: 1 }} />
        </Grid>
      </Grid>
    </Grid>
  );
};
