import { Grid, IconButton, List, Menu, useTheme } from '@mui/material';
import { ISubscriptionBenefitList } from '@op/shared/src/models';
import { useContext, useState } from 'react';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OptionsPlayDivider, StyledDropdownItemWithBorder } from '../styled';
import { SubscriptionBenefitListMenuProps } from './components';

interface Props {
  benefitList: ISubscriptionBenefitList[];
  isAddOn?: boolean;
}

export const FeatureList: React.FC<Props> = ({ benefitList, isAddOn }) => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const renderFeatureMenu = () => {
    return (
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClick={(e) => {
          e.stopPropagation();
          setAnchor(null);
        }}
        onClose={() => setAnchor(null)}
        PaperProps={SubscriptionBenefitListMenuProps(theme)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <List
          sx={{
            overflow: 'auto',
            maxHeight: 300,
          }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            px={2}
            pb={1}
            mt={-1}
            sx={{
              color: theme.palette.timeFrame.contrastText,
            }}>
            <Grid item>
              <OPBoldTypograpghy>{t('subscriptions.choosePlanList.benefits')}</OPBoldTypograpghy>
            </Grid>
            <Grid item>
              <OPBoldTypograpghy>{benefitList.length}</OPBoldTypograpghy>
            </Grid>
          </Grid>
          <OptionsPlayDivider />
          {benefitList.map((benefit) => {
            return (
              <StyledDropdownItemWithBorder
                sx={{
                  backgroundColor: theme.palette.mode === 'light' ? '#D9D9D9' : 'rgba(153, 153, 153, 0.8)',
                  ':hover': {
                    backgroundColor: theme.palette.mode === 'light' ? '#D9D9D9' : 'rgba(153, 153, 153, 0.8)',
                  },
                }}
                color="default"
                key={benefit.title}>
                {benefit.description}
              </StyledDropdownItemWithBorder>
            );
          })}
        </List>
      </Menu>
    );
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setAnchor(e.currentTarget);
        }}>
        <IconComponent
          name="tsInfo"
          stroke={isAddOn ? theme.palette.primary.dark : theme.palette.timeFrame.contrastText}
          size={isAddOn ? 24 : 20}
        />
      </IconButton>
      {renderFeatureMenu()}
    </>
  );
};
