import { TokenResponse } from '.';
import { Permissions, Role, SubscriptionStatus } from '../enums/enums';
import formatting from '../how/formatting';

export class SecurityModel {
  displayName = '';
  email: '';
  roles: Role[] = [];
  permissions: Permissions[] = [];
  subscriptionStatus: SubscriptionStatus = SubscriptionStatus.ACTIVE;
  isSSO = false;
  isSSOChild = false;
  userID = null;
  deleteDate: string;
  deleteDaysLeft: number;
  deleteRequestDate: string;
  deleteRequested: boolean;

  public static fromData = (tokenResponse: TokenResponse) => {
    const model = new SecurityModel();
    model.displayName = tokenResponse.displayName;
    const subscriptionKey =
      typeof tokenResponse.subscriptionStatus === 'string'
        ? formatting.pascalToSnakeCase(tokenResponse.subscriptionStatus)
        : '';
    model.subscriptionStatus = SubscriptionStatus[subscriptionKey as keyof typeof SubscriptionStatus];
    model.roles =
      tokenResponse.roles
        ?.split(',')
        .map((i) => {
          return Role[i as keyof typeof Role];
        })
        .filter((r) => r !== undefined || r != null) ?? [];
    model.permissions =
      tokenResponse.permissions
        ?.split(',')
        .map((i) => {
          //TODO: Temporary. Make enums key as PascalCasing.
          const pascalToSnakeCaseKey = formatting.pascalToSnakeCase(i);
          return Permissions[pascalToSnakeCaseKey as keyof typeof Permissions];
        })
        .filter((p) => p !== undefined || p != null) ?? [];
    model.isSSO = tokenResponse.isSSO?.toLowerCase() === 'true';
    model.isSSOChild = tokenResponse.isSSOChild?.toLowerCase() === 'true';
    model.userID = tokenResponse.userId;
    model.deleteDate = tokenResponse.deleteDate;
    model.deleteDaysLeft = tokenResponse.deleteDaysLeft;
    model.deleteRequestDate = tokenResponse.deleteRequestDate;
    model.deleteRequested = tokenResponse.deleteRequested?.toLowerCase() === 'true';
    return model;
  };

  public static fromSubscriptionData = (tokenResponse: TokenResponse) => {
    const model = new SecurityModel();
    model.displayName = tokenResponse.displayName;
    const subscriptionKey =
      typeof tokenResponse.subscriptionStatus === 'string'
        ? formatting.pascalToSnakeCase(tokenResponse.subscriptionStatus)
        : '';
    model.subscriptionStatus = SubscriptionStatus[subscriptionKey as keyof typeof SubscriptionStatus];
    model.roles =
      tokenResponse.roles
        ?.split(',')
        .map((i) => {
          return Role[i as keyof typeof Role];
        })
        .filter((r) => r !== undefined || r != null) ?? [];
    model.permissions =
      tokenResponse.permissions
        ?.split(',')
        .map((i) => {
          //TODO: Temporary. Make enums key as PascalCasing.
          const pascalToSnakeCaseKey = formatting.pascalToSnakeCase(i);
          return Permissions[pascalToSnakeCaseKey as keyof typeof Permissions];
        })
        .filter((p) => p !== undefined || p != null) ?? [];
    model.isSSO = false;
    model.isSSOChild = false;
    model.userID = tokenResponse.userId;
    model.deleteDate = tokenResponse.deleteDate;
    model.deleteDaysLeft = tokenResponse.deleteDaysLeft;
    model.deleteRequestDate = tokenResponse.deleteRequestDate;
    model.deleteRequested = tokenResponse.deleteRequested?.toLowerCase() === 'true';
    return model;
  };

  public static fromProfileData = (tokenResponse: TokenResponse) => {
    const model = new SecurityModel();
    model.displayName = tokenResponse.displayName;
    const subscriptionKey =
      typeof tokenResponse.subscriptionStatus === 'string'
        ? formatting.pascalToSnakeCase(tokenResponse.subscriptionStatus)
        : '';
    model.subscriptionStatus = SubscriptionStatus[subscriptionKey as keyof typeof SubscriptionStatus];
    model.roles =
      tokenResponse.roles
        ?.toString()
        ?.split(',')
        .map((i) => {
          return Role[i as keyof typeof Role];
        })
        .filter((r) => r !== undefined || r != null) ?? [];
    model.permissions =
      tokenResponse.permissions
        ?.toString()
        ?.split(',')
        .map((i) => {
          //TODO: Temporary. Make enums key as PascalCasing.
          const pascalToSnakeCaseKey = formatting.pascalToSnakeCase(i);
          return Permissions[pascalToSnakeCaseKey as keyof typeof Permissions];
        })
        .filter((p) => p !== undefined || p != null) ?? [];
    model.isSSO = tokenResponse.isSSO?.toLowerCase() === 'true';
    model.isSSOChild = tokenResponse.isSSOChild?.toLowerCase() === 'true';
    model.userID = tokenResponse.userId;
    model.deleteDate = tokenResponse.deleteDate;
    model.deleteDaysLeft = tokenResponse.deleteDaysLeft;
    model.deleteRequestDate = tokenResponse.deleteRequestDate;
    model.deleteRequested = tokenResponse.deleteRequested?.toLowerCase() === 'true';
    return model;
  };

  static fromJson = (json: any | undefined) => {
    if (!json) {
      return undefined;
    }
    const model = new SecurityModel();
    model.displayName = json.displayName;
    model.subscriptionStatus = json.subscriptionStatus;
    model.roles =
      json.roles?.map((r: string) => Role[r as keyof typeof Role]).filter((r: any) => r !== undefined || r != null) ??
      [];
    model.permissions =
      json.permissions
        ?.map((p: string) => {
          //TODO: Temporary. Make enums key as PascalCasing.
          if (p === null || p === undefined) {
            return;
          }
          const pascalToSnakeCaseKey = formatting.pascalToSnakeCase(p);
          return Permissions[pascalToSnakeCaseKey as keyof typeof Permissions];
        })
        .filter((p: any) => p !== undefined || p != null) ?? [];
    model.isSSO = json.isSSO === 'true';
    model.isSSOChild = json.isSSOChild === 'true';
    model.userID = json.userId;
    model.deleteDate = json.deleteDate;
    model.deleteDaysLeft = json.deleteDaysLeft;
    model.deleteRequestDate = json.deleteRequestDate;
    model.deleteRequested = json.deleteRequested === 'True';
    return model;
  };

  public hasRole = (role: Role) => {
    return this.roles.find((r) => r === role) !== undefined;
  };

  public hasPermission = (permission: Permissions) => {
    return this.permissions.find((p) => p === permission) !== undefined;
  };

  public hasAnySubscriptionStatus = (statuses: SubscriptionStatus[]) => {
    return statuses.includes(this.subscriptionStatus);
  };

  public hasAllPermissions = (permissions: Permissions[]) => {
    const permissionMap = new Map<string, Permissions>();
    for (let permission of this.permissions) {
      permissionMap.set(permission, permission);
    }
    let havingAllPermission = true;
    for (let permission of permissions) {
      if (permissionMap.has(permission)) {
        continue;
      }
      havingAllPermission = false;
    }
    return havingAllPermission;
  };
  public hasAnyPermission = (permissions: Permissions[]) => {
    const permissionMap = new Map<string, Permissions>();
    for (let permission of this.permissions) {
      permissionMap.set(permission, permission);
    }
    for (let permission of permissions) {
      if (permissionMap.has(permission)) {
        return true;
      }
    }
    return false;
  };
}
