import { IFilter, ISort } from './i-filter';
import { SentimentType, SortingType } from '..';

export const DefaultMarketCapValues = ['smallCap', 'mediumCap', 'largeCap', 'megaCap'];
export const DefaultIVRankValues = ['<50', '>=50'];
export const DefaultPremiumValues = ['<35%', '35%-38%', '38%-40%', '>40%'];
export const DefaultRawReturnValues = ['0%-5%', '5%-15%', '15%-30%', '>30%'];
export const DefaultHasEarningsValues = ['Y', 'N'];
export const DefaultLiquidityValues = ['1', '2', '3'];
export const DefaultExchangeValues = ['XSTO', 'XCSE', 'XHEL'];

export class Filters {
  data: IFilter[] = [];
  public constructor(filters: IFilter[]) {
    this.data.push(...filters);
  }

  public static get TradeIdeasDefaultFilters() {
    //TODO : Region wise Filter options needs to be written as bydefault all selection will selected.If we combine will get the zero trade Ideas
    const tradeIdeasDefaultFilters = [
      { name: 'sentiment', value: SentimentType.ALL.toString() },
      { name: 'sector', value: '' },
      { name: 'scan', value: '' },
      { name: 'marketCap', value: DefaultMarketCapValues },
      { name: 'exchange', value: DefaultExchangeValues },
      { name: 'ivRank', value: DefaultIVRankValues },
      { name: 'liquidity', value: DefaultLiquidityValues },
      { name: 'portfolio', value: [] },
      { name: 'watchlist', value: [] },
    ];
    return new Filters(tradeIdeasDefaultFilters);
  }

  //TODO : Add Selected value in each section
  public static get portfolioDefaultFilters() {
    const portfolioDefaultFilters = [
      { name: 'alert', value: '' },
      { name: 'notification', value: '' },
    ];
    return new Filters(portfolioDefaultFilters);
  }
}

export class Sorts {
  data: ISort[] = [];

  public constructor(sorts: ISort[]) {
    this.data.push(...sorts);
  }

  public static get TradeIdeasDefaultSorts() {
    const tradeIdeasDefaultSorts: ISort[] = [
      {
        name: 'symbol',
        title: 'common.labels.symbol',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'companyName',
        title: 'what.watchlists.js.watchlistsGrid.companyName',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'price',
        title: 'what.watchlists.js.watchlistsGrid.price',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'score',
        title: 'what.watchlists.js.watchlistsGrid.strength',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'sentiment',
        title: 'what.watchlists.js.watchlistsGrid.view',
        order: SortingType.ASCENDING.toString(),
      },
    ];

    return new Sorts(tradeIdeasDefaultSorts);
  }

  public static get WatchListsDefaultSorts() {
    const watchListDefaultSorts: ISort[] = [
      {
        name: 'companyName',
        title: 'common.labels.symbol',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'price',
        title: 'what.watchlists.js.watchlistsGrid.price',
        order: SortingType.ASCENDING.toString(),
      },
      // In new app name changed to strength
      {
        name: 'score',
        title: 'what.watchlists.js.watchlistsGrid.strength',
        order: SortingType.ASCENDING.toString(),
      },
      // {
      //   name: 'sentiment',
      //   title: 'what.watchlists.js.watchlistsGrid.view',
      //   order: SortingType.ASCENDING.toString(),
      // },
    ];

    return new Sorts(watchListDefaultSorts);
  }

  public static get PortfolioDefaultSorts() {
    const portfolioDefaultSorts: ISort[] = [
      { name: 'companyName', title: 'common.labels.symbol', order: SortingType.ASCENDING.toString() },
      {
        name: 'expiryDate',
        title: 'portfolio.expiryDate',
        order: SortingType.ASCENDING.toString(),
      },
    ];

    return new Sorts(portfolioDefaultSorts);
  }

  public static get CreditSpreadDefaultSorts() {
    const creditSpreadDefaultSorts: ISort[] = [
      {
        name: 'premiumPercentage',
        title: 'Premium vs Width',
        order: SortingType.DESCENDING.toString(),
      },
      {
        name: 'symbol',
        title: 'common.labels.symbol',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'companyName',
        title: 'what.watchlists.js.watchlistsGrid.companyName',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'ivRank',
        title: 'IV Rank',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'earningDate',
        title: 'Earnings Date',
        order: SortingType.ASCENDING.toString(),
      },
    ];

    return new Sorts(creditSpreadDefaultSorts);
  }

  public static get CoveredCallDefaultSorts() {
    const coveredCallDefaultSorts: ISort[] = [
      {
        name: 'rawReturn',
        title: 'Yield',
        order: SortingType.DESCENDING.toString(),
      },
      {
        name: 'symbol',
        title: 'common.labels.symbol',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'companyName',
        title: 'what.watchlists.js.watchlistsGrid.companyName',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'premium',
        title: 'Premium',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'ivRank',
        title: 'IV Rank',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'earningDate',
        title: 'Earnings Date',
        order: SortingType.ASCENDING.toString(),
      },
    ];
    return new Sorts(coveredCallDefaultSorts);
  }

  public static get ShortPutDefaultSorts() {
    const shortPutDefaultSorts: ISort[] = [
      {
        name: 'rawReturn',
        title: 'Yield',
        order: SortingType.DESCENDING.toString(),
      },
      {
        name: 'symbol',
        title: 'common.labels.symbol',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'companyName',
        title: 'what.watchlists.js.watchlistsGrid.companyName',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'premium',
        title: 'Premium',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'ivRank',
        title: 'IV Rank',
        order: SortingType.ASCENDING.toString(),
      },
      {
        name: 'earningDate',
        title: 'Earnings Date',
        order: SortingType.ASCENDING.toString(),
      },
    ];

    return new Sorts(shortPutDefaultSorts);
  }
}
