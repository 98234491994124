import { Grid, Typography, styled } from '@mui/material';
import { helpPinsState } from '@op/shared/src/states';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useRecoilValue } from 'recoil';
import guideFlag from '../images/flag.png';

export const GuideDiv = styled('div')(({ theme }) => ({
  '.sentiment-name.bullish': {
    color: theme.palette.success.main,
  },
  '.sentiment-name.bearish': {
    color: theme.palette.error.main,
  },
  '.sentiment-name.neutral': {
    color: theme.palette.warning.main,
  },
}));

interface IStep {
  target: string;
  content: string;
  title: string;
  spotlightPadding: number;
  disabledBeacon: boolean;
}

interface Props {
  selector: string;
  canShow?: boolean;
}

export const GuideItem: React.FC<Props> = ({ selector, canShow = true }: Props) => {
  const data = useRecoilValue(helpPinsState(selector));

  if (!data || !data.isShowing || !canShow) {
    return null;
  }

  const helpPin: IStep = {
    target: data.helpPin.selector,
    content: data.helpPin.content,
    title: data.helpPin.title,
    spotlightPadding: 3,
    disabledBeacon: true,
  };

  return (
    <>
      <a
        data-tooltip-id={helpPin.target}
        data-tooltip-content={helpPin.content}
        data-some-relevant-attr={helpPin.title}>
        <img
          src={guideFlag}
          className="guideFlag"
          data-tooltip-id={helpPin.target.replace('.', '')}
          data-tooltip-content=""
          style={{
            position: 'absolute',
            zIndex: 98,
            cursor: 'pointer',
            animationDelay: '200ms',
            animation: 'bounce 0.5s ease 0s 1 normal both',
          }}
        />
      </a>
      <Tooltip
        id={helpPin.target}
        place="bottom"
        render={({ content, activeAnchor }) => (
          <Grid sx={{ p: 0.5 }}>
            <Typography variant="h6">{activeAnchor?.getAttribute('data-some-relevant-attr')}</Typography>
            <Typography variant="body1" sx={{ py: 0.5 }}>
              {<GuideDiv dangerouslySetInnerHTML={{ __html: content }}></GuideDiv>}
            </Typography>
          </Grid>
        )}
        style={{
          minWidth: '290px',
          maxWidth: '420px',
          zIndex: 9999,
          position: 'fixed',
          textAlign: 'left',
        }}
      />
    </>
  );
};
