import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { tsAccountBalances, tsEnvironmentState, tsSelectedAccountBalanceState, tsSelectedAccountIdsState } from '..';
import { SummaryTypography } from '../components/ts-typography';
import {
  IConfirmOrderTicket,
  ILimitOrderRequest,
  IMarketOrderRequest,
  IStopLimitOrderRequest,
  IStopOrderRequest,
} from '../models';
import { IOrderTicketPosition, absQuantity, bidMidAskfromSnapShot } from '../models/ts-helpers';
import { getAccountBalances, getQuoteSnapshots } from '../services';
import { tsBidMidAskValueState, tsTradeOrderPropsState } from '../states/ts-ticket-states';

interface Props {
  tsOrderTicketPositions: IOrderTicketPosition[];
  orderRequest: IMarketOrderRequest | ILimitOrderRequest | IStopOrderRequest | IStopLimitOrderRequest;
  confirmOrderTicketData: IConfirmOrderTicket;
}

export const OrderSummaryWidget: React.FC<Props> = ({
  tsOrderTicketPositions,
  orderRequest,
  confirmOrderTicketData,
}: Props) => {
  const theme = useTheme();
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const accountBalances = useRecoilValue(tsSelectedAccountBalanceState);
  const [bidMidAskValue, setBidMidAskValue] = useRecoilState(tsBidMidAskValueState);
  const setAccountBalances = useSetRecoilState(tsAccountBalances);
  const tsTradeProps = useRecoilValue(tsTradeOrderPropsState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [isFetchingBalances, setIsFetchingBalances] = useState(false);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0 || isFetchingBalances) {
      return;
    }
    setIsFetchingBalances(true);
    (async () => {
      const response = await getAccountBalances(tsEnv, selectedAccountIds);
      if (response.hasErrors) {
        return;
      }
      setAccountBalances(response.data.balances);
      setIsFetchingBalances(false);
    })();
  }, [selectedAccountIds, accountBalances]);

  useEffect(() => {
    if (tsTradeProps[0].action !== 'closeAtMarket') {
      return;
    }
    (async () => {
      setBidMidAskValue(undefined);
      const symbols: string[] = tsOrderTicketPositions.map((p) => p.symbol);
      const response = await getQuoteSnapshots(tsEnv, symbols);
      if (response.hasErrors) {
        setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
        return;
      }
      if (response.hasDataErrors) {
        if (response.data.errors && response.data.errors.length > 0) {
          setNotifications([
            {
              type: 'error',
              content: response.data.errors[0]?.error || response.data.errors[0]?.message || '',
              isTradeStation: true,
            },
          ]);
          return;
        }
      }
      if (!response.data.quotes) {
        return;
      }
      const bidMidAskValues = bidMidAskfromSnapShot(response.data, tsOrderTicketPositions);
      setBidMidAskValue(bidMidAskValues);
    })();
  }, [tsTradeProps]);

  if (!confirmOrderTicketData) {
    return;
  }

  const getSelectedAccount = () => {
    if (!accountBalances) {
      return undefined;
    }
    const id = confirmOrderTicketData.confirmations[0].accountID;
    const selectedAccount = accountBalances.find((a) => a.accountID === id);
    if (!selectedAccount) {
      return undefined;
    }
    return selectedAccount;
  };

  const getNetTransactionType = () => {
    const isCredited = Number(confirmOrderTicketData.confirmations[0].debitCreditEstimatedCost) < 0;
    return {
      debitOrCredit: isCredited ? 'NET CREDIT' : 'NET DEBIT',
      color: isCredited ? theme.palette.success.main : theme.palette.error.main,
    };
  };

  const renderBidMidAsk = () => {
    if (!bidMidAskValue) {
      return (
        <>
          <Grid
            item
            xs={3.5}
            md={2}
            lg={2}
            xl={1.5}
            sx={{ my: 1, px: 1, borderRight: `1px dotted ${theme.palette.divider}`, justifyContent: 'center' }}>
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={25} />
          </Grid>
          <Grid
            item
            xs={3.5}
            md={2}
            lg={2}
            xl={1.5}
            sx={{ my: 1, px: 1, borderRight: `1px dotted ${theme.palette.divider}`, justifyContent: 'center' }}>
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={25} />
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="ASK" value={NumberFormatHelper.toCurrency(bidMidAskValue.ask)} />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="BID" value={NumberFormatHelper.toCurrency(bidMidAskValue.bid)} />
        </Grid>
      </>
    );
  };

  const renderBuyingPower = () => {
    const selectedAccount = getSelectedAccount();
    if (!selectedAccount) {
      return (
        <Grid container sx={{ my: 1, borderRight: `1px dotted ${theme.palette.divider}`, justifyContent: 'center' }}>
          <Skeleton animation="wave" variant="rectangular" width={'80%'} height={30} />
        </Grid>
      );
    }
    const buyingPower = Number(selectedAccount.buyingPower);
    const color = buyingPower < 0 ? theme.palette.error.main : theme.palette.success.main;
    return (
      <SummaryTypography
        title="BUYING POWER"
        value={`${NumberFormatHelper.toCurrency(buyingPower)}`}
        sx={{ color: color }}
      />
    );
  };

  const renderOverNightBuyingPower = () => {
    const selectedAccount = getSelectedAccount();
    if (!selectedAccount || !selectedAccount.balanceDetail) {
      return (
        <Grid container sx={{ my: 1, borderRight: `1px dotted ${theme.palette.divider}`, justifyContent: 'center' }}>
          <Skeleton animation="wave" variant="rectangular" width={'80%'} height={30} />
        </Grid>
      );
    }
    const overnightBuyingPower = Number(selectedAccount.balanceDetail?.overnightBuyingPower);
    const color = overnightBuyingPower < 0 ? theme.palette.error.main : theme.palette.success.main;
    return (
      <SummaryTypography
        title="OVERNIGHT BUYING POWER"
        value={`${NumberFormatHelper.toCurrency(overnightBuyingPower)}`}
        sx={{ color: color }}
      />
    );
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ backgroundColor: theme.palette.header.default, borderRadius: 1, boxShadow: 1, padding: 0.5 }}>
      <Grid item xs={12} marginX={1} marginY={0.5}>
        <Typography
          variant="subHeader"
          sx={{
            fontWeight: 'bold',
          }}>{`ORDER SUMMARY | ${confirmOrderTicketData.confirmations[0].summaryMessage}`}</Typography>
      </Grid>
      <Grid item container xs={12} rowSpacing={1}>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="SYMBOL" value={tsOrderTicketPositions[0].underlyingSymbol} />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="QUANTITY" value={absQuantity(tsOrderTicketPositions)} />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="ROUTING" value={confirmOrderTicketData.confirmations[0].route} />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="DURATION" value={confirmOrderTicketData.confirmations[0].timeInForce.duration} />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography
            title="ESTIMATED PRICE"
            value={NumberFormatHelper.toCurrency(confirmOrderTicketData.confirmations[0].estimatedPrice)}
          />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography
            borderNone
            titleColor={getNetTransactionType().color}
            title={getNetTransactionType().debitOrCredit}
            value={NumberFormatHelper.toCurrency(
              Math.abs(Number(confirmOrderTicketData.confirmations[0].debitCreditEstimatedCost)),
            )}
          />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography
            title="ESTIMATED COMMISSION"
            value={NumberFormatHelper.toCurrency(confirmOrderTicketData.confirmations[0].estimatedCommission)}
          />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography
            title="TOTAL COST"
            value={NumberFormatHelper.toCurrency(
              Number(confirmOrderTicketData.confirmations[0].estimatedCommission) +
                Number(confirmOrderTicketData.confirmations[0].estimatedCost),
            )}
          />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography title="TYPE" value={orderRequest.orderType} />
        </Grid>
        {renderBidMidAsk()}
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          <SummaryTypography borderNone title="ACCOUNT" value={confirmOrderTicketData.confirmations[0].accountID} />
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          {renderBuyingPower()}
        </Grid>
        <Grid item xs={3.5} md={2} lg={2} xl={1.5}>
          {renderOverNightBuyingPower()}
        </Grid>
      </Grid>
    </Grid>
  );
};
