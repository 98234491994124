import { IMarketData, IApplicationPreferences, ICoveredCall, IAlerts, CoveredCall, ApplicationPreferences } from '.';
import { IRiskAndInvestment, RiskAndInvestment } from './risk-and-investment';

export interface IApplicationConfiguration {
  marketData: IMarketData;
  applicationPreferences: IApplicationPreferences;
  coveredCall: ICoveredCall;
  alerts: IAlerts;
  riskAndInvestment: IRiskAndInvestment;
}

export class ApplicationConfiguration implements IApplicationConfiguration {
  marketData!: IMarketData;
  applicationPreferences!: IApplicationPreferences;
  coveredCall!: ICoveredCall;
  alerts!: IAlerts;
  riskAndInvestment!: IRiskAndInvestment;

  static fromSelf = (self: IApplicationConfiguration) => {
    const clone = new ApplicationConfiguration();
    clone.marketData = self.marketData;
    clone.applicationPreferences = ApplicationPreferences.fromSelf(self.applicationPreferences);
    clone.coveredCall = CoveredCall.fromSelf(self.coveredCall);
    clone.alerts = self.alerts;
    clone.riskAndInvestment = RiskAndInvestment.fromSelf(self.riskAndInvestment);
    return clone;
  };
}
