import React from 'react';
import { withTranslation } from 'react-i18next';
import './i18n';
import ILocalizationContext from './localization-context';
interface IProps {
  children: React.ReactNode;
  t: never;
  i18n: never;
}
const LocalizationProvider: React.FC<IProps> = ({ children, t, i18n }: IProps) => {
  return <ILocalizationContext.Provider value={{ t, i18n }}>{children}</ILocalizationContext.Provider>;
};

export default withTranslation()(LocalizationProvider);
