import formatting from '@op/shared/src/models/how/formatting';
import { atom, selector } from 'recoil';
import { CachedSecurities } from '../models';
import HowDataModel from '../models/how/how-data-model';
import { WhyEntity } from '../models/why';
import { ErrorResponse } from '../view-models/responses/error-response';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { getHow, getHowAnonymous, getWhy, getWhyAnonymous, ranks, ranksAnonymous } from './../services';

export const isDataLoadingState = atom({
  key: 'isLoadingStateKey',
  default: false,
});

export const previousLocationState = atom<string | undefined>({
  key: 'previousLocationStateKey',
  default: undefined,
});

export const cachedSecuritiesDataState = atom<CachedSecurities | undefined>({
  key: 'cachedSecuritiesDataStateKey',
  default: undefined,
  dangerouslyAllowMutability: true,
});

/*Dedicated state for how, why and ranks data.
 * These are very critical data points and always be sync, meaning, all data for same symbol.
 * Hence, having dedicated state for it.
 */
export const isHowWhyRanksDataLoading = atom({
  key: 'isHowWhyRanksDataLoadingStateKey',
  default: false,
});

export const isSymbolNotSupportedState = atom({
  key: 'isSymbolNotSupportedStateKey',
  default: false,
});

export const howWhyRanksDataState = atom<{
  symbol: string | undefined;
  how: HowDataModel | undefined;
  why: WhyEntity | undefined;
  ranks: { date: string; name: string; symbol: string; value: number }[] | undefined;
}>({
  key: 'howWhyRanksDataStateKey',
  default: { symbol: undefined, how: undefined, why: undefined, ranks: undefined },
  effects: [
    ({ onSet }: any) => {
      onSet((newData: any) => {
        if (!newData.symbol || newData.symbol.trim() === '' || !window || !window.localStorage) {
          return;
        }
        // if (window) {
        //   if (ApplicationContext.configuration && ApplicationContext.configuration.isEmbeddingPlatform) {
        //     window.localStorage.setItem('embedSelectedSymbolStateKey', newData.symbol.toUpperCase());
        //   } else {
        //     // window.localStorage.setItem('selectedSymbolStateKey', newData.symbol.toUpperCase());
        //   }
        // }
      });
    },
  ],
});

export const howDataState = selector<HowDataModel | undefined>({
  key: 'howDataStateKey',
  get: ({ get }) => {
    const data = get(howWhyRanksDataState);
    return data.how;
  },
});

export const whyDataState = selector<WhyEntity | undefined>({
  key: 'whyDataStateKey',
  get: ({ get }) => {
    const data = get(howWhyRanksDataState);
    return data.why;
  },
});

export const ranksDataState = selector<{ date: string; name: string; symbol: string; value: number }[] | undefined>({
  key: 'ranksDataStateKey',
  get: ({ get }) => {
    const data = get(howWhyRanksDataState);
    return data.ranks;
  },
});

export const strategyShareDataState = atom<any | undefined>({
  key: 'strategyShareStateKey',
  default: undefined,
});

export const fetchHowData = async (
  symbol: string | undefined,
  ruleMatch: string | undefined,
  isAnonymous?: boolean,
  shareId?: string,
): Promise<ResponseViewModel<{ howData: HowDataModel }>> => {
  let result = new ResponseViewModel<{ howData: HowDataModel }>();
  if (!symbol || symbol.trim() === '' || (isAnonymous && !shareId)) {
    result.errors = [ErrorResponse.fromMessage('Quote not found')];
    return result;
  }
  /**
   * Inorder to not send the formatted symbol to create a how data model
   * Created a local variable to format and pass it as payload in how api.
   */
  let formattedSymbol = formatting.replaceSlashtoUnderscore(symbol);
  const response = isAnonymous
    ? await getHowAnonymous(formattedSymbol.toUpperCase(), shareId as string)
    : await getHow(formattedSymbol.toUpperCase(), ruleMatch, shareId);
  if (response.hasErrors || !response.data) {
    result.errors = response.errors;
    return result;
  }
  result.data = {
    howData: new HowDataModel(response.data, symbol.toUpperCase()),
  };
  return result;
};

export const fetchWhyData = async (
  symbol: string | undefined,
  ruleMatch: string | undefined,
  isAnonymous?: boolean,
  shareId?: string,
): Promise<ResponseViewModel<WhyEntity>> => {
  let result = new ResponseViewModel<WhyEntity>();
  if (!symbol || symbol.trim() === '' || (isAnonymous && !shareId)) {
    result.errors = [ErrorResponse.fromMessage('Quote not found')];
    return result;
  }
  symbol = formatting.replaceSlashtoUnderscore(symbol);
  let whyResponse = isAnonymous
    ? await getWhyAnonymous(symbol.toUpperCase(), shareId as string)
    : await getWhy(symbol.toUpperCase(), shareId);
  if (whyResponse.hasErrors || !whyResponse.data) {
    result.errors = whyResponse.errors;
    return result;
  }
  result.data = whyResponse.data;
  return result;
};

export const fetchRankData = async (
  symbol: string | undefined,
  isAnonymous?: boolean,
  shareId?: string,
): Promise<ResponseViewModel<{ date: string; name: string; symbol: string; value: number }[]>> => {
  let result = new ResponseViewModel<{ date: string; name: string; symbol: string; value: number }[]>();
  if (!symbol || symbol.trim() === '' || (isAnonymous && !shareId)) {
    result.errors = [ErrorResponse.fromMessage('Quote not found')];
    return result;
  }
  symbol = formatting.replaceSlashtoUnderscore(symbol);
  const ranksResponse = isAnonymous
    ? await ranksAnonymous(symbol.toUpperCase(), shareId as string)
    : await ranks(symbol.toUpperCase(), shareId);
  if (ranksResponse.hasErrors || !ranksResponse.data) {
    result.errors = ranksResponse.errors;
    return result;
  }
  result.data = ranksResponse.data;
  return result;
};
