import { Theme, useTheme } from '@mui/material';
import { Combination, IDataProps } from '@op/shared/src/models';
import BasicCombination from '@op/shared/src/models/how/basic-combination';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  customizationState,
  howDataState,
  isOnGoesToThisPriceChangedAtom,
  subViewState,
  tradingRangeSimulatorState,
  viewState,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

export interface IPlChartProps extends IDataProps<BasicCombination> {
  height?: string;
  width?: number | null;
  showAxis: boolean;
}

export const PlChartWidget: React.FC<IPlChartProps> = ({ data, showAxis, height, width }: IPlChartProps) => {
  const howData = useRecoilValue(howDataState);
  const whatIfSimulator = useRecoilValue(whatIfSimulatorState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const isPriceChanged = useRecoilValue(isOnGoesToThisPriceChangedAtom);
  const view = useRecoilValue(viewState);
  const subView = useRecoilValue(subViewState);
  const theme = useTheme<Theme>();
  const [isReady, setIsReady] = useState(false);
  const [stockPriceRange, setStockPriceRange] = useState([0, 100]);
  const customization = useRecoilValue(customizationState);

  useEffect(() => {
    /*!isReady is a hack. It give the layout to render first.
     * Then chart renders with proper boundingbox.
     */
    setIsReady(true);
    if (!tradingRangeSimulator) {
      return;
    }
    const priceRange = [tradingRangeSimulator.chartLowBound(), tradingRangeSimulator.chartHighBound()];
    setStockPriceRange(priceRange);
    return () => setIsReady(false);
  }, [tradingRangeSimulator]);

  if (!tradingRangeSimulator || !howData) {
    return null;
  }
  const outlookPercentBounds = tradingRangeSimulator.outlookPercentBoundsForPlChart(howData);
  const outlookPriceBounds = tradingRangeSimulator.outlookPriceBoundsForPlChart();

  if (!data) {
    return <HighchartsReact highcharts={Highcharts} />;
  }
  const combination = data;
  const calculateChartPoints = (whatifVol: number | undefined, noOfPoints: number | undefined) => {
    let limits = stockPriceRange;
    let low = limits[0];
    let high = limits[1];
    noOfPoints = noOfPoints || 100;
    let interval = (high - low) / noOfPoints;
    let strikes = combination.strikes().filter((strike) => low <= strike && strike <= high);
    strikes.push(low);
    strikes.push(high);
    strikes.sort((a, b) => a - b);

    let terminalPayoffPoints: number[][] = [];
    let whatIfPayoffPoints: number[][] = [];
    let expiryDate = combination.expiry();

    let whatIfVolatility = whatifVol;
    let whatIfDateTime: Date | undefined = DateTimeHelper.getCurrentDateTime();
    let hasMoreThanOneExpiry = combination.expiries().length > 1;
    if (hasMoreThanOneExpiry) {
      whatIfDateTime = expiryDate;
    }

    for (let i = 1; i < strikes.length; i++) {
      let highSpot = strikes[i];
      let lowSpot = strikes[i - 1] || 0;

      let lowPayoff = combination.payoff(lowSpot, expiryDate);
      let highPayoff = combination.payoff(highSpot, expiryDate);

      let k = (interval * (highPayoff - lowPayoff)) / (highSpot - lowSpot);
      let j = 0;
      let x: number;
      while ((x = lowSpot + interval * j) < highSpot) {
        let y = lowPayoff + k * j;
        terminalPayoffPoints.push([x, y]);
        j++;
        const payOff1 = combination.payoff(x, whatIfDateTime, whatIfVolatility);
        whatIfPayoffPoints.push([x, payOff1]);
      }
      terminalPayoffPoints.push([highSpot, highPayoff]);
      const payOff2 = combination.payoff(highSpot, whatIfDateTime, whatIfVolatility);
      whatIfPayoffPoints.push([highSpot, payOff2]);
    }
    if (hasMoreThanOneExpiry) {
      terminalPayoffPoints = whatIfPayoffPoints;
      whatIfPayoffPoints = [];
    }

    return {
      terminalPayoffPoints: terminalPayoffPoints,
      whatIfPayoffPoints: whatIfPayoffPoints,
      whatIfDateTime: whatIfDateTime,
      whatIfVolatility: whatIfVolatility,
    };
  };

  function generateColorFromData(data: Combination) {
    if (whatIfSimulator && whatIfSimulator.whatifTheoretical && data) {
      const whatIfTheoretical = whatIfSimulator.whatifTheoretical(data);
      if (whatIfTheoretical.payoffValue > 0) {
        return 'green';
      } else if (whatIfTheoretical.payoffValue < 0) {
        return theme.palette.error.main;
      }
    }
    return '#555';
  }

  function getProfitAndLossRangeFill() {
    const lowest = stockPriceRange[0];
    const highest = stockPriceRange[1];
    let lower = lowest;
    let higher = highest;
    //Where there is no option chain listed for symbol, there will be no standard Deviations(sds) data.
    //Legacy code relies on expandedQuote hasOption for this.
    if (tradingRangeSimulator && tradingRangeSimulator.sds.length > 0) {
      const outlookPriceBoundsByExpiration = combination.outlookPriceBoundsByExpiration(
        outlookPercentBounds,
        outlookPriceBounds,
      );
      lower = outlookPriceBoundsByExpiration[0];
      higher = outlookPriceBoundsByExpiration[1];
    }
    const width = highest - lowest;
    let lowerStop = 0;
    let higherStop = 0;
    if (width) {
      lowerStop = (lower - lowest) / width;
      higherStop = (higher - lowest) / width;
    }
    const profitFillColor: Highcharts.GradientColorObject = {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
      stops: [
        [0, 'rgba(200, 200, 200, 0)'],
        [lowerStop, 'rgba(200, 200, 200, 50)'],
        [lowerStop, theme.palette.success.main],
        [higherStop, theme.palette.success.main],
        [higherStop, 'rgba(200, 200, 200, 50)'],
        [1, 'rgba(200, 200, 200, 0)'],
      ],
    };
    const lossFillColor: Highcharts.GradientColorObject = {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
      stops: [
        [0, 'rgba(200, 200, 200, 0)'],
        [lowerStop, 'rgba(200, 200, 200, 50)'],
        [lowerStop, theme.palette.error.main],
        [higherStop, theme.palette.error.main],
        [higherStop, 'rgba(200, 200, 200, 50)'],
        [1, 'rgba(200, 200, 200, 0)'],
      ],
    };
    return {
      profitFillColor: profitFillColor,
      lossFillColor: lossFillColor,
    };
  }

  const getHeightInExpandedView = () => {
    if (subView !== 'expand') {
      return undefined;
    }
    return '220px';
  };

  const getHeightInTradeTab = () => {
    if (view !== 'trade') {
      return undefined;
    }
    if (subView === 'expand') {
      return getHeightInExpandedView();
    }
    if (customization && customization.isEmbeddingPlatform) {
      return '95px';
    }
    return '130px';
  };

  const getHeightInIncomeTab = () => {
    if (view !== 'income') {
      return undefined;
    }
    if (subView === 'expand') {
      return getHeightInExpandedView();
    }
    return '210px';
  };

  const getHeightInPorfolioTab = () => {
    if (view !== 'portfolioManagement') {
      return undefined;
    }
    if (subView === 'expand') {
      return getHeightInExpandedView();
    }
    if (customization && customization.isEmbeddingPlatform) {
      return '105px';
    }
    return '130px';
  };

  const getHeight = () => {
    if (view === 'trade') {
      return getHeightInTradeTab();
    }
    if (view === 'income') {
      return getHeightInIncomeTab();
    }
    if (view === 'portfolioManagement') {
      return getHeightInPorfolioTab();
    }
  };

  function getChartOptions(isExpanded: boolean, whatifVol?: number, noOfPoints?: number) {
    const showLabels = !!isExpanded;
    const enableTooltips = !!isExpanded;
    const chartPoints = calculateChartPoints(whatifVol, noOfPoints);
    const terminalPLs = chartPoints.terminalPayoffPoints;
    const whatIfPLs = chartPoints.whatIfPayoffPoints;
    const underlyingLast = combination.quote.last;
    const whatIfDate = DateTimeHelper.format(chartPoints.whatIfDateTime);
    const plRangeFill = getProfitAndLossRangeFill();
    let { profitFillColor, lossFillColor } = plRangeFill;
    const chartOptions: Highcharts.Options = {
      chart: {
        alignTicks: true,
        reflow: true,
        animation: true,
        // borderWidth: 1,
        // borderColor: theme.palette.info.dark,
        height: getHeight(),
        // width:getWidth(),
        backgroundColor: theme.palette.background.paper,
        plotBorderColor: theme.palette.secondary.dark,
        plotBorderWidth: 1,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
          },
          shadow: false,
          states: {
            hover: {
              lineWidth: 2,
            },
          },
          threshold: 0,
        },
        line: {
          shadow: false,
        },
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      xAxis: [
        {
          plotLines: [
            {
              color: '#555',
              width: 1.5,
              value: underlyingLast,
            },
            isPriceChanged
              ? {
                  color: generateColorFromData(combination as Combination),
                  width: 1.5,
                  value: whatIfSimulator?.whatIfSPrice || underlyingLast,
                }
              : {},
          ],

          labels: {
            style: { color: theme.palette.text.secondary },
            enabled: showAxis,
          },
        },
      ],
      yAxis: [
        {
          title: {
            text: null,
          },
          labels: {
            style: { color: theme.palette.text.secondary },
            align: 'left',
            enabled: showAxis,
            formatter: function () {
              return NumberFormatHelper.toFractionalString(this.value);
            },
            x: 0,
            y: -2,
          },
          showFirstLabel: true,
          plotLines: [
            {
              color: '#555', //themeOptions.plotLinesColor || '#555',
              width: 2,
              value: 0,
              zIndex: 1,
            },
          ],
          maxPadding: 0.1,
          minPadding: 0.1,
          endOnTick: false,
          startOnTick: false,
          gridLineWidth: showLabels ? 1 : 0,
          //tickPositioner: doubleDensityOfTicksIfNeeded,
        },
      ],
      tooltip: {
        backgroundColor: theme.palette.highChart.main,
        borderColor: theme.palette.highChart.dark,
        borderRadius: 8,
        borderWidth: 1,
        padding: 0.1,
        style: {
          color: theme.palette.highChart.contrastText,
        },
        // crosshairs: true,
        enabled: enableTooltips,
        formatter: function () {
          if (!this.points) {
            return undefined;
          }
          let s = `Stock Price: <b>${NumberFormatHelper.toCurrency(this.x)}</b><br>`;
          s += `P/L on expiration: <span style="color:${
            Math.sign(this.points[0].y || 0) === 1 ? theme.palette.success.main : theme.palette.error.main
          }"> ${NumberFormatHelper.toCurrency(this.points[0].y)} </span><br>`;
          if (this.points && this.points[1]) {
            s += `P/L on ${whatIfDate}: <span style="color:${
              Math.sign(this.points[0].y || 0) === 1 ? theme.palette.success.main : theme.palette.error.main
            }"> ${NumberFormatHelper.toCurrency(this.points[1].y)} </span><br>`;
          }
          return s;
        },
        shared: true,
      },
      series: [
        {
          type: 'area',
          name: 'Terminal PL',
          data: terminalPLs,
          zIndex: 50,
          marker: {
            fillColor: theme.palette.highChart.dark,
            lineColor: theme.palette.highChart.light,
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          lineColor: '#0033CC',
          lineWidth: 1,
          showInLegend: false,
          fillColor: profitFillColor,
          negativeFillColor: lossFillColor,
          animation: false,
        },
        {
          type: 'line',
          name: 'What-If PL',
          data: whatIfPLs,
          color: theme.palette.text.disabled,
          zIndex: 95,
          marker: {
            fillColor: theme.palette.highChart.dark,
            lineColor: theme.palette.highChart.light,
            enabled: false,
          },
          lineWidth: 1.5,
          showInLegend: false,
          animation: false,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    return chartOptions;
  }

  if (!isReady) {
    return null;
  }

  return <HighchartsReact highcharts={Highcharts} options={getChartOptions(true)} />;
};
