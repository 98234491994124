import CachedIcon from '@mui/icons-material/Cached';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import { Grid } from '@mui/material';
import { Sorts } from '@op/shared/src/models';
import { Permissions } from '@op/shared/src/models/enums/enums';
import {
  accountState,
  customizationState,
  isPortfolioCombinationsLoadingState,
  portfoliosDataState,
  sortsPortfolioState,
  portfolioAccountsWithoutCombinationsState,
  portfolioBrokerAccountsState,
} from '@op/shared/src/states';
import React from 'react';
import { PortfolioPositionsService } from '@op/shared/src/services';
import { PortfolioType } from '@op/shared/src/models/enums/enums';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { PortfolioAccountsFilterWidget, PortfolioAlertsCountWidget } from '.';
import { OPIconButton } from '../styled';
import { SortingWidget } from '../common/sorting-widget';
import { PortfolioNotificationsCountWidget } from './portfolio-notifications-count-widget';
import { debounce } from 'lodash';

export const PortfolioControlsWidget: React.FC = () => {
  const account = useRecoilValue(accountState);
  const [portfolioSorts, setPortfolioSorts] = useRecoilState(sortsPortfolioState);
  const resetPortfoliosData = useResetRecoilState(portfoliosDataState);
  const isPortfolioCombinationsLoading = useRecoilValue(isPortfolioCombinationsLoadingState);
  const navigate = useNavigate();
  const portfolioWithoutCombinations = useRecoilValue(portfolioAccountsWithoutCombinationsState);
  const customization = useRecoilValue(customizationState);
  const [portfolioBrokerAccounts, setPortfolioBrokerAccounts] = useRecoilState(portfolioBrokerAccountsState);

  if (!account || !account.securityModel || !customization) {
    return null;
  }
  const canManageOwnPortfolio = account.hasPermission(Permissions.MANAGE_OWN_PORTFOLIO);

  const onSort = (sorts: any) => {
    setPortfolioSorts(sorts);
  };

  const navigateToManagePortfolio = () => {
    navigate('/profile/portfolio');
  };

  const renderManagePortfolioButton = () => {
    if (!canManageOwnPortfolio) {
      return;
    }
    return (
      <OPIconButton id="manage-portfolio" onClick={() => navigateToManagePortfolio()}>
        <WorkHistoryOutlinedIcon />
      </OPIconButton>
    );
  };

  const refreshPortfolios = async () => {
    if (!portfolioWithoutCombinations || portfolioWithoutCombinations.length === 0) {
      resetPortfoliosData();
      return;
    }
    let hasBrokerAccounts: boolean = portfolioWithoutCombinations.some(
      (a) => a.portfolioTypeStatus == PortfolioType.TDAmeritrade,
    );
    if (hasBrokerAccounts) {
      const accResponse = await PortfolioPositionsService.instance.getBrokerAccounts(true);
      if (!accResponse.hasErrors && accResponse.data) {
        setPortfolioBrokerAccounts(accResponse.data);
      }
    }
    resetPortfoliosData();
    return;
  };

  const debouncedRefreshPortfolios = debounce(refreshPortfolios, 1000);

  return (
    <>
      <Grid container columnSpacing={1} px={2}>
        <Grid item xs={4}>
          <PortfolioAccountsFilterWidget />
        </Grid>
        <Grid item xs={3}>
          <SortingWidget
            onSort={onSort}
            selectedSorts={portfolioSorts}
            defaultSortOptions={Sorts.PortfolioDefaultSorts}
            isLoading={isPortfolioCombinationsLoading}
          />
        </Grid>
        <Grid item container xs={5} columnSpacing={1}>
          <Grid item xs={'auto'}>
            <PortfolioAlertsCountWidget />
          </Grid>
          <Grid item xs={'auto'}>
            <PortfolioNotificationsCountWidget />
          </Grid>
          {!customization.isEmbeddingPlatform && (
            <Grid item xs={'auto'}>
              {renderManagePortfolioButton()}
            </Grid>
          )}
          <Grid item xs={'auto'}>
            <OPIconButton id="js-porfolio-accounts-refresh-icon" onClick={() => debouncedRefreshPortfolios()}>
              <CachedIcon />
            </OPIconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
