//@ts-ignore

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import ApplicationContext from '@op/shared/src/models/how/application-context';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';

export interface IAxiosErrorResponse {
  status: number;
}
export interface IAxiosError {
  response: IAxiosErrorResponse;
}

export const powerhouseProApiServiceUrl = 'Powerhouse/Pro';

export class ApiService {
  private static _instance: ApiService;
  readonly API_ENDPOINT: string | undefined;
  readonly singalrEndpoint: string | undefined;

  public static customApiEndpoint: string | undefined;

  private readonly ERROR_MESSAGE_NON_200 = 'Server responded with non 200 Ok';
  private readonly ERROR_MESSAGE_RESOURCE_NOT_FOUND = 'Resource not found.';
  private readonly apiEndpoingMap = new Map<string, string>([
    ['localhost:3000', 'https://telluride-api.optionsplay.com'],
    ['bromley-dev.optionsplay.com', 'https://bromley-api.optionsplay.com'],
    ['new-staging.optionsplay.com', 'https://api-staging.optionsplay.com'],
    ['usfe-staging.optionsplay.com', 'https://usbe-staging.optionsplay.com'],
    ['new.optionsplay.com', 'https://api.optionsplay.com'],
    ['bromley-no.optionsplay.com', 'https://bromley-no-api.optionsplay.com'],
    ['new-nordic-staging.optionsplay.com', 'https://api-nordic-staging.optionsplay.com'],
    ['app-nordic.optionsplay.com', 'https://api-nordic.optionsplay.com'],
    ['bromley-ca.optionsplay.com', 'https://bromley-ca-api.optionsplay.com'],
    ['new-tmx-staging.optionsplay.com', 'https://api-tmx-staging.optionsplay.com'],
    ['tmx.optionsplay.com', 'https://api-tmx.optionsplay.com'],
    ['bromley-global.optionsplay.com', 'https://bromley-global-api.optionsplay.com'],
    ['app-ca-staging.optionsplay.com', 'https://api-ca-staging.optionsplay.com'],
    ['app-ca.optionsplay.com', 'https://api-ca.optionsplay.com'],
    ['new-global-staging.optionsplay.com', 'https://api-global-staging.optionsplay.com'],
    ['new-global.optionsplay.com', 'https://api-global.optionsplay.com'],
    ['telluride-app.optionsplay.com', 'https://telluride-api.optionsplay.com'],
    ['telluride-staging.optionsplay.com', 'https://telluride-api-staging.optionsplay.com'],
  ]);
  constructor() {
    let baseApiEndPoint;
    if (!ApiService.customApiEndpoint && location && location.host) {
      baseApiEndPoint = this.apiEndpoingMap.get(location.host);
    } else {
      baseApiEndPoint = ApiService.customApiEndpoint;
    }
    if (!baseApiEndPoint || baseApiEndPoint.trim() === '') {
      throw new Error('Please provide api end point');
    }
    this.API_ENDPOINT = `${baseApiEndPoint}/api`;
    this.singalrEndpoint = `${baseApiEndPoint}/signalr`;
    axios.defaults.baseURL = this.API_ENDPOINT;
  }

  public static get instance(): ApiService {
    if (ApiService._instance == null) {
      ApiService._instance = new ApiService();
    }
    return ApiService._instance;
  }

  public init = (errorCallback: (statusCode: number) => void, loadingCallback: (really: boolean) => void): void => {
    const accessToken = ApplicationContext.accessToken;
    axios.defaults.baseURL = this.API_ENDPOINT;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Accept'] = 'application/json, application/x-www-form-urlencoded, */*';
    if (accessToken !== '') {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    axios.interceptors.request.use(
      function (config: any) {
        if (
          config.url === '/authentication/canUseEmail' ||
          config.url === '/authentication/signUp' ||
          config.url === '/Profile/forgotPassword'
        ) {
          delete config.headers.Authorization;
          delete config.headers?.common.Authorization;
          config.headers.Accept = 'application/json';
        }
        if (config.url === '/notifications/installations') {
          delete config.headers.Authorization;
          config.headers.Accept = 'application/json';
        }
        return config;
      },
      (error: any) => {
        console.log('Interceptor Error: ', error);
      },
    );

    axios.interceptors.response.use(
      (response: any) => {
        loadingCallback(false);
        return response;
      },
      (error: IAxiosError) => {
        loadingCallback(false);
        const errorPromise = Promise.reject(error);
        if (error.response) {
          errorCallback(error.response.status);
        }
        return errorPromise;
      },
    );
  };

  private updateHeaders = () => {
    if (!ApplicationContext.accessToken || ApplicationContext.accessToken.trim() === '') {
      return;
    }
    if (window && window.localStorage) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${ApplicationContext.accessToken}`;
    } else {
      axios.defaults.headers['Authorization'] = `Bearer ${ApplicationContext.accessToken}`;
    }
  };

  //TODO: refactor: config should handle all. parameters not required.
  public get = async <T>(url: string, parameter?: any, config?: AxiosRequestConfig): Promise<ResponseViewModel<T>> => {
    this.updateHeaders();
    try {
      let response: AxiosResponse<T> | undefined;
      if (config) {
        response = await axios.get<T>(url, config);
      } else if (parameter) {
        response = await axios.get<T>(url, { params: parameter });
      } else {
        response = await axios.get<T>(url);
      }
      if (response.status === 200) {
        return ResponseViewModel.fromData(response.data);
      }
      return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_NON_200]);
    } catch (error: any) {
      // This happens if api call is cancelled by client via Axios's cancelToken.
      if (error && !error.response) {
        return ResponseViewModel.fromErrors([error.toString()]);
      }
      if (error && error.response && error.response.status === 404) {
        return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_RESOURCE_NOT_FOUND], error.response.status);
      }
      if (error && error.response && error.response.data) {
        return ResponseViewModel.fromErrors([error.response.data.message], error.response.status);
      }
      return ResponseViewModel.fromErrors([error.response.data], error.response.status);
    }
  };

  public post = async <Req, T>(url: string, body: Req, config?: AxiosRequestConfig): Promise<ResponseViewModel<T>> => {
    this.updateHeaders();
    try {
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      const response = await axios.post<T>(url, JSON.stringify(body), config);
      if (response.status === 200) {
        return ResponseViewModel.fromData(response.data);
      }
      return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_NON_200]);
    } catch (error: any) {
      if (error && error.response && error.response.status === 404) {
        return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_RESOURCE_NOT_FOUND], error.response.status);
      }
      if (error && error.response && error.response.data) {
        if (error.response.data.additionalInfo) {
          return ResponseViewModel.fromErrors([error.response.data.additionalInfo], error.response.status);
        }
        return ResponseViewModel.fromErrors([error.response.data.message], error.response.status);
      }
      return ResponseViewModel.fromErrors([error.response.data], error.response.status);
    }
  };

  public postfile = async <Req, Res>(
    url: string,
    body: Req,
    config?: AxiosRequestConfig,
  ): Promise<ResponseViewModel<Res>> => {
    this.updateHeaders();
    try {
      const response = await axios.post<ResponseViewModel<Res>>(url, body, config);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  public put = async <Req, T>(url: string, body: Req, config?: AxiosRequestConfig): Promise<ResponseViewModel<T>> => {
    this.updateHeaders();
    try {
      axios.defaults.headers.put['Content-Type'] = 'application/json';
      const response = await axios.put<T>(url, JSON.stringify(body), config);
      if (response.status === 200) {
        return ResponseViewModel.fromData(response.data);
      }

      return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_NON_200]);
    } catch (error: any) {
      if (error && error.response && error.response.status === 404) {
        return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_RESOURCE_NOT_FOUND], error.response.status);
      }
      if (error && error.response && error.response.data) {
        const formattedMessage = error.response.data.additionalInfo
          ? error.response.data.additionalInfo
          : error.response.data.message;
        return ResponseViewModel.fromErrors([formattedMessage], error.response.status);
      }
      return ResponseViewModel.fromErrors([error.response.data], error.response.status);
    }
  };

  public delete = async <T>(
    url: string,
    parameter?: any,
    config?: AxiosRequestConfig,
  ): Promise<ResponseViewModel<T>> => {
    this.updateHeaders();
    try {
      axios.defaults.headers.delete['Content-Type'] = 'application/json';
      let response: AxiosResponse<T> | undefined;
      if (config) {
        response = await axios.delete<T>(url, config);
      } else if (parameter) {
        response = await axios.delete<T>(url, { params: parameter });
      } else {
        response = await axios.delete<T>(url);
      }
      if (response.status === 200) {
        return ResponseViewModel.fromData(response.data);
      }

      return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_NON_200]);
    } catch (error: any) {
      if (error && error.response && error.response.status === 404) {
        return ResponseViewModel.fromErrors([this.ERROR_MESSAGE_RESOURCE_NOT_FOUND], error.response.status);
      }
      if (error && error.response && error.response.data) {
        const formattedMessage = error.response.data.additionalInfo
          ? error.response.data.additionalInfo
          : error.response.data.message;
        return ResponseViewModel.fromErrors([formattedMessage], error.response.status);
      }
      return ResponseViewModel.fromErrors([error.response.data], error.response.status);
    }
  };
}
