import { atom, selector } from 'recoil';
import { Lightbox } from '../models';

export const lightboxState = atom({
  key: 'lightboxState',
  default: true,
});

export const lightboxNotificationClick = atom({
  key: 'lightboxNotificationClick',
  default: false,
});

export const unreadLightboxState = atom({
  key: 'unreadLightboxState',
  default: 0,
});

export const lightboxDataState = atom<Lightbox[] | undefined>({
  key: 'lightboxDataState',
  default: undefined,
});

export const lightboxSelectorState = selector<Lightbox[] | undefined>({
  key: 'lightboxSelectorKey',
  get: ({ get }) => {
    const quotes = get(lightboxDataState);
    return quotes;
  },
});
