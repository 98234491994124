import { PortfolioCombination } from '.';

export class ProfitAndLossCalculator {
  symbol: string | undefined;
  isShare: boolean = false;
  isShort: boolean = false;
  selectedPortfolioCombination: PortfolioCombination | undefined;
  sliderTicks: number[] = []; //ko.observableArray(sliderTicks);
  anchor: number[] = [];
  anchorForContract: number = 0;
  quantity: number = 0;
  maxQuantity: number = 0;
  minQuantity = 1;
  private constructor() {}

  static fromData(selectedPortfolioCombination: PortfolioCombination) {
    const modal = new ProfitAndLossCalculator();
    modal.selectedPortfolioCombination = selectedPortfolioCombination;
    modal.isShare = selectedPortfolioCombination.hasOnlyStx();
    modal.symbol = selectedPortfolioCombination.symbol;
    modal.isShort = selectedPortfolioCombination.isShort;
    modal.initialize();
    return modal;
  }

  static fromSelf(self: ProfitAndLossCalculator) {
    const clone = new ProfitAndLossCalculator();
    clone.selectedPortfolioCombination = self.selectedPortfolioCombination;
    clone.isShare = self.isShare;
    clone.symbol = self.symbol;
    clone.isShort = self.isShort;
    clone.quantity = self.quantity;
    clone.maxQuantity = self.maxQuantity;
    clone.sliderTicks = Array.from(self.sliderTicks);
    clone.anchorForContract = self.anchorForContract;
    clone.anchor = Array.from(self.anchor);
    return clone;
  }

  private initialize = () => {
    if (!this.selectedPortfolioCombination) {
      throw new Error('Selected combination is undefined');
    }
    this.maxQuantity = this.selectedPortfolioCombination.absQuantity();
    this.quantity = this.maxQuantity;
  };

  isProfitable = () => {
    if (!this.selectedPortfolioCombination) {
      throw new Error('Selected combination is undefined');
    }
    var isProfitable = this.selectedPortfolioCombination.unrealizedProfitAndLoss() >= 0;
    return isProfitable;
  };

  realizedProfitAndLoss = () => {
    if (!this.selectedPortfolioCombination) {
      throw new Error('Selected combination is undefined');
    }
    var result =
      Math.abs(this.selectedPortfolioCombination.unrealizedProfitAndLoss()) *
      (this.quantity / this.selectedPortfolioCombination.absQuantity());
    return result;
  };

  realizedProfitAndLossPercentage = () => {
    if (!this.selectedPortfolioCombination) {
      throw new Error('Selected combination is undefined');
    }
    var unrealizedProfitAndLossPercentage = this.selectedPortfolioCombination.unrealizedProfitAndLossPercentage() as number;
  
    var quantity = this.quantity;
    var absQuantity = this.selectedPortfolioCombination.absQuantity();
    
    var result = (unrealizedProfitAndLossPercentage * quantity) / absQuantity;
    return result;
  };
}
