import { Avatar, Box, Grid, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import Us from '@op/shared/assets/images/us.png';
import { ISubscriptionPlanPrice } from '@op/shared/src/models';
import { subscriptionPlanPriceState, subscriptionPlanTypeState } from '@op/shared/src/states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { ChoosePlanWidget } from './choose-plan';
import { ChangePlanSlider } from './components';
import { SubscriptionHeaderWidget } from './subscription-footer-widget';
import { SubscriptionSliderSettings } from './subscription-helper';

export const ChangePlan: React.FC = () => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const subscriptionPlanPrices = useRecoilValue(subscriptionPlanPriceState);
  const [subscriptionPlanType, setSubscriptionPlanType] = useRecoilState(subscriptionPlanTypeState);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderSettings = SubscriptionSliderSettings(theme, 'changePlan', currentSlide, setCurrentSlide);
  const [subscriptionBundles, setSubscriptionBundles] = useState<ISubscriptionPlanPrice[]>();

  useEffect(() => {
    if (!subscriptionPlanPrices) {
      return;
    }
    setSubscriptionBundles(
      subscriptionPlanPrices.filter((p) => p.featureType === 'Bundle' && p.planType === subscriptionPlanType),
    );
  }, [subscriptionPlanType, subscriptionPlanPrices]);

  const renderHeaderView = () => {
    return (
      <SubscriptionHeaderWidget
        label={
          <Grid item container xs={12} alignItems="center">
            <OPBoldTypograpghy style={{ color: theme.palette.primary.main, fontSize: '14px', marginRight: '4px' }}>
              {t('subscriptions.selectSubscriptionPlan')}
            </OPBoldTypograpghy>
            <Avatar alt="flag" src={Us} style={{ width: 25, height: 25, margin: 2 }} />
            <OPBoldTypograpghy
              variant="subtitle1"
              sx={{ color: theme.palette.primary.main, paddingLeft: 1, alignItems: 'center' }}>
              US
            </OPBoldTypograpghy>
          </Grid>
        }
      />
    );
  };

  const handlePlanType = (event: any, planType: 'Monthly' | 'Annual') => {
    if (planType !== null) {
      setSubscriptionPlanType(planType);
    }
  };

  const renderPlanType = () => {
    return (
      <ToggleButtonGroup
        color="primary"
        value={subscriptionPlanType}
        exclusive
        onChange={handlePlanType}
        aria-label="Platform">
        <ToggleButton value="Annual" sx={{ width: '140px' }}>
          {t('subscriptions.annual')}
        </ToggleButton>
        <ToggleButton value="Monthly" sx={{ width: '140px' }}>
          {t('subscriptions.monthly')}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const renderPlans = () => {
    return (
      <Box sx={{ width: '90%', margin: '0 auto', mb: 3 }}>
        <ChangePlanSlider {...sliderSettings}>
          {subscriptionBundles.map((plan, index) => (
            <Grid item xs={12} key={index}>
              <ChoosePlanWidget planData={plan} />
            </Grid>
          ))}
        </ChangePlanSlider>
      </Box>
    );
  };

  const renderContent = () => {
    if (!subscriptionBundles || subscriptionBundles.length === 0) {
      return (
        <Grid container justifyContent={'center'} p={2}>
          <OPBoldTypograpghy variant="subtitle2">{t('subscription.noBundlesAvailable')}</OPBoldTypograpghy>
        </Grid>
      );
    }
    return (
      <Grid container p={2}>
        <Grid item xs={12}>
          {renderHeaderView()}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: theme.palette.greyShades.light,
            borderRadius: 2,
          }}>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            {renderPlanType()}
          </Grid>
          <Grid container>{renderPlans()}</Grid>
        </Grid>
      </Grid>
    );
  };

  return <>{renderContent()}</>;
};
