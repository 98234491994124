import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { Variant } from '@mui/material/styles/createTypography';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { IData } from '@op/shared/src/models/what/data-props';
import {
  expandedQuoteAtomFamily,
  expandedQuotesUpdater,
  fetchQuotes,
  isHubInitiatedState,
  selectedSymbolState,
  subscribeToQuotes,
  unsubscribeFromQuotes,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ToExtendedChange } from './to-extended-change';

export interface IExpandedQuoteHubWidgetProps extends IData<string> {
  variant?: Variant;
  alignRight?: boolean;
  showOnlyLastPrice?: boolean;
}

//TODO: r-idea: dont show NA. show loader if data not present. blank data number comes
export const ExpandedQuoteHubWidget: React.FC<IExpandedQuoteHubWidgetProps> = ({
  data,
  variant,
  alignRight,
  showOnlyLastPrice,
}: IExpandedQuoteHubWidgetProps) => {
  const symbol = data;
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(symbol));
  const setExpandedQuotes = useSetRecoilState(expandedQuotesUpdater);
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [delay, setDelay] = useState(2000);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ref, inView, entry] = useInView({
    threshold: 1,
    delay: delay,
  });

  const fetchQuote = async () => {
    const fetchedQuotes = await fetchQuotes([symbol]);
    if (!fetchedQuotes || fetchedQuotes.length === 0) {
      return;
    }
    setExpandedQuotes(fetchedQuotes);
  };

  useEffect(() => {
    if (!isHubInitiated || !symbol || !inView) {
      return;
    }
    (async () => {
      // setDelay(5000)
      if (!expandedQuote) {
        await fetchQuote();
      }
      await subscribeToQuotes([symbol]);
    })();
  }, [isHubInitiated, inView]); //symbol

  useEffect(() => {
    if (inView || !expandedQuote) {
      return;
    }
    //If symbol is selected and loaded in other panels like quotebar, how panel etc. we should not unsubscribe the quotes.
    if (selectedSymbol?.toLowerCase() === expandedQuote.symbol.toLowerCase()) {
      return;
    }
    (async () => {
      await unsubscribeFromQuotes([symbol]);
    })();
  }, [inView, expandedQuote]);

  variant = variant || 'body1';

  if (!symbol) {
    return <></>;
  }

  return (
    <Grid
      ref={ref}
      container
      alignItems="center"
      className="js-expanded-quote"
      justifyContent={alignRight ? 'flex-end' : 'flex-start'}>
      {expandedQuote ? (
        <>
          <Typography variant={variant} sx={{ pr: 1 }} component="span" className="js-expanded-quote-last">
            {NumberFormatHelper.toCurrency(expandedQuote.last)}
          </Typography>
          {!showOnlyLastPrice && (
            <Typography variant={variant} component="span" className="js-expanded-quote-percentage">
              <ToExtendedChange quote={expandedQuote} />
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="body1">N/A</Typography>
      )}
    </Grid>
  );
};
