import { Grid, Link } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { OPDialogWidget } from '../common';

interface SignupModalProps {
  isOpen: boolean;
  onHandleClose: () => void;
  onClickSignUp: () => void;
  onClickAccount: () => void;
}

export const SignupModal: React.FC<SignupModalProps> = (props: SignupModalProps) => {
  const { onHandleClose, isOpen, onClickAccount, onClickSignUp } = props;

  const renderDialogContent = () => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 30, textAlign: 'center' }}>
          <Typography variant="h5">Join the thousands of traders using &nbsp;</Typography>
          <Typography variant="h5">
            <Link href="http://optionsplay.com" target="_blank">
              OptionsPlay Ideas
            </Link>{' '}
            &nbsp; to unlock new investment opportunities!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 30, textAlign: 'center' }}>
          <Typography variant="h5">It only takes a few moments and it’s </Typography>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            FREE!
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            style={{ width: '80%', height: 45, backgroundColor: 'green' }}
            onClick={() => onClickSignUp()}>
            <Typography variant="button"> Sign me up! </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            style={{ width: '80%', height: 35 }}
            onClick={() => onClickAccount()}>
            <Typography variant="button"> Already have an account! </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <OPDialogWidget
        open={isOpen}
        onHandleClose={onHandleClose}
        dialogAction={undefined}
        dialogContent={renderDialogContent()}
        title={undefined}
      />
    </div>
  );
};
