import { SocketConnection } from './socket-connection-base';

export class UserActivityHubConnection extends SocketConnection {
  private constructor() {
    super();
  }

  static fromHubName = (token: string) => {
    const model = new UserActivityHubConnection();
    model.fromHubName('userActivitiesHub', token);
    return model;
  };
}
