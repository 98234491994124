import { Grid, Typography } from '@mui/material';
import React from 'react';
import { OptionsPlayDatePickerWidget } from '../../styled';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
interface Props {
  gtdDate: Date;
  callback: (type: string, value: Date) => void;
}

export const GtdDateWidget: React.FC<Props> = ({ gtdDate, callback }: Props) => {
  const updateGTDDate = (date: Date | null): void => {
    if (date === null) {
      return;
    }
    callback('gtdDate', date);
  };

  const renderGtdDatePrice = () => {
    return (
      <Grid item container xs={12}>
        <Typography variant="captionSmall" lineHeight={1.5} sx={{ margin: '3px' }}>
          GTD Date
        </Typography>

        <OptionsPlayDatePickerWidget
          isTradeStation
          value={DateTimeHelper.format(gtdDate, 'yyyy-MM-dd')}
          min={DateTimeHelper.getCurrentDate()}
          // max={tradingRangeSimulator.dateOfBellSlider}
          onChangeDate={updateGTDDate}
          onBlur={(e) => callback('gtdDate', new Date(e.target.value))}
        />
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {renderGtdDatePrice()}
      </Grid>
    </Grid>
  );
};
