import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Checkbox, FormControlLabel, Grid, Theme, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { selectedSymbolState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { OPDialogWidget } from '../common';
import { OutlinedButton } from '../styled/options-play-components';

export default function VideoIconMenu() {
  const [open, setOpen] = React.useState(false);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme<Theme>();
  const video = {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '560px',
      height: '315px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      height: '100%',
    },
  };

  const handleClickOpen = () => {
    ApplicationContext.userActivityHub?.logActivity(
      'BUTTON',
      'optionsPlayPlatformWalkthrough',
      '',
      EventType.Click,
      selectedSymbol as string,
    );
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'space-between'} spacing={2}>
        <Grid item>
          <FormControlLabel
            control={<Checkbox defaultChecked color="primary" />}
            label="Don't show this window again"
            labelPlacement="end"
          />
        </Grid>
        <Grid item>
          <OutlinedButton size="small" onClick={handleClose} style={{ marginTop: 8 }}>
            <Typography variant="button">{t('common.buttons.close')}</Typography>
          </OutlinedButton>
        </Grid>
      </Grid>
    );
  };
  const renderDialogContent = () => {
    return (
      <Grid item xs={12}>
        <iframe
          width="560"
          height="315"
          style={video}
          src="https://www.youtube.com/embed/videoseries?si=TP7v6qPG9I5IP8Id&amp;list=PLSC1eKlsCR8RrVLU3re6oYs75Yw1FaL6n"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </Grid>
    );
  };
  const renderDialogTitle = () => {
    return (
      <div id="customized-dialog-title">
        <Typography variant="h5" style={{ fontWeight: 900 }}>
          OptionsPlay Platform Walkthrough
        </Typography>
      </div>
    );
  };
  return (
    <div>
      <CommonListItemIcon
        icon={<VideocamOutlinedIcon />}
        text={t('footer.labels.walkthrough')}
        callback={handleClickOpen}
        key={'profile'}
      />
      <OPDialogWidget
        open={open}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </div>
  );
}
