import BasicCombination from './basic-combination';
import formatting from './formatting';
import HowDataModel from './how-data-model';
import { StandardDeviation } from './standard-deviation';
import TradingStrategies from './trading-strategies';

export class TradingRangeSimulator {
  private howDataModel: HowDataModel | undefined;
  sds: number[] = [];
  hasOption: boolean = false;
  chartSPrices = [0, 1];
  dateOfBellSlider = formatting.getCurrentDate();
  last = 0;

  private constructor() {}

  static fromData = (howDataModel: HowDataModel, allCombinations: (BasicCombination | undefined)[], expiry?: Date) => {
    const model = new TradingRangeSimulator();
    //TODO: Refactor: howDataModel should not be cached.
    model.howDataModel = howDataModel;
    // NOTE: The system works if we do not have datepart to it. Hence, only taking datepart and stripping timepart.
    const farthestExpiry = expiry ? new Date(expiry.getFullYear(), expiry.getMonth(), expiry.getDate()) : undefined;
    model.dateOfBellSlider = farthestExpiry || TradingStrategies.farthestExpiration(allCombinations);
    model.hasOption = howDataModel.hasOption;
    model.last = howDataModel.quote.last;
    model.initialize(howDataModel);

    return model;
  };

  public static fromSelf = (self: TradingRangeSimulator) => {
    const clone = new TradingRangeSimulator();
    clone.howDataModel = self.howDataModel;
    clone.last = self.last;
    clone.dateOfBellSlider = self.dateOfBellSlider;
    clone.hasOption = self.hasOption;
    clone.chartSPrices = Array.from(self.chartSPrices);
    clone.sds = Array.from(self.sds);
    return clone;
  };

  get chartLowPrice() {
    return this.chartSPrices[0];
  }

  set chartLowPrice(price: number) {
    this.chartSPrices[0] = price;
  }

  get chartHighPrice() {
    return this.chartSPrices[1];
  }

  set chartHighPrice(price: number) {
    this.chartSPrices[1] = price;
  }

  private initialize = (howDataModel: HowDataModel) => {
    this.turnToNarrow(howDataModel.stdDev, this.dateOfBellSlider);
  };

  private get howData() {
    if (!this.howDataModel) {
      throw new Error('HowDataModel is undefined');
    }
    return this.howDataModel;
  }

  updateSds = (stdDev: StandardDeviation) => {
    const sdsByExpiry = stdDev.getStdDevsByExpiry(this.dateOfBellSlider);
    this.sds = sdsByExpiry ? [sdsByExpiry[1], sdsByExpiry[2], sdsByExpiry[3], sdsByExpiry[4], sdsByExpiry[5]] : [];
  };

  dateSliderMaxDays = () => {
    const result = formatting.daysFromNow(this.dateOfBellSlider);
    if (result < 0) {
      return 0;
    }
    return result;
  };

  chartLowBound = () => {
    if (this.sds.length > 0) {
      return this.sds[0];
    }
    return this.last * 0.7;
  };

  chartHighBound = () => {
    if (this.sds.length >= 5) {
      return this.sds[4];
    }
    return this.last * 1.3;
  };

  chartMidStandardDeviation = () => {
    if (this.sds.length >= 5) {
      return this.sds[2];
    }
    return (this.chartHighBound() - this.chartLowBound()) / 2;
  };

  differenceBetweenLowAndLast = () => {
    return this.chartLowPrice - this.last;
  };

  differenceBetweenHighAndLast = () => {
    return this.chartHighPrice - this.last;
  };

  narrowRange = () => {
    if (this.sds.length > 0) {
      return (
        formatting.closeTo(this.chartSPrices[0], this.sds[1], 0.02) &&
        formatting.closeTo(this.chartSPrices[1], this.sds[3], 0.02)
      );
    }
    return false;
  };

  wideRange = () => {
    if (this.sds.length > 0) {
      return (
        formatting.closeTo(this.chartSPrices[0], this.sds[0], 0.02) &&
        formatting.closeTo(this.chartSPrices[1], this.sds[4], 0.02)
      );
    }
    return false;
  };

  turnToNarrow = (sds: StandardDeviation, expiry: Date) => {
    this.dateOfBellSlider = expiry;
    this.updateSds(sds);
    if (this.sds.length > 3) {
      this.chartSPrices = [this.sds[1], this.sds[3]];
    }
  };

  turnToWide = (sds: StandardDeviation, expiry: Date) => {
    this.dateOfBellSlider = expiry;
    this.updateSds(sds);
    if (this.sds.length > 4) {
      this.chartSPrices = [this.sds[0], this.sds[4]];
    }
  };

  private getProbPos = (value: number, leftRightFlag: boolean) => {
    const lastPercentage = (this.last - this.chartLowBound()) / (this.chartHighBound() - this.chartLowBound());
    const leftPercentage = (value - this.chartLowBound()) / (this.chartHighBound() - this.chartLowBound());
    const left = leftPercentage / 2 + lastPercentage / 2;

    let bottom = 0.3 - Math.abs(lastPercentage - left) * 0.5;
    if (!leftRightFlag && this.leftProbPos() && Math.abs(this.leftProbPos().leftVal - left) < 0.15) {
      bottom -= 0.1;
    }
    let prob = 0;
    const probModel = this.howData && this.howData.predictions.getProb(this.dateOfBellSlider);
    if (probModel && (leftRightFlag ? value < this.last : value > this.last)) {
      prob = probModel.percentileRank(value, this.last);
    }
    return {
      left: left * 100 + '%',
      leftVal: left,
      bottom: bottom * 100 + '%',
      display: !!prob,
      prob: prob * 100,
    };
  };

  leftProbPos = () => {
    return this.getProbPos(this.chartLowPrice, true);
  };

  rightProbPos = () => {
    return this.getProbPos(this.chartHighPrice, false);
  };

  outlookPriceBoundsForPlChart = () => {
    return this.chartSPrices;
  };

  outlookPercentBoundsForPlChart = (howData: HowDataModel) => {
    const prices = this.outlookPriceBoundsForPlChart();
    const probs = howData.predictions.getProb(this.dateOfBellSlider);
    if (probs) {
      const low = probs.getCumulativeProbability(prices[0]);
      const high = probs.getCumulativeProbability(prices[1]);      
      return [low, high];
    }
    return [0, 1];
  };

  getSymmetricPrices = (howData: HowDataModel, presentPrices: number[], lowOrHigh: 'lowPrice' | 'highPrice') => {
    const prices = Array.from(presentPrices);
    const priceIndex = lowOrHigh === 'lowPrice' ? 0 : 1;
    const opposite = howData.predictions.getSymmetricPrice(prices[priceIndex], this.dateOfBellSlider);
    if (opposite === 0) {
      return prices;
    }
    if (priceIndex === 0 && opposite <= prices[0]) {
      prices[0] = opposite;
      prices[1] = prices[0];
    } else if (priceIndex === 1 && opposite >= prices[1]) {
      prices[0] = prices[1];
      prices[1] = opposite;
    } else {
      prices[1 - priceIndex] = opposite;
    }
    return prices;
  };
}
