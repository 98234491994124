export interface IUserSettingsProps {
  hasDisclaimerBeenAccepted: boolean;
  dontShowWalkthrough: boolean;
  theme: string;
  timeOutInMinutes: number;
  //commissionSchedule: ICommissionSchedule;
  subscriptions: string;
  whatIfDefaultPriceType: string;
  acceptedLightboxId: string;
  localeId: number;
  additionalSerializedData: [];
}

export class UserSettings implements IUserSettingsProps {
  hasDisclaimerBeenAccepted = false;
  dontShowWalkthrough = false;
  theme = '';
  timeOutInMinutes = -1;
  // commissionSchedule: ICommissionSchedule;
  subscriptions = '';
  whatIfDefaultPriceType = '';
  acceptedLightboxId = '';
  localeId = -1;
  additionalSerializedData: [] = [];

  public static fromJson = (json: IUserSettingsProps): UserSettings => {
    const model = new UserSettings();
    model.hasDisclaimerBeenAccepted = json.hasDisclaimerBeenAccepted;
    model.dontShowWalkthrough = json.dontShowWalkthrough;
    model.theme = json.theme;
    model.timeOutInMinutes = json.timeOutInMinutes;
    model.subscriptions = json.subscriptions;
    model.whatIfDefaultPriceType = json.whatIfDefaultPriceType;
    model.acceptedLightboxId = json.acceptedLightboxId;
    model.localeId = json.localeId;
    model.additionalSerializedData = json.additionalSerializedData;
    return model;
  };
}
