import { BuyOrSell, ImplementationType } from '@op/shared/src/models/enums/enums';
import { PortfolioManagementCombination, PortfolioPosition } from '../portfolio';
import ApplicationContext from './application-context';
import BasicCombination from './basic-combination';
import { BasicPositionModel } from './basic-position-model';
import Combination from './combination';
import formatting from './formatting';
import NumberFormatHelper from './number-format-helper';
import { Resources } from './resources';

export class CombinationTextGeneration {
  static combinationResources = {
    ToOpen: 'to Open',
    ToClose: 'to Close',
    NoChangesMade: 'No Changes Made',
    Roll: {
      //TODO: Vivek: filled Up Later by enums
      ROLLING_OUT: 'Roll Out',
      ROLLING_UP_OUT: 'Roll Up And Out',
      ROLLING_DOWN_OUT: 'Roll Down And Out',
    },
    PlainEnglishHtml: {
      Common: 'how.plainEnglishPanel.combinationText.plainEnglishHtml.common',
      ExpiryPart: 'how.plainEnglishPanel.combinationText.plainEnglishHtml.expiryPart',
      POPSuffix: 'how.plainEnglishPanel.combinationText.plainEnglishHtml.popSuffix',
    },
  };
  //TODO: Symbol is used to generate formatted objects. This should be passed in.
  //TODO: use the configuration data instead of hard coded values.
  static symbol = '';
  static showCurrencySymbolForNumbers = true; //ApplicationContext.configuration.showCurrencySymbolForNumbers;
  // static divideIndexQuantityByHundred = true; //ApplicationContext.configuration.divideIndexQuantityByHundred;
  static language = 'en-US';

  static divideIndexQuantityByHundred = ApplicationContext.configuration?.divideIndexQuantityByHundred || true;

  static getFormattingObjectForPositionName = (position: BasicPositionModel) => {
    let type = position.legType;
    let expiryFormatted;
    let strikeFormatted;
    if (position.isSecurityType()) {
      type = 'Share';
      expiryFormatted = '';
      strikeFormatted = '';
    } else {
      let expiry = position.expiry;
      if (!expiry) {
        throw new Error('Expiry is not defined.');
      }
      let formatTemplate = expiry.getFullYear() === new Date().getFullYear() ? 'T dd' : "T dd 'yy";
      expiryFormatted = formatting.formatDate(expiry, formatTemplate, null, 'en-US');
      strikeFormatted = position.strike;
    }
    let isIndex = position.isIndex; //formatting.checkIndices(CombinationTextGeneration.symbol);
    let qty =
      isIndex && CombinationTextGeneration.divideIndexQuantityByHundred && position.absQuantity > 99
        ? position.displayAbsQuantity()
        : position.absQuantity;
    type = formatting.pluralize(type, qty);
    return {
      absQuantity: qty,
      expiry: expiryFormatted,
      strike: strikeFormatted,
      type: type,
    };
  };

  static getPositionName = (position: PortfolioPosition) => {
    let format = '{ABS_QUANTITY} {EXPIRY} {STRIKE} {TYPE}';
    let formattingObject = CombinationTextGeneration.getFormattingObjectForPositionName(position);
    return formatting.formatStr(format, formattingObject);
  };

  static getPortfolioPositionName = (position: PortfolioPosition) => {
    return position.longOrShort() + ' ' + CombinationTextGeneration.getPositionName(position);
  };

  // language = ko.observable(localizer.locale()).extend({ notify: "always" });

  // localizer.locale.subscribe(function (language) {
  //     CombinationTextGeneration.language(language);
  // });

  static getFormattingObjectForCombinationName = (combination: BasicCombination, isSymbolRequired: boolean) => {
    const name = combination.strategyName();
    /**
     * absQuantity needs to be absoulte integer value for indexed symbols.
     * so, overrided the function of combination.displayAbsQuantity() to absQuantity / 100 only for the Title
     */
    const absQuantity = combination.absQuantity();
    /**
     * TODO : Divide index symbol by multiplier instead of hardcoded 100
     * TODO : index checking and dividing by 100 is done at multiple places move all of them at
     *        one function. may be from income-controls-widget.tsx
     */
    const isIndexWithStockOnly =
      combination.hasOnlyStx() && combination.isIndex && CombinationTextGeneration.divideIndexQuantityByHundred;
    const absQuantityValue = isIndexWithStockOnly ? absQuantity / 100 : absQuantity;
    const expiriesStrikes = combination.expiriesStrikes();
    let strategyName = formatting.pluralize(name, absQuantityValue);

    let formattingObject = {
      absQuantity: absQuantityValue,
      expiriesStrikes: expiriesStrikes,
      strategyName: strategyName,
      symbol: '',
    };

    if (isSymbolRequired) {
      formattingObject.symbol = formatting.symbolDotExchangeToSymbol(combination.symbol);
    }

    return formattingObject;
  };

  static getCombinationName = (combination: BasicCombination, isSymbolRequired: boolean = false) => {
    let format;
    if (combination.hasOnlyStx()) {
      format = '{ABS_QUANTITY} {STRATEGY_NAME}';
      if (isSymbolRequired) {
        format += ' of {SYMBOL}';
      }
    } else {
      format = '{ABS_QUANTITY} {SYMBOL} {EXPIRIES_STRIKES} {STRATEGY_NAME}';
    }
    let formattingObject = CombinationTextGeneration.getFormattingObjectForCombinationName(
      combination,
      isSymbolRequired,
    );
    let isIndex = combination.isIndex; //formatting.checkIndices(CombinationTextGeneration.symbol);
    return formatting.formatStr(
      format,
      formattingObject,
      CombinationTextGeneration.divideIndexQuantityByHundred,
      isIndex,
    );
  };

  static nameComposer = (options: any) => {
    let format = '{prefix} {combinationName}';
    return formatting.formatStr(format, options);
  };

  static getfullTradeCombinationName = (combination: BasicCombination, withSymbol: boolean) => {
    let prefix;
    switch (combination.implementation) {
      case ImplementationType.MERRILL: {
        prefix = CombinationTextGeneration.merrillPrefix(combination);
        break;
      }
      default: {
        prefix = combination.buyOrSell();
      }
    }

    return CombinationTextGeneration.nameComposer({
      prefix: prefix, //? localizer.localize('how.combinationTextGenration.prefix.' + prefix) : '',
      combinationName: CombinationTextGeneration.getCombinationName(combination, withSymbol),
    });
  };

  static getPortfolioCombinationName = (combination: BasicCombination, withSymbol: boolean = false) => {
    return CombinationTextGeneration.nameComposer({
      prefix: combination.longOrShort(),
      combinationName: CombinationTextGeneration.getCombinationName(combination, withSymbol),
    });
  };

  static merrillPrefix = (combination: BasicCombination) => {
    const type = combination.buyOrSell();
    if (combination.implementation !== ImplementationType.MERRILL) {
      return type;
    }
    return type === BuyOrSell.SELL ? `${type} Short` : type;
  };

  static extendedCombinationFormat = (
    combination: Combination | undefined,
    isToClose: boolean,
    addOpenClosePrfix: boolean,
  ) => {
    if (!combination) {
      return '';
    }
    let format = '{prefix} {openClose} {combinationName} @ {cost} {debitCredit}';
    let formattingObj: {
      combinationName: string | null;
      prefix: string;
      openClose: any;
      cost: string | number | bigint;
      debitOrCredit: string;
    } = {
      combinationName: '',
      prefix: '',
      openClose: '',
      cost: '',
      debitOrCredit: '',
    };

    formattingObj.combinationName = CombinationTextGeneration.getCombinationName(combination, true);
    formattingObj.prefix = combination.buyOrSell();

    if (!combination.hasOnlyStx() && addOpenClosePrfix) {
      formattingObj.openClose = isToClose ? Resources.Combination.ToClose : Resources.Combination.ToOpen;
    }
    let cost = combination.costWithoutOwned();
    let costInFractionalCurrency = NumberFormatHelper.toCurrency(Math.abs(cost), 'never');
    formattingObj.cost = costInFractionalCurrency;
    formattingObj.debitOrCredit = cost >= 0 ? 'Debit' : 'Credit';
    return formatting.formatStr(format, formattingObj);
  };

  private static formatCombinationPart = (
    combPart: Combination | undefined,
    isToClose: boolean,
    isExtendedFormat: boolean,
    addOpenClosePrfix: any,
  ) => {
    let partSentence = '';
    if (isExtendedFormat) {
      return CombinationTextGeneration.extendedCombinationFormat(combPart, isToClose, addOpenClosePrfix);
    }
    if (combPart) {
      let openClose = '';
      if (!combPart.hasOnlyStx()) {
        openClose = isToClose ? Resources.Combination.ToClose : Resources.Combination.ToOpen;
      }
      partSentence +=
        combPart.buyOrSell() + ' ' + openClose + ' ' + CombinationTextGeneration.getCombinationName(combPart);
    }
    return partSentence;
  };

  static getAdjustmentCombinationName = (
    combination: PortfolioManagementCombination,
    isExtendedFormat: boolean,
    addOpenClosePrfix: any,
  ) => {
    if (combination.positions && combination.positions.length === 0) {
      return [Resources.Combination.NoChangesMade];
    }

    if (!isExtendedFormat) {
      let rollType = combination.getRollType();
      if (rollType != null) {
        if (!combination.originalCombination) {
          throw new Error('original combination is undefined');
        }
        return [rollType + ' ' + CombinationTextGeneration.getCombinationName(combination.originalCombination)];
      }
    }
    const names: string[] = [];
    let closingCombination = combination.closingPositionsCombination();
    const closingCombinationName = CombinationTextGeneration.formatCombinationPart(
      closingCombination,
      true,
      isExtendedFormat,
      addOpenClosePrfix,
    );
    names.push(closingCombinationName);

    const openCombination = combination.openningPositionsCombination();
    const openCombinationName = CombinationTextGeneration.formatCombinationPart(
      openCombination,
      false,
      isExtendedFormat,
      addOpenClosePrfix,
    );
    names.push(openCombinationName);

    return names;
  };

  static generateTradeTicketTitle = (
    combination: PortfolioManagementCombination | Combination | undefined,
    notAddToOpenPostfix: boolean = false,
  ) => {
    if (!combination) {
      return [];
    }
    const addOpenClosePrfix = !notAddToOpenPostfix;

    if (combination.isTrade || combination.isIncome) {
      return [CombinationTextGeneration.extendedCombinationFormat(combination, false, addOpenClosePrfix)];
    }
    return CombinationTextGeneration.getAdjustmentCombinationName(
      combination as PortfolioManagementCombination,
      true,
      addOpenClosePrfix,
    );
  };
}
