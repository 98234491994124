export enum LanguageType {
  English = 'en-US',
  Chinese = 'zh-CN',
  FrenchCanadian = 'fr-CA',
  FrenchFrance = 'fr-FR',
  Swedish = 'sv-SE',
}

export const getLanguageType = (text: string): LanguageType => {
  switch (text.toUpperCase()) {
    case 'EN-US':
      return LanguageType.English;
    case 'ZH-CN':
      return LanguageType.Chinese;
    case 'FR-CA':
      return LanguageType.FrenchCanadian;
    case 'SV-SE':
      return LanguageType.Swedish;
    default:
      return LanguageType.English;
  }
};

export interface IGlobalization {
  id: number;
  name: LanguageType;
  fullName: string;
  isActive?: boolean;
}

export class Globalization {
  private _items: IGlobalization[] = [];

  private constructor() {}

  static fromData = (data: IGlobalization[], language: LanguageType) => {
    const model = new Globalization();
    model._items = data.map((g) => {
      return {
        fullName: g.fullName,
        id: g.id,
        name: g.name,
        isActive: g.name === language,
      };
    });
    return model;
  };

  static fromSelf = (self: Globalization) => {
    const model = new Globalization();
    model._items = self.items.map((g) => {
      return {
        fullName: g.fullName,
        id: g.id,
        name: g.name,
        isActive: g.isActive,
      };
    });
    return model;
  };

  static fromSample = () => {
    const model = new Globalization();
    model._items = [
      { id: 1033, name: LanguageType.English, fullName: 'English (US)', isActive: true },
      { id: 1053, name: LanguageType.Swedish, fullName: 'Swedish (Sweden)' },
      { id: 2052, name: LanguageType.Chinese, fullName: 'Chinese (China)' },
      { id: 2054, name: LanguageType.FrenchCanadian, fullName: 'French (Canada)' },
    ];
    return model;
  };

  static get default() {
    return { id: 1033, name: LanguageType.English, fullName: 'English (US)' } as IGlobalization;
  }

  private getGlobalization = (name: LanguageType) => {
    return this.items.find((g) => g.name === name);
  };

  get items() {
    if (!this._items) {
      return [];
    }
    return this._items;
  }

  get active() {
    return this.items.find((i) => i.isActive);
  }

  set active(newLocale: IGlobalization | undefined) {
    if (!newLocale) {
      return;
    }
    const clone = Globalization.fromData(this.items, newLocale.name);
    let itemToUpdate = clone.items.find((i) => i.name === newLocale.name); //its reference.
    if (!itemToUpdate) {
      return;
    }
    itemToUpdate = newLocale; //updated the reference.
  }

  get us() {
    return this.getGlobalization(LanguageType.English);
  }

  get china() {
    return this.getGlobalization(LanguageType.Chinese);
  }

  get french() {
    return this.getGlobalization(LanguageType.FrenchCanadian);
  }

  get sweden() {
    return this.getGlobalization(LanguageType.Swedish);
  }

  static fromNordicDefault = () => {
    const model = new Globalization();
    model._items = [
      { id: 1033, name: LanguageType.English, fullName: 'English (US)' },
      { id: 1053, name: LanguageType.Swedish, fullName: 'Swedish (Sweden)', isActive: true },
    ];
    return model;
  };

  static fromUSDefault = () => {
    const model = new Globalization();
    model._items = [
      { id: 1033, name: LanguageType.English, fullName: 'English (US)', isActive: true },
      { id: 2052, name: LanguageType.Chinese, fullName: 'Chinese (China)' },
    ];
    return model;
  };

  static fromTMXDefault = () => {
    const model = new Globalization();
    model._items = [
      { id: 1033, name: LanguageType.English, fullName: 'English (US)', isActive: true },
      { id: 2052, name: LanguageType.Chinese, fullName: 'Chinese (China)' },
    ];
    return model;
  };
}

export const isNordic = () => {
  return process.env.REACT_APP_REGION === 'nordic';
};

export const isTmx = () => {
  return process.env.REACT_APP_REGION === 'ca';
};

export const isUS = () => {
  return process.env.REACT_APP_REGION === 'us';
};

export const isGlobal = () => {
  return process.env.REACT_APP_REGION === 'global';
};

export default LanguageType;
