import React from 'react';
import Grid from '@mui/material/Grid/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Box, useTheme } from '@mui/material';

export const HowSkeletonWidget: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="flex-start" alignItems="flex-start" sx={{ py: 0.5 }}>
        {[0, 1, 2].map((value) => (
          <Grid key={value} item xs={4}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={20}
              height={20}
              sx={{ mr: 0.5, ml: 0.5, display: 'inline-block' }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              height={20}
              width="70%"
              sx={{ mr: 0.5, ml: 0.5, display: 'inline-block' }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} direction="row" columnSpacing={1} sx={{ px: 0.75 }}>
        {[0, 1, 2].map((value) => (
          <Grid key={value} item xs={4}>
            <Box style={{ border: `1px solid ${theme.palette.grey[500]}`, borderRadius: 4 }}>
              <Skeleton variant="rectangular" height={40} />
              <Skeleton variant="rectangular" height={100} sx={{ mx: 2, my: 1 }} />
              {[0, 1, 2, 3].map((value) => (
                <Box key={value} display="flex" justifyContent="center" sx={{ m: 1 }}>
                  <Skeleton variant="text" width={80} height={20} sx={{ ml: 2, mr: 0.5 }} />
                  <Skeleton variant="text" width={80} height={20} sx={{ mr: 2, ml: 0.5 }} />
                </Box>
              ))}
              <Box display="flex" justifyContent="center" sx={{ m: 1 }}>
                <Skeleton variant="text" width={150} height={20} sx={{ mx: 1, mt: 0.8 }} />
                <Skeleton variant="rounded" width={150} height={30} sx={{ mr: 1, borderRadius: 5 }} />
              </Box>
              <Box display="flex" justifyContent="center" sx={{ m: 1, mt: 2 }}>
                <Skeleton variant="rounded" width={150} height={35} sx={{ mr: 1 }} />
                <Skeleton variant="rounded" width={150} height={35} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid item container xs={12} justifyContent="flex-start" alignItems="flex-start">
        {[0, 1, 2, 3].map((value) => (
          <Grid key={value} item xs={3}>
            <Skeleton animation="wave" variant="text" height={30} sx={{ mr: 0.5, ml: 0.5, mt: 1.5 }} />
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12}>
        <Skeleton animation="wave" variant="text" width="100%" height={70} sx={{ mx: 1 }} />
        <Grid item xs={5}>
          {[0, 1, 2].map((value) => (
            <Grid item xs={12} key={value} display="flex">
              <Skeleton variant="text" width="50%" height={20} sx={{ m: 1.5 }} />
              <Skeleton variant="rounded" width="50%" height={30} sx={{ m: 1 }} />
            </Grid>
          ))}
        </Grid>

        <Grid item container xs={7}>
          {[0, 1, 2].map((value) => (
            <Grid xs={12} key={value} display="flex">
              <Grid item xs={2}>
                <Skeleton animation="wave" variant="text" height={30} sx={{ mr: 0.5, ml: 0.5, mt: 1 }} />
              </Grid>
              <Grid item xs={4.4}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="100%"
                  height={15}
                  sx={{ ml: 0.5, justifyContent: 'center', alignItems: 'center', mt: 2 }}
                />
              </Grid>
              <Grid item xs={0.1}>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={20}
                  height={20}
                  sx={{
                    mt: 1.7,
                    alignItems: 'center',
                  }}
                />
              </Grid>
              <Grid item xs={4.4}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="100%"
                  height={15}
                  sx={{ mr: 0.5, justifyContent: 'center', alignItems: 'center', mt: 2 }}
                />
              </Grid>
              <Grid item xs={2}>
                <Skeleton animation="wave" variant="text" height={30} sx={{ mr: 1, ml: 0.5, mt: 1 }} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
