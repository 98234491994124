import { debounce } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ICommonOrderRequest, IMarketOrderRequest, IPlaceOrderLeg } from '../models';
import { marketPlaceOrderForReview } from '../services';
import {
  tsAccountIdsSelectorState,
  tsEnvironmentState,
  tsOrderTicketState,
  tsSelectedAccountState,
} from '../states/trade-station-states';
import {
  commonOrderRequestState,
  confirmOrderTicketState,
  marketOrderRequestState,
  tsOrdetTicketPositionState,
} from '../states/ts-ticket-states';
import { CommonOrderTicketWidget } from './common-order-ticket-widget';
import { TSOrderType } from '@op/shared/src/models/enums/enums';

interface Props {
  setOrderType: (type: string) => void;
  orderLegs: IPlaceOrderLeg[];
}

export const MarketOrderTicketWidget: React.FC<Props> = ({ setOrderType, orderLegs }: Props) => {
  const orderType = TSOrderType.Market;
  const selectedAccount = useRecoilValue(tsSelectedAccountState);
  const allAccounts = useRecoilValue(tsAccountIdsSelectorState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setMarketOrderRequest = useSetRecoilState(marketOrderRequestState);
  const setConfirmOrderTicketData = useSetRecoilState(confirmOrderTicketState);
  const [commonOrderRequest, setCommonOrderRequest] = useRecoilState(commonOrderRequestState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const tsOrderTicketPositions = useRecoilValue(tsOrdetTicketPositionState);
  const setOrderTicketValue = useSetRecoilState(tsOrderTicketState);

  useEffect(() => {
    const req: ICommonOrderRequest = {
      ...commonOrderRequest,
      timeInForce: commonOrderRequest && commonOrderRequest.timeInForce ? commonOrderRequest.timeInForce : '',
      route: commonOrderRequest && commonOrderRequest.route ? commonOrderRequest.route : 'Intelligent',
      gtdTime: commonOrderRequest && commonOrderRequest.gtdTime ? commonOrderRequest.gtdTime : new Date(),
      limitPrice: undefined,
      stopPrice: undefined,
    };
    setCommonOrderRequest(req);
  }, [tsOrderTicketPositions]);

  if (!tsOrderTicketPositions || !commonOrderRequest) {
    return <></>;
  }

  const marketOrderPreview = async (isBuy: boolean) => {
    if (!isBuy) {
      return;
    }
    setOrderTicketValue('isLoading');
    let request: IMarketOrderRequest = {
      accountID: selectedAccount[0].accountID,
      buyingPowerWarning: 'Enforce',
      orderType: orderType,
      timeInForce: commonOrderRequest.timeInForce,
      gtdTime: commonOrderRequest.gtdTime,
      route: commonOrderRequest.route,
      legs: orderLegs,
    };
    setMarketOrderRequest(request);
    const response = await marketPlaceOrderForReview(tsEnv, request);
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
      setOrderTicketValue('default');
      return;
    }
    setConfirmOrderTicketData(response.data);
    setOrderTicketValue('completed');
  };

  const debouncedmarketOrderPreview = debounce(marketOrderPreview, 1000);

  const disabledSendOrdersButton = () => {
    if (selectedAccount.length > 1) {
      return true;
    }
    if (commonOrderRequest.timeInForce === '') {
      return true;
    }
    return false;
  };

  return (
    <CommonOrderTicketWidget
      key={'marketOrder'}
      orderType={orderType}
      setOrderType={setOrderType}
      orderLegs={orderLegs}
      isDisabled={disabledSendOrdersButton()}
      onClickSendOrder={(v) => debouncedmarketOrderPreview(v)}
    />
  );
};
