import { CacheService } from '../services/cache-service';
import { PhpHubEvents } from '../models/enums/enums';
import { HubSubscription } from './hub-subscription';
import { PowerhouseProHubConnection } from './powerhouse-pro-hub-connection';
import { SentimentModel } from './sentiment-model';

export class SentimentSubscription extends HubSubscription<SentimentModel> {
  private cache: CacheService<SentimentModel> | undefined;

  private constructor() {
    super();
  }

  static fromSocketConnection = (
    socketConnection: PowerhouseProHubConnection,
    shareId?: string | undefined,
    token?: string | undefined,
  ) => {
    const model = new SentimentSubscription();
    model.fromSocketConnection(
      socketConnection,
      'subscribeToSentiments',
      'unsubscribeFromSentiments',
      'getSentiments',
      PhpHubEvents.SYRAH_SENTIMENT,
      shareId,
      token,
    );
    model.cache = CacheService.fromCallback('sentiment', model.getItems);
    return model;
  };

  mapItemsFromServer = (items: any[]) => {
    if (!items) {
      return [];
    }
    const sentiments = items.map((i) => SentimentModel.fromData(i));
    return sentiments;
  };

  get = async (symbols: string[]) => {
    if (!this.cache) {
      throw new Error('cache is undefined');
    }
    let sentiments = await this.cache.get(symbols);
    return sentiments.filter((q) => q !== undefined);
  };

  set = (models: SentimentModel[]) => {
    if (!this.cache) {
      throw new Error('cache is undefined');
    }
    this.cache.set(models);
  };
}
