import { Button, Grid, Typography, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OPBoldTypograpghy } from '../../styled';
import { IPlaceOrderLeg } from '../models';
import { absQuantity } from '../models/ts-helpers';
import { commonOrderRequestState, tsOrdetTicketPositionState } from '../states/ts-ticket-states';
import { TSAccountWidget } from '../ts-account-widget';
import { BidMidAskWidget } from './bid-mid-ask-widget';
import { GtdDateWidget } from './gtd-date-widget';
import { OrderTicketQuantityWidget } from './order-ticket-quantity-widget';
import { OrderTypeWidget } from './order-type-widget';
import { RouteDurationWidget } from './route-duration-widget';
import { ScrollableContainer } from '../ts-styles-component';
import { TSOrderType } from '@op/shared/src/models/enums/enums';
interface Props {
  orderType: string;
  setOrderType: (type: string) => void;
  orderLegs: IPlaceOrderLeg[];
  isDisabled: boolean;
  onClickSendOrder: (value: boolean) => void;
}

export const CommonOrderTicketWidget: React.FC<Props> = ({
  orderType,
  setOrderType,
  orderLegs,
  isDisabled,
  onClickSendOrder,
}) => {
  const theme = useTheme();
  const [commonOrderRequest, setCommonOrderRequest] = useRecoilState(commonOrderRequestState);
  const tsOrderTicketPositions = useRecoilValue(tsOrdetTicketPositionState);

  const hasOptionLeg = () => {
    const isOptionLegAvailable = orderLegs.some((leg) => leg.actionSymbol !== 'None');
    return isOptionLegAvailable;
  };

  const renderAccountNumber = () => {
    return (
      <Grid container>
        <Typography variant="captionSmall">Account No</Typography>
        <Typography color={theme.palette.error.main} lineHeight={1.2}>
          *
        </Typography>
        <TSAccountWidget isOrderTicket />
      </Grid>
    );
  };

  const updateFun = (type: string, value: string | Date) => {
    let clone = cloneDeep(commonOrderRequest);
    if (type === 'route') {
      clone.route = value as string;
    }
    if (type === 'duration') {
      clone.timeInForce = value as string;
    }
    if (type === 'gtdDate') {
      clone.gtdTime = value as Date;
    }
    setCommonOrderRequest(clone);
  };

  const getMinMaxWidth = () => {
    if (orderType === TSOrderType.Market) {
      return 150;
    }
    if (orderType === TSOrderType.StopLimit) {
      return 400;
    }
    return 300;
  };

  return (
    <ScrollableContainer maxWidth="xl">
      <Grid
        item
        minWidth={100}
        maxWidth={100}
        sx={{
          paddingRight: '8px',
        }}>
        <OrderTicketQuantityWidget orderTicketQuantity={absQuantity(tsOrderTicketPositions).toString()} />
      </Grid>

      <Grid
        item
        minWidth={getMinMaxWidth()}
        maxWidth={getMinMaxWidth()}
        sx={{
          paddingRight: '8px',
        }}>
        <OrderTypeWidget orderType={orderType} setOrderType={setOrderType} />
      </Grid>
      <Grid
        item
        minWidth={300}
        maxWidth={300}
        sx={{
          paddingRight: '8px',
        }}>
        <RouteDurationWidget
          hasOptionLeg={hasOptionLeg()}
          callback={(type, string) => updateFun(type, string)}
          route={commonOrderRequest.route}
          duration={commonOrderRequest.timeInForce}
        />
      </Grid>

      <Grid
        item
        minWidth={180}
        maxWidth={180}
        sx={{
          paddingRight: '8px',
        }}>
        {renderAccountNumber()}
      </Grid>
      {commonOrderRequest.timeInForce === 'GTD' && (
        <Grid
          item
          minWidth={180}
          maxWidth={180}
          sx={{
            paddingRight: '8px',
          }}>
          <GtdDateWidget gtdDate={commonOrderRequest.gtdTime} callback={(type, Date) => updateFun(type, Date)} />
        </Grid>
      )}
      <Grid
        item
        minWidth={300}
        maxWidth={300}
        sx={{
          paddingRight: '8px',
        }}>
        <BidMidAskWidget orderType={orderType} />
      </Grid>
      <Grid item minWidth={80} sx={{ marginTop: 'auto' }}>
        <Button
          disabled={isDisabled}
          variant="contained"
          size="small"
          onClick={() => onClickSendOrder(true)}
          sx={{ marginTop: 'auto', paddingX: '4px', height: '25px' }}>
          <OPBoldTypograpghy>Send</OPBoldTypograpghy>
        </Button>
      </Grid>
      <Grid item minWidth={210} maxWidth={250} sx={{ marginTop: 'auto' }}>
        <Typography display="inline-block" sx={{ color: theme.palette.error.main }}>
          *
        </Typography>
        <Typography display="inline-block" style={{ fontSize: '10px' }}>
          Mandatory fields must be filled
        </Typography>
      </Grid>
    </ScrollableContainer>
  );
};
