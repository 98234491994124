import React, { ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Sentiment } from '@op/shared/src/models/enums/enums';
import { isHubInitiatedState, selectedSymbolState } from '@op/shared/src/states';
import {
  fetchSentiments,
  sentimentAtomFamily,
  sentimentsUpdater,
  subscribeToSentiments,
  unsubscribeFromSentiments,
} from '@op/shared/src/states/sentiment-hub-states';
import { useInView } from 'react-intersection-observer';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export interface ITrendingWidgetProps {
  children: ReactNode;
  symbol: string;
  sentiment?: string;
}

export const TrendingWidget: React.FC<ITrendingWidgetProps> = ({
  sentiment,
  children,
  symbol,
}: ITrendingWidgetProps) => {
  const hubSentiment = useRecoilValue(sentimentAtomFamily(symbol));
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [delay, setDelay] = useState(2000);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ref, inView, entry] = useInView({
    threshold: 1,
    delay: delay,
  });

  const fetchSentiment = async () => {
    const fetchedSentiments = await fetchSentiments([symbol]);
    if (!fetchedSentiments || fetchedSentiments.length === 0) {
      return;
    }
    setSentiments(fetchedSentiments);
  };

  React.useEffect(() => {
    if (!isHubInitiated || !symbol || !inView) {
      return;
    }
    (async () => {
      // setDelay(5000);
      if (!hubSentiment) {
        await fetchSentiment();
      }
      await subscribeToSentiments([symbol]);
    })();
  }, [isHubInitiated, inView]);

  useEffect(() => {
    if (inView || !hubSentiment) {
      return;
    }
    //If symbol is selected and loaded in other panels like quotebar, how panel etc. we should not unsubscribe the quotes.
    if (selectedSymbol?.toLowerCase() === hubSentiment.symbol.toLowerCase()) {
      return;
    }
    (async () => {
      await unsubscribeFromSentiments([symbol]);
    })();
  }, [inView, hubSentiment]);

  const appliedLongTermSentiment = (hubSentiment?.syrahLongSentiment?.sentiment.toString() || sentiment || '')
    .trim()
    .toUpperCase();
  let borderColor = 'transparent';
  if (appliedLongTermSentiment === Sentiment.BULLISH.toString().toUpperCase()) {
    borderColor = 'success.main';
  } else if (appliedLongTermSentiment === Sentiment.BEARISH.toString().toUpperCase()) {
    borderColor = 'error.main';
  } else if (appliedLongTermSentiment === Sentiment.NEUTRAL.toString().toUpperCase()) {
    borderColor = 'warning.main';
  }
  return (
    <Box
      ref={ref}
      className="six_month_historical_trend_step"
      sx={{
        pl: 1,
        py: 0,
        pr: 0,
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid',
        borderLeftColor: borderColor,
        borderRadius: 1,
        height: '100%',
      }}>
      {children}
    </Box>
  );
};
