import { atom, atomFamily, selector } from 'recoil';
import { guardRecoilDefaultValue } from '..';
import { ICallOptimal, IncomeCombination, IncomeStrategies } from '../../models';

export const costBasisState = atom<number | undefined>({
  key: 'costBasisStateKey',
  default: undefined,
});

export const sharesState = atom<number | undefined>({
  key: 'sharesStateKey',
  default: undefined,
});

// export const isIncomeSettingsUpdatedState = atom<boolean>({
//   key: 'isIncomeSettingsUpdatedStateKey',
//   default: false,
// });

/**
 * @deprecated DO NOT USE IT. REMOVE IT.
 .* used in option grid to carry ICallOPtions which can be available from howData itself.
 */
export const incomeStrategiesState = atom<IncomeStrategies | undefined>({
  key: 'incomeStrategiesStateKey',
  default: undefined,
});

export const incomeCombinationSelectedIdState = atom<number>({
  key: 'incomeCombinationSelectedIdStateKey',
  default: 0,
});

//ToDO: correct below to ahve date.
export const selectedOptionGridState = atomFamily<ICallOptimal | undefined, number>({
  key: 'selectedOptionGridStateKey',
  default: undefined,
});

export const selectedOptionGridExpiryState = atomFamily<Date | undefined, number>({
  key: 'selectedOptionGridExpiryStateKey',
  default: undefined,
});

export const selectedCombinationPriorityState = atomFamily<number | undefined, number>({
  key: 'selectedPriorityStateKey',
  default: undefined,
});

export const incomeCombinationAtomFamilyState = atomFamily<IncomeCombination | undefined, string>({
  key: 'incomeCombinationsAtomFamilyStateKey',
  default: undefined,
});

export const incomeCombinationUpdaterState = selector<(IncomeCombination | undefined)[]>({
  key: 'incomeCombinationUpdaterStateKey',
  get: () => {
    throw new Error('This is only setter');
  },
  set: ({ set }, combinations) => {
    if (guardRecoilDefaultValue(combinations)) {
      return;
    }
    for (let i = 0; i < combinations.length; i++) {
      const combination = combinations[i];
      set(incomeCombinationAtomFamilyState(i.toString()), combination);
      set(selectedCombinationPriorityState(i), combination?.priority);
    }
  },
});

export const allIncomeCombinationsState = selector({
  key: 'allIncomeCombinationsStateKey',
  get: ({ get }) => {
    const stockCombination0 = get(incomeCombinationAtomFamilyState('0'));
    const stockCombination1 = get(incomeCombinationAtomFamilyState('1'));
    return [stockCombination0, stockCombination1] as (IncomeCombination | undefined)[];
  },
});

export const incomeStrategyHasShareState = atom<number>({
  key: 'incomeStrategyHasShareStateKey',
  default: 0,
});

export const resetIncomeState = atom<{ reason: string; shouldReset: boolean }>({
  key: 'resetIncomeStateKey',
  default: { reason: 'initialized', shouldReset: true },
});
