import { TextFieldProps, useTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import React, { ElementType } from 'react';
import { IconComponent } from '../common';
import { OptionsPlayTextField } from './options-play-components';
import { TSTextField } from '../tradestation/ts-styles-component';
interface IOptionsPlayDatePickerWidgetProps {
  value: string | Date;
  onChangeDate: (e: any) => void;
  onBlur: (e: any) => void;
  min?: Date | undefined;
  max?: Date | undefined;
  isTradeStation?: boolean;
}
export const OptionsPlayDatePickerWidget: React.FC<IOptionsPlayDatePickerWidgetProps> = ({
  value,
  onChangeDate,
  onBlur,
  min,
  max,
  isTradeStation,
}: IOptionsPlayDatePickerWidgetProps) => {
  const theme = useTheme();

  const DateIcon = () => {
    return (
      <div style={{ margin: '4px' }}>
        <IconComponent name="calendar" stroke={theme.palette.primary.main} />
      </div>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        /**
         * For Value condition has been written due to value is coming undefined in intial load
         * Thus field shows red border.
         * So,show the current date if value is undefined.
         */
        value={value ? DateTimeHelper.resolveDate(value) : DateTimeHelper.getCurrentDate()}
        onChange={(e) => onChangeDate(e)}
        format="MM/dd/yyyy"
        minDate={min}
        maxDate={max}
        slotProps={{
          textField: {
            variant: 'outlined',
            sx: {
              button: { ml: 0, p: 0 },
              svg: { width: '18px', height: '18px' },
              width: '100%',
            },
          },
        }}
        slots={{
          textField: isTradeStation
            ? (TSTextField as ElementType<TextFieldProps>)
            : (OptionsPlayTextField as ElementType<TextFieldProps>),
          openPickerIcon: DateIcon,
        }}
      />
    </LocalizationProvider>
  );
};
