class User {
  email = '';
  firstName = '';
  lastName = '';

  static fromJson = (data: User): User => {
    const user = new User();
    user.email = data.email;
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    return user;
  };

  fullName = (): string => {
    if (this.firstName && this.firstName.length && this.lastName && this.lastName.length) {
      return `${this.firstName} ${this.lastName}`;
    }
    if (this.firstName && this.firstName.length) {
      return this.firstName;
    }
    return '';
  };
}

export default User;
