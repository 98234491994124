import ApplicationContext from './application-context';
import BasicCombination from './basic-combination';

const defaultCommissionCost = (combination: BasicCombination) => {
  const commissionSchedule = ApplicationContext.configuration.userSettings?.commissionSchedule;
  if (!commissionSchedule) {
    return 0;
  }
  let result = 0;
  if (combination.hasOnlyStx()) {
    combination.buyablePositions.forEach(function (pos) {
      const commission = commissionSchedule.perShareCommission * pos.absQuantity;
      result += commission;
    });
  }
  if (commissionSchedule.perOptionCommission) {
    combination.buyablePositions.forEach(function (pos) {
      let commission = commissionSchedule.stockTradeCommission;
      if (!pos.isSecurityType) {
        commission += commissionSchedule.perOptionCommission * pos.absQuantity;
      }
      result += commission;
    });
  }
  return result;
};

export const tradeCostCommission = (combination: BasicCombination) => {
  return defaultCommissionCost(combination);
};
