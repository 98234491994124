import { Box, Grid } from '@mui/material';
import { ApiService } from '@op/shared/src/services';
import React, { useEffect } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

export const ScreenshotWidget: React.FC = () => {
  const screenshotRoute = useMatch('/screenshot/:id');
  const navigate = useNavigate();
  const [screenshotId, setscreenshotId] = React.useState('');
  const apiEndPoint = ApiService.instance.API_ENDPOINT;
  const imgUrl = `${apiEndPoint}/sharing/screenshot/${screenshotId}`;

  useEffect(() => {
    if (screenshotRoute) {
      const id = screenshotRoute.params.id;
      if (!id) {
        navigate('/login', { replace: true });
        return;
      }
      setscreenshotId(id);
    }
  }, []);
  return (
    <>
      {screenshotId !== '' ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: '100vh',
          }}>
          <Grid item xs="auto">
            <Box
              sx={{
                display: 'inline-block',
                p: 1,
                border: '2px solid white',
                borderColor: 'primary.main',
                background: 'white',
              }}>
              <img src={imgUrl} alt="" />
            </Box>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};
