import { IForgotPasswordProps } from './';

export class EditForgotPassword implements IForgotPasswordProps {
  email = '';

  public static fromJson = (data: EditForgotPassword): EditForgotPassword => {
    const model = new EditForgotPassword();
    model.email = data.email;
    return model;
  };
}
