export interface ISubscriptionPaymentForm {
  plan?: string;
  cardNumber: string;
  cardName: string;
  cardMonth: string;
  cardYear: string;
  cardCvv: string;
  country?: string;
  address?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  cardNumberMaxLength?: number;
  cardSecurityCodeMaxLength?: number;
  creditCardLastFourNumbers?: number;
  pricingModelCode?: string;
  billingDay?: number;
  billingMonth?: number;
}

export const defaultSubscriptionPaymentData: ISubscriptionPaymentForm = {
  plan: 'Monthly',
  cardNumber: '',
  cardName: '',
  cardMonth: '',
  cardYear: '',
  cardCvv: '',
  country: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  phone: '',
  cardNumberMaxLength: 16,
  cardSecurityCodeMaxLength: 4,
  creditCardLastFourNumbers: 0,
  pricingModelCode: 'ALACARTE',
  billingDay: 0,
  billingMonth: 0,
};
