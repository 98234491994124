import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
  Box,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  SwipeableDrawer,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { isNordic } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { getThemeType } from '@op/shared/src/models/theme-type';
import { ProfileDetailsService } from '@op/shared/src/services/profile-details-service';
import {
  accessTokenKey,
  accountState,
  configurationState,
  customizationState,
  selectedSymbolState,
  themeState,
} from '@op/shared/src/states';
import { lightboxState } from '@op/shared/src/states/lightbox-open-close';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { LightBoxWidget } from '../header';
import InstagramIcon from '../images/instagram_icon.png';
import ST from '../images/st.png';
import LocalizationContext from '../react-i18next/localization-context';
import { MobileAppLinkWidget } from '../subscription/mobile-app-link-widget';
import { tsAuthenticationStatusState, tsEnvironmentState } from '../tradestation';
import { logOutTradeStation } from '../tradestation/services';
import BusinessQueriesDialog from './business-queries-widget';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { DisclaimerWidget } from './disclaimer-widget';
import { GuideWidget } from './guide-tour';
import { LockIconMenu } from './lock-icon-menu-widget';
import VideoIconMenu from './video-icon-widget';

export const colorChange = {
  color: 'blue',
};
export const LinkDeclartion = {
  textDecoration: 'none',
};

// const stepdivs = [
// { target: '.whatPanelSwitcher_helpPinPlaceholder', stepindex: 1 },
// { target: '.tradeIdeas_helpPinPlaceholder', stepindex: 2 },
// { target: '.six_month_historical_trend_step', stepindex: 3 },
// { target: '.grid_technicalRank_helpPinPlaceholder', stepindex: 3 },
// { target: '.grid_companyName_helpPinPlaceholder', stepindex: 4 },
// { target: '.sentiment_helpPinPlaceholder', stepindex: 5 },
// { target: '.symbolInput_helpPinPlaceholder', stepindex: 5 },
// { target: '.trend_terms_step', stepindex: 6 }, // need to check
// { target: '.monthControl_helpPinPlaceholder', stepindex: 7 },
// { target: '.supportAndResistance_helpPinPlaceholder', stepindex: 8 },
// { target: '.priceAction_helpPinPlaceholder', stepindex: 8 },
// { target: '.tradingStrategiesTab_helpPinPlaceholder', stepindex: 9 },
// { target: '.incomeStrategiesTab_helpPinPlaceholder', stepindex: 9 },
// { target: '.sentimentBtnGroup_helpPinPlaceholder', stepindex: 9 },
// { target: '.profitAndLossChart_helpPinPlaceholder', stepindex: 10 },
// { target: '.tradeButton_helpPinPlaceholder', stepindex: 11 },
// { target: '.modifyButton_helpPinPlaceholder', stepindex: 12 },
// { target: '.priceAndDataSlider_helpPinPlaceholder', stepindex: 13 },
// { target: '.volatilitySlider_helpPinPlaceholder', stepindex: 14 },
// { target: '.plainEnglish_helpPinPlaceholder', stepindex: 15 },
// { target: '.investmentAmount_helpPinPlaceholder', stepindex: 16 },
// { target: '.riskAmount_helpPinPlaceholder', stepindex: 17 },
// { target: '.expiryDropdownBox_helpPinPlaceholder', stepindex: 18 },
// { target: '.tradingRangeSlider_helpPinPlaceholder', stepindex: 19 },
// { target: '.supportAndResistanceSliderMarkers_helpPinPlaceholder', stepindex: 20 },
// { target: '.optionsplayScore_helpPinPlaceholder', stepindex: 21 },
// { target: '.sentimentFilterButtons_helpPinPlaceholder', stepindex: 22 },
// ];

const AccountMenuWidget: React.FC = () => {
  const account = useRecoilValue(accountState);
  const configuration = useRecoilValue(configurationState);
  const openlightbox = useRecoilValue(lightboxState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const navigate = useNavigate();
  const { t } = React.useContext(LocalizationContext);
  const setAccount = useSetRecoilState(accountState);
  const setActiveTheme = useSetRecoilState(themeState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [state, setState] = React.useState(false);
  const customization = useRecoilValue(customizationState);
  const [tsAuthenticationStatus, setTsAuthenticationStatus] = useRecoilState(tsAuthenticationStatusState);
  const theme = useTheme<Theme>();
  const setNotifications = useSetRecoilState(notificationsState);

  const icon = {
    display: 'flex',
    minWidth: 30,
  };
  const menuColor = {
    color: theme.palette.text.primary,
  };

  if (!configuration || !customization) {
    return null;
  }

  // if (!configuration || !customization?.showUserMenu) {
  //   return null;
  // }
  const showLiquidityReport = customization.showLiquidityReport;
  const showCoveredCalls = customization.showCoveredCalls;
  const showShortPuts = customization.showShortPuts;
  const showCreditSpread = customization.showCreditSpread;
  const showEarningsCalendar = customization.showEarningsCalendar;
  const showGuide = customization.showGuide;
  const showBusinessInquiries = configuration.showBusinessInquiries;
  const showSupportButtons = configuration.showSupportButtons;
  const showFaq = configuration.showFaq;
  const showFooterOptions = customization.showFooterOptions;
  const showLearnToTradeOptions = customization.showLearnToTradeOptions;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  const onProfileClick = () => {
    navigate('/profile');
    setState(false);
  };

  const websiteUrl = async () => {
    if (!configuration) {
      return '';
    }
    let uri: string = configuration.widgetsSiteUrl;
    const response = await ProfileDetailsService.exitBeta();
    if (response.data) {
      uri = uri + '/authentication/hashToken/' + response.data.hashtoken;
    }
    window.location.replace(uri);
  };

  const setDefaultTheme = () => {
    const theme = getThemeType('light');
    setActiveTheme(theme);
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.SignOut,
      selectedSymbol as string,
    );
  };

  const tradeStationLogout = async () => {
    if (!account || !account.isAllowTradeStationAccountLinking) {
      return undefined;
    }
    if (!tsAuthenticationStatus) {
      return undefined;
    }
    const logoutRes = await logOutTradeStation(tsEnv);
    if (logoutRes.hasErrors || logoutRes.data === '') {
      setNotifications([
        {
          type: 'error',
          content: logoutRes.errors[0].message,
          isTradeStation: true,
        },
      ]);
      return;
    }
    const url = logoutRes.data;
    window.localStorage.removeItem('tsRedirectURL');
    window.localStorage.removeItem('tsEnv');
    return url;
  };

  const logout = () => {
    logActivity('BUTTON', 'signOutButton');
    localStorage.removeItem('lightbox_state');
    localStorage.removeItem('additionalData');
    localStorage.removeItem(accessTokenKey);
    setAccount(undefined);
    setDefaultTheme();
    navigate('/login', { replace: true });
    window.location.reload();
  };

  /**
   * click on op logout
   * if tsAuthenticationStatus is true
   *  - logout from the ts and re- direct to bromley
   *  - call -> ts-logout.tsx
   *  - then logout operation from ts-logout.tsx
   * else
   *  - navigate to logout -> directly call logout()
   */

  const onLogout = async () => {
    if (tsAuthenticationStatus) {
      const isTradeStationLoggedOutURL = await tradeStationLogout();
      if (isTradeStationLoggedOutURL !== '') {
        setTsAuthenticationStatus(false);
        window.open(isTradeStationLoggedOutURL, '_self');
        window.localStorage.setItem('opLogoutFromTS', JSON.stringify(true));
      }
      return;
    }
    logout();
  };

  const renderFAQ = () => {
    if (!showSupportButtons || !showFaq) {
      return;
    }
    return (
      <Link href={'//feedback.optionsplay.com/knowledgebase'} target="_blank" style={LinkDeclartion} rel="noreferrer">
        <CommonListItemIcon icon={<ContactSupportOutlinedIcon />} text={t('footer.labels.faq')} key={'FAQ'} />
      </Link>
    );
  };

  const renderBussinessEnquires = () => {
    if (!showBusinessInquiries) {
      return;
    }
    return (
      <div key="Buniess Inqueries">
        <BusinessQueriesDialog />
      </div>
    );
  };

  const renderSocialLinkIcons = () => {
    if (ApplicationContext.configuration && !ApplicationContext.configuration.showSocialButtons) {
      return;
    }
    return (
      <List sx={{ padding: 0 }}>
        <ListItem
          key="announce"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: 0,
          }}>
          <ListItemIcon color="primary" style={icon}>
            <Link
              href={'//www.facebook.com/OptionsPlay?ref=hl'}
              target="_blank"
              style={LinkDeclartion}
              rel="noreferrer">
              <FacebookIcon style={{ color: '#3b5998' }} />
            </Link>
          </ListItemIcon>
          <ListItemIcon style={icon}>
            <Link href={'//twitter.com/OptionsPlay'} target="_blank" style={LinkDeclartion} rel="noreferrer">
              <TwitterIcon style={{ color: '#00acee' }} />
            </Link>
          </ListItemIcon>
          <ListItemIcon style={icon}>
            <Link
              href={'//www.linkedin.com/company/optionsplay/'}
              target="_blank"
              style={LinkDeclartion}
              rel="noreferrer">
              <LinkedInIcon style={{ color: '#0e76a8' }} />
            </Link>
          </ListItemIcon>
          <ListItemIcon style={icon}>
            <Link
              href={'https://www.instagram.com/options_play_official/'}
              target="_blank"
              style={LinkDeclartion}
              rel="noreferrer">
              <img alt="stock twits" src={InstagramIcon} style={{ width: 20, height: 20 }} />
            </Link>
          </ListItemIcon>
          <ListItemIcon style={icon}>
            <Link
              href={'//stocktwits.com/OptionsPlay?q=OptionsPlay'}
              target="_blank"
              style={LinkDeclartion}
              rel="noreferrer">
              <img alt="stock twits" src={ST} style={{ width: 25, height: 25, marginTop: 5 }} />
            </Link>
          </ListItemIcon>
        </ListItem>
      </List>
    );
  };

  const renderVersionUI = () => {
    return (
      <Grid item xs={12} textAlign={'center'}>
        <Typography>{`Version : ${process.env.REACT_APP_VERSION}`}</Typography>
      </Grid>
    );
  };

  const renderSocialButtons = () => {
    if (isNordic()) {
      return;
    }
    return renderSocialLinkIcons();
  };

  const renderLiquidity = () => {
    if (!showLiquidityReport || isNordic()) {
      return;
    }
    return (
      <div key="liquidity">
        <Link target="_blank" href={configuration.footerLiquidityReportUrl} style={LinkDeclartion}>
          <CommonListItemIcon
            icon={<PaymentsOutlinedIcon />}
            text={t('footer.labels.liquidity')}
            key={'liquidity report'}
          />
        </Link>
      </div>
    );
  };

  const renderCoveredCallsReport = () => {
    if (!showCoveredCalls) {
      return;
    }
    return (
      <div key="coveredCalls">
        <Link target="_blank" href={configuration.footerCoveredCallsUrl} style={LinkDeclartion}>
          <CommonListItemIcon
            icon={<PaymentsOutlinedIcon />}
            text={t('footer.labels.coveredCalls')}
            key={'coveredCalls report'}
          />
        </Link>
      </div>
    );
  };

  const renderShortPutsReport = () => {
    if (!showShortPuts || configuration.footerShortPutsUrl.trim().length === 0) {
      return;
    }
    return (
      <div key="shortPuts">
        <Link target="_blank" href={configuration.footerShortPutsUrl} style={LinkDeclartion}>
          <CommonListItemIcon
            icon={<PaymentsOutlinedIcon />}
            text={t('footer.labels.shortPuts')}
            key={'shortPuts report'}
          />
        </Link>
      </div>
    );
  };

  const renderEarnings = () => {
    if (!showEarningsCalendar || isNordic()) {
      return;
    }
    return (
      <div key="Earnings">
        <Link target="_blank" href={configuration.footerEarningsCalendarUrl} style={LinkDeclartion}>
          <CommonListItemIcon
            icon={<MonetizationOnOutlinedIcon />}
            text={t('footer.labels.earningsCal')}
            key={'earnings report'}
          />
        </Link>
      </div>
    );
  };

  const renderCreditSpreads = () => {
    if (!showCreditSpread || isNordic()) {
      return;
    }
    return (
      <div key="creditSpreads">
        <Link target="_blank" href={configuration.footerCreditSpreadUrl} style={LinkDeclartion}>
          <CommonListItemIcon
            icon={<IconComponent name="creditSpread" size={26} stroke={theme.palette.primary.dark} />}
            text={t('footer.labels.creditSpread')}
            key={'credit spreads'}
          />
        </Link>
      </div>
    );
  };

  const renderSecurityInformation = () => {
    /**
     * As per the client request, In embedding platforms, security information(regarding authorize.net)
     * Should be hidden.
     */
    if (!showFooterOptions || customization.isEmbeddingPlatform) {
      return;
    }
    return (
      <div key="Lock">
        <LockIconMenu />
      </div>
    );
  };

  const renderWalkthrough = () => {
    if (!showFooterOptions) {
      return;
    }
    return (
      <div key="video">
        <VideoIconMenu />
      </div>
    );
  };

  const renderLearnToTrade = () => {
    if (!showLearnToTradeOptions) {
      return;
    }
    return (
      <Link
        href={
          isNordic()
            ? 'https://optionsplay.lpages.co'
            : '//www.youtube.com/playlist?list=PLSC1eKlsCR8Tsa4_ONvZ9rfaFIzKPZzPc'
        }
        target="_blank"
        style={LinkDeclartion}
        rel="noreferrer">
        <CommonListItemIcon
          icon={<SchoolOutlinedIcon />}
          text={t('footer.labels.learnToTradeOptions')}
          key={'Learn to Trade Options'}
        />
      </Link>
    );
  };

  const renderMenuList = () => {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: 200,
        }}>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
          }}>
          <List component="nav">
            {!customization.isEmbeddingPlatform && (
              <CommonListItemIcon
                icon={<AccountCircleOutlinedIcon />}
                text={t('header.labels.profile')}
                callback={onProfileClick}
                key={'profile'}
              />
            )}
            {renderSecurityInformation()}
            {renderWalkthrough()}
            {showGuide && <GuideWidget />}
            {renderFAQ()}
            <div key="Disclaimer">
              <DisclaimerWidget showTabName={true} />
            </div>
            {renderLiquidity()}
            {renderEarnings()}
            {renderCreditSpreads()}
            {renderCoveredCallsReport()}
            {renderShortPutsReport()}
            {renderBussinessEnquires()}
            {renderLearnToTrade()}
            {!customization.isEmbeddingPlatform && (
              <div id="signout">
                <CommonListItemIcon
                  icon={<ExitToAppIcon />}
                  text={t('header.labels.signOut')}
                  callback={onLogout}
                  key={'SignOut'}
                />
              </div>
            )}
            {configuration.enablePreviewBeta && (
              <div id="exitBeta">
                <Link href={''} onClick={() => websiteUrl()} target="_self" style={LinkDeclartion} rel="noreferrer">
                  <CommonListItemIcon
                    icon={<DoubleArrow style={{ transform: 'rotate(200deg)' }} />}
                    text={'Exit Preview'}
                    key={'Learn to Trade Options'}
                  />
                </Link>
              </div>
            )}
          </List>
          {/* <MobileAppLinkWidget /> */}
        </Box>
        <Box sx={{ position: 'sticky', bottom: 0 }}>
          {renderVersionUI()}
          {renderSocialButtons()}
        </Box>
      </Box>
    );
  };
  return (
    <>
      <IconButton onClick={toggleDrawer(true)} size="large">
        <MenuIcon style={menuColor} />
      </IconButton>
      <SwipeableDrawer
        variant="temporary"
        id="account-menu"
        open={state}
        anchor="right"
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}>
        {renderMenuList()}
      </SwipeableDrawer>
      <div>
        <React.Suspense fallback={<div>Loading</div>}>{openlightbox ? <LightBoxWidget /> : ''}</React.Suspense>
      </div>
      {/* {run && guidestartindex && <GuideWidget />} */}
    </>
  );
};

export default AccountMenuWidget;
