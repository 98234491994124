import { Grid, Typography } from '@mui/material';
import { PortfolioCombinationEntities, PortfolioCombinationEntity } from '@op/shared/src/models';
import { isHubInitiatedState, sortsPortfolioState } from '@op/shared/src/states';
import {
  filtersPortfoliosState,
  subscribePortfolioAlerts,
  unsubscribePortfolioAlerts,
} from '@op/shared/src/states/portfolio-alerts-hub-states';
import {
  isPortfolioCombinationsLoadingState,
  portfolioCombinationsOfSelectedAccountsState,
  portfolioSelectedAccountIdsSelectorState,
} from '@op/shared/src/states/portfolio-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Pager } from '../ideas/pager';
import { PortfolioCombinationWidget } from './portfolio-combination-widget';
import { PortfolioWhatLoaderWidget } from './portfolio-what-loader-widget';

export interface IPorfolioCombinationsOfAnAccountWidgetProps {}

export const PorfolioCombinationsOfAccountsWidget: React.FC<IPorfolioCombinationsOfAnAccountWidgetProps> = () => {
  const combinationEntitiesOfSelectedAccounts = useRecoilValue(portfolioCombinationsOfSelectedAccountsState);
  const sorts = useRecoilValue(sortsPortfolioState);
  const setIsPortfolioCombinationsLoading = useSetRecoilState(isPortfolioCombinationsLoadingState);
  const portfolioFilters = useRecoilValue(filtersPortfoliosState);
  const [accountEntities, setAccountEntities] = useState<PortfolioCombinationEntities>();
  const [pagedAccountEntity, setPagedAccountEntity] = useState<PortfolioCombinationEntity[]>();
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  const portfolioSelectedAccountIds = useRecoilValue(portfolioSelectedAccountIdsSelectorState);

  useEffect(() => {
    if (!combinationEntitiesOfSelectedAccounts) {
      return;
    }
    setIsPortfolioCombinationsLoading(true);
    const cloned = PortfolioCombinationEntities.fromSelf(combinationEntitiesOfSelectedAccounts);
    cloned.filter(portfolioFilters).sort(sorts);
    setAccountEntities(cloned);
  }, [combinationEntitiesOfSelectedAccounts, sorts, portfolioFilters]);

  useEffect(() => {
    if (!accountEntities) {
      return;
    }
    (async () => {
      const entities = accountEntities.combinations; //.slice(0, itemsPerPage);
      if (!entities) {
        return [];
      }
      setPagedAccountEntity(entities);
      setIsPortfolioCombinationsLoading(false);
    })();
  }, [accountEntities]);

  useEffect(() => {
    if (!accountEntities) {
      return;
    }
    if (!isHubInitialized) {
      return;
    }
    (async () => {
      await subscribePortfolioAlerts(portfolioSelectedAccountIds);
    })();
    //Clearing function
    return () => {
      (async () => {
        await unsubscribePortfolioAlerts(portfolioSelectedAccountIds);
      })();
    };
  }, [accountEntities, isHubInitialized, portfolioSelectedAccountIds]);

  if (!combinationEntitiesOfSelectedAccounts) {
    return <PortfolioWhatLoaderWidget />;
  }

  if (!accountEntities) {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Typography>No portfolio items found</Typography>
      </Grid>
    );
  }

  if (!pagedAccountEntity) {
    return <>pagedAccountEntity loading...</>;
  }

  return (
    <Grid container>
      <Pager
        data={pagedAccountEntity}
        itemsPerPage={8}
        getNode={(idea, i) => (
          <Grid item xs={12} key={i} id={`js-protfolio-accounts-combination-${i}`} py={0.6} pr={0}>
            <PortfolioCombinationWidget data={idea as PortfolioCombinationEntity} index={i} />
          </Grid>
        )}
        isPortfolio={true}
      />
    </Grid>
  );
};
