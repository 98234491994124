import { Grid, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { sectorFilterState, selectedSymbolState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';
import { GuideItem } from '../../side-menu';
import {
  MenuProps,
  OPBoldTypograpghy,
  OPLightTypograpghy,
  OPSelectDropDownHeader,
  OPSelectField,
  OPToggleButton,
  StyledDropdownItemWithBorder,
} from '../../styled';

export const SectorsFilterWidget2: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const [sectorFilter, setSectorFilter] = useRecoilState(sectorFilterState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  if (!sectorFilter) {
    return <></>;
  }

  const onSectorChange = (sectorValue: string): void => {
    const clone = cloneDeep(sectorFilter);
    for (const f of clone.filters) {
      f.selected = f.value === sectorValue;
    }
    setSectorFilter(clone);
    logActivity(sectorValue);
  };

  const logActivity = (sectorValue: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      'sectorFilter',
      'sectorFilter',
      sectorValue,
      'click',
      `${selectedSymbol}`,
    );
  };

  const getValue = (): string => {
    const sector = sectorFilter.filters.find((s) => s.selected);
    if (!sector) {
      return '';
    }
    return sector.value as string;
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <GuideItem selector=".sectorsDropdown_helpPinPlaceholder" />
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(sectorFilter.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item p={0.5} xs={10.5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <OPSelectField
            className="sectorFilter"
            variant="outlined"
            value={getValue()}
            renderValue={(selected: any) => {
              return (
                <OPToggleButton selected={true} value={undefined}>
                  <OPLightTypograpghy>{selected}</OPLightTypograpghy>
                </OPToggleButton>
              );
            }}
            sx={{
              width: '100%',
              height: 40,
              '& .MuiSvgIcon-root': {
                color: theme.palette.selectAndTextField.main,
              },
            }}
            MenuProps={MenuProps}
            onChange={(event) => onSectorChange(event.target.value as string)}>
            <OPSelectDropDownHeader type="sectors" />
            {sectorFilter.filters.map((sector) => {
              return (
                <StyledDropdownItemWithBorder
                  id="sectorFilter"
                  className="sectorFilter"
                  key={sector.value as string}
                  value={sector.value}
                  data-name="sectorFilter"
                  data-value={`selected ${sector.value} from FilterIdeas`}
                  color="default">
                  {sector.title}
                </StyledDropdownItemWithBorder>
              );
            })}
          </OPSelectField>
        </Grid>
        {/* <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={sectorSelect}
            onClick={handleSwitchToggle}
            icon={<StyledCheckBox />}
            checkedIcon={<StyledCheckBox sx={{ backgroundColor: theme.palette.primary.main }} />}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
