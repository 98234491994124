import { LanguageType } from '@op/shared/src/models';
import { globalizationState } from '@op/shared/src/states';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useRecoilValue } from 'recoil';
import { OptionsPlayInputWidgetProps } from './options-play-input-widget-props';

export const OptionsPlayPercentageFormatInputWidget = React.forwardRef<
  typeof NumericFormat,
  OptionsPlayInputWidgetProps
>(function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
  const { onChange, ...other } = props;
  const globalization = useRecoilValue(globalizationState);
  const thousandSeparator = globalization?.active?.name === LanguageType.Swedish ? ' ' : ',';
  const decimalSeparator = globalization?.active?.name === LanguageType.Swedish ? ',' : '.';

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
          values: values,
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowedDecimalSeparators={['.', ',']}
      valueIsNumericString
      decimalScale={2}
      suffix="%"
    />
  );
});
