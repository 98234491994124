import { Grid, Typography } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { howWhyRanksDataState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';

export const VolumeWidget: React.FC = () => {
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const { t } = React.useContext(LocalizationContext);
  if (!howWhyRanksData.how) {
    return <>No quote data</>;
  }
  const quote = howWhyRanksData.how.quote;

  return (
    <>
      <Grid item>
        <Typography variant="captionSmall">{t('why.volume')}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="captionSmall">
          <strong>{NumberFormatHelper.toFormattedVolume(quote.volume)}</strong>
        </Typography>
      </Grid>
    </>
  );
};
