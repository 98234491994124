import { atom } from 'recoil';
import { SortingType } from '../models';
import { Sorts } from '@op/shared/src/models';

export const sortTradesState = atom({
  key: 'sortTradesState',
  default: SortingType.ASCENDING,
});

export const sortValue = atom({
  key: 'sortValue',
  default: 'companyName',
});

export const sortsWatchlistState = atom({
  key: 'sortsWatchlistState',
  default: new Sorts([Sorts.WatchListsDefaultSorts.data[0]]),
});

export const sortsPortfolioState = atom({
  key: 'sortsPortfolioStateKey',
  default: new Sorts([Sorts.PortfolioDefaultSorts.data[0]]),
});
