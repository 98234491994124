import { ExpandedQuote, SortingType, Sorts } from '..';
import { SentimentModel } from './../../hubs/sentiment-model';
import { Quote } from './watch-lists-quote';

export class WatchList {
  id = -1;
  name = '';
  quotes: Quote[] = [];
  type = '';
  isBookmarked = false;
  portfolioPositionsCount = 0;
  tradeIdeasCount = 0;

  static fromData = (data: any) => {
    const list = new WatchList();
    list.id = data.id;
    list.name = data.name;
    list.type = data.type;
    list.quotes = data.quotes.map((q: any) => Quote.fromData(q));
    list.isBookmarked = data.isBookmarked;
    list.portfolioPositionsCount = data.portfolioPositionsCount;
    list.tradeIdeasCount = data.tradeIdeasCount;
    return list;
  };

  public static fromSelf = (self: WatchList) => {
    if (!self) {
      throw new Error('Self is undefined');
    }
    const clone = new WatchList();
    clone.id = self.id;
    clone.name = self.name;
    clone.type = self.type;
    clone.quotes = self.quotes.map((q: any) => Quote.fromSelf(q));
    clone.isBookmarked = self.isBookmarked;
    clone.portfolioPositionsCount = self.portfolioPositionsCount;
    clone.tradeIdeasCount = self.tradeIdeasCount;
    return clone;
  };

  public static getDefaultWatchList = (items: WatchList[]) => {
    const getSectorWatchList = items.find((v) => v.name === 'Sector SPDRs');
    const defaultWatchList = getSectorWatchList || items[0];
    return defaultWatchList;
  };

  public fillQuotes = (quotes: ExpandedQuote[] | undefined) => {
    if (!quotes) {
      return;
    }
    const watchListMap = new Map<string, Quote>();
    for (let quoteItem of this.quotes) {
      // replace quote as watchitem
      const symbol = quoteItem.symbol.trim().toUpperCase();
      if (watchListMap.has(symbol)) {
        continue;
      }
      watchListMap.set(symbol, quoteItem);
    }

    for (let quote of quotes) {
      const symbol = quote.symbol.trim().toUpperCase();
      const watchList = watchListMap.get(symbol);
      if (!watchList) {
        continue;
      }
      watchList.expandedQuote = quote;
    }
  };

  public fillSentiments = (sentiments: SentimentModel[] | undefined) => {
    if (!sentiments) {
      return;
    }
    let resultingSentiments = sentiments.map((q) => SentimentModel.fromSelf(q));
    resultingSentiments = resultingSentiments.concat(sentiments.map((q) => SentimentModel.fromSelf(q)));
    const watchListSentimentMap = new Map<string, SentimentModel>();
    for (let sentiment of resultingSentiments) {
      if (!sentiment.symbol) {
        continue;
      }
      const symbol = sentiment.symbol.trim().toUpperCase();
      if (watchListSentimentMap.has(symbol)) {
        continue;
      }
      watchListSentimentMap.set(symbol, sentiment);
    }
    if (watchListSentimentMap.size === 0) {
      return;
    }
    for (let quote of this.quotes) {
      const symbol = quote.symbol.trim().toUpperCase();
      const watchListSentiment = watchListSentimentMap.get(symbol);
      if (!watchListSentiment) {
        continue;
      }
      if (watchListSentiment.syrahShortSentiment) {
        quote.sentiment = watchListSentiment.syrahShortSentiment?.sentiment;
      }
    }
  };

  public sort = (sorts: Sorts) => {
    if (!sorts) {
      return this;
    }
    const watchLists = this.sortByCompanyName(sorts)
      .sortBySentiment(sorts)
      .sortByScore(sorts)
      .sortByPrice(sorts)
      .sortByPin();
    return watchLists;
  };

  private sortByPin = () => {
    this.quotes.sort((a, b) => Number(b.isPinned) - Number(a.isPinned));
    return this;
  };

  private sortByCompanyName = (sorts: Sorts): WatchList => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'companyName') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2) {
            if (quote1.symbol > quote2.symbol) {
              return 1;
            }
            if (quote1.symbol < quote2.symbol) {
              return -1;
            }
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2) {
            if (quote1.symbol > quote2.symbol) {
              return -1;
            }
            if (quote1.symbol < quote2.symbol) {
              return 1;
            }
          }
          return 0;
        });
      }
    }
    return this;
  };

  private sortBySentiment = (sorts: Sorts): WatchList => {
    if (!sorts) {
      return this;
    }

    for (const sort of sorts.data) {
      if (sort.name !== 'sentiment') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2) {
            if (quote1.sentiment > quote2.sentiment) {
              return 1;
            }
            if (quote1.sentiment < quote2.sentiment) {
              return -1;
            }
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2) {
            if (quote1.sentiment > quote2.sentiment) {
              return -1;
            }
            if (quote1.sentiment < quote2.sentiment) {
              return 1;
            }
          }
          return 0;
        });
      }
    }
    return this;
  };

  private sortByScore = (sorts: Sorts): WatchList => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'score') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2) {
            if (quote1.technicalRank > quote2.technicalRank) {
              return 1;
            }
            if (quote1.technicalRank < quote2.technicalRank) {
              return -1;
            }
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2) {
            if (quote1.technicalRank > quote2.technicalRank) {
              return -1;
            }
            if (quote1.technicalRank < quote2.technicalRank) {
              return 1;
            }
          }
          return 0;
        });
      }
    }
    return this;
  };

  private sortByPrice = (sorts: Sorts): WatchList => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'price') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2 && quote1.price && quote2.price) {
            if (quote1.price === null || quote2.price === null) {
              return -1;
            }
            if (quote1.price > quote2.price) {
              return 1;
            }
            if (quote1.price < quote2.price) {
              return -1;
            }
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.quotes.sort((quote1, quote2) => {
          if (quote1 && quote2 && quote1.price && quote2.price) {
            if (quote1.price === null || quote2.price === null) {
              return 1;
            }
            if (quote1.price > quote2.price) {
              return -1;
            }
            if (quote1.price < quote2.price) {
              return 1;
            }
          }
          return 0;
        });
      }
    }
    return this;
  };
}
