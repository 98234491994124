import { Grid, Typography, useTheme } from '@mui/material';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { GuideWidget } from '../side-menu/guide-tour';
import { MerrillDisclimerWidgetDialog } from './merrill-disclaimer-widget';

const middlePart = {
  margin: '0 auto',
  fontSize: 12,
};
const link = {
  cursor: 'pointer',
  marginRight: '10px',
  verticalAlign: 'middle',
  '&:hover': {
    textDecoration: 'underline !important',
  },
};
export const EmbeddedFooterWidget: React.FC = () => {
  const customization = useRecoilValue(customizationState);
  const [importantDisclosuresModalOpen, setImportantDisclosuresModalOpen] = React.useState(false);
  const theme = useTheme();

  if (!customization?.showFooter) {
    return null;
  }

  return (
    <Grid container spacing={2} style={{ backgroundColor: theme.palette.optionGridDisabled.main, padding: 2 }}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Typography style={middlePart} color={theme.palette.text.primary}>
            <GuideWidget type="link" />
            <a
              style={link}
              onClick={() => {
                setImportantDisclosuresModalOpen(true);
              }}>
              Important Disclosures
            </a>
            <a
              style={{ ...link, textDecoration: 'none', color: theme.palette.text.primary }}
              href="/merrill-methodology.html"
              target="_blank"
              rel="noopener noreferrer">
              Methodology
            </a>
          </Typography>
          <Grid item xs={12} style={{ paddingTop: 4 }}>
            <Typography
              color={theme.palette.text.primary}
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                textAlign: 'center',
                fontSize: 10,
              }}>
              Commissions, fees, and middle-of-the-month assignments are not considered in any calculations. Commission
              and fees will increase potential loss and lower potential profits. Commission information is available
              upon request from the Merrill Edge Investment Center (1.877.653.4732).
            </Typography>
          </Grid>
        </Grid>
        {importantDisclosuresModalOpen && (
          <MerrillDisclimerWidgetDialog onHandleClose={() => setImportantDisclosuresModalOpen(false)} />
        )}
      </Grid>
    </Grid>
  );
};
