const Constants = {
  TOKEN_KEY: 'token',
  USER: 'user',
  hashTokenKey: 'hashToken',
  returnUrlKey: 'returnUrl',
};

const Messages = {
  UnknownError: 'An unknown error has occured',
  SessionExpired: 'Your session has expired, please login again.',
};

const VND_SOURCE_WEB = 'WEB';

const VND_VERSION = '1.0';

const CHART_IQ_CUSTOM_SETTING = 'chartiq_custom_setting';

export { Constants, Messages, VND_SOURCE_WEB, VND_VERSION, CHART_IQ_CUSTOM_SETTING };
