export interface IExchange {
  country: string;
  exchange: string;
  region: string;
}

export class Exchange {
  private static exchanges: IExchange[] = [
    {
      country: `Stockholm`, //{t('what.tradeIdeas.js.smallCap')}
      exchange: 'XSTO',
      region: 'NO',
    },
    {
      country: `Copenhagen`,
      exchange: 'XCSE',
      region: 'NO',
    },
    {
      country: `Helsinki`,
      exchange: 'XHEL',
      region: 'NO',
    },
  ];

  static get nordic() {
    return Exchange.exchanges.filter((c) => c.region === 'NO');
  }
}
