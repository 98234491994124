import { atom, selector } from 'recoil';
import { SignUpModel } from '../models';

export const signupState = atom<SignUpModel>({
  key: 'signupState',
  default: new SignUpModel(),
});

export const signupDetailState = selector<SignUpModel | undefined>({
  key: 'registrationDetailState',
  get: async ({ get }) => {
    const signUp = get(signupState);

    if (!signUp) {
      return new SignUpModel();
    }
    const response = new SignUpModel(); //await PowerhouseProService.instance.getRegistration(signUp);

    return response;
  },
});

export const biometricState = atom({
  key: 'biometricState',
  default: false,
});
