import { atom } from 'recoil';
import ThemeType from '../models/theme-type';

/**
 * @deprecated DO NOT USE IT. REMOVE IT.
 * //TODO: Remove this, this should take from configurationState (userSettings) value
 */
export const themeState = atom<ThemeType>({
  key: 'theme',
  default: ThemeType.LIGHT,
});
