import { PowerhouseProHub } from '../../hubs/powerhouse-pro-hub';
import { UserActivityHub } from '../../hubs/user-activity-hub';
import { IConfiguration } from '../configuration/configuration';
import { Globalization, IGlobalization } from '../languge-type';

class ApplicationContext {
  // TODO : IConfiguration should be undefined
  private static _configuration: IConfiguration;
  private static _Globalization: IGlobalization | undefined;
  static powerhouseProHub: PowerhouseProHub | undefined;
  static userActivityHub: UserActivityHub | undefined;
  static accessToken: string | undefined;
  static refreshToken: string | undefined;
  // TradeStation
  static tradeStationToken: string | undefined;
  static tradeStationBaseURL: string | undefined;

  static get configuration(): IConfiguration {
    return ApplicationContext._configuration;
  }

  static set configuration(configuration: IConfiguration) {
    ApplicationContext._configuration = configuration;
  }

  static get globalization(): IGlobalization {
    if (!ApplicationContext._Globalization) {
      return Globalization.default;
    }
    return ApplicationContext._Globalization;
  }

  static set globalization(globalization: IGlobalization | undefined) {
    ApplicationContext._Globalization = globalization;
  }
}

export default ApplicationContext;
