import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import { OptionsPlayDivider, ScrollableBox } from '../styled/options-play-components';

import { newsFeedState } from '@op/shared/src/states';
import { DateTime } from 'luxon';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { OPBoldTypograpghy } from '../styled';

export const NewsFeedPanel: React.FC = () => {
  const theme = useTheme();
  const response = useRecoilValue(newsFeedState);
  const noNewsFeedMessage = `Oops... News is not Found`;

  const noNewsFeed = () => {
    return (
      <Grid
        container
        rowSpacing={1}
        justifyContent="center"
        alignItems="center"
        textAlign={'center'}
        sx={{
          borderRadius: '20px 20px 0 0',
          boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
          height: '100%',
        }}>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <OPBoldTypograpghy variant="h6" sx={{ color: theme.palette.error.main }}>
            {noNewsFeedMessage}
          </OPBoldTypograpghy>
        </Grid>
      </Grid>
    );
  };

  if (!response || response.hasErrors || !response.data) {
    return <>{noNewsFeedMessage}</>;
  }

  const newsFeeds = response.data || [];
  const renderNewsFeeds = () => {
    if (newsFeeds.length === 0) {
      return noNewsFeed();
    }
    return newsFeeds.map((value) => {
      const date = value.date ? DateTime.fromJSDate(value.date).toFormat('LLL dd, yyyy') : '';
      return (
        <Box key={value.id} sx={{ mt: 2, px: '10px' }}>
          <div>
            <Link href={value.link} target="_blank">
              {value.headline}
            </Link>
          </div>
          <Typography variant="body1">{value.description}</Typography>
          <Typography variant="overline">{date}</Typography>
          <OptionsPlayDivider />
        </Box>
      );
    });
  };

  return (
    <ScrollableBox height={600} sx={{ backgroundColor: 'transparent' }}>
      {renderNewsFeeds()}
    </ScrollableBox>
  );
};
