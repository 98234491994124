import { ThemeOptions } from '@mui/material';
import { OptionsPlayBreakpoints, fontSizeMediaQuery } from './options-play-breakpoints';

//TODO: Extract common settings like Typography, shape, breakpoints
declare module '@mui/material/styles' {
  interface Palette {
    timeFrame: Palette['primary'];
    tolerance: Palette['primary'];
    collapsible: Palette['primary'];
    selectAndTextField: Palette['primary'];
    selection: Palette['primary'];
    globalSelect: Palette['primary'];
    personalSelect: Palette['primary'];
    legButtons: Palette['primary'];
    orangeButton: Palette['primary'];
    symbolCard: Palette['background'];
    header: Palette['background'];
    highChart?: Palette['primary'];
    slider?: Palette['primary'];
    optionGridDisabled?: Palette['primary'];
    greyShades: Palette['primary'];
  }
  interface PaletteOptions {
    timeFrame?: PaletteOptions['primary'];
    tolerance?: PaletteOptions['primary'];
    collapsible?: PaletteOptions['primary'];
    selectAndTextField?: PaletteOptions['primary'];
    selection?: PaletteOptions['primary'];
    globalSelect: PaletteOptions['primary'];
    personalSelect: PaletteOptions['primary'];
    legButtons?: PaletteOptions['primary'];
    orangeButton?: PaletteOptions['primary'];
    symbolCard?: PaletteOptions['background'];
    header?: PaletteOptions['background'];
    highChart?: PaletteOptions['primary'];
    slider?: PaletteOptions['primary'];
    optionGridDisabled?: PaletteOptions['primary'];
    greyShades?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    captionSmall: React.CSSProperties;
    subHeader: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionSmall?: React.CSSProperties;
    subHeader?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionSmall: true;
    subHeader: true;
  }
}

export const lightTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0078D4',
      light: '#BFE0FA',
      dark: 'rgba(0, 0, 0, 0.54)', // In general used for the hover effect
    },
    secondary: {
      main: '#f50057',
      light: 'rgba(217,217,217,0.5)', // used for hover color and selected color
      dark: '#BFE0FA',
    },
    background: {
      default: '#fafafa',
    },
    info: {
      main: '#AEB4C4',
      light: '#0078D4',
      dark: '#ECF3FF', // Used for the heading background
    },
    success: {
      main: '#00A94F',
      light: '#b8e0b9',
    },
    error: {
      main: '#CF2323',
      light: '#ED004F',
    },
    warning: {
      main: '#DF9641', //'#ffdb8d',
      light: 'rgb(223, 150, 65, 0.2)', //DF9641 with 20% opacity
      dark: '#FDC874', // used for alacarte warning
    },
    text: {
      primary: '#333333', //'#1A2027',
      secondary: '#3E5060',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    greyShades: {
      main: '#D9D9D9',
      light: '#FAFAFA',
      dark: '#333333',
      contrastText: '#888888',
    },
    timeFrame: {
      light: '#84BDF1',
      main: '#5BA0E0',
      dark: '#3585CE',
      contrastText: '#FFFFFF',
    },
    tolerance: {
      light: '#F39B95',
      main: '#F0776D',
      dark: '#E84429',
      contrastText: '#FFFFFF',
    },
    collapsible: {
      main: '#E0E0E0',
    },
    selectAndTextField: {
      light: '#FFFFFF',
      main: '#000000',
    },
    selection: {
      main: '#BFE0FA',
      light: 'rgb(217,217,217,0.3)', // for selected bg of ideas
      dark: '#9E9E9E',
      contrastText: '#333333',
    },
    globalSelect: {
      main: '#00A94F', //For Selected Global Text
      light: '#00A94F', //For Selected Global Icon
      dark: '#00A94F',
    },
    personalSelect: {
      main: '#0078D4', //For Selected Personal Text
      light: '#0078D4', //For Selected Persoanl Icon
    },
    legButtons: {
      main: '#0078D4', //stock
      light: '#00A94F', //call
      dark: '#CF2323', //put
    },
    orangeButton: {
      main: 'rgb(223, 150, 65, 0.2)', // backgroundColr
      light: '#DF9641', //selected border Color
      dark: '#BFE0FA', //  Border color
    },
    symbolCard: {
      default: '#FFFFFF',
    },
    header: {
      default: '#FFFFFF',
    },
    highChart: {
      main: '#FFFFFFF2',
      dark: '#00000061',
      light: '#FFFFFF',
      contrastText: '#000000',
    },
    slider: {
      main: '#BFE0FA',
    },
    optionGridDisabled: {
      main: '#E0E0E0',
      light: '#BFE0FA',
      contrastText: '#333333',
    },
    divider: 'rgba(0, 0, 0, 0.2)',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    subHeader: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          fontFamily: '"Lato", "sans-serif"',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          // color: 'yellow',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: 'capitalize',
        },
        containedPrimary: {
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#0049A3',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Lato", "sans-serif"',
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Lato", "sans-serif"',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4,
          textTransform: 'initial',
          margin: 4,
          color: '#000000',
          backgroundColor: '#FFFFFF',
          border: '1px solid rgba(0, 0, 0, 0.2)',
          '&.Mui-selected,&.Mui-selected:hover': {
            color: '#FFFFFF',
            backgroundColor: '#0078D4',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Lato", "sans-serif"',
          borderBottom: '0.5px dashed rgb(0,0,0,0.5)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: '"Lato", "sans-serif"',
        },
        listbox: {},
        option: {
          fontFamily: '"Lato", "sans-serif"',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
          '&.MuiMenu-list': {
            paddingBottom: 0,
          },
        },
      },
    },
  },
};
