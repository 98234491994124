export interface IEmbedderRequestValidationTokenProps {
  Key: string;
  Value: string;
}

export class EmbedderRequestValidationToken implements IEmbedderRequestValidationTokenProps {
  Key = '';
  Value = '';

  public static fromJson = (data: EmbedderRequestValidationToken): EmbedderRequestValidationToken => {
    const model = new EmbedderRequestValidationToken();
    model.Key = data.Key;
    model.Value = data.Value;
    return model;
  };
}
