import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  debounce,
  useTheme,
} from '@mui/material';
import sadLook from '@op/shared/assets/images/sad-look.gif';
import { ICancelSubscriptionFeatureRequest } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { cancelSubscriptionFeature } from '@op/shared/src/services';
import {
  cancelPlanTypeState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
  subscriptionPlanPricesModelState,
  userSubscriptionFeaturesDataState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { ReactNode, useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { SubscriptionFooterWidget } from './subscription-footer-widget';

export const CancelSubscriptionWidget: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const cancelInitiator = useRecoilValue(cancelPlanTypeState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const resetUserSubscriptionFeaturesData = useResetRecoilState(userSubscriptionFeaturesDataState);
  const resetPricingModels = useResetRecoilState(subscriptionPlanPricesModelState);
  const [value, setValue] = useState('');
  const [comment, setComment] = useState('');
  const [cancelled, setCancelled] = useState(false);
  const [count, setCount] = useState(0);
  const [isCancelClicked, setIsCancelCliked] = useState(false);

  useEffect(() => {
    clearState();
  }, []);

  const clearState = () => {
    setValue('');
    setComment('');
    setCount(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value.length);
    setComment(event.target.value);
  };

  const handleBack = () => {
    logActivity('BUTTON', 'paymentsCancelSubscriptionBackButton');
    setSubscriptionCurrentScreen('CurrentSubscription');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const cancelAccount = async (): Promise<void> => {
    logActivity('BUTTON', 'paymentsCancelSubscriptionContinueButton');
    const cancelRequest: ICancelSubscriptionFeatureRequest = {
      reasonCode: value,
      userComment: comment,
      cancelledFeature: {
        key: cancelInitiator.plan.featureKey,
        featureType: cancelInitiator.plan.featureType,
        billingModel: cancelInitiator.plan.planType,
        usersubscriptionId: cancelInitiator.plan.userSubscriptionId,
      },
    };

    if (value <= '0') {
      setNotifications([{ type: 'error', content: 'Reason is Required...' }]);
    } else if (value === '9999' && !comment) {
      setNotifications([{ type: 'error', content: 'Comment is Required...' }]);
    } else {
      try {
        const response = await cancelSubscriptionFeature(cancelRequest);
        if (response.hasErrors) {
          setNotifications([{ type: 'error', content: 'Subscription cancel Failed' }]);
          setIsCancelCliked(false);
          clearState();
        } else {
          setNotifications([{ type: 'success', content: 'Subscription cancelled Successfully' }]);
          clearState();
          setCancelled(true);
          setTimeout(() => {
            setCancelled(false);
            resetUserSubscriptionFeaturesData();
            resetPricingModels();
            setSubscriptionCurrentScreen('CurrentSubscription');
          }, 5000);
        }
      } catch (error) {
        setNotifications([{ type: 'error', content: 'Subscription cancel Failed' }]);
        setIsCancelCliked(false);
      }
    }
  };

  const debouncedOnCancelSubscription = debounce(cancelAccount, 1000);

  const changePlan = () => {
    logActivity('BUTTON', 'paymentsAddSubscriptionButton');
    setSubscriptionCurrentScreen('ChangeSubscription');
    cancelInitiator.plan.featureType === 'Bundle' ? setActiveStep('changePlan') : setActiveStep('addOn');
  };

  const disableCancelButton = () => {
    if (value <= '0') {
      return true;
    }
    if (value === '9999' && !comment) {
      return true;
    }
    if (isCancelClicked) {
      return true;
    }
    return false;
  };

  const renderRadioOption = (value: string, label: ReactNode) => (
    <FormControlLabel value={value} control={<Radio color="primary" />} label={label} style={{ fontWeight: '500' }} />
  );

  const renderCancelSuccess = () => (
    <Grid container justifyContent="center" alignItems="center" textAlign="center" height={600}>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <OPBoldTypograpghy variant="h5" sx={{ color: theme.palette.primary.main }}>
          {t('subscriptions.yourSubscription')}
        </OPBoldTypograpghy>
        <OPBoldTypograpghy variant="h5" sx={{ color: theme.palette.error.main }}>
          {t('subscriptions.successfullyGotCancelled')}
        </OPBoldTypograpghy>
        <IconComponent name="cancelLoader" />
      </Grid>
    </Grid>
  );

  const renderCancelForm = () => (
    <Grid container>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <OPBoldTypograpghy
          variant="subtitle2"
          style={{ color: theme.palette.primary.main, padding: 12, fontSize: '20px' }}>
          {t('subscriptions.sorryToSeeYouGo')}
        </OPBoldTypograpghy>
        <img src={sadLook} alt="" style={{ height: '74px', width: '74px' }} />
      </Grid>
      <Grid item container xs={12} sx={{ backgroundColor: theme.palette.greyShades.light }}>
        <Grid item xs={12}>
          <br />
          <Typography px={1} style={{ fontSize: theme.typography.subtitle2.fontSize, fontWeight: 500 }}>
            {t('subscriptions.youAreAboutToCancelCurrentSubscription')}
          </Typography>
          <br />
        </Grid>

        <Grid item container px={1} xs={12}>
          <FormControl component="fieldset" color="primary">
            <RadioGroup aria-label="reasons" name="reasons" value={value} onChange={handleChange}>
              {renderRadioOption('1001', t('subscriptions.subscriptionWasExpensive'))}
              {renderRadioOption('1002', t('subscriptions.difficultToUse'))}
              {renderRadioOption('1003', t('subscriptions.didNotUse'))}
              {renderRadioOption('9999', t('subscriptions.other'))}
            </RadioGroup>
            {value === '9999' && (
              <Grid item xs={12} style={{ margin: 10, width: '700px' }}>
                <TextField
                  fullWidth
                  id="comment"
                  name="comment"
                  onChange={handleCommentChange}
                  value={comment}
                  variant="outlined"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 200 }}
                />
                <Typography textAlign="right">{count}/200</Typography>
              </Grid>
            )}
          </FormControl>
        </Grid>
        <Grid container item justifyContent="flex-end" paddingY={2}>
          <Box display="flex" justifyContent="flex-end" p={0.5} gap={2}>
            <Button onClick={handleBack} sx={{ color: theme.palette.primary.light }} size="medium" variant="outlined">
              <ArrowBackIosIcon fontSize="medium" sx={{ color: theme.palette.primary.light }} />
              <OPBoldTypograpghy variant="button" sx={{ color: theme.palette.text.primary }}>
                {t('common.buttons.back')}
              </OPBoldTypograpghy>
            </Button>
            {/* <Button onClick={handleBack} id="pw-subscribe" color="success" size="large" variant="contained">
              <OPBoldTypograpghy variant="button">{t('subscriptions.changeMyMind')}</OPBoldTypograpghy>
            </Button> */}
            <Button onClick={changePlan} id="pw-subscribe" size="large" color="primary" variant="contained">
              <OPBoldTypograpghy variant="button">{t('subscriptions.changePlan')}</OPBoldTypograpghy>
            </Button>
            <Button
              disabled={disableCancelButton()}
              onClick={() => {
                setIsCancelCliked(true);
                debouncedOnCancelSubscription();
              }}
              sx={{ '&:hover': { backgroundColor: theme.palette.error.main } }}
              id="pw-subscribe"
              size="large"
              color="primary"
              variant="contained">
              <OPBoldTypograpghy variant="button">{t('subscriptions.cancelSubscription')}</OPBoldTypograpghy>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} pt={2}>
        <SubscriptionFooterWidget />
      </Grid>
    </Grid>
  );

  return cancelled ? renderCancelSuccess() : renderCancelForm();
};
