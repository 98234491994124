import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Divider, Grid, Theme, useTheme } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { groupBy as rowGrouper } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Column, RenderSortStatusProps, SortColumn, TreeDataGrid } from 'react-data-grid';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { OPBoldTypograpghy } from '../../styled';
import { OrderTicketPositionSkeleton } from '../components/order-ticket-position-skeleton';
import { TsSpan } from '../components/ts-styled-components';
import { useFetchandSetPositionStream, useFetchandSetQuotesStream } from '../components/useStream';
import { ITradeStatationPosition } from '../models/getPositions';
import { IOrderTicketPosition } from '../models/ts-helpers';
import { getPositions } from '../services';
import {
  getAllTSPositionsState,
  tsEnvironmentState,
  tsPositionDataState,
  tsPositionUnderlyingSymbolsState,
  tsPositionsUpdater,
  tsRefreshAccountTokenState,
  tsSelectedAccountIdsState,
} from '../states/trade-station-states';
import { tsTradeOrderPropsState } from '../states/ts-ticket-states';
import '../styles/styles.css';
export interface PositionRow {
  id: number;
  accountId: string;
  underlyingSymbol: string;
  symbol: string;
  qty: string;
  side: string;
  avgPrice: string;
  last: string;
  todaysPL: string;
  unrealizedPL: string;
  unrealizedPLPercentage: string;
  totalCost: string;
  marketValue: string;
  positionId: string;
  timeStamp: Date;
  assetType: string;
  longShort: string;
  tsTradeProps?: IOrderTicketPosition[];
}

const getCellTextColor = (value: any, theme: Theme) => {
  if (value !== undefined && Number(value) === 0) {
    return theme.palette.text.primary;
  } else if (Math.sign(Number(value)) === 1) {
    return theme.palette.success.main;
  } else {
    return theme.palette.error.main;
  }
};

const getColumns = (theme: Theme) => {
  const columns = [
    {
      key: 'symbol',
      name: 'Symbol',
      sortable: true,
      editable: false,
      minWidth: 150,
      headerCellClass: 'symbol-header-cell',
      renderCell({ row }) {
        return <TsSpan>{row.symbol}</TsSpan>;
      },
    },
    {
      key: 'side',
      name: 'Side',
      editable: false,
      renderCell({ row }) {
        return (
          <TsSpan color={row.side && row.side === 'Long' ? theme.palette.primary.main : theme.palette.error.main}>
            {row.side ? row.side : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'qty',
      name: 'Qty',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.qty ? row.qty : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'avgPrice',
      name: 'Avg Price',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.avgPrice ? NumberFormatHelper.roundNumber(row.avgPrice) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'last',
      name: 'Last Price',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.last ? NumberFormatHelper.roundNumber(row.last) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'todaysPL',
      name: `Today's P/L`,
      editable: false,
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.todaysPL, theme)}>
            {row.todaysPL ? NumberFormatHelper.toCurrency(row.todaysPL) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'unrealizedPL',
      name: 'Unrealized P/L',
      editable: false,
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.unrealizedPL, theme)}>
            {row.unrealizedPL ? NumberFormatHelper.toCurrency(row.unrealizedPL) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'totalCost',
      name: `Total Cost`,
      editable: false,
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.totalCost, theme)}>
            {row.totalCost ? NumberFormatHelper.toCurrency(row.totalCost) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'marketValue',
      name: `Market Value`,
      editable: false,
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.marketValue, theme)}>
            {row.marketValue ? NumberFormatHelper.toCurrency(row.marketValue) : 'N/A'}
          </TsSpan>
        );
      },
    },
  ];
  return columns;
};

export const OrderTicketPositionsWidget = () => {
  const selectedOptions = ['underlyingSymbol'] as const;
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const positionsData = useRecoilValue(tsPositionDataState);
  const positionUnderlyingSymbols = useRecoilValue(tsPositionUnderlyingSymbolsState);
  const [rows, setRows] = useState<PositionRow[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const theme = useTheme();
  const [column, setColumn] = useState<Column<any, any>[]>([]);
  const [isFetchingPositions, setIsFetchingPositions] = useState(false);
  const setTsPositionsUpdater = useSetRecoilState(tsPositionsUpdater);
  const positions = useRecoilValue(getAllTSPositionsState);
  const tsOrderProps = useRecoilValue(tsTradeOrderPropsState);
  const fetchPositionsStream = useFetchandSetPositionStream();
  const fetchQuotesStream = useFetchandSetQuotesStream();
  const [refreshAccountToken, setRefreshAccountToken] = useRecoilState(tsRefreshAccountTokenState);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0 || isFetchingPositions) {
      return;
    }
    // Set the state to indicate that positions are being fetched (async call is in progress)
    setIsFetchingPositions(true);
    (async () => {
      const response = await getPositions(tsEnv, selectedAccountIds);
      if (response.hasErrors) {
        return;
      }
      setTsPositionsUpdater(response.data.positions);
      // Reset the state after fetching positions is completed
      setIsFetchingPositions(false);
    })();
  }, [selectedAccountIds, positionsData]);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return;
    }
    if (!positionsData || positionsData.length === 0) {
      return;
    }
    const positionStreamController = new AbortController();

    (async () => {
      await fetchPositionsStream(positionStreamController, selectedAccountIds);
    })();
    return () => {
      setTimeout(() => {
        try {
          if (positionStreamController.signal.aborted) {
            return;
          }
          positionStreamController.abort('executed positions abort');
          if (refreshAccountToken) {
            setRefreshAccountToken(true);
          }
        } catch (e) {
          if (e instanceof DOMException && e.name === 'AbortError') {
          } else {
            console.log('Download error: ' + e.message);
          }
        }
      }, 100);
    };
  }, [selectedAccountIds, positionsData, refreshAccountToken]);

  useEffect(() => {
    if (!positionsData || positionsData.length === 0) {
      return;
    }
    // const symbols = positionsData
    //   .map((p) => p.underlyingSymbol)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    const symbols = positionUnderlyingSymbols.filter((value, index, self) => self.indexOf(value) === index);

    const quoteController = new AbortController();

    (async () => {
      await fetchQuotesStream(quoteController, symbols);
    })();
    return () => {
      setTimeout(() => {
        try {
          if (quoteController.signal.aborted) {
            return;
          }
          quoteController.abort('executed abort');
          if (refreshAccountToken) {
            setRefreshAccountToken(true);
          }
        } catch (e) {
          if (e instanceof DOMException && e.name == 'AbortError') {
          } else {
            console.log('Download error: ' + e.message);
          }
        }
      }, 100);
    };
  }, [positionsData, refreshAccountToken]);

  useEffect(() => {
    const columnsObj = getColumns(theme);
    setColumn(columnsObj);
  }, [theme]);

  useEffect(() => {
    if (!positions) {
      return;
    }
    createPositionRows(positions);
  }, [selectedAccountIds, positions]);

  const sortedRows = useMemo((): PositionRow[] => {
    if (sortColumns.length === 0) return rows;
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        if (sort.columnKey !== 'symbol') {
          return 0;
        }
        if (sort.direction === 'ASC') {
          return a.symbol.localeCompare(b.symbol);
        }
        if (sort.direction === 'DESC') {
          return b.symbol.localeCompare(a.symbol);
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const rowKeyGetter = (row: PositionRow) => {
    return row.id;
  };

  const createPositionRows = (positions: ITradeStatationPosition[]) => {
    const rows: PositionRow[] = [];
    for (let i = 0; i < positions.length; i++) {
      rows.push({
        id: i,
        accountId: positions[i].accountID,
        // underlyingSymbol: positions[i].symbol.substring(
        //   0,
        //   positions[i].symbol.indexOf(' ') === -1 ? positions[i].symbol.length : positions[i].symbol.indexOf(' '),
        // ),
        underlyingSymbol: positionUnderlyingSymbols[i],
        symbol: positions[i].symbol,
        qty: positions[i].quantity,
        side: positions[i].longShort,
        avgPrice: positions[i].averagePrice,
        last: positions[i].last,
        todaysPL: positions[i].todaysProfitLoss,
        unrealizedPL: positions[i].unrealizedProfitLoss,
        unrealizedPLPercentage: positions[i].unrealizedProfitLossPercent,
        totalCost: positions[i].totalCost,
        marketValue: positions[i].marketValue,
        positionId: positions[i].positionID,
        timeStamp: positions[i].timestamp,
        assetType: positions[i].assetType,
        longShort: positions[i].longShort,
      });
    }
    const orderSymbol = tsOrderProps[0].underlyingSymbol;
    const filteredRows =
      selectedAccountIds.length === 1
        ? rows.filter((q) => q.underlyingSymbol === orderSymbol && q.accountId === selectedAccountIds[0])
        : [];
    const sortedRows = filteredRows.sort((r1, r2) => r1.underlyingSymbol.localeCompare(r2.underlyingSymbol));
    setRows(sortedRows);
    return;
  };

  const renderSortStatus = ({ sortDirection, priority }: RenderSortStatusProps) => {
    return (
      <>
        {sortDirection !== undefined ? (
          sortDirection === 'ASC' ? (
            <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          ) : (
            <SouthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          )
        ) : (
          <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: '#BFE0FA' }} />
        )}
        <span>{priority}</span>
      </>
    );
  };

  if (!positionsData || isFetchingPositions) {
    return (
      <Grid container item xs={12} justifyContent={'center'} alignContent={'center'}>
        <OrderTicketPositionSkeleton />
      </Grid>
    );
  }

  const renderPositionDataGrid = () => {
    if (rows.length <= 0 || positions.length === 0) {
      return (
        <Grid
          container
          item
          xs={12}
          justifyContent={'center'}
          alignContent={'center'}
          sx={{ border: `2px solid ${theme.palette.error.main}`, padding: '8px', borderRadius: 2 }}>
          <OPBoldTypograpghy> No Positions Found</OPBoldTypograpghy>
        </Grid>
      );
    }
    return (
      <TreeDataGrid
        className={theme.palette.mode === 'dark' ? 'rdg-dark' : 'rdg-light'}
        style={{ blockSize: rows.length > 0 ? `20vh` : '10vh' }}
        columns={column}
        rows={sortedRows}
        rowKeyGetter={rowKeyGetter}
        groupBy={selectedOptions}
        rowGrouper={rowGrouper}
        expandedGroupIds={new Set([])}
        onExpandedGroupIdsChange={() => {}}
        defaultColumnOptions={{ resizable: false }}
        direction={'ltr'}
        renderers={{
          renderSortStatus,
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} margin={0.4} paddingTop={0.4}>
        <Divider color={theme.palette.primary.main} textAlign={'left'}>
          <OPBoldTypograpghy lineHeight={1} color={theme.palette.primary.main}>
            {`Existing ${tsOrderProps[0]?.underlyingSymbol} Positions`}
          </OPBoldTypograpghy>
        </Divider>
      </Grid>
      <Grid item xs={12}>
        {renderPositionDataGrid()}
      </Grid>
    </Grid>
  );
};
