import { ISubscriptionPaymentForm } from '@op/shared/src/models';

const CARD_TYPE_LENGTHS = {
  AMEX: 15,
  DINERS: 15,
  DISCOVER: 16,
  ENROUTE: 16,
  MASTERCARD: 16,
  VISA: 16,
};

const CVV_LENGTHS = {
  AMEX: 4,
  DEFAULT: 3,
};

export const ValidateCardInfo = (values: ISubscriptionPaymentForm, cardtype: string) => {
  let errors = {} as ISubscriptionPaymentForm;

  if (!values.cardName) {
    errors.cardName = 'Card Holder Name is required';
  } else if (values.cardName.trim().length < 2) {
    errors.cardName = 'Card Holder Name must be more than 2 characters';
  } else if (values.cardName.trim() === '') {
    errors.cardName = 'Name cannot be empty';
  }

  if (!values.cardNumber) {
    errors.cardNumber = 'Card Number is required';
  } else if (CARD_TYPE_LENGTHS[cardtype] && values.cardNumber.length !== CARD_TYPE_LENGTHS[cardtype]) {
    errors.cardNumber = `Card Number should be ${CARD_TYPE_LENGTHS[cardtype]} digits`;
  } else if (!luhnCheck(values.cardNumber)) {
    errors.cardNumber = 'Card Number is invalid';
  }

  if (!values.cardMonth) {
    errors.cardMonth = 'Month is required';
  }

  if (!values.cardYear) {
    errors.cardYear = 'Year is required';
  }

  if (!values.cardCvv) {
    errors.cardCvv = 'CVV is required';
  } else if (values.cardCvv.length !== (CVV_LENGTHS[cardtype] || CVV_LENGTHS.DEFAULT)) {
    errors.cardCvv = `Please enter exactly ${CVV_LENGTHS[cardtype] || CVV_LENGTHS.DEFAULT} numbers`;
  }

  return errors;
};

const luhnCheck = (cardnumber: string) => {
  cardnumber = cardnumber.replace(/\D/g, '');
  let nCheck = 0,
    bEven = false;

  for (let n = cardnumber.length - 1; n >= 0; n--) {
    let nDigit = parseInt(cardnumber.charAt(n), 10);
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};

export const ValidateBillingInfo = (values: ISubscriptionPaymentForm) => {
  let errors = {} as ISubscriptionPaymentForm;

  if (!values.country) {
    errors.country = 'Country Name is required';
  }

  if (!values.address) {
    errors.address = 'Address is required';
  } else if (values.address.trim() === '') {
    errors.address = 'Address cannot be empty';
  }

  if (!values.city) {
    errors.city = 'City is required';
  } else if (values.city.trim() === '') {
    errors.city = 'City cannot be empty';
  }

  if (!values.state) {
    errors.state = 'State is required';
  }

  if (!values.postalCode) {
    errors.postalCode = 'Postal Code is required';
  }

  return errors;
};
