import { IRecomendationDetail } from '.';

export class RecomendationDetail implements IRecomendationDetail {
  id = '';
  expiry = '';
  strike = -1;
  ask = -1;
  bid = -1;
  premium = -1;
  action = '';
  quantity = -1;
  type = '';

  currentEditReccomendOptions = [];
  currentEditReccomendStrikeOptions = [];
  selectedExpiry = { expiryText: '', expiryValue: '' };

  public static fromText = (data: RecomendationDetail): RecomendationDetail => {
    const value = new RecomendationDetail();
    value.id = data.id;
    value.expiry = data.expiry;
    value.strike = data.strike;
    value.ask = data.ask;
    value.bid = data.bid;
    value.premium = data.premium;
    value.action = data.action;
    value.quantity = data.quantity;
    value.type = data.type;
    return value;
  };
}
