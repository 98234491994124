import { PriceCalculationMethod } from '../enums/enums';
import { Combination } from './combination';

export class CombinationBuilder {
  combination: Combination;
  constructor(combination: Combination) {
    this.combination = Combination.fromSelf(combination);
  }

  updatePriceMethod = (type: PriceCalculationMethod) => {
    this.combination.priceCalculationMethod = type;
    this.combination.positions.forEach((p) => {
      p.selectedPrice = type;
    });
    return this;
  };

  build = () => {
    return this.combination;
  };
}
