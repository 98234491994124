import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import { HeightWidget } from '../routes';

export const PortfolioCombinationLoaderWidget: React.FC = () => {
  return (
    <Grid container rowSpacing={1} sx={{ p: 1 }}>
      <Grid item xs={12} container columnSpacing={1}>
        <Grid item xs="auto">
          <Skeleton variant="rectangular" height={30} width={30} sx={{ mt: 0.5 }} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton animation="wave" variant="text" height={20} />
          <Skeleton animation="wave" variant="text" height={20} />
        </Grid>
        <Grid item container xs>
          <Grid item xs={12}>
            <Skeleton animation="wave" variant="text" height={20} />
          </Grid>
          <Grid item container xs={12} columnSpacing={1}>
            {[0, 1, 2].map((n, id) => (
              <Grid item key={id} xs={4}>
                <Skeleton animation="wave" variant="text" height={20} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} pl={5}>
        <Skeleton animation="wave" variant="rectangular" height={30} />
      </Grid>
    </Grid>
  );
};

export const PortfolioWhatLoaderWidget: React.FC = () => {
  return (
    <HeightWidget noScroll height={700}>
      {Array(7)
        .fill(1)
        .map((_n, id) => (
          <PortfolioCombinationLoaderWidget key={id} />
        ))}
    </HeightWidget>
  );
};
