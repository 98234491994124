import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Button, Typography, useTheme } from '@mui/material';
import { Combination, ILeg, Leg, OptionChain } from '@op/shared/src/models';
import { BuyOrSell, LegType } from '@op/shared/src/models/enums/enums';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { canHideLegButton } from './modify';

type Props = {
  callback: (action: string, legs?: ILeg[]) => void;
  logActivity: (controlType: string, controlName: string, value?: any) => void;
  combination: Combination;
};

export const AddStockWidget: React.FC<Props> = ({ callback, combination, logActivity }) => {
  const theme = useTheme();
  const customization = useRecoilValue(customizationState);
  const { t } = React.useContext(LocalizationContext);

  if (!customization) {
    return;
  }

  if (!customization.allowStockPlusOptionforCASymbol && canHideLegButton(combination, LegType.SECURITY)) {
    return;
  }

  const addLeg = (legType: LegType) => {
    let securityQuantity = 1;
    const isSecurityType = legType === LegType.SECURITY;
    if (isSecurityType) {
      securityQuantity = OptionChain.getSecurityQuantity(combination.optionType);
    }
    const leg: ILeg = {
      buyOrSell: BuyOrSell.BUY,
      quantity: securityQuantity,
      legType: legType,
      expiry: undefined,
      strikePrice: undefined,
    };
    const legs = combination.positions.map((p) => Leg.fromPosition(p));
    legs.push(leg);
    callback('', legs);
  };

  const addSecurityLeg = () => {
    addLeg(LegType.SECURITY);
    logActivity('BUTTON', 'editorAddLegButton', 'Security');
  };

  return (
    <Button
      onClick={addSecurityLeg}
      sx={{ color: theme.palette.legButtons.main }}
      startIcon={<AddCircleOutlineRoundedIcon />}>
      <Typography variant="body1">{t('how.combinationEditor.buttons.stock')}</Typography>
    </Button>
  );
};
