import { Grid, Theme, styled, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { OPBoldTypograpghy, OPLightTypograpghy } from '../styled';

type SubscriptionCardGridViewProps = {
  label: ReactNode;
  value: ReactNode;
  cancelled?: boolean;
};

export const SubscriptionCardGridView: React.FC<SubscriptionCardGridViewProps> = ({ label, value, cancelled }) => {
  const theme = useTheme();

  const colorValue = () => {
    if (cancelled) {
      return theme.palette.error.main;
    }
    if (label === 'Discount') {
      return theme.palette.success.main;
    }
    return theme.palette.greyShades.contrastText;
  };

  return (
    <Grid item container justifyContent="space-between" alignItems={'center'}>
      <Grid item xs={6}>
        <OPLightTypograpghy style={{ color: colorValue(), fontSize: '14px' }}>{label}</OPLightTypograpghy>
      </Grid>
      <Grid item xs={6} textAlign="right">
        <OPBoldTypograpghy style={{ color: colorValue(), fontSize: '14px' }}>{value}</OPBoldTypograpghy>
      </Grid>
    </Grid>
  );
};

export const ChangePlanSlider = styled(Slider)(({ theme }) => ({
  '.slick-prev:before, .slick-next:before': {
    color: 'black', // Color for navigation arrows
  },
  '.slick-dots li button:before': {
    fontSize: '12px',
    color: 'black', // Color for inactive dots
  },
  '.slick-dots li.slick-active button:before': {
    color: theme.palette.primary.main, // Color for active dot
  },
}));

export const SubscriptionBenefitListMenuProps = (theme: Theme) => {
  return {
    elevation: 0,
    sx: {
      backgroundColor: theme.palette.primary.main,
      overflow: 'visible',
      ml: 1.5,
      border: `2px solid ${theme.palette.primary.main}`,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 10,
        left: 14,
        width: 10,
        height: 10,
        bgcolor: theme.palette.primary.main,
        transform: 'translateX(-190%) rotate(45deg)',
      },
    },
  };
};
