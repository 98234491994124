import { Grid, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { TableHeaderCell } from '../styled';
import { SubscriptionFooterWidget } from './subscription-footer-widget';

export const CurrentSubscriptionSkeletonWidget: React.FC = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Skeleton variant="rounded" height={50} width={'100%'} sx={{ my: 1 }} />
      <Grid container xs={12}>
        {[0, 1, 2, 3].map((index) => (
          <Grid xs={2.5} item key={index} p={1}>
            <Skeleton variant="text" width={'100%'} height={40} />
          </Grid>
        ))}
        <Grid xs={2} item p={1}>
          <Skeleton variant="text" width={'100%'} height={40} />
        </Grid>
      </Grid>
      <Skeleton variant="rounded" height={'250px'} sx={{ px: 4 }} />
      <Skeleton variant="rectangular" height={70} width={'100%'} sx={{ paddingX: 4 }} />
      <Skeleton variant="rounded" height={60} width={'100%'} sx={{ my: 1 }} />
      <Skeleton variant="rounded" height={60} width={'100%'} sx={{ my: 2 }} />
      <SkeletonLoadingBillingHistory />
      <Grid item xs={12}>
        <SubscriptionFooterWidget />
      </Grid>
    </Paper>
  );
};

export const SkeletonLoadingBillingHistory: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableHeaderCell padding="none">{t('subscriptions.invoices')}</TableHeaderCell>
          <TableHeaderCell padding="none">{t('subscriptions.amountTitle')}</TableHeaderCell>
          <TableHeaderCell padding="none">{t('subscriptions.date')}</TableHeaderCell>
          <TableHeaderCell padding="none">{t('subscriptions.statusTitle')}</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[0, 1, 2].map((row) => (
          <TableRow key={row}>
            <TableCell size="small" component="th" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
            <TableCell padding="none" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
            <TableCell padding="none" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
            <TableCell padding="none" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const SummarySkeleton: React.FC = () => {
  return (
    <Paper>
      <Grid container xs={12} justifyContent={'center'}>
        <Grid item container xs={6}>
          <Grid item xs={12} container justifyContent="center">
            <Skeleton variant="text" height={60} width="40%" sx={{ my: 1 }} />
          </Grid>
          {[0, 1, 2].map((label) => (
            <Grid xs={4} item key={label}>
              <Skeleton variant="text" height={20} width={'80%'} sx={{ px: 1 }} />
            </Grid>
          ))}
          <Skeleton variant="text" height={20} width={'20%'} sx={{ mt: 1 }} />
          {[0, 1, 2, 3, 4].map((index) => (
            <Skeleton key={index} variant="rounded" height={50} width={'100%'} sx={{ my: 1 }} />
          ))}
          {[0, 1, 2, 3].map((index) => (
            <Grid item key={index} container justifyContent="space-between" alignItems={'center'}>
              <Grid item xs={4}>
                <Skeleton variant="text" height={20} sx={{ my: 0.5 }} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="text" height={20} />
              </Grid>
            </Grid>
          ))}
          <Skeleton variant="text" height={20} width={'100%'} sx={{ mt: 1 }} />
          <Skeleton variant="text" height={20} width={'60%'} />
          <Grid container item xs={12} columnSpacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="text" height={60} sx={{ my: 1 }} />
            </Grid>
            <Grid item xs={8}>
              <Skeleton variant="text" height={60} sx={{ my: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const ConfirmationSkeleton: React.FC = () => {
  return (
    <Paper>
      <Grid container xs={12} justifyContent={'center'}>
        <Grid item container xs={6}>
          <Grid item xs={12} container justifyContent="center">
            <Skeleton variant="text" height={60} width="50%" sx={{ my: 1 }} />
          </Grid>
          <Skeleton variant="text" height={30} width="100%" />
          <Grid item xs={12} container justifyContent="center">
            <Skeleton variant="text" height={30} width="40%" />
          </Grid>
          <Skeleton variant="text" height={20} width="100%" />
          <Skeleton variant="rounded" height={'400px'} width="100%" sx={{ mt: 1 }} />

          <Skeleton variant="text" height={20} width={'100%'} sx={{ mt: 1 }} />
          <Skeleton variant="text" height={20} width={'60%'} />
          <Grid container item xs={12} columnSpacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="text" height={60} sx={{ my: 1 }} />
            </Grid>
            <Grid item xs={8}>
              <Skeleton variant="text" height={60} sx={{ my: 1 }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
