import CachedIcon from '@mui/icons-material/Cached';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Grid, IconButton, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import {
  MenuProps,
  OPIconButton,
  OPSelectDropDownHeader,
  OPSelectField,
  OptionsPlayTextField,
  StyledDropdownItemWithBorder,
} from '../styled';
import { filtersOrdersState, tsOrderSearchState, tsOrdersDataState } from './states/trade-station-states';

export const TsOrderHeader: React.FC = () => {
  const theme = useTheme();
  const resetOrders = useResetRecoilState(tsOrdersDataState);
  const [textSearchFieldInput, setTextSearchFieldInput] = useRecoilState(tsOrderSearchState);
  const [orderFilter, setOrderFilter] = useRecoilState(filtersOrdersState);
  const resetOrderFilter = useResetRecoilState(filtersOrdersState);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    return () => {
      setTextSearchFieldInput('');
    };
  }, []);

  const resetSearchValues = () => {
    setTextSearchFieldInput('');
  };

  const resetOrder = () => {
    resetSearchValues();
    resetOrderFilter();
    resetOrders();
  };
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const renderSearch = () => {
    return (
      <Grid item xs={12} container justifyContent="space-between">
        <OptionsPlayTextField
          variant="outlined"
          onChange={(e) => {
            setTextSearchFieldInput(e.target.value.toUpperCase());
          }}
          placeholder="Search by Symbol"
          name="searchname"
          value={textSearchFieldInput}
          style={{ width: '100%' }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: !isFocused && (
              <IconButton disableRipple sx={{ '&.MuiIconButton-root': { padding: 0 }, cursor: 'default' }}>
                <IconComponent name="searchIcon" stroke={theme.palette.info.light} size={18} />
              </IconButton>
            ),
            endAdornment: textSearchFieldInput !== '' && (
              <IconButton onClick={() => setTextSearchFieldInput('')} sx={{ '&.MuiIconButton-root': { padding: 0 } }}>
                <ClearOutlinedIcon fontSize={'small'} sx={{ color: theme.palette.selectAndTextField.main }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
    );
  };

  const getSelectFieldValue = () => {
    const selectedOrderFilter = orderFilter.find((s) => s.selected);
    if (!selectedOrderFilter) {
      return '';
    }
    return selectedOrderFilter.value as string;
  };

  const onStatusChange = (status: string) => {
    const clone = cloneDeep(orderFilter);
    for (const f of clone) {
      f.selected = f.value === status;
    }
    setOrderFilter(clone);
  };

  const renderStatusDropdown = () => {
    return (
      <Grid container item>
        <OPSelectField
          variant="outlined"
          value={getSelectFieldValue()}
          onChange={(e: any) => {
            onStatusChange(e.target.value);
          }}
          fullWidth
          sx={{
            width: '100%',
            height: 35,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            paddingLeft: 1,
          }}
          MenuProps={MenuProps}>
          <OPSelectDropDownHeader type="filters" />
          {orderFilter.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item.name} value={item.value} selected={item.selected}>
                {item.title}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  return (
    <Grid container xs={12} alignItems={'center'}>
      <Grid item xs={0.5} justifyContent="flex-start">
        <OPIconButton id="js-technical-ideas-refresh-icon" onClick={() => resetOrder()}>
          <CachedIcon sx={{ color: theme.palette.info.light }} fontSize={'medium'} />
        </OPIconButton>
      </Grid>
      <Grid item container xs={9.5}>
        <Grid item xs={3}>
          {renderStatusDropdown()}
        </Grid>
      </Grid>

      <Grid item xs={2} textAlign={'right'}>
        {renderSearch()}
      </Grid>
    </Grid>
  );
};
