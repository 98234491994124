import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';

export class ShareDetailsService extends BaseApiService {
  private static _instance: ShareDetailsService;

  private constructor() {
    super('/sharing');
    ApiService.instance.init(ShareDetailsService.onError, ShareDetailsService.onLoading);
  }

  public static get instance(): ShareDetailsService {
    if (ShareDetailsService._instance == null) {
      ShareDetailsService._instance = new ShareDetailsService();
    }
    return ShareDetailsService._instance;
  }

  private static onError = (errorCode: number): void => {
    console.error('Error in Profile-details-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public getSharedData = async (id: string) => {
    const response = await ApiService.instance.get<any>(`/sharing/${id}`);
    return response;
  };

  public getStrategyData = async (body: any) => {
    const response = await ApiService.instance.post<any, any>(`/sharing/getStrategy`, body);
    return response;
  };
}

export const shareStrategyRequestPattern = new RegExp(/occ[1-9]=|u[1-9]=|hash=/i);
export const shareStrategyLegRequestPattern = new RegExp(/^(\w+=\w+&)*\w+=\w+(?:&\w+=\w+)*$/);
export const shareRequestPattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/i);

export const fetchSharedTradeByOcc = async (occ: string | undefined) => {
  const isValidStrategyParam1 = shareStrategyRequestPattern.test(occ || '');
  const isValidStrategyParam2 = shareStrategyLegRequestPattern.test(occ || '');
  if (!isValidStrategyParam1 && !isValidStrategyParam2) {
    return undefined;
  }
  return await ShareDetailsService.instance.getStrategyData({ url: occ });
};

export const fetchSharedTradeById = async (id: string | undefined) => {
  const isValidShareId = shareRequestPattern.test(id || '');
  if (!isValidShareId) {
    return undefined;
  }
  return await ShareDetailsService.instance.getSharedData(id as string);
};

export const fetchSharedTradeByOccHash = async (hash: string | undefined) => {
  const isValidHash = shareRequestPattern.test(hash || '');
  if (!isValidHash) {
    return undefined;
  }
  return await ShareDetailsService.instance.getStrategyData({ url: `hash=${hash}` });
};
