import { ApiService, powerhouseProApiServiceUrl } from '.';
import { ICompanyLookup, INewsFeedProps, NewsFeed, WhyEntity } from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';

const _getWhy = async (url: string) => {
  const response = await ApiService.instance.get<WhyEntity>(url);
  const vm = new ResponseViewModel<WhyEntity>();
  vm.data = response.data as unknown as WhyEntity;
  vm.errors = response.errors;
  return vm;
};

export const getWhy = async (symbol: string, shareId?: string): Promise<ResponseViewModel<WhyEntity>> => {
  symbol = symbol || 'AMG';
  let url = `${powerhouseProApiServiceUrl}/why/${symbol}`;
  if (shareId && shareId.trim() !== '') {
    url += `?shareId=${shareId}`;
  }
  return await _getWhy(url);
};

export const getWhyAnonymous = async (symbol: string, shareId: string): Promise<ResponseViewModel<WhyEntity>> => {
  if (!shareId || shareId.trim() === '') {
    throw new Error('Please provide valid share id');
  }
  symbol = symbol || 'AMG';
  let url = `${powerhouseProApiServiceUrl}/anonymous/why/${symbol}?shareId=${shareId}`;
  return await _getWhy(url);
};

const _ranks = async (url: string) => {
  const response = await ApiService.instance.get<{
    signals: { date: string; name: string; symbol: string; value: number }[];
  }>(url);
  const vm = new ResponseViewModel<{ date: string; name: string; symbol: string; value: number }[]>();
  vm.errors = response.errors;
  vm.data = response.data?.signals;
  return vm;
};

export const ranks = async (
  symbol: string,
  shareId?: string,
): Promise<ResponseViewModel<{ date: string; name: string; symbol: string; value: number }[]>> => {
  symbol = symbol.toUpperCase();
  let url = `${powerhouseProApiServiceUrl}/ranks/${symbol}`;
  if (shareId && shareId.trim() !== '') {
    url += `?shareId=${shareId}`;
  }
  return await _ranks(url);
};

export const ranksAnonymous = async (
  symbol: string,
  shareId: string,
): Promise<ResponseViewModel<{ date: string; name: string; symbol: string; value: number }[]>> => {
  if (!shareId || shareId.trim() === '') {
    throw new Error('Please provide valid share id');
  }
  symbol = symbol.toUpperCase();
  const url = `${powerhouseProApiServiceUrl}/anonymous/ranks/${symbol}?shareId=${shareId}`;
  return await _ranks(url);
};

export const companyLookup = async (searchTerm: string, config: any): Promise<ResponseViewModel<ICompanyLookup[]>> => {
  if (!searchTerm || searchTerm.trim() === '') {
    return ResponseViewModel.fromData([]);
  }
  const url = `${powerhouseProApiServiceUrl}/companyLookup?filter=${searchTerm.trim().toUpperCase()}&limit=20`;
  const response = await ApiService.instance.get<ICompanyLookup[]>(url, undefined, config);
  return response;
};

export const getNewsFeed = async (symbol: string): Promise<ResponseViewModel<NewsFeed[]>> => {
  let response = new ResponseViewModel<NewsFeed[]>();
  if (!symbol) {
    return response;
  }
  const url = `${powerhouseProApiServiceUrl}/news?symbol=${symbol}&_=${Date.now()}`;
  const result = await ApiService.instance.get<INewsFeedProps[]>(url);
  if (result.hasErrors || !result.data) {
    response.errors = result.errors;
    return response;
  }
  const newsFeeds = (result.data as INewsFeedProps[]).map((f) => NewsFeed.fromText(f));
  response.data = newsFeeds;
  return response;
};
