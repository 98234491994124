import { IncomeCombination, PortfolioManagementCombination } from '..';
import { BuyOrSell, LegType } from '../enums/enums';
import ApplicationContext from './application-context';
import Combination from './combination';
import { CombinationTextGeneration } from './combination-text-generation';
import formatting from './formatting';
import NumberFormatHelper from './number-format-helper';
import { Position } from './position-model';
import { Resources } from './resources';

const roundPrice = (price: number) => {
  return NumberFormatHelper.roundNumber(Math.abs(price), 2);
};

class StockLeg {
  quantity: number;
  price: number;
  ask: number | undefined;
  bid: number | undefined;
  type: LegType;
  shortCode: string;
  isToClose: boolean;
  combination: Combination | undefined;
  priceString: string;
  showShortCodeInTradeTicket = ApplicationContext.configuration.showShortCodeInTradeTicket;

  constructor(position: Position, combination: Combination) {
    this.quantity = position.quantity;
    this.price = roundPrice(position.price());
    this.ask = position.ask();
    this.bid = position.bid();
    this.type = (position.type[0].toUpperCase() + position.type.slice(1).toLowerCase()) as LegType; //position.type.toUpperCase() as LegType;
    this.isToClose = combination.isClosePosition(position, true);
    this.shortCode = position.shortCode;
    this.combination = combination;
    this.priceString = position.price().toString();
  }

  getActionName = () => {
    let format = '{buyOrSell} {openOrClose}';
    let formatObj = {
      buyOrSell: this.quantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL,
      openOrClose: this.isToClose ? Resources.Combination.ToClose : Resources.Combination.ToOpen,
    };
    let result = formatting.formatStr(format, formatObj);
    return result.toUpperCase();
  };

  //TODO: Duplicated code Need to remove
  actionName = this.getActionName();

  tryMerge = (pos: Position) => {
    if (this.isTheSameAs(pos)) {
      this.quantity += pos.quantity;
      return true;
    }
    return false;
  };

  isTheSameAs = (pos: Position) => {
    if (!this.combination) {
      throw new Error('Combination is undefined');
    }
    return this.type === pos.type.toUpperCase() && this.isToClose === this.combination.isClosePosition(pos, true);
  };
}

export class OptionLeg extends StockLeg {
  expiry: Date | undefined;
  strike: number | undefined;

  constructor(position: Position, combination: Combination) {
    super(position, combination);
    this.expiry = position.expiry;
    this.strike = position.strike;
  }
}

export class TradeTicket {
  combination: Combination | PortfolioManagementCombination | undefined;
  showShortCodeInTradeTicket = ApplicationContext.configuration.showShortCodeInTradeTicket;
  hideMessageInTradeTicket = ApplicationContext.configuration.hideMessageInTradeTicket;
  singleLegTradesOnly = ApplicationContext.configuration.singleLegTradesOnly;
  showSubmitButtonInTradeTicket = ApplicationContext.configuration.showSubmitButtonInTradeTicket;
  title: string[] = [];
  strategyName: string = '';
  eventStrategyName: string = '';
  sentiment: string = '';
  daysToExpiry: string | number = '';
  risk: number | string = 0;
  reward: number | string = 0;
  isAdjustment: boolean = false;
  recommendedPriceCss: any;
  isNakedPut: boolean = false;
  showNakedPutWarning: boolean = false;
  showCheckList: boolean = false;
  loggedData = '';
  optionLegs: OptionLeg[] = [];
  securityLegs: OptionLeg[] = [];
  tradeObject = null;
  isToClose: boolean = false;

  refreshTrade = (
    combination: Combination | IncomeCombination | PortfolioManagementCombination,
    resultingCombination: PortfolioManagementCombination | undefined,
  ) => {
    this.combination = combination;
    this.title = CombinationTextGeneration.generateTradeTicketTitle(combination);
    this.isNakedPut = combination.isNakedPut();
    this.showNakedPutWarning = this.isNakedPut && this.title.includes('Sell to Open');
    this.showCheckList = false;
    this.optionLegs = this.optionLegs;
    this.securityLegs = this.securityLegs;
    this.recommendedPriceCss = this.getRecommendedPriceCss(combination);
    if (!combination) {
      this.clearTicket();
      return;
    }

    let legs = this.getLegsForTrade(combination);
    this.optionLegs = legs.optionLegs;
    this.securityLegs = legs.securityLegs;

    this.isAdjustment = resultingCombination != null;

    if (resultingCombination) {
      let strategyName = resultingCombination.strategyName();
      let rollType = combination.getRollType();
      if (rollType !== null) {
        strategyName = 'Roll ' + strategyName;
      }
      /**
       * On Click of close position in portfolio StrategyName overwritten to 'Positions Closed'
       */
      if (resultingCombination.isClosedPosition()) {
        strategyName = 'Positions Closed';
      }
      this.strategyName = strategyName;
      this.daysToExpiry = resultingCombination.daysToExpiry();
      let resPos = combination.positions.filter((p) => p.isBuyPosition);
      let expiries = combination.getExpiries(resPos);
      this.daysToExpiry = formatting.daysFromNow(expiries[0]);
      this.risk = resultingCombination.maxRisk();
      this.reward = resultingCombination.maxReward();
    } else {
      this.strategyName = combination.strategyName();
      this.daysToExpiry = combination.daysToExpiry();
      this.risk = combination.maxRisk();
      this.reward = combination.maxReward();
    }
    this.eventStrategyName = combination.getBuyablePositionsStrategyName();
  };

  allLegs = () => {
    let optionLegs = this.optionLegs.slice(0);
    let securityLegs = this.securityLegs.slice(0);
    let result = optionLegs.concat(securityLegs);

    result.sort(function (p1, p2) {
      if (p1.isToClose) {
        return p2.isToClose ? 0 : -1;
      }
      if (p2.isToClose) {
        return 1;
      }
      if (p1.type === LegType.SECURITY) {
        return p1.type === p2.type ? 0 : 1;
      }
      if (p2.type === LegType.SECURITY) {
        return -1;
      }
      if (!p1.strike || !p2.strike) {
        throw new Error('Strike is undefined');
      }
      if (!p1.expiry || !p2.expiry) {
        throw new Error('expiry is undefined');
      }
      let result = p1.strike - p2.strike;
      if (!result) {
        result = p1.expiry.getTime() - p2.expiry.getTime();
      }
      /**
       * Below line of code is mainly orders by CALL Type
       * Below sort is commented as
       * it is effecting the order in Long Call vertical startegy
       */
      // if (!result) {
      //   result = p1.type === LegType.CALL ? -1 : 1;
      // }
      return result;
    });
    return result;
  };

  //TODO uncomment when tradeTicketExecutor implemented
  // canExecuteTrade = () => {
  //   if (!this.tradeTicketExecutor) {
  //     return ApplicationContext.configuration.isEmbeddingPlatform; // && configuration.implementation !== enums.ImplementationType.BNC;
  //   }

  //   let tradeObject = this.tradeObject;
  //   let canExecute = this.tradeTicketExecutor.canExecuteTrade(tradeObject);
  //   return canExecute;
  // };

  // toggleCheckList = function () {
  //   this.showCheckList(!this.showCheckList());
  // };

  ask = () => {
    let combination = this.combination;
    let ask = combination && combination.askPrice();
    return ask;
  };

  bid = () => {
    let combination = this.combination;
    let bid = combination && combination.bidPrice();
    return bid;
  };

  mid = () => {
    let combination = this.combination;
    let mid = combination && combination.midPrice();
    return mid;
  };

  /**
   * For netAsk/netBid/netMid
   * absQuantity > 0 is required as to not show the amount with infinity with new logic.
   */

  netAsk = () => {
    let combination = this.combination;
    let ask = combination && combination.netAskPrice(); //askPrice();
    if (!ask || !combination) return 0;
    const absQuantity = combination.hasOnlyStx() ? combination.absQuantity() / 100 : combination.absQuantity();
    return absQuantity == 0 ? 0 : ask / absQuantity;
  };

  netBid = () => {
    let combination = this.combination;
    let bid = combination && combination.netBidPrice(); //bidPrice();
    if (!bid || !combination) return 0;
    const absQuantity = combination.hasOnlyStx() ? combination.absQuantity() / 100 : combination.absQuantity();
    return absQuantity == 0 ? 0 : bid / absQuantity;
  };

  netMid = () => {
    let combination = this.combination;
    let mid = combination && combination.netMidPrice(); //midPrice();
    if (!mid || !combination) return 0;
    const absQuantity = combination.hasOnlyStx() ? combination.absQuantity() / 100 : combination.absQuantity();
    return absQuantity == 0 ? 0 : mid / absQuantity;
  };

  avgPremium = () => {
    let combination = this.combination;
    if (!combination) return 0;
    const allLegs = this.allLegs();
    let totalPremium = 0;
    const absQuantity = combination.hasOnlyStx() ? combination.absQuantity() / 100 : combination.absQuantity();
    allLegs.map((l) => {
      totalPremium += l.type === LegType.SECURITY ? l.price * (l.quantity / 100) : l.price * l.quantity;
    });
    return absQuantity == 0 ? 0 : totalPremium / absQuantity;
  };

  clearTicket = () => {
    // this.combination = undefined;
    this.title = [];
    this.optionLegs = [];
    this.securityLegs = [];
    this.isNakedPut = false;
    this.strategyName = '';
    this.daysToExpiry = '';
    this.risk = '';
    this.reward = '';
    this.isAdjustment = false;
    this.showNakedPutWarning = false;
  };

  getLegsForTrade = (combination: Combination) => {
    combination.buyablePositions.forEach((pos) => {
      let legConstructor: any;
      let targetList = [];

      if (pos.type === LegType.SECURITY) {
        legConstructor = new StockLeg(pos, combination);
        targetList = this.securityLegs;
      } else {
        legConstructor = new OptionLeg(pos, combination);
        targetList = this.optionLegs;
      }
      targetList.push(legConstructor);
    });

    this.optionLegs.sort(function (p1, p2) {
      if (!p1.strike || !p2.strike) {
        throw new Error('Strike is undefined');
      }
      if (!p1.expiry || !p2.expiry) {
        throw new Error('expiry is undefined');
      }
      let result = p1.strike - p2.strike;
      if (!result) {
        result = p1.expiry.getTime() - p2.expiry.getTime();
      }
      if (!result) {
        result = p1.type === LegType.CALL.toUpperCase() ? -1 : 1;
      }
      return result;
    });

    return {
      optionLegs: this.optionLegs,
      securityLegs: this.securityLegs,
    };
  };

  getTradeForExecution = () => {
    let combination = this.combination;
    if (!combination) {
      return null;
    }
    let price = combination.price();

    let priceSign = Math.sign(price);
    let roundedPrice = roundPrice(price);

    let tradeObject = {
      symbol: combination.symbol,
      optionLegs: this.optionLegs,
      securityLegs: this.securityLegs,
      strategyName: this.eventStrategyName,
      price: roundedPrice,
      priceSign: priceSign,
      isCustomStrategy: this.eventStrategyName.match(/custom strategy/i),
    };
    return tradeObject;
  };

  createTradeTicketForLog = () => {
    return {
      title: this.title,
      optionLegs: this.optionLegs,
      securityLegs: this.securityLegs,
      isNakedPut: this.isNakedPut,
      strategyName: this.eventStrategyName,
      daysToExpiry: this.daysToExpiry,
      risk: this.risk,
      reward: this.reward,
      showNakedPutWarning: this.showNakedPutWarning,
    };
  };

  getRecommendedPriceCss = (combination: Combination) => {
    let spreadPercent = parseFloat(combination.spreadPercent());
    if (spreadPercent >= 5) {
      return 'mid-price';
    } else {
      return combination.isBuyCombination() ? 'bid-price' : 'ask-price';
    }
  };
}
