import { Grid, Typography, useTheme } from '@mui/material';
import { isGlobal, isNordic, isTmx } from '@op/shared/src/models';
import { ICoveredCallsProps, ICreditSpreadsProps, IRowProps, IdeaProps } from './pager';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { customizationState, ideaMoreDetailsState } from '@op/shared/src/states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CompanyTitleWidget } from '../portfolio/company-title-widget';

export const ForthRow: React.FC<IdeaProps<IRowProps | ICreditSpreadsProps | ICoveredCallsProps>> = ({
  data,
}: IdeaProps<IRowProps | ICreditSpreadsProps | ICoveredCallsProps>) => {
  const customization = useRecoilValue(customizationState);
  const [open, onOpen] = useRecoilState(ideaMoreDetailsState);
  const theme = useTheme();

  const renderCompanyName = () => {
    return (
      <Grid item xs={11} className="js-trade-idea-company-name">
        <CompanyTitleWidget symbol={data.symbol} hasQuotes={true} companyName={data.companyName} />
      </Grid>
    );
  };

  const renderMoreDetailsIcon = () => {
    if (!customization || (!customization.showIV && !customization.showLiquidity)) {
      return;
    }
    if (isNordic() || isTmx() || data.type === 'coveredCall' || data.type === 'shortPut' || isGlobal()) {
      return;
    }
    return (
      <Grid
        item
        xs={1}
        onClick={(e) => {
          e.stopPropagation();
          onOpen((prev) => !prev);
        }}
        sx={{
          boxShadow: '0px 2px 2px rgba(0,0,0,0.1) inset',
          borderLeft: `0.5px solid ${theme.palette.grey[400]}`,
          borderTop: `0.5px solid ${theme.palette.grey[400]}`,
          borderRadius: '8px 0 4px 0',
          // py: 0.2,
          backgroundColor: open ? theme.palette.secondary.light : 'inherit',
        }}
        className="js-trade-idea-company-icon">
        <Typography variant="body1" textAlign="right">
          {open ? (
            <ArrowDropUpIcon sx={{ width: '18px', height: '18px', color: theme.palette.primary.main }} />
          ) : (
            <ArrowDropDownIcon sx={{ width: '18px', height: '18px', color: theme.palette.primary.main }} />
          )}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} container justifyContent="space-between" alignItems="center">
      {renderCompanyName()}
      {renderMoreDetailsIcon()}
    </Grid>
  );
};
