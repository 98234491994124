import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import { userSettingState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

export const TimeoutSettingWidget: React.FC = () => {
  const [setting, setSetting] = useRecoilState(userSettingState);
  const { t } = React.useContext(LocalizationContext);

  if (!setting) {
    return <></>;
  }

  const onChange = (value: number) => {
    const clone = cloneDeep(setting);
    clone.timeOutInMinutes = value;
    setSetting(clone);
  };

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup
          row={true}
          aria-label="timeOutInMinutes"
          name="minutes"
          value={setting.timeOutInMinutes}
          onChange={(_, value) => onChange(Number(value))}>
          {[20, 30, 60, 120, 240].map((value) => (
            <FormControlLabel
              value={value}
              control={<Radio />}
              label={`${value} ${t('profile.accountSettings.labels.minutes')}`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
