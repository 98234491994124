import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { IData, PortfolioCombination } from '@op/shared/src/models';
import { AlertType } from '@op/shared/src/models/enums/enums';
import { PortfolioAlertModel } from '@op/shared/src/models/portfolio/portfolio-alert-model';
import { configurationPortfolioAlertResourcesState, portfolioSelectedFilterSummaryState } from '@op/shared/src/states';
import {
  portfolioCombinationAlertOrNotificationSelectedState,
  portfolioNotificationsByAccountAndCombinationState,
} from '@op/shared/src/states/portfolio-alerts-hub-states';
import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPLightTypograpghy } from '../styled';

export type IPortfolioNotificationDetailsWidgetProps = IData<PortfolioCombination>;

export const PortfolioNotificationDetailsWidget: React.FC<IPortfolioNotificationDetailsWidgetProps> = ({
  data,
}: IPortfolioNotificationDetailsWidgetProps) => {
  const combination = data;
  const notifications = useRecoilValue(portfolioNotificationsByAccountAndCombinationState(combination.hash));
  const portfolioCombinationSelectedAlertIcon = useRecoilValue(portfolioCombinationAlertOrNotificationSelectedState);
  const portfolioAlertResources = useRecoilValue(configurationPortfolioAlertResourcesState);
  const selectedPortfolioFilters = useRecoilValue(portfolioSelectedFilterSummaryState);
  const { t } = useContext(LocalizationContext);

  const theme = useTheme();

  /**
   *
   * We have a alert for every combination
   * This function returns the particular selected alert
   */
  const isAlertSelected = () => {
    return (
      portfolioCombinationSelectedAlertIcon &&
      portfolioCombinationSelectedAlertIcon.alertType === AlertType.NOTIFICATION
    );
  };

  if (
    !notifications ||
    notifications.length === 0 ||
    !portfolioAlertResources ||
    (!isAlertSelected() && selectedPortfolioFilters.selectedNotificationsLength === 0)
    // || !(selectedPortfolioFilters.alerts > 0 && isAlertSelected())
  ) {
    return null;
  }

  // const getTitle = (alert: PortfolioAlert) => {
  //   try {
  //     const key = alert.key;
  //     const resource = portfolioAlertResources.find((r) => r.key.trim().toUpperCase() === key.trim().toUpperCase());
  //     if (!resource) {
  //       return '';
  //     }
  //     return formatting.formatStr(resource.title as string, alert.additionalAlertData);
  //   } catch (ex) {
  //     console.log('error: notification: ', ex);
  //     return '';
  //   }
  // };

  const getTitles = () => {
    return notifications.map((alert, i) => (
      <Button
        key={i}
        variant="outlined"
        style={{
          border: `1px solid ${theme.palette.primary.main}`,
          marginRight: 8,
          padding: '4px 6px',
        }}>
        <OPLightTypograpghy>
          {PortfolioAlertModel.getTitle(alert, portfolioAlertResources, combination, t)}
        </OPLightTypograpghy>
      </Button>
    ));
  };

  return (
    <Grid container py={1}>
      <Grid item xs="auto">
        <Box>
          <Typography pl={2} pr={1.5} pt={1.5} sx={{ visibility: 'hidden' }}>
            <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs pl={1}>
        <Typography variant="body1" component="span" fontWeight="bold" pr={1}>
          Notifications
        </Typography>
        {getTitles()}
      </Grid>
    </Grid>
  );
};
