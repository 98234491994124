import { Grid, styled } from '@mui/material';

type Props = {
  selected: boolean;
};

export const OPTradeIdeaStyledHoverCard = styled(Grid)<Props>(({ selected, theme }) => ({
  boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
  borderRadius: theme.shape.borderRadius,
  border: selected ? `1.5px solid ${theme.palette.primary.main}` : `1.5px solid transparent`,
  cursor: 'pointer',
  ':hover': {
    boxShadow: '0px 3px 3px rgba(0,0,0,0.2)',
    border: `1.5px solid ${theme.palette.primary.main}`,
    '& .js-trade-idea-company-icon': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  backgroundColor: selected ? theme.palette.info.dark : '',
  width: '100%',
  background: selected
    ? theme.palette.mode === 'light'
      ? 'rgb(217,217,217,0.3)'
      : 'rgb(17,24,30)'
    : theme.palette.background.paper,
}));
