import { Button, Grid, Typography, debounce, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState, useRecoilState } from 'recoil';
import { OPBoldTypograpghy, OutlinedButton } from '../../styled';
import { OrderConfirmationSkeleton } from '../components/order-confirmation-skeleton';
import {
  IConfirmOrderTicket,
  ILimitOrderRequest,
  ILimitPlaceOrderRequest,
  IMarketOrderRequest,
  IMarketPlaceOrderRequest,
  IPlaceOrderTicket,
  IStopLimitOrderRequest,
  IStopLimitPlaceOrderRequest,
  IStopOrderRequest,
  IStopPlaceOrderRequest,
} from '../models';
import { IOrderTicketPosition } from '../models/ts-helpers';
import { executeLimitOrder, executeMarketOrder, executeStopLimitOrder, executeStopOrder } from '../services';
import {
  tsEnvironmentState,
  tsOrderTicketState,
  tsOrdersDataState,
  tsSelectedAccountIdsState,
  tsSelectedTabState,
} from '../states/trade-station-states';
import { tsBidMidAskValueState, tsOrderPlacementState, tsTradeOrderPropsState } from '../states/ts-ticket-states';
import { BuyingPowerWarning } from './buying-power-warning';
import { OrderConfirmationLegInfoWidget } from './order-confirmation-leg-info-widget';
import { OrderSummaryWidget } from './order-summary-widget';
import { TSOrderType } from '@op/shared/src/models/enums/enums';

interface Props {
  tsOrderTicketPositions: IOrderTicketPosition[] | undefined;
  orderRequest: IMarketOrderRequest | ILimitOrderRequest | IStopOrderRequest | IStopLimitOrderRequest;
  confirmOrderTicketData: IConfirmOrderTicket | undefined;
  setConfirmOrderTicketData: (confirmOrderTicketData: IConfirmOrderTicket | undefined) => void;
}

export const OrderConfirmationWidget: React.FC<Props> = ({
  tsOrderTicketPositions,
  orderRequest,
  confirmOrderTicketData,
  setConfirmOrderTicketData,
}: Props) => {
  const theme = useTheme();
  const setNotifications = useSetRecoilState(notificationsState);
  const setTSSelectedTab = useSetRecoilState(tsSelectedTabState);
  const setTrade = useSetRecoilState(tsOrderPlacementState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const resetOrders = useResetRecoilState(tsOrdersDataState);
  const [isOrderIdNull, setIsOrderIdNull] = useState(false);
  const resetBidMidAsk = useResetRecoilState(tsBidMidAskValueState);
  const tsOrderProps = useRecoilValue(tsTradeOrderPropsState);
  const [tsOrderTicketValue, setTsOrderTicketValue] = useRecoilState(tsOrderTicketState);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length > 1) {
      return;
    }
    if (!confirmOrderTicketData) {
      return;
    }
    if (selectedAccountIds[0] !== confirmOrderTicketData.confirmations[0].accountID) {
      onCancelOrPlaceOrderFailed();
    }
  }, [selectedAccountIds]);

  const redirectToOrders = () => {
    resetOrders();
    setTsOrderTicketValue('default');
    setTSSelectedTab('orders');
  };

  const showNotification = (type: 'error' | 'success', message: string, orderID: string) => {
    setNotifications([
      {
        type: type,
        content: `${message} for OrderID: ${orderID}`,
        isTradeStation: true,
      },
    ]);
  };

  if (!orderRequest) {
    //|| !bidMidAskValues
    setConfirmOrderTicketData(undefined);
    return;
  }

  const shouldRedirectToOrders = (response: ResponseViewModel<IPlaceOrderTicket>) => {
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: response.errors[0].message, isTradeStation: true }]);
      resetOrders();
      onCancelOrPlaceOrderFailed();
      return;
    }
    // if (response.data.errors) {
    //   showNotification('error', response.data.errors[0].message, response.data.errors[0].orderID);
    //   resetOrders();
    //   onCancelOrPlaceOrderFailed();
    //   return;
    // }
    const orderMessage = response.data.orders[0].message;
    const orderID = response.data.orders[0].orderId;
    if (orderMessage.toLowerCase().includes('order failed')) {
      showNotification('error', orderMessage, orderID);
      resetOrders();
      onCancelOrPlaceOrderFailed();
      return;
    }
    if (!orderID) {
      setIsOrderIdNull(true);
      return;
    }
    showNotification('success', orderMessage, orderID);
    setTrade(false);
    resetBidMidAsk(); // reset to undefined
    setConfirmOrderTicketData(undefined);
    redirectToOrders();
  };

  const onMarketPlaceOrder = async (fromWarningPopup: boolean) => {
    const req: IMarketPlaceOrderRequest = {
      orderConfirmID: confirmOrderTicketData.confirmations[0].orderConfirmID,
      buyingPowerWarning: fromWarningPopup ? 'Confirmed' : (orderRequest as IStopLimitOrderRequest).buyingPowerWarning,
      accountID: confirmOrderTicketData.confirmations[0].accountID,
      timeInForce: confirmOrderTicketData.confirmations[0].timeInForce.duration,
      gtdTime: (orderRequest as IMarketOrderRequest).gtdTime,
      route: confirmOrderTicketData.confirmations[0].route,
      legs: (orderRequest as IMarketOrderRequest).legs,
    };
    const response = await executeMarketOrder(tsEnv, req);
    shouldRedirectToOrders(response);
  };

  const onLimitPlaceOrder = async (fromWarningPopup: boolean) => {
    const req: ILimitPlaceOrderRequest = {
      orderConfirmID: confirmOrderTicketData.confirmations[0].orderConfirmID,
      buyingPowerWarning: fromWarningPopup ? 'Confirmed' : (orderRequest as IStopLimitOrderRequest).buyingPowerWarning,
      accountID: confirmOrderTicketData.confirmations[0].accountID,
      timeInForce: confirmOrderTicketData.confirmations[0].timeInForce.duration,
      gtdTime: (orderRequest as ILimitOrderRequest).gtdTime,
      route: confirmOrderTicketData.confirmations[0].route,
      limitPrice: (orderRequest as ILimitOrderRequest).limitPrice,
      legs: (orderRequest as ILimitOrderRequest).legs,
    };
    const response = await executeLimitOrder(tsEnv, req);
    shouldRedirectToOrders(response);
  };

  const onStopPlaceOrder = async (fromWarningPopup: boolean) => {
    const req: IStopPlaceOrderRequest = {
      orderConfirmID: confirmOrderTicketData.confirmations[0].orderConfirmID,
      buyingPowerWarning: fromWarningPopup ? 'Confirmed' : (orderRequest as IStopLimitOrderRequest).buyingPowerWarning,
      accountID: confirmOrderTicketData.confirmations[0].accountID,
      timeInForce: confirmOrderTicketData.confirmations[0].timeInForce.duration,
      gtdTime: (orderRequest as IStopOrderRequest).gtdTime,
      route: confirmOrderTicketData.confirmations[0].route,
      stopPrice: (orderRequest as IStopOrderRequest).stopPrice,
      legs: (orderRequest as IStopOrderRequest).legs,
    };
    const response = await executeStopOrder(tsEnv, req);
    shouldRedirectToOrders(response);
  };

  const onStopLimitPlaceOrder = async (fromWarningPopup: boolean) => {
    const req: IStopLimitPlaceOrderRequest = {
      orderConfirmID: confirmOrderTicketData.confirmations[0].orderConfirmID,
      buyingPowerWarning: fromWarningPopup ? 'Confirmed' : (orderRequest as IStopLimitOrderRequest).buyingPowerWarning,
      accountID: confirmOrderTicketData.confirmations[0].accountID,
      timeInForce: confirmOrderTicketData.confirmations[0].timeInForce.duration,
      gtdTime: (orderRequest as IStopLimitOrderRequest).gtdTime,
      route: confirmOrderTicketData.confirmations[0].route,
      limitPrice: (orderRequest as IStopLimitOrderRequest).limitPrice,
      stopPrice: (orderRequest as IStopLimitOrderRequest).stopPrice,
      legs: (orderRequest as IStopLimitOrderRequest).legs,
    };
    const response = await executeStopLimitOrder(tsEnv, req);
    shouldRedirectToOrders(response);
  };

  const onPlaceOrder = (fromWarningPopup: boolean) => {
    if (orderRequest.orderType === TSOrderType.Market) {
      return onMarketPlaceOrder(fromWarningPopup);
    }
    if (orderRequest.orderType === TSOrderType.Limit) {
      return onLimitPlaceOrder(fromWarningPopup);
    }
    if (orderRequest.orderType === TSOrderType.StopMarket) {
      return onStopPlaceOrder(fromWarningPopup);
    }
    if (orderRequest.orderType === TSOrderType.StopLimit) {
      return onStopLimitPlaceOrder(fromWarningPopup);
    }
    return;
  };

  const debouncedOnPlaceOrder = debounce(onPlaceOrder, 1000);

  const onCancelOrPlaceOrderFailed = () => {
    resetBidMidAsk(); // reset to undefined
    setConfirmOrderTicketData(undefined);
    // If Close at Market, then on cancel, redirect to positions tab
    if (tsOrderProps[0].action === 'closeAtMarket') {
      setTrade(false);
      setTSSelectedTab('positions');
    }
    setTsOrderTicketValue('default');
  };
  if (tsOrderTicketValue === 'isLoading' && !confirmOrderTicketData) {
    return <OrderConfirmationSkeleton />;
  }
  return (
    <Grid container alignItems={'center'}>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={12}
        xl={10}
        m={0.5}
        p={1}
        rowSpacing={1}
        sx={{ backgroundColor: theme.palette.secondary.light, borderRadius: 2 }}>
        <BuyingPowerWarning
          open={isOrderIdNull}
          setOpen={setIsOrderIdNull}
          goBack={onCancelOrPlaceOrderFailed}
          sendOrder={() => debouncedOnPlaceOrder(true)}
        />
        <OrderSummaryWidget
          tsOrderTicketPositions={tsOrderTicketPositions}
          orderRequest={orderRequest}
          confirmOrderTicketData={confirmOrderTicketData}
        />
        <Grid item container xs={12} my={0.5}>
          <OrderConfirmationLegInfoWidget orderTicketLegs={orderRequest.legs} />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={12} lg={12} xl={10} justifyContent={'flex-end'} columnSpacing={2}>
        <Grid item xs={12} textAlign={'right'} sx={{ marginBottom: '2px' }}>
          <Typography>Order not yet sent. Please confirm to send</Typography>
        </Grid>
        <OutlinedButton
          variant="contained"
          sx={{ width: '240px', marginRight: '16px' }}
          onClick={onCancelOrPlaceOrderFailed}>
          <OPBoldTypograpghy sx={{ color: theme.palette.text.primary }}>Cancel</OPBoldTypograpghy>
        </OutlinedButton>
        <Button
          disabled={isConfirmClicked}
          variant="contained"
          sx={{ width: '240px' }}
          onClick={() => {
            setIsConfirmClicked(true);
            debouncedOnPlaceOrder(false);
          }}>
          <OPBoldTypograpghy>Confirm</OPBoldTypograpghy>
        </Button>
      </Grid>
    </Grid>
  );
};
