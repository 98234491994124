import { ExpandedQuote } from '..';

export interface IWatchListQuotePortfolio {
  id: number;
  name: string;
  portfolios: null;
  symbol: string;
  watchlists: null;
}

export class Quote {
  position = -1;
  symbol = '';
  technicalRank = -1;
  price = -1;
  expandedQuote: ExpandedQuote | undefined;
  sentiment = '';
  portfolios: IWatchListQuotePortfolio[] = [];
  isTradeIdea = false;
  isPinned = false;
  portfolioPositionsCount = 0;
  company = '';

  static fromData = (data: any) => {
    const quote = new Quote();
    quote.position = data.position;
    quote.symbol = data.symbol;
    quote.technicalRank = data.technicalRank;
    quote.price = data.price;
    quote.expandedQuote = data.expandedQuote;
    quote.sentiment = data.sentiment;
    quote.portfolios = data.portfolios;
    quote.isTradeIdea = data.isTradeIdea;
    quote.isPinned = data.isPinned;
    quote.portfolioPositionsCount = data.portfolioPositionsCount;
    quote.company = data.company;
    return quote;
  };

  static fromSelf = (self: Quote) => {
    if (!self) {
      throw new Error('Self is undefined');
    }
    const clone = new Quote();
    clone.position = self.position;
    clone.symbol = self.symbol;
    clone.technicalRank = self.technicalRank;
    clone.price = self.price;
    clone.expandedQuote = self.expandedQuote;
    clone.sentiment = self.sentiment;
    clone.portfolios = self.portfolios;
    clone.isTradeIdea = self.isTradeIdea;
    clone.isPinned = self.isPinned;
    clone.portfolioPositionsCount = self.portfolioPositionsCount;
    clone.company = self.company;
    return clone;
  };

  static sortvalue = '';
}
