import { Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { StyledTableCell } from '../ts-styles-component';

export const OrderTicketPositionSkeleton: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="none">Symbol</StyledTableCell>
              <StyledTableCell padding="none">Side</StyledTableCell>
              <StyledTableCell padding="none">Qty</StyledTableCell>
              <StyledTableCell padding="none">Avg Price</StyledTableCell>
              <StyledTableCell padding="none">Last Price</StyledTableCell>
              <StyledTableCell padding="none">Unrealized P/l</StyledTableCell>
              <StyledTableCell padding="none">Unrealized P/l qty</StyledTableCell>
              <StyledTableCell padding="none">Total Cost</StyledTableCell>
              <StyledTableCell padding="none">Position ID</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[0, 1, 2, 3, 4].map((row) => (
              <TableRow key={row}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                  <TableCell size="small" component="th" sx={{ width: '8%' }} key={index}>
                    <Skeleton animation="wave" height={20} width="80%" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
