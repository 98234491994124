export interface IEmailDeliveryFrequencyProps {
  localizedName: string;
  name: string;
  value: number;
}

export class EmailDeliveryFrequency implements IEmailDeliveryFrequencyProps {
  localizedName = '';
  name = '';
  value = 0;

  public static fromJson = (data: EmailDeliveryFrequency): EmailDeliveryFrequency => {
    const model = new EmailDeliveryFrequency();
    model.localizedName = data.localizedName;
    model.name = data.name;
    model.value = data.value;
    return model;
  };
}
