import {
  AddToWatchlistResponse,
  DeleteResponse,
  DeleteWatchlist,
  EditRequest,
  EditResponse,
  WatchLists
} from '../../src/models';

import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';
import { ResponseViewModel } from '../../src/view-models/responses/response-viewmodel';
import { SaveRequest } from '../models/watch-list/watch-lits-save-request';
import { SaveResponse } from '../models/watch-list/watch-lits-save-response';
import formatting from '@op/shared/src/models/how/formatting';

export class WatchlistService extends BaseApiService {
  private static _instance: WatchlistService;

  private constructor() {
    super('WatchLists');
    ApiService.instance.init(WatchlistService.onError, WatchlistService.onLoading);
  }

  public static get instance(): WatchlistService {
    if (WatchlistService._instance == null) {
      WatchlistService._instance = new WatchlistService();
    }
    return WatchlistService._instance;
  }

  private static onLoading = (isLoading: boolean): void => {};

  public getWatchLists = async (): Promise<ResponseViewModel<WatchLists>> => {
    const response = await ApiService.instance.get<WatchLists>('/WatchLists');
    return response;
  };

  public add = async (request: {
    name: string;
    type: string;
  }): Promise<ResponseViewModel<AddToWatchlistResponse | undefined>> => {
    const url = 'WatchLists/add';
    const response = await ApiService.instance.post<{ name: string; type: string }, AddToWatchlistResponse>(
      url,
      request,
    );
    // const addresponse = AddToWatchlistResponse.fromJson((response as unknown) as IAddToWatchlistResponseProps);
    const vm = new ResponseViewModel<AddToWatchlistResponse>();
    vm.data = response.data as unknown as AddToWatchlistResponse;
    vm.errors = response.errors;
    return vm;
  };

  public remove = async (request: DeleteWatchlist): Promise<ResponseViewModel<DeleteResponse | null>> => {
    const url = 'WatchLists/remove';
    const response = await ApiService.instance.post<DeleteWatchlist, DeleteResponse>(url, request);
    //const deleteReponse = DeleteResponse.fromJson((response as unknown) as IDeleteResponseProps);
    const vm = new ResponseViewModel<DeleteResponse>();
    vm.data = response.data as unknown as DeleteResponse;
    vm.errors = response.errors;
    return vm;
    // if (response.errors) {
    //   return null;
    // }
    // return deleteReponse;
  };

  public rename = async (request: EditRequest): Promise<ResponseViewModel<EditResponse | undefined>> => {
    const url = 'WatchLists/rename';
    const response = await ApiService.instance.post<EditRequest, EditResponse>(url, request);
    const vm = new ResponseViewModel<EditResponse>();
    vm.data = response.data as unknown as EditResponse;
    vm.errors = response.errors;
    return vm;
    //const editReponse = EditResponse.fromJson((response as unknown) as IEditResponseProps);
    // if (response.errors) {
    //   return undefined;
    // }
    // return editReponse;
  };

  public getWatchListQuote = async (symbol: string): Promise<ResponseViewModel<Symbol>> => {
    const parsedSymbol = formatting.replaceSlashtoUnderscore(symbol);
    const url = `WatchLists/${parsedSymbol}`;
    const response = await ApiService.instance.get<Symbol>(url);
    const vm = new ResponseViewModel<Symbol>();
    vm.data = response.data as unknown as Symbol;
    vm.errors = response.errors;
    return vm;
  };

  public save = async (request: SaveRequest): Promise<ResponseViewModel<SaveResponse | undefined>> => {
    const url = 'WatchLists/save';
    const response = await ApiService.instance.post<SaveRequest, SaveResponse>(url, request);
    const vm = new ResponseViewModel<SaveResponse>();
    vm.data = response.data as unknown as SaveResponse;
    vm.errors = response.errors;
    return vm;
  };

  public bookmarkWatchList = async (
    id: number,
    isBookmarked: boolean,
  ): Promise<ResponseViewModel<SaveResponse | undefined>> => {
    const url = 'watchLists/bookmark';
    const response = await ApiService.instance.post<any, any>(url, {
      id: id,
      isBookmarked: isBookmarked,
    });
    const vm = new ResponseViewModel<any>();
    vm.data = response.data as any;
    vm.errors = response.errors;
    return vm;
  };

  public pinWatchList = async (id: number, symbol: string, isPinned: boolean): Promise<ResponseViewModel<any>> => {
    const url = 'watchLists/pin';
    const response = await ApiService.instance.post<any, any>(url, {
      id: id,
      symbol: symbol,
      isPinned: isPinned,
    });
    const vm = new ResponseViewModel<any>();
    vm.data = response.data as any;
    vm.errors = response.errors;
    return vm;
  };

  private static onError = (errorCode: number): void => {
    console.error('Error in watchlists-service: ', errorCode);
  };
}
