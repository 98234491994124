export interface IResetResponceProps {
  errorCode: string;
  formattedMessage: string;
  isSuccess: boolean;
}
export class ResetPasswordResponse implements IResetResponceProps {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: ResetPasswordResponse): ResetPasswordResponse => {
    const model = new ResetPasswordResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
