import { Button, Grid, Link, Typography, useTheme } from '@mui/material';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { addSubscriptionErrorState, selectedSymbolState, stepperUpdaterState } from '@op/shared/src/states';
import React, { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

export const SubscriptionErrorWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const setAddSubscriptionError = useSetRecoilState(addSubscriptionErrorState);

  const onClickTryAnotherCard = () => {
    setAddSubscriptionError(false);
    setActiveStep('cardInfo');
    logActivity('BUTTON', 'paymentsTryAnotherCardButton');
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  return (
    <Grid container p={2} justifyContent={'center'} rowSpacing={0.25}>
      <Grid item xs={12}>
        <Typography variant="body1" component="span" color={theme.palette.error.main}>
          {t('profile.payments.text.yourPaymentHasbeenDeclined')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="span" color={theme.palette.error.main}>
          {t('profile.payments.text.pleaseCheckTheInformationYouHaveEntered')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="span">
          {t('subscriptions.issueWithCard')}
          <Link href="mailto:support@optionsplay.com"> {t('subscriptions.contactUs')}</Link>
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent={'flex-end'}>
        <Button variant="contained" color="primary" onClick={onClickTryAnotherCard}>
          <Typography variant="button"> {t('profile.payments.buttons.tryAnotherCard')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
