import { ExpandedQuote } from '@op/shared/src/models/how/expanded-quote';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import React, { FC } from 'react';
import { RedGreenWidget } from './red-green-widget';

export interface IToExtendedChange {
  quote: ExpandedQuote | undefined;
}

export const ToExtendedChange: FC<IToExtendedChange> = (props: IToExtendedChange) => {
  const { quote } = props;
  if (!quote) {
    return null;
  }
  const { change, percentageChange } = quote;
  const changeWithSign = quote.changeSign === 'd' ? change * -1 : change;
  let result = NumberFormatHelper.toCurrency(change, 'exceptZero');
  if (percentageChange) {
    result += ` (${NumberFormatHelper.toPercentage(percentageChange, 'never')})`;
  }
  return <RedGreenWidget value={changeWithSign}>{result}</RedGreenWidget>;
};

export const ToExtendedChange2: React.FC<any> = ({
  change,
  percentageChange,
  asIntFractional,
  numberOfFractionalDigits,
  language,
  showCurrencySymbolForNumbers,
}: {
  change: number | undefined;
  percentageChange: number | undefined;
  asIntFractional: boolean;
  numberOfFractionalDigits: number;
  language: string | undefined;
  showCurrencySymbolForNumbers: boolean;
}) => {
  if (change == null) {
    return null;
  }
  const changeWithSign = change > 0 ? change * 1 : change;
  let result = NumberFormatHelper.toCurrency(change, 'exceptZero');
  if (percentageChange) {
    result += ` (${NumberFormatHelper.toPercentage(percentageChange, 'never')})`;
  }
  return <RedGreenWidget value={changeWithSign}>{result}</RedGreenWidget>;
};
