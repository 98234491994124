import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { reportSearchState } from '..';
import { IFilter2, Sorts } from '../../models';
import NumberFormatHelper from '../../models/how/number-format-helper';
import { CreditSpreads, ICreditSpreadReport } from '../../models/reports/credit-spread';
import { IFilterGroup } from '../../models/what/i-filter-group';
import { FuseConfig } from '../configuration/fuse-search-config';
import { guardRecoilDefaultValue } from '../trade';

/* =================== FILTERS ================= */
const sentimentFilter = {
  title: 'Sentiment',
  name: 'sentiment',
  translationKey: 'what.tradeIdeas.ideas.sentiment',
  filters: [
    {
      title: 'Bullish Put',
      name: 'bullish',
      translationKey: 'what.tradeIdeas.ideas.bullishPut',
      value: 'BullishPut',
      selected: true,
    },
    {
      title: 'Bearish Call',
      name: 'bearish',
      translationKey: 'what.tradeIdeas.ideas.bearishCall',
      value: 'BearishCall',
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterBySentiment = (groups: IFilterGroup[], data: ICreditSpreadReport[]) => {
  const group = groups.find((g) => g.name === 'sentiment');
  if (!group) {
    return data;
  }
  let filtered: ICreditSpreadReport[] = [];
  for (const filter of group.filters) {
    if (!filter.selected) {
      continue;
    }
    if (filter.name === 'all') {
      filtered = data;
      continue;
    }
    const items = data.filter((d) => d.strategy + d.type === filter.value);
    filtered = filtered.concat(items);
  }
  return filtered;
};

const hasEarningFilter = {
  title: 'Has Earnings',
  name: 'hasEarnings',
  translationKey: 'what.tradeIdeas.ideas.hasEarnings',
  filters: [
    {
      title: 'Yes',
      name: 'yes',
      translationKey: 'what.tradeIdeas.ideas.yes',
      value: 'Y',
      selected: true,
    },
    {
      title: 'No',
      name: 'no',
      translationKey: 'what.tradeIdeas.ideas.no',
      value: 'N',
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByHasEarnings = (groups: IFilterGroup[], data: ICreditSpreadReport[]) => {
  const group = groups.find((g) => g.name === 'hasEarnings');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: ICreditSpreadReport[] = [];
  for (const filter of group.filters) {
    if (!filter.selected) {
      continue;
    }
    if (filter.name === 'yes') {
      const items = data.filter((d) => d.earningsDate);
      filtered = filtered.concat(items);
    }
    if (filter.name === 'no') {
      const items = data.filter((d) => !d.earningsDate);
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const premiumFilter = {
  title: 'Premium vs Width',
  name: 'premium',
  translationKey: 'what.tradeIdeas.ideas.premiumWidth',
  filters: [
    {
      title: '<35%',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.lessThan35',
      value: 35,
      selected: true,
    },
    {
      title: '35%-38%',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.35To38',
      value: 38,
      selected: true,
    },
    {
      title: '38%-40%',
      name: 'range3',
      translationKey: 'what.tradeIdeas.ideas.38to40',
      value: 40,
      selected: true,
    },
    {
      title: '>40%',
      name: 'range4',
      translationKey: 'what.tradeIdeas.ideas.greaterThan40',
      value: 40,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByPremium = (groups: IFilterGroup[], data: ICreditSpreadReport[]) => {
  const group = groups.find((g) => g.name === 'premium');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: ICreditSpreadReport[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    if (f.name === 'range1') {
      const items = data.filter(
        (t) => NumberFormatHelper.roundPercentageValue(t.premium.percentage) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range2') {
      const items = data.filter(
        (t) =>
          NumberFormatHelper.roundPercentageValue(t.premium.percentage) >= 35 &&
          NumberFormatHelper.roundPercentageValue(t.premium.percentage) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range3') {
      const items = data.filter(
        (t) =>
          NumberFormatHelper.roundPercentageValue(t.premium.percentage) >= 38 &&
          NumberFormatHelper.roundPercentageValue(t.premium.percentage) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range4') {
      const items = data.filter(
        (t) => NumberFormatHelper.roundPercentageValue(t.premium.percentage) >= (f.value as number),
      );
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const IvRankFilter = {
  title: 'IV Rank',
  name: 'ivRank',
  translationKey: 'what.tradeIdeas.ideas.ivRank',
  filters: [
    {
      title: 'Less Than 50',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.lessThan50',
      value: 50,
      selected: true,
    },
    {
      title: 'Greater Than Or Equal To 50',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.greaterEqualTo50',
      value: 50,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByIVRank = (groups: IFilterGroup[], data: ICreditSpreadReport[]) => {
  const group = groups.find((g) => g.name === 'ivRank');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: ICreditSpreadReport[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    if (f.name === 'range1') {
      const items = data.filter((t) => Number(t.impliedVolatilityRank.replace('%', '')) < (f.value as number));
      filtered = filtered.concat(items);
    }
    if (f.name === 'range2') {
      const items = data.filter((t) => Number(t.impliedVolatilityRank.replace('%', '')) >= (f.value as number));
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const linkedPortfoliosFilter = {
  name: 'linkedPortfolios',
  title: 'Portfolio',
  translationKey: 'what.tradeIdeas.ideas.availableInPortfolio',
  filters: [
    {
      name: 'linkedPortfolios',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedPortfolios = (groups: IFilterGroup[], data: ICreditSpreadReport[]) => {
  const group = groups.find((g) => g.name === 'linkedPortfolios');
  if (!group) {
    return data;
  }
  let filtered: ICreditSpreadReport[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.filter((t) => t.portfolios.length > 0) : data;
    filtered = filtered.concat(items);
  }
  return filtered;
};

const linkedWatchListsFilter = {
  name: 'linkedWatchLists',
  title: 'Watchlist',
  translationKey: 'what.tradeIdeas.ideas.availableInWatchlist',
  filters: [
    {
      name: 'linkedWatchLists',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedWatchLists = (groups: IFilterGroup[], data: ICreditSpreadReport[]) => {
  const group = groups.find((g) => g.name === 'linkedWatchLists');
  if (!group) {
    return data;
  }
  let filtered: ICreditSpreadReport[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.filter((t) => t.watchlists.length > 0) : data;
    filtered = filtered.concat(items);
  }
  return filtered;
};

export const creditSpreadFilters = [
  sentimentFilter,
  hasEarningFilter,
  premiumFilter,
  IvRankFilter,
  linkedPortfoliosFilter,
  linkedWatchListsFilter,
] as IFilterGroup[];

/* END of Filters */

export const creditSpreadDataCachedState = atom<CreditSpreads | undefined>({
  key: 'creditSpreadDataCachedStateKey',
  default: undefined,
});

export const sortsCreditSpreadState = atom({
  key: 'sortsCreditSpreadStateKey',
  default: new Sorts([Sorts.CreditSpreadDefaultSorts.data[0]]),
});

export const filtersCreditSpreadState = atom({
  key: 'filtersCreditSpreadStateKey',
  default: creditSpreadFilters,
});

export const creditSpreadState = selector<CreditSpreads | undefined>({
  key: 'creditSpreadStateKey',
  get: ({ get }) => {
    const query = get(reportSearchState);
    const creditSpreads = get(creditSpreadDataCachedState);
    const sorts = get(sortsCreditSpreadState);
    const filters = get(filtersCreditSpreadState);
    if (!creditSpreads) {
      return undefined;
    }
    const clone = CreditSpreads.fromSelf(creditSpreads);
    let spreads = filterBySentiment(filters, clone.creditSpreads);
    spreads = filterByHasEarnings(filters, spreads);
    spreads = filterByIVRank(filters, spreads);
    spreads = filterByPremium(filters, spreads);
    spreads = filterByLinkedPortfolios(filters, spreads);
    spreads = filterByLinkedWatchLists(filters, spreads);
    const fuse = new Fuse(spreads, {
      ...FuseConfig,
      keys: ['symbol', 'companyName'],
    });
    const items = query.trim() !== '' ? fuse.search(query).map((i) => i.item) : spreads;
    clone.creditSpreads = items;
    clone.data = items;
    const sortedCreditSpreads = clone.sort(sorts);
    return new CreditSpreads(sortedCreditSpreads);
  },
  set: ({ set }, newCreditSpreads) => {
    if (guardRecoilDefaultValue(newCreditSpreads)) {
      throw new Error('newCreditSpread in not compatible type');
    }
    set(creditSpreadDataCachedState, newCreditSpreads);
  },
});

export const creditSpreadCountState = selector<number>({
  key: 'creditSpreadCountStateKey',
  get: ({ get }) => {
    const creditSpreads = get(creditSpreadState);
    if (!creditSpreads) {
      return 0;
    }
    return creditSpreads.creditSpreadCount;
  },
});

export const creditSpreadPortfolioPositionsCountState = selector<number>({
  key: 'creditSpreadPortfolioPositionsCountStateKey',
  get: ({ get }) => {
    const creditSpreads = get(creditSpreadState);
    if (!creditSpreads) {
      return 0;
    }
    const items = creditSpreads.creditSpreads;
    const count = items
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
    return count;
  },
});

export const creditSpreadWatchlistQuotesCountState = selector<number>({
  key: 'creditSpreadWatchlistQuotesCountStateKey',
  get: ({ get }) => {
    const creditSpreads = get(creditSpreadState);
    if (!creditSpreads) {
      return 0;
    }
    const items = creditSpreads.creditSpreads;
    const count = items
      .filter((e) => e.watchlists && e.watchlists.length > 0)
      .reduce((a, i) => a + i.watchlists.length, 0);
    return count;
  },
});
