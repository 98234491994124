import {
  BuyOrSell,
  CombinationSentiment,
  CombinationType,
  LegType,
  LongOrShort,
  PriceCalculationMethod,
  RiskRewardType,
  StrategyName,
} from '@op/shared/src/models/enums/enums';
import {
  CombinationTextGeneration,
  ExpandedQuote,
  IExpandedQuote,
  ILeg,
  IStrategyTemplate,
  Leg,
  OptionChain,
  Position,
  Predictions,
  StandardDeviation,
  StrategiesProvider,
} from '.';
import { PortfolioPosition } from '../portfolio';
import ApplicationContext from './application-context';
import { BasicPositionModel } from './basic-position-model';
import formatting from './formatting';
import { unique } from './helpers';
import HowDataModel from './how-data-model';
import NumberFormatHelper from './number-format-helper';
import { SpecialStrategies } from './special-strategies';
import { tradeCostCommission } from './tradecost-commission';

type FunctionNameTypes = 'payoff' | 'delta' | 'gamma' | 'vega' | 'theta' | 'rho';

abstract class BasicCombination {
  private _stdDev: StandardDeviation | undefined;
  private _predictions: Predictions | undefined;
  originalLegs: ILeg[] | undefined; //OriginalLegs will be use to reset legs to original state.
  showCurrencySymbolForNumbers = ApplicationContext.configuration.showCurrencySymbolForNumbers;
  implementation = ApplicationContext.configuration.implementation;
  positions: BasicPositionModel[] = [];
  chain: OptionChain | undefined = undefined;
  quote: IExpandedQuote = new ExpandedQuote();
  symbol = '';
  priceCalculationMethod = PriceCalculationMethod.BID_ASK;
  optionType = 'S';
  originalMultiplier = 100;
  defaultDaysToFindExpiry = ApplicationContext.configuration.defaultDaysToFindExpiry;
  isIncomeSpecific = false;
  allowNegativeCostBasis = false;
  isRoll: boolean = false;
  combinationType = CombinationType.TRADE;
  noCallOptimalCombinationTitle = ''; //TODO: This should initite in constructor

  protected constructor() {}

  fromData = (
    legs: ILeg[] | undefined,
    data: { quote: IExpandedQuote; chain: OptionChain; stdDev: StandardDeviation; predictions: Predictions },
    priceCalculationMethod = PriceCalculationMethod.BID_ASK,
    optionType = 'S',
  ) => {
    /**
     * TODO: Implement builder patter for easy intialization
     */
    const { quote, chain, stdDev, predictions } = data;
    this.quote = { ...quote } as IExpandedQuote;
    this.symbol = this.quote.symbol;
    this.chain = chain; // Todo: May be clone the chain
    this.predictions = predictions;
    this.stdDev = stdDev;
    this.priceCalculationMethod = priceCalculationMethod;
    this.optionType = optionType;
    this.originalLegs = legs?.map((l) => Leg.fromSelf(l));
    this.originalMultiplier = OptionChain.getSecurityQuantity(this.optionType);
  };

  fromCombinationContext = (
    legs: ILeg[] | undefined,
    combinationContext: HowDataModel,
    priceCalculationMethod: PriceCalculationMethod | undefined,
  ) => {
    /**
     * TODO: Implement builder patter for easy intialization
     */
    this.originalLegs = legs?.map((l) => Leg.fromSelf(l));
    // TODO: clone all these variables. however this is super class may not be required.
    this.quote = ExpandedQuote.fromSelf(combinationContext.quote);
    this.symbol = this.quote.symbol;
    this.chain = combinationContext.chain; // Todo: May be clone the chain
    this.predictions = combinationContext.predictions;
    this.stdDev = combinationContext.stdDev;
    this.priceCalculationMethod = priceCalculationMethod || PriceCalculationMethod.BID_ASK;
    this.originalMultiplier = OptionChain.getSecurityQuantity(this.optionType);
  };

  fromPortolioCombination = (portfolioPositions: PortfolioPosition[], underlyingQuote: ExpandedQuote | undefined) => {
    this.quote = underlyingQuote ? ExpandedQuote.fromSelf(underlyingQuote) : new ExpandedQuote();
    this.symbol = underlyingQuote ? underlyingQuote.symbol : '';
    this.originalLegs = portfolioPositions.map((p) => Leg.fromPortfolioPosition(p));
    this.originalMultiplier = OptionChain.getSecurityQuantity(this.optionType);
  };

  fromSelf = (self: BasicCombination) => {
    //this.positions = self.positions.map(p => BasicPositionModel.from);
    this.originalLegs = self.originalLegs;
    this.quote = ExpandedQuote.fromSelf(self.quote);
    this.symbol = self.symbol;
    this.chain = self.chain; //TOD0: Create fromSelf in OptionChain.
    this.predictions = self.predictions;
    this.stdDev = self.stdDev;
    this.priceCalculationMethod = self.priceCalculationMethod;
    this.optionType = self.optionType;
    this.originalMultiplier = self.originalMultiplier;
    this.defaultDaysToFindExpiry = self.defaultDaysToFindExpiry;
    this.isIncomeSpecific = self.isIncomeSpecific;
    this.allowNegativeCostBasis = self.allowNegativeCostBasis;
    this.priceCalculationMethod = self.priceCalculationMethod;
    this.isRoll = self.isRoll;
    this.combinationType = self.combinationType;
  };

  get extractedPositions() {
    return Position.extractPositions(this.positions);

    //TODO: this is giving error. However, we should do the below thing.
    // if (this._extractedPositions) {
    //   return this._extractedPositions;
    // }
    // this._extractedPositions = Position.extractPositions(this.positions);
    // return this._extractedPositions;
  }

  get stdDev() {
    if (!this._stdDev) {
      throw new Error('StdDev is undefined');
    }
    return this._stdDev;
  }

  set stdDev(value: StandardDeviation) {
    this._stdDev = value;
  }

  get predictions() {
    if (!this._predictions) {
      throw new Error('predictions is undefined');
    }
    return this._predictions;
  }

  set predictions(value: Predictions) {
    this._predictions = value;
  }

  get nameInPortfolio() {
    return CombinationTextGeneration.getPortfolioCombinationName(this, false);
  }

  get isShort() {
    const buyOrSell = this.buyOrSell();
    return buyOrSell.trim().toUpperCase() === BuyOrSell.SELL.toUpperCase();
  }

  get isLong() {
    const buyOrSell = this.buyOrSell();
    return buyOrSell.trim().toUpperCase() === BuyOrSell.BUY.toUpperCase();
  }

  get isTrade() {
    return this.combinationType === CombinationType.TRADE;
  }

  get isIncome() {
    return this.combinationType === CombinationType.INCOME;
  }

  get isAdjustment() {
    return this.combinationType === CombinationType.PORTFOLIO_ADJUSTMENT;
  }

  get isCurrent() {
    return this.combinationType === CombinationType.PORTFOLIO_CURRENT;
  }

  get isResulting() {
    return this.combinationType === CombinationType.PORTFOLIO_RESULTING;
  }

  get isPortfolio() {
    return this.isCurrent || this.isAdjustment || this.isResulting;
  }

  get hasOptionChain() {
    return this.chain && this.chain.rows.length > 0;
  }

  get isOnlyOptionsWithChain() {
    if (this.positions.length === 0) {
      return false;
    }

    let isOnlyStock = true;
    for (let position of this.positions) {
      if (position.isOptionType) {
        isOnlyStock = false;
        break;
      }
    }

    return !(!isOnlyStock && (!this.chain || this.chain.rows.length === 0));
  }

  //Overwritten in combination.ts because of type of Position.
  get buyablePositions() {
    return this.positions.filter((position) => {
      return !position.isOwned();
    });
  }

  get isIndex() {
    return formatting.checkIndices(this.quote?.exchange || '');
  }

  fullNameWithoutSymbol = () => CombinationTextGeneration.getfullTradeCombinationName(this, false);

  private calculateEigenvalueByPositions = (positions: BasicPositionModel[], multiplier: number) => {
    let eigenvalue = '';
    for (let i = 0; i < positions.length; i++) {
      const current = positions[i];
      const qty = current.legType === LegType.SECURITY ? current.quantity / multiplier : current.quantity;
      const previous: BasicPositionModel | undefined = positions[i - 1];
      let preQty = 1;
      if (previous) {
        preQty = previous.legType === LegType.SECURITY ? previous.quantity / multiplier : previous.quantity;
      }
      // eigenvalue += ((!!previous ? qty / preQty : qty > 0 ? 1 : -1) * 100).toFixed(0);
      // prettier-ignore
      /* Append for leg quantity
       * -ve = qantity < 0
       * +ve = quantity > 0
       */
      const qtyNumber = previous != undefined
        ? qty / preQty
        : qty > 0 ? 1 : -1;
      eigenvalue += (qtyNumber * 100).toFixed(0);
      // TODO: relook it from origional. added || !pos || pos.legType === LegType.SECURITY by ourself.
      // Atleast one of position is security. Set Eigen value as 2.

      /* Append for leg expiry
       * 0 = Both option are expiring together.
       *-1 = Previous is expiring first.
       * 1 = Current is expiriing first.
       * 2 = One of them is security
       */
      if (!previous || previous.legType === LegType.SECURITY || (current && current.legType === LegType.SECURITY)) {
        eigenvalue += 2;
      } else {
        // Positions are option type. They must have an expiry.
        if (!previous.expiry || !current.expiry) {
          throw new Error('Expiry of pos or prePos is undefined');
        }
        // Append (-1 or 1) if PREVIOUS is EXPIRING first ELSE 0.
        if (previous.expiry.toDateString() !== current.expiry.toDateString()) {
          eigenvalue += previous.expiry.getTime() < current.expiry.getTime() ? -1 : 1;
        } else {
          eigenvalue += 0;
        }
      }
      // prettier-ignore
      // eigenvalue += previous && previous.legType !== LegType.SECURITY ? (current.strikePrice === previous.strikePrice ? 0 : 1) : 2;

      /* Append for Leg price
       * 0 = Both price is equal
       * 1 = Both price are not equal.
       * 2 = Security
       */
      if (!previous || previous.legType === LegType.SECURITY) {
        eigenvalue += 2;
      } else {
        eigenvalue += current.strikePrice === previous.strikePrice ? 0 : 1;
      }

      // prettier-ignore
      //eigenvalue += current.legType !== LegType.SECURITY ? (current.legType === LegType.CALL ? 1 : 2) : 0;

      /* Append for Leg type
       * 0 = Security
       * 1 = option and its CALL
       * 2 = option and its PUT
       */
      if (current.legType === LegType.SECURITY) {
        eigenvalue += 0;
      } else {
        eigenvalue += current.legType === LegType.CALL ? 1 : 2;
      }
      eigenvalue += '|';
    }
    return eigenvalue;
  };

  private buyablePositionsEigenvalue = () => {
    const extractedBuyablePositions = Position.extractPositions(this.buyablePositions);
    return this.calculateEigenvalueByPositions(extractedBuyablePositions, this.multiplier());
  };

  private combinationEigenvalue = () => {
    return this.calculateEigenvalueByPositions(this.extractedPositions, this.multiplier());
  };

  private costCalculatorForCustomStrategies = (
    priceFunction: (position: BasicPositionModel) => number,
    positions?: BasicPositionModel[],
    ignoreCommissions?: boolean,
  ) => {
    positions = positions || this.positions;
    let result = positions.reduce((acc, pos) => {
      return acc + priceFunction(pos) * Math.sign(pos.quantity);
    }, 0);
    if (!ignoreCommissions) {
      result += this.commissionCost();
    }
    return result;
  };

  private compositionMarketValueCalulator(
    marketValueGetter: (position: BasicPositionModel) => number,
    positionsToIterate: BasicPositionModel[],
  ) {
    return positionsToIterate.reduce((acc: number, pos: BasicPositionModel) => {
      let value = marketValueGetter(pos) || 0;
      return acc + value * Math.sign(pos.quantity);
    }, 0);
  }

  private askBidNetCalculator(priceGetter: any) {
    return !this.getBuyablePositionsStrategy()
      ? this.costCalculatorForCustomStrategies(priceGetter, this.buyablePositions, true)
      : this.compositionMarketValueCalulator(priceGetter, this.buyablePositions);
  }

  private _getAggregated = (
    functionName: FunctionNameTypes,
    spotPrice?: number,
    dateTime?: Date,
    volatility?: number,
    useExpiryByDefault?: boolean,
    ignoreDividends?: boolean,
  ) => {
    spotPrice = spotPrice == null ? this.quote.last : spotPrice;
    dateTime = dateTime || (useExpiryByDefault ? this.expiration() : formatting.getCurrentDateTime());
    let result = 0;
    let lastPrice = this.quote.last;
    for (let position of this.positions) {
      let expiry = position.expiry;
      let yearToMaturity = this.getYearToMaturity(dateTime, expiry);
      let olDivs = position.dividendYield;
      // TODO: modifying the existing value for ignoring calculation can bring suble bug. Either clone the combination to do it or modify the function on mutable/readonly data.
      if (ignoreDividends) {
        //position.dividendYield = 0;
      }
      if (
        ApplicationContext.configuration.indicesForOptionsFuture &&
        ApplicationContext.configuration.indicesForOptionsFuture.findIndex(
          (i) => i.trim().toUpperCase() === position.symbol.trim().toUpperCase(),
        ) > -1
      ) {
        if (!position.chain) {
          throw new Error('position chain is undefined');
        }
        if (!position.strike) {
          // console.error('position strike is undefined');
          throw new Error('position strike is undefined');
        }
        // we only need to do this for the payoff function
        if (position.isCallType() || (position.isPutType() && functionName === 'payoff')) {
          // expiration of the selected position
          let positionExpiry = expiry;
          let whatIfDate = dateTime;
          let whatIfPrice: number | undefined = spotPrice;
          let positionChainRow = position.chain.findRow(
            position.strike.toString(),
            positionExpiry,
            position.optionType,
          );
          if (!positionChainRow) {
            throw new Error('PositionChainRow is undefined');
          }
          let positionForwardPrice = positionChainRow.forwardPrice();
          let adjustmentAtExpiry = positionForwardPrice - lastPrice;
          if (adjustmentAtExpiry > 0) {
            adjustmentAtExpiry = 0;
          }
          let expiries = position.chain.expiryList;
          if (!whatIfDate && !whatIfPrice) {
            throw new Error('Invalid data.');
          }
          let nearestExpiryGreaterThanWhatIfDate = expiries.filter((x) => {
            return x > (whatIfDate as Date);
          });

          if (nearestExpiryGreaterThanWhatIfDate.length > 0) {
            let adjustmentChainRow = position.chain.findRow(
              position.strike.toString(),
              nearestExpiryGreaterThanWhatIfDate[0],
              position.optionType,
            );
            if (!adjustmentChainRow) {
              throw new Error('adjustmentChainRow is undefined');
            }
            let adjustmentAtWhatIfDate = adjustmentChainRow.forwardPrice() - lastPrice;
            if (adjustmentAtWhatIfDate > 0) {
              adjustmentAtWhatIfDate = 0;
            }

            // set the spotPrice to the adjusted price
            spotPrice = (whatIfPrice as number) + adjustmentAtExpiry - adjustmentAtWhatIfDate;
          }
        }
      }
      let positionResult = position[functionName].call(position, spotPrice as number, yearToMaturity, volatility);
      // TODO: modifying the existing value for ignoring calculation can bring suble bug. Either clone the combination to do it or modify the function on mutable/readonly data.
      //position.dividendYield = olDivs;
      result += positionResult * position.quantity * position.premiumMultiplier();
    }
    return result;
  };

  // tag: index future pricing
  private getCalculatorFor(functionName: FunctionNameTypes, useExpiryByDefault?: boolean) {
    return (spotPrice?: number, dateTime?: Date, volatility?: number, ignoreDividends?: boolean) => {
      return this._getAggregated(functionName, spotPrice, dateTime, volatility, useExpiryByDefault, ignoreDividends);
    };
  }

  private getBuyablePositionsStrategy = () => {
    let eiganvalue = this.buyablePositionsEigenvalue();
    return StrategiesProvider.strategyTemplateByEigenvalue.get(eiganvalue);
  };

  getTemplateName(strategy?: IStrategyTemplate) {
    if (strategy) {
      return strategy.template.name.replace('Stock', 'Share');
    } else {
      return 'Custom Strategy';
    }
  }

  getRawPositions = () => {
    return this.positions.map((p) => {
      return p.getRawPosition();
    });
  };

  ownedPositions = () => {
    return this.positions.filter((position) => {
      return position.isOwned();
    });
  };

  multiplier = () => {
    let multipliers = this.positions.map((position) => {
      return position.premiumMultiplier();
    });
    // assume the multiplier is identical for options of the underlying. 1 for security.
    let multiplier = Math.max(...multipliers);
    return multiplier || 1;
  };

  extractedBuyablePositions = () => Position.extractPositions(this.buyablePositions);

  expiry = () => this.getCombinationExpiry();

  hasStock = () => {
    return this.positions.some((position) => {
      return position.type === LegType.SECURITY;
    });
  };

  hasOption = () => {
    return this.positions.some((position) => {
      return position.type !== LegType.SECURITY;
    });
  };

  // change it to getter.
  hasOnlyStx = () => {
    let positions = this.positions;
    return !this.hasOption() && positions && !!positions.length;
  };

  hasStxAndOpt = () => {
    return this.hasStock() && this.hasOption();
  };

  hasOnlyOptions = () => {
    return this.hasOption() && !this.hasStock();
  };

  matchedTemplate = () => {
    // TODO: fix specialStrategy.
    //SpecialStrategies.checkSpecialStrategies(this);
    const eigenvalue = this.combinationEigenvalue();
    const template = StrategiesProvider.strategyTemplateByEigenvalue.get(eigenvalue);
    return template || undefined;
  };

  buyOrSell = (): string => {
    let matchedTemplate = this.matchedTemplate();
    if (matchedTemplate) {
      if (this.isIncomeSpecific) {
        let isBuyOrSell = SpecialStrategies.checkSpecialStrategies(this);
        return isBuyOrSell?.incomeBuyOrSell() || '';
      }
      return matchedTemplate.buyOrSell;
    }
    if (this.positions.length === 0) {
      return '';
    }
    if (Math.sign(this.costWithoutOwned()) == 1) {
      return BuyOrSell.BUY;
    }
    if (Math.sign(this.costWithoutOwned()) == -1) {
      return BuyOrSell.SELL;
    }
    // TODO: May be returning undefined is better option.
    return BuyOrSell.BUY;
  };

  longOrShort = () => {
    if (this.isCoveredCall()) {
      return LongOrShort.SHORT;
    }
    if (this.isShort) {
      return LongOrShort.SHORT;
    }
    return LongOrShort.LONG;
  };

  isBuyCombination = () => {
    return this.buyOrSell() === BuyOrSell.BUY;
  };

  isSellCombination = () => {
    return this.buyOrSell() === BuyOrSell.SELL;
  };

  absQuantity = () => {
    const template = this.matchedTemplate();
    if (template && template.absQuantity !== undefined) {
      return template.absQuantity;
    }
    const quantities = this.extractedPositions.map((pos) => {
      return Math.abs(pos.quantity);
    });
    if (quantities.length === 0) {
      return 0;
    }
    let minQuantity = Math.min(...quantities);
    if (!isFinite(minQuantity) || isNaN(minQuantity)) {
      minQuantity = 0;
    }
    return minQuantity;
  };

  displayAbsQuantity = () => {
    return this.absQuantity() - 99;
  };

  defaultGreeks = {
    delta: () => {
      return this.delta();
    },
    gamma: () => {
      return this.gamma();
    },
    theta: () => {
      return this.theta();
    },
    vega: () => {
      return this.vega();
    },
  };

  commissionCost = () => {
    return tradeCostCommission(this);
  };

  protected costCalculator = (
    priceFunction: (position: BasicPositionModel) => number,
    positions?: BasicPositionModel[],
    ignoreCommissions?: boolean,
  ) => {
    positions = positions || this.positions;
    let result = positions.reduce((acc: number, pos: BasicPositionModel) => {
      let price = priceFunction(pos);
      let quantity = pos.quantity;
      let multiplier = pos.premiumMultiplier();
      return acc + price * quantity * multiplier;
    }, 0);
    if (!ignoreCommissions) {
      result += this.commissionCost();
    }
    return result;
  };

  cost = () => {
    return this.costCalculator((position) => {
      return position.price();
    });
  };

  midPricedCost = () => {
    return this.costCalculator((position) => {
      return position.midPrice();
    });
  };

  //overwritten in incomeCombination extended class.
  costWithoutOwned = () => {
    return this.costCalculator((pos) => {
      return pos.price();
    }, this.buyablePositions);
  };

  costWithoutComissions = () => {
    return this.cost() - this.commissionCost();
  };

  ask = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.askBidPrice();
    });
  };

  bid = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.oppositeAskBidPrice();
    });
  };

  mid = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.midPrice();
    });
  };

  netAsk = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.netAskBidPrice();
    });
  };

  netBid = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.netOppositeAskBidPrice();
    });
  };

  netMid = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.netMidPrice();
    });
  };

  netPrice = () => {
    return this.askBidNetCalculator((pos: Position) => {
      return pos.price();
    });
  };

  totalMarketValue = () => {
    return this.compositionMarketValueCalulator((pos: BasicPositionModel) => {
      return pos.totalMarketValue();
    }, this.ownedPositions());
  };

  costBasisTotal = () => {
    return this.compositionMarketValueCalulator((pos: BasicPositionModel) => {
      return pos.costBasisTotal() || 0;
    }, this.ownedPositions());
  };

  unrealizedProfitAndLoss = () => {
    return this.positions.reduce((acc, pos) => {
      let pl = pos.unrealizedProfitAndLoss() || 0;
      return acc + pl;
    }, 0);
  };

  extrinsicValue = () => {
    let extrinsic = 0;
    let pos;
    let strikePrice;
    let premium;
    let lastPrice;
    if (this.isPut()) {
      pos = this.extractedPositions[0];
      strikePrice = pos.strikePrice || 0;
      lastPrice = this.quote.last;
      premium = pos.price();
      if (strikePrice > lastPrice) {
        extrinsic = premium - (strikePrice - lastPrice);
      } else {
        extrinsic = premium;
      }
    } else if (this.isCoveredCall() && this.extractedPositions.length === 2) {
      pos = this.extractedPositions[1];
      strikePrice = pos.strikePrice;
      lastPrice = this.quote.last; //this.quote.last;
      premium = pos.price();
      if (strikePrice) {
        if (strikePrice > lastPrice) {
          extrinsic = premium;
        } else {
          extrinsic = premium - (lastPrice - strikePrice);
        }
      }
    }
    return extrinsic;
  };

  unrealizedProfitAndLossPercentage = () => {
    if (this.costBasisTotal()) {
      return (this.unrealizedProfitAndLoss() / this.costBasisTotal()) * 100;
    }
    return undefined;
  };

  summary = () => {
    let extractedPositions = this.extractedPositions.map((p: any) => {
      let result = p; //$.extend({}, p);
      delete result.price;
      return result;
    });

    let summary = {
      positions: extractedPositions,
      cost: this.cost(),
    };
    return JSON.stringify(summary);
  };

  strategyName = () => {
    return this.getTemplateName(this.matchedTemplate());
  };

  daysToExpiry = () => {
    let expiry = this.getCombinationExpiry();
    if (this.positions.length < 1 || this.hasOnlyStx() || !expiry) {
      return -999999;
    }
    return formatting.daysFromNow(expiry);
  };

  expiriesStrikes = () => {
    const expiries = this.getExpiries()
      .map((expiry) => {
        const formatTemplate = expiry.getFullYear() == new Date().getFullYear() ? 'T dd' : "T dd 'yy";
        return formatting.formatDate(expiry, formatTemplate, undefined, 'en-US');
      })
      .join('/');
    let strikes = this.strikes().join('/');
    if (this.matchedTemplate()?.template.name === StrategyName.PutVertical) {
      strikes = this.strikes().reverse().join('/');
    }
    return `${expiries} ${strikes}`;
  };

  isPut = () => {
    return this.strategyName() === 'Put';
  };

  isNakedPut = () => {
    return (this.isSellCombination() && this.isPut()) || false;
  };

  isCoveredCall = () => {
    let strategyName = this.strategyName();
    return strategyName.toUpperCase().match(`Covered ${LegType.CALL}`.toUpperCase());
  };

  isCalendar = () => {
    this.expiries().length > 1;
  };

  getYearToMaturity(dateTime: Date | undefined, expiry: Date | undefined) {
    if (!dateTime || !expiry) {
      //|| typeof dateTime.getTime !== '') {
      return 0;
    }
    // if dates are the same, then we can assume that user means expiry.
    if (formatting.sameDate(dateTime, expiry)) {
      return 0;
    }
    return (expiry.getTime() - dateTime.getTime()) / (365 * 24 * 3600 * 1000);
  }

  getBuyablePositionsStrategyName = () => {
    let strategy = this.getBuyablePositionsStrategy();
    return this.getTemplateName(strategy);
  };

  //TODO: Refactor These methods are calculating numer of times. The result should be cached.
  getExpiries = (positions?: BasicPositionModel[]) => {
    const appliedPositions = positions || Position.extractPositions(this.positions);
    const expiries = appliedPositions
      .filter((p) => p.expiry)
      .map((p) => p.expiry as Date)
      .sort((a: Date, b: Date) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
    const expiryMap = new Map<string, Date>();
    for (let expiry of expiries) {
      if (expiryMap.has(expiry.toISOString())) {
        continue;
      }
      expiryMap.set(expiry.toISOString(), expiry);
    }
    return Array.from(expiryMap.values());
  };

  expiries = () => this.getExpiries();

  getCombinationExpiry = (positions?: BasicPositionModel[]): Date | undefined => {
    let expiries = this.getExpiries(positions);
    if (expiries.length === 0) {
      return undefined;
    }
    return expiries[0];
  };

  getExpiryOrDefault = () => {
    let expiry = this.getCombinationExpiry();
    if (expiry) {
      return expiry;
    }
    expiry = formatting.getCurrentDate();
    if (!expiry) {
      throw new Error('expiry is undefined');
    }
    if (this.chain) {
      return this.chain.findExpiry(expiry, -1, this.defaultDaysToFindExpiry);
    }

    expiry.setDate(expiry.getDate() + this.defaultDaysToFindExpiry);
    return expiry;
  };

  strikes = () => {
    const positions = Position.extractPositions(this.positions); //(this.extractedPositions && this.extractedPositions) || Position.extractPositions(this.positions);
    let strikePrices = positions.map((p) => p.strikePrice).filter((p): p is number => p !== undefined && p !== null);
    strikePrices = unique(strikePrices);
    strikePrices.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    return strikePrices;
  };

  highSpotBound = () => {
    return this.quote.last * 10;
  };

  expiration = () => this.getExpiryOrDefault();

  /**
   * payoff outlook
   * @param spotPrice {string} stock spot price to outlook
   * @param dateTime {Date} what if datetime
   * @param whatIfVolatility {number} volatility of underlying to outlook
   */
  payoff = this.getCalculatorFor('payoff', true);

  getPayoffWithoutDividends = (spotPrice: number) => {
    return this.payoff(spotPrice, undefined, undefined, true);
  };

  payoffWithoutDividends = this.getPayoffWithoutDividends;

  delta = this.getCalculatorFor('delta');
  gamma = this.getCalculatorFor('gamma');
  vega = this.getCalculatorFor('vega');
  theta = this.getCalculatorFor('theta');
  rho = this.getCalculatorFor('rho');

  breakevens = (): number[] => {
    let strikes = this.strikes(); //(this.strikes && this.strikes.slice(0)) || this.getStrikes();
    strikes.unshift(0.01);
    strikes.push(this.highSpotBound());
    let breakevens = [];
    let expiries = this.getExpiries(); //(this.expiries && this.expiries) || this.getExpiries();
    let isPayoffStraightLine = expiries.length <= 1;
    for (let i = 1; i < strikes.length; i++) {
      let highSpot = strikes[i];
      if (highSpot === undefined) {
        throw new Error('HighSpot is undefined');
      }
      let lowSpot = strikes[i - 1] || 0.01;
      let lowPayoff = this.getPayoffWithoutDividends(lowSpot);
      let highPayoff = this.getPayoffWithoutDividends(highSpot);
      if (lowPayoff * highPayoff < 0) {
        let breakevenValue;
        if (isPayoffStraightLine) {
          let k = (highPayoff - lowPayoff) / (highSpot - lowSpot);
          breakevenValue = lowSpot - lowPayoff / k;
        } else {
          let currentSpot = (lowSpot + highSpot) / 2;
          let currentPayoff = this.getPayoffWithoutDividends(currentSpot);
          let lowBound = lowSpot;
          let highBound = highSpot;
          while (highBound - lowBound > 0.01) {
            if (currentPayoff * lowPayoff < 0) {
              highBound = currentSpot;
            } else if (currentPayoff * highPayoff < 0) {
              lowBound = currentSpot;
            } else {
              break;
            }

            currentSpot = (lowBound + highBound) / 2;
            lowPayoff = this.getPayoffWithoutDividends(lowBound);
            highPayoff = this.getPayoffWithoutDividends(highBound);
            currentPayoff = this.getPayoffWithoutDividends(currentSpot);
          }
          breakevenValue = currentSpot;
        }
        breakevens.push(NumberFormatHelper.roundNumber(breakevenValue));
      } else if (highPayoff === 0) {
        breakevens.push(NumberFormatHelper.roundNumber(highSpot));
      } else if (lowPayoff === 0) {
        breakevens.push(NumberFormatHelper.roundNumber(lowSpot));
      }
    }
    return unique(breakevens);
  };

  sentiment = () => {
    let perihelion = this.payoff(0.1);
    let aphelion = this.payoff(this.highSpotBound());
    let breakevens: number[] = this.breakevens(); //(this.breakevens && this.breakevens()) || this.getBreakevens();
    let focus = breakevens.length === 2 ? this.payoff((breakevens[0] + breakevens[1]) / 2) : 0;
    if (perihelion > 1 && aphelion > 1) {
      return CombinationSentiment.SHARP_MOVE;
    } else if (perihelion >= 1 && aphelion < 1) {
      return CombinationSentiment.BEARISH;
    } else if (perihelion < 1 && aphelion >= 1) {
      return CombinationSentiment.BULLISH;
    } else if (focus >= 1) {
      return CombinationSentiment.NEUTRAL;
    }
    return 'unknown';
  };

  maxRisk = () => {
    let highSpotBound = this.highSpotBound();
    let highPayoff = this.payoffWithoutDividends(highSpotBound);
    let highPayoff2 = this.payoffWithoutDividends(highSpotBound + 0.1);
    if (highPayoff2 - highPayoff < -0.5 && highPayoff2 < 0.01) {
      return RiskRewardType.UNLIMITED;
    }
    let lowPayoff = this.payoffWithoutDividends(0.1);
    let lowPayoff2 = this.payoffWithoutDividends(0.2);
    if (lowPayoff - lowPayoff2 < -0.01 && lowPayoff < -0.01) {
      return this.payoffWithoutDividends(0);
    }
    let strikes = this.strikes(); //(this.strikes && this.strikes.slice(0)) || this.getStrikes();

    strikes.push(0);
    strikes.push(highSpotBound);
    let payoffs = strikes.map((k: any) => {
      return this.payoffWithoutDividends(k);
    });
    payoffs.push(0);
    return Math.min(...payoffs);
  };

  maxReward = () => {
    let highSpotBound = this.highSpotBound();
    let highPayoff = this.payoffWithoutDividends(highSpotBound);
    let highPayoff2 = this.payoffWithoutDividends(highSpotBound + 0.1);
    if (highPayoff2 - highPayoff > 0.5 && highPayoff2 > 0.01) {
      return RiskRewardType.UNLIMITED;
    }
    let lowPayoff = this.payoffWithoutDividends(0.1);
    let lowPayoff2 = this.payoffWithoutDividends(0.2);
    if (lowPayoff - lowPayoff2 > 0.01 && lowPayoff > 0.01) {
      return this.payoffWithoutDividends(0);
    }
    let strikes = this.strikes(); //(this.strikes && this.strikes.slice(0)) || this.getStrikes();
    strikes.push(0);
    strikes.push(highSpotBound);
    let payoffs = strikes.map((k: any) => {
      return this.payoffWithoutDividends(k);
    });
    payoffs.push(0);
    return Math.max(...payoffs);
  };

  askPrice = () => {
    return Math.max(Math.abs(this.ask()), Math.abs(this.bid()));
  };

  bidPrice = () => {
    return Math.min(Math.abs(this.bid()), Math.abs(this.ask()));
  };

  midPrice = () => {
    let result = this.mid();
    return Math.abs(result);
  };

  netAskPrice = () => {
    return Math.max(Math.abs(this.netAsk()), Math.abs(this.netBid()));
  };

  netBidPrice = () => {
    return Math.min(Math.abs(this.netBid()), Math.abs(this.netAsk()));
  };

  netMidPrice = () => {
    let result = this.netMid();
    return Math.abs(result);
  };

  price = () => {
    let result = this.netPrice();
    return Math.abs(result);
  };

  costBasis = () => {
    let resultCostBasis = 0;
    // for covered call strategy calculation of Cost Basis differ from others strategies
    if (this.isCoveredCall()) {
      let totalCountOfSharesInLegs = 0;
      resultCostBasis = this.compositionMarketValueCalulator((pos: BasicPositionModel) => {
        if (pos.type.toUpperCase() === LegType.SECURITY.toUpperCase()) {
          totalCountOfSharesInLegs += pos.quantity;
        }
        if (!pos.costBasis) {
          throw new Error('cost basis is undefined');
        }
        return Math.abs(pos.costBasis * pos.quantity * pos.premiumMultiplier());
      }, this.ownedPositions());

      resultCostBasis /= totalCountOfSharesInLegs;
    } else {
      resultCostBasis = this.compositionMarketValueCalulator((pos: BasicPositionModel) => {
        return pos.costBasis as number;
      }, this.ownedPositions());
    }
    return this.allowNegativeCostBasis ? resultCostBasis : Math.abs(resultCostBasis);
  };

  worthlessProb = () => {
    if (!this._predictions) {
      throw new Error('predictions is undefined');
    }
    let prob = this._predictions.getProb(this.expiration());
    if (!prob) {
      throw new Error('Prob data is undefined');
    }
    if (this.isCoveredCall()) {
      return prob.getCumulativeProbability(this.strikes()[0]);
    }
    if (this.isNakedPut()) {
      return 1 - prob.getCumulativeProbability(this.strikes()[0]);
    }
    return 0;
  };

  annualizedReturn = () => {
    let extrinsic = this.extrinsicValue();
    if (extrinsic > 0) {
      let re = extrinsic / this.quote.last;
      let days = this.daysToExpiry() || 0.1;
      re = (re / (days / 365)) * 100;
      if (!isNaN(re)) {
        return re;
      }
    }
    return 0;
  };

  twelveMonthProjectedDividendYield = () => {
    let divYield = this.quote.yield;
    if (divYield == null) divYield = 0;
    return this.annualizedReturn() + divYield;
  };

  outlookPriceBoundsByExpiration = (percentBounds: number[], priceBounds: number[]) => {
    const expiration = this.expiration();
    let outlookPercentBounds = Array.from(percentBounds);
    let outlookPriceBounds = Array.from(priceBounds);
    let prob = this.predictions.getProb(expiration);
    if (prob) {
      for (let i = 0; i < 2; i++) {
        if (outlookPercentBounds[i]) {
          outlookPriceBounds[i] = prob.getPriceByProbability(outlookPercentBounds[i]);
        }
      }
    }
    return outlookPriceBounds;
  };

  probabilityOfProfit = () => {
    let breakevens = this.breakevens();
    let expiration = this.expiration();
    let prob = this.predictions.getProb(expiration);
    let result = undefined;

    if (prob) {
      breakevens.push(0);
      breakevens.push(prob.prices[prob.prices.length - 1] * 3);
      breakevens.sort((a: number, b: number) => {
        return a - b;
      });
      result = 0;
      for (let i = 0; i < breakevens.length - 1; i++) {
        let midPrice = (breakevens[i] + breakevens[i + 1]) / 2;
        let midPayout = this.getPayoffWithoutDividends(midPrice);
        if (midPayout > 0) {
          result += prob.percentileRank(breakevens[i], breakevens[i + 1]);
        }
      }
    }

    return result;
  };

  spread = () => {
    let ask = this.askPrice(),
      bid = this.bidPrice();
    return ask - bid;
  };

  //TODO: use toPercenrage helper for number-format-helper
  spreadPercent = () => {
    return ((this.spread() / this.askPrice()) * 100).toFixed(2) + '%';
  };
}

export default BasicCombination;
