import formatting from './formatting';
import { IStandardDeviation } from '.';
import DateTimeHelper from './date-time-helper';

export class StandardDeviation {
  readonly symbol;
  stdDevs: IStandardDeviation[] = [];

  constructor(symbol: string, last: number, data: IStandardDeviation[]) {
    this.symbol = symbol;
    this.readStdDev(data, last);
  }

  private readStdDev = (data: IStandardDeviation[], last: number) => {
    for (let i = 0; i < data.length; i++) {
      let sd: IStandardDeviation = {
        expiry: data[i].expiry,
        stdDevPrices: [...data[i].stdDevPrices],
      };
      sd.stdDevPrices.push(last);
      sd.stdDevPrices.sort((a, b) => {
        return a - b;
      });
      this.stdDevs.push({
        expiry: sd.expiry,
        stdDevPrices: [...sd.stdDevPrices],
      });
    }
  };

  getStdDevsByExpiry = (expiry: Date | undefined) => {
    if (this.stdDevs.length === 0) {
      return undefined;
    }
    if (!expiry) {
      return this.stdDevs[0].stdDevPrices;
    }
    for (let sd of this.stdDevs) {
      const sdExpiry = DateTimeHelper.resolveDate(sd.expiry);
      if (sdExpiry === undefined) {
        continue;
      }
      if (sdExpiry.valueOf() === expiry.valueOf()) {
        return sd.stdDevPrices;
      }
    }
    return undefined;
  };

  getStdDevUp = (expiry: Date | undefined, step?: number) => {
    let stdDevs = this.getStdDevsByExpiry(expiry);
    if (stdDevs === undefined || !stdDevs.length) {
      return [];
    }
    return stdDevs[3 + (step || 1)];
  };

  getStdDevDown = (expiry: Date | undefined, step?: number) => {
    const stdDevs = this.getStdDevsByExpiry(expiry);
    if (stdDevs === undefined || stdDevs.length === 0) {
      return [];
    }
    return stdDevs[3 - (step || 1)];
  };
}
