import { CombinationTextGeneration, IExpandedQuote } from '.';
import Combination from './combination';

class SingleTrade {
  public showPandLtarget = true; // This should come from configuation.
  combination: Combination;
  syrahSentiments: string | undefined = undefined;
  strategyChecklist: string | null = null;
  permissions = {}; //enums.Permissions;
  quote: IExpandedQuote;
  whatIfSPrice = 0.0; //strategyContext.whatIfSimulator.whatIfSPrice;
  whatIfDate = new Date(); //strategyContext.whatIfSimulator.whatIfDate;
  canShare = true; //strategyContext.canShare;
  disableOptionsPlayScorePanel = false; //strategyContext.disableOptionsPlayScorePanel;
  disableTrendsInSymbolWidget = false; //configuration.disableTrendsInSymbolWidget;
  combinationTitle = '';
  combinationTitleIsLong = false;
  flipped = false;
  language = 'en-US';

  // TODO: Vivek: clone the combination.
  // TODO: SingleTrade does not need strategyContext, specially, allCombination. Refactor to remove it.
  constructor(combination: Combination) {
    this.combination = combination;
    this.quote = combination.quote;
    this.activate();
  }

  activate = () => {
    const titles = CombinationTextGeneration.generateTradeTicketTitle(this.combination);
    this.combinationTitle = titles.length > 0 ? titles[0] : '';
    this.combinationTitleIsLong = this.combinationTitle.length > 60;
    this.syrahSentiments = this.combination.sentiment(); //context.trend;
    this.quote = this.combination.quote;
  };
}
export default SingleTrade;
