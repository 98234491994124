import { Grid, Skeleton, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import { LegType } from '@op/shared/src/models/enums/enums';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tsEnvironmentState, tsPositionDataState, tsSelectedAccountIdsState } from '..';
import { IconComponent } from '../../common';
import { OPSelectField, StyledDropdownItemWithBorder } from '../../styled';
import { IExpiration, IOptionExpiry, formatExpiryList, getPositionExpiration } from '../models/ts-expiry-strike';
import { IOrderTicketPosition, checkExistingPositionByAccountID, formatSlashDotSymbol } from '../models/ts-helpers';
import { getOptionStrikes } from '../services';
import {
  tsBidMidAskValueState,
  tsDualLoginErrorState,
  tsExpiryStrikeState,
  tsOrdetTicketPositionState,
  tsTriggerState,
} from '../states/ts-ticket-states';
import { StyledTableCell, StyledTableRow } from '../ts-styles-component';
import { OrderQuantityWidget } from './order-quantity-widget';
import { OrderSideWidget } from './order-side-widget';

interface Props {
  index: number;
  position: IOrderTicketPosition;
  removePosition: () => void;
  tsOptionExpirations: IOptionExpiry | undefined;
}

export const OrderPositionWidget: React.FC<Props> = ({
  index,
  position,
  removePosition,
  tsOptionExpirations,
}: Props) => {
  const theme = useTheme();
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const bidMidAskValue = useRecoilValue(tsBidMidAskValueState);
  const tsDualLoginError = useRecoilValue(tsDualLoginErrorState);
  const [tsOrderTicketPositions, setTsOrderTicketPositions] = useRecoilState(tsOrdetTicketPositionState);
  const [tsTrigger, setTsTrigger] = useRecoilState(tsTriggerState);
  const [tsOptionExpiryStrike, setTsOptionExpiryStrike] = useRecoilState(tsExpiryStrikeState);
  const prevPosition = useRef<IOrderTicketPosition | null>(null);

  // existing positions for underlyingSymbol
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const tsPositions = useRecoilValue(tsPositionDataState);
  const existingPosition = checkExistingPositionByAccountID(tsPositions, selectedAccountIds, position);

  useEffect(() => {
    if (!position || !position.expiry || !position.strike) {
      return;
    }
    if (!tsOptionExpirations) {
      return;
    }
    if (prevPosition.current && prevPosition.current === position) {
      return;
    }
    if (tsTrigger.target !== 'strike' || !tsTrigger.actionRequired) {
      return;
    }

    (async () => {
      const symbol = position.underlyingSymbol;
      const optionExpiry = getPositionExpiration(tsOptionExpirations.expirations, position.expiry);
      const positionExpiry = optionExpiry ? optionExpiry : tsOptionExpirations.expirations[0];
      const expiration = DateTimeHelper.formatExpiryDate(DateTimeHelper.resolveDate(positionExpiry.date), 'MM-dd-yyyy');
      const expiryStrike = tsOptionExpiryStrike && tsOptionExpiryStrike.find((t) => t.expiry === expiration);
      if (expiryStrike && expiryStrike.strikeList.length > 0) {
        const strike = await getStrike(expiryStrike.strikeList);
        updateFun('strike', strike);
        return;
      }
      const response = await getOptionStrikes(tsEnv, symbol, expiration);
      if (response.hasErrors) {
        return;
      }
      updatePos(response.data.strikes.flat(), positionExpiry); // NEED TO COMMENT
      // Update tsOptionExpiryStrike
      const existedExpiryStrike = cloneDeep(tsOptionExpiryStrike || []);
      existedExpiryStrike.push({ expiry: expiration, strikeList: response.data.strikes.flat() });
      setTsOptionExpiryStrike(existedExpiryStrike);
    })();
  }, [position, tsOptionExpirations]);

  const getStrike = async (strikeList: string[]) => {
    const optionStrike = strikeList.find((s) => s === position.strike.toString());
    const strike = optionStrike ? optionStrike : strikeList[0];
    return strike;
  };

  const updatePos = async (strikeList: string[], positionExpiry: IExpiration) => {
    const strike = await getStrike(strikeList);
    updateFun('expiry', DateTimeHelper.format(positionExpiry.date), true);
    updateFun('strike', strike, tsTrigger.target === 'strike' ? false : true);
  };

  const updateFun = (type: 'strike' | 'expiry' | 'type', value: string, fromUseEffect?: boolean) => {
    let clone = cloneDeep(tsOrderTicketPositions);
    let pos = clone[index];
    if (!pos) {
      return;
    }
    const isOptionSymbol = pos.type !== LegType.SECURITY;
    const symbol = formatSlashDotSymbol(pos.underlyingSymbol, isOptionSymbol);
    if (type === 'expiry') {
      pos.expiry = DateTimeHelper.resolveExpiry(new Date(value));
      pos.description = `${symbol} ${value} ${pos.strike} ${pos.type}`;
      pos.symbol = `${symbol} ${DateTimeHelper.toExpiryKey(pos.expiry, 'yyMMdd')}${pos.type.charAt(0)}${pos.strike}`;
    }
    if (type === 'strike') {
      if (pos.strike === Number(value)) {
        return;
      }
      pos.strike = Number(value);
      pos.description = `${symbol} ${DateTimeHelper.format(pos.expiry)} ${value} ${pos.type}`;
      pos.symbol = `${symbol} ${DateTimeHelper.toExpiryKey(pos.expiry, 'yyMMdd')}${pos.type.charAt(0)}${value}`;
    }
    if (type === 'type') {
      pos.type = value;
      pos.description = `${symbol} ${DateTimeHelper.format(pos.expiry)} ${pos.strike} ${value}`;
      pos.symbol = `${symbol} ${DateTimeHelper.toExpiryKey(pos.expiry, 'yyMMdd')}${value.charAt(0)}${pos.strike}`;
    }
    clone[index] = pos;
    prevPosition.current = fromUseEffect && pos;
    setTsOrderTicketPositions(clone);
    if (type === 'expiry') {
      !fromUseEffect && setTsTrigger({ target: 'strike', actionRequired: true });
    }
    if (type === 'strike' || type === 'type') {
      !fromUseEffect && setTsTrigger({ target: 'snapshot', actionRequired: true });
    }
  };

  const getExpiryTerm = () => {
    const expiration = getPositionExpiration(tsOptionExpirations.expirations, position.expiry);
    return expiration.type;
  };

  const renderExpiry = (position: IOrderTicketPosition) => {
    if (!tsOptionExpirations) {
      return (
        <Grid item xs={12}>
          <Skeleton animation="wave" width={'80%'} height={25} />
        </Grid>
      );
    }
    const formattedExpList = formatExpiryList(tsOptionExpirations.expirations);
    const expiryList = position.expiry ? formattedExpList.map((e) => e.date) : [];
    return (
      <Grid item xs={12}>
        <OPSelectField
          variant="outlined"
          disabled={position.isClose}
          value={DateTimeHelper.format(position.expiry)}
          onChange={(event) => {
            // NEED TO COMMENT
            updateFun('expiry', event.target.value as string);
          }}
          fullWidth
          sx={{
            width: '100%',
            height: 30,
            padding: 1,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
          }}>
          {expiryList.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item} value={item}>
                {item}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  const renderStrike = (position: IOrderTicketPosition) => {
    const exp = DateTimeHelper.formatExpiryDate(new Date(position.expiry), 'MM-dd-yyyy');
    const strikeByExpiry = (tsOptionExpiryStrike || []).find((e) => e.expiry === exp);
    if (!strikeByExpiry || strikeByExpiry.strikeList.length === 0) {
      return (
        <Grid item xs={12}>
          <Skeleton animation="wave" width={'80%'} height={25} />
        </Grid>
      );
    }
    // const strikeList = position.expiry ? tsOptionStrikes.strikes.flat() : [];
    const strikeList = strikeByExpiry.strikeList;
    return (
      <Grid item xs={12}>
        <OPSelectField
          variant="outlined"
          disabled={position.isClose}
          value={position.strike.toString()}
          onChange={(event) => {
            // NEED TO COMMENT
            updateFun('strike', event.target.value as string);
          }}
          fullWidth
          sx={{
            width: '80%',
            height: 30,
            padding: 1,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
          }}>
          {strikeList.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item} value={item}>
                {item}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  const renderType = (position: IOrderTicketPosition) => {
    const positionTypes = ['Call', 'Put'];
    return (
      <Grid item xs={12}>
        <OPSelectField
          variant="outlined"
          disabled={position.isClose}
          value={position.type}
          onChange={(event) => {
            updateFun('type', event.target.value as string);
          }}
          fullWidth
          sx={{
            width: '100%',
            height: 30,

            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            paddingLeft: 1,
          }}>
          {positionTypes.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item} value={item}>
                {item}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  const renderTerm = () => {
    if (!tsOptionExpirations) {
      return (
        <Grid item xs={12}>
          <Skeleton animation="wave" width={'80%'} height={25} />
        </Grid>
      );
    }
    return <>{getExpiryTerm()}</>;
  };

  const renderExistingPositions = (position: IOrderTicketPosition) => {
    // const p = existingPositions.find((p) => p.symbol === position.symbol);
    return existingPosition ? existingPosition.quantity : 0;
  };

  const renderDeleteLeg = () => {
    if (!bidMidAskValue && !tsDualLoginError) {
      return;
    }
    return (
      <StyledTableCell onClick={removePosition}>
        <IconComponent name={'crossDelete'} stroke={'#999999'} hoverColor={theme.palette.error.main} />
      </StyledTableCell>
    );
  };

  return (
    <StyledTableRow key={index}>
      <StyledTableCell>{position.description}</StyledTableCell>
      <OrderSideWidget index={index} position={position} />
      <OrderQuantityWidget index={index} position={position} />
      <StyledTableCell align="center">{renderExistingPositions(position)}</StyledTableCell>
      {position.type === LegType.SECURITY ? (
        <>
          <StyledTableCell> - </StyledTableCell>
          <StyledTableCell> - </StyledTableCell>
          <StyledTableCell>{position.type}</StyledTableCell>
        </>
      ) : (
        <>
          {/* <StyledTableCell align="left">{DateTimeHelper.format(position.expiry)}</StyledTableCell> */}
          <StyledTableCell align="left">{renderExpiry(position)}</StyledTableCell>
          <StyledTableCell align="left">{renderStrike(position)}</StyledTableCell>
          <StyledTableCell align="left">{renderType(position)}</StyledTableCell>
        </>
      )}
      {position.type === LegType.SECURITY ? (
        <>
          <StyledTableCell> - </StyledTableCell>
        </>
      ) : (
        <>
          <StyledTableCell>{renderTerm()} </StyledTableCell>
        </>
      )}
      <StyledTableCell>{position.symbol}</StyledTableCell>
      {renderDeleteLeg()}
    </StyledTableRow>
  );
};
