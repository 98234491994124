import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Grid, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import {
  configurationState,
  howDataState,
  selectedSymbolState,
  tradingRangeSimulatorState,
  whyDataState,
} from '@op/shared/src/states';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OptionsPlaySlider, OptionsPlayThumbComponent } from '../styled';
import { GuideItem } from '../side-menu';

/*NOTE: index.css is having a style rule on `.highcharts-text-outline` which removed stroke-width for the text on highcharts. The libraries, dataLabel api style is not working.*/
export const TradingRangeChartWidget: React.FC<{}> = () => {
  const howData = useRecoilValue(howDataState);
  const whyData = useRecoilValue(whyDataState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const configuration = useRecoilValue(configurationState);
  const [tradingRangeSimulator, setTradingRangeSimulator] = useRecoilState(tradingRangeSimulatorState);
  const [prices, setPrices] = React.useState<number[]>([]);
  const theme = useTheme<Theme>();

  useEffect(() => {
    if (!tradingRangeSimulator) {
      return;
    }
    setPrices(tradingRangeSimulator.chartSPrices);
  }, [tradingRangeSimulator]);

  if (!tradingRangeSimulator || prices.length === 0 || !howData || !configuration) {
    return <Skeleton height={150} animation="wave" />;
  }

  // TODO: Language here. Globalization.
  const maxPrice = tradingRangeSimulator.chartHighBound();
  const minPrice = tradingRangeSimulator.chartLowBound();
  const lengthOfSlider = maxPrice - minPrice;
  const supportsAndResistances = whyData?.supportAndResistance;

  const updatePrices = (values: number[]) => {
    const newTradingRangeSimulator = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    newTradingRangeSimulator.chartSPrices = values;
    setTradingRangeSimulator(newTradingRangeSimulator);
    ApplicationContext.userActivityHub?.logActivity(
      'editorChartSlider',
      'editorChartSlider',
      values,
      'slidechange',
      selectedSymbol,
    );
  };
  const onSliderChange = (_event: Event, value: number | number[], activeThumb: number) => {
    const lowOrHigh = activeThumb === undefined || activeThumb === 0 ? 'lowPrice' : 'highPrice';
    const symmetricPrices = tradingRangeSimulator.getSymmetricPrices(howData, value as number[], lowOrHigh);
    setPrices(symmetricPrices as number[]);
    ApplicationContext.userActivityHub?.logActivity(
      'editorChartSlider',
      'editorChartSlider',
      symmetricPrices,
      '',
      selectedSymbol,
    );
  };

  const gauss = (x: number, u: number, sigma: number) => {
    return (1 / (Math.sqrt(2 * Math.PI) * sigma)) * Math.exp(-Math.pow(x - u, 2) / (2 * Math.pow(sigma, 2)));
  };

  const rangeChartOptions = () => {
    const spotMarker = {
      enabled: true,
      fillColor: 'white',
      lineWidth: 2,
      lineColor: 'green',
      states: {
        hover: {
          enabled: false,
        },
      },
    };
    const seriesData = [];
    const lastPrice = howData.quote.last;
    const prices = tradingRangeSimulator.chartSPrices;
    const lowSpot = prices[0];
    const highSpot = prices[1];
    const dense = 50;
    const sds = tradingRangeSimulator.sds;
    const low = sds[0];
    const high = sds[sds.length - 1];

    const step = (high - low) / dense;
    const sd = (high - low) / 4;
    let y;
    for (let x = low; x <= high + 0.01; x += step) {
      if (x >= lowSpot && Math.abs(x - lowSpot) < step) {
        y = gauss(lowSpot, lastPrice, sd);
        const lowSpotDatum = { x: lowSpot, y: y, marker: spotMarker };
        seriesData.push(lowSpotDatum);
      } else if (x >= highSpot && Math.abs(x - highSpot) < step) {
        y = gauss(highSpot, lastPrice, sd);
        const highSpotDatum = { x: highSpot, y: y, marker: spotMarker };
        seriesData.push(highSpotDatum);
      }
      y = gauss(x, lastPrice, sd);
      const datum = { x: x, y: y };
      seriesData.push(datum);
    }
    const themeOptions = theme.palette.info.main;
    const fillColor = themeOptions;
    const fillGradient: Highcharts.GradientColorObject = {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
      stops: [
        [0, 'rgba(0, 0, 0, 0)'],
        [(lowSpot - low) / (high - low), 'rgba(0, 0, 0, 0)'],
        [(lowSpot - low) / (high - low), fillColor],
        [(highSpot - low) / (high - low), fillColor],
        [(highSpot - low) / (high - low), 'rgba(0, 0, 0, 0)'],
        [1, 'rgba(0, 0, 0, 0)'],
      ],
    };
    const rangeChartMainColor = theme.palette.primary.main;
    const textColor = theme.palette.text.primary;
    const formatingSDs = (idx: number) => {
      return `${NumberFormatHelper.toIntString(idx - 2, 'exceptZero')}SD`;
    };

    const chartOptions: Highcharts.Options = {
      chart: {
        width: 410,
        height: 150,
        margin: [0, 0, 0, 0],
        animation: false,
        backgroundColor: theme.palette.background.paper,
      },
      title: {
        text: '',
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      xAxis: [
        {
          lineWidth: 0,
          lineColor: 'transparent',
          labels: {
            enabled: false,
          },
          minorTickLength: 0,
          tickLength: 0,
        },
      ],
      yAxis: [
        {
          title: {
            text: '',
          },
          labels: {
            enabled: false,
          },
          gridLineWidth: 0,
          min: 0,
          maxPadding: 0.5,
          endOnTick: false,
        },
      ],
      tooltip: {
        formatter: function () {
          return NumberFormatHelper.toFractionalString(this.x);
        },
        borderColor: theme.palette.globalSelect.dark,
      },
      series: [
        {
          type: 'area',
          data: seriesData,
          lineColor: rangeChartMainColor,
          lineWidth: 3,
          marker: {
            enabled: false,
          },
          fillColor: fillGradient,
          zIndex: 7,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: { enabled: false },
    };

    const lineData = sds.map((spot, idx) => {
      y = gauss(spot, lastPrice, sd);
      return [
        { x: spot, y: 0 },
        {
          x: spot,
          y: y,
          dataLabels: {
            enabled: true,
            format: (idx === 2 ? '' : formatingSDs(idx)) + '<br/>' + NumberFormatHelper.toCurrency(spot),
            color: textColor,
            y: -12,
          },
        },
      ];
    });

    for (let i = 0; i < lineData.length; i++) {
      chartOptions.series?.push({
        type: 'area',
        data: lineData[i],
        marker: {
          enabled: false,
        },
        lineColor: rangeChartMainColor,
        lineWidth: 3,
        fillColor: fillGradient,
        zIndex: 8,
      });
    }

    return chartOptions;
  };

  const valueLabelFormat = (value: number) => {
    const midSD = howData.quote.last;
    const differenceWithLast = NumberFormatHelper.toCurrency(value - midSD);
    const chartThumbPrice = NumberFormatHelper.toCurrency(value);
    return (
      <>
        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
          {chartThumbPrice}
        </Typography>
        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
          {differenceWithLast}
        </Typography>
      </>
    );
  };

  const sliderTicks = tradingRangeSimulator.sds.map((x) => {
    const value: number = x;
    return {
      value,
    };
  });

  const leftProbPosition = () => {
    const leftProbability = tradingRangeSimulator.leftProbPos();
    if (!leftProbability.display) {
      return null;
    }
    const leftPosition = parseFloat(leftProbability.prob.toFixed(2));
    return (
      <div
        style={{
          position: 'absolute',
          left: leftProbability.left,
          bottom: leftProbability.bottom,
        }}>
        {NumberFormatHelper.toPercentage(leftPosition)}
      </div>
    );
  };

  const rightProbPosition = () => {
    const rightProbability = tradingRangeSimulator.rightProbPos();
    if (!rightProbability.display) {
      return null;
    }
    const rightPosition = parseFloat(rightProbability.prob.toFixed(2));
    return (
      <div
        style={{
          position: 'absolute',
          left: rightProbability.left,
          bottom: rightProbability.bottom,
        }}>
        {NumberFormatHelper.toPercentage(rightPosition)}
      </div>
    );
  };

  const getCarretPositions = (points: number[] | undefined) => {
    if (!points) {
      return [];
    }
    return points
      .filter((p) => p >= minPrice && p <= maxPrice)
      .map((p) => {
        const lengthOfSupportFromLeft = p - minPrice;
        return { positionPercentage: (lengthOfSupportFromLeft / lengthOfSlider) * 100, value: p };
      })
      .slice(0, 2);
  };

  const renderSupportCarrets = () => {
    const supports = supportsAndResistances?.support.map((s) => s.value);
    const carretPositions = getCarretPositions(supports);
    return carretPositions.map((p, i) => (
      <KeyboardArrowUpIcon
        key={i}
        fontSize="small"
        onClick={() => updatePrices([p.value, prices[1]])}
        sx={{
          fontWeight: 'bold',
          position: 'absolute',
          top: '15px',
          left: `${p.positionPercentage}%`,
          transform: `translate(-50%, 0%)`,
          color: 'success.main',
          cursor: 'pointer',
        }}
      />
    ));
  };

  const renderResistanceCarrets = () => {
    const resistances = supportsAndResistances?.resistance.map((r) => r.value);
    const carretPositions = getCarretPositions(resistances);
    return carretPositions.map((p, i) => (
      <KeyboardArrowUpIcon
        key={i}
        fontSize="small"
        onClick={() => updatePrices([prices[0], p.value])}
        sx={{
          fontWeight: 'bold',
          position: 'absolute',
          top: '15px',
          left: `${p.positionPercentage}%`,
          transform: `translate(-50%, 0%)`,
          color: 'error.main',
          cursor: 'pointer',
        }}
      />
    ));
  };

  const renderSlider = () => {
    return (
      <Box sx={{ position: 'relative' }}>
        <OptionsPlaySlider
          components={{ Thumb: OptionsPlayThumbComponent }}
          value={prices}
          valueLabelFormat={valueLabelFormat}
          aria-labelledby="range-slider"
          min={minPrice}
          max={maxPrice}
          marks={sliderTicks}
          onChange={onSliderChange}
          onChangeCommitted={(_, value: number | number[]) => updatePrices(value as number[])}
          valueLabelDisplay="on"
          step={0.01}
          sx={{
            '& .MuiSlider-valueLabel': { top: 70 },
            '& .MuiSlider-valueLabel::before': { top: '-8px' },
          }}
        />
        {renderSupportCarrets()}
        {renderResistanceCarrets()}
      </Box>
    );
  };

  const chartOptions: Highcharts.Options = rangeChartOptions();
  return (
    <Grid container>
      <Grid item xs={12} textAlign="center">
        <Box sx={{ display: 'inline-block', position: 'relative' }}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          {leftProbPosition()}
          {rightProbPosition()}
          <GuideItem selector=".tradingRangeSlider_helpPinPlaceholder" />
          {renderSlider()}
        </Box>
      </Grid>
    </Grid>
  );
};
