import { Tooltip } from '@mui/material';
import { IFilter2, cloneDeep } from '@op/shared/src/models';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { accountState, customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OrangeButton } from '../styled';
import { IconComponent } from './icons';

type Props = {
  filter: IFilterGroup;
  onClick: (group: IFilterGroup) => void;
  icon: string;
  stroke?: string;
};

export const ViewFiltersByCount2: React.FC<Props> = ({ filter, onClick, icon, stroke }: Props) => {
  const { t } = React.useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const customization = useRecoilValue(customizationState);
  const f = filter.filters[0];

  const onSelect = (f: IFilter2) => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      if (x.name !== f.name) {
        continue;
      }
      if (!x.selected && Number(f.title) === 0) {
        return;
      }
      x.selected = !f.selected;
    }
    onClick(clone);
  };

  const canAccessPortfolio = account.canWorkWithPortfolios && filter.name === 'linkedPortfolios';
  const canAccessWatchList = account.canWorkWithWatchLists && filter.name === 'linkedWatchLists';
  const canAccessIdea = account.canWorkWithTradeIdeas && filter.name === 'linkedIdeas';

  if (!customization || !customization.showTechnicalReports) {
    return <></>;
  }

  if (!canAccessPortfolio && !canAccessWatchList && !canAccessIdea) {
    return <></>;
  }

  return (
    <Tooltip title={t(filter.translationKey)} placement="top" arrow>
      <OrangeButton
        id={`js-${filter.name}-filter-icon`}
        disableRipple={f.selected}
        isSelected={f.selected}
        onClick={() => onSelect(f)}>
        <IconComponent
          name={icon}
          style={{ paddingBottom: icon === 'tradeIdeaDisabled' ? 3 : null }}
          stroke={stroke || 'inherit'}
        />
        <OPBoldTypograpghy sx={{ pl: 1, color: Number(f.title) === 0 ? '#999999' : null }}>
          {NumberFormatHelper.numberWithTrailingZero(Number(f.title))}
        </OPBoldTypograpghy>
      </OrangeButton>
    </Tooltip>
  );
};
