import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Grid, Theme, useTheme } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { groupBy as rowGrouper } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Column, RenderSortStatusProps, SelectColumn, SortColumn, TreeDataGrid } from 'react-data-grid';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LastPriceWidget } from './components/last-price-widget';
import { PositionsSkeleton } from './components/position-skeleton';
import { TsSpan } from './components/ts-styled-components';
import { useFetchandSetPositionStream, useFetchandSetQuotesStream } from './components/useStream';
import { ITradeStatationPosition } from './models/getPositions';
import { getPositions } from './services';
import {
  getAllTSPositionsState,
  tsEnvironmentState,
  tsPositionDataState,
  tsPositionUnderlyingSymbolsState,
  tsPositionsUpdater,
  tsRefreshAccountTokenState,
  tsSelectedAccountIdsState,
} from './states/trade-station-states';
import './styles/styles.css';
import { TsOOPS } from './ts-oops';
import { TsPositionActionMenu } from './ts-position-action-menu';
import { TsPositionHeader } from './ts-positions-header';
export interface PositionRow {
  id: number;
  accountId: string;
  underlyingSymbol: string;
  symbol: string;
  qty: string;
  side: string;
  avgPrice: string;
  last: string;
  todaysPL: string;
  unrealizedPL: string;
  unrealizedPLPercentage: string;
  totalCost: string;
  marketValue: string;
  positionId: string;
  timeStamp: Date;
  assetType: string;
  longShort: string;
}

interface SummaryRow {
  title: string;
  totalTodaysPL: number;
  totalOpenPL: number;
  totalCost: number;
  marketValue: number;
}

const getCellTextColor = (value: any, theme: Theme) => {
  if (value !== undefined && Number(value) === 0) {
    return theme.palette.text.primary;
  } else if (Math.sign(Number(value)) === 1) {
    return theme.palette.success.main;
  } else {
    return theme.palette.error.main;
  }
};

const getColumns = (theme: Theme, isAllGroupExpanded: boolean, headerCall: () => void) => {
  const columns = [
    SelectColumn,
    {
      key: 'underlyingSymbol',
      name: '',
      sortable: false,
      editable: false,
      maxWidth: 30,
      renderHeaderCell: () => (
        <div onClick={headerCall} style={{ cursor: 'pointer' }}>
          {isAllGroupExpanded ? (
            <KeyboardDoubleArrowDownIcon style={{ verticalAlign: 'middle', color: '#0078D4' }} />
          ) : (
            <KeyboardDoubleArrowRightIcon
              style={{ verticalAlign: 'middle', color: theme.palette.mode === 'light' ? '#999999' : '#ddd' }}
            />
          )}
        </div>
      ),
      renderSummaryCell({ row: { title } }) {
        return (
          <TsSpan>
            <b>{title}</b>
          </TsSpan>
        );
      },
      renderGroupCell({ groupKey, row }) {
        return row && row.isExpanded ? (
          <KeyboardDoubleArrowDownIcon sx={{ verticalAlign: 'middle', color: theme.palette.primary.main }} />
        ) : (
          <KeyboardDoubleArrowRightIcon
            style={{ verticalAlign: 'middle', color: theme.palette.mode === 'light' ? '#999999' : '#ddd' }}
          />
        );
      },
    },
    {
      key: 'symbol',
      name: 'Symbol',
      sortable: true,
      editable: false,
      minWidth: 150,
      headerCellClass: 'symbol-header-cell',
      renderGroupCell({ groupKey }) {
        return <TsSpan>{groupKey}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{row.symbol}</TsSpan>;
      },
    },
    {
      key: 'side',
      name: 'Side',
      editable: false,
      renderCell({ row }) {
        return (
          <TsSpan color={row.side && row.side === 'Long' ? theme.palette.primary.main : theme.palette.error.main}>
            {row.side ? row.side : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'qty',
      name: 'Qty',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.qty ? row.qty : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'avgPrice',
      name: 'Avg Price',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.avgPrice ? NumberFormatHelper.roundNumber(row.avgPrice) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'last',
      name: 'Last Price',
      editable: false,
      renderGroupCell({ groupKey }) {
        return <LastPriceWidget symbol={groupKey} />;
      },
      renderCell({ row }) {
        return <TsSpan>{row.last ? NumberFormatHelper.roundNumber(row.last) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'todaysPL',
      name: `Today's P/L`,
      editable: false,
      renderSummaryCell({ row: { totalTodaysPL } }) {
        return (
          <TsSpan color={getCellTextColor(totalTodaysPL, theme)}>
            <b>{totalTodaysPL !== undefined ? NumberFormatHelper.toCurrency(totalTodaysPL) : 'N/A'}</b>
          </TsSpan>
        );
      },
      renderGroupCell({ childRows }) {
        const groupTodaysPL = childRows.reduce(
          (prev: number, { todaysPL }: any) => NumberFormatHelper.roundNumber(prev + Number(todaysPL)),
          0,
        );
        return (
          <TsSpan color={getCellTextColor(groupTodaysPL, theme)}>
            {groupTodaysPL !== undefined ? NumberFormatHelper.toCurrency(groupTodaysPL) : 'N/A'}
          </TsSpan>
        );
      },
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.todaysPL, theme)}>
            {row.todaysPL ? NumberFormatHelper.toCurrency(row.todaysPL) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'unrealizedPL',
      name: 'Unrealized P/L',
      editable: false,
      renderSummaryCell({ row: { totalOpenPL } }) {
        return (
          <TsSpan color={getCellTextColor(totalOpenPL, theme)}>
            <b>{totalOpenPL !== undefined ? NumberFormatHelper.toCurrency(totalOpenPL) : 'N/A'}</b>
          </TsSpan>
        );
      },
      renderGroupCell({ childRows }) {
        const groupUnrealizedPL = childRows.reduce(
          (prev: number, { unrealizedPL }: any) => NumberFormatHelper.roundNumber(prev + Number(unrealizedPL)),
          0,
        );
        return (
          <TsSpan color={getCellTextColor(groupUnrealizedPL, theme)}>
            {groupUnrealizedPL !== undefined ? NumberFormatHelper.toCurrency(groupUnrealizedPL) : 'N/A'}
          </TsSpan>
        );
      },
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.unrealizedPL, theme)}>
            {row.unrealizedPL ? NumberFormatHelper.toCurrency(row.unrealizedPL) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'totalCost',
      name: `Total Cost`,
      editable: false,
      renderSummaryCell({ row: { totalCost } }) {
        return (
          <TsSpan color={getCellTextColor(totalCost, theme)}>
            <b>{totalCost !== undefined ? NumberFormatHelper.toCurrency(totalCost) : 'N/A'}</b>
          </TsSpan>
        );
      },
      renderGroupCell({ childRows }) {
        const groupTotalCost = childRows.reduce((prev: number, { totalCost }: any) => {
          return prev + Number(totalCost);
        }, 0);
        return (
          <TsSpan color={getCellTextColor(groupTotalCost, theme)}>
            {groupTotalCost !== undefined ? NumberFormatHelper.toCurrency(groupTotalCost) : 'N/A'}
          </TsSpan>
        );
      },
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.totalCost, theme)}>
            {row.totalCost ? NumberFormatHelper.toCurrency(row.totalCost) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'marketValue',
      name: `Market Value`,
      editable: false,
      renderSummaryCell({ row: { marketValue } }) {
        return (
          <TsSpan color={getCellTextColor(marketValue, theme)}>
            <b>{marketValue !== undefined ? NumberFormatHelper.toCurrency(marketValue) : 'N/A'}</b>
          </TsSpan>
        );
      },
      renderGroupCell({ childRows }) {
        const marketGroupValue = childRows.reduce((prev: number, { marketValue }: any) => {
          return prev + Number(marketValue);
        }, 0);
        return (
          <TsSpan color={getCellTextColor(marketGroupValue, theme)}>
            {marketGroupValue !== undefined ? NumberFormatHelper.toCurrency(marketGroupValue) : 'N/A'}
          </TsSpan>
        );
      },
      renderCell({ row }) {
        return (
          <TsSpan color={getCellTextColor(row.marketValue, theme)}>
            {row.marketValue ? NumberFormatHelper.toCurrency(row.marketValue) : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'positionId',
      name: 'Position Id',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.positionId ? row.positionId : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'timeStamp',
      name: 'Opened At',
      editable: false,
      minWidth: 180,
      renderCell({ row }) {
        return <TsSpan>{row.timeStamp ? DateTimeHelper.getLocalTime(row.timeStamp) : 'N/A'}</TsSpan>;
      },
    },
  ];
  return columns;
};

export const TSPositionsWidget: React.FC = () => {
  const selectedOptions = ['underlyingSymbol'] as const;
  const fetchPositionsStream = useFetchandSetPositionStream();
  const fetchQuotesStream = useFetchandSetQuotesStream();
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);
  const positionsData = useRecoilValue(tsPositionDataState);
  const positionUnderlyingSymbols = useRecoilValue(tsPositionUnderlyingSymbolsState);
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<number> => new Set());
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set<unknown>(['underlyingSymbol']));
  const [rows, setRows] = useState<PositionRow[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const theme = useTheme();
  const [column, setColumn] = useState<Column<any, any>[]>([]);
  const [isFetchingPositions, setIsFetchingPositions] = useState(false);
  const [isAllGroupExpanded, setIsAllGroupExpanded] = useState(false);
  // Hub positions
  const setTsPositionsUpdater = useSetRecoilState(tsPositionsUpdater);
  const positions = useRecoilValue(getAllTSPositionsState);
  const [refreshAccountToken, setRefreshAccountToken] = useRecoilState(tsRefreshAccountTokenState);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0 || isFetchingPositions) {
      return;
    }
    // if (positions && positions.length > 0) {
    //   return;
    // }
    // Set the state to indicate that positions are being fetched (async call is in progress)
    setIsFetchingPositions(true);
    (async () => {
      const response = await getPositions(tsEnv, selectedAccountIds);
      if (response.hasErrors) {
        return;
      }
      setTsPositionsUpdater(response.data.positions);
      // Reset the state after fetching positions is completed
      setIsFetchingPositions(false);
      resetExpandedIds();
    })();
  }, [selectedAccountIds, positionsData]);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return;
    }
    if (!positionsData || positionsData.length === 0) {
      return;
    }
    const positionStreamController = new AbortController();

    (async () => {
      await fetchPositionsStream(positionStreamController, selectedAccountIds);
    })();
    return () => {
      setTimeout(() => {
        try {
          if (positionStreamController.signal.aborted) {
            return;
          }
          positionStreamController.abort('executed positions abort');
          if (refreshAccountToken) {
            setRefreshAccountToken(true);
          }
        } catch (e) {
          if (e instanceof DOMException && e.name === 'AbortError') {
          } else {
            console.log('Download error: ' + e.message);
          }
        }
      }, 100);
    };
  }, [selectedAccountIds, positionsData, refreshAccountToken]);

  useEffect(() => {
    if (!positionsData || positionsData.length === 0) {
      return;
    }
    // const symbols = positionsData
    //   .map((p) => p.underlyingSymbol)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    const symbols = positionUnderlyingSymbols.filter((value, index, self) => self.indexOf(value) === index);

    const quoteController = new AbortController();

    (async () => {
      await fetchQuotesStream(quoteController, symbols);
    })();
    return () => {
      setTimeout(() => {
        try {
          if (quoteController.signal.aborted) {
            return;
          }
          quoteController.abort('executed abort');
          if (refreshAccountToken) {
            setRefreshAccountToken(true);
          }
        } catch (e) {
          if (e instanceof DOMException && e.name == 'AbortError') {
          } else {
            console.log('Download error: ' + e.message);
          }
        }
      }, 100);
    };
  }, [positionsData, refreshAccountToken]);

  useEffect(() => {
    commonColumnCall();
  }, [theme, selectedRows]);

  useEffect(() => {
    commonColumnCall();
    const groupRowSymbols = new Set(['underlyingSymbol', ...sortedRows.map((row) => row.underlyingSymbol)]);
    const newExpandedIds = isAllGroupExpanded ? groupRowSymbols : new Set(['underlyingSymbol']);
    setExpandedGroupIds(newExpandedIds);
  }, [theme, isAllGroupExpanded]);

  const commonColumnCall = () => {
    const headerCall = () => setIsAllGroupExpanded(!isAllGroupExpanded);
    const columnsObj = getColumns(theme, isAllGroupExpanded, headerCall);
    setColumn(columnsObj);
  };

  useEffect(() => {
    if (!positions) {
      return;
    }
    createPositionRows(positions);
  }, [selectedAccountIds, positions]);

  useEffect(() => {
    if (expandedGroupIds.size === 1) {
      setIsAllGroupExpanded(false);
      return;
    }
    const groupRowSymbols = new Set(['underlyingSymbol', ...sortedRows.map((row) => row.underlyingSymbol)]);
    if (!isAllGroupExpanded && expandedGroupIds.size !== groupRowSymbols.size) {
      return;
    }
    setIsAllGroupExpanded(true);
  }, [expandedGroupIds]);

  const resetExpandedIds = () => {
    setExpandedGroupIds(new Set(['underlyingSymbol']));
    setIsAllGroupExpanded(false);
  };

  const sortedRows = useMemo((): PositionRow[] => {
    if (sortColumns.length === 0) return rows;
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        if (sort.columnKey !== 'symbol') {
          return 0;
        }
        if (sort.direction === 'ASC') {
          return a.underlyingSymbol.localeCompare(b.underlyingSymbol);
        }
        if (sort.direction === 'DESC') {
          return b.underlyingSymbol.localeCompare(a.underlyingSymbol);
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const rowKeyGetter = (row: PositionRow) => {
    return row.id;
  };

  const createPositionRows = (positions: ITradeStatationPosition[]) => {
    const rows: PositionRow[] = [];
    for (let i = 0; i < positions.length; i++) {
      rows.push({
        id: i,
        accountId: positions[i].accountID,
        underlyingSymbol:
          positionUnderlyingSymbols[i] ||
          positions[i].symbol.substring(
            0,
            positions[i].symbol.indexOf(' ') === -1 ? positions[i].symbol.length : positions[i].symbol.indexOf(' '),
          ),
        symbol: positions[i].symbol,
        qty: positions[i].quantity,
        side: positions[i].longShort,
        avgPrice: positions[i].averagePrice,
        last: positions[i].last,
        todaysPL: positions[i].todaysProfitLoss,
        unrealizedPL: positions[i].unrealizedProfitLoss,
        unrealizedPLPercentage: positions[i].unrealizedProfitLossPercent,
        totalCost: positions[i].totalCost,
        marketValue: positions[i].marketValue,
        positionId: positions[i].positionID,
        timeStamp: positions[i].timestamp,
        assetType: positions[i].assetType,
        longShort: positions[i].longShort,
      });
    }
    const sortedRows = rows.sort((r1, r2) => r1.underlyingSymbol.localeCompare(r2.underlyingSymbol));
    setRows(sortedRows);
  };

  const summaryRows = useMemo((): readonly SummaryRow[] => {
    const todaysPL = rows.reduce((prev: number, { todaysPL }: any) => {
      return prev + Number(todaysPL);
    }, 0);
    const openPL = rows.reduce((prev: number, { unrealizedPL }: any) => {
      return prev + Number(unrealizedPL);
    }, 0);
    const totalCost = rows.reduce((prev: number, { totalCost }: any) => {
      return prev + Number(totalCost);
    }, 0);
    const marketValue = rows.reduce((prev: number, { marketValue }: any) => {
      return prev + Number(marketValue);
    }, 0);
    return [
      { title: 'Totals', totalTodaysPL: todaysPL, totalOpenPL: openPL, totalCost: totalCost, marketValue: marketValue },
    ];
  }, [rows]);

  const renderSortStatus = ({ sortDirection, priority }: RenderSortStatusProps) => {
    return (
      <>
        {sortDirection !== undefined ? (
          sortDirection === 'ASC' ? (
            <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          ) : (
            <SouthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          )
        ) : (
          <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: '#BFE0FA' }} />
        )}
        <span>{priority}</span>
      </>
    );
  };

  const renderOOPSImage = () => {
    return <TsOOPS name="Positions" />;
  };

  const renderPositionAction = () => {
    const selectedPositions = rows.filter((row) => selectedRows.has(row.id));
    if (selectedPositions.length === 0) {
      return;
    }
    return <TsPositionActionMenu selectedPositions={selectedPositions} />;
  };

  if (!selectedAccountIds || selectedAccountIds.length === 0) {
    return renderOOPSImage();
  }

  if (!positionsData || isFetchingPositions) {
    return (
      <Grid container item xs={12} justifyContent={'center'} alignContent={'center'}>
        <PositionsSkeleton />
      </Grid>
    );
  }

  const onSelectedRowsChange = (selectedRows: Set<number>) => {
    const isValidSelection = sortedRows
      .filter((row) => selectedRows.has(row.id))
      .reduce((acc, curr, index, arr) => acc && (!index || curr.underlyingSymbol === arr[0].underlyingSymbol), true);
    const rowIds = Array.from(selectedRows);
    const selectRows = isValidSelection ? selectedRows : new Set([rowIds[rowIds.length - 1]]);
    setSelectedRows(selectRows);
  };

  const renderPositionDataGrid = () => {
    if (positions.length === 0) {
      return renderOOPSImage();
    }
    const blockSize = selectedRows.size === 0 ? 135 : 184;
    return (
      <TreeDataGrid
        className={theme.palette.mode === 'dark' ? 'rdg-dark' : 'rdg-light'}
        style={{ blockSize: `calc(55vh - ${blockSize}px)` }} // TO BE FIXED w/ vh
        columns={column}
        rows={sortedRows}
        rowKeyGetter={rowKeyGetter}
        selectedRows={selectedRows}
        onSelectedRowsChange={(selectedRows) => onSelectedRowsChange(selectedRows)}
        groupBy={selectedOptions}
        rowGrouper={rowGrouper}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        defaultColumnOptions={{ resizable: false }}
        direction={'ltr'}
        renderers={{
          renderSortStatus,
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        topSummaryRows={summaryRows}
        // style={{ height: selectedRows.size === 0 ? 'inherit' : '80%' }}
      />
    );
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <TsPositionHeader />
      </Grid>
      <Grid item xs={12}>
        {renderPositionDataGrid()}
        {renderPositionAction()}
      </Grid>
    </Grid>
  );
};
