import { atom, atomFamily, selector } from 'recoil';
import { guardRecoilDefaultValue, howDataState, tradingRangeSimulatorAtom } from '.';

import HowDataModel from '../models/how/how-data-model';
import { PortfolioCombination } from '../models/portfolio/portfolio-combination-model';
import { PortfolioManagementCombination } from '../models/portfolio/portfolio-management-combination-model';
import { PortfolioPositionsManagement } from '../models/portfolio/portfolio-positions-management';
import { ProfitAndLossCalculator } from '../models/portfolio/profitandloss-calculator';
import { Recommendation } from '../models/portfolio/recommendation-model';
import { TradingRangeSimulator } from '../models/how/trading-range-simulator';
import TradingStrategies from '../models/how/trading-strategies';
import WhatIfSimulator from '../models/how/whatif-simulator';
import { getHow } from '../services';
import { whatIfSimulatorState } from '@op/shared/src/states';

export const selectedRecommendationState = atom<Recommendation | undefined>({
  key: 'selectedRecommendationStateKey',
  default: undefined,
});

//TODO: This does not belong here. Remove from here.
export const plCalculatorState = atom<ProfitAndLossCalculator | undefined>({
  key: 'plCalculatorKey',
  default: undefined,
});

export const portfolioManagementCombinationState = atomFamily<PortfolioManagementCombination | undefined, number>({
  key: 'portfolioManagementCombinationStateKey',
  default: undefined,
});

export const portfolioAllCombinationsState = selector({
  key: 'portfolioAllCombinationsStateKey',
  get: ({ get }) => {
    //different read, so that notification can happen on this.
    const current = get(portfolioManagementCombinationState(0));
    const recommended = get(portfolioManagementCombinationState(1));
    const resulting = get(portfolioManagementCombinationState(2));
    return [current, recommended, resulting];
  },
});

export const portfolioManagementCombinationsUpdaterState = selector<(PortfolioManagementCombination | undefined)[]>({
  key: 'portfolioManagementCombinationsUpdaterStateKey',
  get: () => {
    throw new Error('this is an updater');
  },
  set: ({ set }, newCombinations) => {
    if (guardRecoilDefaultValue(newCombinations)) {
      console.info('received default value in updater.');
      return;
    }
    for (let i = 0; i < newCombinations.length; i++) {
      set(portfolioManagementCombinationState(i), newCombinations[i]);
    }
  },
});

export const portfolioFarthestExpirationState = selector<Date | undefined>({
  key: 'portfolioFarthestExpirationStateKey',
  get: ({ get }) => {
    const allCombinations = get(portfolioAllCombinationsState);
    return TradingStrategies.farthestExpiration(allCombinations);
  },
  set: ({ get, set }, expiry) => {
    if (guardRecoilDefaultValue(expiry) || !expiry) {
      return;
    }
    const tradingRangeSimulator = get(tradingRangeSimulatorAtom);
    if (!tradingRangeSimulator) {
      return;
    }
    const howData = get(howDataState);
    if (!howData) {
      return;
    }
    const clone = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    clone.turnToNarrow(howData.stdDev, expiry);
    const sentiment = howData.originalSentiment;
    const whatIfSimulator = WhatIfSimulator.fromData(howData, sentiment, clone);
    set(whatIfSimulatorState, whatIfSimulator);
    set(tradingRangeSimulatorAtom, clone);
  },
});

/* Helper functions */
export const fetchPortfolioManagementPositions = async (symbol: string, combination: PortfolioCombination) => {
  if (symbol.trim() === '') {
    throw new Error('Symbol is required');
  }
  symbol = symbol.trim().toUpperCase();
  const howResponse = await getHow(symbol);
  if (howResponse.hasErrors || !howResponse.data) {
    return undefined;
  }
  const howDataModel = new HowDataModel(howResponse.data, symbol);
  const portfolioManagementPositions = PortfolioPositionsManagement.fromData(howDataModel, combination);
  return portfolioManagementPositions;
};
