import { IForgotPasswordResponseProps } from './';

export class ForgotPasswordResponse implements IForgotPasswordResponseProps {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: ForgotPasswordResponse): ForgotPasswordResponse => {
    const model = new ForgotPasswordResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
