import { Globalization, IConfiguration, IGlobalization, isTmx, LanguageType } from '@op/shared/src/models';
import { MarketRegion } from '@op/shared/src/models/enums/enums';
import { globalizationState } from '@op/shared/src/states';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

export const localeIds = [
  {
    id: 1033,
    type: LanguageType.English,
  },
  {
    id: 2052,
    type: LanguageType.Chinese,
  },
  {
    id: 1053,
    type: LanguageType.Swedish,
  },
  {
    id: 3084,
    type: LanguageType.FrenchCanadian,
  },
];

export const useGlobalization = () => {
  const { i18n } = React.useContext(LocalizationContext);
  const setGlobalization = useSetRecoilState(globalizationState);
  const localLanguage = window.localStorage.getItem('i18nextLng');

  const setGlobalizationLanguage = (res: IGlobalization[], lang: LanguageType) => {
    const globalization = Globalization.fromData(res, lang);
    setGlobalization(globalization);
    setLanguage(globalization, lang);
  };

  const setLanguage = (globalization: Globalization, type: LanguageType) => {
    const selectedValue = globalization.items.find((i) => i.name === type);
    globalization.active = selectedValue;
    if (selectedValue) {
      i18n.changeLanguage(selectedValue.name);
    }
  };

  const setLangugaeByLocaleId = (res: IGlobalization[], id: number) => {
    const getLanguageById = localeIds.find((i) => i.id === id);
    const selectedLanguage = getLanguageById?.type || LanguageType.English;
    setGlobalizationLanguage(res, selectedLanguage);
  };

  const setGlobalizationItems = (res: ResponseViewModel<Globalization>, config: IConfiguration, fromLogin: boolean) => {
    /**
     * on load from the login it should take config localeid
     * on refresh need to take from the localstorage
     */
    if (fromLogin) {
      if (config && config.userSettings) {
        setLangugaeByLocaleId(res.data as unknown as IGlobalization[], config.userSettings?.localeId);
        return;
      }
      setGlobalizationLanguage(res.data as unknown as IGlobalization[], localLanguage as LanguageType);
      return;
    }
    if (!localLanguage) {
      const lang = config.marketRegion;
      switch (lang) {
        case MarketRegion.Nordic:
          setGlobalizationLanguage(res.data as unknown as IGlobalization[], LanguageType.Swedish);
          break;
        // Local Changes
        case MarketRegion.TMX:
        default:
          setGlobalizationLanguage(res.data as unknown as IGlobalization[], LanguageType.English);
          break;
      }
    }
    setGlobalizationLanguage(res.data as unknown as IGlobalization[], localLanguage as LanguageType);
  };

  return setGlobalizationItems;
};
