export class PowerhouseProAdditionalPositionDataModel {
  symbol: string | undefined;
  accountId: number | undefined;
  portfolioCombinationHash: string | undefined;
  probabilityOfProfit: number | undefined;
  isSuccess: boolean | undefined; //This property in api response, not used in reactjs

  static fromData = (data: any) => {
    const model = new PowerhouseProAdditionalPositionDataModel();
    model.symbol = data.symbol;
    model.accountId = data.accountId;
    model.portfolioCombinationHash = data.portfolioCombinationHash;
    model.probabilityOfProfit = data.probabilityOfProfit;
    model.isSuccess = data.isSuccess;
    return model;
  };

  static fromSelf = (self: PowerhouseProAdditionalPositionDataModel) => {
    const clone = new PowerhouseProAdditionalPositionDataModel();
    clone.symbol = self.symbol;
    clone.accountId = self.accountId;
    clone.portfolioCombinationHash = self.portfolioCombinationHash;
    clone.probabilityOfProfit = self.probabilityOfProfit;
    clone.isSuccess = self.isSuccess;
    return clone;
  };
}
