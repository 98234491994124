import { guardRecoilDefaultValue } from '@op/shared/src/states';
import { atomFamily, selector } from 'recoil';
import { IOrder } from '../models';
import { ITsQuotes } from '../models/getTsQuotes';
import { IBidMidAskProps } from '../models/ts-helpers';
import { ITradeStatationPosition } from './../models/getPositions';
import { tsOrdersAtomFamily, tsPositionDataState, tsPositionsAtomFamily } from './trade-station-states';
import { tsBidMidAskValueState } from './ts-ticket-states';

export const tsPositionsUpdaterByPosID = selector<ITradeStatationPosition | undefined>({
  key: 'tsPositionsUpdaterByPosIDKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ get, set, reset }, newPos) => {
    if (!newPos || guardRecoilDefaultValue(newPos)) {
      return;
    }
    const positionCacheData = get(tsPositionDataState);

    // if (!newPos || newPos) { // to check locally the brand new position come from orders
    //   const min = 100000000;
    //   const max = 199999999;
    //   const randomIndex = Math.floor(Math.random() * positionCacheData.length);
    //   const randomItem = { ...positionCacheData[randomIndex] };
    //   const posID = randomItem.positionID.trim().toUpperCase();
    //   const newPosID = posID === '162895250' && (Math.floor(Math.random() * (max - min + 1)) + min).toString();
    //   randomItem.last = Math.floor(Math.random() * (150 - 50 + 1) + 50).toString();
    //   randomItem.unrealizedProfitLoss = Math.floor(Math.random() * (500 - 300 + 1) + 300).toString();
    //   set(tsPositionsAtomFamily(posID), randomItem);
    //   const isOldPosition =
    //     posID === '162895250'
    //       ? positionCacheData.some((p) => p.positionID === newPosID)
    //       : positionCacheData.some((p) => p.positionID === posID);
    //   if (isOldPosition) {
    //     return;
    //   }
    //   reset(tsPositionDataState);
    //   return;
    // }
    // if (newPos && !newPos.positionID) {
    //   // const randomIndex = Math.floor(Math.random() * positionCacheData.length);
    //   // const randomItem = { ...positionCacheData[randomIndex] };
    //   // const posID = randomItem.positionID.trim().toUpperCase();
    //   // randomItem.last = Math.floor(Math.random() * (150 - 50 + 1) + 50).toString();
    //   // randomItem.unrealizedProfitLoss = Math.floor(Math.random() * (500 - 300 + 1) + 300).toString();
    //   // set(tsPositionsAtomFamily(posID), randomItem);
    //   return;
    // }
    const posID = newPos.positionID;
    // if posID is undefined, i.e., newPos = {streamStatus: 'EndSnapshot'}
    if (!posID) {
      return;
    }
    set(tsPositionsAtomFamily(posID), newPos);
    // NOTE: on brand new position (not in main getPositions API)
    const isOldPosition = positionCacheData.some((p) => p.positionID === posID);
    if (isOldPosition) {
      return;
    }
    console.log('New position in positions tab => ', newPos);
    reset(tsPositionDataState); // but this will recall the API, and the skeleton will come till new connection.
  },
});

// /**
//  *  TS Orders Hub
//  */

export const tsOrdersUpdaterByOrderID = selector<IOrder | undefined>({
  key: 'tsOrdersUpdaterByOrderIDKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ get, set }, newOrder) => {
    if (!newOrder || guardRecoilDefaultValue(newOrder)) {
      return;
    }
    if (newOrder && !newOrder.accountID) {
      return;
    }
    const orderId = newOrder.orderID.trim().toUpperCase();
    set(tsOrdersAtomFamily(orderId), newOrder);
  },
});

export const tsQuoteAtomFamily = atomFamily<ITsQuotes | undefined, string>({
  key: 'tsQuoteAtomFamilyKey',
  default: undefined,
});

export const tsQuotesUpdater = selector<ITsQuotes | undefined>({
  key: 'tsQuotesUpdaterKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ set }, quote) => {
    if (!quote || guardRecoilDefaultValue(quote)) {
      //reset(expandedQuotesAtomFamily(symbol));
      return;
    }
    if (!quote || !quote.symbol) {
      return;
    }
    const symbol = quote.symbol.trim().toUpperCase();
    set(tsQuoteAtomFamily(symbol), quote);
  },
});

export const tsOptionQuotesUpdater = selector<IBidMidAskProps | undefined>({
  key: 'tsOptionQuotesUpdaterKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ set }, quote) => {
    if (!quote || guardRecoilDefaultValue(quote)) {
      return;
    }
    set(tsBidMidAskValueState, quote);
  },
});
