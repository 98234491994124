import { howDataState, isDataLoadingState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { HowSkeletonWidget } from './how-skeleton-widget';
import { TradingStrategiesWidget } from '../trade';

export const HowWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const isDataLoading = useRecoilValue(isDataLoadingState);

  if (isDataLoading || !howData) {
    return <HowSkeletonWidget />;
  }

  return <TradingStrategiesWidget />;
};
