import { Grid, Typography } from '@mui/material';
import { Combination, IData, IncomeCombination, isNordic } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import {
  incomeCombinationAtomFamilyState,
  incomeCombinationSelectedIdState,
  selectedOptionGridState,
  tradingRangeSimulatorState,
  whatIfSimulatorState,
} from '@op/shared/src/states';

import React, { ReactNode, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BasicCardWidget, RedGreenWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { StrategyChecklistWidget } from '../single-trade/strategy-checklist-widget';
import {
  OptionsPlayCurrencyFormatInput,
  OptionsPlayDatePickerWidget,
  OptionsPlayTextField,
  ScrollableBox,
} from '../styled';

export const PLTargetSubWidget = ({ label, content }: { label: ReactNode; content: ReactNode }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between" pb={0.5}>
      <Grid item xs={6}>
        {label}
      </Grid>
      <Grid item xs={6}>
        {content}
      </Grid>
    </Grid>
  );
};

export interface IProfitAndLossTargetWidgetProps extends IData<IncomeCombination | Combination> {
  date?: Date;
}

/** There is specific implemenatation for income widget `IncomeProfitAndLossTargetWidget` */
export const ProfitAndLossTargetWidget: React.FC<IProfitAndLossTargetWidgetProps> = ({
  data,
  date,
}: IProfitAndLossTargetWidgetProps) => {
  const currentDate = DateTimeHelper.getCurrentDate();
  const [whatIfSimulator, setWhatIfSimulator] = useRecoilState(whatIfSimulatorState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const { t } = React.useContext(LocalizationContext);
  const [price, setPrice] = useState(0);
  const [priceInputValue, setPriceInputValue] = useState('');
  const [targetDate, setTargetDate] = useState(date || whatIfSimulator?.whatIfDate || currentDate);
  const [maxTargetDate, _setMaxTargetDate] = useState(date || whatIfSimulator?.whatIfDate || currentDate);
  useEffect(() => {
    if (!whatIfSimulator) {
      return;
    }
    const cost = whatIfSimulator.whatIfSPrice;
    setPrice(cost);
    setPriceInputValue(NumberFormatHelper.toFractionalString(cost));
  }, [whatIfSimulator]);
  const combination = data;
  if (!combination) {
    return <>Please select a strategy to show details.</>;
  }

  const onKeyPressOfPrice = (key: string) => {
    if (!tradingRangeSimulator || key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    const max = tradingRangeSimulator.chartHighBound();
    const min = tradingRangeSimulator.chartLowBound();
    let amount = price;
    if (price >= max) {
      amount = max;
    }
    if (price <= min) {
      amount = min;
    }
    const roundPrice = NumberFormatHelper.roundNumber(amount);
    setPrice(roundPrice);
    updatePrice(roundPrice);
  };

  const updatePrice = (whatIfPrice: number): void => {
    if (!whatIfSimulator) {
      return;
    }
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    newWhatIfSimulator.whatIfSPrice = NumberFormatHelper.roundNumber(whatIfPrice);
    setWhatIfSimulator(newWhatIfSimulator);
  };

  const onDateChange = (date: Date | null): void => {
    if (date === null || !whatIfSimulator) {
      return;
    }
    const newWhatIfSimulator = WhatIfSimulator.fromSelf(whatIfSimulator);
    newWhatIfSimulator.whatIfDate = date;
    setTargetDate(date);
    setWhatIfSimulator(newWhatIfSimulator);
  };

  const renderExpectedProfitAndReturnPercentage = () => {
    if (!whatIfSimulator) {
      return null;
    }
    const whatIfTheoretical = whatIfSimulator.whatifTheoretical(combination);
    return (
      <>
        <PLTargetSubWidget
          label={<Typography variant="body1">{t('how.singleTrade.labels.expectedProfit')}</Typography>}
          content={
            <Typography variant="body1">
              <RedGreenWidget value={whatIfTheoretical.payoffValue}>{whatIfTheoretical.payoffFormatted}</RedGreenWidget>
            </Typography>
          }
        />
        <PLTargetSubWidget
          label={
            <Typography variant="body1" sx={{ pr: 1 }}>
              {t('how.singleTrade.labels.expectedReturn')}
            </Typography>
          }
          content={
            <Typography variant="body1">
              <RedGreenWidget value={whatIfTheoretical.payoffValue}>
                {whatIfTheoretical.returnPercentageFormatted}
              </RedGreenWidget>
            </Typography>
          }
        />
      </>
    );
  };

  const renderPLTargetContent = () => {
    return (
      <ScrollableBox maxHeight={'20vh'}>
        <PLTargetSubWidget
          label={<Typography variant="body1">{t('how.singleTrade.labels.targetPrice')}</Typography>}
          content={
            <OptionsPlayTextField
              variant="outlined"
              fullWidth
              value={priceInputValue}
              onChange={(event: any) => {
                setPrice(event.values.floatValue);
                setPriceInputValue(event.values.value);
              }}
              onKeyPress={(event) => onKeyPressOfPrice(event.key)}
              onBlur={() => onKeyPressOfPrice('ENTER')}
              name="targetPrice"
              className="op-formatted-textbox"
              id="target-price"
              InputProps={{
                inputComponent: OptionsPlayCurrencyFormatInput as any,
              }}
            />
          }
        />
        <PLTargetSubWidget
          label={<Typography variant="body1">{t('how.singleTrade.labels.targetDate')}</Typography>}
          content={
            <OptionsPlayDatePickerWidget
              value={targetDate}
              min={currentDate}
              max={maxTargetDate}
              onChangeDate={onDateChange}
              onBlur={(_e) => console.warn('')}
            />
          }
        />
        {renderExpectedProfitAndReturnPercentage()}
      </ScrollableBox>
    );
  };

  const renderPLTarget = () => {
    /**
     * In legacy for nordic configuration.expandFromIncomeStrategies used to hide the PL target in income.
     * As there is no specific condition to show used isNordic() function.
     */
    if (isNordic() && data.isIncome) {
      return;
    }
    return (
      <BasicCardWidget
        title={
          <Typography variant="subtitle1" lineHeight={1.2}>
            {t('how.singleTrade.titles.plTarget')}
          </Typography>
        }
        content={renderPLTargetContent()}
      />
    );
  };

  return (
    <Grid container justifyContent="space-between" columnSpacing={1}>
      <Grid item xs={6}>
        {renderPLTarget()}
      </Grid>
      <Grid item xs={6}>
        <StrategyChecklistWidget data={combination} checkListItems={[]} />
      </Grid>
    </Grid>
  );
};

export const IncomeProfitAndLossTargetWidget: React.FC = () => {
  const incomeCombinationSelectedId = useRecoilValue(incomeCombinationSelectedIdState);
  const combination = useRecoilValue(incomeCombinationAtomFamilyState(incomeCombinationSelectedId.toString()));
  const selectedOption = useRecoilValue(selectedOptionGridState(incomeCombinationSelectedId));
  if (!combination) {
    return null;
  }
  const expiry = selectedOption ? DateTimeHelper.resolveExpiry(selectedOption.expiry) : combination.expiration();
  return (
    <Grid item xs={12} container>
      <ProfitAndLossTargetWidget data={combination} date={expiry} />
    </Grid>
  );
};
