import { Grid, Typography, useTheme } from '@mui/material';
import { IncomeCombination } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import Combination from '@op/shared/src/models/how/combination';
import formatting from '@op/shared/src/models/how/formatting';
import helpers from '@op/shared/src/models/how/helpers';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { expandedQuoteAtomFamily } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { BasicCardWidget, PlainEnglishSentenceOfCombinationWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { useBreakEvenDescription } from '../states/use-breakeven';
import { GuideItem } from '../side-menu';
import { ScrollableBox } from '../styled';

export interface IStrategyGreekWidgetProps {
  data: Combination | IncomeCombination;
}

export const ExplainStrategyGreekWidget: React.FC<IStrategyGreekWidgetProps> = ({
  data,
}: IStrategyGreekWidgetProps) => {
  const breakEvenDesc = useBreakEvenDescription();
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(data.symbol.toUpperCase()));
  const { t } = React.useContext(LocalizationContext);
  const configuration = ApplicationContext.configuration;
  const theme = useTheme();
  if (!data) {
    return <>Please select a strategy to show details.</>;
  }

  const riskProfile = () => {
    let risk = data.maxRisk();
    return helpers.isNumber(risk)
      ? t('how.howPanel.basicCombinationModel.limited')?.toString() || ''
      : t('how.howPanel.basicCombinationModel.unlimited')?.toString() || '';
  };

  //Moved from combination.ts
  const getDeltaDetails = () => {
    if (!data.positions.length) {
      return undefined;
    }

    let deltaDetails: { [x: string]: any } = {};

    let delta = data.delta();
    let theta = data.theta();
    let gamma = data.gamma();
    let vega = data.vega();
    deltaDetails.delta = delta;
    deltaDetails.theta = theta;
    deltaDetails.gamma = gamma;
    deltaDetails.vega = vega;
    deltaDetails.sentiment =
      data.sentiments.length > 0 ? formatting.capitaliseFirstLetter(data.sentiments[0]) : 'Unknown_Sentiment';
    deltaDetails.isBullish = deltaDetails.sentiment === 'Bullish';
    deltaDetails.isBearish = deltaDetails.sentiment === 'Bearish';
    deltaDetails.isNeutral = deltaDetails.sentiment === 'Neutral';
    deltaDetails.isSharpMove = deltaDetails.sentiment === 'Sharp-move';
    deltaDetails.showDollarText = data.showCurrencySymbolForNumbers === false ? '' : 'dollar';

    deltaDetails.riskProfile = formatting.capitaliseFirstLetter(riskProfile()) + '_risk';
    deltaDetails.symbol = data.symbol;
    deltaDetails.stockDirection =
      delta >= 0
        ? t('how.howPanel.deltaDetails.stockDirection.increases')
        : t('how.howPanel.deltaDetails.stockDirection.decreases');
    deltaDetails.deltaAOrAn = t('how.howPanel.deltaDetails.aOrAn.' + formatting.aOrAn(delta));
    deltaDetails.formattedDelta = NumberFormatHelper.toCurrency(delta);
    deltaDetails.dotOrComma = data.matchedTemplate() && data.matchedTemplate()?.template.name === 'Stock';
    deltaDetails.andOrBut =
      theta < 0 ? t('how.howPanel.deltaDetails.andOrBut.but') : t('how.howPanel.deltaDetails.andOrBut.and');
    deltaDetails.lossOrGain =
      theta < 0 ? t('how.howPanel.deltaDetails.lossOrGain.lose') : t('how.howPanel.deltaDetails.lossOrGain.gain');
    deltaDetails.formattedgamma = NumberFormatHelper.toCurrency(gamma);
    deltaDetails.formattedvega = NumberFormatHelper.toCurrency(vega);
    deltaDetails.formattedTheta = NumberFormatHelper.toCurrency(theta);
    deltaDetails.breakevenDesc = breakEvenDesc(data);
    deltaDetails.currencySymbol =
      configuration?.marketRegion.toUpperCase() === 'NO' ? expandedQuote?.currency : 'dollar';
    return deltaDetails;
  };

  const deltaDetails = getDeltaDetails();

  if (!deltaDetails) {
    return null;
  }

  const strategyExplanation = () => {
    return (
      <div id="strategy-explaination">
        <BasicCardWidget
          title={<Typography variant="subtitle1">{t('how.singleTrade.titles.strategyExplanation')}</Typography>}
          content={
            <ScrollableBox maxHeight={data.isIncome ? '170px' : 'max-content'}>
              <GuideItem selector=".plainEnglishGreeksToggleButton_helpPinPlaceholder" />
              <PlainEnglishSentenceOfCombinationWidget combination={data} />
              <PlainEnglishSentenceOfCombinationWidget combination={data} />
            </ScrollableBox>
          }
        />
      </div>
    );
  };

  const greekDeltaDetails = () => {
    if (!deltaDetails) {
      return;
    }
    if (deltaDetails.isBullish || deltaDetails.isBearish) {
      return (
        <Typography variant="body1">
          {t('how.greeksText.forEvery')}
          {deltaDetails.showDollarText}
          {t('how.greeksText.that')} <b>{formatting.symbolDotExchangeToSymbol(deltaDetails.symbol)}</b>{' '}
          <b>{deltaDetails.stockDirection}</b>
          {t('how.greeksText.willResultIn')} {deltaDetails.deltaAOrAn} {t('how.greeksText.svLangText')}
          <b>{NumberFormatHelper.toCurrency(deltaDetails.delta, 'never')}</b> {t('how.greeksText.profit')}
          {deltaDetails.dotOrComma ? '.' : ','}{' '}
          {!deltaDetails.dotOrComma ? (
            <span>
              {deltaDetails.andOrBut}
              {t('how.greeksText.timeDecay')}
              <b>{deltaDetails.lossOrGain}</b> <b>{deltaDetails.formattedTheta}</b>{' '}
              {t('how.greeksText.expirationApproaches')}
            </span>
          ) : null}
        </Typography>
      );
    }
    if (deltaDetails.isNeutral || deltaDetails.isSharpMove) {
      return (
        <Typography variant="body1">
          {t('how.greeksText.strategyProfitIf')}
          <b>{formatting.symbolDotExchangeToSymbol(deltaDetails.symbol)}</b> {t('how.greeksText.trades')}
          <b>{deltaDetails.breakevenDesc}</b>
          {t('how.greeksText.onExpiry')} {deltaDetails.andOrBut} <b>{deltaDetails.lossOrGain}</b>{' '}
          <b>{deltaDetails.formattedTheta}</b> {t('how.greeksText.expirationApproaches')}
        </Typography>
      );
    }
  };

  const renderGreeksContent = () => {
    return (
      <ScrollableBox sx={{ position: 'absolute' }}>
        <Grid container>
          <Grid item xs={12}>
            <GuideItem selector=".greeksExplanation_helpPinPlaceholder" />
            {greekDeltaDetails()}
          </Grid>
          <Grid container marginY={0.2} justifyContent={'space-between'}>
            <Grid
              item
              xs={5.5}
              container
              p={0.8}
              justifyContent={'space-between'}
              sx={{
                backgroundColor: theme.palette.info.dark,
                border: `1.5px solid ${theme.palette.secondary.dark}`,
                borderRadius: 1,
              }}>
              <Typography variant="body1" fontWeight="bold">
                Delta
              </Typography>
              <Typography variant="body1">{deltaDetails.formattedDelta}</Typography>
            </Grid>
            <Grid
              item
              container
              xs={5.5}
              justifyContent={'space-between'}
              p={0.8}
              sx={{
                backgroundColor: theme.palette.info.dark,
                border: `1.5px solid ${theme.palette.secondary.dark}`,
                borderRadius: 1,
              }}>
              <Typography variant="body1" fontWeight="bold">
                Theta
              </Typography>
              <Typography variant="body1">{deltaDetails.formattedTheta}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent={'space-between'} marginY={0.5}>
            <Grid
              item
              container
              xs={5.5}
              p={0.8}
              justifyContent={'space-between'}
              sx={{
                backgroundColor: theme.palette.info.dark,
                border: `1.5px solid ${theme.palette.secondary.dark}`,
                borderRadius: 1,
              }}>
              <Typography variant="body1" fontWeight="bold">
                Gamma
              </Typography>
              <Typography variant="body1">{deltaDetails.formattedgamma} </Typography>
            </Grid>
            <Grid
              item
              container
              justifyContent={'space-between'}
              xs={5.5}
              p={0.8}
              sx={{
                backgroundColor: theme.palette.info.dark,
                border: `1.5px solid ${theme.palette.secondary.dark}`,
                borderRadius: 1,
              }}>
              <Typography variant="body1" fontWeight="bold">
                Vega
              </Typography>
              <Typography variant="body1">{deltaDetails.formattedvega}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </ScrollableBox>
    );
  };

  const greeks = () => {
    return (
      <div id="greek">
        <BasicCardWidget
          title={<Typography variant="subtitle1">{t('how.singleTrade.titles.greeks')}</Typography>}
          content={renderGreeksContent()}
        />
      </div>
    );
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={6}>
        {strategyExplanation()}
      </Grid>
      <Grid item xs={6}>
        {greeks()}
      </Grid>
    </Grid>
  );
};
