import { IChangeEmailResponseProps } from './';

export class ChangeEmailResponse implements IChangeEmailResponseProps {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: ChangeEmailResponse): ChangeEmailResponse => {
    const model = new ChangeEmailResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
