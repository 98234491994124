export interface IRiskAndInvestment {
  riskAndInvestmentAmount: number;
  riskAndInvestmentPercentage: number;
}

export class RiskAndInvestment implements IRiskAndInvestment {
  riskAndInvestmentAmount = 0;
  riskAndInvestmentPercentage = 0;

  static fromSelf = (self: IRiskAndInvestment) => {
    const clone = new RiskAndInvestment();
    clone.riskAndInvestmentAmount = self.riskAndInvestmentAmount;
    clone.riskAndInvestmentPercentage = self.riskAndInvestmentPercentage;
    return clone;
  };
}
