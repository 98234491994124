import {
  AccountAdvisor,
  CombinationTextGeneration,
  ExpandedQuote,
  PortfolioPositionAccount,
  priceIncrementsResolver,
} from '..';
import { CombinationSentiment, LegType, LongOrShort, PriceCalculationMethod } from '../enums/enums';
import { BasicPositionModel } from '../how/basic-position-model';
import { PortfolioPositionEntity } from './position';

export class PortfolioPosition extends BasicPositionModel {
  private _yesterdayMidPrice: number | undefined;
  portfolioPositionAccount: PortfolioPositionAccount | undefined;
  accountAdvisor: AccountAdvisor[] = [];
  yesterdayAsk: number | undefined;
  yesterdayBid: number | undefined;

  private constructor() {
    super();
  }

  // TODO: Clone the reference argument.
  static fromData = (
    positionData: PortfolioPositionEntity,
    underlyingQuote: ExpandedQuote,
    positionQuote: ExpandedQuote,
  ) => {
    const model = new PortfolioPosition();
    model.fromPortfolioPosition(positionData, underlyingQuote, positionQuote);
    model.portfolioPositionAccount = PortfolioPositionAccount.fromData(positionData);
    model.accountAdvisor = positionData.accountAdvisor?.map((a) => AccountAdvisor.fromText(a));
    model.yesterdayAsk = positionData.yesterdayQuote && positionData.yesterdayQuote.ask;
    model.yesterdayBid = positionData.yesterdayQuote && positionData.yesterdayQuote.bid;
    //overrite the property value from base class.
    model.selectedPrice = PriceCalculationMethod.MID;
    return model;
  };

  static fromSelf = (self: PortfolioPosition) => {
    const clone = new PortfolioPosition();
    clone.fromSelf(self);
    clone.portfolioPositionAccount = PortfolioPositionAccount.fromData(self.portfolioPositionAccount);
    clone.yesterdayAsk = self.yesterdayAsk;
    clone.yesterdayBid = self.yesterdayBid;
    clone.selectedPrice = self.selectedPrice;
    return clone;
  };

  get yesterdayMidPrice() {
    if (this._yesterdayMidPrice) {
      return this._yesterdayMidPrice;
    }
    if (!this.yesterdayAsk || !this.yesterdayBid) {
      return undefined;
    }
    let midPrice = (this.yesterdayAsk + this.yesterdayBid) / 2;
    if (this.type.toUpperCase().trim() !== LegType.SECURITY.toString().toUpperCase()) {
      midPrice = priceIncrementsResolver.getPriceAdjusted(this.symbol, midPrice);
    }
    return midPrice;
  }

  longOrShort = () => {
    const result = this.quantity >= 0 ? LongOrShort.LONG : LongOrShort.SHORT;
    return result;
  };

  positionIconCss = () => {
    let type = this.type;

    if (type === LegType.SECURITY) {
      type = 'Stock';
    }
    return 'stg-' + (this.longOrShort() + type);
  };

  sentiment = () => {
    const type = this.type;

    let result = CombinationSentiment.NEUTRAL;
    switch (type) {
      case LegType.SECURITY:
      case LegType.CALL:
        result = CombinationSentiment.BULLISH;
        break;
      case LegType.PUT:
        result = CombinationSentiment.BEARISH;
        break;
    }
    const qty = this.quantity;
    if (qty < 0) {
      result = result === CombinationSentiment.BULLISH ? CombinationSentiment.BEARISH : CombinationSentiment.BULLISH;
    }
    return result;
  };

  positionName = (): string => {
    return CombinationTextGeneration.getPortfolioPositionName(this);
  };

  yesterdayAskBidPrice = () => {
    return this.isBuyPosition ? this.yesterdayAsk : this.yesterdayBid;
  };

  yesterdayPrice = () => {
    const result =
      this.selectedPrice === PriceCalculationMethod.MID ? this.yesterdayMidPrice : this.yesterdayAskBidPrice();
    return result;
  };

  yesterdayMark = () => {
    return this.yesterdayPrice();
  };

  yesterdayTotalMarketValue = () => {
    const yesterdayMark = this.yesterdayMark();
    if (yesterdayMark == null) {
      return 1;
    }
    return yesterdayMark * this.premiumMultiplier() * this.absQuantity;
  };

  todayProfitAndLoss = () => {
    const yesterdayTotalMarketValue = this.yesterdayTotalMarketValue();
    if (yesterdayTotalMarketValue == null) {
      return null;
    }
    const todayProfitAndLoss = (this.totalMarketValue() - yesterdayTotalMarketValue) * Math.sign(this.quantity);
    return todayProfitAndLoss;
  };

  todayProfitAndLossPercentage = () => {
    const todayProfitAndLoss = this.todayProfitAndLoss();
    if (todayProfitAndLoss == null) {
      return null;
    }
    if (this.yesterdayTotalMarketValue() === null) {
      throw new Error('yesterdayTotalMarketValue is null');
    }
    const todayProfitAndLossPercentage = (todayProfitAndLoss / this.yesterdayTotalMarketValue()) * 100;
    return todayProfitAndLossPercentage;
  };
}
