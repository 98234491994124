import { Grid } from '@mui/material';
import { accountState, isDataLoadingState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { QuoteBarWidget } from '../quoteBar/quote-bar-widget';
import { HeightWidget, PageContainerWidget } from '../routes';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { ChartWidget } from './chart-widget';

export const GraphPageWidget: React.FC = () => {
  const fetchAndSetData = useFetchAndSetData();
  const account = useRecoilValue(accountState);
  const graphPageRoute = useMatch('/graph/:symbol');
  const setIsDataLoading = useSetRecoilState(isDataLoadingState);

  useEffect(() => {
    let symbol = graphPageRoute?.params['symbol'];
    if (!account || !symbol || symbol.trim() === '') {
      return;
    }
    setIsDataLoading(true);
    (async () => {
      await fetchAndSetData(symbol);
      setIsDataLoading(false);
    })();
  }, [account]);

  return (
    <PageContainerWidget>
      <HeightWidget noScroll>
        <Grid container rowSpacing={1}>
          <Grid item xs={6}>
            <QuoteBarWidget />
          </Grid>
          <Grid item xs={12}>
            <ChartWidget />
          </Grid>
        </Grid>
      </HeightWidget>
    </PageContainerWidget>
  );
};
