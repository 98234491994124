import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Grid, useTheme } from '@mui/material';
import { ISubscriptionPlanPrice, cloneDeep, groupBy } from '@op/shared/src/models';
import { EventType, UserSubscriptionPeriod } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  accountState,
  selectedPlansState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
  subscriptionPlanPriceState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy } from '../styled';
import { StyledCheckBox } from '../tradestation/ts-styles-component';
import { FeatureList } from './feature-list';
import { SubscriptionHeaderTabWidget } from './subscription-header-tab-widget';
import { getSubscriptionHeaderTitle } from './subscription-helper';

type paymentPlansDetails = {
  OneTime: ISubscriptionPlanPrice;
  Monthly: ISubscriptionPlanPrice;
  Annual: ISubscriptionPlanPrice;
};

export const AddOn: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const selectedPlans = useRecoilValue(selectedPlansState);
  const [subscriptionCurrentScreen, setSubscriptionCurrentScreen] = useRecoilState(subscriptionCurrentScreenState);
  const userCachedSubscription = useRecoilValue(userSubscriptionFeaturesState);
  const [subscriptionPlanPrices, setSubscriptionPlanPrices] = useRecoilState(subscriptionPlanPriceState);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const [showHeader, setShowHeader] = useState(true);
  const [subscriptionAddOns, setSubscriptionAddOns] = useState<ISubscriptionPlanPrice[]>();
  const [expanded, setExpanded] = useState(false);
  const account = useRecoilValue(accountState);
  const { deleteRequested } = account.securityModel || {};
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  useEffect(() => {
    if (subscriptionCurrentScreen === 'ChangeSubscription') {
      setShowHeader(true);
      return;
    }
    setShowHeader(false);
  }, [subscriptionCurrentScreen]);

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const getFilteredAddOns = (showHeader: boolean) => {
    return subscriptionPlanPrices.filter((addOn) => {
      if (addOn.featureType === 'Bundle') {
        return false;
      }
      if (showHeader) {
        return addOn.featureType === 'AddOn';
      }
      const userCurrentPlan = userCachedSubscription.features.find((f) => f.title === UserSubscriptionPeriod.CURRENT);
      const isPurchased = userCurrentPlan.subscriptionList.some((c) => c.featureKey === addOn.featureKey);
      return addOn.featureType === 'AddOn' && !isPurchased;
    });
  };

  useEffect(() => {
    if (!subscriptionPlanPrices) {
      return;
    }
    setSubscriptionAddOns(getFilteredAddOns(showHeader));
  }, [subscriptionPlanPrices, showHeader]);

  const handleChange = (value: boolean, featureKey: string, type: string) => {
    logActivity('click', 'paymentsAddOnButton');
    let clone = cloneDeep(subscriptionPlanPrices);
    const updatedPlans = clone.map((plan) => {
      if (plan.featureType === 'AddOn' && plan.featureKey === featureKey) {
        return {
          ...plan,
          isSelected: plan.planType === type ? value : false,
        };
      }
      return plan;
    });
    setSubscriptionPlanPrices(updatedPlans);
  };

  const handleAddOnSubscribe = () => {
    logActivity('BUTTON', 'paymentsAddSubscriptionButton');
    setSubscriptionCurrentScreen('ChangeSubscription');
    setActiveStep('cardInfo');
  };

  const getSelectedPrice = (plans: ISubscriptionPlanPrice[]) => {
    const selectedPlan = plans.find((plan) => plan.isSelected);
    return selectedPlan ? NumberFormatHelper.toCurrency(selectedPlan.discountedPrice) : '$ 000';
  };

  const renderAddOnTile = (plan: ISubscriptionPlanPrice) => {
    const { isActivePlan, isUpcoming, isSelected, featureKey, planType, discountedPrice } = plan;
    return (
      <Box display="flex" alignItems="center" marginRight={2}>
        <Checkbox
          key={`${featureKey}-${planType}`}
          disabled={isActivePlan || isUpcoming}
          checked={isActivePlan || isUpcoming || isSelected}
          icon={<StyledCheckBox key={`checkbox-${featureKey}-${planType}`} />}
          onChange={(e, checked) => handleChange(checked, featureKey, planType)}
          checkedIcon={
            <StyledCheckBox
              sx={{
                backgroundColor: isActivePlan || isUpcoming ? theme.palette.grey[500] : theme.palette.primary.main,
              }}
            />
          }
        />
        {isActivePlan || isUpcoming ? (
          <OPBoldTypograpghy variant="body1">
            {planType} ${discountedPrice}
          </OPBoldTypograpghy>
        ) : (
          <OPLightTypograpghy variant="body1">
            {planType} ${discountedPrice}
          </OPLightTypograpghy>
        )}
      </Box>
    );
  };

  const renderAddOnRow = (plans: ISubscriptionPlanPrice[]) => {
    if (plans.length === 0) return;

    const addOnPlans: paymentPlansDetails = {
      OneTime: plans.find((item) => item.planType === 'OneTime'),
      Monthly: plans.find((item) => item.planType === 'Monthly'),
      Annual: plans.find((item) => item.planType === 'Annual'),
    };

    const activeAddOn = plans.find((a) => a.isActivePlan);
    const upcomingAddOn = plans.find((a) => a.isUpcoming);
    const isAddOnSelected = plans.some((a) => a.isSelected);
    const { featureKey, benefitsList } = plans[0];

    return (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          columnSpacing={2}
          sx={{
            boxShadow: 1,
            backgroundColor: theme.palette.primary.light,
            borderRadius: 1,
            my: 2,
            border:
              activeAddOn || upcomingAddOn || isAddOnSelected
                ? `2px solid ${theme.palette.primary.main}`
                : `2px solid transparent`,
            ':hover': { border: `2px solid ${theme.palette.primary.main}` },
          }}>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: theme.palette.selectAndTextField.light,
              boxShadow: 2,
              paddingY: 0.5,
              borderRadius: 1,
            }}>
            <Grid item xs={10}>
              {activeAddOn || upcomingAddOn ? (
                <OPBoldTypograpghy variant="body1">{featureKey}</OPBoldTypograpghy>
              ) : (
                <OPLightTypograpghy variant="body1">{featureKey}</OPLightTypograpghy>
              )}
            </Grid>
            <Grid item xs={2} textAlign={'center'}>
              <FeatureList benefitList={benefitsList} isAddOn />
            </Grid>

            {/* <Grid item xs={3}>
              <OPLightTypograpghy variant="body1">{getSelectedPrice(plans)}</OPLightTypograpghy>
            </Grid> */}
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={12}>
              {addOnPlans.OneTime && renderAddOnTile(addOnPlans.OneTime)}
            </Grid>
            <Grid item xs={6}>
              {addOnPlans.Monthly && renderAddOnTile(addOnPlans.Monthly)}
            </Grid>
            <Grid item xs={6}>
              {addOnPlans.Annual && renderAddOnTile(addOnPlans.Annual)}
            </Grid>
          </Grid>
        </Grid>
        {activeAddOn && (
          <OPBoldTypograpghy sx={{ color: theme.palette.primary.main, ml: -1.5, mt: -1 }}>
            {t('subscriptions.yourCurrentActivePlan')} - {activeAddOn.planType}
          </OPBoldTypograpghy>
        )}
        {upcomingAddOn && (
          <OPBoldTypograpghy sx={{ color: theme.palette.primary.main, ml: -1.5, mt: -1 }}>
            {t('subscriptions.yourUpcomingPlan')} - {upcomingAddOn.planType}
          </OPBoldTypograpghy>
        )}
      </>
    );
  };

  if (!subscriptionAddOns || subscriptionAddOns.length === 0) {
    if (!showHeader) {
      return;
    }
    return (
      <Grid container justifyContent="center" alignItems={'center'} p={2}>
        <Grid item xs={6} container direction={'column'} justifyContent={'center'} alignItems={'flex-end'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy variant="h5" sx={{ color: theme.palette.primary.main }}>
              {t('subscriptions.ifYouAreWaitingFor')}
            </OPBoldTypograpghy>
            <OPBoldTypograpghy variant="h4" sx={{ color: theme.palette.primary.main }}>
              {t('subscriptions.addOns')}
            </OPBoldTypograpghy>
            <OPBoldTypograpghy variant="h5" sx={{ color: theme.palette.primary.main }}>
              {t('subscriptions.toBeAvailableSoon')}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <IconComponent name="noAddOn" />
        </Grid>
        {/*  */}
      </Grid>
    );
  }

  const renderAddOnList = () => {
    const groupedData = groupBy(subscriptionAddOns, 'featureKey');
    return Object.keys(groupedData).map((featureKey) => renderAddOnRow(groupedData[featureKey]));
  };

  const disableNowButton = () => {
    if (!subscriptionAddOns || subscriptionAddOns.length === 0) {
      return true;
    }
    const isAddOnSelected = subscriptionAddOns.some((s) => s.isSelected);
    return !isAddOnSelected;
  };

  const renderAddonAccordian = () => {
    return (
      <Grid item container xs={12}>
        <Accordion
          sx={{ width: '100%' }}
          disableGutters
          disabled={deleteRequested}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              paddingY: 0,
              borderRadius: 1,
            }}>
            <OPBoldTypograpghy variant="subtitle1" sx={{ color: theme.palette.timeFrame.contrastText }}>
              {t('subscriptions.availableAddOnsToSusbcribe')}
            </OPBoldTypograpghy>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: theme.palette.greyShades.light,
            }}>
            <Grid
              item
              container
              xs={12}
              rowSpacing={1}
              mt={1}
              p={1}
              sx={{
                backgroundColor: theme.palette.greyShades.light,
                borderRadius: 2,
                padding: showHeader ? 4 : null,
              }}>
              {renderAddOnList()}
            </Grid>
            <Grid container item xs={12} mt={1} justifyContent={'flex-end'}>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.success.main,
                    ':hover': { backgroundColor: theme.palette.success.main },
                  }}
                  disabled={disableNowButton()}
                  onClick={handleAddOnSubscribe}>
                  <OPBoldTypograpghy variant="button">{t('subscriptions.subscribeNow')}</OPBoldTypograpghy>
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  const renderContent = () => {
    if (showHeader) {
      return (
        <Grid container justifyContent={'center'} alignItems={'center'} p={2}>
          <Grid item xs={12}>
            <SubscriptionHeaderTabWidget title={getSubscriptionHeaderTitle(selectedPlans, 'addOn', t)} />
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            mt={1}
            sx={{
              backgroundColor: theme.palette.greyShades.light,
              borderRadius: 2,
              padding: 4,
            }}>
            <Grid item rowSpacing={1} container xs={9}>
              {renderAddOnList()}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container justifyContent="center">
        {renderAddonAccordian()}
      </Grid>
    );
  };

  return <>{renderContent()}</>;
};
