import { atom, selector } from 'recoil';
import { SubscriptionStatus } from '../../src/models/enums/enums';
import {
  ISubscriptionPlanPricesModel,
  ICurrentSubscription,
  ISubscriptionPlanPrice,
  IUserSubscriptionFeatures,
  SubscriptionPaymentForms,
  cloneDeep,
} from '../models';
import { guardRecoilDefaultValue } from './trade';

//TODO: this state is a scape-hatch to handle "back to the App" button. Should be remove later.
export const isSubscriptionRenewedState = atom({
  key: 'isSubscriptionRenewedStateKey',
  default: false,
});

export const SubscriptionPaymentFormsState = atom<SubscriptionPaymentForms>({
  key: 'SubscriptionPaymentFormsState',
  default: new SubscriptionPaymentForms(),
});

export const currentSubscriptionState = atom<ICurrentSubscription | undefined>({
  key: 'currentSubscriptionStatekey',
  default: undefined,
});

export const CardPaymentTypeState = atom<string>({
  key: 'CardPaymentTypeState',
  default: '',
});

export const currentSubscriptionScreenState = atom<
  'currentsubscription' | 'cancelsubscription' | 'SubscriptionWidget' | 'Operationcompleted' | 'changeInformation'
>({
  key: 'currentSubscriptionScreenStatekey',
  default: 'currentsubscription',
});
