import { Grid } from '@mui/material';
import { PowerhouseProAdditionalPositionDataModel } from '@op/shared/src/hubs/powerhouse-pro-additional-position-data-model';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { IData } from '@op/shared/src/models/what/data-props';
import { isHubInitiatedState, selectedSymbolState } from '@op/shared/src/states';
import {
  portfolioSubscribeToAdditionalData,
  portfolioUnsubscribeToAdditionalData,
  portolioPositionDataAtomFamilyState,
} from '@op/shared/src/states/portfolio-hub-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy } from '../styled';
import { useInView } from 'react-intersection-observer';

export interface PortfolioPopWidgetProps extends IData<string> {
  accountId: number | string;
  portfolioCombinationHash: string;
}

export const PortfolioPopWidget: React.FC<PortfolioPopWidgetProps> = ({
  data,
  accountId,
  portfolioCombinationHash,
}: PortfolioPopWidgetProps) => {
  const symbol = data.toUpperCase();
  const key = `${portfolioCombinationHash}_${symbol}`;
  const isHubInitiated = useRecoilValue(isHubInitiatedState);
  const additionalPositionData = useRecoilValue<PowerhouseProAdditionalPositionDataModel | undefined>(
    portolioPositionDataAtomFamilyState(key),
  );
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [delay, setDelay] = useState(2000);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ref, inView, entry] = useInView({
    threshold: 1,
    delay: delay,
  });
  const { t } = React.useContext(LocalizationContext);

  React.useEffect(() => {
    if (!isHubInitiated || !symbol || !inView) {
      return;
    }
    (async () => {
      // setDelay(5000);
      await portfolioSubscribeToAdditionalData(symbol, accountId, portfolioCombinationHash);
    })();
  }, [isHubInitiated, inView]);

  useEffect(() => {
    if (inView || !symbol) {
      return;
    }
    //If symbol is selected and loaded in other panels like quotebar, how panel etc. we should not unsubscribe the quotes.
    if (selectedSymbol?.toLowerCase() === symbol.toLowerCase()) {
      return;
    }
    (async () => {
      await portfolioUnsubscribeToAdditionalData(symbol, accountId, portfolioCombinationHash);
    })();
  }, [inView, symbol]);

  const getPop = () => {
    if (!additionalPositionData || !additionalPositionData.probabilityOfProfit) {
      return '0.00%';
    }
    return NumberFormatHelper.toPercentage(additionalPositionData.probabilityOfProfit * 100);
  };

  return (
    <Grid ref={ref} container className="js-porfolio-accounts-pop" justifyContent={'flex-start'}>
      <OPLightTypograpghy sx={{ pr: 1 }}>{t('portfolio.pop')}</OPLightTypograpghy>
      <OPBoldTypograpghy>{getPop()}</OPBoldTypograpghy>
    </Grid>
  );
};
