import { PhpHubEvents } from '../models/enums/enums';
import { HubSubscription } from './hub-subscription';
import { PowerhouseProAdditionalPositionDataModel } from './powerhouse-pro-additional-position-data-model';
import { PowerhouseProHubConnection } from './powerhouse-pro-hub-connection';

export class PortfolioSubscription extends HubSubscription<PowerhouseProAdditionalPositionDataModel> {
  private constructor() {
    super();
  }

  static fromSocketConnection = (socketConnection: PowerhouseProHubConnection) => {
    const subscription = new PortfolioSubscription();
    subscription.fromSocketConnection(
      socketConnection,
      'subscribeToAdditionalPositionData',
      'unsubscribeFromAdditionalPositionData',
      undefined,
      PhpHubEvents.POSITION_DATA,
    );
    return subscription;
  };

  mapItemsFromServer = (items: any[]) => {
    if (!items) {
      return [];
    }
    const sentiments = items.map((i) => PowerhouseProAdditionalPositionDataModel.fromData(i));
    return sentiments;
  };
}
