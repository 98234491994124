import { ICore } from './core';

export interface IPut extends ICall {}

export interface ICall extends ICore {
  timeFrame: string;
  shortTimeFrameLowerBound: number;
  shortTimeFrameHigherBound: number;
  mediumTimeFrameLowerBound: number;
  mediumTimeFrameHigherBound: number;
  longTimeFrameLowerBound: number;
  longTimeFrameHigherBound: number;
  aggressiveness: string;
  coservativeAggressivenessLowerBound: number;
  coservativeAggressivenessHigherBound: number;
  optimalAggressivenessLowerBound: number;
  optimalAggressivenessHigherBound: number;
  aggressiveAggressivenessLowerBound: number;
  aggressiveAggressivenessHigherBound: number;
}

export class Call implements ICall {
  defaultMinimumPremium!: number;
  defaultMinimumReturn!: number;
  label!: string;
  timeFrame!: string;
  shortTimeFrameLowerBound!: number;
  shortTimeFrameHigherBound!: number;
  mediumTimeFrameLowerBound!: number;
  mediumTimeFrameHigherBound!: number;
  longTimeFrameLowerBound!: number;
  longTimeFrameHigherBound!: number;
  aggressiveness!: string;
  coservativeAggressivenessLowerBound!: number;
  coservativeAggressivenessHigherBound!: number;
  optimalAggressivenessLowerBound!: number;
  optimalAggressivenessHigherBound!: number;
  aggressiveAggressivenessLowerBound!: number;
  aggressiveAggressivenessHigherBound!: number;

  static fromSelf = (self: ICall) => {
    const clone = new Call();

    clone.defaultMinimumPremium = self.defaultMinimumPremium;
    clone.defaultMinimumReturn = self.defaultMinimumReturn;
    clone.label = self.label;
    clone.timeFrame = self.timeFrame;
    clone.shortTimeFrameLowerBound = self.shortTimeFrameLowerBound;
    clone.shortTimeFrameHigherBound = self.shortTimeFrameHigherBound;
    clone.mediumTimeFrameLowerBound = self.mediumTimeFrameLowerBound;
    clone.mediumTimeFrameHigherBound = self.mediumTimeFrameHigherBound;
    clone.longTimeFrameLowerBound = self.longTimeFrameLowerBound;
    clone.longTimeFrameHigherBound = self.longTimeFrameHigherBound;
    clone.aggressiveness = self.aggressiveness;
    clone.coservativeAggressivenessLowerBound = self.coservativeAggressivenessLowerBound;
    clone.coservativeAggressivenessHigherBound = self.coservativeAggressivenessHigherBound;
    clone.optimalAggressivenessLowerBound = self.optimalAggressivenessLowerBound;
    clone.optimalAggressivenessHigherBound = self.optimalAggressivenessHigherBound;
    clone.aggressiveAggressivenessLowerBound = self.aggressiveAggressivenessLowerBound;
    clone.aggressiveAggressivenessHigherBound = self.aggressiveAggressivenessHigherBound;
    return clone;
  };
}
