import { atom, selector } from 'recoil';
import {
  Filters,
  ICallOptimal,
  IPortfolioAccount,
  IPutOptimal,
  PortfolioCombination,
  PortfolioCombinationAccount,
  PortfolioCombinationAccounts,
  PortfolioCombinationEntities,
  PortfolioCombinationEntity,
  PortfolioManagementCombination,
  PortfoliouploadData,
} from '../models';
import { PortfolioPositionsService, PowerhouseProService } from '../services';
import {
  alertsByAccountsState,
  notificationByAccountsState,
  selectedAlertFilterState,
  selectedNotificationFilterState,
} from './portfolio-alerts-hub-states';

import Fuse from 'fuse.js';
import NumberFormatHelper from '../models/how/number-format-helper';
import { IPortfolioSlectedSummary } from '../models/portfolio/alert-filter-model';
import { FuseConfig } from './configuration/fuse-search-config';
import { sortsPortfolioState } from './sorting-state';
import { guardRecoilDefaultValue } from './trade';

export const portfolioSearchState = atom({
  key: 'portfolioSearchStateKey',
  default: '',
});

export const filtersPortfolioState = atom({
  key: 'filtersPortfolioStateKey',
  default: Filters.portfolioDefaultFilters,
});

export const portfoliosDataState = atom<PortfolioCombinationAccounts | undefined>({
  key: 'portfoliosDataStateKey',
  default: undefined,
  // dangerouslyAllowMutability: true,
});

export const portfolioSelectedAccountIdsState = atom<number[] | undefined>({
  key: 'portfolioSelectedAccountIdsStateKey',
  default: undefined,
});

const portfolioSelectedCombinationAtomState = atom<PortfolioCombination | undefined>({
  key: 'portfolioSelectedCombinationAtomStateKey',
  default: undefined,
});

/*
 * On fast changing of accounts selection, portfolio combination needs time to load proper data.
 */
export const isPortfolioCombinationsLoadingState = atom({
  key: 'isPortfolioCombinationsLoadingStateKey',
  default: false,
});

export const portfolioSelectedEntityState = atom<PortfolioCombinationEntity | undefined>({
  key: 'isPortfolioSelectedEntityStateKey',
  default: undefined,
});

export const rollOptimalState = atom<ICallOptimal | IPutOptimal | undefined>({
  key: 'rollOptimalStateStateKey',
  default: undefined,
});

export const isSelectedRecommendedStateOpenState = atom<boolean>({
  key: 'isSelectedRecommendedStateOpenStateKey',
  default: false,
});

export const portfolioSelectedCombinationState = selector<PortfolioCombination | undefined>({
  key: 'portfolioSelectedCombinationStateKey',
  get: ({ get }) => {
    return get(portfolioSelectedCombinationAtomState);
  },
  set: ({ set }, newCombination) => {
    if (guardRecoilDefaultValue(newCombination) || !newCombination) {
      return;
    }
    const clone = PortfolioCombination.fromSelf(newCombination);
    set(portfolioSelectedCombinationAtomState, clone);
  },
});

export const portfolioManagementSelectedCombinationState = atom<PortfolioManagementCombination | undefined>({
  key: 'portfolioManagementSelectedCombinationStateKey',
  default: undefined,
});

export const portfolioSelectedAccountIdsSelectorState = selector<number[] | undefined>({
  key: 'portfolioSelectedAccountIdsSelectorStateKey',
  get: ({ get }) => {
    const portfolioAccounts = get(portfolioAccountsWithoutCombinationsState);
    if (!portfolioAccounts || portfolioAccounts.length === 0) {
      return;
    }
    const ids = portfolioAccounts.filter((p) => !p.isGlobal).map((i) => i.portfolioId);
    return ids;
  },
  set: ({ set }, ids) => {
    if (guardRecoilDefaultValue(ids) || !ids) {
      return;
    }
    set(portfolioSelectedAccountIdsState, ids);
  },
});

export const portfolioAccountsDataState = selector<PortfolioCombinationAccount[] | undefined>({
  key: 'portfolioAccountsDataStateKey',
  get: ({ get }) => {
    const accountsData = get(portfoliosDataState);
    if (!accountsData || accountsData.data.length === 0) {
      return undefined;
    }
    const selectedAccountIds = get(portfolioSelectedAccountIdsState);
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return undefined;
    }
    const accounts: PortfolioCombinationAccount[] | undefined = [];
    for (let id of selectedAccountIds) {
      const account = accountsData.data.find((a) => a.portfolioId === id);
      if (!account) {
        continue;
      }
      accounts.push(account);
    }
    return accounts;
  },
});

export const portfolioCombinationsOfSelectedAccountsState = selector<PortfolioCombinationEntities | undefined>({
  key: 'portfolioCombinationsOfSelectedAccountsStateKey',
  get: ({ get }) => {
    const query = get(portfolioSearchState);
    const sorts = get(sortsPortfolioState);
    const filters = get(filtersPortfolioState);
    const accounts = get(portfolioAccountsDataState);
    if (!accounts || accounts.length === 0) {
      return undefined;
    }
    let entitiesAccounts = new PortfolioCombinationEntities();
    for (let account of accounts) {
      entitiesAccounts.combinations = entitiesAccounts.combinations.concat(account.portfolioCombinations);
    }
    const cloned = PortfolioCombinationEntities.fromSelf(entitiesAccounts);
    let combinations = cloned.filter(filters).combinations;
    const fuse = new Fuse(combinations, {
      ...FuseConfig,
      keys: ['symbol'],
    });
    const items = query.trim() !== '' ? fuse.search(query).map((i) => i.item) : combinations;
    cloned.combinations = items;
    let finalAccounts = cloned;
    if (query.trim() === '') {
      finalAccounts = cloned.sort(sorts);
    }
    return finalAccounts;
  },
});

export const portfolioAccountsWithoutCombinationsState = selector({
  key: 'portfolioAccountsWithoutCombinationsStateKey',
  get: ({ get }) => {
    const accounts = get(portfoliosDataState);
    if (!accounts) {
      return undefined;
    }
    const model = accounts.data.map((a) => {
      return {
        portfolioId: a.portfolioId,
        portfolioName: a.portfolioName,
        isGlobal: a.isGlobal,
        totalCombinations: a.portfolioCombinations.length,
        portfolioTypeStatus: a.portfolioTypeStatus,
        portfolioAccountName: a.portfolioAccountName,
      };
    });
    /**
     * As per the requirement portfolioName should be in ascending order
     */
    model.sort((a, b) => (a.portfolioName.toLowerCase() > b.portfolioName.toLowerCase() ? 1 : -1));
    return model;
  },
});

export const portfolioSaveTradeAccountsState = atom<PortfoliouploadData[] | undefined>({
  key: 'porfolioSaveTradeAccountsStateKey',
  default: undefined,
});

export const fetchPorfolioCombinationAccounts = async () => {
  const response = await PowerhouseProService.instance.getPortfolio();
  if (response.hasErrors || !response.data) {
    return undefined;
  }
  return response.data;
};

export const portfolioBrokerAccountsState = atom<IPortfolioAccount[] | undefined>({
  key: 'portfolioBrokerAccountsStateKey',
  default: undefined,
});

export const fetchPorfolioBrokerccounts = async (refresh?: boolean) => {
  const accResponse = await PortfolioPositionsService.instance.getBrokerAccounts(refresh || false);
  if (accResponse.hasErrors || !accResponse.data) {
    return undefined;
  }
  return accResponse.data;
};

export const portfolioSelectedFilterSummaryState = selector({
  key: 'portfolioSelectedFilterSummaryStateKey',
  get: ({ get }) => {
    const portfolioAccounts = get(portfolioAccountsDataState);
    // const selectedAccounts = get(portfolioSelectedAccountIdsState);
    const sortsPortfolio = get(sortsPortfolioState);

    const alertsByAccounts = get(alertsByAccountsState);
    const selectedalerts = get(selectedAlertFilterState);
    const notitifcationByAccounts = get(notificationByAccountsState);
    const selectednotifcations = get(selectedNotificationFilterState);
    const portfolioBrokerAccounts = get(portfolioBrokerAccountsState);
    // Selected Portfolio Accounts
    const portfolioAccountNames = portfolioAccounts?.map((a) => a.portfolioName.trim()).join(', ');
    const accountsLength = portfolioAccounts?.length || 0;

    // Selected Order
    const order = sortsPortfolio.data[0].order == '0' ? 'Ascending' : 'Descending';

    // Selected Alerts
    const selectedAlertNames =
      selectedalerts && typeof selectedalerts.value !== 'string'
        ? selectedalerts?.value
            .map((a) => {
              const names = alertsByAccounts?.alert.filter((al) => {
                if (al.key === a) {
                  return al;
                }
              });
              return names?.map((n) => n.name).join(',');
            })
            .join(',')
        : undefined;
    const selectedAlertsLength = selectedAlertNames ? selectedAlertNames.trim().split(',').length : 0;

    // Selected Notification
    const selectedNotificationNames =
      selectednotifcations && typeof selectednotifcations.value !== 'string'
        ? selectednotifcations?.value
            .map((a) => {
              const names = notitifcationByAccounts?.alert.filter((al) => {
                if (al.key === a) {
                  return al;
                }
              });
              return names?.map((n) => n.name).join(',');
            })
            .join(',')
        : undefined;
    const selectedNotificationsLength = selectedNotificationNames
      ? selectedNotificationNames.trim().split(',').length
      : 0;

    const summary: IPortfolioSlectedSummary = {
      selectedAccounts: portfolioAccountNames || '',
      selectedAccountsLength: accountsLength,
      order: order,
      selectedAlerts: selectedAlertNames || '',
      selectedAlertsLength: selectedAlertsLength,
      selectedNotifications: selectedNotificationNames || '',
      selectedNotificationsLength: selectedNotificationsLength,
      brokerAccounts: portfolioBrokerAccounts,
    };
    return summary;
  },
});

export const processPortfolioUrl = (
  portfolios: PortfolioCombinationAccounts | undefined,
  id: string | null,
  symbol?: string | null,
) => {
  if (!portfolios || !portfolios.data || portfolios.data.length === 0) {
    return undefined;
  }
  const portfolioId = NumberFormatHelper.parseNumberOrReturnNull(id);
  if (!portfolioId) {
    return undefined;
  }
  const portfolio = portfolios.data.find((p) => p.portfolioId === portfolioId);
  if (!portfolio) {
    return undefined;
  }
  const searchCombinationEntity = portfolio.portfolioCombinations.find((p) => p.symbol === symbol);
  return { id: portfolio.portfolioId, entity: searchCombinationEntity ? searchCombinationEntity : undefined };
};
