import { Grid, Typography, useTheme } from '@mui/material';
import { Leg, SentimentType, StrategyHelpers } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  IStrategyConstructor,
  howDataState,
  selectedSymbolState,
  strategyConstructorSelectorState,
  strategyModifyState,
  tradingStrategiesSentimentAtom,
  tradingStrategyAtomFamilyState,
  tradingStrategySelectedStrategyState,
} from '@op/shared/src/states';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { MenuProps, OPSelectDropDownHeader, OPSelectField, StyledDropdownItemWithBorder } from '../styled';
import { GuideItem } from '../side-menu';

export const StrategyConstructorWidget: React.FC = () => {
  const theme = useTheme();
  const selectedCombinationId = useRecoilValue(tradingStrategySelectedStrategyState);
  const howData = useRecoilValue(howDataState);
  const setStrategyModify = useSetRecoilState(strategyModifyState);
  const [combination, setCombination] = useRecoilState(tradingStrategyAtomFamilyState(selectedCombinationId));
  const { t } = React.useContext(LocalizationContext);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const strategySentiment = useRecoilValue(tradingStrategiesSentimentAtom);
  const strategyOptions = useRecoilValue(strategyConstructorSelectorState);

  if (!selectedCombinationId || !combination || !strategyOptions) {
    return <>Please select a strategy for details</>;
  }
  const selectedStratergy = combination?.matchedTemplate()?.displayedName;

  const renderStrategyOptions = (option: IStrategyConstructor) => {
    if (!option) {
      return;
    }
    let nestedOptions = option.template; //StrategiesProvider.strategyTemplatesBySentiment.get(option);
    if (!nestedOptions) {
      return;
    }
    return nestedOptions.map((nestedOption, index) => (
      <StyledDropdownItemWithBorder key={index} value={nestedOption.displayedName} color="default">
        {nestedOption.displayedName}
      </StyledDropdownItemWithBorder>
    ));
  };

  const renderSentimentOptions = () => {
    const sentimentOptions = strategyOptions; //Array.from(StrategiesProvider.sentiments);
    if (!sentimentOptions || !sentimentOptions.length) {
      return;
    }
    return sentimentOptions.map((option, index) => [
      <OPSelectDropDownHeader key={index} type="strategy" header={option.name} />,
      renderStrategyOptions(option),
    ]);
  };

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const onSelectStrategyToConstruct = (strategyDisplayedName: string) => {
    logActivity('BUTTON', 'editorStrategySelector', strategyDisplayedName);
    const newCombination = StrategyHelpers.generateCombinationByStrategyName(
      strategyDisplayedName,
      howData,
      combination,
      strategySentiment as SentimentType,
    );
    if (!newCombination) {
      return;
    }
    if (!combination.originalLegs) {
      return;
    }
    newCombination.originalLegs = combination.originalLegs.map((l) => Leg.fromSelf(l));
    setCombination(newCombination);
    setStrategyModify(newCombination);
  };

  return (
    <Grid container spacing={1} direction="row" alignItems="center">
      <Grid item container xs={3}>
        <Typography variant="body1">{t('common.titles.strategy')}:</Typography>
        <GuideItem selector=".strategyConstructor_helpPinPlaceholder" />
      </Grid>
      <Grid item xs={9} sx={{ minWidth: 120, pr: 2 }}>
        {/* If not selectedStratergy, then it should show 'Custom Strategy' */}
        <OPSelectField
          variant="outlined"
          onChange={(event) => onSelectStrategyToConstruct(event.target.value as string)}
          id="grouped-native-select"
          sx={{
            pl: 1,
            width: '100%',
            height: 35,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
          }}
          MenuProps={MenuProps}
          value={selectedStratergy ? selectedStratergy : 'Custom Strategy'}>
          {!selectedStratergy && (
            <StyledDropdownItemWithBorder value={'Custom Strategy'} color="default">
              {'Custom Strategy'}
            </StyledDropdownItemWithBorder>
          )}
          {renderSentimentOptions()}
        </OPSelectField>
      </Grid>
    </Grid>
  );
};
