import { Grid, Typography } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { howWhyRanksDataState, customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';

export const DivYieldWidget: React.FC = () => {
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const { t } = React.useContext(LocalizationContext);
  const customization = useRecoilValue(customizationState);

  if (!howWhyRanksData.how) {
    return <>No quote data</>;
  }
  const quote = howWhyRanksData.how.quote;
  const { div } = quote;
  const formattingYield = NumberFormatHelper.toPercentage(quote.yield);

  const getExDividend = () => {
    if (!customization?.isMerrill || !howWhyRanksData.why || !howWhyRanksData.why.exDividends) {
      return;
    }
    const dividendsDate = DateTimeHelper.resolveDate(howWhyRanksData.why.exDividends.exDividendDate);
    const days = DateTimeHelper.daysFromNow(dividendsDate);
    const strdate = DateTimeHelper.format(dividendsDate);
    let result = '';
    result = strdate + ' (' + days + ')';
    return <Typography style={{ fontSize: 9 }}>{`- ${result}`}</Typography>;
  };

  return (
    <>
      <Grid item xs={5}>
        <Typography variant={'captionSmall'} component="div" lineHeight={1.2}>
          {t('why.divYield')}
        </Typography>
      </Grid>
      <Grid item xs={7} textAlign="end">
        <Typography variant={'captionSmall'} fontWeight="bold">
          <span>{NumberFormatHelper.toCurrency(div)}</span> / <span>{formattingYield}</span>
          {getExDividend()}
        </Typography>
      </Grid>
    </>
  );
};
