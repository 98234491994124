import { Button, Grid, debounce, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { OPBoldTypograpghy } from '../../styled';
import { IMarketOrderRequest } from '../models';
import { ITradeProps, buildOrderTicketPositions, getLegsForOrderRequest } from '../models/ts-helpers';
import { marketPlaceOrderForReview } from '../services';
import { tsEnvironmentState, tsOrderTicketState, tsSelectedTabState } from '../states/trade-station-states';
import {
  confirmOrderTicketState,
  marketOrderRequestState,
  tsOrderPlacementState,
  tsOrdetTicketPositionState,
  tsTradeOrderPropsState,
} from '../states/ts-ticket-states';
import { TSOrderType } from '@op/shared/src/models/enums/enums';

interface Props {
  tsTradeProps: ITradeProps[];
}

export const ClosePositionWidget: React.FC<Props> = ({ tsTradeProps }: Props) => {
  const theme = useTheme();
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setMarketOrderRequest = useSetRecoilState(marketOrderRequestState);
  const setConfirmOrderTicketData = useSetRecoilState(confirmOrderTicketState);
  const setTsTradeOrderProps = useSetRecoilState(tsTradeOrderPropsState);
  const setTrade = useSetRecoilState(tsOrderPlacementState);
  const setTSSelectedTab = useSetRecoilState(tsSelectedTabState);
  const resetTsOrderTicketPositions = useResetRecoilState(tsOrdetTicketPositionState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setOrderTicketValue = useSetRecoilState(tsOrderTicketState);
  const closePosition = async () => {
    if (!tsTradeProps || tsTradeProps[0].action !== 'closeAtMarket') {
      return;
    }
    setTrade(true);
    setTSSelectedTab('orderTicket');
    setOrderTicketValue('isLoading');
    setTsTradeOrderProps(tsTradeProps);
    setConfirmOrderTicketData(undefined);
    const tsOrderTicketPositions = buildOrderTicketPositions(tsTradeProps);
    if (!tsOrderTicketPositions) {
      return;
    }
    let request: IMarketOrderRequest = {
      accountID: tsOrderTicketPositions[0].accountID,
      buyingPowerWarning: 'Enforce',
      orderType: TSOrderType.Market,
      timeInForce: 'Day',
      route: 'Intelligent',
      legs: getLegsForOrderRequest(tsOrderTicketPositions, tsTradeProps),
    };
    setMarketOrderRequest(request);
    const response = await marketPlaceOrderForReview(tsEnv, request);
    if (response.hasErrors) {
      setNotifications([{ type: 'error', content: response.errors[0].message }]);
      setTSSelectedTab('positions');
      setOrderTicketValue('default');
      setTrade(false);
      return;
    }
    setConfirmOrderTicketData(response.data);
    setOrderTicketValue('completed');
  };

  const debouncedOnClosePosition = debounce(closePosition, 1000);

  return (
    <Grid item xs={2}>
      <Button
        sx={{
          backgroundColor: theme.palette.error.main,
          ':hover': {
            backgroundColor: theme.palette.error.main,
          },
        }}
        fullWidth
        onClick={() => {
          resetTsOrderTicketPositions();
          debouncedOnClosePosition();
        }}
        variant="contained">
        <OPBoldTypograpghy>Close at Market</OPBoldTypograpghy>
      </Button>
    </Grid>
  );
};
