import { ILeg } from '..';
import { IEditPortfolioResponseProps } from './';

export class EditPortfolioResponse implements IEditPortfolioResponseProps {
  id = 0;
  symbol = '';
  markedAsDeleted = false;
  enableExpiry = false;
  enablestrikePrice = false;
  isNew = false;
  leg: ILeg[] = [];
  costBasisTotal = '0';
  expiry = '';
  legType = '';
  quantity = '1';
  strike = 0;

  public static fromJson = (data: EditPortfolioResponse): EditPortfolioResponse => {
    const model = new EditPortfolioResponse();
    model.id = data.id;
    model.symbol = data.symbol;
    model.leg = data.leg;
    model.enableExpiry = data.enableExpiry;
    model.enablestrikePrice = data.enablestrikePrice;
    model.isNew = data.isNew;
    model.markedAsDeleted = data.markedAsDeleted;
    model.costBasisTotal = parseFloat(data.costBasisTotal).toFixed(2);
    model.expiry = data.expiry;
    model.legType = data.legType;
    model.quantity = parseFloat(data.quantity).toFixed(2);
    model.strike = data.strike;
    return model;
  };

  static fromSelf = (data: EditPortfolioResponse) => {
    const clone = new EditPortfolioResponse();
    clone.id = data.id;
    clone.symbol = data.symbol;
    clone.leg = data.leg;
    clone.enableExpiry = data.enableExpiry;
    clone.enablestrikePrice = data.enablestrikePrice;
    clone.isNew = data.isNew;
    clone.markedAsDeleted = data.markedAsDeleted;
    clone.costBasisTotal = parseFloat(data.costBasisTotal).toFixed(2);
    clone.expiry = data.expiry;
    clone.legType = data.legType;
    clone.quantity = parseFloat(data.quantity).toFixed(2);
    clone.strike = data.strike;
    return clone;
  };
}
