import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import { historicalChartState } from './chart-state';

export const ChartModalWidget: React.FC<{ children: ReactElement }> = ({ children }: { children: ReactElement }) => {
  const settings = useRecoilValue(historicalChartState);

  const getDialogWidth = (selectedDialogSize: string) => {
    if (selectedDialogSize === 'sm') {
      return 65;
    } else if (selectedDialogSize === 'md') {
      return 85;
    } else {
      return 100;
    }
  };

  //Used only for modal height
  const getDialogHeight = (sizeName: string) => {
    if (sizeName === 'sm') {
      return 66;
    } else if (sizeName === 'md') {
      return 76;
    } else {
      return 100;
    }
  };

  const width = getDialogWidth(settings.modalSize);
  const height = getDialogHeight(settings.modalSize);

  {
    /* Op-save-views is hidden using custom style in index.css
     * chart-modal-dialog is used to hide the op-save-view icon in index.css
     */
  }
  return (
    <Dialog
      id="chart-modal-dialog"
      hideBackdrop
      PaperProps={{ tabIndex: -1 }}
      open={true} //{props.open}
      aria-labelledby="draggable-dialog"
      fullScreen
      translate="yes"
      disableEnforceFocus
      sx={{
        zIndex: 1200,
        left: '50%',
        top: '50%',
        WebkitTransform: 'translate(-50%,-50%)',
        tranform: 'translate(-50%,-50%)',
        width: `${width}%`,
        height: `${height}%`,
      }}>
      <DialogContent sx={{ p: 0, overflow: 'hidden' }}>{children}</DialogContent>
    </Dialog>
  );
};
