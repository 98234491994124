import { atom, selector, selectorFamily } from 'recoil';
import { Account, IHelpPin } from '../models';
import { Aggressiveness, TimeFrame } from '@op/shared/src/models/enums/enums';

export const StorageAccountKey = 'account_key';
export const accessTokenKey = 'access_token';
export const refreshTokenKey = 'refresh_token';

export const accountState = atom<Account | undefined>({
  key: 'accountStateKey',
  default: undefined,
});

export const defaultTimeFrameCallState = atom<TimeFrame>({
  key: 'defaultTimeFrameCallState',
  default: TimeFrame.LONG,
});

export const defaultAggressivenessCallState = atom<Aggressiveness>({
  key: 'defaultAggressivenessCallState',
  default: Aggressiveness.AGGRESSIVE,
});

export const defaultTimeFramePutState = atom<TimeFrame>({
  key: 'defaultTimeFramePutState',
  default: TimeFrame.SHORT,
});

export const defaultAggressivenessPutState = atom<Aggressiveness>({
  key: 'defaultAggressivenessPutState',
  default: Aggressiveness.CONSERVATIVE,
});

export const guideStartState = atom<boolean>({
  key: 'guideStartStateKey',
  default: false,
});

export const guideCachedState = atom<{ isShowing: boolean; helpPins: IHelpPin[] }>({
  key: 'guideCachedStateKey',
  default: { isShowing: false, helpPins: [] },
});

type helpPinType = { isShowing: boolean; helpPin?: IHelpPin } | undefined;
export const helpPinsState = selectorFamily<helpPinType, string>({
  key: 'helpPinsStateKey',
  get:
    (selector: string) =>
    ({ get }) => {
      const guide = get(guideCachedState);
      const helpPin = guide.helpPins.find((hp) => hp.selector === selector);
      if (!helpPin) {
        return undefined;
      }
      return { isShowing: guide.isShowing, helpPin: helpPin };
    },
  set:
    (selector: string) =>
    ({ set }, newPins: helpPinType) => {
      set(guideCachedState, (oldGuide) => {
        const allPins = [...oldGuide.helpPins];
        return {
          isShowing: newPins.isShowing,
          helpPins: allPins,
        };
      });
    },
});

export const isDeleteRequested = selector<boolean>({
  key: 'isDeleteRequestedKey',
  get: async ({ get }) => {
    const account = get(accountState);

    if (!account) {
      return false;
    }

    const {
      securityModel: { deleteRequested },
    } = account;

    return deleteRequested;
  },
});
