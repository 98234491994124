import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Grid, Link } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { cloneDeep, isNordic, isTmx } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { ProfileDetailsService } from '@op/shared/src/services';
import { accountState, configurationState, customizationState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { CommonListItemIcon } from './common-list-item-icon-widget';
import { OPDialogWidget } from '../common';
import { OutlinedButton } from '../styled/options-play-components';
import { ImplementationType } from '@op/shared/src/models/enums/enums';
import { BncDisclaimerWidget } from '../embedded/bnc-disclaimer-widget';

export interface DisclaimerWidgetProps {
  showTabName: boolean;
}

export const DisclaimerWidget: React.FC<DisclaimerWidgetProps> = ({ showTabName }: DisclaimerWidgetProps) => {
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const account = useRecoilValue(accountState);
  const [open, setOpen] = React.useState(false);
  const { t } = React.useContext(LocalizationContext);

  useEffect(() => {
    if (!configuration || !account || !configuration.userSettings || !customization || !customization.showDisclaimer) {
      return;
    }

    // //TODO: use customization instead of configuration
    // if (configuration.isEmbeddingPlatform) {
    //   return;
    // }
    const hasDisclaimerBeenAccepted = ApplicationContext.configuration.userSettings?.hasDisclaimerBeenAccepted;
    if (!hasDisclaimerBeenAccepted) {
      handleClickOpen();
    }
  }, [account, configuration]);

  if (!account || !configuration || !customization || !customization.showDisclaimer) {
    return null;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNordicDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          OptionsPlay Nordic is available free of charge only for non professional users. By agreeing to this form, I
          hereby confirm I am a non professional user. For more information about this distinction, it can be found on
          the Nasdaq Global Subscriber Agreement (on page 9).
        </Typography>
        <Typography gutterBottom>
          <b>A non-professional users is defined by:</b>
        </Typography>
        <Typography gutterBottom>
          A natural person for the purpose of managing the Subscriber’s own personal investments and not for any
          business purpose, nor for the purpose of giving any form of advice to any other person.
        </Typography>
        <Typography gutterBottom>
          <b>A Private Use Subscriber may not:</b>
        </Typography>
        <Typography gutterBottom>
          (a) contract for, receive or use Information for the purpose of Private Use on behalf of any other person or
          any corporation, partnership, limited liability company, trust, association or other form of entity,
        </Typography>
        <Typography gutterBottom>
          (b) contract for, receive or use Information for the purpose of Private Use in any Service that is paid for by
          another person or any corporation, partnership, limited liability company, trust, association or other form of
          entity.
        </Typography>
        <Typography gutterBottom>
          <b>A Private Use Subscriber shall, notwithstanding the above:</b>
        </Typography>
        <Typography gutterBottom>
          (c) be permitted to contract for, receive or use Information on behalf of or paid for by another natural
          person (person B) provided that (1) its for the purpose of managing person B’s own personal investments and
          not for any business purpose, and (2) person B have filed a power of attorney or equivalent documentation
          accordingly with Licensee,
        </Typography>
        <Typography gutterBottom>
          (d) be permitted to contract for, receive or use Information for Private Use on behalf of and/or paid for by a
          legal entity or other form of non-natural Person in which the Private Use Subscriber has full (100%) ownership
          and exercises full (100%)control,
        </Typography>
        <Typography gutterBottom>
          (e) Section (c) and (d) may not be combined. A professional subscriber can still receive Options Education and
          OptionsPlay’s weekly newsletters on options related news, and reports. A professional subscriber cannot
          receive free access to OptionsPlay Nordic.
        </Typography>
      </DialogContent>
    );
  };

  const renderTMXDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          The information provided by OptionsPlay is for general informational and educational purposes only and is not
          intended to be, and should not be construed as, investment advice, a recommendation, or an offer or
          solicitation to buy or sell any securities, financial instruments or other investments. OptionsPlay is not
          registered with the Investment Industry Regulatory Organization of Canada (IIROC).
        </Typography>
        <Typography gutterBottom>
          Trading involves substantial risk and is not suitable for all investors. You should carefully consider your
          financial objectives, level of experience, risk tolerance, and investment horizon before making any investment
          decisions. We strongly encourage you to seek the advice of a qualified financial professional before making
          any investment decisions based on the trading signals or any other information provided by our service.
        </Typography>
        <Typography gutterBottom>
          We make no representations, warranties or guarantees of any kind, express or implied, regarding the accuracy,
          reliability, completeness, or timeliness of the information provided through our service. You acknowledge and
          agree that any reliance on such information is solely at your own risk, and we expressly disclaim any and all
          liability for any errors, omissions, or inaccuracies in the information or for any actions taken in reliance
          on the information.
        </Typography>
        <Typography gutterBottom>
          By using OptionsPlay, you acknowledge that you have read, understood, and agree to be bound by this
          disclaimer, and you assume full responsibility for your own investment decisions and any resulting outcomes.
        </Typography>
      </DialogContent>
    );
  };

  const regalDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          OptionsPlay provides equity and option investing tools, daily trading ideas and strategies, and technical
          analysis for informational purposes only and eOption cannot attest to its reliability, performance, accuracy
          or completeness. eOption makes no representation or warranty that any data or information supplied to or by
          OptionsPlay is complete or free from errors, omissions, or defects. No information contained in OptionsPlay is
          intended to constitute a recommendation by eOption to buy, sell, or hold any stock, option, or a particular
          investment. eOption makes no investment recommendations and does not provide financial, tax, or legal advice.
        </Typography>
        <Typography gutterBottom>
          With your use of OptionsPlay, you are solely responsible for your own investment and trading decisions, and
          any losses damages or costs resulting from your reliance on any data or information that is provided in
          connection with your use of OptionsPlay. eOption is not responsible for determining whether any transaction
          you may enter into is suitable, appropriate or advisable. The research and investment strategies contained in
          OptionsPlay may not be appropriate for you.
        </Typography>
        <Typography gutterBottom>
          Options involve risk and are not suitable for all investors. Prior to trading options, you must be approved
          for options trading and read the
          <Link href="http://www.optionsclearing.com/about/publications/character-risks.jsp" target="_blank">
            Characteristics and Risks of Standardized Options
          </Link>
          . A copy may also be requested via email at
          <Link href="mailto:support@eoption.com" target="_blank">
            support@eoption.com
          </Link>
          or via mail to eOption, 950 Milwaukee Ave., Ste. 102, Glenview, IL 60025. Online trading has inherent risks
          due to loss of online services or delays from system performance, risk parameters, market conditions, and
          erroneous or unavailable market data.
        </Typography>
      </DialogContent>
    );
  };

  const renderDefaultDisclaimer = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>
          There can be significant risk of losses in trading securities and options. Customers must consider all
          relevant risk elements including their individual financial circumstances prior to trading. Options involve
          risks and are not suitable for every investor. Prior to buying or selling an option, an investor must receive
          a copy of Characteristics and Risks of Standardized Options. Also please note on December 17, 2009 the
          Securities and Exchange Commission (SEC) approved a{' '}
          <span className="underline">Supplement to the Options Disclosure Document (ODD)</span> providing information
          relating to options on indexes and the adjustment of stock option contracts. Any results posted are unique and
          are not indicative of any future results.
        </Typography>
        <Typography gutterBottom>
          Copies may be obtained from your broker, one of the exchanges or the Options Clearing Corporation. Historical
          results do not indicate future performance. OptionsPlay does not guarantee in writing or verbally that
          material on this website or that taught in the videos or written material contained in the associated files
          that accompany the videos will result in a profit. Investors need a broker to trade options, and must meet
          suitability requirements.
        </Typography>
        <Typography gutterBottom>
          For ease of simplicity, all examples used on this website and in the videos exclude commissions, interest and
          dividend and other transaction fees, tax considerations, or margin requirements, which are factors that may
          significantly affect the economic consequences of trades illustrated. Please contact a tax advisor for the tax
          implications involved with trading specific spread types and read the Options Disclosure Document (ODD) prior
          to investing in options. You can obtain a copy of the ODD at{' '}
          <Link href="http://www.theocc.com/about/publications/character-risks.jsp" target="_blank">
            http://www.theocc.com/about/publications/character-risks.jsp
          </Link>
          ; or, from the Options Clearing Corporation (1888-options), One North Wacker Drive, Suite 500, Chicago IL
          60606. Stock/Index/ETF and option symbols and price data shown are for illustrative purposes only.
        </Typography>
        <Typography gutterBottom>
          The information and data contained in this website (www.OptionsPlay.com) was obtained from sources believed to
          be reliable, but accuracy is not guaranteed. Neither the information, nor any opinion expressed, constitutes a
          recommendation to purchase or sell a security, or to provide investment advice.
        </Typography>
        <Typography gutterBottom>
          The information contained on this site is provided for general informational purposes, as a convenience to
          clients. The materials are not a substitute for obtaining professional advice from a qualified person, firm or
          corporation. Consult the appropriate professional advisor for more complete and current information.
          OptionsPlay is not engaged in rendering any legal or professional services by placing these general
          informational materials on this website.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay specifically disclaims any liability, whether based in contract, tort, strict liability or
          otherwise, for any direct, indirect, incidental, consequential, or special damages arising out of or in any
          way connected with access to or use of the site, even if OptionsPlay has been advised of the possibility of
          such damages, including liability in connection with mistakes or omissions in, or delays in transmission of,
          information to or from the user, interruptions in telecommunications connections to the site or viruses.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay makes no representations or warranties about the accuracy or completeness of the information
          contained on this website. Any links provided to other server sites are offered as a matter of convenience and
          in no way are meant to imply that OptionsPlay endorses, sponsors, promotes or is affiliated with the owners of
          or participants in those sites, or endorses any information contained on those sites, unless expressly stated.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay is the copyright owner of all text and graphics contained on this website, except as otherwise
          indicated. Other parties trademarks and service marks that may be referred to herein are the property of their
          respective owners. You may print a copy of the information contained herein for your personal use only, but
          you may not reproduce or distribute the text or graphics to others or substantially copy the information on
          your own server, or link to this website, without prior written permission of OptionsPlay.
        </Typography>
        <Typography gutterBottom>
          Permission to use and reproduce documents and related graphics available from this website is granted,
          provided that: 1. the below copyright notice appears in all copies and that both the copyright and this
          permission notice appear; 2. use and reproduction of documents and related graphics available from this
          website is limited to personal, non-commercial use; 3. no documents or related graphics, including logos,
          available from this website are modified in any way; and 4. no graphics, including logos, available from this
          website are used separate from accompanying text. Use or reproduction for any other purpose is expressly
          prohibited by law, and may result in civil and criminal penalties.
        </Typography>
      </DialogContent>
    );
  };

  const renderFirsTrade = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
          Options trading entails significant risk and is not appropriate for all investors. Certain complex options
          strategies carry additional risk. There is always the potential of losing money when you invest in securities
          or other financial products. Investors should consider their investment objectives and risks carefully before
          investing. To learn more about the risks associated with options, please read the Characteristics and Risks of
          Standardized Options before you begin trading options. Supporting documentation for any claims, if applicable,
          will be furnished upon request.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay, LLC and Syrah Trading Technologies, LLC (together, “OptionsPlay”) are independent third-party
          providers and have no affiliation with Firstrade. OptionsPlay is the sole party responsible for the software,
          data, and services (“OptionsPlay Services”) they offer via Firstrade. Firstrade has not verified the accuracy
          of the OptionsPlay Services and does not guarantee its accuracy or completeness. Firstrade will not be liable
          for any issues arising from your use of or reliance on the OptionsPlay Services.
        </Typography>
        <Typography gutterBottom>
          Past performance of the probability of profit (POP) metric doesn't guarantee future results. Options contracts
          carry inherent risks, including the risk of assignment even if the contract is out of the money or before its
          expiration. Calculations for maximum risk and reward don't account for exercise or assignment. Changes to an
          options strategy, like trading or rolling, can alter its maximum loss, gain, and breakeven values. This
          applies to all options strategies, including long and short options as well as spreads.
        </Typography>
        <Typography gutterBottom>
          Using historical data to compare Annualized Return doesn't guarantee future outcomes.
        </Typography>
        <Typography gutterBottom>
          Copies may be obtained from your broker, one of the exchanges or the Options Clearing Corporation. Historical
          results do not indicate future performance. OptionsPlay does not guarantee in writing or verbally that
          material on this website or that taught in the videos or written material contained in the associated files
          that accompany the videos will result in a profit. Investors need a broker to trade options, and must meet
          suitability requirements.
        </Typography>
        <Typography gutterBottom>
          {`For ease of simplicity, all examples used on this website and in the videos exclude commissions, interest and
          dividend and other transaction fees, tax considerations, or margin requirements, which are factors that may
          significantly affect the economic consequences of trades illustrated. Please contact a tax advisor for the tax
          implications involved with trading specific spread types and read the Options Disclosure Document (ODD) prior
          to investing in options. You can obtain a copy of the ODD at `}
          <Link
            href="https://www.theocc.com/getmedia/a151a9ae-d784-4a15-bdeb-23a029f50b70/riskstoc.pdf"
            target="_blank">
            {`https://www.theocc.com/getmedia/a151a9ae-d784-4a15-bdeb-23a029f50b70/riskstoc.pdf or, from the Options `}
          </Link>
          {`Clearing Corporation (1888-options), One North Wacker Drive, Suite 500, Chicago IL 60606. Stock/Index/ETF and
          option symbols and price data shown are for illustrative purposes only.`}
        </Typography>
        <Typography gutterBottom>
          The information and data contained in this website (www.OptionsPlay.com) was obtained from sources believed to
          be reliable, but accuracy is not guaranteed. Neither the information, nor any opinion expressed, constitutes a
          recommendation to purchase or sell a security, or to provide investment advice.
        </Typography>
        <Typography gutterBottom>
          The information contained on this site is provided for general informational purposes, as a convenience to
          clients. The materials are not a substitute for obtaining professional advice from a qualified person, firm or
          corporation. Consult the appropriate professional advisor for more complete and current information.
          OptionsPlay is not engaged in rendering any legal or professional services by placing these general
          informational materials on this website.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay specifically disclaims any liability, whether based in contract, tort, strict liability or
          otherwise, for any direct, indirect, incidental, consequential, or special damages arising out of or in any
          way connected with access to or use of the site, even if OptionsPlay has been advised of the possibility of
          such damages, including liability in connection with mistakes or omissions in, or delays in transmission of,
          information to or from the user, interruptions in telecommunications connections to the site or viruses.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay makes no representations or warranties about the accuracy or completeness of the information
          contained on this website. Any links provided to other server sites are offered as a matter of convenience and
          in no way are meant to imply that OptionsPlay endorses, sponsors, promotes or is affiliated with the owners of
          or participants in those sites, or endorses any information contained on those sites, unless expressly stated.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay is the copyright owner of all text and graphics contained on this website, except as otherwise
          indicated. Other parties' trademarks and service marks that may be referred to herein are the property of
          their respective owners. You may print a copy of the information contained herein for your personal use only,
          but you may not reproduce or distribute the text or graphics to others or substantially copy the information
          on your own server, or link to this website, without prior written permission of OptionsPlay.
        </Typography>
        <Typography gutterBottom>
          Permission to use and reproduce documents and related graphics available from this website is granted,
          provided that: 1. the below copyright notice appears in all copies and that both the copyright and this
          permission notice appear; 2. use and reproduction of documents and related graphics available from this
          website is limited to personal, non-commercial use; 3. no documents or related graphics, including logos,
          available from this website are modified in any way; and 4. no graphics, including logos, available from this
          website are used separate from accompanying text. Use or reproduction for any other purpose is expressly
          prohibited by law, and may result in civil and criminal penalties.
        </Typography>
      </DialogContent>
    );
  };

  const renderBncTrade = () => {
    return (
      <DialogContent className="scrollbar-content">
        <Typography gutterBottom>© 2020 OptionsPlay. All Rights Reserved.</Typography>
        <Typography gutterBottom>
          OptionsPlay have one goal: To show you how options can unlock investment opportunities for everyone. They
          guide investors of all experience levels through the risks and rewards of options trading using clear, concise
          language that anyone can understand.
        </Typography>
        <Typography gutterBottom>
          OptionsPlay tool is the property of OptionsPlay which is not affiliated with National Bank Direct Brokerage
          (NBDB), a division of National Bank Financial Inc., or any of the companies in the National Bank of Canada
          group.
        </Typography>
        <Typography gutterBottom>
          The content and information provided in the OptionsPlay tool have been independently prepared by OptionsPlay
          and/or its content providers and are neither endorsed nor approved by NBDB which does not accept any
          responsibility in this regard.
        </Typography>
        <Typography gutterBottom>
          The information contained herein: (1) is proprietary to OptionsPlay and/or its content providers; (2) may not
          be copied or distributed; (3) is not warranted to be accurate, complete or timely; and, (4) does not
          constitute advice or a recommendation by NBDB, OptionsPlay or its content providers in respect of the
          investment in financial instruments.
        </Typography>
        <Typography gutterBottom>
          Neither NBDB, nor OptionsPlay and its content providers are responsible for any damages or losses arising from
          any use of this information. Past performance is no guarantee of future results.
        </Typography>
      </DialogContent>
    );
  };

  const renderDialogContent = () => {
    if (customization && customization.isEmbeddingPlatform) {
      if (customization.implementation === ImplementationType.FIRSTRADE) {
        return renderFirsTrade();
      }
      if (customization.implementation === ImplementationType.BNC) {
        return renderBncTrade();
      }
    }
    if (isNordic()) {
      return renderNordicDisclaimer();
    }
    if (isTmx()) {
      return renderTMXDisclaimer();
    }
    return renderDefaultDisclaimer();
  };

  const onClickButton = async (isAccepted: boolean) => {
    const res = await acceptDisclaimer(isAccepted);
    if (!res) {
      return;
    }
    if (!isAccepted && !customization.isEmbeddingPlatform) {
      if (isNordic()) {
        window.location.href = 'https://optionsplay.lpages.co/nordic-edu/';
      } else {
        window.location.href = 'http://www.optionsplay.com/';
      }
    }
    const clonedConfiguration = cloneDeep(configuration);
    if (clonedConfiguration.userSettings !== undefined) {
      clonedConfiguration.userSettings.hasDisclaimerBeenAccepted = isAccepted;
      ApplicationContext.configuration = clonedConfiguration;
    }
    handleClose();
  };

  const acceptDisclaimer = async (value: boolean) => {
    const response = await ProfileDetailsService.instance.AcceptDisclaimer(value);
    if (response.hasErrors) {
      return undefined;
    }
    return response.data;
  };

  const renderTabName = () => {
    if (!showTabName) {
      return null;
    }
    return (
      <Typography>
        <CommonListItemIcon
          icon={<ErrorOutlineIcon />}
          text={t('footer.labels.disclaimer')}
          callback={handleClickOpen}
          key={'disclaimer'}
        />
      </Typography>
    );
  };

  const renderDialogTitle = () => {
    return (
      <Grid id="customized-dialog-title" onClick={handleClose}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Online Options Trading
          <br />
          Legal Notice & Disclaimer
        </Typography>
      </Grid>
    );
  };

  const renderDialogAction = () => {
    if (customization.showDisclaimerContinueButton) {
      return (
        <Grid container justifyContent={'flex-end'} spacing={2}>
          <Grid item>
            <OutlinedButton autoFocus size="small" onClick={() => onClickButton(true)}>
              <Typography variant="button">{t('modals.disclaimer.buttons.continue')}</Typography>
            </OutlinedButton>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent={'flex-end'} spacing={2}>
        <Grid item>
          <OutlinedButton autoFocus size="small" onClick={() => onClickButton(false)}>
            <Typography variant="button">{t('modals.disclaimer.buttons.doNotAgree')}</Typography>
          </OutlinedButton>
        </Grid>
        <Grid item>
          <OutlinedButton autoFocus size="small" onClick={() => onClickButton(true)}>
            <Typography variant="button">{t('modals.disclaimer.buttons.agree')}</Typography>
          </OutlinedButton>
        </Grid>
      </Grid>
    );
  };

  const renderDisclaimer = () => {
    if (!configuration) {
      return;
    }
    if (customization && customization.isEmbeddingPlatform) {
      if (customization.implementation === ImplementationType.BNC) {
        return <BncDisclaimerWidget onHandleClose={handleClose} open={open} onClickButton={onClickButton} />;
      }
    }
    return (
      <OPDialogWidget
        open={open}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    );
  };

  return (
    <Grid>
      {renderTabName()}
      {renderDisclaimer()}
    </Grid>
  );
};
