import { IAddToWatchlistProps } from '.';

export class AddToWatchlist implements IAddToWatchlistProps {
  id = -1;
  name = '';
  type = 'None';

  public static fromJson = (data: AddToWatchlist): AddToWatchlist => {
    const model = new AddToWatchlist();
    model.name = data.name;
    model.type = data.type;
    return model;
  };
}
