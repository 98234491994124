export interface ILightbox {
  id: number;
  content: string | null;
  name: string | null;
  categoryString: string | null;
  isLightboxAccepted: boolean;
  url: string | null;
  webinarLink: string | null;
  eventStartDateTime: string | null;
  eventEndDateTime: string | null;
  webinarLabel: string | null;
  isRead: boolean;
  imageFilesrc: string | null;
  registeredUserAccess: boolean;
  tag: string | null;
  liveStreamLink: string | null;
  replayRecordedLink: string | null;
  registrationLink: string | null;
  startDateTime: string | null;
  endDateTime: string | null;
  imageUrl: string | null;
}

//TODO: this class is not requried. Interface is enough
export class Lightbox implements ILightbox {
  id = -1;
  content = '';
  name = '';
  categoryString = '';
  isLightboxAccepted = true;
  url = '';
  webinarLink = '';
  eventStartDateTime = '';
  eventEndDateTime = '';
  webinarLabel = '';
  isRead = true;
  imageFilesrc = '';
  registeredUserAccess = false;
  tag = '';
  liveStreamLink = '';
  replayRecordedLink = '';
  registrationLink = '';
  startDateTime = '';
  endDateTime = '';
  imageUrl = '';

  public static fromJson = (data: Lightbox): Lightbox => {
    const model = new Lightbox();
    model.id = data.id;
    model.content = data.content;
    model.name = data.name;
    model.categoryString = data.categoryString;
    model.isLightboxAccepted = data.isLightboxAccepted;
    model.url = data.url;
    model.webinarLink = data.webinarLink;
    model.eventStartDateTime = data.eventStartDateTime;
    model.eventEndDateTime = data.eventEndDateTime;
    model.webinarLabel = data.webinarLabel;
    model.isRead = data.isRead;
    return model;
  };
}
