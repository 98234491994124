export interface ISavePortfolioResponce {
  entity: string;
  errorCode: string;
  formattedMessage: string;
  isSuccess: boolean;
}

export class SavePortfolioResponce implements ISavePortfolioResponce {
  entity = '';
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: SavePortfolioResponce): SavePortfolioResponce => {
    const model = new SavePortfolioResponce();
    model.entity = data.entity;
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
