import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MenuProps, OPSelectDropDownHeader, OPSelectField, StyledDropdownItemWithBorder } from '../../styled';
import { getDuration, getRoutes } from '../services';
import { tsEnvironmentState } from '../states/trade-station-states';
import { prepareRoutes, tsDurationDataState, tsRoutesDataState } from '../states/ts-ticket-states';

interface Props {
  callback: (type: string, value: string) => void;
  route: string;
  duration: string;
  hasOptionLeg: boolean;
}

export const RouteDurationWidget: React.FC<Props> = ({ callback, route, duration, hasOptionLeg }: Props) => {
  const theme = useTheme();
  const [durations, setDurations] = useRecoilState(tsDurationDataState);
  const [routeOptions, setRouteOptions] = useRecoilState(tsRoutesDataState);
  const tsEnv = useRecoilValue(tsEnvironmentState);

  useEffect(() => {
    if (!durations || !routeOptions) {
      (async () => {
        const response = await getDuration(tsEnv);
        if (response.hasErrors || !response.data) {
          return;
        }
        setDurations(response.data);
      })();
      (async () => {
        const response = await getRoutes(tsEnv);
        if (response.hasErrors) {
          return;
        }
        const routes = prepareRoutes(response.data.routes);
        setRouteOptions(routes);
      })();
    }
  }, []);

  if (!durations || !routeOptions) {
    return (
      <Grid container sx={{ mt: 2.5, justifyContent: 'space-between' }}>
        <Skeleton animation="wave" variant="rectangular" width={'48%'} height={25} sx={{ borderRadius: 1 }} />
        <Skeleton animation="wave" variant="rectangular" width={'48%'} height={25} sx={{ borderRadius: 1 }} />
      </Grid>
    );
  }

  // Invalid durations for Option-Leg
  const invalidDurations = ['DYP', 'GCP', 'GDP', 'CLO'];
  const filteredDurations = hasOptionLeg
    ? [...durations].filter((duration) => !invalidDurations.includes(duration.name))
    : durations;
  const filteredRoutes = hasOptionLeg
    ? [...routeOptions].filter((r) => r.assetTypes.includes('STOCKOPTION'))
    : [...routeOptions].filter((r) => r.assetTypes.includes('STOCK'));

  const renderRoute = () => {
    return (
      <Grid container>
        <Typography variant="captionSmall">Route</Typography>
        <Typography color={theme.palette.error.main} lineHeight={1.2}>
          *
        </Typography>
        <OPSelectField
          variant="outlined"
          value={route}
          onChange={(event) => {
            callback('route', event.target.value as string);
          }}
          sx={{
            height: '24px',
            padding: 1,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            color: route === '' ? theme.palette.text.disabled : theme.palette.selectAndTextField.main,
          }}
          MenuProps={MenuProps}>
          <OPSelectDropDownHeader type="tsRoute" />
          {filteredRoutes.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item.id} value={item.name}>
                {item.name}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  const renderDuration = () => {
    return (
      <Grid container>
        <Typography variant="captionSmall">Duration</Typography>
        <Typography color={theme.palette.error.main} lineHeight={1.2}>
          *
        </Typography>
        <OPSelectField
          variant="outlined"
          displayEmpty
          value={duration}
          renderValue={duration !== '' ? undefined : () => 'Select Duration'}
          onChange={(event) => {
            callback('duration', event.target.value as string);
          }}
          sx={{
            padding: 1,
            height: '24px',
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            color: duration === '' ? theme.palette.text.disabled : theme.palette.selectAndTextField.main,
          }}
          MenuProps={MenuProps}>
          <OPSelectDropDownHeader type="tsDuration" />
          {filteredDurations.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item.name} value={item.name}>
                {item.name} {item.fullName && ` - ${item.fullName}`}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        {renderRoute()}
      </Grid>
      <Grid item xs={6}>
        {renderDuration()}
      </Grid>
    </Grid>
  );
};
