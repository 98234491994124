import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';

interface IAccordianProps {
  initialExpand: boolean;
  noExpansionClose: boolean;
  title: ReactNode;
  summary: JSX.Element;
}

export const AccordianWidget = ({ initialExpand, noExpansionClose, title, summary }: IAccordianProps) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(initialExpand);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <Accordion
        disableGutters
        defaultExpanded={initialExpand}
        expanded={expanded}
        onChange={() => {
          if (noExpansionClose) {
            return;
          }
          handleExpansion();
        }}
        sx={{
          border: `2px solid ${theme.palette.primary.light}`,
          ':hover': { border: `2px solid ${theme.palette.primary.main}` },
          borderRadius: 1,
        }}>
        <AccordionSummary
          sx={{
            backgroundColor: theme.palette.primary.main,
            paddingY: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
          expandIcon={
            !noExpansionClose && (
              <ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} fontSize={'large'} />
            )
          }>
          <Typography variant="h6" fontWeight={'600'} color={theme.palette.timeFrame.contrastText}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{summary}</AccordionDetails>
      </Accordion>
    </>
  );
};
