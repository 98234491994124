import { Box, Card, Grid, Skeleton } from '@mui/material';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';

export const QuotebarLoaderWidget: React.FC = () => {
  const customization = useRecoilValue(customizationState);
  const symbolWidgetHeight =
    customization.quotebarName === 'variant1' || customization.quotebarName === 'variant2' ? 100 : 125;

  const renderVariant1Skeleton = () => {
    return (
      <Card>
        <Grid container>
          <Grid item xs={0.2}>
            <Skeleton animation="wave" variant="rectangular" height={symbolWidgetHeight} width={8} />
          </Grid>
          <Grid item xs={11.8}>
            <Box display="flex" sx={{ m: 1 }}>
              <Skeleton variant="rounded" width={400} height={35} sx={{ mr: 1 }} />
              <Skeleton variant="text" width={200} height={35} />
            </Box>
            {customization.quotebarName === 'variant1' || customization.quotebarName === 'variant2' ? (
              <>
                {[0, 1].map((value) => (
                  <Box display="flex" justifyContent="center" key={value}>
                    {[0, 1, 2].map((value) => (
                      <Grid
                        xs={4}
                        display="flex"
                        justifyContent="space-between"
                        key={value}
                        sx={{ borderRight: '1px solid lightgrey', px: 1, py: 0.5 }}>
                        <Skeleton variant="text" width="50%" height={15} sx={{ mx: 0.5 }} />
                        <Skeleton variant="text" width="50%" height={15} sx={{ mx: 0.5 }} />
                      </Grid>
                    ))}
                  </Box>
                ))}
              </>
            ) : (
              <>
                {[0, 1, 2].map((value) => (
                  <Box display="flex" justifyContent="center" key={value}>
                    {[0, 1, 2].map((value) => (
                      <Grid
                        xs={4}
                        display="flex"
                        justifyContent="space-between"
                        key={value}
                        sx={{ borderRight: '1px solid lightgrey', px: 1, py: 0.5 }}>
                        <Skeleton variant="text" width="50%" height={15} sx={{ mx: 0.5 }} />
                        <Skeleton variant="text" width="50%" height={15} sx={{ mx: 0.5 }} />
                      </Grid>
                    ))}
                  </Box>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  };

  return renderVariant1Skeleton();
};
