import { Grid, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import { BuyOrSell, LegType } from '@op/shared/src/models/enums/enums';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { tsPositionDataState, tsSelectedAccountIdsState } from '..';
import { OPSelectField, StyledDropdownItemWithBorder } from '../../styled';
import {
  IOrderTicketPosition,
  ITradeProps,
  buildOrderTicketPositions,
  checkCloseFlag,
  checkExistingPositionByAccountID
} from '../models/ts-helpers';
import { tsOrdetTicketPositionState, tsTradeOrderPropsState, tsTriggerState } from '../states/ts-ticket-states';
import { StyledTableCell } from '../ts-styles-component';

interface Props {
  index: number;
  position: IOrderTicketPosition;
}

export const OrderSideWidget: React.FC<Props> = ({ index, position }) => {
  const theme = useTheme();
  const tsTradeOrderProps = useRecoilValue(tsTradeOrderPropsState);
  const [tsOrderTicketPositions, setTsOrderTicketPositions] = useRecoilState(tsOrdetTicketPositionState);
  const setTsTrigger = useSetRecoilState(tsTriggerState);

  const orderSides = position.orderSides;
  // const tsPositions = useRecoilValue(getAllPositionsByAccountState);
  const tsPositions = useRecoilValue(tsPositionDataState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);

  /**
   * if user owns the Long shares, TS only have Buy/Sell buttons under same account
   * if user owns the Short shares, TS only have Buy To Cover/Sell Short buttons under same acoount
   * but if user select different account that doesn't have any position, where TS have Buy/Sell Short buttons
   */
  useEffect(() => {
    const existingPosition = checkExistingPositionByAccountID(tsPositions, selectedAccountIds, position);
    let clone = cloneDeep(tsOrderTicketPositions);
    let pos = clone[index];
    if (!pos || tsOrderTicketPositions.length > 1) {
      return;
    }
    if (position.type === LegType.SECURITY) {
      if (existingPosition) {
        pos.orderSides = existingPosition.longShort === 'Short' ? ['BUY TO COVER', 'SELL SHORT'] : ['BUY', 'SELL'];
        pos.side = existingPosition.longShort === 'Short' ? 'BUY TO COVER' : 'SELL';
        pos.buyOrSell = pos.side.includes('BUY') ? BuyOrSell.BUY : BuyOrSell.SELL;
        pos.qty = (pos.side.includes('BUY') ? '' : '-') + Math.abs(Number(existingPosition.quantity)).toString();
        pos.isClose = true;
      } else {
        pos.orderSides = ['BUY', 'SELL SHORT'];
        pos.side = 'BUY';
        pos.buyOrSell = BuyOrSell.BUY;
        pos.qty = Math.abs(Number(tsTradeOrderProps[index].qty)).toString();
        pos.isClose = false;
      }
    }
    clone[index] = pos;
    setTsOrderTicketPositions(clone);
  }, [tsPositions]);

  const updateFun = (value: string) => {
    let clone = cloneDeep(tsOrderTicketPositions);
    let pos = clone[index];
    if (!pos) {
      return;
    }
    pos.side = value;
    pos.buyOrSell = value.includes('BUY') ? BuyOrSell.BUY : BuyOrSell.SELL;
    pos.isClose = checkCloseFlag(value); // TODO-fix
    pos.qty = (value.includes('BUY') ? '' : '-') + Math.abs(Number(pos.qty)).toString();
    pos.orderSides = pos.orderSides;
    // check for (sell/buy to close && sell/sellshort)
    if (pos.isClose) {
      const existingPosition = checkExistingPositionByAccountID(tsPositions, selectedAccountIds, pos);
      const underlyingSymbol = existingPosition
        ? existingPosition.underlyingSymbol
        : tsTradeOrderProps[index].underlyingSymbol;
      const tradeProps: ITradeProps = existingPosition
        ? {
            underlyingSymbol: underlyingSymbol,
            qty: existingPosition.quantity,
            symbol: existingPosition.symbol,
            accountID: existingPosition.accountID,
            longShort: existingPosition.longShort,
            action: 'trade',
            initiator: 'positions',
          }
        : tsTradeOrderProps[index];

      let newPos = buildOrderTicketPositions([tradeProps]);
      newPos[0].qty = (value.includes('BUY') ? '' : '-') + Math.abs(Number(tradeProps.qty));
      newPos[0].side = pos.side;
      newPos[0].buyOrSell = pos.buyOrSell;
      newPos[0].isClose = pos.isClose;
      newPos[0].orderSides = pos.orderSides;
      pos = newPos[0];
    }
    clone[index] = pos;
    setTsOrderTicketPositions(clone);
    setTsTrigger({ target: 'snapshot', actionRequired: true });
  };

  const renderOrderSide = () => {
    return (
      <Grid item xs={12}>
        <OPSelectField
          variant="outlined"
          //   disabled
          value={position.side}
          onChange={(event) => {
            updateFun(event.target.value as string);
          }}
          fullWidth
          sx={{
            width: '80%',
            height: 30,
            '& .MuiSvgIcon-root': {
              color: theme.palette.selectAndTextField.main,
            },
            paddingLeft: 1,
          }}>
          {orderSides.map((item) => {
            return (
              <StyledDropdownItemWithBorder color="default" key={item} value={item}>
                {item}
              </StyledDropdownItemWithBorder>
            );
          })}
        </OPSelectField>
      </Grid>
    );
  };

  return (
    <>
      <StyledTableCell>{renderOrderSide()}</StyledTableCell>
    </>
  );
};
