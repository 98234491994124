export interface ISavePortfolioRequestProps {
  // costBasisTotal: number;
  // enableExpiry: boolean;
  // enablestrikePrice: boolean;
  // expiry: string;
  // id: number;
  // isNew: boolean;
  // legType: string;
  // markedAsDeleted: boolean;
  // quantity: number;
  // strike: number;
  // symbol: string;
  // portfolioName: string;
  id: number;
  symbol: string;
  quantity: number;
  costBasisTotal: number;
  legType: string;
  expiry: string;
  strike: number;
  portfolioId: number;
  isNew: boolean;
  portfolioName: string;
}

export interface ISavePortfolioRequest {
  totalRecords: number;
  portfolioId: number;
  portfolioName: string;
  isManualSelection: boolean;
  portfolioPositions?: ISavePortfolioRequestProps[];
}

export class SavePortfolioRequest implements ISavePortfolioRequest {
  portfolioPositions = [
    {
      id: 0,
      symbol: '',
      quantity: 0,
      costBasisTotal: 0,
      legType: '',
      expiry: '',
      strike: 0,
      portfolioId: 0,
      isNew: true,
      portfolioName: '',
    },
  ];
  totalRecords = 0;
  isManualSelection = true;
  portfolioId = 0;
  portfolioName = '';

  public static fromJson = (items: SavePortfolioRequest): SavePortfolioRequest => {
    const model = new SavePortfolioRequest();
    model.totalRecords = items.totalRecords;
    model.portfolioId = items.portfolioId;
    model.portfolioName = items.portfolioName;
    model.isManualSelection = items.isManualSelection;
    model.portfolioPositions = items.portfolioPositions;
    return model;
  };
}
