import { TradeTicket, orderByAscending } from '@op/shared/src/models';
import { atom } from 'recoil';
import {
  ICommonOrderRequest,
  IConfirmOrderTicket,
  ILimitOrderRequest,
  IMarketOrderRequest,
  IStopLimitOrderRequest,
  IStopOrderRequest,
} from '../models';
import { IDuration, IRoute } from '../models/order-duration-route';
import { IOptionExpiryStrike } from '../models/ts-expiry-strike';
import { IBidMidAskProps, IOrderTicketPosition, ITradeProps } from '../models/ts-helpers';

export const tsTradeOrderPropsState = atom<ITradeProps[] | undefined>({
  key: 'tsTradeOrderPropsStatekey',
  default: undefined,
});

export const tsOrdetTicketPositionState = atom<IOrderTicketPosition[] | undefined>({
  key: 'tsOrdetTicketPositionStateKey',
  default: undefined,
});

export const tsBidMidAskValueState = atom<IBidMidAskProps | undefined>({
  key: 'tsBidMidAskValueStateKey',
  default: undefined,
});

// to contain already fetched strikes by expiry(key)
export const tsExpiryStrikeState = atom<IOptionExpiryStrike[] | undefined>({
  key: 'tsExpiryStrikeStateKey',
  default: undefined,
});

type tsTriggerPoints = 'strike' | 'snapshot' | 'initialized';
export const tsTriggerState = atom<{ target: tsTriggerPoints; actionRequired: boolean }>({
  key: 'tsTriggerStateKey',
  default: { target: 'initialized', actionRequired: false },
});

// ======================================================================

export const tsOrderPlacementState = atom({
  key: 'tsOrderPlacementStateKey',
  default: false,
});

type tradeTicketTypes = TradeTicket | undefined;

export const tsTradeTicketState = atom<tradeTicketTypes>({
  key: 'tsTradeTicketStateKey',
  default: undefined,
});

export const commonOrderRequestState = atom<ICommonOrderRequest>({
  key: 'commonOrderRequestStateKey',
  default: undefined,
});

export const marketOrderRequestState = atom<IMarketOrderRequest | undefined>({
  key: 'marketOrderRequestStateKey',
  default: undefined,
});

export const limitOrderRequestState = atom<ILimitOrderRequest | undefined>({
  key: 'limitOrderRequestStateKey',
  default: undefined,
});

export const stopOrderRequestState = atom<IStopOrderRequest | undefined>({
  key: 'stopOrderRequestStateKey',
  default: undefined,
});

export const stopLimitOrderRequestState = atom<IStopLimitOrderRequest | undefined>({
  key: 'stopLimitOrderRequestStateKey',
  default: undefined,
});

export const confirmOrderTicketState = atom<IConfirmOrderTicket | undefined>({
  key: 'confirmOrderTicketStateKey',
  default: undefined,
});

export const confirmOrderTicketRequest = atom<IConfirmOrderTicket>({
  key: 'confirmOrderTicketRequestKey',
  default: undefined,
});

export const tsDurationDataState = atom<IDuration[] | undefined>({
  key: 'tsDurationDataStateKey',
  default: undefined,
});

export const tsRoutesDataState = atom<IRoute[] | undefined>({
  key: 'tsRoutesDataStateKey',
  default: undefined,
});

export const tsOrderTicketTypeState = atom<string>({
  key: 'tsOrderTicketTypeStateKey',
  default: 'Market',
});

export const tsDualLoginErrorState = atom({
  key: 'tsDualLoginErrorStateKey',
  default: false,
});

/* ================================================ */
export const prepareRoutes = (routes: IRoute[]) => {
  const data: IRoute[] = [
    {
      id: 'Intelligent',
      assetTypes: ['STOCK', 'STOCKOPTION', 'INDEXOPTION'],
      name: 'Intelligent',
    },
  ];
  const sortedRoutes = orderByAscending(routes, 'name');
  data.push(...sortedRoutes);
  return data;
};

export const isTSTradeTicketPreserved = () => {
  const tradeTicketFromLS = JSON.parse(localStorage.getItem('tradeTicketCombination'));
  if (!tradeTicketFromLS) {
    return false;
  }
  return true;
};
