import { Grid, useTheme } from '@mui/material';
import { howWhyRanksDataState } from '@op/shared/src/states';
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import {
  IVRankWidget,
  LiquidityRatingWidget,
  SentimentLongTermWidget,
  SentimentShortTermWidget,
  TechnicalScoreWidget,
  TrendingWidget,
} from '../common';
import { HeightWidget } from '../routes';
import { GuideItem } from '../side-menu';
import { DivYieldWidget } from './components/div-yield-widget';
import { EarningsDateWidget } from './components/earnings-date-widget';
import { EpsWidget } from './components/eps-widget';
import { VolumeWidget } from './components/volume-widget';
import { WeekRangeWidget } from './components/week-range';
import { QuotebarLoaderWidget } from './quote-bar-loader-widget';
import { QuotebarSearchWidget } from './quote-bar-search-widget';

export const QuotebarStandardWidget: React.FC = () => {
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const theme = useTheme();
  const symbolWidgetHeight = 125;
  if (!howWhyRanksData.how || !howWhyRanksData.symbol) {
    return null;
  }
  return (
    <HeightWidget id="symbol-widget-content" className="quotebar-standard" height={symbolWidgetHeight} noScroll>
      <GuideItem selector=".sentiment_helpPinPlaceholder" />
      <TrendingWidget sentiment={howWhyRanksData.how.originalSentiment} symbol={howWhyRanksData.symbol || ''}>
        <Grid container>
          <GuideItem selector=".symbolInput_helpPinPlaceholder" />
          <Grid item xs={12}>
            <Suspense fallback={<QuotebarLoaderWidget />}>
              <QuotebarSearchWidget paddingLeft={0.5} iconBorder />
            </Suspense>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={4} sx={{ borderRight: `1px solid ${theme.palette.divider}`, pr: 1 }} id="quotebar-section-1">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <WeekRangeWidget />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <EpsWidget />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <DivYieldWidget />
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ borderRight: `1px solid ${theme.palette.divider}`, pl: 1, pr: 1 }}
              id="quotebar-section-2">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <LiquidityRatingWidget data={howWhyRanksData.ranks} showRatings={false} />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <IVRankWidget data={howWhyRanksData.ranks} showIVRankBar={false} />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <EarningsDateWidget />
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ pl: 1, pr: 1 }} id="quotebar-section-3">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <GuideItem selector=".termAnalysis_helpPinPlaceholder" />
                <SentimentShortTermWidget isQuoteBar symbol={howWhyRanksData.symbol} />
                <SentimentLongTermWidget isQuoteBar symbol={howWhyRanksData.symbol} />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <TechnicalScoreWidget data={howWhyRanksData.ranks} />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <VolumeWidget />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TrendingWidget>
    </HeightWidget>
  );
};
