import { IPortfolioRecommendationsEmailDelivery, PortfolioRecommendationsEmailDelivery } from '.';

export interface IApplicationPreferences {
  portfolioRecommendationsEmailDelivery: IPortfolioRecommendationsEmailDelivery;
}

export class ApplicationPreferences implements IApplicationPreferences {
  portfolioRecommendationsEmailDelivery!: IPortfolioRecommendationsEmailDelivery;

  static fromSelf = (self: IApplicationPreferences) => {
    const clone = new ApplicationPreferences();
    clone.portfolioRecommendationsEmailDelivery = PortfolioRecommendationsEmailDelivery.fromSelf(
      self.portfolioRecommendationsEmailDelivery,
    );
    return clone;
  };
}
