import { EditPortfolioResponse } from './edit-portfolio';

export class PortfolioSaveTradePosition {
  id = 0;
  name = '';
  portfolioPositions: EditPortfolioResponse[] = [];

  public static fromData = (items: PortfolioSaveTradePosition): PortfolioSaveTradePosition => {
    const model = new PortfolioSaveTradePosition();
    model.id = items.id;
    model.name = items.name;
    model.portfolioPositions = items.portfolioPositions.map((p) => EditPortfolioResponse.fromJson(p));
    return model;
  };
  static fromSelf = (data: PortfolioSaveTradePosition) => {
    const clone = new PortfolioSaveTradePosition();
    clone.id = data.id;
    clone.name = data.name;
    clone.portfolioPositions = data.portfolioPositions;
    return clone;
  };
}
