import { Grid, Paper, Skeleton } from '@mui/material';
import React from 'react';

export const TradeTicketLoaderWidget: React.FC = () => {
  return (
    <Grid container alignItems={'center'}>
      <Skeleton variant="rectangular" height={40} width={'100%'} />
      <Skeleton variant="text" width={'50%'} sx={{ margin: 1 }} />
      <Grid item xs={12}>
        <Paper sx={{ margin: 1 }}>
          <Skeleton variant="rounded" height={65} width={'100%'} />
        </Paper>
      </Grid>
      <Grid item container xs={12}>
        {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
          <Grid key={i} item xs={3} sx={{ padding: 0.5, paddingX: 1 }}>
            <Skeleton variant="rounded" height={35} />
          </Grid>
        ))}
      </Grid>
      <Grid item alignItems={'center'} container xs={12} marginY={1}>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <Skeleton variant="text" height={35} />
        </Grid>
        <Grid item xs={7} sx={{ padding: 1 }}>
          <Skeleton variant="rounded" height={35} />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <Skeleton variant="rounded" height={30} />
        </Grid>
      </Grid>
      <Grid item alignItems={'center'} container xs={12}>
        <Grid item xs={1} sx={{ padding: 1 }}>
          <Skeleton variant="text" height={30} />
        </Grid>
        <Grid item xs={11} sx={{ padding: 1 }}>
          <Skeleton variant="rounded" height={50} />
        </Grid>
      </Grid>
      <Skeleton variant="rectangular" height={50} width={'100%'} sx={{ margin: 1 }} />
      {[0, 1, 2].map((i) => (
        <Grid key={i} item container xs={12}>
          <Grid item xs={12}>
            <Skeleton variant="text" height={35} width={'25%'} sx={{ padding: 1, marginX: 1, marginTop: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" height={30} sx={{ padding: 0.5, marginX: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" height={30} width={'50%'} sx={{ padding: 0.5, marginX: 1 }} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
