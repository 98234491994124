import { Grid, Paper, Skeleton, Stack, useTheme } from '@mui/material';

export const AccountSummarySkeleton = () => {
  const theme = useTheme();
  return (
    <div>
      <Grid item container xs={12} marginBottom={1} alignItems={'center'}>
        <Grid item xs={0.5} justifyContent="flex-start">
          <Skeleton variant="rectangular" height={30} width={30} sx={{ ml: 0.5 }} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation="wave" variant="text" height={25} />
        </Grid>
      </Grid>
      <Paper
        elevation={2}
        sx={{
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: 2,
          paddingY: 2,
          ':hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
          },
          backgroundColor: theme.palette.header.default,
        }}>
        <Grid container xs={12} rowSpacing={1}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <Grid
              item
              xs={2}
              sx={{
                borderRight: `1px dotted ${theme.palette.divider}`,
              }}
              marginY={1}
              paddingX={1}
              key={item}
              alignItems={'flex-end'}>
              <Stack direction={'row'}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'45%'}
                  height={30}
                  sx={{ borderRadius: 1, mx: 1 }}
                />
              </Stack>
              <Stack alignItems={'flex-end'}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={'45%'}
                  height={30}
                  sx={{ borderRadius: 1, mx: 1 }}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
};
