import { Box, Link, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { OPDialogWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OutlinedButton } from '../styled/options-play-components';
export interface MerrillDisclimerDialogProps {
  onHandleClose: () => void;
}

export const MerrillDisclimerWidgetDialog: React.FC<MerrillDisclimerDialogProps> = (
  props: MerrillDisclimerDialogProps,
) => {
  const { onHandleClose } = props;
  const { t } = React.useContext(LocalizationContext);
  const customization = useRecoilValue(customizationState);
  const theme = useTheme();

  const renderDialogAction = () => {
    if (customization.showDisclaimerContinueButton) {
      return (
        <div style={{ justifyContent: 'center' }}>
          <OutlinedButton
            variant={theme.palette.mode === 'light' ? 'outlined' : 'contained'}
            onClick={onHandleClose}
            size="small">
            <Typography variant="button"> {t('modals.disclaimer.buttons.continue')}</Typography>
          </OutlinedButton>
        </div>
      );
    }
    return (
      <div style={{ justifyContent: 'center' }}>
        <OutlinedButton
          variant={theme.palette.mode === 'light' ? 'outlined' : 'contained'}
          onClick={onHandleClose}
          size="small">
          <Typography variant="button"> {t('modals.disclaimer.buttons.doNotAgree')}</Typography>
        </OutlinedButton>
        <OutlinedButton
          variant={theme.palette.mode === 'light' ? 'outlined' : 'contained'}
          onClick={onHandleClose}
          size="small">
          <Typography variant="button"> {t('modals.disclaimer.buttons.agree')}</Typography>
        </OutlinedButton>
      </div>
    );
  };
  const renderDialogContent = () => {
    return (
      <div>
        <Typography gutterBottom>
          IMPORTANT: The calculations or other information generated by OptionsPlay regarding the likelihood of various
          investment outcomes are hypothetical in nature, do not reflect actual investment results and are not
          guarantees of future results. The calculations and other information generated by OptionsPlay may vary with
          each use and over time.
        </Typography>
        <Typography gutterBottom>
          The calculations and other information presented in OptionsPlay do not incorporate or reflect the transaction
          costs and fees you may incur, such as commissions and contract, exercise, or assignment fees. Orders which
          involve multiple options transactions will be charged separate commissions and fees on each leg of the options
          order. Transaction costs can be significant and you should consider them when evaluating any options trade or
          strategy.
        </Typography>
        <Typography gutterBottom>
          Options involve risk and are not suitable for all investors. Certain requirements must be met to trade
          options. Before engaging in the purchase or sale of options, investors should understand the nature of and
          extent of their rights and obligations and be aware of the risks involved in investing with options. Prior to
          buying or selling an option, clients must receive the options disclosure document —
          <Link
            href="https://olui2.fs.ml.com/Publish/Content/application/pdf/GWMOL/riskstoc_supplement.pdf"
            target="_blank"
            rel="noreferrer">
            {' '}
            Characteristics and Risks of Standardized Options
          </Link>{' '}
          .Click this link or call the Investment Center at 1.877.653.4732 for a copy. A separate client agreement is
          needed to trade options.
        </Typography>

        <Typography gutterBottom>
          The calculations and other information generated by OptionsPlay are based solely on inputs that you enter into
          OptionsPlay. You are solely responsible for determining the reasonableness of the inputs that you enter into
          OptionsPlay. Neither Merrill nor OptionsPlay expresses any view on the reasonableness of any inputs you enter
          into OptionsPlay, or of any assumption that you have made to form the basis of any such inputs. As such, users
          of OptionsPlay should be aware that OptionsPlay is not designed to as a tool to assess the reasonableness,
          validity or accuracy of the user-defined inputs used for OptionsPlay. Furthermore, OptionsPlay does not
          predict or assure the performance of any investment.
        </Typography>

        <Typography gutterBottom>
          Supporting documentation for any claims (including any claims made on behalf of options programs or the
          options expertise of salespersons), comparisons, recommendations, statistics, or other technical data, will be
          supplied on request.
        </Typography>

        <Typography gutterBottom>
          OptionsPlay is a tool that is provided for educational purposes only. It provides technical analyses of
          options data based on user-defined inputs by the investor. Option strategies discussed herein do not take into
          consideration clients currently approved permissions or eligibility. Contact the investment Center for more
          information on how to obtain approval for various strategies.
        </Typography>

        <Typography gutterBottom>
          OptionsPlay calculations are based on the most recent reported price of the option or the underlying security.
          <span style={{ fontWeight: 'bold' }}>
            Commissions and middle-of-the-month assignments are not considered in any calculations.
          </span>
          Commission information is available upon request from the Merrill Edge Investment Center.
        </Typography>

        <Typography gutterBottom>
          OptionsPlay is simply a tool that can perform analytics based on the limited data that it allows you to input
          for analysis. Before using OptionsPlay, you should carefully review the meanings of the terms used by
          OptionsPlay, which is contained in the Guide provided to you. The data that OptionsPlay permits you to input
          for analysis does not capture all available scenarios relevant to you or to any transaction or strategy.
          Consequently, before transacting in any options transaction, we urge you to evaluate any data generated by
          OptionsPlay together with all other information available to you, including those contained in the options
          disclosure document —{' '}
          <Link
            href="https://olui2.fs.ml.com/Publish/Content/application/pdf/GWMOL/riskstoc_supplement.pdf"
            target="_blank"
            rel="noreferrer">
            {' '}
            Characteristics and Risks of Standardized Options
          </Link>{' '}
          — provided to you. You should not transact in any options transaction based solely on data generated by
          OptionsPlay.
        </Typography>

        <Typography gutterBottom>
          OptionsPlay cannot take into account your specific investment objectives, financial situation, risk tolerance,
          liquidity needs, investment time horizon, investment experience, or tax status. It also cannot take into
          consideration the future performance of any stock or strategy or market conditions. You should not construe
          any result, probability of any outcome, score or other data generated by OptionsPlay as a recommendation by
          Merrill or OptionsPlay to transact or not transact in any particular strategy or transaction.
        </Typography>
        <Typography gutterBottom>
          You may incur significant losses by transacting in options. Certain options strategies may be risky and/or
          speculative. You should carefully review the information contained in the options disclosure document before
          transacting in options.
        </Typography>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          For individuals that trade on Margin, please review our Margin Risks Disclosure Statement.
        </Typography>
        <Typography gutterBottom>
          When you purchase securities, you may pay for the securities in full, or if your account has been established
          as a margin account with the margin lending program, you may borrow part of the purchase price from Merrill,
          thereby leveraging your investment. If you choose to borrow funds for your purchase, Merrill&quot;s collateral
          for the loan will be the securities purchased, other assets in your margin account, and your assets in any
          other accounts at Merrill other than retirement accounts (such as IRAs). If the securities in your margin
          account decline in value, so does the value of the collateral supporting your loan, and, as a result, we can
          take action, such as to issue a margin call and/or sell securities in any of your accounts held with us, in
          order to maintain the required equity in your account. If your account has a Visa® card and/or checks, you may
          also create a margin debit if your withdrawals (by Visa card, checks, preauthorized debits, FTS or other
          transfers) exceed the sum of any available free credit balances plus available money account balances (such as
          bank deposit balances or money market funds). Please refer to your account documents for more information.
        </Typography>
        <Typography gutterBottom>
          Also note that it may be more advantageous to pay cash than to use margin for smaller securities purchases. On
          smaller securities purchases, a higher percentage of the transaction costs goes to commissions and interest
          charges, which are generally higher on smaller balances. The commissions plus interest charges could equal or
          exceed any appreciation in your securities.
        </Typography>
        <Typography gutterBottom>
          Clients investing on margin should carefully review the terms governing margin loans. The margin terms are
          contained in the Margin Lending Program Client Agreement and/or your account agreement and disclosures. It is
          important that you fully understand the risks involved in using margin. These risks including the following:
        </Typography>
        <Typography gutterBottom>
          <ul>
            <li>
              <span style={{ fontWeight: 'bold' }}>
                You can lose more funds than you deposit in the margin account.
              </span>
              A decline in the value of securities that are purchased on margin may require you to provide additional
              funds to us to avoid the forced sale of those securities or other securities or assets in your account(s).
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>
                We can force the sale of securities or other assets in your account(s).
              </span>
              If the equity in your account falls below the maintenance margin requirements or Merrill&apos;s higher
              &quot;house&quot; requirements, we can sell the securities or other assets in any of your accounts held by
              us to cover the margin deficiency. You also will be responsible for any shortfall in the account after
              such a sale.
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>
                We can sell your securities or other assets without contacting you.
              </span>
              Some investors mistakenly believe that they must be contacted for a margin call to be valid, and that
              securities or other assets in their accounts cannot be liquidated to meet the call unless they are
              contacted first. This is not the case. We will attempt to notify you of margin calls, but we are not
              required to do so. Even if we have contacted you and provided a specific date by which you can meet a
              margin call, we can still take necessary steps to protect our financial interests, including immediately
              selling the securities or other assets without notice to you.
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>
                You are not entitled to choose which securities or other assets in your account(s) are liquidated or
                sold to meet a margin call.
              </span>
              Because the securities or other assets are collateral for the margin loan, we have the right to decide
              which securities or other assets to sell in order to protect our interests.
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>
                We can increase our &quot;house&quot; maintenance margin requirements at any time, including on specific
                securities experiencing significant volatility, and are not required to provide you advance written
                notice.
              </span>
              These changes in our policy may take effect immediately and may result in the issuance of a maintenance
              margin call. Your failure to satisfy the call may cause us to liquidate or sell securities in your
              account(s).
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>You are not entitled to an extension of time on a margin call.</span>
              While an extension of time to meet margin requirements may be available to you under certain conditions,
              you do not have a right to the extension.
            </li>
          </ul>
        </Typography>
        <Typography gutterBottom>
          If you have any questions or concerns about margin and the margin lending program, please contact your Merrill
          advisor, the Merrill Advisory Center or the Merrill Investment Center.
        </Typography>
        <Typography gutterBottom>
          By clicking [&quot;I Agree&quot;] below, I acknowledge that (i) I have read the options disclosure document —
          <Link
            href="https://olui2.fs.ml.com/Publish/Content/application/pdf/GWMOL/riskstoc_supplement.pdf"
            target="_blank"
            rel="noreferrer">
            {' '}
            Characteristics and Risks of Standardized Options
          </Link>{' '}
          , (ii) I am knowledgeable about options transactions and the risks associated with transacting in options,
          (iii) none of the results, probabilities, scores, or any other data or information presented in or generated
          by OptionsPlay constitute or should be construed as a recommendation by Merrill or OptionsPlay to transact or
          not transact in any particular strategy or transaction, (iv) Merrill is not making a recommendation to me to
          transact or not to transact in any particular strategy or transaction by making the OptionsPlay tool available
          for me and is not assessing, evaluating or otherwise expressing any view on the reliability, accuracy or
          reasonableness of any input that I may enter into OptionsPlay, or any assumption on which any such input is
          based and iv) I have read and understood the Important Notice above.
        </Typography>
      </div>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Typography variant="h4" style={{ fontWeight: 600 }}>
        Important Notice
      </Typography>
    );
  };
  return (
    <Box>
      <OPDialogWidget
        open={true}
        onHandleClose={onHandleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </Box>
  );
};
