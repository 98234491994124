import { TokenRequest } from '.';

export interface ISignInModelProps {
  Login: string;
  Password: string;
  RememberMe: boolean;
  ReturnUrl: string;
  UserVoiceReturnUrl: string;
  refresh_token: string;
}

//signInModel name is required bcoz api accept that only.
export class signInModel implements ISignInModelProps {
  Login = '';
  Password = '';
  RememberMe = false;
  ReturnUrl = '';
  UserVoiceReturnUrl = '';
  refresh_token = '';

  public static fromJson(json: ISignInModelProps): signInModel {
    const model = new signInModel();
    model.Login = json.Login;
    model.Password = json.Password;
    model.RememberMe = json.RememberMe;
    model.ReturnUrl = json.ReturnUrl;
    model.UserVoiceReturnUrl = json.UserVoiceReturnUrl;
    model.refresh_token = json.refresh_token;
    return model;
  }

  toTokenRequest = (): TokenRequest => {
    const request = new TokenRequest();
    request.grant_type = 'password';
    request.password = this.Password;
    request.username = this.Login;
    return request;
  };

  toRefreshTokenRequest = (): any => {
    const request: any = {};
    request.grant_type = 'refresh_token';
    request.refreshToken = this.refresh_token;
    return request;
  };
}
