import { Grid } from '@mui/material';
import { Combination } from '@op/shared/src/models';
import React from 'react';
import { PlainEnglishOfCombinationWidget } from './plain-english-of-combination-widget';

export interface IPlainEnglishOfCombinationsProps {
  combinations: (Combination | undefined)[] | undefined;
}

export const PlainEnglishOfCombinationsWidget: React.FC<IPlainEnglishOfCombinationsProps> = ({
  combinations,
}: IPlainEnglishOfCombinationsProps) => {
  const renderPlainEnglishOfCombination = (combination: Combination | undefined, i: number) => {
    if (!combination) {
      return <></>;
    }

    return (
      <Grid item key={i} xs={4} style={i === 2 ? { paddingRight: 0 } : { paddingRight: 4 }}>
        <PlainEnglishOfCombinationWidget combination={combination} index={i} />
      </Grid>
    );
  };

  const renderPlainEnglishOfCombinations = () => {
    if (!combinations) {
      return <></>;
    }

    return combinations.map((c, i) => renderPlainEnglishOfCombination(c, i));
  };

  return (
    <Grid container id="plain-english">
      {renderPlainEnglishOfCombinations()}
    </Grid>
  );
};
