import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { tsQuoteAtomFamily } from '../states/ts-hub-states';
import { TsSpan } from './ts-styled-components';

interface Props {
  symbol: string;
}

export const LastPriceWidget: React.FC<Props> = ({ symbol }) => {
  const quote = useRecoilValue(tsQuoteAtomFamily(symbol));

  if (!symbol || !quote) {
    return <></>;
  }

  return <TsSpan>{NumberFormatHelper.toCurrency(quote.last)}</TsSpan>;
};
