import { ExpandedQuote, PortfolioPosition } from '@op/shared/src/models';
import { PortfolioCombinationEntity, PortfolioPositionEntity } from '.';
import { SentimentModel } from '../../hubs/sentiment-model';
import { PortfolioCombination } from './portfolio-combination-model';

//NOTE: In legacy code its knows as `PorfolioGrid`
export class PortfolioCombinationsOfAnAccount {
  combinations: PortfolioCombination[] = [];

  public constructor() {}
  // TODO: Remove below code which is not used.
  // static fromCombinations = (
  //   portfolioCombinations: PortfolioCombinationEntity[],
  //   quotes: ExpandedQuote[] | undefined,
  //   sentiments: SentimentModel[] | undefined,
  //   positionQuotes: ExpandedQuote[] | undefined,
  // ) => {
  //   const model = new PortfolioCombinationsOfAnAccount();
  //   const combinations = portfolioCombinations.map((c) => {
  //     return this.createCombination(c, quotes, sentiments, positionQuotes);
  //   });
  //   model.combinations = combinations.filter((c): c is PortfolioCombination => c !== undefined);
  //   return model;
  // };

  static createCombination = (
    combinationVM: PortfolioCombinationEntity,
    quotes: ExpandedQuote[] | undefined,
    sentiments: SentimentModel[] | undefined,
    positionQuotes: ExpandedQuote[] | undefined,
  ) => {
    const symbol = combinationVM.symbol.trim().toUpperCase();
    const underlyingQuote = quotes?.find((q) => q.symbol.trim().toUpperCase() === symbol);
    const syrahSentiments = sentiments?.find((s) => s.symbol?.trim().toUpperCase() === symbol);
    const positionModels = combinationVM.positions
      .map((p) => this.createPosition(p, quotes, positionQuotes))
      .filter((p): p is PortfolioPosition => p !== undefined);
    return PortfolioCombination.fromData(combinationVM, positionModels, underlyingQuote, syrahSentiments);
  };

  static createPosition = (
    positionVM: PortfolioPositionEntity,
    quotes: ExpandedQuote[] | undefined,
    positionQuotes: ExpandedQuote[] | undefined,
  ) => {
    let underlyingQuote = quotes?.find((q) => q.symbol.trim().toUpperCase() === positionVM.symbol.trim().toUpperCase());
    let positionQuote = positionQuotes?.find(
      (q) => q.symbol.trim().toUpperCase() === positionVM.quotationSymbol.trim().toUpperCase(),
    );
    /**
     * If No underlyingQuote/positionQuote  set from yesterday quote of that position
     * If No yesterday Quote then should not be created a combination.
     */
    if (!underlyingQuote || underlyingQuote == null) {
      underlyingQuote = positionVM.yesterdayQuote as ExpandedQuote;
    }
    if (!positionQuote || positionQuote == null) {
      positionQuote = positionVM.yesterdayQuote as ExpandedQuote;
    }
    if (!underlyingQuote || !positionQuote) {
      return PortfolioPosition.fromData(positionVM, new ExpandedQuote(), new ExpandedQuote());
    }
    return PortfolioPosition.fromData(positionVM, underlyingQuote, positionQuote);
  };
}
