import { IProfileDetailsProps } from './profile-interface';

export class ProfileDetails implements IProfileDetailsProps {
  displayName = '';
  firstName = '';
  lastName = '';
  status = '';
  registrationDate = '';
  email = '';
  //phone = -1;
  privacyPolicyAccepted = false;
  marketRegion = '';
  loginName = '';

  public static fromJson = (data: ProfileDetails): ProfileDetails => {
    const profile = new ProfileDetails();
    profile.displayName = data.displayName;
    profile.firstName = data.firstName;
    profile.lastName = data.lastName;
    profile.status = data.status;
    profile.registrationDate = data.registrationDate;
    profile.email = data.email;
    //profile.phone = data.phone;
    profile.privacyPolicyAccepted = data.privacyPolicyAccepted;
    profile.marketRegion = data.marketRegion;
    profile.loginName = data.loginName;
    return profile;
  };
}
