import { Button, CircularProgress, Grid, TextField, Typography, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { ApiService, PaymentsService } from '@op/shared/src/services';
import { accountState, configurationState, selectedSymbolState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent, OPDialogWidget } from '../common';
import { CircularLoading } from '../styled';

export interface TelegramShareProps {
  onHandleClose: () => void;
  title: string;
  imgsrc: string;
  symbol: string;
  sentiment: any;
  screenshotId: string;
}

export const TelegramShare: React.FC<TelegramShareProps> = (props: TelegramShareProps) => {
  const setNotifications = useSetRecoilState(notificationsState);
  const configuration = useRecoilValue(configurationState);
  const { onHandleClose, title, imgsrc, symbol, sentiment, screenshotId } = props;
  const account = useRecoilValue(accountState);
  const value = title.substring(0, title.indexOf('@'));
  const sentimentvalue = sentiment.charAt(0).toUpperCase() + sentiment.slice(1);
  const [textareaTiltle, setTextAreaTitle] = useState(`${sentimentvalue} $${symbol} Trade: ${title}`);
  // const imageContent = imgsrc.substring(imgsrc.indexOf(',') + 1);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [loaded, setLoaded] = React.useState(false);
  const [emailItems, setEmailItems] = React.useState<string[]>([]);
  const [email, setEmail] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  const [loader, setLoader] = React.useState(false);
  const apiEndPoint = ApiService.instance.API_ENDPOINT;
  const theme = useTheme();
  useEffect(() => {
    if (!configuration || !configuration.sharingEmail) {
      return;
    }
    setEmail(configuration.sharingEmail);
  }, []);

  const handelInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    setError('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      if (!email) {
        return;
      }
      if (!emailPattern.test(email)) {
        setError('Email address is invalid');
        return;
      }

      if (emailItems.includes(email)) {
        setError(`${email} has already been added.`);
        return;
      }
      setEmailItems([...emailItems, email]);
      setEmail('');
    }
  };

  const handleDelete = (chipToDelete: string) => () => {
    setEmailItems((emailItems) => emailItems.filter((chip) => chip !== chipToDelete));
  };

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Submit,
      selectedSymbol as string,
    );
  };

  const handleSubmit = async () => {
    if (email !== '') {
      if (!emailPattern.test(email as string)) {
        setError('Email address is invalid');
        logActivity('FORM', 'submitShareEmailForm', emailItems);
        return;
      }
      emailItems.push(email as string);
    }
    if (emailItems.length === 0) {
      setNotifications([{ type: 'error', content: 'Email is Required' }]);
    } else {
      if (!account || !account.securityModel) {
        return;
      }
      if (screenshotId.length <= 0) {
        return;
      }
      logActivity('FORM', 'submitShareEmailForm', emailItems);
      setLoader(true);
      const emailSubject = `${account.securityModel?.displayName} shared a ${symbol} trade with you`;
      const email = {
        subject: emailSubject,
        message: textareaTiltle,
        // TODO : Create Common share genration link function
        sharingLink: `${window.location.origin}/share/${screenshotId}`,
        screenshotUrl: `${apiEndPoint}/sharing/screenshot/${screenshotId}`,
        emails: emailItems,
      };
      const response = await PaymentsService.instance.sendEmailMessage(email);
      setNotifications([{ type: 'success', content: 'Email has been sent' }]);
      setEmail('');
      onHandleClose();
      setLoader(false);
    }
  };

  const canDisableEmailField = () => {
    if (configuration && !configuration?.sharingEmail) {
      return false;
    }
    return true;
  };

  const renderEmailChip = () => {
    if (configuration && configuration?.sharingEmail && configuration.sharingEmail.length > 0) {
      return;
    }
    return emailItems.map((data: string) => {
      return <Chip key={data} label={data} onDelete={handleDelete(data)} />;
    });
  };

  const onChangeTextArea = (value: string) => {
    setTextAreaTitle(value);
  };
  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item>
          <Button autoFocus onClick={handleSubmit} variant="contained" color="primary">
            <Typography variant="button">Send Email </Typography>
            {loader && <CircularLoading style={{ width: 20, height: 20 }} />}
          </Button>
        </Grid>
      </Grid>
    );
  };
  const renderDialogContent = () => {
    return (
      <div>
        {loaded ? null : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        )}
        <div style={loaded ? {} : { display: 'none' }}>
          <img src={imgsrc} width="100%" height="100%" onLoad={() => setLoaded(true)} alt="" />
          <Grid container direction="row">
            <Grid item xs={12}>
              {renderEmailChip()}
              <br /> <br />
              <TextField
                fullWidth
                id="email"
                disabled={canDisableEmailField()}
                placeholder="Type email address and press enter"
                variant="outlined"
                value={email}
                label="Email Address"
                name="email"
                onChange={handelInputChange}
                onKeyDown={handleKeyDown}
              />
              {error && <span style={{ color: 'tomato' }}>{error}</span>}
              <br />
              <br />
              <TextField
                fullWidth
                id="outlined-multiline-static"
                multiline
                rows={5}
                onChange={(e) => onChangeTextArea(e.target.value)}
                defaultValue={textareaTiltle}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
  const renderDialogTitle = () => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={1} md={1}>
          <IconComponent
            name="telegramIcon"
            style={{ position: 'absolute', left: 8, top: 16 }}
            stroke={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1">Share : {value}</Typography>
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <OPDialogWidget
        open={true}
        onHandleClose={onHandleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
      />
    </div>
  );
};
