// @ts-nocheck

/* NOTE: VERY IMPORTANT- DO NOT ORGANIZE this file. ChartIQ expectes in this order only.*/
import React from 'react';

import { WhyEntity } from '@op/shared/src/models';
import { getCustomConfig } from './resources'; // ChartIQ library resources

import { initOpStudies } from './studies';
import { addSupportAndResistance, addTradeIdeaMarker } from './support-resistance-marker';

// Import necessary ChartIQ library files
import { CIQ } from 'chartiq/js/advanced';
import 'chartiq/js/components';
import 'chartiq/js/componentUI';
import 'chartiq/js/addOns';
import PerfectScrollbar from 'chartiq/js/thirdparty/perfect-scrollbar.esm.js';

import { AdvancedTemplate } from './template-advanced';

// Base styles required by the library to render color correctly.
import 'chartiq/css/normalize.css';
import 'chartiq/css/stx-chart.css'; // Chart API
import 'chartiq/css/chartiq.css'; // Chart UI
import './library-overrides.css';
/* <!-- Legacy Edge support for webcomponents --> */
/* <script src="js/thirdparty/custom-elements.min.js"></script>  */

export { CIQ };

export interface ICoreProps {
  children?: any;
  symbol: string;
  whyData: WhyEntity;
  panelHeight: number | null;
  theme: string;
  showSRLine: boolean;
  enableCiqPreferences: boolean;
}

export interface ICoreState {
  isReady: boolean;
  stx: CIQ.ChartEngine | null;
  UIContext: CIQ.UI.Context | null;
}

export default class Core extends React.Component<ICoreProps, ICoreState> {
  container: any;
  masterData: any;
  symbol: string;
  config: any;
  configRef: any;

  getMasterData = (data: WhyEntity) => {
    const result = data.historicalQuotes.map((item) => {
      return {
        Date: item.date,
        Open: item.open,
        High: item.high,
        Low: item.low,
        Close: item.close,
        Volume: item.volume,
        syrahSentimentLongTerm: data.syrahSentimentLongTerm.find((f) => f.date === item.date),
        syrahSentimentShortTerm: data.syrahSentimentShortTerm.find((f) => f.date === item.date),
      };
    });
    return {
      quotes: result,
      last: result[result.length - 1]?.Close,
    };
  };

  constructor(props: ICoreProps) {
    super(props);
    const { symbol, theme, enableCiqPreferences } = props;
    this.container = React.createRef();
    const resources = {
      // markerSample: marker.MarkersSample,
      scrollStyle: PerfectScrollbar,
      enableCiqPreferences: enableCiqPreferences,
    };
    this.config = getCustomConfig({ resources });
    this.config.themes.defaultTheme = theme;
    const data = this.getMasterData(this.props.whyData);
    this.masterData = data.quotes;
    this.symbol = symbol;
    this.state = {
      isReady: false,
      stx: null,
      UIContext: null,
    };
    this.configRef = React.createRef(false);
  }

  componentDidMount() {
    if (this.configRef.current || this.configRef.current !== null) {
      return;
    }
    this.configRef.current = true;
    const container = this.container.current;
    const { whyData, panelHeight, showSRLine } = this.props;
    const { config } = this;
    portalizeContextDialogs(container);
    // Delay the call to createChartAndUI so any other chart components on the page
    // using multi chart setup have a chance to call portalizeContextDialogs
    window.setTimeout(() => {
      const uiContext = new CIQ.UI.Chart().createChartAndUI({ container, config });
      // @ts-ignore
      const chartEngine: CIQ.ChartEngine = uiContext?.stx;
      chartEngine.layout.crosshair = true;
      // As per request allowScrollFuture made as true to enable future dates in chart.
      chartEngine.chart.allowScrollFuture = true;
      chartEngine.chart.allowScrollPast = false;
      //NOTE: ChartIQ declaration files have these props as `object`. Hence ts-ignore become necessary.
      //@ts-ignore
      chartEngine.controls.chartControls.style.display = 'none';
      //@ts-ignore
      chartEngine.controls.chartControls = null;
      // @ts-ignore
      CIQ.UI.Layout.prototype.setOpRange = function (node, month: '1M' | '3M' | '6M') {
        const param = {
          multiplier: 6,
          base: 'month',
          padding: 0,
          periodicity: {
            period: 1,
            interval: 5,
            timeUnit: 'minute',
          },
        };
        switch (month) {
          case '1M':
            param.multiplier = 4;
            param.base = 'week';
            chartEngine.setSpan(param, () => {});
            break;
          case '3M':
            param.multiplier = 3;
            chartEngine.setSpan(param, () => {});
            break;
          case '6M':
          default:
            chartEngine.setSpan(param, () => {});
        }
      };
      initOpStudies(chartEngine, panelHeight);
      if (this.masterData) {
        chartEngine.loadChart(this.symbol, this.masterData);
      }
      //last entry of masterdata
      const lastTick = this.masterData.slice(-1)[0];
      addTradeIdeaMarker(chartEngine, lastTick, whyData.tradeIdeas);
      if (showSRLine) {
        addSupportAndResistance(chartEngine, whyData.supportAndResistance);
      }
      this.setState({ isReady: true, stx: chartEngine, UIContext: uiContext });
    }, 0);
  }
  componentWillUnmount() {
    // Destroy the ChartEngine instance when unloading the component.
    // This will stop internal processes such as quotefeed polling.
    // window.removeEventListener('showSR', this.handleStorage);
    const { stx, UIContext } = this.state;
    if (!stx || !UIContext || !this.configRef.curent) {
      return;
    }
    stx.destroy();
    stx.draw = () => {};
    this.configRef.current = false;
  }

  render() {
    return <cq-context ref={this.container}>{this.props.children || <AdvancedTemplate />}</cq-context>;
  }
}

/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container: any) {
  container.querySelectorAll('cq-dialog').forEach((dialog: any) => {
    dialog.remove();
    if (!dialogPortalized(dialog)) {
      document.body.appendChild(dialog);
    }
  });
}

function dialogPortalized(el: any) {
  const tag = el.firstChild.nodeName.toLowerCase();
  let result = Array.from(document.querySelectorAll(tag)).some((el) => !el.closest('cq-context'));
  return result;
}
