import { getThemeType } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { accessTokenKey, accountState, selectedSymbolState, themeState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tsAuthenticationStatusState } from './states/trade-station-states';

export const TSLogOutWidget = () => {
  const tsLogOutRoute = useMatch('/profile/tradestation/signout');
  const navigate = useNavigate();
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setAccount = useSetRecoilState(accountState);
  const setActiveTheme = useSetRecoilState(themeState);
  const tsAuthenticationStatus = useRecoilValue(tsAuthenticationStatusState);

  useEffect(() => {
    if (!tsLogOutRoute) {
      return;
    }
    if (!tsAuthenticationStatus) {
      navigate('/', { replace: true });
      return;
    }
    const getTsLogoutValue = window.localStorage.getItem('opLogoutFromTS');
    const canExecuteLogoutFromOP = getTsLogoutValue && getTsLogoutValue !== null ? JSON.parse(getTsLogoutValue) : false;
    const redirectUrl = window.localStorage.getItem('tsRedirectURL');
    if (!canExecuteLogoutFromOP) {
      setNotifications([
        {
          type: 'success',
          content: 'TradeStation Log out successfull.',
          isTradeStation: true,
        },
      ]);
      navigate(redirectUrl ? redirectUrl : '/', { replace: true });
      return;
    }
    logoutOP();
  }, []);

  const setDefaultTheme = () => {
    const theme = getThemeType('light');
    setActiveTheme(theme);
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.SignOut,
      selectedSymbol as string,
    );
  };

  const logoutOP = () => {
    logActivity('BUTTON', 'signOutButton');
    localStorage.removeItem('tradeTicketCombination');
    localStorage.removeItem('lightbox_state');
    localStorage.removeItem('additionalData');
    localStorage.removeItem('opLogoutFromTS');
    localStorage.removeItem(accessTokenKey);
    setAccount(undefined);
    setDefaultTheme();
    navigate('/login', { replace: true });
    window.location.reload();
  };

  return null;
};
