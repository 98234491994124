import {
  BuyOrSell,
  ClosePositionType,
  LegType,
  PriceCalculationMethod,
  RollPositionType,
} from '@op/shared/src/models/enums/enums';
import { ICallOptimal, ILeg, Leg, Position, StrategyHelpers } from '.';
import BasicCombination from './basic-combination';
import helpers from './helpers';
import HowDataModel from './how-data-model';
import NumberFormatHelper from './number-format-helper';

export interface IOptions {
  defaultDaysToFindExpiry?: number;
  defaultCombination?: ICallOptimal;
  alternativeCombination?: ICallOptimal;
}

export class Combination extends BasicCombination {
  lastHigherChanged: boolean = false;
  lastHighersChanged: boolean = false;
  // Positions
  positions: Position[] = [];
  options: IOptions | undefined;
  sentimentProfile = '';
  sentiments: string[] = []; //This was filled in sentiments function. Make it _setiment and init function.

  protected constructor() {
    super();
  }

  static fromLegs = (
    legs: Leg[] | undefined,
    howData: HowDataModel,
    options?: IOptions,
    priceCalucationMethod?: PriceCalculationMethod,
  ) => {
    const combination = new Combination();
    combination.fromCombinationContext(legs, howData, priceCalucationMethod);
    combination.options = options;
    combination.initialize(legs);
    return combination;
  };

  static fromSelf(self: Combination) {
    const clone = new Combination();
    clone.fromSelf(self);
    clone.positions = self.positions.map((p) => Position.fromSelf(p));
    clone.options = self.options;
    clone.predictions = self.predictions;
    clone.stdDev = self.stdDev;
    clone.priceCalculationMethod = self.priceCalculationMethod;
    clone.isRoll = self.isRoll;
    clone.implementation = self.implementation;
    clone.lastHighersChanged = self.lastHighersChanged;
    clone.lastHigherChanged = self.lastHigherChanged;
    clone.combinationType = self.combinationType;
    clone.sentiments = self.sentiments.map((s) => s);
    clone.sentimentProfile = self.sentimentProfile;
    return clone;
  }

  protected initialize = (legs: Leg[] | undefined) => {
    this.initPositions(legs);
    this.profileSentiments();
  };

  protected initPositions = (originalLegs: Leg[] | undefined) => {
    if (!originalLegs || !originalLegs.length) {
      this.positions = [];
      return;
    }
    let positions = originalLegs.map((leg) => {
      return Position.fromLeg(leg, this.chain, this.quote, this.optionType, this.priceCalculationMethod, false);
    });
    this.positions = positions;
  };

  //Changed to init function.
  public profileSentiments = () => {
    const lastPrice = this.quote.last;
    const perihelion = this.payoff(0.1);
    const aphelion = this.payoff(this.highSpotBound());
    const breakevens = this.breakevens();
    let focus = breakevens.length === 2 ? this.payoff((breakevens[0] + breakevens[1]) / 2, this.expiration()) : 0;
    focus = breakevens.length === 4 ? this.payoff((breakevens[1] + breakevens[2]) / 2, this.expiration()) : focus;
    const expiry = this.expiration();
    const sds = this.stdDev.getStdDevsByExpiry(expiry);

    if (breakevens.length === 2 && perihelion > 1 && aphelion > 1) {
      this.sentiments.push('sharp-move');
      if (Math.min(...breakevens) >= lastPrice) {
        this.sentimentProfile = 'bearish sharp-move';
      } else if (Math.max.apply(this, breakevens) <= lastPrice) {
        this.sentimentProfile = 'bullish sharp-move';
      } else {
        this.sentimentProfile = 'sharp move';
      }
    } else if (perihelion >= 1 && aphelion < 1) {
      this.sentiments.push('bearish');
      if (!sds) {
        this.sentimentProfile = 'bearish';
      } else {
        if (lastPrice - breakevens[0] <= (lastPrice - sds[2]) * 0.75) {
          this.sentimentProfile = 'bearish';
        } else {
          this.sentimentProfile = 'very bearish';
        }
      }
    } else if (perihelion < 1 && aphelion >= 1) {
      this.sentiments.push('bullish');
      if (!sds) {
        this.sentimentProfile = 'bullish';
      } else {
        if (breakevens[0] - lastPrice <= (sds[4] - lastPrice) * 0.75) {
          this.sentimentProfile = 'bullish';
        } else {
          this.sentimentProfile = 'very bullish';
        }
      }
    } else if (focus >= 1) {
      this.sentiments.push('neutral');
      if (Math.min(...breakevens) >= lastPrice) {
        this.sentimentProfile = 'bullish neutral';
      } else if (Math.max(...breakevens) <= lastPrice) {
        this.sentimentProfile = 'bearish neutral';
      } else {
        this.sentimentProfile = 'neutral';
      }
    }
  };

  addPositionByLeg = (leg: ILeg) => {
    return StrategyHelpers.addPositionByLeg(this, leg);
  };

  addPosition = (
    buyOrSell: BuyOrSell | undefined,
    quantity: number,
    type: string,
    expiry?: any,
    strikePrice?: any,
    costBasis?: any,
  ) => {
    return StrategyHelpers.addPosition(this, buyOrSell, quantity, type, expiry, strikePrice, costBasis);
  };

  replacePositionByLeg = (leg: ILeg, index: number) => {
    return StrategyHelpers.replacePositionByLeg(this, leg, index);
  };

  replacePosition = (newLeg: ILeg, index: number) => {
    return StrategyHelpers.replacePosition(this, newLeg, index);
  };

  canUpdatePosition = (newLeg: ILeg) => {
    return StrategyHelpers.canUpdatePosition(this, newLeg);
  };

  removePosition = (position: Position, index: number) => {
    StrategyHelpers.removePosition(this, position, index);
  };

  setAbsQuantity = (newVal: number) => {
    StrategyHelpers.setAbsQuantity(this, newVal);
  };

  width = () => {
    return StrategyHelpers.width(this);
  };

  canCustomizeWidth = () => {
    return StrategyHelpers.canCustomizeWidth(this);
  };

  canShrinkWidth = () => {
    return StrategyHelpers.canShrinkWidth(this);
  };

  canExpandWidth = () => {
    return StrategyHelpers.canExpandWidth(this);
  };

  wingspanLeft = () => {
    return StrategyHelpers.wingspanLeft(this);
  };

  wingspanRight = () => {
    return StrategyHelpers.wingspanRight(this);
  };

  canCustomizeWingspan = () => {
    return StrategyHelpers.canCustomizeWingspan(this);
  };

  canShrinkWingspan = () => {
    return StrategyHelpers.canShrinkWingspan(this);
  };

  canExpandWingspan = () => {
    return StrategyHelpers.canExpandWingspan(this);
  };

  breakevenDisplayed = () => {
    return this.breakevens()
      .map((value) => NumberFormatHelper.toCurrency(value))
      .join(', ');
  };

  probabilityOfProfitDisplayed = () => {
    let probability = this.probabilityOfProfit();
    if (probability === undefined || !helpers.isNumber(probability)) {
      return '--';
    }
    return NumberFormatHelper.toPercentage(probability * 100);
  };

  canTradeStrategy = () => {
    return this.quote.isTradeable || !this.hasStock();
  };

  invertedCost = () => {
    return NumberFormatHelper.toCurrency(this.cost() * -1);
  };

  isClosedPosition = () => {
    return this.absQuantity() === 0;
  };

  private getFullExpiryList = () => {
    if (!this.chain) {
      return null;
    }
    return this.chain.getFullExpiryList(this.optionType);
  };

  getFullStrikeList = (expiry: Date | undefined) => {
    if (!this.chain) {
      return null;
    }
    return this.chain.getStrikeListForExpiry(expiry, this.optionType);
  };

  isClosePosition = (pos: Position, checkPartiallyClosed: boolean = false) => {
    var result = checkPartiallyClosed
      ? pos.closePositionType != null
      : pos.closePositionType === ClosePositionType.FULL_CLOSE;
    return result;
  };

  private getRollTypeByExpiryAndStrikeDiffs(expiryDiff: number, strikeDiff: number) {
    if (expiryDiff <= 0) {
      return null;
    }
    if (strikeDiff < 0) {
      return RollPositionType.ROLLING_DOWN_OUT;
    }
    if (strikeDiff > 0) {
      return RollPositionType.ROLLING_UP_OUT;
    }

    return RollPositionType.ROLLING_OUT;
  }

  getRollType = () => {
    const closePositionLegs = [];
    const openPositionLegs = [];
    for (let position of this.positions) {
      if (position.isSecurityType()) {
        continue;
      }
      if (this.isClosePosition(position)) {
        closePositionLegs.push(position);
      } else {
        openPositionLegs.push(position);
      }
    }

    if (closePositionLegs.length !== openPositionLegs.length || openPositionLegs.length === 0) {
      return null;
    }

    function customPositionsSortingPredicate(p1: Position, p2: Position) {
      let result: any;
      if (p1.legType === LegType.SECURITY) {
        return -1;
      }
      if (p2.legType === LegType.SECURITY) {
        return 1;
      }
      if (p1.legType !== p2.legType) {
        return p1.legType === LegType.CALL ? -1 : 1;
      }
      if (p1 && p2 && p1.strike && p2.strike) {
        result = p1.strike - p2.strike;
      }
      if (!result && p1.expiry && p2.expiry) {
        result = p1.expiry.getTime() - p2.expiry.getTime();
      }
      return result;
    }

    closePositionLegs.sort(customPositionsSortingPredicate);
    openPositionLegs.sort(customPositionsSortingPredicate);

    if (!this.chain) {
      return null;
    }
    const expiryList = this.getFullExpiryList();
    let expiryDiff: number | null = null;
    let strkeDiff: number | null = null;
    for (var j = 0; j < closePositionLegs.length; j++) {
      var closePos = closePositionLegs[j];
      var openPos = openPositionLegs[j];
      if (closePos.legType !== openPos.legType || closePos.isEquivalentPosition(openPos)) {
        return null;
      }
      var closePosExpiry = expiryList?.findIndex((e) => closePos.expiry && e === closePos.expiry) || 0; //helpers.findIndex(expiryList, closePos.expiry);
      var openPosExpiryIndex = expiryList?.findIndex((e) => openPos.expiry && e === openPos.expiry) || 0; //helpers.findIndex(expiryList, openPos.expiry);
      var currentExpiryDiff = openPosExpiryIndex - closePosExpiry;
      if (currentExpiryDiff <= 0 || (expiryDiff != null && expiryDiff !== currentExpiryDiff)) {
        return null;
      }
      if (!openPos.strike || !closePos.strike) {
        throw new Error('strike prices undefined');
      }
      var currentStrikeDiff = Math.sign(openPos.strike - closePos.strike);
      if (strkeDiff != null && currentStrikeDiff !== 0 && currentStrikeDiff !== strkeDiff) {
        return null;
      }

      expiryDiff = currentExpiryDiff;
      strkeDiff = currentStrikeDiff === 0 ? null : currentStrikeDiff;
    }
    return this.getRollTypeByExpiryAndStrikeDiffs(expiryDiff || -1, strkeDiff || 0);
  };

  canRoll = (expiryDiff: number, strikeDiff: number, allowRollingIn: boolean) => {
    let buyablePositions = this.buyablePositions;
    if (buyablePositions.length === 1 && buyablePositions[0].type === LegType.SECURITY) {
      return false;
    }

    var expiries = this.getFullExpiryList();
    if (expiries === null) {
      return false;
    }
    let expiryList = expiries as Date[];
    let unableToRoll = this.positions
      .filter((p) => {
        return !this.isClosePosition(p);
      })
      .some((pos) => {
        if (pos.isSecurityType()) {
          return false;
        }
        if (expiryDiff) {
          var nextExpiryIndex = expiryList.findIndex((e) => pos.expiry && e === pos.expiry) + expiryDiff; //helpers.findIndex(expiryList, pos.expiry) + expiryDiff;
          if (expiryList && nextExpiryIndex >= expiryList.length) {
            return true;
          }
          if (allowRollingIn) {
            return nextExpiryIndex < 0;
          }
          var result = expiryList[nextExpiryIndex].valueOf() <= (this.expiration() as Date).valueOf();
          return result;
        }
        if (strikeDiff) {
          if (!pos.strike) {
            throw new Error('strike price is undefined');
          }
          var strikeList = this.getFullStrikeList(pos.expiry as Date);
          if (strikeList) {
            const idx = strikeList.findIndex((s) => s === pos.strike);
            const nextStrkeIndex = idx + strikeDiff;
            return nextStrkeIndex >= strikeList.length || nextStrkeIndex < 0;
          }
        }
        return false;
      });
    return !unableToRoll;
  };

  //overwritten from basic-combination.
  get buyablePositions() {
    return this.positions.filter((position) => {
      return !position.isOwned();
    });
  }

  // get canAddPositions() {
  //   if (this.positions.length >= 4) {
  //     return false;
  //   }
  //   return true;
  // }

  canAddPositions(maximumLegs: number) {
    return this.positions.length < maximumLegs;
  }

  get merrillcanAddPositions() {
    if (this.positions.length >= 2) {
      return false;
    }
    return true;
  }
}

export default Combination;
