import { ICollection } from '..';
import { CachedSecurity } from './cached-security';
import Fuse from 'fuse.js';

export class CachedSecurities implements ICollection<CachedSecurity> {
  data: CachedSecurity[] = [];

  private static fuzzySearchInstance: Fuse<CachedSecurity>;

  private constructor() {}

  static fromData = (items: CachedSecurity[]) => {
    const model = new CachedSecurities();
    model.data = items ? items : [];
    CachedSecurities.fuzzySearchInstance = new Fuse(items, {
      shouldSort: true,
      threshold: 0.1,
      location: 0,
      distance: 100,
      includeScore: true,
      keys: ['symbol', 'name'],
    });
    return model;
  };

  static fuzzySearch = (query: string) => {
    const result = CachedSecurities.fuzzySearchInstance.search(query);
    return result.slice(0, 20).map((i) => i.item);
  };
}
