import { ICollection } from '..';

export class AlertFilter {
  value = '';
  text = '';
  isSelected = true;

  static fromSelf = (data: AlertFilter): AlertFilter => {
    const scanAlert = new AlertFilter();
    scanAlert.isSelected = true;
    scanAlert.text = data.text;
    scanAlert.value = data.value;
    return scanAlert;
  };

  static fromText = (text: string): AlertFilter => {
    const scanAlert = new AlertFilter();
    scanAlert.isSelected = true;
    scanAlert.text = text;
    scanAlert.value = text;
    return scanAlert;
  };

  static empty = (): AlertFilter => {
    return new AlertFilter();
  };

  isEmpty = (): boolean => {
    return this.value === '';
  };
}

export class AlertFilters implements ICollection<AlertFilter> {
  data: AlertFilter[] = [];

  public constructor(scans: AlertFilter[]) {
    this.data = [...scans];
  }

  public get isEmpty(): boolean {
    return this.data.length === 0;
  }
}
