import { Table, TableBody, TableContainer, TableHead, useTheme } from '@mui/material';
import { useWindowSize } from '../../states/use-window-size';
import { ScrollableBox } from '../../styled';

export const TradeStationTable = ({ tableHead, tableBody }) => {
  const windowSize = useWindowSize();
  const theme = useTheme();
  return (
    <TableContainer>
      <ScrollableBox
        sx={{
          maxHeight: windowSize?.height * 0.45,
          backgroundColor: theme.palette.mode === 'light' ? '#F7F7F7' : '#1f262b',
        }}>
        <Table
          stickyHeader
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0px 5px',
            paddingX: '2px',
          }}>
          <TableHead sx={{ boxShadow: 2, marginBottom: 8 }}>{tableHead}</TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </ScrollableBox>
    </TableContainer>
  );
};
