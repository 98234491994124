import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { PortfolioAlert } from '@op/shared/src/hubs/portfolio-alert';
import { IData, PortfolioCombination, PortfolioCombinationEntity } from '@op/shared/src/models';
import { AlertType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { PortfolioAlertModel } from '@op/shared/src/models/portfolio/portfolio-alert-model';
import { Recommendation } from '@op/shared/src/models/portfolio/recommendation-model';
import {
  accessTokenKey,
  accountState,
  customizationState,
  fetchHowData,
  fetchRankData,
  fetchWhyData,
  howWhyRanksDataState,
  isDataLoadingState,
  isSelectedRecommendedStateOpenState,
  portfolioManagementSelectedCombinationState,
  portfolioSelectedCombinationState,
  portfolioSelectedEntityState,
  portfolioSelectedFilterSummaryState,
  selectedSymbolState,
  subViewState,
  tradeSimulatorOperationTypeState,
  viewState,
} from '@op/shared/src/states';
import { configurationPortfolioAlertResourcesState } from '@op/shared/src/states/configuration/configuration-states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import {
  portfolioAlertsByAccountAndCombinationState,
  portfolioCombinationAlertOrNotificationSelectedState,
} from '@op/shared/src/states/portfolio-alerts-hub-states';
import { selectedRecommendationState } from '@op/shared/src/states/portfolio-management-states';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPLightTypograpghy } from '../styled';

export interface IPortfolioAlertDetailsWidgetProps extends IData<PortfolioCombination> {
  entity: PortfolioCombinationEntity;
}

export const PortfolioAlertDetailsWidget: React.FC<IPortfolioAlertDetailsWidgetProps> = ({
  data,
  entity,
}: IPortfolioAlertDetailsWidgetProps) => {
  // TODO: get this hook
  //const fetchAndSetData = useFetchAndSetData();
  const combination = data;
  const customization = useRecoilValue(customizationState);
  const alerts = useRecoilValue(portfolioAlertsByAccountAndCombinationState(combination.hash));
  const portfolioCombinationSelectedAlertIcon = useRecoilValue(portfolioCombinationAlertOrNotificationSelectedState);
  const portfolioAlertResources = useRecoilValue(configurationPortfolioAlertResourcesState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const selectedPortfolioFilters = useRecoilValue(portfolioSelectedFilterSummaryState);
  const portfolioSelectedCombination = useRecoilValue(portfolioSelectedCombinationState);
  const setHowWhyRanksData = useSetRecoilState(howWhyRanksDataState);
  const resetAccount = useResetRecoilState(accountState);
  const [selectedRecommendation, setSelectedRecommendation] = useRecoilState(selectedRecommendationState);
  const setportfolioSelectedEntity = useSetRecoilState(portfolioSelectedEntityState);
  const resetPortfolioManagementSelectedCombination = useResetRecoilState(portfolioManagementSelectedCombinationState);
  const resetPortfolioSelectedEntity = useResetRecoilState(portfolioSelectedCombinationState);
  const setIsDataLoading = useSetRecoilState(isDataLoadingState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const resetPortfolioManagementSelectedEntity = useResetRecoilState(portfolioSelectedEntityState);
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const setIsSelectedRecommendedStateOpen = useSetRecoilState(isSelectedRecommendedStateOpenState);
  /**
   *
   * We have a alert for every combination
   * This function returns the particular selected alert
   */
  const isAlertSelected = () => {
    return portfolioCombinationSelectedAlertIcon && portfolioCombinationSelectedAlertIcon.alertType === AlertType.ALERT;
  };

  if (
    !alerts ||
    alerts.length === 0 ||
    !portfolioAlertResources ||
    (!isAlertSelected() && selectedPortfolioFilters.selectedAlertsLength === 0)
    // || !(selectedPortfolioFilters.alerts > 0 && isAlertSelected())
  ) {
    return null;
  }

  /**
   * Below implementation for UI to show the alert has been selected
   */
  const getVariantBySelectedRecommendation = (title: string, alert: PortfolioAlert) => {
    if (
      title === selectedRecommendation?.alertTitle &&
      alert.portfolioCombinationHash === portfolioSelectedCombination?.hash
    ) {
      return 'contained';
    }
    return 'outlined';
  };

  const renderAlerts = () => {
    return alerts.map((alert, index) => {      
      const title = PortfolioAlertModel.getTitle(alert, portfolioAlertResources, combination, t) || '';
      return (
        <Button
          key={index}
          color={'success'}
          variant={getVariantBySelectedRecommendation(title, alert)}
          style={{
            marginRight: 8,
            padding: '4px 6px',
          }}
          onClick={title === '' ? undefined : () => onAlertRecommendationClick(alert)}>
          <OPLightTypograpghy>{title}</OPLightTypograpghy>
        </Button>
      );
    });
  };

  const onAlertRecommendationClick = async (alert: PortfolioAlert) => {
    const alertResource = portfolioAlertResources.find((r) => r.key.trim().toUpperCase() === alert.key.toUpperCase());
    if (!alertResource) {
      throw new Error('data not available');
    }
    const symbol = entity.symbol.trim().toUpperCase();
    setIsDataLoading(true);
    const [fetchedHowData, fetchedWhyData, fetchedRanksData] = await Promise.all([
      fetchHowData(symbol, undefined),
      fetchWhyData(symbol, undefined),
      fetchRankData(symbol),
    ]);
    if (
      fetchedHowData.hasErrors ||
      fetchedWhyData.hasErrors ||
      fetchedRanksData.hasErrors ||
      !fetchedHowData.data ||
      !fetchedWhyData.data ||
      !fetchedRanksData.data
    ) {
      if (fetchedHowData.errorCode === 403) {
        if (customization?.isEmbeddingPlatform) {
          setNotifications([{ type: 'error', content: `Unexpected error has occurred, please reload the page.` }]);
          setIsDataLoading(false);
          return;
        }
        localStorage.removeItem('lightbox_state');
        localStorage.removeItem(accessTokenKey);
        resetAccount();
        navigate('/login', { replace: true });
        window.location.reload();
      } else {
        setNotifications([
          { type: 'error', content: `Quote not found.Switching back to ${selectedSymbol?.toUpperCase()}` },
        ]);
      }
      setIsDataLoading(false);
      return;
    }
    const howData = fetchedHowData.data.howData;
    const legs: any = PortfolioAlertModel.getAlertLegs(alert, howData, combination);
    const recommendation = Recommendation.fromAlert(alert, alertResource, legs);
    setHowWhyRanksData({
      symbol: symbol,
      how: howData,
      why: fetchedWhyData.data,
      ranks: fetchedRanksData.data,
    });
    resetPortfolioManagementSelectedCombination();
    resetPortfolioManagementSelectedEntity();
    resetPortfolioSelectedEntity();
    setSubView(undefined);
    setView('portfolioManagement');
    setSimulatorOperation(tradeSimulatorOperationType.PLSimulator);
    setportfolioSelectedEntity(entity);
    setSelectedRecommendation(recommendation);
    setIsSelectedRecommendedStateOpen(false);
    setIsDataLoading(false);
  };

  return (
    <Grid container py={1}>
      <Grid item xs="auto">
        <Typography pl={2} pr={1.5} pt={1.5} sx={{ visibility: 'hidden' }}>
          <FontAwesomeIcon size="lg" icon={faExclamationTriangle} />
        </Typography>
      </Grid>
      <Grid item xs container pl={1} alignItems="center">
        <Typography variant="body1" fontWeight="bold" pr={1}>
          Alerts
        </Typography>
        {renderAlerts()}
      </Grid>
    </Grid>
  );
};
