import { Grid, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { buildOrderTicketPositions, getLegsForOrderRequest } from '../models/ts-helpers';
import {
  tsDockState,
  tsOrderTicketState,
  tsSelectedAccountState,
  tsSelectedTabState,
} from '../states/trade-station-states';
import {
  commonOrderRequestState,
  confirmOrderTicketState,
  limitOrderRequestState,
  marketOrderRequestState,
  stopLimitOrderRequestState,
  stopOrderRequestState,
  tsOrderPlacementState,
  tsOrderTicketTypeState,
  tsOrdetTicketPositionState,
  tsTradeOrderPropsState,
} from '../states/ts-ticket-states';
import { LimitOrderTicketWidget } from './limit-order-ticket-widget';
import { MarketOrderTicketWidget } from './market-order-ticket-widget';
import { OrderConfirmationWidget } from './order-confirmation-widget';
import { OrderTicketPositionsWidget } from './order-ticket-position-widget';
import { OrderTicketTable } from './order-ticket-table';
import { StopLimitOrderTicketWidget } from './stop-limit-order-ticket-widget';
import { StopOrderTicketWidget } from './stop-order-ticket-widget';
import { TSOrderType } from '@op/shared/src/models/enums/enums';

export const OrderTicketWidget: React.FC = () => {
  const selectedAccount = useRecoilValue(tsSelectedAccountState);
  const marketOrderRequest = useRecoilValue(marketOrderRequestState);
  const limitOrderRequest = useRecoilValue(limitOrderRequestState);
  const stopOrderRequest = useRecoilValue(stopOrderRequestState);
  const stopLimitOrderRequest = useRecoilValue(stopLimitOrderRequestState);
  const [confirmOrderTicketData, setConfirmOrderTicketData] = useRecoilState(confirmOrderTicketState);
  const setTSSelectedTab = useSetRecoilState(tsSelectedTabState);
  const [showDock, setShowDock] = useRecoilState(tsDockState);
  const setTrade = useSetRecoilState(tsOrderPlacementState);
  // const [orderType, setOrderType] = useState('Market');
  const [orderType, setOrderType] = useRecoilState(tsOrderTicketTypeState);
  const resetCommonOrderRequest = useResetRecoilState(commonOrderRequestState);
  const tsOrderProps = useRecoilValue(tsTradeOrderPropsState);
  const [tsOrderTicketPositions, setTsOrderTicketPositions] = useRecoilState(tsOrdetTicketPositionState);
  const tsOrderTicketValue = useRecoilValue(tsOrderTicketState);
  const setNotifications = useSetRecoilState(notificationsState);

  const theme = useTheme();
  useEffect(() => {
    if (!tsOrderProps) {
      return;
    }
    // if no legs, remove the orderTicket tab & close the dock
    if (tsOrderProps && tsOrderProps.length === 0) {
      setTrade(false);
      setTSSelectedTab('positions');
      setShowDock(!showDock);
      return;
    }
    // if (tsOrderProps[0].action === 'trade') {
    //   setConfirmOrderTicketData(undefined);
    // }
    if (!tsOrderTicketPositions) {
      const orderTicketPositions = buildOrderTicketPositions(tsOrderProps);
      setTsOrderTicketPositions(orderTicketPositions);
      tsOrderProps[0].action === 'trade' && resetCommonOrderRequest(); // resetting common order request on initial load while trade
      tsOrderProps[0].action === 'trade' ? setOrderType('Limit') : setOrderType('Market'); // on trade -> limit by default, on close -> market by default
    }
    // setOrderType('Market');
  }, [tsOrderProps]);

  if (!tsOrderTicketPositions || tsOrderTicketPositions.length === 0) {
    return <></>;
  }

  if (!selectedAccount || selectedAccount.length === 0) {
    setNotifications([{ type: 'error', content: 'No Account Found', isTradeStation: true }]);
    setTrade(false);
    setTSSelectedTab('positions');
    setShowDock(!showDock);
    return;
  }

  const getOrderRequestByType = () => {
    if (orderType === TSOrderType.Market) {
      return marketOrderRequest;
    }
    if (orderType === TSOrderType.Limit) {
      return limitOrderRequest;
    }
    if (orderType === TSOrderType.StopMarket) {
      return stopOrderRequest;
    }
    if (orderType === TSOrderType.StopLimit) {
      return stopLimitOrderRequest;
    }
    return;
  };

  const renderOrdetTicketByType = () => {
    if (orderType === TSOrderType.Market) {
      return (
        <MarketOrderTicketWidget
          setOrderType={(v) => setOrderType(v)}
          orderLegs={getLegsForOrderRequest(tsOrderTicketPositions)}
        />
      );
    }
    if (orderType === TSOrderType.Limit) {
      return (
        <LimitOrderTicketWidget
          setOrderType={(v) => setOrderType(v)}
          orderLegs={getLegsForOrderRequest(tsOrderTicketPositions)}
        />
      );
    }
    if (orderType === TSOrderType.StopMarket) {
      return (
        <StopOrderTicketWidget
          setOrderType={(v) => setOrderType(v)}
          orderLegs={getLegsForOrderRequest(tsOrderTicketPositions)}
        />
      );
    }
    if (orderType === TSOrderType.StopLimit) {
      return (
        <StopLimitOrderTicketWidget
          setOrderType={(v) => setOrderType(v)}
          orderLegs={getLegsForOrderRequest(tsOrderTicketPositions)}
        />
      );
    }
    return;
  };

  const renderOrderTicket = () => {
    if (tsOrderProps.length > 0 && tsOrderTicketValue === 'default' && tsOrderProps[0].action === 'trade') {
      return (
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
              paddingX: 1,
              marginTop: 0.5,
              paddingY: 0.2,
            }}>
            <Grid item xs={12}>
              {renderOrdetTicketByType()}
            </Grid>
            <Grid item xs={12}>
              <OrderTicketTable />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OrderTicketPositionsWidget />
          </Grid>
        </Grid>
      );
    }
    return (
      <OrderConfirmationWidget
        tsOrderTicketPositions={tsOrderTicketPositions}
        orderRequest={getOrderRequestByType()}
        confirmOrderTicketData={confirmOrderTicketData}
        setConfirmOrderTicketData={setConfirmOrderTicketData}
      />
    );
  };

  return (
    <Grid container rowSpacing={1}>
      {renderOrderTicket()}
    </Grid>
  );
};
