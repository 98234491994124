import { IBillingHistoryResponse } from '../models/subscription/billing-history';
import {
  IAddSubscriptionRequest,
  IAddSubscriptionResponse,
  ICancelSubscriptionFeatureRequest,
  IMakePaymentResponse,
  ISubscriptionPlanPricesModel,
  IUserSubscriptionResponseFeatures,
} from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';

export const getSubscriptionPricingModels = async (): Promise<ResponseViewModel<ISubscriptionPlanPricesModel>> => {
  const url = '/alacarte/getPricingModel';
  const response = await ApiService.instance.get<ISubscriptionPlanPricesModel>(url);
  const vm = new ResponseViewModel<ISubscriptionPlanPricesModel>();
  vm.data = response.data as unknown as ISubscriptionPlanPricesModel;
  vm.errors = response.errors;
  return vm;
};

export const getUserSubscriptions = async (): Promise<ResponseViewModel<IUserSubscriptionResponseFeatures>> => {
  const url = '/alacarte/getUserSubscriptions';
  const response = await ApiService.instance.get<IUserSubscriptionResponseFeatures>(url);
  const vm = new ResponseViewModel<IUserSubscriptionResponseFeatures>();
  // vm.data = UserFeatureSubscription as IUserSubscriptionResponseFeatures;
  vm.data = response.data as unknown as IUserSubscriptionResponseFeatures;
  vm.errors = response.errors;
  return vm;
};

export const addModernSubscription = async (
  request: IAddSubscriptionRequest,
): Promise<ResponseViewModel<IMakePaymentResponse | null>> => {
  const url = 'payments/addModernSubscription';
  const response = await ApiService.instance.put<any, IMakePaymentResponse>(url, request);
  const vm = new ResponseViewModel<IMakePaymentResponse>();
  vm.data = response.data as unknown as IMakePaymentResponse;
  vm.errors = response.errors;
  return vm;
};

export const addModernSubscriptionV2 = async (
  request: IAddSubscriptionRequest,
): Promise<ResponseViewModel<IAddSubscriptionResponse | null>> => {
  const url = 'payments/addModernSubscriptionV2';
  const response = await ApiService.instance.put<any, IAddSubscriptionResponse>(url, request);
  const vm = new ResponseViewModel<IAddSubscriptionResponse>();
  vm.data = response.data as unknown as IAddSubscriptionResponse;
  vm.errors = response.errors;
  return vm;
};

export const cancelSubscriptionFeature = async (
  request: ICancelSubscriptionFeatureRequest,
): Promise<ResponseViewModel<IMakePaymentResponse | null>> => {
  const url = 'payments/cancelSubscriptionFeature';
  const response = await ApiService.instance.post<any, IMakePaymentResponse>(url, request);
  const vm = new ResponseViewModel<IMakePaymentResponse>();
  vm.data = response.data as unknown as IMakePaymentResponse;
  vm.errors = response.errors;
  return vm;
};

export const getBillingHistory = async (): Promise<ResponseViewModel<IBillingHistoryResponse>> => {
  const url = 'payments/getTransactionsV2';
  const response = await ApiService.instance.get<IBillingHistoryResponse>(url);
  const vm = new ResponseViewModel<IBillingHistoryResponse>();
  vm.data = response.data as unknown as IBillingHistoryResponse;
  vm.errors = response.errors;
  return vm;
};
