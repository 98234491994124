import { Grid, Typography, useTheme } from '@mui/material';
import { IBuilderData, IncomeBuilder, IncomeStrategies } from '@op/shared/src/models';
import { EventType, LegType, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import formatting from '@op/shared/src/models/how/formatting';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import {
  configurationState,
  howDataState,
  incomeCombinationSelectedIdState,
  incomeStrategyHasShareState,
  isDataLoadingState,
  previousSelectedSymbolState,
  selectedSymbolState,
  strategyModifyState,
  tradeSimulatorOperationTypeState,
  tradingRangeSimulatorState,
  viewState,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import {
  costBasisState,
  incomeCombinationAtomFamilyState,
  incomeCombinationUpdaterState,
  incomeStrategiesState,
  resetIncomeState,
  sharesState,
} from '@op/shared/src/states/how/income-how-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';
import {
  OptionsPlayCurrencyNonNegativeFormatInput,
  OptionsPlayNonDecimalNonNegativeNumberFormatInput,
  OptionsPlaySwitch,
  OptionsPlayTextField,
} from '../styled';
import { IncomeLoaderWidget } from './income-loader-widget';

export const IncomeControlsWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  //TODO: costBasis and shares recoil no required now.
  const [cb, setCostBasis] = useRecoilState(costBasisState);
  const [share, setShares] = useRecoilState(sharesState);
  const [hasShares, setHasShares] = useRecoilState(incomeStrategyHasShareState);
  const view = useRecoilValue(viewState);
  const [incomeCombinationSelectedId, setIncomeCombinationSelectedId] = useRecoilState(
    incomeCombinationSelectedIdState,
  );
  const [combination, setCombination] = useRecoilState(
    incomeCombinationAtomFamilyState(incomeCombinationSelectedId.toString()),
  );
  const setIncomeCombinations = useSetRecoilState(incomeCombinationUpdaterState);
  const setIncomeStrategies = useSetRecoilState<IncomeStrategies | undefined>(incomeStrategiesState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorState);
  const setWhatIfSimulator = useSetRecoilState(whatIfSimulatorState);
  const setPreviousSelectedSymbol = useSetRecoilState(previousSelectedSymbolState('income'));
  const resetStrategyModify = useResetRecoilState(strategyModifyState);
  const [sharesInputValue, setSharesInputValue] = useState<string | undefined>();
  const [sharesValue, setSharesValue] = useState<number>(0);
  const [costBasisInputValue, setCostBasisInputValue] = useState<string | undefined>();
  const [costBasisValue, setCostBasisValue] = useState<number>(0);
  const { t } = React.useContext(LocalizationContext);
  const [isDataLoading, setIsDataLoading] = useRecoilState(isDataLoadingState);
  const configuration = useRecoilValue(configurationState);
  const [isChanged, setIsChanged] = useState(false);
  const [resetIncome, setResetIncome] = useRecoilState(resetIncomeState);
  const theme = useTheme();

  const userActivitySymbol = combination ? combination.symbol : selectedSymbol;

  const getShares = (value?: number, inputText?: string) => {
    if (!howData || !configuration) {
      throw new Error('configuration is undefined');
    }
    if (value && value > 0) {
      if (configuration.divideIndexQuantityByHundred && formatting.checkIndices(howData.quote.exchange)) {
        value *= 100;
      }
      //for covered call, min number of shares is 100.
      //so for 10 shares, 100 should be passed from income strategy generation.
      const newValue = Math.max(100, value);
      return { shares: newValue, sharesText: inputText };
    }
    const shares = 100;
    /*
     * for Index symbols, share value is multiple of 100, however textbox is not.
     * Multiple of 100 is required to keep it `Covered call`,
     */
    let sharesInput = shares;
    if (configuration.divideIndexQuantityByHundred && formatting.checkIndices(howData.quote.exchange)) {
      sharesInput = shares / 100;
    }
    return { shares: shares, sharesText: `${sharesInput}` };
  };

  const getCost = (value?: number, inputText?: string) => {
    if (value && value > 0) {
      return { cost: value, costText: inputText };
    }
    if (!howData) {
      throw new Error('configuration is undefined');
    }
    const cost = howData.quote.ask !== 0 ? howData.quote.ask : howData.quote.last;
    return { cost: cost, costText: `${cost}` };
  };

  //TODO: Check for share < 100
  const initialize = () => {
    if (!howData || !configuration) {
      return;
    }
    const costBasis = howData.quote.ask !== 0 ? howData.quote.ask : howData.quote.last;
    const { shares, sharesText: input } = getShares();
    const strategies = IncomeStrategies.fromData(howData, shares, costBasis);
    const sentiment = strategies.sentiment;
    if (!sentiment) {
      throw new Error('sentiment  is undefined');
    }
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, strategies.Combinations);
    const whatIfSimulator = WhatIfSimulator.fromData(howData, sentiment, tradingRangeSimulator);
    setSharesValue(shares);
    setSharesInputValue(input);
    setShares(shares);
    setCostBasisValue(costBasis);
    setCostBasisInputValue(costBasis?.toFixed(2));
    setCostBasis(costBasis);
    setIncomeStrategies(strategies);
    setIncomeCombinations(strategies.Combinations);
    setTradingRangeSimulator(tradingRangeSimulator);
    setWhatIfSimulator(whatIfSimulator);
    setPreviousSelectedSymbol(howData.symbol);
    if (configuration.divideIndexQuantityByHundred && formatting.checkIndices(howData.quote.exchange)) {
      setIncomeCombinationSelectedId(1);
      setHasShares(1);
    } else {
      setIncomeCombinationSelectedId(0);
      setHasShares(0);
    }
    setResetIncome({ reason: 'completed', shouldReset: false });
    setIsDataLoading(false);
  };

  const updateStrategies = () => {
    if (!howData || !configuration || !combination) {
      return;
    }
    if (!isChanged) {
      return;
    }
    let { shares, sharesText } = getShares(sharesValue, sharesInputValue);
    let { cost, costText } = getCost(costBasisValue, costBasisInputValue);
    setShares(shares);
    setSharesValue(shares);
    setSharesInputValue(sharesText);
    setCostBasisValue(cost);
    setCostBasisInputValue(costText);
    setIsChanged(false);

    const sharesRounded = NumberFormatHelper.roundDownToMultiplier(shares, 100);
    const call = combination.positions.find((l) => l.legType === LegType.CALL);
    if (!call) {
      return;
    }
    const leg: any = {
      legType: call.legType,
      strike: call.strike,
      expiry: call.expiry,
    };
    const strategy = new IncomeBuilder(howData as IBuilderData)
      .withShares(sharesRounded)
      .withCostBasisPerUnit(cost)
      .assembleCall(leg);
    setCombination(strategy);
  };

  useEffect(() => {
    if (!howData || !configuration) {
      return;
    }
    const { shares, sharesText } = getShares(share, (share || '').toString());
    let { cost, costText } = getCost(cb, (cb || '').toString());
    setSharesValue(shares);
    setSharesInputValue(sharesText);
    setCostBasisValue(cost);
    setCostBasisInputValue(costText);
    if (!resetIncome.shouldReset) {
      return;
    }
    initialize();
  }, [howData, configuration]);

  if (view === 'trade' || !configuration || !howData || isDataLoading) {
    return <IncomeLoaderWidget />;
  }

  const onChangeOfHasShares = (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasShares) {
      setHasShares(0);
      setIncomeCombinationSelectedId(0);
      ApplicationContext.userActivityHub?.logActivity(
        'BUTTON',
        'hasShares',
        'yes',
        EventType.Click,
        userActivitySymbol,
      );
    } else {
      setHasShares(1);
      setIncomeCombinationSelectedId(1);
      ApplicationContext.userActivityHub?.logActivity('BUTTON', 'hasShares', 'no', EventType.Click, userActivitySymbol);
    }
    resetStrategyModify();
    setSimulatorOperation(tradeSimulatorOperationType.OptionsGrid);
  };

  const onKeyPressOfCostBasis = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER' || costBasisInputValue === undefined) {
      return;
    }
    updateStrategies();
    ApplicationContext.userActivityHub?.logActivity(
      'costBasis',
      'costBasis',
      costBasisValue,
      'change',
      userActivitySymbol,
    );
  };

  const onBlurOfCostBasis = () => {
    ApplicationContext.userActivityHub?.logActivity(
      'costBasis',
      'costBasis',
      costBasisValue,
      'change',
      userActivitySymbol,
    );
    updateStrategies();
  };

  const onKeyPressOfShares = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    updateStrategies();
    ApplicationContext.userActivityHub?.logActivity(
      'numberOfShares',
      'numberOfShares',
      sharesValue,
      'change',
      userActivitySymbol,
    );
  };

  const onBlurOfShares = () => {
    updateStrategies();
    ApplicationContext.userActivityHub?.logActivity(
      'numberOfShares',
      'numberOfShares',
      sharesValue,
      'change',
      userActivitySymbol,
    );
  };

  const canSwitchDisable = () => {
    if (configuration.divideIndexQuantityByHundred && formatting.checkIndices(howData.quote.exchange)) {
      return true;
    }
    return false;
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <GuideItem selector=".ownedSharesPrompt_helpPinPlaceholder" />
      <Grid item container xs={6.5} alignItems="center">
        <Grid item xs="auto">
          <Typography variant="body1" component="span">
            {t('how.incomeStrategies.labels.ownSharesOf')}
          </Typography>
          <Typography variant="body1" component="span" fontWeight="bold" sx={{ pr: 1 }}>
            {` ${formatting.symbolDotExchangeToSymbol(howData.symbol)}`}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Grid item container alignItems="center">
            <Grid item>
              <Typography variant="body1">{t('common.buttons.yes')}</Typography>
            </Grid>
            <Grid item>
              <OptionsPlaySwitch
                checked={hasShares === 1}
                disabled={canSwitchDisable()}
                onChange={onChangeOfHasShares}
                name="callChecked"
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t('common.buttons.no')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={5.5} columnSpacing={1}>
        {hasShares === 0 && (
          <>
            <Grid item xs={6}>
              <OptionsPlayTextField
                id="no-shares"
                label={t('how.incomeStrategies.labels.numberOfShares')}
                variant="outlined"
                size="small"
                value={sharesInputValue || ''}
                InputProps={{
                  inputComponent: OptionsPlayNonDecimalNonNegativeNumberFormatInput as any,
                }}
                InputLabelProps={{
                  style: { color: `${theme.palette.text.secondary}` },
                }}
                onChange={(event: any) => {
                  setIsChanged(true);
                  setSharesInputValue(event.values.value);
                  setSharesValue(event.values.floatValue);
                }}
                onBlur={onBlurOfShares}
                onKeyPress={(event: any) => onKeyPressOfShares(event.key)}
              />
            </Grid>
            <Grid item xs={6}>
              <OptionsPlayTextField
                id="cost-basis"
                label={t('common.labels.costBasis')}
                variant="outlined"
                InputProps={{
                  inputComponent: OptionsPlayCurrencyNonNegativeFormatInput as any,
                }}
                InputLabelProps={{
                  style: { color: `${theme.palette.text.secondary}` },
                }}
                size="small"
                value={costBasisInputValue || ''}
                onChange={(event: any) => {
                  setIsChanged(true);
                  setCostBasisInputValue(event.values.value);
                  setCostBasisValue(event.values.floatValue);
                }}
                onBlur={onBlurOfCostBasis}
                onKeyPress={(event) => onKeyPressOfCostBasis(event.key)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
