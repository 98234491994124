import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Account as AccountModel, ProfileDetails, VerifyCredentialsModel } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { AuthenticationService, ProfileDetailsService, getSecurityModel } from '@op/shared/src/services';
import {
  accountState,
  fetchProfile,
  isDeleteRequested,
  profileCachedState,
  selectedSubscriptionState,
  selectedSymbolState,
  subscriptionCurrentPaymentState,
  subscriptionPlanPricesModelState,
  userSubscriptionFeaturesDataState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { AccordianWidget } from '../common/accordian-widget';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';

export const DeleteAccountWidget = () => {
  const { t } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useRecoilState<AccountModel | undefined>(accountState);
  const [profile, setProfile] = useRecoilState(profileCachedState);
  const deleteRequested = useRecoilValue(isDeleteRequested);
  const setNotifications = useSetRecoilState(notificationsState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  //reset
  const resetSelectedSubscription = useResetRecoilState(selectedSubscriptionState);
  const resetPaymentDetails = useResetRecoilState(subscriptionCurrentPaymentState);
  const resetPricingModels = useResetRecoilState(subscriptionPlanPricesModelState);
  const resetUserSubscriptionFeaturesData = useResetRecoilState(userSubscriptionFeaturesDataState);
  const resetUserSubscriptionFeatures = useResetRecoilState(userSubscriptionFeaturesState);

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === 'email') {
      // setEmail(value);
      return;
    }
    setPassword(value);
  };

  const handleClickOpen = () => {
    logActivity('BUTTON', 'deleteAccountRequestButton');
    setOpen(true);
  };

  const handleClose = () => {
    logActivity('BUTTON', 'deleteAccountRequestCancelButton');
    setOpen(false);
  };

  const onDeleteRequest = async () => {
    logActivity('BUTTON', 'deleteAccountRequestConfirmButton');
    setLoading(true);
    const response = await ProfileDetailsService.instance.DeleteAccount();
    setLoading(false);
    if (response.hasErrors || !response.data) {
      setNotifications([{ type: 'error', content: 'Deletion Request Failed' }]);
      return;
    }
    await refreshSecurityModel();
    setOpen(false);
    setPassword('');
    setNotifications([{ type: 'success', content: 'Deletion Request Successful' }]);
  };

  /*
    NOTE: Reset Selected Subscription + Payment Details + PricingModel + UserSubscription
  */
  const resetState = () => {
    resetSelectedSubscription();
    resetPaymentDetails();
    resetPricingModels();
    resetUserSubscriptionFeaturesData();
    resetUserSubscriptionFeatures();
  };

  const onCancelRequest = async () => {
    setLoading(true);
    const response = await ProfileDetailsService.instance.CancelDeletion();
    setLoading(false);
    if (response.hasErrors || !response.data) {
      setNotifications([{ type: 'error', content: 'Cancel Deletion Request Failed' }]);
      return;
    }
    await refreshSecurityModel();
    setNotifications([{ type: 'success', content: 'Cancel Deletion Request Successful' }]);
  };

  const debouncedOnDeleteRequest = debounce(onDeleteRequest, 500);

  const debouncedOnCancelRequest = debounce(onCancelRequest, 500);

  const verifyUserCredentials = useCallback(async () => {
    if (password === '') {
      setNotifications([{ type: 'error', content: 'Please enter valid password to proceed' }]);
      return;
    }
    const request = new VerifyCredentialsModel();
    request.login = email;
    request.password = password;
    const response = await AuthenticationService.instance.verifyCredentials(request);
    if (response.data === false) {
      setNotifications([{ type: 'error', content: 'Invalid User Credentials. Deletion Request Failed' }]);
      return;
    }
    setNotifications([{ type: 'success', content: 'User Credentials Verified.' }]);
    debouncedOnDeleteRequest();
  }, [email, password, setNotifications, debouncedOnDeleteRequest]);

  const debouncedVerifyCredentials = debounce(verifyUserCredentials, 500);

  useEffect(() => {
    if (profile && profile.email) {
      updateEmail(profile);
      return;
    }
    (async () => {
      const profile = await fetchProfile();
      if (profile) {
        updateEmail(profile);
        setProfile(profile);
      }
    })();
  }, [profile, setProfile]);

  const updateEmail = (profile: ProfileDetails) => {
    const { email } = profile;
    setEmail(email);
  };

  const refreshSecurityModel = async () => {
    const token = ApplicationContext.accessToken;
    if (token === null || token.trim() === '') {
      return;
    }
    const accountSecurityModel = await getSecurityModel(token);
    if (!accountSecurityModel) {
      return;
    }
    setAccount(accountSecurityModel);
    resetState();
  };

  const renderCircularProgress = () => {
    return (
      <CircularProgress
        sx={{
          color: '#FFFFFF',
          marginLeft: 1,
        }}
        size={20}
      />
    );
  };

  const renderDetails = () => {
    if (!account) {
      return null;
    }
    if (deleteRequested) {
      const { deleteRequestDate, deleteDate } = account.securityModel;
      return (
        <>
          <Box mt={2}>
            <Typography variant="body1" fontWeight={'600'}>
              {t('profile.changePassword.titles.accountDeletionPending')}
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="body1">
              {t('profile.changePassword.descriptions.deleteRequest.on')}
              <span style={{ fontWeight: '600' }}>
                {DateTime.fromJSDate(new Date(deleteRequestDate)).toFormat('dd LLL yyyy')}
              </span>
              {t('profile.changePassword.descriptions.deleteRequest.deleteRequestedText')}
              <span style={{ fontWeight: '600' }}>
                {DateTime.fromJSDate(new Date(deleteDate)).toFormat('dd LLL yyyy')}
              </span>
              .
            </Typography>
          </Box>
          <Box my={2} display={'flex'} justifyContent={'flex-end'}>
            <Button
              onClick={debouncedOnCancelRequest}
              color="success"
              size="large"
              variant="contained"
              disabled={loading}>
              <OPBoldTypograpghy variant="button">
                {t('profile.changePassword.buttons.cancelDeletion')}
              </OPBoldTypograpghy>
              {loading && renderCircularProgress()}
            </Button>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box>
          <Typography variant="body1" fontWeight="600">
            {t('profile.changePassword.descriptions.deleteRequest.canIDelete')}
          </Typography>
          <Typography variant="body1">
            {t('profile.changePassword.descriptions.deleteRequest.youAreInControl')}{' '}
            <span style={{ fontWeight: '600' }}>
              {t('profile.changePassword.descriptions.deleteRequest.permanentlyDelete')}
            </span>
          </Typography>
        </Box>
        <Box my={2}>
          <Typography variant="body1">
            {t('profile.changePassword.descriptions.deleteRequest.deletingIsPermanent')}{' '}
            <span style={{ fontWeight: '600' }}>
              {t('profile.changePassword.descriptions.deleteRequest.deletion90Days')}
            </span>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            <span style={{ fontWeight: '600' }}>
              {t('profile.changePassword.descriptions.deleteRequest.cancelAutoRenew')}
            </span>{' '}
            {t('profile.changePassword.descriptions.deleteRequest.noSubRestoration')}
          </Typography>
        </Box>
        <Box my={2} display={'flex'} justifyContent={'flex-end'}>
          <Button onClick={handleClickOpen} color="error" size="large" variant="contained" disabled={loading}>
            <OPBoldTypograpghy variant="button">
              {t('profile.changePassword.buttons.deleteAccountRequest')}
            </OPBoldTypograpghy>
          </Button>
        </Box>
      </>
    );
  };

  const renderAlertDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle fontSize={16} fontWeight={'bold'}>
          {t('profile.changePassword.titles.confirmAccountDeletion')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="subtitle2" pt={2}>
            <span style={{ fontWeight: '600' }}>
              {t('profile.changePassword.descriptions.deleteRequest.areYouSure')}
            </span>{' '}
            {t('profile.changePassword.descriptions.deleteRequest.noRefunds')}
          </DialogContentText>
          <Grid item xs={12} container justifyContent="center" textAlign="center" rowSpacing={1} mt={2}>
            <Grid item xs={12}>
              <TextField
                onChange={onChange}
                value={email}
                name="email"
                label={t('common.inputs.email')}
                variant="outlined"
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <TextField
                onChange={onChange}
                value={password}
                type="password"
                name="password"
                label={t('common.inputs.password')}
                variant="outlined"
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: 20, paddingBottom: 20 }}>
          <Button onClick={handleClose} color="primary" size="large" variant="outlined" disabled={loading}>
            <OPBoldTypograpghy variant="button">{t('common.buttons.cancel')}</OPBoldTypograpghy>
          </Button>
          <Button
            onClick={debouncedVerifyCredentials}
            color="error"
            size="large"
            variant="contained"
            disabled={loading}
            autoFocus>
            <OPBoldTypograpghy variant="button">
              {t('profile.changePassword.buttons.deleteAccountRequest')}
            </OPBoldTypograpghy>
            {loading && renderCircularProgress()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AccordianWidget
        initialExpand={true}
        noExpansionClose={true}
        title={t('profile.changePassword.labels.privacy')}
        summary={renderDetails()}
      />
      {renderAlertDialog()}
    </>
  );
};
