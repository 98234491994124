import lodash from 'lodash';
import { DateTime } from 'luxon';
import { isNordic, isTmx, isUS } from '../languge-type';
import ApplicationContext from './application-context';
import helpers from './helpers';
//TODO: use loadash, luxon and other utilities to simpliy these helpers.

export const cloneDeep = <T>(value: T) => {
  return lodash.cloneDeep(value);
};

//The function defination is same coming from lodash
export const uniqueBy = <T>(array: Array<T> | null | undefined, iteratee: (value: T) => unknown): T[] => {
  return lodash.uniqBy(array, iteratee);
};

export const uniqueWith = <T>(array: Array<T> | null | undefined): T[] => {
  return lodash.uniqWith(array, lodash.isEqual);
};

class formatting {
  static readonly DEFAULT_DATE_FORMAT = 'T dd yyyy';
  static readonly EXPIRY_KEY_DATE_FORMAT = 'yyyyMMdd';
  static readonly EXPIRY_DATE_FORMAT = 'YYMMDD';
  static readonly DEFAULT_LIMIT_VALUE_FOR_FRACTIONS = 9999;
  static readonly MARKET_TIME_ZONE = 'EST5EDT';
  static readonly OTHER_THAN_NORDIC = false;
  static readonly NOT_AVAILABLE = 'N/A';

  // converts e.g. 9575.7857 → 9,575.785,7
  static convertToNumberWithCommas = (number: number | string): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // converts e.g. 9575.7857 → 9,576
  private static toIntString = (num: number) => {
    const round = formatting.roundNumber(num, 0);
    return formatting.convertToNumberWithCommas(round);
  };

  // converts e.g. 9575.7857 → 9,576.79
  static toFractionalString = (
    num?: any,
    numberOfFractionalDigits?: number | null | undefined,
    language?: string,
    type?: string,
  ) => {
    if (!numberOfFractionalDigits) {
      numberOfFractionalDigits = 2;
    }
    if (formatting.OTHER_THAN_NORDIC) {
      language = 'fr-FR';
    }
    const number = formatting.roundNumber(num, numberOfFractionalDigits).toFixed(numberOfFractionalDigits);
    if (type == 'peRatio' || type == 'eps' || type == 'beta' || type == 'div' || type == 'volume') {
      return formatting.resolveCurrencyWithoutSymbol(number, language);
    }
    return number;
  };

  static toSignedFractionalString = (num: number | null, signed: boolean, numberOfFractionalDigits: number) => {
    let sign = '';
    if (!num) return;
    if (signed) {
      if (num > 0) {
        sign = '+';
      } else if (num < 0) {
        sign = '-';
      } else {
        sign = '';
      }
    }
    const number = sign + formatting.toFractionalString(Math.abs(num), numberOfFractionalDigits);
    return number;
  };

  /*
   * to return flag and country according to the exchange symbol
   */
  static formatFlagAccToExchange = (exchange: string) => {
    if (exchange.length > 4) {
      if (exchange.includes('IND')) {
        exchange = exchange.replace('IND', '');
      }
    }
    let country = 'en-US';
    switch (exchange) {
      case 'XCSE':
        country = 'Denmark';
        break;
      case 'XHEL':
        country = 'Finland';
        break;
      case 'XSTO':
        country = 'Sweden';
        break;
      case 'CSTE':
        country = 'Canada';
        break;
      case 'XTSE':
        country = 'Canada';
        break;
      default:
        country = 'en-US';
        break;
    }
    return country;
  };

  // converts e.g. 9575.7857 → $9,576
  static toIntCurrency = (num: number) => {
    const sign = num < 0 ? '-' : '';
    const number = sign + formatting.toIntString(Math.abs(num));
    return number;
  };

  // converts e.g. 9575.7857 → $9,575.79
  static toFractionalCurrency = (
    num: number | null,
    numberOfFractionalDigits?: any,
    showCurrencySymbolForNumbers?: any,
  ) => {
    if (num == null) return '';
    const sign = num < 0 ? '-' : '';
    const number = `${sign}${formatting.toFractionalString(Math.abs(num), numberOfFractionalDigits)}`;
    return number;
  };

  // converts e.g. 9575.7857 → $9,575.79
  static toFractionalCurrency1 = (num: string | number | undefined | null, numberOfFractionalDigits?: number) => {
    if (num === null || num === undefined) {
      return '';
    }
    const number = lodash.toNumber(num);
    const sign = (num as number) < 0 ? '-' : '';
    return `${sign}${formatting.toFractionalString(Math.abs(number), numberOfFractionalDigits)}`;
  };

  static toIntFractionalCurrency = (
    num: number | null,
    numberOfFractionalDigits: any,
    showCurrencySymbolForNumbers: boolean = false,
  ) => {
    if (!num) return '';
    const mod = Math.abs(num) % 1;
    const result =
      mod < 0.01 || mod > 0.99
        ? formatting.toIntCurrency(num)
        : formatting.toFractionalCurrency(num, numberOfFractionalDigits, showCurrencySymbolForNumbers);
    return result;
  };

  /**
   * @param {Number} num number to format
   * @param {Number} limit below which (by absolute value) number will be converted as fractional currency.
   * If number is greater than limit, it will be converted as integer currency.
   *
   * @example
   * // returns → $9,575.79
   * toLimitedFractionalCurrency(9575.7857, 9999)
   *
   * // returns → $99,575
   * toLimitedFractionalCurrency(99575.7857, 9999)
   */
  static toLimitedFractionalCurrency = (
    num: number | null,
    limitValue: number = 2,
    showCurrencySymbolForNumbers?: any,
  ) => {
    if (!num) return;
    limitValue = limitValue || formatting.DEFAULT_LIMIT_VALUE_FOR_FRACTIONS;
    return Math.abs(num) <= limitValue ? formatting.toFractionalCurrency(num) : formatting.toIntCurrency(num);
  };

  // converts e.g. 75.7857 → +75.79%
  static toPercentage = (
    num: number | null | undefined,
    signed: boolean,
    numberOfFractionalDigits: number,
    language?: string,
  ) => {
    if (formatting.OTHER_THAN_NORDIC) {
      language = 'fr-FR';
    }
    if (num == null || num === undefined) return;
    let sign = '';

    if (signed) {
      if (num > 0) {
        sign = '+';
      } else if (num < 0) {
        sign = '-';
      } else {
        sign = '';
      }
    }
    const returnValue =
      sign +
      formatting.resolveCurrencyWithoutSymbol(
        formatting.toFractionalString(Math.abs(num), numberOfFractionalDigits),
        language,
      );
    if (language === 'fr-FR') {
      return returnValue + ' %';
    } else {
      return returnValue + '%';
    }
  };

  static toFormattedVolume = (num: number | null | undefined, type: any, language: string) => {
    if (!num) return;
    const billion = 1000000000;
    const million = 1000000;
    const billionSign = 'B';
    const millionSign = 'M';
    let result, doubleValue;

    if (num >= billion) {
      doubleValue = num / billion;
      doubleValue = formatting.toFractionalString(doubleValue, undefined, language, type);
      result = doubleValue + billionSign;
    } else if (num >= million) {
      doubleValue = num / million;
      doubleValue = formatting.toFractionalString(doubleValue, undefined, language, type);
      result = doubleValue + millionSign;
    } else {
      result = formatting.toFractionalString(num, 0, language, type);
    }
    return result;
  };

  static toExtendedChange = (
    change: number | null,
    percentageChange: any,
    asIntFractional: any,
    numberOfFractionalDigits: any,
    language: string | undefined,
    showCurrencySymbolForNumbers: boolean,
  ): string | null => {
    if (change == null) {
      return null;
    }
    const changeMark = change > 0 ? '+' : '';
    const transformFunction = asIntFractional ? formatting.toIntFractionalCurrency : formatting.toFractionalCurrency;
    let result = '';
    if (showCurrencySymbolForNumbers === false) {
      result =
        changeMark +
        formatting.resolveCurrencyWithoutSymbol(transformFunction(change, numberOfFractionalDigits), language);
    } else {
      result =
        changeMark +
        formatting.resolveCurrencyAccToLanguage(transformFunction(change, numberOfFractionalDigits), language);
    }
    if (percentageChange) {
      result += ' (' + formatting.toPercentage(percentageChange, false, numberOfFractionalDigits, language) + ')';
    }
    return result;
  };

  static almostEqual = (a: number, b: number, precision?: number) => {
    precision = precision || 1e-6;
    const result = Math.abs(a - b) < precision;
    return result;
  };

  static roundNumber = (digit: number, digits: number = 2) => {
    if (digit === undefined) {
      throw new Error('number is null or undefiend');
    }
    const multiple = Math.pow(10, digits);
    const roundedNum = Math.round(digit * multiple) / multiple;
    return roundedNum;
  };

  /**
   * Rounds given number x to be multiple of number f
   * @example
   * returns 2.50
   * round(2.47, 0.05);
   * returns 2.45
   * round(2.47, 0.05, true);
   * @param {number} x Number to round
   * @param {number} f Number to round
   * @param {boolean=undefined} if not set - the value is rounded to nearest multiple of 'f'.
   */
  static roundToMultiplier = (x: number, f: number, roundDown?: number | null) => {
    const func = roundDown == null ? Math.round : roundDown ? Math.floor : Math.ceil;
    return f * func(x / f);
  };

  /*
   *  returns symbol from symbol.exchange
   * eg :BIP.UN.XTSE => BIP.UN , X.XTSE => X
   */
  static symbolDotExchangeToSymbol = (symbolExchange: string) => {
    if (formatting.Exchange(symbolExchange) == '') return symbolExchange;
    else return symbolExchange.substring(0, symbolExchange.lastIndexOf('.'));
  };

  /*
   *  returns exchange from symbol.exchange
   * eg :BIP.UN.XTSE => XTSE , X.XTSE => XTSE
   */
  static symbolDotExchangeToExchange = (symbolExchange: any) => {
    const exchange = formatting.getExchange(symbolExchange);
    return exchange;
  };

  static getExchange = (value: string) => {
    const splits = value.split('.');
    const exchange = splits[splits.length - 1];
    return exchange;
  };

  /*
   *  returns symbol.exchange or symbol w.r.t regions using the symbol and exchange parameters
   * eg : getSymbolDotExchange(ABC , WXYZ) -> ABC.WXYZ || ABC
   */
  static getSymbolDotExchange = (symbol: string, exchange: string | undefined) => {
    if (symbol.trim() === '' || !exchange || exchange === '' || !ApplicationContext.configuration) {
      return symbol;
    }
    // if (!isUS()) {
    return `${symbol}.${exchange}`;
    // }
    // return symbol;
  };

  static Exchange = (value: string) => {
    if (value.lastIndexOf('.') > 0) {
      const splits = value.split('.');
      // last one contains the exchange
      const exchange = splits[splits.length - 1];
      if (exchange.length >= 4) return exchange;
      else return '';
    } else {
      return '';
    }
  };

  static aOrAn = (num: number) => {
    num = formatting.roundNumber(num, 0);
    while (num > 999) {
      num = formatting.roundNumber(num / 1000, 0);
    }
    if (num == 8 || num == 11 || num == 18 || (num >= 80 && num < 90)) {
      return 'an';
    } else {
      return 'a';
    }
  };

  /**
   * Converts a Date object from one time zone to another using the timezoneJS.Date library
   * @param  {Date} dt                    Original JavaScript Date object, from the original time zone
   * @param  {string} originalTimeZone    The original time zone
   * @param  {string} targetTimeZone      The target time zone
   * @return {timezoneJS.Date}            The date in the target timezone. This behaves the same as a native Date.
   * @memberOf STX
   */
  static convertTimeZone = (dt: Date, originalTimeZone: string, targetTimeZone: string) => {
    return dt;
    // Convert from original timezone to local time
    // const newDT = new STXThirdParty.timezoneJS.Date(
    //   dt.getFullYear(),
    //   dt.getMonth(),
    //   dt.getDate(),
    //   dt.getHours(),
    //   dt.getMinutes(),
    //   dt.getSeconds(),
    //   dt.getMilliseconds(),
    //   originalTimeZone,
    // );
    // // Convert from local time to new timezone
    // newDT.setTimezone(targetTimeZone);
    // return newDT;
  };

  /*
   * Returns Date object if date is valid date (string or in Date object).
   * Applies additional time zone conversions if more than one argument is given
   * NOTE: Javascript date returned still contain local timezone info.
   * however date itself (hours, minutes) will be converted into targetTimeZone
   * @param {Date|string} date date to parse and/or check
   * @param {string} sourceTimeZone source date time zone. default - local time zone
   * @param {string='EST5EDT'} targetTimeZone target time zone. Return value will be in this time zone
   */

  static resolveDate = (date: Date | string, sourceTimeZone?: string, targetTimeZone?: string) => {
    let convertedDate: Date | undefined;
    if (typeof date === 'string') {
      let dateTime = DateTime.fromISO(date);
      convertedDate = dateTime.toJSDate();
    } else {
      convertedDate = new Date(date);
    }
    if (!helpers.isDate(convertedDate)) {
      throw new Error('date is undefined');
    }
    //TODO: Use luxon TimeZone to convert the time zone.
    convertedDate = formatting.convertTimeZone(
      convertedDate as Date,
      sourceTimeZone || '',
      targetTimeZone || formatting.MARKET_TIME_ZONE,
    );
    const result = new Date(
      convertedDate.getFullYear(),
      convertedDate.getMonth(),
      convertedDate.getDate(),
      convertedDate.getHours(),
      convertedDate.getMinutes(),
      convertedDate.getSeconds(),
      convertedDate.getMilliseconds(),
    );
    return result;
  };

  /* tsk-4194-@mohit : Build Internationalization Framework*/
  /*
   * Returns currency formatted according to the language passed while calling
   * @param {value} the number currency that needs to be formatted
   * @param {language} the language passed according to which the number is to be formatted
   * NOTE: Currency constiable is to be set manually according to the language passed
   */
  static resolveCurrencyAccToLanguage = (
    value: number | string,
    language?: string,
    OTHER_THAN_NORDIC?: boolean | undefined,
    minimumFractionDigits?: undefined,
  ) => {
    let parsedValue: string | number = typeof value == 'string' ? (value as string).replace(',', '') : value;
    let currency = 'USD';
    let languageOverride = language;
    let formattedValue = 'N/A';
    let currencyDisplay = 'symbol';
    switch (language) {
      case 'en-US':
        currency = 'USD';
        break;
      case 'zh-CN':
        currency = 'USD';
        languageOverride = 'en-US';
        break;
      case 'fr-CA':
        currency = 'USD';
        languageOverride = 'en-US'; // we're doing this because we need $ to show as $, not $ US
        break;
      case 'sv-SE' /*Nordics use the same currency and date localizations as France.*/:
        currency = 'SEK';
        break;
      default:
        currency = 'USD';
        break;
    }
    if (!parsedValue && parsedValue !== 0) {
      formattedValue = 'N/A';
    } else {
      if (OTHER_THAN_NORDIC) {
        formattedValue = Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: minimumFractionDigits,
        }).format(parsedValue as unknown as number);
      } else {
        if (currency === 'USD') {
          formattedValue = Intl.NumberFormat(languageOverride, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: minimumFractionDigits,
          }).format(parsedValue as unknown as number);
        } else {
          formattedValue = Intl.NumberFormat(languageOverride, {
            currency: currency,
            minimumFractionDigits: minimumFractionDigits,
          }).format(parsedValue as unknown as number);
        }
      }
    }
    return formattedValue;
  };

  public static resolveCurrencyWithoutSymbol = (value?: number | string, language?: string | string[] | undefined) => {
    let formattedValue = 'N/A';
    if (language === 'fr-CA') {
      language = 'en-US';
    }
    if (!value) {
      formattedValue = 'N/A';
    } else {
      formattedValue = value.toString();
      if (formatting.OTHER_THAN_NORDIC) {
        if ((value as number) > Math.floor(value as number)) {
          formattedValue = Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
            value as number,
          );
        } else {
          formattedValue = Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(value as number);
        }
      } else {
        if ((value as number) > Math.floor(value as number)) {
          formattedValue = Intl.NumberFormat(language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
            value as number,
          );
        } else {
          formattedValue = Intl.NumberFormat(language, { minimumFractionDigits: 2 }).format(value as number);
        }
      }
    }
    return formattedValue;
  };

  static resolveExpiry(date: Date | undefined) {
    if (!date) {
      return undefined;
    }
    let dateValue = formatting.resolveDate(date, formatting.MARKET_TIME_ZONE);
    if (dateValue.getDay() !== 6) {
      dateValue.setHours(16, 0, 0, 0);
    } else {
      dateValue.setHours(0, 0, 0, 0);
    }
    return dateValue;
  }

  /*
   * Returns boolean to format dates if the dates are Weekly expiry or Monthly expiry
   */
  static formatExpiryDates = (date: Date) => {
    const thirdWeek = formatting.getWeekNumber(date);
    let isWeekly = true;
    if (thirdWeek === 3) {
      isWeekly = false;
    }
    return isWeekly;
  };

  /**
   * Returns date object in Market's time
   */
  static getCurrentDateTime = () => {
    return formatting.resolveDate(new Date());
  };

  static getCurrentDate = () => {
    let current = formatting.getCurrentDateTime();
    current.setHours(0, 0, 0, 0);
    return current;
  };

  /* Adds given number of days to start date and returns resulting date
   * @param {number} numOfDays Number of days to add to start date
   * @param {Date=<Current EST Date>} startDate Number of days to add to start date
   */

  static getDateFromDaysInFuture = (numOfDays: number, startDate?: string | number | Date) => {
    let result = startDate ? new Date(startDate) : formatting.getCurrentDate();
    let dateTime = DateTime.fromJSDate(result);
    let futureDate = dateTime.plus({ days: numOfDays });
    return futureDate.toJSDate();
  };

  /**
   * Throws out user's timezone from given date.
   * Note: be careful while using this . It modifies actual date and result could be wrong
   */
  static throwTimezoneOffset = (date?: Date | undefined | string, fromLocal?: undefined | boolean) => {
    //date = formatting.resolveDate(date); =====priya
    if (typeof date === 'string') {
      let newDate = new Date(date);
      let timezoneOffset = newDate.getTimezoneOffset() * 60000;
      newDate = new Date(newDate.getTime() + timezoneOffset);
      return newDate;
    }
    if (!date) {
      return null;
    }
    let timezoneOffset = date.getTimezoneOffset() * 60000;
    if (fromLocal) {
      timezoneOffset *= -1;
    }
    let newDate = new Date(date.getTime() + timezoneOffset);
    return newDate;
  };

  static toExpiryKey = (date: Date | undefined) => {
    if (!date) {
      return '';
    }
    const month = date.getMonth() + 1;
    const dateNumber = date.getDate();
    const result = `${date.getFullYear()}${month < 10 ? '0' : ''}${month}${dateNumber < 10 ? '0' : ''}${dateNumber}`;
    return result;
  };

  static formatUTCDate = (
    date: Date | undefined,
    dateFormat: string | undefined,
    language: string | string[] | undefined,
  ) => {
    return formatting.formatDate(date, dateFormat, 'UTC', language);
  };

  //TODO: use luxon to simplify this
  // (new Date()).format('yyyy-MM-dd hh:mm:ss.S') → 2006-07-02 08:09:04.423
  // (new Date()).format('yyyy-M-d h:m:s.S') → 2006-7-2 8:9:4.18
  static formatDate = (
    date?: Date | undefined | null,
    dateFormat?: string,
    kind?: undefined | string | null,
    language?: string | string[] | undefined,
  ) => {
    if (!date) return null;
    if (kind) {
      date = formatting.resolveDate(date, kind, kind);
    } else {
      date = formatting.resolveDate(date);
    }

    if (!date) {
      throw Error('Invalid date to dateFormat');
    }

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const dateTimeFormat = Intl.DateTimeFormat(language, options).format(date);
    dateFormat = dateFormat || formatting.DEFAULT_DATE_FORMAT;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // date
      h: date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // season
      S: date.getMilliseconds(), // millisecond
      T: months[date.getMonth()], // month text
    };
    const month = date.getMonth() + 1;
    const dateNumber = date.getDate();
    if (dateFormat === 'yyyyMMdd') {
      const result =
        '' + date.getFullYear() + (month < 10 ? '0' : '') + month + (dateNumber < 10 ? '0' : '') + dateNumber;
      return result;
    }
    if (dateFormat === 'YYMMDD') {
      const result =
        '' +
        date.getFullYear().toString().slice(-2) +
        (month < 10 ? '0' : '') +
        month +
        (dateNumber < 10 ? '0' : '') +
        dateNumber;
      return result;
    }
    if (/(y+)/.test(dateFormat)) {
      dateFormat = dateFormat.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }

    for (const k in o) {
      if (!o.hasOwnProperty(k)) {
        continue;
      }
      if (new RegExp('(' + k + ')').test(dateFormat)) {
        // dateFormat = dateFormat.replace(
        //   RegExp.$1,
        //   RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length), =====priya
        // );
      }
    }

    return dateTimeFormat;
  };

  static getWeekNumber = (date: Date) => {
    const dayDiff = 5 - date.getDay();
    const shiftedDate = new Date();
    shiftedDate.setDate(date.getDate() + dayDiff);
    const dayOfMonth = shiftedDate.getDate();
    const result = Math.ceil(dayOfMonth / 7);
    return result;
  };

  private static getDatePart = (date: Date | string) => {
    if (typeof date === 'string') {
      const dateTime = DateTime.fromISO(date);
      if (!dateTime.isValid) {
        console.error(dateTime.invalidExplanation as string);
        return undefined;
      }
      return new Date(dateTime.year, dateTime.month, dateTime.day);
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  static sameDate = (date1: Date | undefined, date2: Date | undefined) => {
    if (!date1 || !date2) {
      return false;
    }

    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    if (year1 !== year2) return false;
    if (month1 !== month2) return false;
    if (day1 !== day2) return false;

    return true;
  };

  //TODO: use luxon to do the math.
  static daysFromNow = (date?: Date) => {
    if (!date || !helpers.isDate(date)) {
      return 0;
    }
    const now = formatting.getCurrentDate().getTime();
    const datePart = formatting.getDatePart(date as Date);
    if (!datePart) {
      throw new Error('datepart is undefined');
    }
    let diffMili = datePart.getTime() - now;
    let diff = diffMili / 1000 / 3600 / 24;
    diff = formatting.roundNumber(diff, 0);
    return diff;
  };

  private static toPropertyName = (str: string) => {
    if (!str) {
      return '';
    }
    if (str[0] === str[0].toLowerCase()) {
      return str;
    }
    let result = str.toLowerCase();
    result = result.replace(/_([a-z])/g, (g) => {
      return g[1].toUpperCase();
    });
    return result;
  };

  static checkIndices = (exchange: string) => {
    return exchange.indexOf('IND') != -1;
  };

  /**
   * Formats string using object as replacement source.
   * E.g. formatStr('{SOME_PROPERTY} continuation', {someProperty: 'Test'}) -> 'Test continuation'
   * Casing inside brackets does not matter. E.g. {Test},{TEST},{TeSt} refers to the same property test
   * Underscore followed by letter will be replaced with uppercase letter (camel casing)
   * @param {string} str String to format
   * @param data Object to take replacement values from
   */
  static formatStr = (
    str: string | null | undefined,
    data: any,
    divideIndexQuantityByHundred: boolean = false,
    isIndex: boolean = false,
  ) => {
    let result = '';
    if (!str) {
      return result;
    }
    if (!data) {
      return str;
    }
    result = str;
    // Matches any string inside figure brackets
    var re = /\{(.*?)\}/g;
    let match: RegExpExecArray | null;
    let singleOrPluralBool = false;
    while ((match = re.exec(str)) !== null) {
      let strToReplace = match[0];
      let propertyName = match[1];
      if (propertyName === '') {
        continue;
      }
      propertyName = formatting.toPropertyName(propertyName);
      var propertyValue = data[propertyName];
      if (typeof propertyValue === 'function') {
        propertyValue = propertyValue();
      }
      if (isIndex && divideIndexQuantityByHundred) {
        if (typeof propertyValue === 'number') {
          if (propertyValue < 2) {
            singleOrPluralBool = true;
          }
        }
        if (propertyValue === 'Shares') {
          if (singleOrPluralBool == true) {
            propertyValue = 'Share';
          }
        }
      }
      result = result.replace(strToReplace, propertyValue || '');
    }
    return result.replace(/[\t ]+/g, ' ');
  };

  //TODO: Use pluralize function.
  /**
   * Extremely simplified pluralization for english words ONLY
   */
  static pluralize = function (word: string, quantity: number) {
    if (Math.abs(quantity) <= 1) {
      return word;
    }

    const restoreCase = (word: string, token: string) => {
      if (word && word === word.toString().toUpperCase()) {
        return token.toUpperCase();
      }

      // Title cased words. E.g. "Title".
      if (word && word[0] === word[0].toUpperCase()) {
        return formatting.capitaliseFirstLetter(token);
      }

      // Lower cased words. E.g. "test".
      return token.toLowerCase();
    };

    /**
     * Interpolate a regexp string.
     *
     * @param  {[type]} str  [description]
     * @param  {[type]} args [description]
     * @return {[type]}      [description]
     */
    const interpolate = function (str: string, args: any) {
      return str.replace(/\$(\d{1,2})/g, (match: any, index: string | number) => {
        return args[index] || '';
      });
    };

    const pluralizationRules: any = [
      [/s?$/i, 's'],
      [/([^aeiou]ese)$/i, '$1'],
      [/(ax|test)is$/i, '$1es'],
      [/(alias|[^aou]us|tlas|gas|ris)$/i, '$1es'],
      [/(e[mn]u)s?$/i, '$1s'],
      [/([^l]ias|[aeiou]las|[emjzr]as|[iu]am)$/i, '$1'],
      [/(alumn|alg|vertebr)(?:a|ae)$/i, '$1ae'],
      [/(seraph|cherub)(?:im)?$/i, '$1im'],
      [/(her|at|gr)o$/i, '$1oes'],
      [/sis$/i, 'ses'],
      [/(?:(i)fe|(ar|l|ea|eo|oa|hoo)f)$/i, '$1$2ves'],
      [/([^aeiouy]|qu)y$/i, '$1ies'],
      [/([^ch][ieo][ln])ey$/i, '$1ies'],
      [/(x|ch|ss|sh|zz)$/i, '$1es'],
      [/(matr|cod|mur|sil|vert|ind|append)(?:ix|ex)$/i, '$1ices'],
      [/eaux$/i, '$0'],
      [/m[ae]n$/i, 'men'],
    ];
    let len = pluralizationRules.length;

    while (len--) {
      const rule = pluralizationRules[len];

      if (rule[0].test(word)) {
        return word.replace(rule[0], function (match, index, word) {
          const result = interpolate(rule[1], arguments);

          if (match === '') {
            return restoreCase(word[index - 1], result);
          }

          return restoreCase(match, result);
        });
      }
    }
    return word;
  };

  static closeTo = (num1: number, num2: number, tolerance: number) => {
    const diff = num1 - num2;
    return Math.abs(diff) <= tolerance;
  };

  //! NOT USED
  static ordinalIndicator = (number: number | null) => {
    if (!number) return;
    const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
    if (number % 100 >= 11 && number % 100 <= 13) {
      return number + 'th';
    } else {
      return number + ends[number % 10];
    }
  };

  static capitaliseFirstLetter = (string: string) => {
    const result = string.charAt(0).toUpperCase() + string.slice(1);
    return result;
  };

  //TODO: Temporary solution. The enums value should be same as API PascalCasing.
  static pascalToSnakeCase = (value: string) => {
    if (typeof value !== 'string') return;
    return value
      .split(/(?=[A-Z])/)
      .join('_')
      .toUpperCase();
  };

  static replaceSlashtoUnderscore = (symbol: string) => {
    return this.customReplaceAll(symbol, '/', '_');
  };

  static customReplaceAll = (str: string, from: string, to: string) => {
    return lodash.replace(str, new RegExp(from, 'g'), to);
  };

  static formatExpiryDate = (date: Date) => {
    const formattedExpiryDate = this.formatDate(date, this.EXPIRY_DATE_FORMAT);
    return formattedExpiryDate;
  };
}
export default formatting;
