import { PortfolioType } from '@op/shared/src/models/enums/enums';
import { strategyShareDataState } from '@op/shared/src/states';
import {
  fetchPorfolioBrokerccounts,
  fetchPorfolioCombinationAccounts,
  portfolioBrokerAccountsState,
  portfoliosDataState,
} from '@op/shared/src/states/portfolio-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { PorfolioCombinationsOfAccountsWidget } from './porfolio-combinations-of-accounts-widget';
import { PortfolioWhatLoaderWidget } from './portfolio-what-loader-widget';

export const PortfolioWhatWidget: React.FC<{}> = () => {
  const [portfoliosData, setPortfoliosData] = useRecoilState(portfoliosDataState);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const setPortfolioBrokerAccounts = useSetRecoilState(portfolioBrokerAccountsState);
  const resetStrategyShareData = useResetRecoilState(strategyShareDataState);
  const strategyShareData = useRecoilValue(strategyShareDataState);

  useEffect(() => {
    //TODO: refetch after cache expiration.
    if (portfoliosData !== undefined) {
      setShouldShowSkeleton(false);
      return;
    }
    // This condition is required when user shared data and directly loaded the portfolio page
    if (strategyShareData) {
      resetStrategyShareData();
    }
    (async () => {
      setShouldShowSkeleton(true);
      const porfolioAccounts = await fetchPorfolioCombinationAccounts();
      setPortfoliosData(porfolioAccounts);

      if (porfolioAccounts) {
        let hasBrokerAccounts: boolean = porfolioAccounts.data.some(
          (a) => a.portfolioTypeStatus === PortfolioType.TDAmeritrade,
        );
        if (hasBrokerAccounts) {
          const porfolioBrokerAccounts = await fetchPorfolioBrokerccounts();
          setPortfolioBrokerAccounts(porfolioBrokerAccounts);
        }
      }
      setShouldShowSkeleton(false);
    })();
  }, [portfoliosData]);

  if (shouldShowSkeleton) {
    return <PortfolioWhatLoaderWidget />;
  }

  return <PorfolioCombinationsOfAccountsWidget />;
};
