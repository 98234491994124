import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { IBillingModelData } from '@op/shared/src/models/subscription/billing-history';
import { getBillingHistory } from '@op/shared/src/services';
import React, { useContext, useEffect, useState } from 'react';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, TableHeaderCell } from '../styled';

export const BillingHistoryNew: React.FC = () => {
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [values, setValues] = useState<IBillingModelData[]>();
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    (async () => {
      const billingHistoryResponse = await getBillingHistory();
      if (billingHistoryResponse.hasErrors) {
        return;
      }
      const billingData = billingHistoryResponse.data.data;
      setValues(billingData);
    })();
  }, []);

  if (!values) {
    return;
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderTableBody = () => {
    const slicedValues = values.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return slicedValues.map((row) => (
      <TableRow key={row.identifier} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align="left">
          {row.featureKey} {row.billingModel}
        </TableCell>
        <TableCell align="left">{`$${row.amount}`}</TableCell>
        <TableCell align="left">{DateTimeHelper.format(row.dateSubmitted)}</TableCell>
        <TableCell align="left" sx={{ color: theme.palette.success.main }}>
          {row.status}
        </TableCell>
      </TableRow>
    ));
  };

  const renderTable = () => {
    if (values.length === 0) {
      return (
        <TableRow>
          <Typography>{t('subscriptions.noMatchingRecords')}</Typography>
        </TableRow>
      );
    }
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableHeaderCell align="left">{t('subscriptions.invoices')}</TableHeaderCell>
              <TableHeaderCell align="left">{t('subscriptions.amountTitle')}</TableHeaderCell>
              <TableHeaderCell align="left">{t('subscriptions.date')}</TableHeaderCell>
              <TableHeaderCell align="left">{t('subscriptions.statusTitle')}</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {renderTable()}
        </Grid>
        <Grid item xs={12}>
          <TablePagination
            component="div"
            count={values.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion sx={{ width: '100%' }} disableGutters expanded={true}>
      <AccordionSummary
        sx={{
          backgroundColor: theme.palette.primary.main,
          paddingY: 0,
          borderRadius: 1,
        }}>
        <OPBoldTypograpghy variant="subtitle1" sx={{ color: theme.palette.timeFrame.contrastText }}>
          {t('subscriptions.billingHistory')}
        </OPBoldTypograpghy>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: theme.palette.greyShades.light,
          p: 1,
        }}>
        <Grid
          item
          container
          xs={12}
          sx={{
            backgroundColor: theme.palette.greyShades.light,
            borderRadius: 2,
          }}>
          {renderContent()}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
