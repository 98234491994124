import { IAddToWatchlistResponseProps } from '.';

export class AddToWatchlistResponse implements IAddToWatchlistResponseProps {
  id = -1;
  name = '';
  type = 'None';

  public static fromJson = (data: AddToWatchlistResponse): AddToWatchlistResponse => {
    const model = new AddToWatchlistResponse();
    model.id = data.id;
    model.name = data.name;
    model.type = data.type;
    return model;
  };
}
