import { Connection, hubConnection, Proxy } from 'signalr-no-jquery';
import { ApiService, AuthenticationService } from '../services';
//TODO: Latest maintained package: https://github.com/tehcojam/signalr-no-jquery
export abstract class SocketConnection {
  private _retryAttempts = 3;
  protected connection: Connection | undefined;
  private _isConnected = false;
  private _isStopped = true;
  notifications = new Map<string, Array<{ name: string; callback: (data: any) => void }>>();
  hub: Proxy | undefined;
  onDisconnect?: () => void;
  protected constructor() {}

  fromHubName(hubName: string, token?: string | undefined) {
    const signalrEndpoint = ApiService.instance.singalrEndpoint;
    if (!signalrEndpoint || signalrEndpoint.trim().length === 0) {
      throw new Error('signalR endpoint is undefined');
    }
    this.connection = hubConnection(signalrEndpoint, {
      useDefaultPath: false,
      logging: false,
      qs: token !== undefined && token.trim() !== '' ? 'bearer_token=' + token : undefined,
    });
    this.connection.reconnecting(() => {
      console.warn('signalR: connection is reconnecting');
    });
    this.connection.disconnected(() => {
      //console.error('hubSignalR: is disconnected');
      this.disconnect();
      // if it was connected previously, we need to recreate deferred for future calls to hub
      if (this.onDisconnect) {
        this.onDisconnect();
      }
    });
    this.hub = this.connection.createHubProxy(hubName);
    this.hub.on('notify', (eventType: string, data: any) => {
      const uniqueHandlers = this.getUniqueHandlers(eventType);
      if (eventType === 'Quotes') {
      }
      if (uniqueHandlers) {
        for (let handler of uniqueHandlers) {
          handler.callback(data);
        }
      }
    });
  }

  get isConnected() {
    return this._isConnected;
  }

  set isConnected(value: boolean) {
    this._isConnected = value;
  }

  get isStopped() {
    return this._isStopped;
  }

  set isStopped(value: boolean) {
    this._isStopped = value;
  }

  connect = async () => {
    if (this.isConnected) {
      this.isStopped = false;
      return true;
    }
    return new Promise<boolean>((resolve, reject) => {
      if (!this.connection) {
        throw new Error('Connection is undefined');
      }
      return this.connection
        .start()
        .fail(() => {
          console.error('connection failed');
          this.isConnected = false;
          this.isStopped = true;
          reject(false);
        })
        .done(() => {
          this.isConnected = true;
          this.isStopped = false;
          console.info(`connection successful to ${this.hub?.hubName} ${this.connection?.id}`);
          resolve(true);
        });
    });
  };

  disconnect = () => {
    if (this.isStopped) {
      return;
    }
    this.isConnected = false;
    this.connection?.stop();
    this.notifications.clear();
    this.isStopped = true;
  };

  invoke = async (name: string, args?: any | any[], shareId?: string | undefined) => {
    if (!this.hub) {
      throw new Error('Hub is undefined');
    }
    let attempt = 0;
    while (attempt < this._retryAttempts) {
      //TODO: use tockTimer to delay 500ms before next try.
      try {
        //await this.connect(); //TODO: check isConnected before invoking
        let result: any;
        if (args) {
          if (shareId) {
            result = await this.hub.invoke(name, args, shareId);
          } else {
            result = await this.hub.invoke(name, args);
          }
        } else {
          result = await this.hub.invoke(name, shareId);
        }
        return result;
      } catch (error) {
        // console.error('error while calling hub method: ', name, error);
      }
      attempt += 1;
    }
  };

  private getUniqueHandlers = (eventType: string) => {
    const handlers = this.notifications.get(eventType);
    if (!handlers) {
      return undefined;
    }
    const uniqueHandlerNames = new Set(handlers.map((h) => h.name));
    const uniqueHandlers: { name: string; callback: (data: any) => void }[] = [];
    for (let name of Array.from(uniqueHandlerNames)) {
      const firstHandler = handlers.find((h) => h.name === name);
      if (!firstHandler) {
        continue;
      }
      uniqueHandlers.push(firstHandler);
    }
    return uniqueHandlers;
  };

  /* React components are rendered multiple times on change of state. name of handler is used keep the callback to minimum.
   * The duplicate handlers should be solved by using useEffect with proper dependency graph in component itself.
   * However, for now keeping dedupe logic in <pre>on</pre> registration method as well.
   */
  on = (eventType: string, handler: { name: string; callback: (data: any) => void }) => {
    if (eventType === '') {
      return;
    }
    const eventHandlers = this.getUniqueHandlers(eventType); //this.notifications.get(eventType);
    if (!eventHandlers) {
      this.notifications.set(eventType, [handler]);
      return;
    }
    this.notifications.set(eventType, [...eventHandlers, handler]);
  };
}
