import { Grid, Typography } from '@mui/material';
import { Combination, IData } from '@op/shared/src/models';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { tradeSimulatorOperationTypeState, whatIfSimulatorState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { RedGreenWidget } from './red-green-widget';
import { GuideItem } from '../side-menu';

export interface IProfitLossWidgetProps extends IData<Combination> {
  index?: number;
}

export const ProfitLossWidget: React.FC<IProfitLossWidgetProps> = ({ data, index }: IProfitLossWidgetProps) => {
  const tradeSimulatorOperation = useRecoilValue(tradeSimulatorOperationTypeState);
  const whatIfSimulator = useRecoilValue(whatIfSimulatorState);
  const { t } = React.useContext(LocalizationContext);

  if (!whatIfSimulator) {
    return null;
  }
  const isPLSimulatorSelected = tradeSimulatorOperation === tradeSimulatorOperationType.PLSimulator;
  const whatIfTheoretical = whatIfSimulator.whatifTheoretical(data);

  const renderProfitLossIfPossible = () => {
    if (!isPLSimulatorSelected) {
      return undefined;
    }
    return (
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1" textAlign="right">
            {whatIfTheoretical.payoffValue >= 0
              ? t('how.toolbox.whatIfSimulator.text.profit')
              : t('how.toolbox.whatIfSimulator.text.loss')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" id="card-profit" fontWeight="bold">
            <RedGreenWidget value={whatIfTheoretical.payoffValue}>{whatIfTheoretical.payoffFormatted}</RedGreenWidget>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderReturnIfPossible = () => {
    if (!isPLSimulatorSelected) {
      return undefined;
    }
    return (
      <Grid container columnSpacing={1}>
        <Grid item xs={6}>
          <Typography variant="body1" textAlign="right">
            {t('how.toolbox.whatIfSimulator.text.return')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" id="card-return" fontWeight="bold">
            <RedGreenWidget value={whatIfTheoretical.payoffValue}>
              {whatIfTheoretical.returnPercentageFormatted}
            </RedGreenWidget>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <GuideItem selector=".whatIfReturnPanels_helpPinPlaceholder" canShow={index === 0} />
      <Grid item xs={12}>
        {renderProfitLossIfPossible()}
      </Grid>
      <Grid item xs={12}>
        {renderReturnIfPossible()}
      </Grid>
    </Grid>
  );
};
