import { Card, Grid } from '@mui/material';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { HeightWidget } from '../routes';
import { GuideItem } from '../side-menu';
import { OPBoldTypograpghy } from '../styled';
import { QuotebarSearchWidget } from './quote-bar-search-widget';

interface Props {
  symbol: string;
}

export const QuotebarUnsupportedSymbolWidget: React.FC<Props> = ({ symbol }) => {
  const customization = useRecoilValue(customizationState);
  const { t } = React.useContext(LocalizationContext);
  const symbolWidgetHeight =
    customization.quotebarName === 'variant1' || customization.quotebarName === 'variant2' ? 100 : 125;

  const renderView = () => {
    if (customization.showSearchBarForUnknownSymbol) {
      return (
        <Card>
          <HeightWidget height={symbolWidgetHeight}>
            <Grid container>
              <GuideItem selector=".symbolInput_helpPinPlaceholder" />
              <Grid item xs={12}>
                <QuotebarSearchWidget />
              </Grid>
              <Grid item container xs={12} justifyContent="center" alignItems="center" pt={2}>
                <OPBoldTypograpghy>
                  {t('why.unsupportedSymbol')} {symbol}
                </OPBoldTypograpghy>
              </Grid>
            </Grid>
          </HeightWidget>
        </Card>
      );
    }
    return (
      <Card>
        <HeightWidget height={symbolWidgetHeight}>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <OPBoldTypograpghy>
              {t('why.unsupportedSymbol')} {symbol}
            </OPBoldTypograpghy>
          </Grid>
        </HeightWidget>
      </Card>
    );
  };

  return renderView();
};
