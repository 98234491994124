export interface IPutOptimal {
  symbol: string;
  strike: number;
  return: number;
  premium: number;
  expiry: string;
  legType: string;
  probability: number;
  probabilityInSigmas: string | number;
  riskTolerance: string;
  timeFrame: string;
  aggressiveness: string;
  priority: number;
  hasEarnings: boolean;
  daysQuantityBeforeEarnings: number;
  volume: number;
  openInterest: number;
  impliedVolatility: number;
  oneWeekTheta: number;
  daysUntilExpiry: number;
  delta: number;
  percentAboveBelowCurrentPrice: number;
  numberOfStrikesBelowAboveCurrentPrice: number;
  numberOfSdBelowAboveCurrentPrice: string | number;
  ask: number;
  bid: number;
  useForwardPricing: boolean;
}

export interface IOptimal extends ICallOptimal {}

export interface ICallOptimal extends IPutOptimal {}

export class PutOptimal implements IPutOptimal {
  symbol: string = '';
  strike: number = 0;
  return: number = 0;
  premium: number = 0;
  expiry: string = '';
  legType: string = '';
  probability: number = 0;
  probabilityInSigmas: string | number = 0;
  riskTolerance: string = '';
  timeFrame: string = '';
  aggressiveness: string = '';
  priority: number = 0;
  hasEarnings: boolean = false;
  daysQuantityBeforeEarnings: number = 0;
  volume: number = 0;
  openInterest: number = 0;
  impliedVolatility: number = 0;
  oneWeekTheta: number = 0;
  daysUntilExpiry: number = 0;
  delta: number = 0;
  percentAboveBelowCurrentPrice: number = 0;
  numberOfStrikesBelowAboveCurrentPrice: number = 0;
  numberOfSdBelowAboveCurrentPrice: string | number = 0;
  ask: number = 0;
  bid: number = 0;
  useForwardPricing: boolean = false;

  private constructor() {}

  static fromData = (data: IPutOptimal) => {
    const model = new PutOptimal();
    model.symbol = data.symbol;
    model.strike = data.strike;
    model.return = data.return;
    model.premium = data.premium;
    model.expiry = data.expiry;
    model.legType = data.legType;
    model.probability = data.probability;
    model.probabilityInSigmas = data.probabilityInSigmas;
    model.riskTolerance = data.riskTolerance;
    model.timeFrame = data.timeFrame;
    model.aggressiveness = data.aggressiveness;
    model.priority = data.priority;
    model.hasEarnings = data.hasEarnings;
    model.daysQuantityBeforeEarnings = data.daysQuantityBeforeEarnings;
    model.volume = data.volume;
    model.openInterest = data.openInterest;
    model.impliedVolatility = data.impliedVolatility;
    model.oneWeekTheta = data.oneWeekTheta;
    model.daysUntilExpiry = data.daysUntilExpiry;
    model.delta = data.delta;
    model.percentAboveBelowCurrentPrice = data.percentAboveBelowCurrentPrice;
    model.numberOfStrikesBelowAboveCurrentPrice = data.numberOfStrikesBelowAboveCurrentPrice;
    model.numberOfSdBelowAboveCurrentPrice = data.numberOfSdBelowAboveCurrentPrice;
    model.ask = data.ask;
    model.bid = data.bid;
    model.useForwardPricing = data.useForwardPricing;
    return model;
  };

  static fromSelf = (self: PutOptimal) => {
    const clone = new PutOptimal();
    clone.symbol = self.symbol;
    clone.strike = self.strike;
    clone.return = self.return;
    clone.premium = self.premium;
    clone.expiry = self.expiry;
    clone.legType = self.legType;
    clone.probability = self.probability;
    clone.probabilityInSigmas = self.probabilityInSigmas;
    clone.riskTolerance = self.riskTolerance;
    clone.timeFrame = self.timeFrame;
    clone.aggressiveness = self.aggressiveness;
    clone.priority = self.priority;
    clone.hasEarnings = self.hasEarnings;
    clone.daysQuantityBeforeEarnings = self.daysQuantityBeforeEarnings;
    clone.volume = self.volume;
    clone.openInterest = self.openInterest;
    clone.impliedVolatility = self.impliedVolatility;
    clone.oneWeekTheta = self.oneWeekTheta;
    clone.daysUntilExpiry = self.daysUntilExpiry;
    clone.delta = self.delta;
    clone.percentAboveBelowCurrentPrice = self.percentAboveBelowCurrentPrice;
    clone.numberOfStrikesBelowAboveCurrentPrice = self.numberOfStrikesBelowAboveCurrentPrice;
    clone.numberOfSdBelowAboveCurrentPrice = self.numberOfSdBelowAboveCurrentPrice;
    clone.ask = self.ask;
    clone.bid = self.bid;
    clone.useForwardPricing = self.useForwardPricing;
    return clone;
  };
}

export class CallOptimal implements ICallOptimal {
  symbol: string = '';
  strike: number = 0;
  return: number = 0;
  premium: number = 0;
  expiry: string = '';
  legType: string = '';
  probability: number = 0;
  probabilityInSigmas: string | number = 0;
  riskTolerance: string = '';
  timeFrame: string = '';
  aggressiveness: string = '';
  priority: number = 0;
  hasEarnings: boolean = false;
  daysQuantityBeforeEarnings: number = 0;
  volume: number = 0;
  openInterest: number = 0;
  impliedVolatility: number = 0;
  oneWeekTheta: number = 0;
  daysUntilExpiry: number = 0;
  delta: number = 0;
  percentAboveBelowCurrentPrice: number = 0;
  numberOfStrikesBelowAboveCurrentPrice: number = 0;
  numberOfSdBelowAboveCurrentPrice: string | number = 0;
  ask: number = 0;
  bid: number = 0;
  useForwardPricing: boolean = false;

  private constructor() {}

  static fromData = (data: ICallOptimal) => {
    const model = new CallOptimal();
    model.symbol = data.symbol;
    model.strike = data.strike;
    model.return = data.return;
    model.premium = data.premium;
    model.expiry = data.expiry;
    model.legType = data.legType;
    model.probability = data.probability;
    model.probabilityInSigmas = data.probabilityInSigmas;
    model.riskTolerance = data.riskTolerance;
    model.timeFrame = data.timeFrame;
    model.aggressiveness = data.aggressiveness;
    model.priority = data.priority;
    model.hasEarnings = data.hasEarnings;
    model.daysQuantityBeforeEarnings = data.daysQuantityBeforeEarnings;
    model.volume = data.volume;
    model.openInterest = data.openInterest;
    model.impliedVolatility = data.impliedVolatility;
    model.oneWeekTheta = data.oneWeekTheta;
    model.daysUntilExpiry = data.daysUntilExpiry;
    model.delta = data.delta;
    model.percentAboveBelowCurrentPrice = data.percentAboveBelowCurrentPrice;
    model.numberOfStrikesBelowAboveCurrentPrice = data.numberOfStrikesBelowAboveCurrentPrice;
    model.numberOfSdBelowAboveCurrentPrice = data.numberOfSdBelowAboveCurrentPrice;
    model.ask = data.ask;
    model.bid = data.bid;
    model.useForwardPricing = data.useForwardPricing;
    return model;
  };

  static fromSelf = (self: CallOptimal) => {
    const clone = new CallOptimal();
    clone.symbol = self.symbol;
    clone.strike = self.strike;
    clone.return = self.return;
    clone.premium = self.premium;
    clone.expiry = self.expiry;
    clone.legType = self.legType;
    clone.probability = self.probability;
    clone.probabilityInSigmas = self.probabilityInSigmas;
    clone.riskTolerance = self.riskTolerance;
    clone.timeFrame = self.timeFrame;
    clone.aggressiveness = self.aggressiveness;
    clone.priority = self.priority;
    clone.hasEarnings = self.hasEarnings;
    clone.daysQuantityBeforeEarnings = self.daysQuantityBeforeEarnings;
    clone.volume = self.volume;
    clone.openInterest = self.openInterest;
    clone.impliedVolatility = self.impliedVolatility;
    clone.oneWeekTheta = self.oneWeekTheta;
    clone.daysUntilExpiry = self.daysUntilExpiry;
    clone.delta = self.delta;
    clone.percentAboveBelowCurrentPrice = self.percentAboveBelowCurrentPrice;
    clone.numberOfStrikesBelowAboveCurrentPrice = self.numberOfStrikesBelowAboveCurrentPrice;
    clone.numberOfSdBelowAboveCurrentPrice = self.numberOfSdBelowAboveCurrentPrice;
    clone.ask = self.ask;
    clone.bid = self.bid;
    clone.useForwardPricing = self.useForwardPricing;
    return clone;
  };
}
