import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil';
import { ApiService } from '../services';
import { SentimentModel } from '../hubs/sentiment-model';
import ApplicationContext from '../models/how/application-context';
import { guardRecoilDefaultValue } from './trade/trade-states';

export const fetchSentiments = async (symbols: string[]) => {
  if (!symbols || symbols.length === 0) {
    return undefined;
  }

  const powerhouseProHub = ApplicationContext.powerhouseProHub;

  if (!powerhouseProHub) {
    return undefined;
  }

  const sentiments = await powerhouseProHub.getSentiments(symbols);

  if (!sentiments || sentiments.length === 0) {
    return undefined;
  }

  return sentiments;
};

export const subscribeToSentiments = async (symbols: string[]) => {
  if (!symbols || symbols.length === 0) {
    return undefined;
  }

  const powerhouseProHub = ApplicationContext.powerhouseProHub;

  if (!powerhouseProHub) {
    return undefined;
  }

  await powerhouseProHub.sentimentsSubscription.subscribe(symbols);
};

export const unsubscribeFromSentiments = async (symbols: string[]) => {
  if (!symbols || symbols.length === 0) {
    return undefined;
  }

  const powerhouseProHub = ApplicationContext.powerhouseProHub;

  if (!powerhouseProHub) {
    return undefined;
  }

  await powerhouseProHub.sentimentsSubscription.unsubscribe(symbols);
};

//REST call
export const getQuotesAndSentiments = async (
  symbols: string[],
): Promise<{ quotes: any[]; sentiments: any[] } | undefined> => {
  const response = await ApiService.instance.post<string[], { quotes: any[]; sentiments: any[] }>(
    '/powerhouse/pro/how/getQuotesAndSentiments',
    symbols,
  );
  if (response.hasErrors || !response.data) {
    return undefined;
  }
  return response.data;
};

export const sentimentAtomFamily = atomFamily<SentimentModel | undefined, string>({
  key: 'sentimentsAtomFamily',
  default: undefined,
});

export const hubSentimentsState = selectorFamily({
  key: 'hubSentimentsStateKey',
  get:
    (symbols: string[]) =>
    ({ get }) => {
      if (symbols.length === 0) {
        return [];
      }
      const sentiments: { symbol: string; sentiment: SentimentModel | undefined }[] = [];
      for (let symbol of symbols) {
        const s = symbol.trim().toUpperCase();
        const sentiment = get(sentimentAtomFamily(s));
        sentiments.push({ symbol: s, sentiment: sentiment });
      }
      return sentiments;
    },
});

export const sentimentsUpdater = selector<SentimentModel[] | undefined>({
  key: 'sentimentsUpdater',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ set }, newSentiments) => {
    if (!newSentiments || guardRecoilDefaultValue(newSentiments)) {
      //reset(expandedQuotesAtomFamily(symbol));
      return;
    }
    for (let sentiment of newSentiments) {
      if (!sentiment.symbol || sentiment.symbol.trim() === '') {
        continue;
      }
      const symbol = sentiment.symbol.trim().toUpperCase();
      set(sentimentAtomFamily(symbol), sentiment);
    }
    //Update the internal cache as well with the latest signalR data.
    if (ApplicationContext.powerhouseProHub) {
      ApplicationContext.powerhouseProHub.sentimentsSubscription.set(newSentiments);
    }
  },
});
