import ShareIcon from '@mui/icons-material/Share';
import { Box, CircularProgress, Grid, Popover, Tooltip, Typography, useTheme } from '@mui/material';
import { createTradeTicketObject } from '@op/shared/src/models';
import { EmbedderEventType, EventType, Permissions } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { Combination } from '@op/shared/src/models/how/combination';
import SingleTrade from '@op/shared/src/models/how/single-trade';
import { PaymentsService } from '@op/shared/src/services';
import { accountState, customizationState, selectedSymbolState, whatIfSimulatorState } from '@op/shared/src/states';
import * as htmlToImage from 'html-to-image';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GuideItem } from '../side-menu';
import { EmailClientShare, LinkShare, StockShare, TelegramShare, TwitterShare } from '../single-trade';
import { IconComponent } from './icons';

export interface ISingleShareWidgetProps {
  data: SingleTrade;
  comb: Combination;
}

export const SingleShareWidget: React.FC<ISingleShareWidgetProps> = ({ data, comb }: ISingleShareWidgetProps) => {
  const themeA = useTheme();
  const account = useRecoilValue(accountState);
  const [telgramModalOpen, setTelegramModalOpen] = React.useState(false);
  const [twitterModalOpen, setTwitterModalOpen] = React.useState(false);
  const [mailModalOpen, setMailModalOpen] = React.useState(false);
  const [stockModalOpen, setStockModalOpen] = React.useState(false);
  const [linkModalOpen, setLinkModalOpen] = React.useState(false);
  const [screenshotsrc, setScreenshotSrc] = React.useState('');
  const [screenshotid, setScreenshotid] = React.useState<any>('');
  const [shareIconAnchor, setShareIconAnchor] = useState(null);
  const whatIfSimulator = useRecoilValue(whatIfSimulatorState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const currentTheme = useTheme();
  const [selectShare, setSelectShare] = useState(false);
  const customization = useRecoilValue(customizationState);
  const [shareEventCP, setShareEventCP] = useState(false);

  // if (!whatIfSimulator || !whatIfSimulator.whatIfDate || !customization || customization.isEmbeddingPlatform) {
  //   return null;
  // }

  let node: HTMLElement;
  const getNode = () => {
    if (comb.isTrade) {
      node = document.getElementById('screenshot') as HTMLElement;
    }
    if (comb.isIncome) {
      node = document.getElementById('income_screenshot') as HTMLElement;
    }
  };
  getNode();

  const quote = data.quote;
  const { symbol } = quote;
  const title = data.combinationTitle;
  const value = title.substring(0, title.indexOf('@'));
  const sentimentType: any = data.syrahSentiments;
  const sentimentValue = sentimentType.charAt(0).toUpperCase() + sentimentType.slice(1);
  const textareaTiltle = `${sentimentValue} $${symbol} Trade: ${title}`;
  const sentiment = data.syrahSentiments;
  if (!account || !account.securityModel) {
    return <></>;
  }

  const singleSharePermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_BY_EMAIL);
  const emailSharePermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_BY_EMAIL_CLIENT);
  const shareByTwitterPermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_BY_TWITTER);
  const shareByStockTwistPermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARE_TO_STOCK_TWITS);
  const shareByLinkPermission = account.securityModel.hasPermission(Permissions.ALLOW_COPY_LINK);

  const getScreenshot = async () => {
    // Temporary fix to get the node, if not exists
    setShareEventCP(true);
    if (!node) {
      getNode();
    }
    htmlToImage
      .toPng(node, {
        style: { backgroundColor: currentTheme.palette.background.paper },
      })
      .then(async function (dataUrl) {
        const img = new Image();
        img.src = dataUrl;
        setScreenshotSrc(dataUrl);
        if (comb && comb.positions && comb.positions.length > 0) {
          if (!whatIfSimulator.whatIfDate) {
            return null;
          }
          let legData: any[] = [];
          comb.positions.forEach((itemPosition: any) => {
            let position = {
              legType: itemPosition.legType,
              strikePrice: itemPosition.strikePrice,
              costBasis: itemPosition.costBasis,
              quantity: itemPosition.quantity,
              expiry: itemPosition.expiry,
            };
            legData.push(position);
          });
          const whatIfDate = whatIfSimulator.whatIfDate;
          const targetDate = new Date(
            Date.UTC(whatIfDate.getFullYear(), whatIfDate.getMonth(), whatIfDate.getDate(), 0, 0, 0),
          );
          const sendImageContents = {
            sharedTrade: {
              fullName: value,
              symbol: symbol,
              legs: legData,
              costBasis: comb.midPricedCost(),
              targetDate: targetDate.toISOString(),
              targetPrice: whatIfSimulator.whatIfSPrice,
              priceCalculationMethod: comb.priceCalculationMethod,
              combinationType: comb.combinationType,
            },
            screenshot: {
              content: dataUrl.substring(dataUrl.indexOf(',') + 1),
              mimeType: 'image/png',
              name: `Share: ${value}`,
              description: textareaTiltle,
            },
          };
          const response = await PaymentsService.instance.saveImage(sendImageContents);
          setScreenshotid(response.data);
          shareViaEventClick(response.data);
          setShareEventCP(false);
        }
      })
      .catch(function (error) {
        setShareEventCP(false);
        console.error('oops, something went wrong!', error);
      });
  };

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const handleShareIconClick = (event) => {
    setShareIconAnchor(event.currentTarget);
    setSelectShare(true);
  };

  const handleShareIconClose = () => {
    setShareIconAnchor(null);
    setSelectShare(false);
  };

  const shareViaEventClick = async (screenshotId: string) => {
    if (!customization || !customization.isEmbeddingPlatform || !customization.showIconForEventShare) {
      return;
    }
    const tradeTicketObj = createTradeTicketObject(comb, customization);
    const shareObject = {
      ...tradeTicketObj,
      companyName: quote.name,
      imgUrl: `${window.location.origin}/screenshot/${screenshotId}`,
      shareUrl: `${window.location.origin}/share/${screenshotId}`,
    };
    const message = { event: EmbedderEventType.ShareButtonExecute.toString(), data: shareObject };
    window.parent.postMessage(JSON.stringify(message), '*');
  };

  const renderShareEventIcon = () => {
    if (!customization || !customization.isEmbeddingPlatform || !customization.showIconForEventShare) {
      return;
    }
    return (
      <Grid item>
        {shareEventCP ? (
          <div>
            <CircularProgress size={15} sx={{ mr: 1, mt: 0.7, color: '#999999' }} />
          </div>
        ) : (
          <Tooltip title="Share Event" placement="top" arrow>
            <div>
              <IconComponent
                name="shareEventIcon"
                style={{ cursor: 'pointer', marginTop: 0.2 }}
                onClick={async () => {
                  await getScreenshot();
                }}
                stroke="#999999"
                hoverColor={themeA.palette.text.primary}
              />
            </div>
          </Tooltip>
        )}
      </Grid>
    );
  };

  const renderSingleShareWidget = () => {
    //showShareView &&
    if (
      !singleSharePermission &&
      !emailSharePermission &&
      !shareByTwitterPermission &&
      !shareByStockTwistPermission &&
      !shareByLinkPermission
    ) {
      return <Box p={1} style={{ margin: 0 }}></Box>;
    }
    return (
      <>
        <Tooltip title="Share Via" placement="top" arrow>
          <ShareIcon
            fontSize="medium"
            sx={{
              color: selectShare ? themeA.palette.info.light : '#999999',
              ':hover': { color: themeA.palette.info.light },
              cursor: 'pointer',
            }}
            onClick={handleShareIconClick}
          />
        </Tooltip>
        <Popover
          open={Boolean(shareIconAnchor)}
          anchorEl={shareIconAnchor}
          onClose={handleShareIconClose}
          PaperProps={{
            elevation: 2,
            sx: {
              backgroundColor: themeA.palette.info.dark,
              overflow: 'visible',
              mt: 2,
              width: '250px',
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 10,
                width: 10,
                height: 10,
                bgcolor: themeA.palette.info.dark,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Grid container justifyContent="space-between" textAlign={'center'} px={0.8} mt={0.5}>
            <GuideItem selector=".shareTradeButton_helpPinPlaceholder" />
            <Grid item mt={0.5}>
              <Typography variant="body1" lineHeight={1.2}>
                Share via
              </Typography>
            </Grid>
            <Grid item>
              {singleSharePermission && (
                <Tooltip title="Send" placement="top" arrow>
                  <div>
                    <IconComponent
                      name="telegramIcon"
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getScreenshot();
                        logActivity('BUTTON', 'shareViaEmail');
                        setTelegramModalOpen(true);
                      }}
                      stroke="#999999"
                      hoverColor={themeA.palette.text.primary}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              {emailSharePermission && (
                <Tooltip title="E-Mail" placement="top" arrow>
                  <div>
                    <IconComponent
                      name="emailIcon"
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getScreenshot();
                        logActivity('BUTTON', 'shareViaClientEmail');
                        setMailModalOpen(true);
                      }}
                      stroke="#999999"
                      hoverColor={themeA.palette.text.primary}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              {shareByTwitterPermission && (
                <Tooltip title="Twitter" placement="top" arrow>
                  <div>
                    <IconComponent
                      name="twitterIcon"
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getScreenshot();
                        logActivity('BUTTON', 'shareViaTwitter');
                        setTwitterModalOpen(true);
                      }}
                      stroke="#999999"
                      hoverColor={themeA.palette.text.primary}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              {shareByStockTwistPermission && (
                <Tooltip title="StockTwits" placement="top" arrow>
                  <div>
                    <IconComponent
                      name="stockTwitsIcon"
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getScreenshot();
                        logActivity('BUTTON', 'shareViaStocktwits');
                        setStockModalOpen(true);
                      }}
                      stroke="#999999"
                      hoverColor={themeA.palette.text.primary}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              {shareByLinkPermission && (
                <Tooltip title="Link" placement="top" arrow>
                  <div>
                    <IconComponent
                      name="linkIcon"
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        await getScreenshot();
                        logActivity('BUTTON', 'copyShareToClipboard');
                        setLinkModalOpen(true);
                      }}
                      stroke="#999999"
                      hoverColor={themeA.palette.text.primary}
                    />
                  </div>
                </Tooltip>
              )}
            </Grid>
            {renderShareEventIcon()}
          </Grid>
        </Popover>
      </>
    );
  };

  return (
    <Grid container>
      {twitterModalOpen && (
        <TwitterShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          onHandleClose={() => setTwitterModalOpen(false)}
        />
      )}
      {mailModalOpen && (
        <EmailClientShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          screenshotid={screenshotid}
          sentiment={sentiment}
          onHandleClose={() => setMailModalOpen(false)}
        />
      )}
      {stockModalOpen && (
        <StockShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          onHandleClose={() => setStockModalOpen(false)}
        />
      )}
      {linkModalOpen && (
        <LinkShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          screenshotid={screenshotid}
          onHandleClose={() => setLinkModalOpen(false)}
        />
      )}
      {telgramModalOpen && (
        <TelegramShare
          title={data.combinationTitle}
          imgsrc={screenshotsrc}
          symbol={symbol}
          sentiment={sentiment}
          onHandleClose={() => setTelegramModalOpen(false)}
          screenshotId={screenshotid}
        />
      )}
      <Grid item container justifyContent={'flex-end'} sx={{ paddingX: '8px' }}>
        {renderSingleShareWidget()}
      </Grid>
    </Grid>
  );
};
