import { Position } from '.';
import { PortfolioPosition } from '..';
import { BuyOrSell } from '../enums/enums';

export interface ILeg {
  legType: string;
  quantity: number;
  expiry?: Date; //TODO: If this is coming from server, it will be string.
  strikePrice?: number;
  costBasis?: number;
  hash?: string;
  costBasisPerUnit?: number;
  buyOrSell?: BuyOrSell;
  premium?: number;
}

export class Leg implements ILeg {
  buyOrSell?: BuyOrSell = BuyOrSell.BUY;
  legType: string = '';
  quantity: number = 0;
  expiry?: Date;
  strikePrice?: number;
  strike?: number;
  costBasis?: number;
  hash?: string;
  costBasisPerUnit?: number;
  premium?: number;

  static fromBasicPosition = (
    quantity: number,
    legType: string,
    expiry?: Date,
    strikePrice?: number,
    costBasis?: number,
    buyOrSell?: BuyOrSell,
    premium?: number,
  ) => {
    const leg = new Leg();
    leg.quantity = quantity;
    leg.legType = legType;
    leg.expiry = expiry;
    leg.strikePrice = strikePrice;
    leg.costBasis = costBasis;
    leg.buyOrSell = buyOrSell;
    leg.premium = premium;
    return leg;
  };

  static fromPosition = (position: Position) => {
    const leg = new Leg();
    leg.buyOrSell = position.buyOrSell;
    leg.legType = position.legType;
    leg.quantity = position.quantity;
    leg.expiry = position.expiry;
    leg.costBasis = position.costBasis;
    leg.hash = position.hash;
    leg.strikePrice = position.strikePrice;
    leg.strike = position.strike;
    leg.premium = position.premium;
    //TODO: costbasisperunit and Premium are not available on Position.
    //leg.costBasisPerUnit =
    return leg;
  };

  static fromPortfolioPosition = (position: PortfolioPosition) => {
    const leg = new Leg();
    leg.buyOrSell = position.buyOrSell;
    leg.legType = position.legType;
    leg.quantity = position.quantity;
    leg.expiry = position.expiry;
    leg.costBasis = position.costBasis;
    leg.hash = position.hash;
    leg.strikePrice = position.strikePrice;
    leg.strike = position.strike;
    //TODO: costbasisperunit and Premium are not available on Position.
    //leg.costBasisPerUnit =
    return leg;
  };

  static fromSelf = (leg: Leg) => {
    const clone = new Leg();
    clone.legType = leg.legType;
    clone.quantity = leg.quantity;
    clone.expiry = leg.expiry;
    clone.strikePrice = leg.strikePrice;
    clone.strike = leg.strike;
    clone.costBasis = leg.costBasis;
    clone.hash = leg.hash;
    clone.costBasisPerUnit = leg.costBasisPerUnit;
    clone.premium = leg.premium;
    return clone;
  };
}
