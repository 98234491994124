export interface IvalidationErrors {
  message: string;
  rowNumber: number;
  symbol: string;
}

export class ValidationErrors implements IvalidationErrors {
  message = '';
  rowNumber = 0;
  symbol = '';

  public static fromJson = (data: ValidationErrors): ValidationErrors => {
    const model = new ValidationErrors();
    model.message = data.message;
    model.rowNumber = data.rowNumber;
    model.symbol = data.symbol;
    return model;
  };
}
