import { SortingType } from '@op/shared/src/models';
import { BasicPositionModel } from '@op/shared/src/models/how/basic-position-model';
import loadash from 'lodash';
import { IPortfolioCombinationAccount } from '.';
import { Filters, Sorts } from '../what/filters';
import { PortfolioCombinationEntity } from './portfolio-combination-entity';
export class PortfolioCombinationEntities {
  combinations: PortfolioCombinationEntity[] = [];
  originalCombinations: PortfolioCombinationEntity[] = [];

  static fromSelf = (self: PortfolioCombinationEntities) => {
    const model = new PortfolioCombinationEntities();
    model.combinations = self.combinations.map((c) => PortfolioCombinationEntity.fromSelf(c));
    model.originalCombinations = self.combinations.map((c) => PortfolioCombinationEntity.fromSelf(c));
    return model;
  };

  public filter = (filters: Filters): PortfolioCombinationEntities => {
    if (!filters) {
      return this;
    }
    let hashArray: string[] = [];
    // Filter By Alert and Notifications combines all hashes to load selected filter
    filters.data.map((f) => {
      const formattedFilter = f.value.toString().toLowerCase().trim().split(',');
      formattedFilter.map((value) => {
        if (value != '') {
          hashArray.push(value);
        }
      });
    });
    hashArray = loadash.uniq(hashArray);
    let portfolios = this.filterByHash(hashArray);
    return portfolios;
  };

  public sort = (sorts: Sorts) => {
    if (!sorts) {
      return this;
    }
    const portfolios = this.sortByCompanyName(sorts).sortByExpiry(sorts);
    return portfolios;
  };

  private filterByHash = (hashes: string[]) => {
    if (hashes.length === 0) {
      this.combinations = this.originalCombinations;
      return this;
    }
    const finalCombinations = this.originalCombinations.filter((c) => {
      return hashes.some((r) => {
        return r.trim() === c.hash.trim();
      });
    });
    this.combinations = finalCombinations;
    return this;
  };

  private sortByCompanyName = (sorts: Sorts) => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'companyName') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.combinations.sort((comb1, comb2) => {
          if (comb1 && comb2 && comb1.symbol > comb2.symbol) {
            return 1;
          }
          if (comb1 && comb2 && comb1.symbol < comb2.symbol) {
            return -1;
          }
          return 0;
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.combinations.sort((comb1, comb2) => {
          if (comb1 && comb2 && comb1.symbol > comb2.symbol) {
            return -1;
          }
          if (comb1 && comb2 && comb1.symbol < comb2.symbol) {
            return 1;
          }
          return 0;
        });
      }
    }
    return this;
  };

  private sortByExpiry = (sorts: Sorts) => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'expiryDate') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.combinations.sort((comb1, comb2) => {
          let expiry1 = comb1.daysToExpiry(comb1.positions as unknown as BasicPositionModel[]);
          let expiry2 = comb2.daysToExpiry(comb2.positions as unknown as BasicPositionModel[]);
          return (expiry1 != 999999 ? expiry1 : Infinity) - (expiry2 != 999999 ? expiry2 : Infinity);
        });
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.combinations.sort((comb1, comb2) => {
          let expiry1 = comb1.daysToExpiry(comb1.positions as unknown as BasicPositionModel[]);
          let expiry2 = comb2.daysToExpiry(comb2.positions as unknown as BasicPositionModel[]);
          return (expiry2 != 999999 ? expiry2 : -Infinity) - (expiry1 != 999999 ? expiry1 : -Infinity);
        });
      }
    }
    return this;
  };
}

export class PortfolioCombinationAccount implements IPortfolioCombinationAccount {
  portfolioId = -1;
  portfolioName = '';
  portfolioCombinations: PortfolioCombinationEntity[] = [];
  processingStatus = '';
  processingOn = new Date();
  isGlobal = false;
  portfolioTypeStatus = '';
  portfolioAccountName = '';

  public static fromData = (data: any): PortfolioCombinationAccount => {
    const porfolioAccount = new PortfolioCombinationAccount();
    porfolioAccount.portfolioId = data.accountId;
    porfolioAccount.portfolioName = data.accountName;
    if (
      data.portfolioCombinations != undefined &&
      data.portfolioCombinations != null &&
      data.portfolioCombinations.length > 0
    ) {
      data.portfolioCombinations.forEach((item: any) => {
        const portfolioAccountEntity = PortfolioCombinationEntity.fromData(item);
        portfolioAccountEntity.portfolioId = data.accountId;
        portfolioAccountEntity.portfolioName = data.accountName;
        porfolioAccount.portfolioCombinations.push(portfolioAccountEntity);
      });
    }
    porfolioAccount.processingStatus = data.processingStatus;
    porfolioAccount.processingOn = data.processingOn;
    porfolioAccount.isGlobal = data.isGlobal;
    porfolioAccount.portfolioTypeStatus = data.portfolioTypeStatus;
    porfolioAccount.portfolioAccountName = data.portfolioAccountName;
    return porfolioAccount;
  };

  public static fromSelf = (account: PortfolioCombinationAccount): PortfolioCombinationAccount => {
    const cloneAccount = new PortfolioCombinationAccount();
    cloneAccount.portfolioId = account.portfolioId;
    cloneAccount.portfolioName = account.portfolioName;
    cloneAccount.portfolioCombinations = account.portfolioCombinations.map((c) =>
      PortfolioCombinationEntity.fromSelf(c),
    );
    cloneAccount.processingStatus = account.processingStatus;
    cloneAccount.processingOn = account.processingOn;
    cloneAccount.isGlobal = account.isGlobal;
    cloneAccount.portfolioAccountName = account.portfolioAccountName;
    return cloneAccount;
  };

  get portfolioDisplayName() {
    return 'Display name not implemented';
  }
}
