import { OptionChain, StandardDeviation } from '.';
import { SentimentType, WhatIfDefaultPriceType } from '../enums/enums';
import ApplicationContext from './application-context';
import Combination from './combination';
import DateTimeHelper from './date-time-helper';
import formatting from './formatting';
import HowDataModel from './how-data-model';
import NumberFormatHelper from './number-format-helper';
import { TradingRangeSimulator } from './trading-range-simulator';

export interface IWhatifTheoretical {
  payoffValue: number;
  payoffFormatted: string;
  returnValue: number;
  returnPercentageFormatted: string;
}

class WhatIfSimulator {
  whatIfSPrice = 0;
  whatIfDate: Date | undefined = DateTimeHelper.getCurrentDate();
  whatIfVolatility = 0.3;
  sentiment: SentimentType | undefined;
  chain: OptionChain | undefined;
  stdDev: StandardDeviation | undefined;
  hasOption: boolean = false;
  last: number = 0;
  dateOfBellSlider: Date | undefined;
  sdSliderTicks: number[] = [];

  private constructor() {}

  static fromData = (
    howDataModel: HowDataModel,
    sentiment: SentimentType,
    tradingRangeSimulator: TradingRangeSimulator,
  ) => {
    const model = new WhatIfSimulator();
    model.hasOption = howDataModel.hasOption;
    model.last = howDataModel.quote.last;
    model.chain = howDataModel.chain;
    model.stdDev = howDataModel.stdDev;
    model.sentiment = sentiment;
    model.dateOfBellSlider = tradingRangeSimulator.dateOfBellSlider;
    model.whatIfDate = tradingRangeSimulator.dateOfBellSlider;
    model.sdSliderTicks = tradingRangeSimulator.sds;
    model.resetPriceRange();
    model.resetWhatIfVolatility();
    return model;
  };

  public static fromSelf = (self: WhatIfSimulator) => {
    const clone = new WhatIfSimulator();
    clone.whatIfSPrice = self.whatIfSPrice;
    clone.sentiment = self.sentiment;
    clone.hasOption = self.hasOption;
    clone.last = self.last;
    clone.chain = self.chain;
    clone.stdDev = self.stdDev;
    clone.whatIfDate = self.whatIfDate;
    clone.whatIfVolatility = self.whatIfVolatility;
    clone.dateOfBellSlider = self.dateOfBellSlider;
    return clone;
  };

  get whatIfDays() {
    return formatting.daysFromNow(this.whatIfDate);
  }

  set whatIfDays(newDays: number) {
    let date = formatting.getDateFromDaysInFuture(newDays);
    this.whatIfDate = date;
  }

  minVolatility = () => {
    if (this.chain) {
      let dateOfBell = this.dateOfBellSlider;
      let result = this.chain.impliedVolatility(dateOfBell) * 0.5;
      if (result > 0) {
        return result;
      }
    }
    return 0;
  };

  maxVolatility = () => {
    if (this.chain) {
      let dateOfBell = this.dateOfBellSlider;
      let result = this.chain.impliedVolatility(dateOfBell) * 1.5;
      return result;
    }
    return 100;
  };

  //TODO: revisit, i guess achor should be set by this value. Presently its doing 50%
  whatIfVolatilityAnchor = () => {
    let volatilityAnchor = 30;
    if (this.chain && this.stdDev) {
      volatilityAnchor = this.chain.impliedVolatility(this.dateOfBellSlider);
    }
    return volatilityAnchor;
  };

  resetPriceRange = () => {
    let sdPrice = NumberFormatHelper.roundNumber(this.last);
    if (this.sdSliderTicks.length >= 4) {
      //For Neutral strategy Price will be same as sds[2]
      sdPrice =
        this.sentiment === SentimentType.BULLISH
          ? this.sdSliderTicks[3]
          : this.sentiment === SentimentType.BEARISH
          ? this.sdSliderTicks[1]
          : this.sdSliderTicks[2];
    } else {
      sdPrice = this.sentiment === SentimentType.BULLISH ? this.last * 1.15 : this.last * 0.85;
    }
    const userSettings = ApplicationContext.configuration.userSettings;
    const priceType = userSettings?.whatIfDefaultPriceType?.toUpperCase() || WhatIfDefaultPriceType.SD.toUpperCase();
    const price = priceType === 'SD' ? sdPrice : this.last;
    this.whatIfSPrice = NumberFormatHelper.roundNumber(price);
  };

  resetWhatIfDate = () => {
    this.whatIfDate = this.dateOfBellSlider;
  };

  resetWhatIfVolatility = () => {
    this.whatIfVolatility = this.whatIfVolatilityAnchor();
  };

  whatifTheoretical = (comb: Combination): IWhatifTheoretical => {
    /* this is the calculation of the payoff price
    * based on the what id price and what if date
    * this is what we will need to change for the index
    * but for this we need the current price at the time
    * that this calculation is done
    * so... how do we get the current price?
    * voila... this.price has the current price
    
    * TODO: This is where the adjustments needs to be done
    * TODO: Before the values are passed to the payoff method
    * variables that we'll need: this.price, this.chain
    * find the nearest expiry > whatIfDate
    * find the date of expiration for the leg being evaluated
    * whatIfPrice = whatIfPrice
    */
    /**
     * As per the CR In portfolio for return "payoff should be divided with costbasis".
     * Mark should not be considered as Mark by def is a comb.price().
     * Costbasis is what we invested/bought positions.
     */
    // TODO: Instead of portfolio check position.isowned
    const po = comb.payoff(this.whatIfSPrice, this.whatIfDate, this.whatIfVolatility / 100);
    const cost = comb.isPortfolio ? comb.costBasisTotal() : comb.cost();
    const pc = cost !== 0 ? po / cost : 0;
    return {
      payoffValue: po,
      payoffFormatted: NumberFormatHelper.toCurrency(po),
      returnValue: pc,
      returnPercentageFormatted: NumberFormatHelper.toPercentage(Math.abs(pc * 100), 'auto'),
    };
  };
}

export default WhatIfSimulator;
