import ApplicationContext from './application-context';
import formatting from './formatting';

export class priceIncrementsResolver {
  static PROCE_MARGIN = 3;
  static getPriceIncrementForSymbol = (symbol: string, price: number) => {
    if (!symbol) {
      return 0;
    }
    // TODO: Configuration fix
    const symbolsOneOrFiveCentsIncrement = ApplicationContext.configuration.symbolsOneOrFiveCentsIncrement.indexOf(
      symbol.trim().toUpperCase(),
    );
    const symbolsOneCentIncrement = ApplicationContext.configuration.symbolsOneCentIncrement.indexOf(
      symbol.trim().toUpperCase(),
    );

    if (symbolsOneCentIncrement) {
      return 0.01;
    }
    var result;
    if (symbolsOneOrFiveCentsIncrement) {
      result = price < priceIncrementsResolver.PROCE_MARGIN ? 0.01 : 0.05;
      return result;
    }
    result = price < priceIncrementsResolver.PROCE_MARGIN ? 0.05 : 0.1;
    return result;
  };

  static getPriceAdjusted = (symbol: string, price: number) => {
    if (!symbol) {
      return 0;
    }
    var increment = priceIncrementsResolver.getPriceIncrementForSymbol(symbol, price);
    var newPrice = priceIncrementsResolver.adjustPrice(price, increment);

    return newPrice;
  };

  static adjustPrice = (price: number, increment: number) => {
    var adjustedPrice = price;
    var sign = Math.sign(adjustedPrice);
    adjustedPrice = adjustedPrice * sign;
    adjustedPrice = formatting.roundToMultiplier(adjustedPrice, increment);
    adjustedPrice = adjustedPrice * sign;
    return adjustedPrice;
  };
}
