import { Button, Grid, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent, OPDialogWidget } from '../common';
import { OPBoldTypograpghy } from '../styled';
import { logOutTradeStation } from './services';
import { tsAuthenticationStatusState, tsEnvironmentState } from './states/trade-station-states';

interface TsLogoutWidgetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const TsLogoutWidget: React.FC<TsLogoutWidgetProps> = ({ open, setOpen }: TsLogoutWidgetProps) => {
  const theme = useTheme();
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setTsAuthenticationStatus = useSetRecoilState(tsAuthenticationStatusState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [isLogoutButtonClicked, setIsLogoutButtonClicked] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onClickLogOut = async () => {
    const logoutRes = await logOutTradeStation(tsEnv);
    if (logoutRes.hasErrors || logoutRes.data === '') {
      setNotifications([
        {
          type: 'error',
          content: logoutRes.errors[0].message,
          isTradeStation: true,
        },
      ]);
      setOpen(false);
      setIsLogoutButtonClicked(false);
      return;
    }
    const url = logoutRes.data;
    window.localStorage.setItem('tsRedirectURL', window.location.pathname + window.location.search);
    window.localStorage.removeItem('tsEnv');
    setTsAuthenticationStatus(false);
    window.open(url, '_self');
  };

  const renderDialogTitle = () => {
    return (
      <Grid container>
        <IconComponent name="tsLogo" />
        <OPBoldTypograpghy style={{ marginLeft: 8, fontSize: '16px', color: '#fff' }}>Log Out</OPBoldTypograpghy>
      </Grid>
    );
  };

  const renderDialogAction = () => {
    return (
      <Grid container spacing={1} marginBottom={2}>
        <Grid item xs={6} textAlign={'center'}>
          <Button variant="text" onClick={handleClose}>
            <OPBoldTypograpghy sx={{ color: '#666666' }}>Cancel</OPBoldTypograpghy>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={isLogoutButtonClicked}
            variant="contained"
            size="large"
            sx={{
              backgroundColor: theme.palette.error.main,
              ':hover': {
                backgroundColor: theme.palette.error.main,
              },
            }}
            onClick={() => {
              setIsLogoutButtonClicked(true);
              onClickLogOut();
            }}>
            <OPBoldTypograpghy>Log Out</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };
  const renderDialogContent = () => {
    return (
      <Grid container paddingX={4} marginTop={4}>
        <OPBoldTypograpghy>Do you want to log out of TradeStation ?</OPBoldTypograpghy>
      </Grid>
    );
  };
  return (
    <OPDialogWidget
      open={open}
      onHandleClose={handleClose}
      dialogAction={renderDialogAction()}
      dialogContent={renderDialogContent()}
      title={renderDialogTitle()}
      dialogdividers
      dialogTitleStyle={{
        backgroundColor: theme.palette.error.main,
        color: '#fff',
      }}
    />
  );
};
