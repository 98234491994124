import { Grid, IconButton, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { OPBoldTypograpghy, OPSemiBoldTypograpghy } from '../../styled';
import React from 'react';
import { IconComponent } from '../../common';

type Props = {
  title: string;
  value: any;
  sx?: SxProps<Theme>;
  borderNone?: boolean;
  labelWithoutValue?: boolean;
  icon?: string;
  toolTipTitle?: string;
  titleColor?: string;
};

export const SummaryTypography: React.FC<Props> = ({ title, value, sx, borderNone, labelWithoutValue, titleColor }) => {
  const theme = useTheme();
  const color = titleColor ? titleColor : labelWithoutValue ? '#999' : theme.palette.text.primary;
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        borderRight: borderNone ? 'none' : `1px dotted ${theme.palette.divider}`,
      }}
      columnSpacing={1}
      paddingX={0.5}>
      <Grid item xs={6}>
        <Typography component="div" variant="captionSmall" textAlign="left" lineHeight={1.2} sx={{ color: color }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <OPBoldTypograpghy variant="captionSmall" sx={{ ...sx }}>
          {value}
        </OPBoldTypograpghy>
      </Grid>
    </Grid>
  );
};

export const OrderSummaryTypography: React.FC<Props> = ({ title, value, sx, borderNone, icon, toolTipTitle }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      alignItems={'center'}
      sx={{
        borderRight: borderNone ? 'none' : `1px dotted ${theme.palette.divider}`,
      }}
      marginY={1}
      paddingX={1}>
      <Grid item xs={12}>
        <OPSemiBoldTypograpghy variant="captionSmall">{title}</OPSemiBoldTypograpghy>
        {icon ? (
          <Tooltip title={`${toolTipTitle}`} placement="top" arrow>
            <IconButton sx={{ padding: '4px' }}>
              <IconComponent name={`${icon}`} stroke="#999" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Grid>
      <Grid item xs={12} textAlign={'right'}>
        <OPBoldTypograpghy variant="subHeader" sx={{ ...sx }}>
          {value}
        </OPBoldTypograpghy>
      </Grid>
    </Grid>
  );
};
