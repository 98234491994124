import { Grid, styled } from '@mui/material';

export const TsSpan = styled('span')(({ color, theme }) => ({
  color: color,
  fontSize: theme.typography.captionSmall.fontSize,
}));

export const OrderTicketGrid = styled(Grid)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.secondary.dark}`,
  boxShadow: '4px',
  ':hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  position: 'relative',
}));
