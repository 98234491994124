import { Box, TableCell, TableRow, styled, tableCellClasses } from '@mui/material';
import { OptionsPlayTextField, ScrollableBox } from '../styled/options-play-components';

export const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    fontWeight: 400,
    borderBottom: 0,
    padding: ' 1px 8px 1px 8px',
    margin: '8px',
  },
  [`&.${tableCellClasses.head}`]: {
    fontSize: '12px',
    fontWeight: 800,
    align: 'center',
    backgroundColor: theme.palette.info.dark,
    boxShadow: 4,
    padding: ' 0px 16px 0px 8px',
  },
}));

export const ShadowCell: any = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  align: 'center',
  borderBottom: 0,
  width: '150px',
  borderRight: `1px solid ${theme.palette.info.dark}`,
  borderRadius: 8,
  boxShadow: '2px 0 5px rgb(0,0,0,0.45)',
}));
export const StyledTableRow: any = styled(TableRow)(({ theme }) => ({
  ':hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4,
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  backgroundColor: theme.palette.selectAndTextField.light,
  borderRadius: 4,
  outline: '1px solid #BFE0FA',
}));

export const StyledCheckBox: any = styled(Box)(({ theme }) => ({
  width: 10,
  height: 10,
  outline: `2px solid ${theme.palette.primary.main}`,
  outlineOffset: '2px',
  borderRadius: 1,
}));

export const TSTextField = styled(OptionsPlayTextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-input': {
      paddingLeft: '8px',
      paddingTop: '3px',
      paddingBottom: '3px',
      paddingRight: 0,
    },
  },
}));

export const ScrollableContainer = styled(ScrollableBox)(({ theme }) => ({
  width: '100%',
  marginLeft: 0,
  '@media': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'row',
  paddingY: 0.2,
  scrollbarWidth: 'thin',
}));
