import { IBuilderData, IncomeBuilder, IncomeCombination, IncomeStrategies } from '@op/shared/src/models';
import {
  BuyOrSell,
  LegType,
  OptionType,
  PriceCalculationMethod,
  tradeSimulatorOperationType,
} from '@op/shared/src/models/enums/enums';
import BasicCombination from '@op/shared/src/models/how/basic-combination';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import {
  costBasisState,
  howDataState,
  incomeCombinationSelectedIdState,
  incomeCombinationUpdaterState,
  incomeStrategiesState,
  incomeStrategyHasShareState,
  resetIncomeState,
  sharesState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradeTicketCombinationState,
  tradingRangeSimulatorState,
  viewState,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TradeTicketWidget } from './trade-ticket-widget';

export const IncomeStrategyTicketWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const [incomeCombinationSelectedId, setIncomeCombinationSelectedId] = useRecoilState(
    incomeCombinationSelectedIdState,
  );
  const setWhatIfSimulator = useSetRecoilState(whatIfSimulatorState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorState);
  const setIncomeStrategies = useSetRecoilState(incomeStrategiesState);
  const setIncomeCombinations = useSetRecoilState(incomeCombinationUpdaterState);
  const setCostBasis = useSetRecoilState(costBasisState);
  const setShares = useSetRecoilState(sharesState);
  const setHasShares = useSetRecoilState(incomeStrategyHasShareState);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setResetIncome = useSetRecoilState(resetIncomeState);
  const setSubView = useSetRecoilState(subViewState);
  const setView = useSetRecoilState(viewState);
  const setTradeTicket = useSetRecoilState(tradeTicketCombinationState);

  useEffect(() => {
    const tradeTicketFromLS = JSON.parse(localStorage.getItem('tradeTicketCombination'));
    if (!tradeTicketFromLS) {
      return;
    }
    const isIncome = (tradeTicketFromLS.view as string) === 'income';
    if (!isIncome) {
      return;
    }
    if (!howData) {
      return;
    }
    initializeIncomeIfPossible(tradeTicketFromLS);
  }, [howData]);

  const initializeIncomeIfPossible = (data: any) => {
    if (!howData) {
      return;
    }
    /**
     * Note:
     * Below condition is imp as it removes the localstorage on symbol change.
     */

    if (howData.symbol !== data.symbol) {
      localStorage.removeItem('tradeTicketCombination');
      setSubView(undefined);
      return;
    }
    const costBasis = data?.costBasis ? (data.costBasis as number) : howData.quote.last;
    const shares = data?.shares ? (data.shares as number) : 100;
    const incomeStrategies = IncomeStrategies.fromData(howData, shares, costBasis);
    const sentiment = incomeStrategies.sentiment;
    if (!sentiment) {
      throw new Error('sentiment  is undefined');
    }
    //TODO: have typed share result.
    const enumKey = formatting.pascalToSnakeCase(data.priceCalculationMethod);
    const priceCalculationMethod = PriceCalculationMethod[enumKey as keyof typeof PriceCalculationMethod];
    const targetPrice = data.targetPrice as number;
    let targetDate = DateTimeHelper.resolveDate(data.targetDate);
    // NOTE: The system works if we do not have datepart to it. Hence, only taking datepart and stripping timepart.
    if (targetDate) {
      targetDate.setHours(0, 0, 0, 0);
    }
    const optionLegIndex = data.legs.findIndex((l) => l.type !== LegType.SECURITY);
    const isCall = data.legs.some((l: any) => l.legType.toUpperCase() === 'CALL');
    const leg: any = {
      legType: data.legs[optionLegIndex].legType,
      strike: data.legs[optionLegIndex].strikePrice,
      expiry: data.legs[optionLegIndex].expiry,
    };
    const legWithPremium = { ...leg, premium: data.legs[optionLegIndex].premium };
    let savedCombination: IncomeCombination;
    if (isCall) {
      savedCombination = new IncomeBuilder(howData as IBuilderData)
        .withShares(Math.abs(data.legs[optionLegIndex].quantity) * 100)
        /**
         * !Important - Below line needs to be analysed,as setting premium will fix the problem.
         */
        .withCostBasisPerUnit(howData.quote.last)
        .withPriceCalculationMethod(priceCalculationMethod)
        .assembleCall(leg);
    } else {
      savedCombination = new IncomeBuilder(howData as IBuilderData)
        .withShares(Math.abs(data.legs[optionLegIndex].quantity) * 100)
        .withPriceCalculationMethod(priceCalculationMethod)
        .assemblePut(leg);
    }
    if (isCall) {
      savedCombination.isIncomeSpecific = true;
    }
    incomeStrategies.incomeOptionPair.set(isCall ? OptionType.CALL : OptionType.PUT, savedCombination);
    const newCombinations: BasicCombination[] = incomeStrategies.Combinations.filter(
      (c): c is IncomeCombination => c !== undefined,
    ).map((c) => c);
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, newCombinations);
    const whatIfSimulator = WhatIfSimulator.fromData(howData, sentiment, tradingRangeSimulator);
    whatIfSimulator.whatIfDate = targetDate;
    if (targetPrice) {
      whatIfSimulator.whatIfSPrice = targetPrice;
    }

    const optionLeg = data.legs.find((leg: { legType: LegType }) => {
      return leg.legType !== LegType.SECURITY;
    });
    let combinationHasShares = optionLeg.legType === LegType.CALL ? true : false;
    const incomeCombinationSelectedId = !combinationHasShares ? 1 : 0;
    const hasShares = !combinationHasShares ? 1 : 0;

    // TODO : IncomeProfiltLossWidget is not working
    setView('income');
    setSubView('tradeTicket');
    setSimulatorOperation(tradeSimulatorOperationType.ShowRisksandCheckilst);
    setShares(shares * 100 || 100); //TODO: Check by sharing shares income.
    setCostBasis(howData.quote.last);
    setIncomeStrategies(incomeStrategies);
    setIncomeCombinationSelectedId(incomeCombinationSelectedId);
    setHasShares(hasShares);
    setIncomeCombinations(incomeStrategies.Combinations);
    setTradingRangeSimulator(tradingRangeSimulator);
    setWhatIfSimulator(whatIfSimulator);
    setResetIncome({ reason: 'completed', shouldReset: false });
    let tradeTicketCombnation: IncomeCombination;
    if (isCall) {
      tradeTicketCombnation = new IncomeBuilder(howData as IBuilderData)
        .withShares(Math.abs(data.legs[optionLegIndex].quantity) * 100)
        /**
         * !Important - Below line needs to be analysed,as setting premium will fix the problem.
         */
        .withCostBasisPerUnit(legWithPremium.premium)
        .withPriceCalculationMethod(priceCalculationMethod)
        .assembleCall(legWithPremium);
    } else {
      tradeTicketCombnation = new IncomeBuilder(howData as IBuilderData)
        .withShares(Math.abs(data.legs[optionLegIndex].quantity) * 100)
        .withPriceCalculationMethod(priceCalculationMethod)
        .assemblePut(legWithPremium);
    }
    if (isCall) {
      tradeTicketCombnation.isIncomeSpecific = true;
    }
    setTradeTicket({
      combination: tradeTicketCombnation,
      resultingCombination: undefined,
    });
  };

  return <TradeTicketWidget />;
};
