import { PortfoliouploadData } from '.';

export class PortfolioUploadedDataList {
  data: PortfoliouploadData[] = [];
  recordsFiltered: number = 0;
  recordsTotal: number = 0;


  static fromData = (data: any) => {
    if (!data) {
      throw new Error('Data is undefined');
    }
    const model = new PortfolioUploadedDataList();
    model.data = data.watchLists.map((w: any) => PortfoliouploadData.fromJson(w));
    return model;
  };

  static fromSelf = (self: PortfolioUploadedDataList) => {
    if (!self) {
      throw new Error('Self is undefined');
    }
    const clone = new PortfolioUploadedDataList();
    clone.data = self.data.map((wl) => PortfoliouploadData.fromSelf(wl));
    return clone;
  };
}
