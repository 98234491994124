import { Combination, ILeg, IncomeCombination, PortfolioManagementCombination } from '@op/shared/src/models';
import { tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import { tradeSimulatorOperationTypeState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { PlainEnglishOfCombinationsWidget, RiskInvestmentWidget, TabPanel } from '../common';
import { IncomeSettingsWidget } from '../income';
import { OptionGridWidget } from '../income/option-grid-widget';
import { CombinationEditorWidget } from '../modify';
import { PLSimulatorWidget } from '../pl-simulator';
import { PLCalculatorWidget } from '../portfolio';
import { ExplainStrategyGreekWidget } from '../single-trade';
import { TradingRangeSimulatorWidget } from '../trading-range-simulator';
import { IncomeProfitAndLossTargetWidget, ProfitAndLossTargetWidget } from '../why/profit-and-loss-target-widget';
export interface ISimulatorWidgetProps {
  combinations?: (Combination | PortfolioManagementCombination | undefined)[];
  combination?: Combination | PortfolioManagementCombination | IncomeCombination | undefined;
  onEditCombinationCallback?: (action: string, legs?: ILeg[]) => void;
  shouldIncludeStrategyConstructor?: boolean;
}

//TODO: OnEditCombinationCallback is just passed over, It should be recoil or useCallback with useMemo for optamization
export const SimulatorWidget: React.FC<ISimulatorWidgetProps> = ({
  combinations,
  combination,
  onEditCombinationCallback,
  shouldIncludeStrategyConstructor,
}: ISimulatorWidgetProps) => {
  const tradeSimulatorOperation = useRecoilValue(tradeSimulatorOperationTypeState);
  const renderCombinationEditorIfPossible = () => {
    if (!onEditCombinationCallback) {
      return <>Callback handler not defined.</>;
    }
    return (
      <CombinationEditorWidget
        onEditCombinationCallback={onEditCombinationCallback}
        shouldIncludeStrategyConstructors={shouldIncludeStrategyConstructor}
      />
    );
  };

  const renderIncomeSettingsTab = () => {
    return <IncomeSettingsWidget isMinimalistic={true} />;
  };

  const renderOperationWidget = () => {
    switch (tradeSimulatorOperation) {
      case tradeSimulatorOperationType.PLSimulator: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.PLSimulator}>
            <PLSimulatorWidget />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.TradingRangeSimulator: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.TradingRangeSimulator}>
            <TradingRangeSimulatorWidget />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.PLCalculator: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.PLCalculator}>
            <PLCalculatorWidget />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.PlainEnglish: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.PlainEnglish}>
            <PlainEnglishOfCombinationsWidget combinations={combinations} />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.Modify: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.Modify}>
            {renderCombinationEditorIfPossible()}
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.RiskInvestmentCalculator: {
        return (
          <TabPanel
            selectedIndex={tradeSimulatorOperation}
            index={tradeSimulatorOperationType.RiskInvestmentCalculator}>
            <RiskInvestmentWidget />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.IncomeSettings: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.IncomeSettings}>
            {renderIncomeSettingsTab()}
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.OptionsGrid: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.OptionsGrid}>
            <OptionGridWidget />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.IncomeProfitAndLossTarget: {
        return (
          <TabPanel
            selectedIndex={tradeSimulatorOperation}
            index={tradeSimulatorOperationType.IncomeProfitAndLossTarget}>
            <IncomeProfitAndLossTargetWidget />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.ShowRisksandCheckilst: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.ShowRisksandCheckilst}>
            <ProfitAndLossTargetWidget data={combination as Combination} />
          </TabPanel>
        );
      }
      case tradeSimulatorOperationType.ExplainStrategyandGreeks: {
        return (
          <TabPanel
            selectedIndex={tradeSimulatorOperation}
            index={tradeSimulatorOperationType.ExplainStrategyandGreeks}>
            <ExplainStrategyGreekWidget data={combination as IncomeCombination} />
          </TabPanel>
        );
      }
      default: {
        return (
          <TabPanel selectedIndex={tradeSimulatorOperation} index={tradeSimulatorOperationType.PLSimulator}>
            <PLSimulatorWidget />
          </TabPanel>
        );
      }
    }
  };

  return renderOperationWidget();
};
