import { IAllowedStrategy, IStrategyStrategy } from '../configuration';
import { BuyOrSell, LegType } from '../enums/enums';
import { ITradingStrategy } from './trading-strategy';

export interface IStrategyTemplate {
  template: IStrategyStrategy;
  buyOrSell: BuyOrSell; //strategy.buyOrSell,
  displayedName: string;
  iconName: string;
  incomeBuyOrSell: string;
  absQuantity: number | undefined;
}

export class StrategiesProvider {
  static strategyTemplateByEigenvalue: Map<string, IStrategyTemplate> = new Map();
  static sentiments = new Set<string>();
  static strategyTemplatesBySentiment: Map<string, IStrategyTemplate[]> = new Map();

  private static eigenvalue = (strategy: IStrategyStrategy, strategyBuyOrSell: string) => {
    const legs = strategy.legs;
    legs.sort(function (a, b) {
      if (a.legType === LegType.SECURITY) {
        return -1;
      }
      if (b.legType === LegType.SECURITY) {
        return 1;
      }
      let result = a.strike - b.strike;
      if (!result) {
        result = (a.expiry || 0) - (b.expiry || 0);
      }
      if (!result) {
        result = a.legType === LegType.CALL ? -1 : 1;
      }

      return result;
    });

    let value = '';
    let i = 0;
    for (; i < legs.length; i++) {
      let leg = legs[i];
      let preLeg = legs[i - 1];
      let qty = leg.buyOrSell === strategyBuyOrSell ? leg.quantity : -leg.quantity;
      let preQty = 1;
      if (preLeg) {
        preQty = preLeg.buyOrSell === strategyBuyOrSell ? preLeg.quantity : -preLeg.quantity;
        if (preLeg.legType === LegType.SECURITY) {
          preQty = preQty / 100;
        }
      }
      if (leg.legType === LegType.SECURITY) {
        qty = qty / 100;
      }
      value += ((preLeg ? qty / preQty : qty > 0 ? 1 : -1) * 100).toFixed(0);
      value +=
        !preLeg || preLeg.legType === LegType.SECURITY
          ? 2
          : preLeg.expiry == leg.expiry
          ? 0
          : (preLeg.expiry || 0) < (leg.expiry || 0)
          ? -1
          : 1;
      value += !preLeg || preLeg.legType === LegType.SECURITY ? 2 : leg.strike == preLeg.strike ? 0 : 1;
      value += leg.legType === LegType.SECURITY ? 0 : leg.legType === LegType.CALL ? 1 : 2;
      value += '|';
    }

    return value;
  };

  static getTemplateByName = (strategyName?: ITradingStrategy | string, buyOrSell?: BuyOrSell) => {
    if (!strategyName) {
      return undefined;
    }
    buyOrSell = buyOrSell || BuyOrSell.BUY;
    let name = '';
    if (typeof strategyName === 'string') {
      name = strategyName;
    } else {
      name = strategyName.strategyName;
    }
    for (let eigenvalue in StrategiesProvider.strategyTemplateByEigenvalue) {
      const strategy = StrategiesProvider.strategyTemplateByEigenvalue.get(eigenvalue);
      if (!strategy) {
        return undefined;
      }
      if (
        strategy.buyOrSell.trim().toUpperCase() === buyOrSell.toString().toUpperCase() &&
        strategy.template.name === name
      ) {
        return strategy.template;
      }
    }
    // return StrategiesProvider.findTemplate((strategy: IStrategyTemplate) => {
    //   return strategy && strategy.buyOrSell === buyOrSell && strategy.template.name === name;
    // });
  };

  static initialize = (allowedStrategies: IAllowedStrategy[]) => {
    for (let allowedStrategy of allowedStrategies) {
      StrategiesProvider.sentiments.add(allowedStrategy.name);
      StrategiesProvider.strategyTemplatesBySentiment.set(allowedStrategy.name, []);
      for (let strategy of allowedStrategy.strategies) {
        const template = strategy.strategy;
        const eigenvalue = StrategiesProvider.eigenvalue(template, strategy.buyOrSell);
        const currentStrategyTemplate = {
          template: strategy.strategy,
          buyOrSell: BuyOrSell[strategy.buyOrSell.trim().toUpperCase() as keyof typeof BuyOrSell],
          displayedName: strategy.name,
          iconName: strategy.name.trim(),
          incomeBuyOrSell: '',
          absQuantity: undefined,
        };
        StrategiesProvider.strategyTemplateByEigenvalue.set(eigenvalue, currentStrategyTemplate);
        let sentimentOptions = StrategiesProvider.strategyTemplatesBySentiment.get(allowedStrategy.name);
        if (!sentimentOptions) {
          throw new Error('sentimentOptions is undefined');
        }
        sentimentOptions.push(currentStrategyTemplate);
      }
    }
  };
}
