import { Constants } from '@op/shared/src/constant';
import { accountState } from '@op/shared/src/states';
import { useEffect, useState } from 'react';
import { NavigateOptions, To, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export const useNavigateSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (params: any, path?: string) => navigate(`${path ? path : location.pathname}?${createSearchParams(params)}`);
};

export const useOPNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const account = useRecoilValue(accountState);
  const [isloggedIn, setIsLoggedIn] = useState(false);
  const defaultRoute = '/';

  useEffect(() => {
    if (!account) {
      setIsLoggedIn(false);
      return;
    }
    setIsLoggedIn(true);
  }, [account]);

  const formatUrl = (to: To) => {
    /**
     * TODO: Recheck
     * on back to app button, useeeffct is runs two times
     * with intial '/' and second run '/login'
     * So below condition has kept.
     */
    if (account && to.toString().includes('/login')) {
      to = defaultRoute;
    }
    /**
     * scenarios :
     * => User loads the /login directly return URL is not required.
     * => when user navigates with in "protected routes" return URL is not required
     * => when user loads any protected url path, should navigate to login with return URL
     *    {domainName}/{destination}?returnUrl={encoded destination path}
     * => For Share Via Anonymous :
     *    * Return URL not required
     *    * On click of "Already have an account" return url should form after navigating to login
     *      {domainName}/login?returnUrl=/share/{shareID}
     *    * On click of "Sign Me Up" return url should form after navigating to registration
     *      {domainName}/registration?returnUrl=/share/{shareID}
     * => To maintain return URL on navigating to multiple screens :
     *    a. Check for return URL from location
     *    b. load same url for the destination path.
     *    (Example : load anonymous share via --> click on already have an account -->
     *      click on Don't have an account in login screen --> fill details --> again come to
     *      login screen --> return URL will be maintained)
     */
    const isReturnUrlExists = location.search.includes(`?${Constants.returnUrlKey}`);
    if (isReturnUrlExists) {
      return `${to}${location.search}`;
    }
    const returnUrl = encodeURIComponent(`${location.pathname + location.search || defaultRoute}`);
    const canHideReturnURl =
      isloggedIn || returnUrl.includes('login') || returnUrl.includes('forgot') || returnUrl.includes('registration');

    return `${to}${canHideReturnURl ? '' : `?${Constants.returnUrlKey}=${returnUrl}`}`;
  };

  const formatOptions = (options?: NavigateOptions) => {
    return {
      replace: true,
    };
  };

  //TODO: NAviagte FUnction needs to use
  return (to: To, options?: NavigateOptions) => navigate(formatUrl(to), formatOptions(options));
};
