import { Constants } from '@op/shared/src/constant';
import { getThemeType, Account, StrategiesProvider } from '@op/shared/src/models';
import { AuthenticationService, getLocales, PowerhouseProService } from '@op/shared/src/services';
import { accessTokenKey, accountState, configurationState, lightboxDataState, themeState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { useGlobalization } from '../states/use-globalization';

export const SeamlessLoginWidget: React.FC = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const setGlobalizationLanguage = useGlobalization();
  const setAccount = useSetRecoilState(accountState);
  const setConfiguration = useSetRecoilState(configurationState);
  const setActiveTheme = useSetRecoilState(themeState);
  const setLightboxData = useSetRecoilState(lightboxDataState);
  const resetAccount = useResetRecoilState(accountState);
  const resetRoute = useMatch('/password/reset');
  const shareRoute = useMatch('/share/*');

  const defaultRoute = '/';
  let returnUrl = defaultRoute;

  useEffect(() => {
    const token = searchParams.get(Constants.hashTokenKey);
    if (!token || token.toString().trim() === '') {
      navigate('/login', { replace: true });
      return;
    }
    returnUrl = searchParams.get(Constants.returnUrlKey) || defaultRoute;
    (async () => await processToken(token))();
  }, []);

  const fetchConfigration = async () => {
    const configurationResponse = await PowerhouseProService.instance.Configuration();

    const configuration = configurationResponse.data;
    if (!configuration) {
      return undefined;
    }
    return configuration;
  };

  const processToken = async (token: string) => {
    const response = await AuthenticationService.instance.getAccessKeyAndToken(token);
    if (response.hasErrors || !response.data) {
      // If there is any return URL That will be maintained
      navigate('/login', { replace: true });
      return;
    }
    const accountData = response.data as any;
    const accountFromResponse = Account.fromTokenResponse(accountData);
    localStorage.setItem(accessTokenKey, accountData.access_token);
    const configuration = await fetchConfigration();
    const localeResponse = await getLocales();
    if (!configuration) {
      localStorage.removeItem(accessTokenKey);
      if (!shareRoute && !resetRoute) {
        navigate('/login', { replace: true });
      }
      resetAccount();
      return;
    }
    if (configuration.strategiesConfiguration) {
      StrategiesProvider.initialize(configuration.strategiesConfiguration.allowedStrategies);
    }
    const lightboxData = configuration?.lightbox;
    const theme = getThemeType(configuration.userSettings?.theme || '');
    setConfiguration(configuration);
    setGlobalizationLanguage(localeResponse, configuration, false);
    setActiveTheme(theme);
    setLightboxData(lightboxData);
    setAccount(accountFromResponse);
    navigate(returnUrl, { replace: true });
  };

  return null;
};
