import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { reportSearchState } from '..';
import { IFilter2, Sorts } from '../../models';
import NumberFormatHelper from '../../models/how/number-format-helper';
import { IShortPutReport, ShortPuts } from '../../models/reports/short-put';
import { IFilterGroup } from '../../models/what/i-filter-group';
import { FuseConfig } from '../configuration/fuse-search-config';
import { guardRecoilDefaultValue } from '../trade';

/* =================== FILTERS ================= */
const hasEarningFilter = {
  title: 'Has Earnings',
  name: 'hasEarnings',
  translationKey: 'what.tradeIdeas.ideas.hasEarnings',
  filters: [
    {
      title: 'Yes',
      name: 'yes',
      translationKey: 'what.tradeIdeas.ideas.yes',
      value: 'Y',
      selected: true,
    },
    {
      title: 'No',
      name: 'no',
      translationKey: 'what.tradeIdeas.ideas.no',
      value: 'N',
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByHasEarnings = (groups: IFilterGroup[], data: IShortPutReport[]) => {
  const group = groups.find((g) => g.name === 'hasEarnings');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: IShortPutReport[] = [];
  for (const filter of group.filters) {
    if (!filter.selected) {
      continue;
    }
    const items = data.filter((d) => d.earningsFlag.trim().toUpperCase() === filter.value);
    filtered = filtered.concat(items);
  }
  return filtered;
};

const IvRankFilter = {
  title: 'IV Rank',
  name: 'ivRank',
  translationKey: 'what.tradeIdeas.ideas.ivRank',
  filters: [
    {
      title: 'Less Than 50',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.lessThan50',
      value: 50,
      selected: true,
    },
    {
      title: 'Greater Than Or Equal To 50',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.greaterEqualTo50',
      value: 50,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByIVRank = (groups: IFilterGroup[], data: IShortPutReport[]) => {
  const group = groups.find((g) => g.name === 'ivRank');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: IShortPutReport[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    if (f.name === 'range1') {
      const items = data.filter(
        (t) => NumberFormatHelper.roundPercentageValue(t.impliedVolatilityRank) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range2') {
      const items = data.filter(
        (t) => NumberFormatHelper.roundPercentageValue(t.impliedVolatilityRank) >= (f.value as number),
      );
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const liquidityFilter = {
  title: 'Liquidity',
  name: 'liquidity',
  translationKey: 'what.tradeIdeas.ideas.liquidity',
  filters: [
    {
      title: 'Not Liquid',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.notLiquid',
      value: 3,
      selected: false,
    },
    {
      title: 'Somewhat Liquid',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.somewhatLiquid',
      value: 2,
      selected: false,
    },
    {
      title: 'Very Liquid',
      name: 'range3',
      translationKey: 'what.tradeIdeas.ideas.veryLiquid',
      value: 1,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByLiquidity = (groups: IFilterGroup[], data: IShortPutReport[]) => {
  const group = groups.find((g) => g.name === 'liquidity');
  if (!group) {
    return data;
  }
  let filtered: IShortPutReport[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    const items = data.filter((t) => t.liquidityRank === (f.value as number));
    filtered = filtered.concat(items);
  }
  return filtered;
};

const rawReturnFilter = {
  title: 'Annualized Return',
  name: 'annualizedReturn',
  translationKey: 'what.tradeIdeas.ideas.annualizedReturn',
  filters: [
    {
      title: '<5%',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.lessThan5',
      value: 5,
      selected: true,
    },
    {
      title: '5%-15%',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.lessThan15',
      value: 15,
      selected: true,
    },
    {
      title: '15%-30%',
      name: 'range3',
      translationKey: 'what.tradeIdeas.ideas.lessThan30',
      value: 30,
      selected: true,
    },
    {
      title: '>30%',
      name: 'range4',
      translationKey: 'what.tradeIdeas.ideas.greaterThan30',
      value: 30,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByRawReturn = (groups: IFilterGroup[], data: IShortPutReport[]) => {
  const group = groups.find((g) => g.name === 'annualizedReturn');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: IShortPutReport[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    if (f.name === 'range1') {
      const items = data.filter(
        (t) => NumberFormatHelper.roundPercentageValue(t.annualizedReturn) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range2') {
      const items = data.filter(
        (t) =>
          NumberFormatHelper.roundPercentageValue(t.annualizedReturn) >= 5 &&
          NumberFormatHelper.roundPercentageValue(t.annualizedReturn) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range3') {
      const items = data.filter(
        (t) =>
          NumberFormatHelper.roundPercentageValue(t.annualizedReturn) >= 15 &&
          NumberFormatHelper.roundPercentageValue(t.annualizedReturn) < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range4') {
      const items = data.filter(
        (t) => NumberFormatHelper.roundPercentageValue(t.annualizedReturn) >= (f.value as number),
      );
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const linkedPortfoliosFilter = {
  name: 'linkedPortfolios',
  title: 'Portfolio',
  translationKey: 'what.tradeIdeas.ideas.availableInPortfolio',
  filters: [
    {
      name: 'linkedPortfolios',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedPortfolios = (groups: IFilterGroup[], data: IShortPutReport[]) => {
  const group = groups.find((g) => g.name === 'linkedPortfolios');
  if (!group) {
    return data;
  }
  let filtered: IShortPutReport[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.filter((t) => t.portfolios.length > 0) : data;
    filtered = filtered.concat(items);
  }
  return filtered;
};

const linkedWatchListsFilter = {
  name: 'linkedWatchLists',
  title: 'Watchlist',
  translationKey: 'what.tradeIdeas.ideas.availableInWatchlist',
  filters: [
    {
      name: 'linkedWatchLists',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedWatchLists = (groups: IFilterGroup[], data: IShortPutReport[]) => {
  const group = groups.find((g) => g.name === 'linkedWatchLists');
  if (!group) {
    return data;
  }
  let filtered: IShortPutReport[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.filter((t) => t.watchlists.length > 0) : data;
    filtered = filtered.concat(items);
  }
  return filtered;
};

export const shortPutFilters = [
  hasEarningFilter,
  IvRankFilter,
  liquidityFilter,
  rawReturnFilter,
  linkedPortfoliosFilter,
  linkedWatchListsFilter,
] as IFilterGroup[];

/* END of Filters */

export const shortPutDataCachedState = atom<ShortPuts | undefined>({
  key: 'shortPutDataCachedStateKey',
  default: undefined,
});

export const sortsShortPutState = atom({
  key: 'sortsShortPutStateKey',
  default: new Sorts([Sorts.ShortPutDefaultSorts.data[0]]),
});

export const filtersShortPutState = atom({
  key: 'filtersShortPutStateKey',
  default: shortPutFilters,
});

export const shortPutState = selector<ShortPuts | undefined>({
  key: 'shortPutStateKey',
  get: ({ get }) => {
    const query = get(reportSearchState);
    const sorts = get(sortsShortPutState);
    const filters = get(filtersShortPutState);
    const shortPuts = get(shortPutDataCachedState);
    if (!shortPuts) {
      return undefined;
    }
    const clone = ShortPuts.fromSelf(shortPuts);
    let puts = filterByHasEarnings(filters, clone.shortPuts);
    puts = filterByIVRank(filters, puts);
    puts = filterByLiquidity(filters, puts);
    puts = filterByRawReturn(filters, puts);
    puts = filterByLinkedPortfolios(filters, puts);
    puts = filterByLinkedWatchLists(filters, puts);
    const fuse = new Fuse(puts, {
      ...FuseConfig,
      keys: ['symbol', 'companyName'],
    });
    const items = query.trim() !== '' ? fuse.search(query).map((i) => i.item) : puts;
    clone.shortPuts = items;
    clone.data = items;
    const sortedShortPuts = clone.sort(sorts);
    return new ShortPuts(sortedShortPuts);
  },
  set: ({ set }, newShortPuts) => {
    if (guardRecoilDefaultValue(newShortPuts)) {
      throw new Error('newShortPut is not compatible type');
    }
    set(shortPutDataCachedState, newShortPuts);
  },
});

export const shortPutCountState = selector<number>({
  key: 'shortPutCountStateKey',
  get: ({ get }) => {
    const shortPuts = get(shortPutState);
    if (!shortPuts) {
      return 0;
    }
    return shortPuts.shortPutCount;
  },
});

export const shortPutPortfolioPositionsCountState = selector<number>({
  key: 'shortPutPortfolioPositionsCountStateKey',
  get: ({ get }) => {
    const shortPuts = get(shortPutState);
    if (!shortPuts) {
      return 0;
    }
    const items = shortPuts.shortPuts;
    const count = items
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
    return count;
  },
});

export const shortPutWatchlistQuotesCountState = selector<number>({
  key: 'shortPutWatchlistQuotesCountStateKey',
  get: ({ get }) => {
    const shortPuts = get(shortPutState);
    if (!shortPuts) {
      return 0;
    }
    const items = shortPuts.shortPuts;
    const count = items
      .filter((e) => e.watchlists && e.watchlists.length > 0)
      .reduce((a, i) => a + i.watchlists.length, 0);
    return count;
  },
});
