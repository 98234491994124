import { IFilter2 } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { guardRecoilDefaultValue } from '@op/shared/src/states';
import { FuseConfig } from '@op/shared/src/states/configuration/fuse-search-config';
import Fuse from 'fuse.js';
import lodash from 'lodash';
import { atom, atomFamily, selector } from 'recoil';
import { IOrder, ITradeStationAccount } from '../models';
import { IAccountBalance } from '../models/getAccountBalance';
import { IHistoricalOrder } from '../models/getOrderHistory';
import { ITradeStatationPosition } from '../models/getPositions';

export const tsDockState = atom({
  key: 'tsDockStateKey',
  default: false,
});

export const tsTermsAndConditionAcceptState = atom({
  key: 'tsTermsAndConditionAcceptStateKey',
  default: false,
});

export const tsAuthenticationStatusState = atom<boolean>({
  key: 'tsAuthenticationStateKey',
  default: false,
});

export type TSEnvironmentTypes = 'sim' | 'live' | 'none';
export const tsEnvironmentState = atom<TSEnvironmentTypes>({
  key: 'tsEnvironmentStateKey',
  default: 'none',
});

export const tsAccountsDataState = atom<ITradeStationAccount[] | undefined>({
  key: 'tsAccountsDataStateKey',
  default: undefined,
});

export const tsSelectedAccountIdsState = atom<string[] | undefined>({
  key: 'tsSelectedAccountIdsStateKey',
  default: undefined,
});

export type TSOrderConfirmationStatus = 'default' | 'isLoading' | 'completed';
export const tsOrderTicketState = atom<TSOrderConfirmationStatus>({
  key: 'tsOrderTicketStateKey',
  default: 'default',
});

// TODO USE Case of this selector is duplicated
export const tsAccountIdsSelectorState = selector<string[] | undefined>({
  key: 'tsAccountIdsSelectorStateKey',
  get: ({ get }) => {
    const accounts = get(tsAccountsDataState);
    if (!accounts || accounts.length === 0) {
      return;
    }
    const ids = accounts.map((i) => i.accountID);
    return ids;
  },
  set: ({ set }, ids) => {
    if (guardRecoilDefaultValue(ids) || !ids) {
      return;
    }
    set(tsSelectedAccountIdsState, ids);
  },
});

export const tsSelectedAccountState = selector<ITradeStationAccount[] | undefined>({
  key: 'tsSelectedAccountStateKey',
  get: ({ get }) => {
    const accountsData = get(tsAccountsDataState);
    if (!accountsData || accountsData.length === 0) {
      return undefined;
    }
    const selectedAccountIds = get(tsSelectedAccountIdsState);
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return undefined;
    }
    let accounts: ITradeStationAccount[] | undefined = [];
    for (let id of selectedAccountIds) {
      const account = accountsData.filter((a) => a.accountID === id);
      if (!account) {
        continue;
      }
      accounts = [...accounts, ...account];
    }
    return accounts;
  },
});

export const tsOrdersDataState = atom<IOrder[] | undefined>({
  key: 'tsOrdersStateKey',
  default: undefined,
});

export const tsPositionDataState = atom<ITradeStatationPosition[] | undefined>({
  key: 'tsPositionDataStateKey',
  default: undefined,
});

export const tsPositionUnderlyingSymbolsState = selector<string[] | undefined>({
  key: 'tsPositionUnderlyingSymbolsStateKey',
  get: ({ get }) => {
    const positionCachedData = get(tsPositionDataState);
    if (!positionCachedData || positionCachedData.length === 0) {
      return [];
    }
    const positions = get(getAllTSPositionsState);
    const positionIds = positions.map((p) => p.positionID);
    const underlyingSymbols = positionCachedData
      .filter((p) => positionIds.includes(p.positionID))
      .map((e) => e.underlyingSymbol);
    return underlyingSymbols;
  },
});

export const tsHistoricalOrdersState = atom<IHistoricalOrder[] | undefined>({
  key: 'tsHistoricalOrdersStateKey',
  default: undefined,
});

export const tsRefreshAccountTokenState = atom<boolean>({
  key: 'tsRefreshAccountTokenStateKey',
  default: false,
});

/* ============= FILTERS =============== */
export const orderStatusFilter: IFilter2[] = [
  {
    title: 'All Orders',
    name: 'all',
    translationKey: 'all',
    value: 'all',
    selected: true,
  },
  {
    title: 'Open/Queued',
    name: 'open',
    translationKey: 'open',
    value: 'Open',
    selected: false,
  },
  {
    title: 'Filled',
    name: 'filled',
    translationKey: 'filled',
    value: 'Filled',
    selected: false,
  },
  {
    title: 'Canceled/Rejected',
    name: 'canceled',
    translationKey: 'canceled',
    value: 'Canceled',
    selected: false,
  },
];

export const openStatus = [
  'ACK',
  'ASS',
  'BRC',
  'BRF',
  'BRO',
  'CHG',
  'CND',
  'COR',
  'CSN',
  'DIS',
  'DOA',
  'DON',
  'ECN',
  'EXE',
  'FPR',
  'LAT',
  'OPN',
  'OSO',
  'OTHER',
  'PLA',
  'REC',
  'RJC',
  'RPD',
  'RSN',
  'STP',
  'STT',
  'SUS',
  'UCN',
];
const filledStatus = ['FLL', 'FLP'];
const cancaledStatus = ['CAN', 'EXP', 'OUT', 'RJR', 'SCN', 'TSC', 'UCH', 'REJ'];

const filterByStatus = <T extends IOrder | IHistoricalOrder>(filters: IFilter2[], data: T[]): T[] => {
  let filtered: T[] = [];
  for (const filter of filters) {
    if (!filter.selected) {
      continue;
    }
    if (filter.name === 'all') {
      filtered = data;
      continue;
    }
    if (filter.name === 'open') {
      const items = data.filter((d) => openStatus.includes(d.status));
      filtered = filtered.concat(items);
    }
    if (filter.name === 'filled') {
      const items = data.filter((d) => filledStatus.includes(d.status));
      filtered = filtered.concat(items);
    }
    if (filter.name === 'canceled') {
      const items = data.filter((d) => cancaledStatus.includes(d.status));
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const filterByDate = (data: IHistoricalOrder[], filterDate: Date) => {
  if (!data || data.length === 0) {
    return data;
  }
  if (DateTimeHelper.isDateEqualWithoutTime(filterDate, historyOrderDefaultDate)) {
    return data;
  }
  return data.filter((d) => new Date(d.openedDateTime).getTime() >= filterDate.getTime());
};

export const filtersOrdersState = atom({
  key: 'filtersOrdersStateKey',
  default: orderStatusFilter,
});

export const filtersHistoricalOrdersState = atom({
  key: 'filtersHistoricalOrdersStateKey',
  default: orderStatusFilter,
});

const historyOrderDefaultDate = DateTimeHelper.toDateFromDateTime(DateTimeHelper.PastUTCDate(90));
export const filtersHisOrdersByDateState = atom({
  key: 'filtersHisOrdersByDateStateKey',
  default: historyOrderDefaultDate,
});

export const tsHistoricalOrdersAccountState = selector<IHistoricalOrder[] | undefined>({
  key: 'tsHistoricalOrdersAccountStateKey',
  get: ({ get }) => {
    const historicalOrderData = get(tsHistoricalOrdersState);
    const filters = get(filtersHistoricalOrdersState);
    const dateFilter = get(filtersHisOrdersByDateState);
    const query = get(tsHistoricalOrderSearchState);
    if (!historicalOrderData || historicalOrderData.length === 0) {
      return undefined;
    }
    const selectedAccountIds = get(tsSelectedAccountIdsState);
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return undefined;
    }
    let historicalOrders: IHistoricalOrder[] = [];
    for (let id of selectedAccountIds) {
      const historicalOrder = historicalOrderData.filter((o) => o.accountID === id);
      if (!historicalOrder) {
        continue;
      }
      historicalOrders = [...historicalOrders, ...historicalOrder];
    }
    historicalOrders = filterByDate(historicalOrders, dateFilter);
    historicalOrders = filterByStatus(filters, historicalOrders);
    const fuse = new Fuse(historicalOrders, {
      ...FuseConfig,
      keys: ['legs.symbol'],
    });
    const searchedFilteredHistoricalOrders =
      query.trim() !== '' ? fuse.search(query).map((i) => i.item) : historicalOrders;
    return searchedFilteredHistoricalOrders;
  },
});

export const tsPositionSearchState = atom({
  key: 'tsPositionSearchStateKey',
  default: '',
});

export const tsAccountBalances = atom<IAccountBalance[] | undefined>({
  key: 'tsAccountBalancesKey',
  default: undefined,
});

export const tsSelectedAccountBalanceState = selector<IAccountBalance[] | undefined>({
  key: 'tsAccountBalanceStateKey',
  get: ({ get }) => {
    const accountBalanceData = get(tsAccountBalances);
    if (!accountBalanceData || accountBalanceData.length === 0) {
      return undefined;
    }
    const selectedAccountIds = get(tsSelectedAccountIdsState);
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return undefined;
    }
    let balanceData: IAccountBalance[] = [];
    for (let id of selectedAccountIds) {
      const accountBalances = accountBalanceData.filter((a) => a.accountID === id);
      if (!accountBalances) {
        continue;
      }
      balanceData = [...balanceData, ...accountBalances];
    }
    return balanceData;
  },
});

export const tsOrderSearchState = atom({
  key: 'tsOrderSearchStateKey',
  default: '',
});

export const tsHistoricalOrderSearchState = atom({
  key: 'tsHistoricalOrderSearchStateKey',
  default: '',
});

export type TSTabTypes = 'positions' | 'orders' | 'history' | 'accountSummary' | 'notificationLog' | 'orderTicket';
export const tsSelectedTabState = atom<TSTabTypes>({
  key: 'tsSelectedTabState',
  default: 'positions',
});

/* ==== Positions Tab States ==== */

export const tsPositionsAtomFamily = atomFamily<ITradeStatationPosition | undefined, string>({
  key: 'tsPositionsAtomFamilyKey',
  default: undefined,
});

export const tsPositionsUpdater = selector<ITradeStatationPosition[] | undefined>({
  key: 'tsPositionsUpdaterKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ set }, positions) => {
    if (!positions || guardRecoilDefaultValue(positions)) {
      return;
    }
    if (!positions || positions.length === 0) {
      set(tsPositionDataState, []);
      return undefined;
    }
    for (const pos of positions) {
      if (pos && !pos.positionID) {
        return;
      }
      const posID = pos.positionID.trim().toUpperCase();
      set(tsPositionDataState, positions);
      set(tsPositionsAtomFamily(posID), pos);
    }
  },
});

export const getAllPositionsByAccountState = selector<ITradeStatationPosition[] | undefined>({
  key: 'getAllPositionsByAccountStateKey',
  get: ({ get }) => {
    const accountIDs = get(tsSelectedAccountIdsState);
    if (!accountIDs) {
      return;
    }
    const positionCacheData = get(tsPositionDataState);
    if (!positionCacheData) {
      return [];
    }
    const allPosIDs = positionCacheData.map((p) => p.positionID);
    if (!allPosIDs || allPosIDs.length === 0) {
      return [];
    }
    const allPositionsData = allPosIDs.map((id) => get(tsPositionsAtomFamily(id)));
    if (!allPositionsData) {
      return;
    }
    const filterPosBySelectedAccounts = allPositionsData.filter((pos) => accountIDs.find((id) => pos.accountID === id));
    return filterPosBySelectedAccounts;
  },
});

export const getAllTSPositionsState = selector<ITradeStatationPosition[] | undefined>({
  key: 'getAllTSPositionsStateKey',
  get: ({ get }) => {
    const getAllPositionsByAccount = get(getAllPositionsByAccountState);
    const query = get(tsPositionSearchState);
    if (!getAllPositionsByAccount || getAllPositionsByAccount.length === 0) {
      return [];
    }
    const fuse = new Fuse(getAllPositionsByAccount, {
      ...FuseConfig,
      keys: ['symbol'],
    });
    const searchedFilteredPositions =
      query.trim() !== '' ? fuse.search(query).map((i) => i.item) : getAllPositionsByAccount;
    return searchedFilteredPositions;
  },
});

type getPositionHeaderValues = {
  openPL: string;
  todaysOpenPL: string;
  marketValue: string;
};

export const getPositionHeaderValueState = selector<getPositionHeaderValues>({
  key: 'getPositionHeaderValueStateKey',
  get: ({ get }) => {
    const getAllPositionsByAccount = get(getAllPositionsByAccountState);
    if (!getAllPositionsByAccount) {
      return {
        openPL: '0',
        todaysOpenPL: '0',
        marketValue: '0',
      };
    }
    const openPL = lodash.sumBy(getAllPositionsByAccount, (i) => Number(i.unrealizedProfitLoss));
    const todaysOpenPL = lodash.sumBy(getAllPositionsByAccount, (i) => Number(i.todaysProfitLoss));
    const marketValue = lodash.sumBy(getAllPositionsByAccount, (i) => Number(i.marketValue));
    return {
      openPL: openPL.toString(),
      todaysOpenPL: todaysOpenPL.toString(),
      marketValue: marketValue.toString(),
    };
  },
});

type getAccountBalances = {
  marketValue: string;
  realizedPL: string;
  unRealizedPL: string;
  accountValue: string;
  cashBalance: string;
  commission: string;
  costOfPosition: string;
  overNightBuyingPower: string;
  buyingPower: string;
  margin: string;
};
export const getAccountBalancesState = selector<getAccountBalances>({
  key: 'getAccountBalancesStateKey',
  get: ({ get }) => {
    const getAccountBalanceByAccount = get(tsSelectedAccountBalanceState);
    if (!getAccountBalanceByAccount) {
      return undefined;
    }

    return {
      marketValue: lodash.sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.marketValue)).toString(),
      realizedPL: lodash
        .sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.balanceDetail.realizedProfitLoss))
        .toString(),
      unRealizedPL: lodash
        .sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.balanceDetail.unrealizedProfitLoss))
        .toString(),
      accountValue: lodash
        .sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.cashBalance) + Number(i.marketValue))
        .toString(),
      cashBalance: lodash.sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.cashBalance)).toString(),
      commission: lodash.sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.commission)).toString(),

      costOfPosition: lodash
        .sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.balanceDetail.costOfPositions))
        .toString(),
      overNightBuyingPower: lodash
        .sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.balanceDetail.overnightBuyingPower))
        .toString(),
      buyingPower: lodash.sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.buyingPower)).toString(),
      margin: lodash
        .sumBy(getAccountBalanceByAccount, (i: IAccountBalance) => Number(i.balanceDetail.requiredMargin))
        .toString(),
    };
  },
});
/* ==== Orders Tab States ==== */

export const tsOrdersAtomFamily = atomFamily<IOrder | undefined, string>({
  key: 'tsOrdersAtomFamilyKey',
  default: undefined,
});

export const tsOrdersUpdater = selector<IOrder[] | undefined>({
  key: 'tsOrdersUpdaterKey',
  get: () => {
    throw new Error('This is an updater');
  },
  set: ({ set }, orders) => {
    if (!orders || guardRecoilDefaultValue(orders)) {
      return;
    }
    if (!orders || orders.length === 0) {
      set(tsOrdersDataState, []);
      return undefined;
    }
    for (const order of orders) {
      if (order && !order.orderID) {
        return;
      }
      const orderId = order.orderID.trim().toUpperCase();
      set(tsOrdersDataState, orders);
      set(tsOrdersAtomFamily(orderId), order);
    }
  },
});

export const getAllOrdersByAccountState = selector<IOrder[] | undefined>({
  key: 'getAllOrdersByAccountStateKey',
  get: ({ get }) => {
    const accountIDs = get(tsSelectedAccountIdsState);
    if (!accountIDs) {
      return;
    }
    const ordersData = get(tsOrdersDataState);

    if (!ordersData) {
      return;
    }
    const allOrderIds = ordersData.map((p) => p.orderID);
    if (!allOrderIds || allOrderIds.length === 0) {
      return [];
    }
    const allOrdersData = allOrderIds.map((id) => get(tsOrdersAtomFamily(id)));
    if (!allOrdersData) {
      return;
    }
    const filterOrdersBySelectedAccounts = allOrdersData.filter((pos) => accountIDs.find((id) => pos.accountID === id));
    return filterOrdersBySelectedAccounts;
  },
});

export const getAllTSOrdersState = selector<IOrder[] | undefined>({
  key: 'getAllTSOrdersStateKey',
  get: ({ get }) => {
    const getAllOrdersByAccount = get(getAllOrdersByAccountState);
    const filters = get(filtersOrdersState);
    const query = get(tsOrderSearchState);
    if (!getAllOrdersByAccount) {
      return;
    }
    const orders = filterByStatus(filters, getAllOrdersByAccount);
    const fuse = new Fuse(orders, {
      ...FuseConfig,
      keys: ['legs.symbol'],
    });
    const searchedFilteredPositions = query.trim() !== '' ? fuse.search(query).map((i) => i.item) : orders;
    return searchedFilteredPositions;
  },
});

// ====================================================================

export const prepareAccountData = (accounts: ITradeStationAccount[]) => {
  if (!accounts || accounts.length === 0) {
    return accounts;
  }
  const supportedAccountTypes = ['Cash', 'Margin'];
  const filteredAccounts = accounts.filter((acc) => supportedAccountTypes.includes(acc.accountType));
  return filteredAccounts;
};
