import { Theme, useMediaQuery } from '@mui/material';
import {
  ISubscriptionFeature,
  ISubscriptionPlanPrice,
  ISubscriptionPlanPricesModel,
  IUserSubscriptionFeatures,
  IUserSubscriptionResponseFeatures,
  cloneDeep,
} from '@op/shared/src/models';
import { SubscriptionStatus, UserSubscriptionPeriod } from '@op/shared/src/models/enums/enums';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { ISelectedPlanModels } from '@op/shared/src/states';

// Helper function to create either a monthly or annual plan
const createPlan = (
  plan: ISubscriptionPlanPrice,
  userPlan: ISubscriptionFeature | undefined,
  upcomingPlan: ISubscriptionFeature | undefined,
  type: 'Monthly' | 'Annual',
): ISubscriptionPlanPrice => {
  return {
    ...plan,
    planPrice: type === 'Monthly' ? plan.price.monthlyPrice : plan.price.annualPrice,
    discountedPrice: type === 'Monthly' ? plan.price.monthlyDiscountedPrice : plan.price.annualDiscountedPrice,
    discountedRate: type === 'Monthly' ? plan.price.monthlyDiscountedRate : plan.price.annualDiscountedRate,
    planType: type,
    isRecommeded: plan.featureType === 'Bundle' && type === 'Annual', // Set recommendation flag for Pro bundle
    isActivePlan: userPlan ? userPlan.planType === type : false, // Check if the user's current plan matches the type
    isUpcoming: upcomingPlan ? upcomingPlan.planType === type : false,
  };
};

// Main function to prepare the subscription plan
export const prepareSubscriptionPlan = (
  cachedPlan: ISubscriptionPlanPricesModel,
  userSubscription: IUserSubscriptionFeatures,
) => {
  const cloned = cloneDeep(cachedPlan);
  const modifiedPlans: ISubscriptionPlanPrice[] = [];
  // Function to process each plan type and add to modifiedPlans
  const processPlan = (plan: ISubscriptionPlanPrice, index: number) => {
    const userCurrentPlan = userSubscription.features.find((f) => f.title === UserSubscriptionPeriod.CURRENT);
    const userPlan = userCurrentPlan.subscriptionList.find((c) => c.featureKey === plan.featureKey); // Find the user's current plan
    const userUpcomingPlans = userSubscription.features.find((f) => f.title === UserSubscriptionPeriod.UPCOMING);
    const upcomingPlan = userUpcomingPlans.subscriptionList.find((c) => c.featureKey === plan.featureKey); // Find the user's current plan
    switch (plan.type) {
      case 'OneTime':
        // Process one-time payment plan
        modifiedPlans.push({
          ...plan,
          planType: 'OneTime',
          isActivePlan: userPlan ? userPlan.planType === 'OneTime' : false,
          isUpcoming: upcomingPlan ? upcomingPlan.planType === 'OneTime' : false,
          isSelected: false,
        });
        break;
      case 'Monthly':
        // Process monthly plan
        modifiedPlans.push(createPlan(plan, userPlan, upcomingPlan, 'Monthly'));
        break;
      case 'Annual':
        // Process annual plan
        modifiedPlans.push(createPlan(plan, userPlan, upcomingPlan, 'Annual'));
        break;
      case 'Both':
        // Process both monthly and annual plans
        modifiedPlans.push(
          createPlan(plan, userPlan, upcomingPlan, 'Monthly'),
          createPlan(plan, userPlan, upcomingPlan, 'Annual'),
        );
        break;
      default:
        // Handle unexpected types gracefully
        console.warn(`Unknown plan type: ${plan.type}`);
        break;
    }
  };

  cloned.prices.forEach(processPlan);
  cloned.prices = selectBestAnnualPlan(modifiedPlans);
  return cloned;
};

const selectBestAnnualPlan = (plans: ISubscriptionPlanPrice[]) => {
  // Check if there's any active plan
  const activePlan = plans.find((plan) => plan.isActivePlan);

  // If an active annual plan exists, return the plans as is
  if (activePlan) {
    const mplan2 = plans.map((plan) => ({
      ...plan,
      isSelected: plan === activePlan && !plan.isActivePlan, // Mark the active plan as selected
    }));
    return mplan2;
  }
  // Here isActive is false thus no additional check
  // Filter the plans that meet the conditions: isActive is false, isSelected is false, and planType is 'Annual'
  const eligiblePlans = plans.filter(
    (plan) => plan.planType === 'Annual' && plan.featureType === 'Bundle' && !plan.isActivePlan,
  );

  // If no plans meet the criteria, return the original plans array
  if (eligiblePlans.length === 0) {
    return plans;
  }

  // Find the plan with the highest number of features (benefitsList)
  const bestPlan = eligiblePlans.reduce((best, current) =>
    current.benefitsList.length > best.benefitsList.length ? current : best,
  );

  const mPlans = plans.map((plan) => ({
    ...plan,
    isSelected: plan === bestPlan ? true : false, // Now setting isSelected here for the best annual plan
  }));
  return mPlans;
};

export const getModifiedUserCachedSubscription = (
  userCachedSubscriptionResponse: IUserSubscriptionResponseFeatures,
) => {
  const { userID, createdOn, isLatest, features } = userCachedSubscriptionResponse;

  const modifiedUserSubscription: IUserSubscriptionFeatures = {
    userID,
    createdOn,
    isLatest,
    features: [
      {
        title: UserSubscriptionPeriod.CURRENT,
        subscriptionList: features.current,
      },
      // !Note : Based on the discussion we are showcasing Zero Past records
      // ! due to this reason hardcoded [] array in subscriptionList instead "features.past"
      { title: UserSubscriptionPeriod.PAST, subscriptionList: [] },
      { title: UserSubscriptionPeriod.UPCOMING, subscriptionList: features.upcoming },
    ],
  };

  return modifiedUserSubscription;
};

// function to generate header title on plan selection
export const getSubscriptionHeaderTitle = (
  selectedPlans: ISelectedPlanModels,
  screenType: 'addOn' | 'cardInfo',
  t: (translate: string) => React.ReactNode,
) => {
  if (!selectedPlans) return;

  const { bundles, addOns } = selectedPlans;
  const selectedBundle = bundles.find((b) => b.isSelected);
  const selectedAddOns = addOns.filter((a) => a.isSelected);

  if (!selectedBundle && selectedAddOns.length === 0) return;

  if (screenType === 'addOn') {
    if (!selectedBundle) return;
    const saved = selectedBundle.planPrice - selectedBundle.discountedPrice;
    let returnText = `${t('subscriptions.youSelected')} OptionsPlay ${selectedBundle.featureKey} ${
      selectedBundle.planType
    } $${selectedBundle.discountedPrice}`;
    returnText = saved > 0 ? returnText + ` ${t('subscriptions.saved')} $${saved}` : returnText;
    return returnText;
  }

  const totalAmount =
    (selectedBundle ? selectedBundle.discountedPrice : 0) +
    selectedAddOns.reduce((acc, addOn) => acc + addOn.discountedPrice, 0);

  const totalSaved =
    (selectedBundle ? selectedBundle.planPrice - selectedBundle.discountedPrice : 0) +
    selectedAddOns.reduce((acc, addOn) => acc + (addOn.planPrice - addOn.discountedPrice), 0);

  const addOnText =
    selectedAddOns.length > 0
      ? `${selectedBundle ? ' + ' : ''}${selectedAddOns.length} Add-On${selectedAddOns.length > 1 ? 's' : ''}`
      : '';

  const format = '{prefix} {bundleText}{addOnText} ${totalAmount}{savedText}';
  const formatObj = {
    prefix: t('subscriptions.youSelected') as string,
    bundleText: selectedBundle ? `OptionsPlay ${selectedBundle.featureKey} ${selectedBundle.planType}` : '',
    addOnText,
    totalAmount,
    savedText: totalSaved > 0 ? `  (${t('subscriptions.saved')} $${totalSaved})` : '',
  };

  return formatHeaderTitle(format, formatObj);
};

export const getDiscountLabel = (
  subscriptionPlanPrices: ISubscriptionPlanPricesModel,
  featureKey: string,
  planType: string,
): string => {
  if (!subscriptionPlanPrices) return '';

  const price = subscriptionPlanPrices.prices.find(
    (item) => item.featureKey === featureKey && item.planType === planType,
  );

  return price && Number(price.discountedRate) > 0 ? ` ${price.discountedRate * 100}% off` : '';
};

const formatHeaderTitle = (template: string, parts: { [key: string]: string | number }): string => {
  return template.replace(/{(\w+)}/g, (_, key) => String(parts[key] ?? ''));
};

// Check if the new subscription plan is an upgrade based on the number of features.
export const isSubscriptionPlanUpgraded = (
  currentSubscriptionPlan: ISubscriptionFeature,
  subscribingPlan: ISubscriptionPlanPrice,
) => {
  const currentFeatureCount = currentSubscriptionPlan.featureIncludes.length;
  const newFeatureCount = subscribingPlan.featureIncludes.length;
  return newFeatureCount > currentFeatureCount;
};

// Determine the appropriate payment date based on the subscription status and plan upgrade.
export const calculateNextPaymentDate = (
  newSubscriptionPlan: ISelectedPlanModels,
  currentSubscription: ISubscriptionFeature,
) => {
  const newPlans = [...newSubscriptionPlan.bundles, ...newSubscriptionPlan.addOns];
  const isUpgradingPlan = newPlans.some((plan) => isSubscriptionPlanUpgraded(currentSubscription, plan));
  const trialUser = currentSubscription.subscriptionStatus === SubscriptionStatus.ACTIVE_TRIAL;

  if (isUpgradingPlan && !trialUser) {
    return DateTimeHelper.getCurrentDate();
  }
  return DateTimeHelper.resolveDate(currentSubscription.endDate);
};

// Subscription slider settings for swipeable slider
export const SubscriptionSliderSettings = (
  theme: Theme,
  screenType: 'changePlan' | 'confirmation',
  currentSlide?: number,
  setCurrentSlide?: (currentIndex: number) => void,
) => {
  const screenSize = useMediaQuery(theme.breakpoints.down(screenType === 'changePlan' ? 'md' : 'sm'));
  const slideToShow = screenType === 'changePlan' ? [2, 3] : [1, 2];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: screenSize ? slideToShow[0] : slideToShow[1], // Show one card at a time
    slidesToScroll: 1, // Scroll one card at a time
    arrows: true,
    swipeToSlide: true,
    initialSlide: currentSlide,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: screenSize ? slideToShow[0] : slideToShow[1], // Show two cards at a time on larger screens
        },
      },
    ],
  };

  return settings;
};
