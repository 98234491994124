import React from 'react';
import { ILocalization } from './localization';

const LocalizationContext = React.createContext<ILocalization>({
  t: (translate: string) => translate,
  i18n: {
    changeLanguage: (language: string): string => language,
  },
});
export default LocalizationContext;
