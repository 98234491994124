import { ICollection } from '..';
export interface Scan {
  value: string;
  text: string;
  isSelected: boolean;
}

export const defaultScan: Scan = {
  value: '',
  text: '',
  isSelected: true,
};

export interface Scans extends ICollection<Scan> {
  data: Scan[];
}
