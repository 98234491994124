import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import {
  paymentDetailsErrorState,
  selectedSubscriptionState,
  subscriptionCurrentPaymentState,
} from '@op/shared/src/states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPSemiBoldTypograpghy, OptionsPlayTextField } from '../styled';
import { Province, countries, states } from './data-list';

export const BillingDetailsWidget: React.FC = () => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const [errors, setErrors] = useRecoilState(paymentDetailsErrorState);
  const [paymentDetails, setPaymentDetails] = useRecoilState(subscriptionCurrentPaymentState);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [editDetails, setEditDetails] = useState(false);
  const selectedSubscription = useRecoilValue(selectedSubscriptionState);

  useEffect(() => {
    if (!selectedSubscription || !selectedSubscription.billingAddress) {
      return;
    }

    const billingAddress = selectedSubscription.billingAddress;
    if (isBillingAddressNull()) {
      return;
    }
    setPaymentDetails({
      ...paymentDetails,
      address: billingAddress.address,
      country: billingAddress.country,
      city: billingAddress.city,
      state: billingAddress.state,
      postalCode: billingAddress.zip,
    });
    setErrors({
      ...errors,
      address: '',
      country: '',
      city: '',
      state: '',
      postalCode: '',
    });
  }, [editDetails]);

  const isBillingAddressNull = () => {
    if (!selectedSubscription || !selectedSubscription.billingAddress) {
      return true;
    }
    const billingAddress = selectedSubscription.billingAddress;
    return (
      !billingAddress ||
      billingAddress.address === null ||
      billingAddress.city === null ||
      billingAddress.country === null ||
      billingAddress.state === null ||
      billingAddress.zip === null
    );
  };

  const isBillingAccordianError = () => {
    return errors.address || errors.city || errors.state || errors.postalCode ? true : false;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.name === 'postalCode' && event.target.value.length > 10) {
      return;
    }
    if (event.target.name === 'country') {
      let newCardData = { ...paymentDetails };
      newCardData.state = '';
      setPaymentDetails({ ...newCardData, [event.target.name]: event.target.value });
      setErrors({ ...errors, [event.target.name]: '' });
      return;
    }

    setPaymentDetails({ ...paymentDetails, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneRegex = /^\+?[1-9]\d{5,15}$/;
    const { value } = event.target;
    setIsValidPhoneNumber(phoneRegex.test(phoneNumber));
    setPhoneNumber(value);
  };

  const renderMobileNumberView = () => {
    return (
      <Grid item xs={12} container alignItems="center" justifyContent="space-between">
        <Grid item container xs={12}>
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Typography
              sx={{
                color: theme.palette.text.disabled,
              }}>
              {t('subscriptions.pleaseProvidePhoneNo')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.phoneNumber')}</OPSemiBoldTypograpghy>
        </Grid>
        <Grid item container xs={9} alignItems="center">
          <Grid item xs={12}>
            <OptionsPlayTextField
              variant="outlined"
              name="phoneNumber"
              size="small"
              type="number"
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={!isValidPhoneNumber}
              helperText={!isValidPhoneNumber && 'Please enter a valid phone number.'}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderAddressFields = () => {
    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12} container alignItems="center">
          {renderMobileNumberView()}
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.address')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item container rowGap={1} xs={9}>
            <OptionsPlayTextField
              variant="outlined"
              id="address"
              name="address"
              value={paymentDetails.address}
              onChange={handleChange}
              helperText={errors.address}
              error={errors.address && errors.address !== '' ? true : false}
              inputProps={{ maxLength: 60 }}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '40px',
                        justifyContent: 'space-between',
                      }}>
                      <Box
                        sx={{
                          height: '24px',
                          borderLeft: `1px solid ${theme.palette.secondary.dark}`,
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: 'right',
                        }}
                        color={paymentDetails.address.length === 60 ? 'error' : 'textSecondary'}>
                        {paymentDetails.address.length}/{60}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={3}>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.country')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={9}>
            <OptionsPlayTextField
              id="outlined-select-currency"
              select
              size="small"
              fullWidth
              name="country"
              value={paymentDetails.country}
              onChange={handleChange}
              helperText={errors.country}
              error={errors.country && errors.country !== '' ? true : false}
              variant="outlined">
              {countries.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </OptionsPlayTextField>
          </Grid>
        </Grid>
        <Grid item container xs={12} columnSpacing={1} justifyContent="space-between" alignItems="center">
          <Grid item container xs={6} justifyContent="space-between" alignItems="center">
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.city')}</OPSemiBoldTypograpghy>
            <Grid item container xs={6}>
              <OptionsPlayTextField
                variant="outlined"
                id="city"
                name="city"
                value={paymentDetails.city}
                onChange={handleChange}
                helperText={errors.city}
                error={errors.city && errors.city !== '' ? true : false}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item container xs={5} justifyContent="space-between" alignItems="center">
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.state')}</OPSemiBoldTypograpghy>
            <Grid item xs={9}>
              {paymentDetails.country === 'US' ? (
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="state"
                  value={paymentDetails.state}
                  fullWidth
                  onChange={handleChange}
                  helperText={errors.state}
                  error={errors.state && errors.state !== '' ? true : false}
                  variant="outlined">
                  {states.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              ) : paymentDetails.country === 'CA' ? (
                <OptionsPlayTextField
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="state"
                  value={paymentDetails.state}
                  fullWidth
                  onChange={handleChange}
                  helperText={errors.state}
                  error={errors.state && errors.state !== '' ? true : false}
                  variant="outlined">
                  {Province.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </OptionsPlayTextField>
              ) : (
                <OptionsPlayTextField
                  variant="outlined"
                  id="state"
                  name="state"
                  value={paymentDetails.state}
                  onChange={handleChange}
                  helperText={errors.state}
                  error={errors.state && errors.state !== '' ? true : false}
                  size="small"
                  fullWidth
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="space-between" alignItems="center">
          <Grid item xs={6} container>
            <OPSemiBoldTypograpghy variant="subtitle2">{t('subscriptions.zipCode')}</OPSemiBoldTypograpghy>
          </Grid>
          <Grid item xs={6} container>
            <OptionsPlayTextField
              variant="outlined"
              name="postalCode"
              value={paymentDetails.postalCode}
              id="postalCode"
              helperText={errors.postalCode}
              error={errors.postalCode && errors.postalCode !== '' ? true : false}
              onChange={handleChange}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSavedAddress = () => {
    let fullAddress = '';
    if (!selectedSubscription || !selectedSubscription.billingAddress) {
      fullAddress = '';
    } else {
      const billingAddress = selectedSubscription.billingAddress;
      const address = billingAddress.address + ', ' || '';
      const country = billingAddress.country + ', ' || '';
      const city = billingAddress.city + ', ' || '';
      const state = billingAddress.state + ', ' || '';
      const zip = billingAddress.zip || '';
      fullAddress = address + country + city + state + zip;
    }
    return (
      <Grid container>
        <Grid container item xs={12} sx={{ backgroundColor: theme.palette.greyShades.light }}>
          <Grid item xs={10} justifyContent="center" alignItems="center" p={1}>
            <OPBoldTypograpghy>{`${fullAddress}.`}</OPBoldTypograpghy>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs={6}>
              <IconButton
                onClick={() => {
                  setEditDetails(!editDetails);
                }}>
                <IconComponent name="editIcon" fill={theme.palette.info.light} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {editDetails && (
            <Grid container>
              <Grid item xs={12} my={2}>
                <OPBoldTypograpghy style={{ fontSize: 14, color: theme.palette.greyShades.contrastText }}>
                  {t('subscriptions.billingAddressEdit')}
                </OPBoldTypograpghy>
              </Grid>
              <Grid xs={12} item>
                {renderAddressFields()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderAccordianContent = () => {
    if (isBillingAddressNull()) {
      return renderAddressFields();
    }
    return renderSavedAddress();
  };

  const renderAccordian = () => {
    return (
      <Accordion defaultExpanded={true} disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
          sx={{
            background: isBillingAccordianError() ? theme.palette.error.main : theme.palette.primary.main,
            borderTopRightRadius: theme.shape.borderRadius,
            borderTopLeftRadius: theme.shape.borderRadius,
            color: theme.palette.timeFrame.contrastText,
            paddingY: 0,
            minHeight: '40px',
          }}>
          <OPBoldTypograpghy>{t('subscriptions.billingAddress')}</OPBoldTypograpghy>
        </AccordionSummary>
        <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: '10px 0px 10px 0px' } }}>
          <Grid item xs={12} px={2}>
            {renderAccordianContent()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return <>{renderAccordian()}</>;
};
