import CachedIcon from '@mui/icons-material/Cached';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { OPIconButton, OptionsPlayTextField } from '../styled';
import { SummaryTypography } from './components/ts-typography';
import {
  getPositionHeaderValueState,
  tsAccountBalances,
  tsPositionDataState,
  tsPositionSearchState,
} from './states/trade-station-states';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';

export const TsPositionHeader: React.FC = () => {
  const theme = useTheme();
  const resetPositions = useResetRecoilState(tsPositionDataState);
  const [textSearchFieldInput, setTextSearchFieldInput] = useRecoilState(tsPositionSearchState);
  const resetAccountBalance = useResetRecoilState(tsAccountBalances);
  const getPositionHeaderValue = useRecoilValue(getPositionHeaderValueState);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    return () => {
      setTextSearchFieldInput('');
    };
  }, []);

  if (!getPositionHeaderValue) {
    return;
  }

  const resetSearchValues = () => {
    setTextSearchFieldInput('');
  };

  const resetPosition = () => {
    resetSearchValues();
    resetPositions();
    resetAccountBalance();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const renderSearch = () => {
    return (
      <Grid item xs={12} container justifyContent="flex-end" columnSpacing={1}>
        <OptionsPlayTextField
          variant="outlined"
          onChange={(e) => {
            setTextSearchFieldInput(e.target.value.toUpperCase());
          }}
          placeholder="Search by Symbol"
          name="searchname"
          value={textSearchFieldInput}
          style={{ width: '100%' }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: !isFocused && (
              <IconButton disableRipple sx={{ '&.MuiIconButton-root': { padding: 0 }, cursor: 'default' }}>
                <IconComponent name="searchIcon" stroke={theme.palette.info.light} size={18} />
              </IconButton>
            ),
            endAdornment: textSearchFieldInput !== '' && (
              <IconButton onClick={() => setTextSearchFieldInput('')} sx={{ '&.MuiIconButton-root': { padding: 0 } }}>
                <ClearOutlinedIcon fontSize={'small'} sx={{ color: theme.palette.selectAndTextField.main }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
    );
  };

  const renderBalances = () => {
    return (
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? '#D9D9D9' : '#343A3D',
          borderRadius: 1,
        }}>
        <Grid
          item
          xs={3.5}
          sm={2}
          sx={{
            px: 1,
            borderRadius: 1,
            backgroundColor: theme.palette.mode === 'light' ? '#D9D9D9' : '#343A3D',
          }}>
          <Typography lineHeight={1} fontWeight={'bold'} variant="captionSmall">
            Account Summary
          </Typography>
        </Grid>
        <Grid item container xs={8.5} sm={10} justifyContent={'space-between'} columnSpacing={1}>
          <Grid item xs={12} sm={4} py={0.5} sx={{ backgroundColor: theme.palette.header.default, marginY: '1px' }}>
            <SummaryTypography
              title={`Today's Open P/L`}
              value={NumberFormatHelper.toCurrency(getPositionHeaderValue.todaysOpenPL)}
              sx={{
                color:
                  Number(getPositionHeaderValue.todaysOpenPL) >= 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
              borderNone
            />
          </Grid>
          <Grid item xs={12} py={0.5} sm={3.93} sx={{ backgroundColor: theme.palette.header.default, marginY: '1px' }}>
            <SummaryTypography
              title={`Open P/L`}
              value={NumberFormatHelper.toCurrency(getPositionHeaderValue.openPL)}
              sx={{
                color:
                  Number(getPositionHeaderValue.openPL) >= 0 ? theme.palette.success.main : theme.palette.error.main,
              }}
              borderNone
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            py={0.5}
            sx={{
              backgroundColor: theme.palette.header.default,
              marginY: '1px',
              marginRight: '1px',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
            }}>
            <SummaryTypography
              title={`Market Value`}
              value={NumberFormatHelper.toCurrency(getPositionHeaderValue.marketValue)}
              sx={{
                color:
                  Number(getPositionHeaderValue.marketValue) >= 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
              borderNone
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container alignItems={'center'} columnSpacing={2}>
      <Grid item xs={1} sm={0.5} xl={0.2} container justifyContent="flex-start">
        <OPIconButton onClick={() => resetPosition()}>
          <CachedIcon sx={{ color: theme.palette.info.light }} />
        </OPIconButton>
      </Grid>
      <Grid item container xs={8.5} sm={9.5} xl={10.8}>
        <Grid item xs={12} sm={11} md={9} lg={7} xl={5.5}>
          {renderBalances()}
        </Grid>
      </Grid>
      <Grid item xs={2.5} sm={2} xl={1} textAlign={'right'}>
        {renderSearch()}
      </Grid>
    </Grid>
  );
};
