import { fetchProfile, profileCachedState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PageContainerWidget } from '../routes/page-container-widget';
import AccountTabsWidget from './account-tabs-widget';

export const ProfilePageWidget: React.FC<{}> = () => {
  const { state }: any = useLocation();
  const setProfile = useSetRecoilState(profileCachedState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAmertidate, setIsAmertitrade] = useState(false);

  // useMemo(() => {
  //   if (searchParams.get('code') || searchParams.get('error')) {
  //     setIsAmertitrade(true);
  //   }
  // }, []);

  useEffect(() => {
    // TODO : make it as dynamic with query params
    // const queryParam = new URLSearchParams(search);
    // const index = queryParam.get('tab');
    document.title = 'OptionsPlay Profile | OptionsPlay';
    (async () => {
      const profile = await fetchProfile();
      if (profile) {
        setProfile(profile);
      }
    })();
  }, []);

  return (
    <PageContainerWidget>
      <AccountTabsWidget
      // status={state && typeof state == 'object' ? state.id : state ? (state as number) : isAmertidate ? 3 : 0}
      />
    </PageContainerWidget>
  );
};
