import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import { selectedSymbolState } from '@op/shared/src/states';
import { howDataState } from '@op/shared/src/states/data-states';
import { tradingRangeSimulatorAtom, tradingRangeSimulatorState } from '@op/shared/src/states/how';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { GuideItem } from '../side-menu';
import { OptionsPlayCurrencyFormatInput, OptionsPlaySwitch, OptionsPlayTextField } from '../styled';
import { TradingRangeChartWidget } from './trading-range-chart-widget';

export const TradingRangeSimulatorWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const tradingRangeSimulator = useRecoilValue(tradingRangeSimulatorState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorAtom);
  const [narrowOrWide, setNarrowOrWide] = useState('narrow');
  const [highPrice, setHighPrice] = useState(0);
  const [lowPrice, setLowPrice] = useState(0);
  const [lowPriceInputValue, setLowPriceInputValue] = useState('');
  const [highPriceInputValue, setHighPriceInputValue] = useState('');
  const [expiries, setExpiries] = useState<
    { dateOnSelector: string; date: Date; isGreaterThanMaxExpiry: boolean }[] | undefined
  >();
  const [selectedExpiry, setSelectedExpiry] = useState<
    { dateOnSelector: string; date: Date; isGreaterThanMaxExpiry: boolean } | undefined
  >();
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();

  useEffect(() => {
    if (!tradingRangeSimulator || !howData || !howData.chain) {
      return;
    }
    const expiryOptions = howData.chain.expirySelectOptions();
    if (!expiryOptions || expiryOptions.length === 0) {
      return;
    }
    let selectedExpiry = expiryOptions.find(
      (e) => e.date.toDateString() === tradingRangeSimulator.dateOfBellSlider.toDateString(),
    );
    if (!selectedExpiry) {
      selectedExpiry = expiryOptions[0];
    }
    setExpiries(expiryOptions);
    setSelectedExpiry(selectedExpiry);
    setHighPrice(tradingRangeSimulator.chartHighPrice);
    setLowPrice(tradingRangeSimulator.chartLowPrice);
    setHighPriceInputValue(tradingRangeSimulator.chartHighPrice.toFixed(2));
    setLowPriceInputValue(tradingRangeSimulator.chartLowPrice.toFixed(2));
  }, [tradingRangeSimulator]);

  useEffect(() => {
    const newTradingRangeSimulator = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    newTradingRangeSimulator.turnToNarrow(howData.stdDev, tradingRangeSimulator.dateOfBellSlider);
    setTradingRangeSimulator(newTradingRangeSimulator);
  }, []);

  if (!howData || !howData.chain || !tradingRangeSimulator) {
    return <Skeleton height={250} animation="wave" />;
  }

  const midSD = tradingRangeSimulator.chartMidStandardDeviation();
  const low = tradingRangeSimulator.chartLowBound();
  const high = tradingRangeSimulator.chartHighBound();

  const onExpiryChange = (expiry: { dateOnSelector: string; date: Date; isGreaterThanMaxExpiry: boolean }) => {
    const newTradingRangeSimulator = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    newTradingRangeSimulator.turnToNarrow(howData.stdDev, expiry.date);
    setSelectedExpiry(expiry);
    setNarrowOrWide('narrow');
    setTradingRangeSimulator(newTradingRangeSimulator);
  };

  const renderExpiries = () => {
    if (!tradingRangeSimulator.hasOption) {
      return undefined;
    }
    if (!expiries) {
      return undefined;
    }
    return (
      <Autocomplete
        sx={{
          width: '95%',
          '& .MuiSvgIcon-root': {
            color: theme.palette.selectAndTextField.main,
          },
        }}
        options={expiries}
        disableClearable
        value={selectedExpiry}
        selectOnFocus
        clearOnBlur
        getOptionLabel={(option) => option.dateOnSelector}
        onChange={(_event, value, _reason) => onExpiryChange(value)}
        renderInput={(params) => (
          <OptionsPlayTextField
            {...params}
            variant="outlined"
            size="small"
            label={<Typography gutterBottom>{t('common.labels.expiry')}</Typography>}
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{
              style: { color: `${theme.palette.text.secondary}` },
            }}
          />
        )}
      />
    );
  };

  const updateValueOfChartSSlider = (lowHighPrices: number[], lowOrHighPrice: 'lowPrice' | 'highPrice') => {
    const priceIndex = lowOrHighPrice === 'lowPrice' ? 0 : 1;
    const opposite = howData.predictions.getSymmetricPrice(
      lowHighPrices[priceIndex],
      tradingRangeSimulator.dateOfBellSlider,
    );
    if (opposite === 0) {
      return lowHighPrices;
    }
    if (priceIndex === 0 && opposite <= lowHighPrices[0]) {
      lowHighPrices[0] = opposite;
      lowHighPrices[1] = lowHighPrices[0];
    } else if (priceIndex === 1 && opposite >= lowHighPrices[1]) {
      lowHighPrices[0] = lowHighPrices[1];
      lowHighPrices[1] = opposite;
    } else {
      lowHighPrices[1 - priceIndex] = opposite;
    }
    return lowHighPrices;
  };

  const onLowPriceCommitted = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    if (!lowPrice) {
      return;
    }
    let price = lowPrice;
    if (lowPrice < low) {
      price = low;
    }
    if (lowPrice >= midSD) {
      price = midSD;
    }
    setLowPrice(price);
    setLowPriceInputValue(price.toFixed(2));
    updateLowPrice(price);
    logActivity('chartLowPrice', 'blur');
  };

  const updateLowPrice = (lowPrice: number) => {
    if (isNaN(lowPrice)) {
      return;
    }
    const newTradingRangeSimulator = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    const newChartSPrice = [lowPrice, newTradingRangeSimulator.chartHighPrice];
    newTradingRangeSimulator.chartSPrices = updateValueOfChartSSlider(newChartSPrice, 'lowPrice');
    setTradingRangeSimulator(newTradingRangeSimulator);
  };

  const onHighPriceCommitted = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }
    if (!highPrice) {
      return;
    }
    let price = highPrice;
    if (highPrice > high) {
      price = high;
    }
    if (highPrice <= midSD) {
      price = midSD;
    }
    setHighPrice(price);
    setHighPriceInputValue(price.toFixed(2));
    updateHighPrice(price);
    logActivity('chartHighPrice', 'blur');
  };

  const updateHighPrice = (highPrice: number) => {
    if (isNaN(highPrice)) {
      return;
    }
    const newTradingRangeSimulator = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    const newChartSPrice = [newTradingRangeSimulator.chartLowPrice, highPrice];
    newTradingRangeSimulator.chartSPrices = updateValueOfChartSSlider(newChartSPrice, 'highPrice');
    setTradingRangeSimulator(newTradingRangeSimulator);
  };

  const onChangeNarrowOrWide = () => {
    const newTradingRangeSimulator = TradingRangeSimulator.fromSelf(tradingRangeSimulator);
    const isWide = narrowOrWide === 'wide';
    if (isWide) {
      newTradingRangeSimulator.turnToNarrow(howData.stdDev, tradingRangeSimulator.dateOfBellSlider);
      logActivity('turnToNarrowButton', 'click');
    } else {
      newTradingRangeSimulator.turnToWide(howData.stdDev, tradingRangeSimulator.dateOfBellSlider);
      logActivity('turnToWideButton', 'click');
    }
    setNarrowOrWide(isWide ? 'narrow' : 'wide');
    setTradingRangeSimulator(newTradingRangeSimulator);
  };

  const logActivity = (name: string, event: string) => {
    ApplicationContext.userActivityHub?.logActivity(name, name, '', event, selectedSymbol);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        alignItems={'center'}
        sx={{ width: '100%', backgroundColor: theme.palette.info.dark, borderRadius: 2, height: '50px' }}
        px={1}>
        <form noValidate autoComplete="off" style={{ width: '100%', padding: 8 }}>
          <Grid container justifyContent="space-around" columnSpacing={1}>
            <Grid item xs={2}>
              <OptionsPlayTextField
                label={<Typography gutterBottom>{'From'}</Typography>}
                variant="outlined"
                id="outlined-basic"
                name="tradingRangeFrom"
                className="op-formatted-textbox"
                value={lowPriceInputValue}
                onChange={(event: any) => {
                  setLowPrice(event.values.floatValue);
                  setLowPriceInputValue(event.values.value);
                }}
                onKeyPress={(event) => {
                  onLowPriceCommitted(event.key);
                }}
                InputProps={{
                  inputComponent: OptionsPlayCurrencyFormatInput as any,
                }}
                InputLabelProps={{
                  style: { color: `${theme.palette.text.secondary}` },
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <OptionsPlayTextField
                label={<Typography gutterBottom>{'To'}</Typography>}
                variant="outlined"
                id="outlined-basic"
                name="tradingRangeTo"
                className="op-formatted-textbox"
                value={highPriceInputValue}
                onChange={(event: any) => {
                  setHighPrice(event.values.floatValue);
                  setHighPriceInputValue(event.values.value);
                }}
                onKeyPress={(event) => {
                  onHighPriceCommitted(event.key);
                }}
                InputProps={{
                  inputComponent: OptionsPlayCurrencyFormatInput as any,
                }}
                InputLabelProps={{
                  style: { color: `${theme.palette.text.secondary}` },
                }}
              />
            </Grid>
            <Grid item xs>
              <Grid>
                <GuideItem selector=".expiryDropdownBox_helpPinPlaceholder" />
                {renderExpiries()}
              </Grid>
            </Grid>
            <Grid item container xs="auto" alignItems={'center'}>
              <Grid item xs="auto">
                <Typography variant="body1">{t('how.toolbox.tradingRangeSimulator.buttons.normal')}</Typography>
              </Grid>
              <Grid item xs>
                <OptionsPlaySwitch
                  value={narrowOrWide}
                  onChange={onChangeNarrowOrWide}
                  checked={narrowOrWide !== 'narrow'}
                  name="narrow or wide"
                />
              </Grid>
              <Grid item xs="auto">
                <Typography variant="body1">{t('how.toolbox.tradingRangeSimulator.buttons.wide')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12}>
        <TradingRangeChartWidget />
      </Grid>
    </Grid>
  );
};
