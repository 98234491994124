import { Combination } from '@op/shared/src/models';
import { PriceCalculationMethod, tradeSimulatorOperationType } from '@op/shared/src/models/enums/enums';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import formatting from '@op/shared/src/models/how/formatting';
import { TradingRangeSimulator } from '@op/shared/src/models/how/trading-range-simulator';
import TradingStrategies from '@op/shared/src/models/how/trading-strategies';
import WhatIfSimulator from '@op/shared/src/models/how/whatif-simulator';
import {
  howDataState,
  subViewState,
  tradeSimulatorOperationTypeState,
  tradeTicketCombinationState,
  tradingRangeSimulatorState,
  tradingStrategiesSentimentAtom,
  tradingStrategiesUpdater,
  tradingStrategySelectedStrategyState,
  viewState,
  whatIfSimulatorState,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TradeTicketWidget } from './trade-ticket-widget';

export const TradingStrategyTicketWidget: React.FC = () => {
  const howData = useRecoilValue(howDataState);
  const setNotifications = useSetRecoilState(notificationsState);
  const setSelectedCombinationId = useSetRecoilState(tradingStrategySelectedStrategyState);
  const setTradingStrategiesUpdater = useSetRecoilState(tradingStrategiesUpdater);
  const setSimulatorOperation = useSetRecoilState(tradeSimulatorOperationTypeState);
  const setWhatIfSimulator = useSetRecoilState(whatIfSimulatorState);
  const setTradingRangeSimulator = useSetRecoilState(tradingRangeSimulatorState);
  const setStrategySentiment = useSetRecoilState(tradingStrategiesSentimentAtom);
  const setSubView = useSetRecoilState(subViewState);
  const setView = useSetRecoilState(viewState);
  const setTradeTicket = useSetRecoilState(tradeTicketCombinationState);

  useEffect(() => {
    const tradeTicketFromLS = JSON.parse(localStorage.getItem('tradeTicketCombination'));
    if (!tradeTicketFromLS) {
      return;
    }
    const isTrade = (tradeTicketFromLS.view as string) === 'trade';
    if (!isTrade) {
      return;
    }
    if (!howData) {
      return;
    }
    initializeTradeIfPossible(tradeTicketFromLS);
  }, [howData]);

  const initializeTradeIfPossible = (data: any) => {
    if (!howData || !data) {
      return;
    }

    /**
     * Note:
     * Below condition is imp as it removes the localstorage on symbol change.
     */

    if (howData.symbol !== data.symbol) {
      localStorage.removeItem('tradeTicketCombination');
      setSubView(undefined);
      return;
    }
    if (!howData.chain || howData.chain.rows.length === 0) {
      setNotifications([{ type: 'info', content: `Symbol ${howData.symbol.toUpperCase()} has no listed options` }]);
    }
    const actualLegs = data.legs;
    const legsWithoutPremium = data.legs.map((obj) => {
      const { premium, ...rest } = obj; // Use destructuring to remove the city property
      return rest; // Return the object without the city property
    });
    const enumKey = formatting.pascalToSnakeCase(data.priceCalculationMethod);
    const priceCalculationMethod = PriceCalculationMethod[enumKey as keyof typeof PriceCalculationMethod];
    const targetPrice = data.targetPrice as number;
    const targetDate = DateTimeHelper.resolveDate(data.targetDate);
    // NOTE: The system works if we do not have datepart to it. Hence, only taking datepart and stripping timepart.
    if (targetDate) {
      targetDate.setHours(0, 0, 0, 0);
    }
    const tradingStrategies = TradingStrategies.fromData(howData.originalSentiment, howData);
    const combinations = tradingStrategies.allCombinations;
    const savedCombination = Combination.fromLegs(legsWithoutPremium, howData, undefined, priceCalculationMethod);
    combinations[0] = savedCombination;
    const tradingRangeSimulator = TradingRangeSimulator.fromData(howData, combinations);
    const whatIfSimulator = WhatIfSimulator.fromData(howData, howData.originalSentiment, tradingRangeSimulator);
    whatIfSimulator.whatIfDate = targetDate;
    if (targetPrice) {
      whatIfSimulator.whatIfSPrice = targetPrice;
    }
    setView('trade');
    setSubView('tradeTicket');
    setSimulatorOperation(tradeSimulatorOperationType.ShowRisksandCheckilst);
    setStrategySentiment(howData.originalSentiment);
    setTradingStrategiesUpdater(combinations);
    setTradingRangeSimulator(tradingRangeSimulator);
    setWhatIfSimulator(whatIfSimulator);
    setSelectedCombinationId('0');
    const tradeticketCombination = Combination.fromLegs(actualLegs, howData, undefined, priceCalculationMethod);
    setTradeTicket({ combination: tradeticketCombination, resultingCombination: undefined });
    // localStorage.removeItem('tradeTicketCombination');
  };

  return <TradeTicketWidget />;
};
