import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import ChechIcon from '@op/shared/assets/images/checkIcon.svg';
import { ISubscriptionPlanPrice } from '@op/shared/src/models';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  addSubscriptionErrorState,
  selectedPlansState,
  selectedSubscriptionState,
  selectedSymbolState,
  subscriptionCurrentPaymentState,
  subscriptionCurrentScreenState,
  subscriptionCurrentState,
  subscriptionPlanPricesModelState,
  userSubscriptionFeaturesDataState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ChangePlanSlider, SubscriptionCardGridView } from '.';
import { LanguageSelectorWidget } from '../header';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy, OPSemiBoldTypograpghy, OptionsPlayDivider } from '../styled';
import { CreditCardTypeWidget } from './credit-card-type-widget';
import { SubscriptionCardLayout } from './subscription-card-layout';
import { SubscriptionErrorWidget } from './subscription-error-widget';
import { SubscriptionSliderSettings, calculateNextPaymentDate } from './subscription-helper';
import { ConfirmationSkeleton } from './subscription-skeleton-widget';

export const SubscriptionConfirmationWidget: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const sliderSettings = SubscriptionSliderSettings(theme, 'confirmation');
  // TODO: need to remove below lines once original makepayment response is integrated.
  const selectedPlans = useRecoilValue(selectedPlansState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const resetSelectedSubscription = useResetRecoilState(selectedSubscriptionState);
  const resetPaymentDetails = useResetRecoilState(subscriptionCurrentPaymentState);
  const resetPricingModels = useResetRecoilState(subscriptionPlanPricesModelState);
  const resetUserSubscriptionFeaturesData = useResetRecoilState(userSubscriptionFeaturesDataState);
  const purchasedPlans = [...selectedPlans.bundles, ...selectedPlans.addOns];
  const [isLoading, setIsLoading] = useState(true);
  const resetUserSubscriptionFeatures = useResetRecoilState(userSubscriptionFeaturesState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const addSubscriptionError = useRecoilValue(addSubscriptionErrorState);

  const paymentDetails = useRecoilValue(subscriptionCurrentPaymentState);
  const currentSubscription = useRecoilValue(subscriptionCurrentState);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  });

  if (isLoading) {
    return <ConfirmationSkeleton />;
  }

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const handleSeeMySubscription = () => {
    logActivity('BUTTON', 'paymentsSeeMySubscriptionButton');
    setSubscriptionCurrentScreen('CurrentSubscription');
    resetState();
  };

  /*
    NOTE: Reset Selected Subscription + Payment Details + PricingModel + UserSubscription
  */
  const resetState = () => {
    resetSelectedSubscription();
    resetPaymentDetails();
    resetPricingModels();
    resetUserSubscriptionFeaturesData();
    resetUserSubscriptionFeatures();
  };

  const backToApp = () => {
    logActivity('BUTTON', 'paymentsBackToAppButton');
    window.location.href = window.location.origin;
    resetState();
  };

  const billSummary = (plan: ISubscriptionPlanPrice) => {
    const selectedType = purchasedPlans.map((type) => type.planType).toString();
    const paymentDate = calculateNextPaymentDate(selectedPlans, currentSubscription);
    const isFutureDate = DateTimeHelper.isFutureDate(paymentDate);
    return (
      <Grid container rowSpacing={1}>
        <SubscriptionCardGridView label={t('subscriptions.typeOfPlan')} value={plan.planType} />
        <SubscriptionCardGridView
          label={t('subscriptions.region')}
          value={<LanguageSelectorWidget iconView={'subscription'} />}
        />
        <SubscriptionCardGridView
          label={t('subscriptions.startDate')}
          value={`${DateTimeHelper.format(paymentDate)}`}
        />
        <SubscriptionCardGridView
          label={t('subscriptions.endDate')}
          value={`${DateTimeHelper.nextDate(paymentDate, selectedType)}`}
        />
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy
              style={{
                fontSize: '14px',
              }}>
              {t('subscriptions.paymentDate')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy
              variant="subHeader"
              style={{ color: isFutureDate ? theme.palette.error.main : theme.palette.text.primary }}>
              {DateTimeHelper.format(paymentDate)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
        <SubscriptionCardGridView
          label={t('subscriptions.cardPay')}
          value={<CreditCardTypeWidget cardNumber={paymentDetails.cardNumber} textView="typeWithNumber" />}
        />
        <Grid item xs={12}>
          <OptionsPlayDivider />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6}>
            <OPBoldTypograpghy variant="subHeader" style={{ color: theme.palette.text.primary }}>
              {t('subscriptions.totalPayment')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <OPBoldTypograpghy variant="subHeader" style={{ color: theme.palette.text.primary }}>
              {NumberFormatHelper.toCurrency(plan.discountedPrice)}
            </OPBoldTypograpghy>
          </Grid>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 400,
              color: theme.palette.text.primary,
            }}
            // variant="body2"
            component="span">
            {`${t('subscriptions.noteToKnowAutomaticRenew')} ${DateTimeHelper.format(paymentDate)}. ${t(
              'subscriptions.ensuresUninterreptedAccess',
            )}`}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Grid item xs={12} container mt={3} pl={2} pr={2.5}>
        <Grid item xs={6}>
          <Button onClick={backToApp} id="pw-subscribe" color="primary" size="large" variant="contained" fullWidth>
            <OPBoldTypograpghy variant="button">{t('subscriptions.backToApp')}</OPBoldTypograpghy>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            onClick={handleSeeMySubscription}
            id="pw-subscribe"
            color="primary"
            size="large"
            variant="contained"
            sx={{ ml: 1 }}>
            <OPBoldTypograpghy variant="button">{t('profile.payments.buttons.seeMySubscription')}</OPBoldTypograpghy>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderPurchasedPlans = () => {
    return (
      <Grid item xs={12}>
        <Box sx={{ width: '90%', margin: '0 auto', mt: 2, mb: 5 }}>
          <>
            <ChangePlanSlider {...sliderSettings}>
              {purchasedPlans.map((plan, index) => (
                <SubscriptionCardLayout
                  key={index}
                  sx={{ marginX: 2, pb: 0.5 }}
                  title={
                    <Grid item container xs={12} justifyContent="space-between">
                      <OPBoldTypograpghy variant="subHeader">
                        {`${
                          plan.featureType === 'Bundle' ? t('subscriptions.activePlan') : t('subscriptions.activeAddOn')
                        } ${t('subscriptions.subscription')}`}
                      </OPBoldTypograpghy>
                      <img src={ChechIcon} alt="" />
                    </Grid>
                  }
                  isConfirmationScreen
                  content={billSummary(plan)}
                />
              ))}
            </ChangePlanSlider>
            <Grid item xs={12} container mt={3} pl={2} pr={2.5}>
              <Grid item xs={6}>
                <Button
                  onClick={backToApp}
                  id="pw-subscribe"
                  color="primary"
                  size="large"
                  variant="contained"
                  fullWidth>
                  <OPBoldTypograpghy variant="button">{t('subscriptions.backToApp')}</OPBoldTypograpghy>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={handleSeeMySubscription}
                  id="pw-subscribe"
                  color="primary"
                  size="large"
                  variant="contained"
                  sx={{ ml: 1 }}>
                  <OPBoldTypograpghy variant="button">
                    {t('profile.payments.buttons.seeMySubscription')}
                  </OPBoldTypograpghy>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="center" mt={1}>
              <Typography
                variant="subHeader"
                style={{
                  color: theme.palette.text.primary,
                  justifyContent: 'center',
                }}>
                {t('subscriptions.receiptOnYourMail')}
              </Typography>
            </Grid>
          </>
        </Box>
      </Grid>
    );
  };

  const renderSuccessful = () => {
    // TODO: need to remove below lines once original makepayment response is integrated.
    const { bundles, addOns } = selectedPlans;
    let message = bundles.length > 0 ? `${bundles[0].planType} "${bundles[0].featureKey}"` : '';
    message = addOns.length > 0 ? `${message} + ${addOns.length} Add-On` : message;
    const paymentDate = calculateNextPaymentDate(selectedPlans, currentSubscription);
    const isFutureDate = DateTimeHelper.isFutureDate(paymentDate);
    return (
      <Grid container item xs={12} justifyContent={'center'}>
        <OPSemiBoldTypograpghy
          style={{ color: theme.palette.primary.main, fontSize: '18px', fontWeight: 600, marginRight: '4px' }}
          variant="subtitle1"
          component="span">
          {`${t('subscriptions.your')} ${message} ${t('subscriptions.subscriptionHasBeen')}`}
        </OPSemiBoldTypograpghy>
        <OPSemiBoldTypograpghy
          variant="subtitle1"
          style={{ color: theme.palette.success.main, fontSize: '18px', fontWeight: 600 }}>
          {isFutureDate ? t('subscriptions.successfullyActivatedUpcoming') : t('subscriptions.successfullyActivated')}
          {'.'}
        </OPSemiBoldTypograpghy>
      </Grid>
    );
  };

  const renderContent = () => {
    if (addSubscriptionError) {
      return <SubscriptionErrorWidget />;
    }
    return (
      <Grid container p={2} justifyContent={'center'} rowSpacing={2}>
        <Grid item container xs={12} rowSpacing={1} justifyContent={'center'}>
          <Grid item container xs={9} rowSpacing={0.5} textAlign={'center'}>
            <Grid item xs={12}>
              {renderSuccessful()}
            </Grid>
            <Grid item xs={12}>
              <OPLightTypograpghy variant="subHeader" style={{ color: theme.palette.text.primary }} component="span">
                {t('subscriptions.gainConfidenceOverInvestments')}
              </OPLightTypograpghy>
            </Grid>
          </Grid>
          <Grid item container xs={12} rowSpacing={1}>
            {renderPurchasedPlans()}
          </Grid>
          {/* <Grid item xs={12}>
            {renderButtons()}
          </Grid> */}
        </Grid>
      </Grid>
    );
  };

  return renderContent();
};
