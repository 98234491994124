import { Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { StyledTableCell } from '../ts-styles-component';

interface Props {
  isOrders?: boolean;
}

export const OrderSkeleton: React.FC<Props> = ({ isOrders }: Props) => {
  const numbersOfColumn: number[] = Array.from({ length: isOrders ? 17 : 14 }, (_, index) => index);
  return (
    <Grid container>
      <Grid item container xs={12} paddingBottom={0.5} alignItems={'center'}>
        <Grid item xs={0.5} justifyContent="flex-start">
          <Skeleton variant="rectangular" height={30} width={30} sx={{ mt: 0.5, marginRight: 1 }} />
        </Grid>
        <Grid item container xs={9} alignItems={'center'}>
          {isOrders && (
            <Grid item xs={2.5}>
              <Skeleton animation="wave" variant="rounded" height={30} />
            </Grid>
          )}
          {!isOrders && (
            <Grid container columnSpacing={1}>
              <Grid item xs={1}>
                <Skeleton animation="wave" variant="text" height={25} />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton animation="wave" variant="rounded" height={30} />
              </Grid>
              <Grid item xs={1}>
                <Skeleton animation="wave" variant="text" height={25} />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton animation="wave" variant="rounded" height={30} sx={{ ml: 2 }} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={2.5} container alignItems={'center'} justifyContent={'right'}>
          <Skeleton variant="rounded" height={30} width={'80%'} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="none"></StyledTableCell>
              <StyledTableCell align="center">Symbol</StyledTableCell>
              <StyledTableCell align="center">Side</StyledTableCell>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Qty</StyledTableCell>
              <StyledTableCell align="center">Filled Qty</StyledTableCell>
              <StyledTableCell align="center">Limit Price</StyledTableCell>
              <StyledTableCell align="center">Stop Price</StyledTableCell>
              <StyledTableCell align="center">Avg Fill Price</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Open Time</StyledTableCell>
              <StyledTableCell align="center">Close Time</StyledTableCell>
              <StyledTableCell align="center">Routing</StyledTableCell>
              <StyledTableCell align="center">Duration</StyledTableCell>
              {isOrders && (
                <>
                  <StyledTableCell align="center">Commission Fee</StyledTableCell>
                  <StyledTableCell align="center">Route Fee</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[0, 1, 2, 3, 4].map((row) => (
              <TableRow key={row}>
                {numbersOfColumn.map((col, index) => (
                  <TableCell size="small" component="th" sx={{ width: '8%' }} key={index}>
                    <Skeleton animation="wave" height={20} width="80%" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
