import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TableCell, TextField, styled } from '@mui/material';
import { Combination, ILeg, Leg, Position } from '@op/shared/src/models';
import { EventType, LegType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { sharesState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

const QuantityTextField = styled(TextField)(({ theme }) => ({
  border: 'none',
  width: '30%',
  textAlign: 'center',
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Muidisabled):before': {
    borderBottom: 'none',
  },
}));

type Props = {
  index: number;
  position: Position;
  combination: Combination;
  onEditCombinationCallback: (action: string, legs?: ILeg[]) => void;
};

export const QuantityField: React.FC<Props> = ({ index, position, combination, onEditCombinationCallback }) => {
  const shares = useRecoilValue(sharesState);
  const combinationPositions = combination?.positions;
  const [quantities, setQuantities] = useState<number[]>(() => {
    if (!combinationPositions) return [];
    const leqQuantities: number[] = [];
    combinationPositions.map((p, i) => {
      return leqQuantities.push(Math.abs(p.quantity));
    });
    return leqQuantities;
  });

  useEffect(() => {
    if (!combination) return;
    const positions = combination.positions;
    setQuantities(() => {
      if (!positions) return [];
      const legQuantities: number[] = [];
      positions.map((p, i) => {
        return legQuantities.push(p.quantity);
      });
      return legQuantities;
    });
    //  setselectedPrice(combination.priceCalculationMethod);
    //  setPriceBy(combination.priceCalculationMethod === PriceCalculationMethod.BID_ASK ? 0 : 1);
  }, [combination]);

  const getLegs = () => {
    const legs = combination.positions.map((p) => Leg.fromPosition(p));
    return legs;
  };

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      combination.symbol,
    );
  };

  /*
   * (1) Business rule for SECURITY leg:
   * -1a If INDEX symbol      => increment by 100.
   * -1b if NOT INDEX symbol  => increment by 1
   * NOTE: On display for index symbol => (Security quantity / 100)
   * -----------------------------------------------------------------
   * (2) Business rule for INCOME strategy
   * -2a If Covered call  => #Option legs <= (security qantity / 100);
   * -2b If PUT           => No restriction on spin up.
   *
   */
  const qtySpinUp = () => {
    //business rule - 1a, 1b
    const increment = combination.isIndex && position.legType === LegType.SECURITY ? 100 : 1;
    const newQuantity = position.absQuantity + increment;
    //business rule - (2a)
    if (combination.isIncome && position.isCallType()) {
      //for income call type, it is guarenteed that security leg is present.
      // const security = combination.originalLegs?.find((p): p is ILeg => p.legType == LegType.SECURITY);
      if (!shares) {
        return;
      }
      if (newQuantity > Math.abs(shares) / 100) {
        return;
      }
    }
    const posIndex = combination.positions.indexOf(position);
    let newLeg = {
      buyOrSell: position.buyOrSell,
      quantity: newQuantity,
      legType: position.legType,
      expiry: position.expiry,
      strikePrice: position.strike,
    };
    let legs = getLegs();
    const leg = combination.canUpdatePosition(newLeg);
    if (!leg) {
      return;
    }
    legs.splice(posIndex, 1, leg);
    //security quantity should be 100 * option qty for COVERED CALL
    if (combination.isIncome && position.isCallType()) {
      legs = legs.filter((l) => l.legType !== LegType.SECURITY);
      legs.push({
        buyOrSell: position.buyOrSell,
        quantity: newQuantity * 100,
        legType: LegType.SECURITY,
      });
    }
    onEditCombinationCallback('', legs);
    logActivity('ICON', 'editorQuantityChangeButton', position.absQuantity);
  };

  /*
   * (1) Business rule for SECURITY leg:
   * -1a If INDEX symbol      => increment by 100.
   * -1b if NOT INDEX symbol  => increment by 1
   * NOTE: On display for index symbol => (Security quantity / 100)
   * -----------------------------------------------------------------
   * (2) Business rule for INCOME strategy
   * -2a If Covered call  => #Option legs <= (security qantity / 100);
   * -2b If PUT           => No restriction on spin up.
   *
   */
  const qtySpinDown = () => {
    // Business rule: 1a, 1b
    const decrement = combination.isIndex && position.legType === LegType.SECURITY ? 100 : 1;
    const newQuantity = position.absQuantity - decrement;
    //Security cannot be elss than 100
    if (position.isSecurityType() && newQuantity < 100) {
      return;
    }
    //option cannot be less than 1.
    if (position.isOptionType && newQuantity < 1) {
      return;
    }
    const posIndex = combination.positions.indexOf(position);
    let newLeg = {
      buyOrSell: position.buyOrSell,
      quantity: newQuantity,
      legType: position.legType,
      expiry: position.expiry,
      strikePrice: position.strike,
    };
    let legs = getLegs();
    const leg = combination.canUpdatePosition(newLeg);
    if (!leg) {
      return;
    }
    legs.splice(posIndex, 1, leg);
    if (combination.isIncome && position.isCallType()) {
      legs = legs.filter((l) => l.legType !== LegType.SECURITY);
      //security quantity should be 100 * option qty for COVERED CALL
      legs.push({
        buyOrSell: position.buyOrSell,
        quantity: newQuantity * 100,
        legType: LegType.SECURITY,
      });
    }
    onEditCombinationCallback('', legs);
    logActivity('ICON', 'editorQuantityChangeButton', position.absQuantity);
  };

  const getQuantityValue = (position: Position, index: number) => {
    if (combination.isIndex && position.legType === LegType.SECURITY) {
      return Math.abs(quantities[index] / 100);
    }
    return Math.abs(quantities[index]);
  };

  const prevQtyOnBlur = () => {
    let newqty = quantities;
    if (isNaN(newqty[index])) {
      newqty[index] = combination.positions[index].quantity;
    } else if (Math.abs(newqty[index]) < 1) {
      newqty[index] = 1;
    }
    setQuantities(newqty);
    updateQty(newqty[index], index, position);
  };

  const updateQty = (qty: number, index: number, position: Position) => {
    let newLeg = {
      buyOrSell: position.buyOrSell,
      quantity: qty,
      legType: position.legType,
      expiry: position.expiry,
      strikePrice: position.strike,
    };
    const newCombination = Combination.fromSelf(combination);
    newCombination.replacePosition(newLeg, index);
    onEditCombinationCallback('', getLegsFromCombination(newCombination));
  };

  const setQty = (key: string) => {
    if (key.trim().toUpperCase() !== 'ENTER') {
      return;
    }

    let newqty = quantities;
    if (isNaN(newqty[index])) {
      newqty[index] = combination.positions[index].quantity;
    } else if (Math.abs(newqty[index]) < 1) {
      newqty[index] = 1;
    } else {
      newqty = quantities;
    }
    setQuantities(newqty);
    updateQty(newqty[index], index, position);
  };

  const onchangeOfQty = (qtyValue: number) => {
    if (qtyValue * 100 > shares && combination.isIncome && !combination.isPut()) {
      return;
    }
    let qtArray: number[] = [];
    combination.positions.map((p, i) => {
      if (i === index) {
        /**
         * For Indexed Symbols based on the client inputs changed security quantity
         * At all places doing /100 so on change multiplied with 100
         * Only for UI purpose internal logic remains same.
         */
        qtyValue = qtyValue;
        if (combination.isIndex && position.legType === LegType.SECURITY) {
          qtyValue = qtyValue * 100;
        }
        return qtArray.push(Math.abs(qtyValue));
      } else {
        return qtArray.push(Math.abs(p.quantity));
      }
    });
    setQuantities(qtArray);
    logActivity('ICON', 'editorQuantityChange', position.absQuantity);
  };

  const getLegsFromCombination = (combination: Combination) => {
    const newLegs = combination.positions.map((p) => Leg.fromPosition(p));
    return newLegs;
  };

  const canShowSpinDown = () => {
    return true;
  };

  const canShowSpinUp = () => {
    return true;
  };

  return (
    <TableCell className="quantity-cell" sx={{ width: '11%', p: 0 }}>
      {canShowSpinDown() && <ArrowDropDownIcon sx={{ p: 0 }} onClick={qtySpinDown} />}
      <QuantityTextField
        type="number"
        value={getQuantityValue(position, index)}
        onBlur={prevQtyOnBlur}
        onChange={(event) => onchangeOfQty(parseFloat(event.target.value))}
        onKeyDown={(event) => setQty(event.key)}
        inputProps={{
          sx: { textAlign: 'center', width: '100%' },
        }}
        InputProps={{ disableUnderline: true }}
      />
      {canShowSpinUp() && <ArrowDropUpIcon onClick={qtySpinUp} />}
    </TableCell>
  );
};
