import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Badge, Grid, OutlinedInput, Select, Typography, useTheme } from '@mui/material';
import TDAmeritradeIcon from '@op/shared/assets/images/TDAmeritrade.png';
import TDAmeritradeInactiveIcon from '@op/shared/assets/images/TDAmeritradeInactive.png';
import { IFilter, isEqual } from '@op/shared/src/models';
import { PortfolioType } from '@op/shared/src/models/enums/enums';
import { IAlertsByAccounts } from '@op/shared/src/models/portfolio/alert-filter-model';
import React, { useState } from 'react';
import { IconComponent } from '../common';
import { MenuProps, OrangeButton } from './styled-components';
import { OPSelectDropDownHeader, StyledDropdownItemWithBorder } from './styled-dropdown-item-widget';

interface IOPAlertSelectComponentWidgetProps {
  open: boolean;
  selectedValues: IFilter | undefined;
  handleChange: (value: string[]) => void;
  onClearFilter: () => void;
  handleOpen: () => void;
  handleClose: () => void;
  data: IAlertsByAccounts;
  type: string;
}

export const OPIconSelectComponentWidget: React.FC<IOPAlertSelectComponentWidgetProps> = ({
  open,
  data,
  selectedValues,
  onClearFilter,
  handleChange,
  handleOpen,
  handleClose,
  type,
}: IOPAlertSelectComponentWidgetProps) => {
  const theme = useTheme();
  const [isAll, setIsAll] = useState(false);

  const renderGridItem = (name: string, count: number) => {
    return (
      <Grid container justifyContent={'space-between'} alignItems="center">
        <Grid item xs={10.5}>
          <Typography className="label-value">{name.toString()}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography className="count-value">{count}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderCustomItem = (name: string, count: number, autoEnableAll: boolean) => {
    let isAllSelected = isAll || autoEnableAll;
    return (
      <Grid container justifyContent={'space-between'} alignItems="center">
        <Grid item xs={10.5}>
          <Typography
            sx={{
              color:
                isAllSelected && type === 'alert'
                  ? theme.palette.success.main
                  : isAllSelected && type === 'notification'
                  ? theme.palette.primary.main
                  : 'inherit',
            }}>
            {name.toString()}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography
            style={{
              color: isAllSelected ? '#ffffff' : 'inherit',
              backgroundColor:
                isAllSelected && type === 'alert'
                  ? theme.palette.success.main
                  : isAllSelected && type === 'notification'
                  ? theme.palette.primary.main
                  : 'inherit',
              borderRadius: theme.shape.borderRadius,
              textAlign: 'center',
              border: `1px solid ${theme.palette.grey[500]}`,
            }}>
            {count}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const onClickAll = () => {
    const allKeys = data.alert.map((a) => a.key);
    allKeys.push('all');
    handleChange(isAll ? [] : allKeys);
    setIsAll(!isAll);
  };

  /**
   * "All" button for alert/notification should enable when user selects every item
   * @returns boolean
   */
  const autoEnableAllButtonOnSelect = () => {
    let value = false;
    if (selectedValues && selectedValues.value.length > 0) {
      const selectedKeys = data.alert.map((a) => a.key);
      value = isEqual(selectedValues.value, selectedKeys);
    }
    return value;
  };

  return (
    <Badge
      invisible={data.totalCount === 0}
      badgeContent={
        <Typography
          style={{
            backgroundColor: theme.palette.error.main,
            color: '#ffffff',
            paddingTop: 5,
            borderRadius: 12,
            width: 20,
            height: 20,
            textAlign: 'center',
            fontSize: 9,
          }}>
          {data.totalCount}
        </Typography>
      }
      sx={{
        color: type === 'alert' ? theme.palette.success.main : theme.palette.primary.main,
        alignItems: 'center',
      }}
      max={99}>
      <OrangeButton onClick={handleOpen} isSelected={selectedValues && selectedValues.value.length > 0}>
        {type === 'alert' ? (
          <ReportProblemIcon
            id="js-porfolio-accounts-alert-counts"
            fontSize="small"
            style={{ color: theme.palette.success.main }}
          />
        ) : (
          <InfoIcon id="js-porfolio-accounts-notification-counts" fontSize="small" />
        )}
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </OrangeButton>

      <Select
        labelId="demo-multiple-name-label"
        id={`${type}-filter`}
        multiple
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedValues?.value || []}
        onChange={(e) => {
          if (e.target.value.includes('all')) {
            return;
          }
          handleChange(e.target.value as string[]);
        }}
        sx={{ p: 0 }}
        input={<OutlinedInput style={{ visibility: 'hidden', width: 1, height: 1, lineHeight: 0 }} />}
        MenuProps={MenuProps}>
        <OPSelectDropDownHeader
          type={type}
          onClearFilter={() => {
            setIsAll(!isAll);
            onClearFilter();
          }}
        />
        <StyledDropdownItemWithBorder
          onClick={onClickAll}
          key={-1}
          color={type === 'alert' ? 'green' : 'blue'}
          value={'all'}
          selected={true}>
          {renderCustomItem('All', data.totalCount, autoEnableAllButtonOnSelect())}
        </StyledDropdownItemWithBorder>
        {data.alert.map((r, i) => {
          const isSelected = selectedValues ? selectedValues.value.indexOf(r.name) === -1 : false;
          const color =
            isSelected && type === 'alert' ? 'green' : isSelected && type === 'notification' ? 'blue' : 'default';
          return (
            <StyledDropdownItemWithBorder color={color} key={i} value={r.key} selected={isSelected}>
              {renderGridItem(r.name, r.count)}
            </StyledDropdownItemWithBorder>
          );
        })}
      </Select>
    </Badge>
  );
};

export const AccountSelectIconItemWidget: React.FC<{
  isGlobal: boolean;
  isSelected: boolean;
  portfolioTypeStatus?: string;
}> = ({
  isGlobal,
  isSelected,
  portfolioTypeStatus,
}: {
  isGlobal: boolean;
  isSelected: boolean;
  portfolioTypeStatus: string;
}) => {
  const theme = useTheme();
  if (isGlobal) {
    return (
      <div style={{ marginTop: 4 }}>
        <IconComponent
          name="globalIcon"
          stroke={isSelected ? theme.palette.success.main : theme.palette.text.secondary}
          size={15}
        />
      </div>
    );
  } else if (portfolioTypeStatus && portfolioTypeStatus === PortfolioType.TDAmeritrade) {
    return (
      <img
        alt="logo"
        src={isSelected ? TDAmeritradeIcon : TDAmeritradeInactiveIcon}
        style={{ width: 21, height: 21, position: 'relative', top: 3 }}></img>
    );
  }
  return (
    <div style={{ marginTop: 4 }}>
      <IconComponent
        name="userIcon"
        stroke={isSelected ? theme.palette.primary.main : theme.palette.text.secondary}
        size={15}
      />
    </div>
  );
};

export const WatchlistSelectIconItemWidget: React.FC<{ isGlobal: boolean; isSelected: boolean }> = ({
  isGlobal,
  isSelected,
}: {
  isGlobal: boolean;
  isSelected: boolean;
}) => {
  const theme = useTheme();
  if (isGlobal) {
    return <IconComponent name="globalIcon" stroke={isSelected ? theme.palette.success.main : '#999999'} size={15} />;
  }
  return <IconComponent name="userIcon" stroke={isSelected ? theme.palette.primary.main : '#999999'} size={15} />;
};
