export class SaveRequest {
  list = -1;
  symbols: string[] = [];
  removed: string[] = [];

  private constructor() {}

  static fromData = (data: any) => {
    const model = new SaveRequest();
    model.list = data.list;
    model.symbols = data.symbols.map((s: string) => s.trim().toUpperCase());
    model.removed = data.removed.map((r: string) => r.trim().toUpperCase());
    return model;
  };
}
