import { EmbedderRequestValidationToken } from '../models';
import { ResponseViewModel } from '../view-models/responses/response-viewmodel';
import { ApiService } from './api-service';
import { BaseApiService } from './base-api-service';

export class EmbedderService extends BaseApiService {
  private static _instance: EmbedderService;
  private constructor() {
    super('/widgetloader');
    ApiService.instance.init(EmbedderService.onError, EmbedderService.onLoading);
  }
  
  public static get instance(): EmbedderService {
    if (EmbedderService._instance == null) {
      EmbedderService._instance = new EmbedderService();
    }
    return EmbedderService._instance;
  } 

  private static onError = (errorCode: number): void => {
    console.error('Error in powerhouse-pro-service: ', errorCode);
  };

  private static onLoading = (isLoading: boolean): void => {};

  public getRequestValidationToken = async (): Promise<ResponseViewModel<EmbedderRequestValidationToken[]>> => {
    const response = await ApiService.instance.get<EmbedderRequestValidationToken[]>('/widgetloader');
    return response;
  };
}
