export enum ThemeType {
  LIGHT = 'light',
  // DARK = 'dark',
  // MLBLUE = 'ml-blue',
  MLDARK = 'ml-dark',
  MLLIGHT = 'ml-light',
  // OBSIDIAN = 'obsidian',
  NASDAQ = 'nasdaq',
  QUESTRADELIGHT = 'edge-desktop-light',
  QUESTRADEDARK = 'edge-desktop-dark',
  QUESTRADEBLUE = 'edge-desktop-blue',
  QUESTRADEWEBLIGHT = 'edge-web-light',
  THREEPANELLIGHT = 'three-panel-light',
  TWOPANELLIGHT = 'two-panel-light',
}

/**
 * null value comes for first time user
 * when user has theme property as null -> set light theme as default
 */

/**
 * As per the discussion from ml-blue,obsidian and dark theme will be removed.
 * fallback theme should be slate theme
 * Rename slate to Dark
 */

export const getThemeType = (text: string | null | undefined): ThemeType => {
  if (!text || text == null || text.trim().length == 0) {
    return ThemeType.LIGHT;
  }

  const themeConversion = text.replace(/-/g, '');
  console.log('themeConversion', themeConversion);
  if (themeConversion.length == 0) {
    return ThemeType.LIGHT;
  }

  switch (themeConversion.toUpperCase()) {
    case 'LIGHT':
      return ThemeType.LIGHT;
    case 'DARK':
    // return ThemeType.DARK;
    case 'MLBLUE':
    case 'OBSIDIAN':
    case 'MLDARK':
      return ThemeType.MLDARK;
    case 'MLLIGHT':
      return ThemeType.MLLIGHT;
    case 'NASDAQ':
      return ThemeType.NASDAQ;
    case 'EDGEDESKTOPLIGHT':
      return ThemeType.QUESTRADELIGHT;
    case 'EDGEDESKTOPDARK':
      return ThemeType.QUESTRADEDARK;
    case 'EDGEDESKTOPBLUE':
      return ThemeType.QUESTRADEBLUE;
    case 'EDGEWEBLIGHT':
      return ThemeType.QUESTRADEWEBLIGHT;
    case 'THREEPANELLIGHT':
      return ThemeType.THREEPANELLIGHT;
    case 'TWOPANELLIGHT':
      return ThemeType.TWOPANELLIGHT;
    default:
      return ThemeType.LIGHT;
  }
};

export default ThemeType;
