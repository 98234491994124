import { Box, Grid, Paper, SwipeableDrawer, Theme, Typography, useTheme, Zoom } from '@mui/material';
import { PowerhouseProHub } from '@op/shared/src/hubs/powerhouse-pro-hub';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  fetchSharedTradeById,
  fetchSharedTradeByOcc,
  fetchSharedTradeByOccHash,
  fetchTradeIdeas,
  shareRequestPattern,
  shareStrategyLegRequestPattern,
  shareStrategyRequestPattern,
} from '@op/shared/src/services';
import {
  accountState,
  isDataLoadingState,
  isHubInitiatedState,
  prepareScans,
  prepareSectors,
  scanFilterState,
  sectorFilterState,
  strategyShareDataState,
  subViewState,
  tradesSetState,
  viewState,
  viewTypes,
} from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import React, { useEffect, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { HowTabsWidget } from '../how';
import { IncomeSingleStrategyWidget } from '../income/income-single-strategy-widget';
import { QuoteBarWidget } from '../quoteBar/quote-bar-widget';
import { HeightWidget, NavigationWidget, PageContainerWidget, PageLoaderWidget } from '.';
import { TradeSingleStrategyWidget } from '../single-trade/trade-single-strategy-widget';
import { useConnectPowerhouseProHub } from '../states/use-connect-powerhouse-pro-hub';
import { useFetchAndSetAccount } from '../states/use-fetch-and-set-account';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { useMinWidthBreakpoint } from '../states/use-min-width-breakpoint';
import { useRegisterHubListeners } from '../states/use-register-hub-listeners';
import { TradeTicketWidget } from '../trade-ticket';
import { MidTabPanelWidget } from '../why/mid-tab-panel-widget';
import { TechnicalIdeas } from '../ideas/technical-idea/technical-ideas';
import { useOPNavigate } from '../states/use-navigate-search';
import { GuideItem } from '../side-menu';
import { TradeStationDock } from '../tradestation';

export const SharePageWidget: React.FC = () => {
  const isNormalView = useMinWidthBreakpoint();
  const navigate = useOPNavigate();
  const account = useRecoilValue(accountState);
  const registerHubHandlers = useRegisterHubListeners();
  const connectPowerhouseProHub = useConnectPowerhouseProHub();
  const setNotifications = useSetRecoilState(notificationsState);
  const fetchAndSetAccount = useFetchAndSetAccount();
  const setStrategyShareData = useSetRecoilState(strategyShareDataState);
  const setTrades = useSetRecoilState(tradesSetState);
  const setIsDataLoading = useSetRecoilState(isDataLoadingState);
  const fetchAndSetData = useFetchAndSetData();
  const shareRoute = useMatch('/share/*');
  const [searchParams, _setSearchParams] = useSearchParams();
  const setIsHubConnected = useSetRecoilState(isHubInitiatedState);
  const [shareId, setShareId] = useState('');
  const [view, setView] = useRecoilState(viewState);
  const [subView, setSubView] = useRecoilState(subViewState);
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [combinationType, setCombinationType] = useState<string | undefined>();
  const theme = useTheme<Theme>();
  const setSectorFilter = useSetRecoilState(sectorFilterState);
  const setScanFilter = useSetRecoilState(scanFilterState);
  const [ready, setIsReady] = useState(false);

  const initialize = async (response: ResponseViewModel<any> | undefined, isValidToken: boolean, idOrOcc?: string) => {
    if (!response || response.hasErrors || !response.data) {
      setNotifications([{ type: 'error', content: 'Incorrect shared trade details' }]);
      navigate('/');
      window.location.reload();
      return;
    }
    const data = response.data as any;
    //connect hub
    if (isValidToken) {
      await connectPowerhouseProHub();
    } else if (idOrOcc && idOrOcc.trim() !== '') {
      await connectPowerhouseProHub(idOrOcc);
    }
    if (account && !account.canWorkWithTradeIdeas) {
      setNotifications([{ type: 'error', content: 'No permission for technical ideas' }]);
    }
    if (!account || account.canWorkWithTradeIdeas) {
      const fetchedTrades = await fetchTradeIdeas(!isValidToken, idOrOcc);
      if (!fetchedTrades) {
        setNotifications([{ type: 'error', content: 'Unable to get technical ideas.' }]);
        return;
      }
      const sectorFilterGroup = prepareSectors(fetchedTrades.sectors);
      const scanFilterGroup = prepareScans(fetchedTrades.scans);
      setTrades(fetchedTrades);
      setSectorFilter(sectorFilterGroup);
      setScanFilter(scanFilterGroup);
    }
    const isSuccessful = await fetchAndSetData(data.symbol, undefined, !isValidToken, idOrOcc);
    if (!isSuccessful) {
      setNotifications([{ type: 'error', content: 'Insufficent data' }]);
      navigate('/');
      window.location.reload();
      return;
    }
    setShareId(idOrOcc || '');
    setCombinationType(data.combinationType as string);
    // setView((data.combinationType as string) === 'Trade' ? 'trade' : 'income');
    const tradeTicketFromLS = JSON.parse(localStorage.getItem('tradeTicketCombination'));
    const combinationView = (data.combinationType as string) === 'Trade' ? 'trade' : 'income';
    const tradeTicketFromLSview = tradeTicketFromLS?.view === 'trade' ? 'trade' : 'income';
    setView(tradeTicketFromLS ? (tradeTicketFromLSview as viewTypes) : combinationView);
    setSubView(tradeTicketFromLS ? 'tradeTicket' : 'expand');
    setStrategyShareData(data);
    setIsDataLoading(false);
    setIsReady(true);
  };

  const onDisconnect = async () => {
    setIsHubConnected(false);
    setTimeout(async () => {
      await connectSharedPowerhouseProHub(shareId);
    }, 5000);
  };

  //TODO: Not used, refactoring in progress.
  //for shared route, shoudl be moved to share page.
  const connectSharedPowerhouseProHub = async (shareId: string) => {
    // if (!shareRoute) {
    //   return;
    // }
    setIsHubConnected(false);
    const powerhouseProHub = PowerhouseProHub.fromSocketConnection(shareId);
    const isConnected = await powerhouseProHub.connect();
    //TODO: retry here if not connected.
    if (!isConnected) {
      return;
    }
    powerhouseProHub.socketConnection.onDisconnect = onDisconnect;
    ApplicationContext.powerhouseProHub = powerhouseProHub;
    registerHubHandlers(ApplicationContext.powerhouseProHub);
    setIsHubConnected(true);
  };

  //TODO: should carry the returnUrl, so that user can redirect to previous url
  const navigateToLogin = () => {
    setIsDataLoading(false);
    if (!shareRoute || shareRoute.params['*'] === '') {
      navigate('/login');
      // Here the reload is required as the case /share or /share/ scenario home page widget is not laoding with data.
      window.location.reload();
      return;
    }
    navigate('/login');
  };

  useEffect(() => {
    document.title = 'OptionsPlay Ideas | Share';
    // setSubView('expand');
    (async () => {
      if (!shareRoute) {
        navigate('/');
        return;
      }
      setIsDataLoading(true);
      const isValidToken = await fetchAndSetAccount();
      const idOrOcc = shareRoute.params['*'] || '';
      const isValidShareId = shareRequestPattern.test(idOrOcc);
      if (isValidShareId) {
        const response = await fetchSharedTradeById(idOrOcc);
        await initialize(response, isValidToken, idOrOcc);
        return;
      }
      if (!isValidToken) {
        navigateToLogin();
        return;
      }
      const isValidStrategyParam = shareStrategyRequestPattern.test(idOrOcc);
      const isValidStrategyParam2 = shareStrategyLegRequestPattern.test(idOrOcc || '');

      //OCC format is only for logged in users.
      if (isValidToken && (isValidStrategyParam || isValidStrategyParam2)) {
        const response = await fetchSharedTradeByOcc(idOrOcc);
        await initialize(response, isValidToken);
        return;
      }
      const hash = searchParams.get('hash') || '';
      if (hash.trim() !== '') {
        const response = await fetchSharedTradeByOccHash(hash);
        await initialize(response, isValidToken);
        return;
      }
      navigateToLogin();
    })();
  }, []);

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  const DrawerContent = () => {
    return (
      <HeightWidget>
        <Grid container rowSpacing={0.5}>
          <Grid item xs={12}>
            <GuideItem selector=".whatPanelSwitcher_helpPinPlaceholder" />
            <NavigationWidget />
          </Grid>
          <Grid item xs={12}>
            <GuideItem selector=".grid_companyName_helpPinPlaceholder" />
            <TechnicalIdeas />
          </Grid>
        </Grid>
      </HeightWidget>
    );
  };

  const renderTradeIdeasCollapseControl = () => {
    if (openCollapse) {
      return (
        <Paper>
          <Typography>Close Trade Ideas</Typography>
        </Paper>
      );
    }
    return (
      <Paper
        style={{ background: theme.palette.grey[300], padding: '20px', height: '800px', cursor: 'pointer' }}
        color="primary">
        <Typography
          variant="h6"
          style={{ height: '200px', transform: 'rotate(-90deg)', position: 'absolute', top: '50%' }}>
          Open Trade Ideas
        </Typography>
      </Paper>
    );
  };

  const renderFirstColumn = () => {
    if (isNormalView) {
      return DrawerContent();
    }
    return (
      <>
        <div style={{ height: '100%' }} onClick={handleOpenSettings}>
          {renderTradeIdeasCollapseControl()}
        </div>
        <SwipeableDrawer
          variant={'temporary'}
          open={openCollapse}
          anchor="left"
          onClose={() => setOpenCollapse(!openCollapse)}
          onOpen={() => setOpenCollapse(!openCollapse)}
          transitionDuration={{
            enter: 500,
            exit: 500,
          }}
          PaperProps={{
            sx: {
              width: 400,
            },
          }}>
          {DrawerContent()}
        </SwipeableDrawer>
      </>
    );
  };

  const renderRequiredView = () => {
    if (subView === 'tradeTicket') {
      return (
        <Zoom in={subView === 'tradeTicket'} unmountOnExit>
          <Box sx={{ pt: '7px' }}>
            <TradeTicketWidget />
          </Box>
        </Zoom>
      );
    }
    /**
     * View condition is important as once income share is loaded
     * then on click of trade tab also becuase of route match combination type is income
     * the strategy is loading the income combination on expand of trade.
     */
    if (subView === 'expand' && combinationType === 'Trade' && view === 'trade') {
      return <TradeSingleStrategyWidget />;
    }
    if (subView === 'expand' && combinationType === 'Income' && view === 'income') {
      return <IncomeSingleStrategyWidget />;
    }
    return <HowTabsWidget />;
  };

  if (!ready) {
    return <PageLoaderWidget />;
  }

  return (
    <PageContainerWidget>
      <Grid container columnSpacing={1}>
        <Grid item xs={isNormalView ? 7 : 6} container columnSpacing={1}>
          <Grid item xs={isNormalView ? 4 : 1}>
            <div>{renderFirstColumn()}</div>
          </Grid>
          <Grid item xs={isNormalView ? 8 : 11}>
            <HeightWidget noScroll>
              <Grid container>
                <Grid item xs={12}>
                  <QuoteBarWidget />
                </Grid>
                <Grid item xs={12}>
                  <MidTabPanelWidget />
                </Grid>
              </Grid>
            </HeightWidget>
          </Grid>
        </Grid>
        <Grid item xs={isNormalView ? 5 : 6}>
          <HeightWidget>{renderRequiredView()}</HeightWidget>
        </Grid>
        <Grid item xs={12}>
          <TradeStationDock />
        </Grid>
      </Grid>
    </PageContainerWidget>
  );
};
