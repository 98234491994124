import { Grid, Skeleton, Table, TableBody, TableCell, TableRow, useTheme, TableHead } from '@mui/material';
import { StyledTableCell } from '../ts-styles-component';

export const PositionsSkeleton: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item container xs={12} paddingBottom={0.5} alignItems={'center'}>
        <Grid item xs={0.5} justifyContent="flex-start">
          <Skeleton variant="rounded" height={30} width={30} />
        </Grid>
        <Grid item container xs={9} alignItems={'center'}>
          <Grid item xs={1}>
            <Skeleton animation="wave" variant="text" height={25} />
          </Grid>
          {[0, 1, 2].map((item) => (
            <Grid
              item
              container
              xs={2.5}
              key={item}
              justifyContent={'space-between'}
              sx={{
                borderRight: `1px dotted ${theme.palette.divider}`,
              }}
              paddingX={2}>
              <Skeleton animation="wave" width={'40%'} variant="text" height={25} />
              <Skeleton animation="wave" width={'40%'} variant="text" height={25} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={2.5} container alignItems={'center'} justifyContent={'right'}>
          <Skeleton variant="rounded" height={30} width={'80%'} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="none"></StyledTableCell>
              <StyledTableCell padding="none">Symbol</StyledTableCell>
              <StyledTableCell padding="none">Side</StyledTableCell>
              <StyledTableCell padding="none">Qty</StyledTableCell>
              <StyledTableCell padding="none">Avg Price</StyledTableCell>
              <StyledTableCell padding="none">Last Price</StyledTableCell>
              <StyledTableCell padding="none">Unrealized P/l</StyledTableCell>
              <StyledTableCell padding="none">Unrealized P/l qty</StyledTableCell>
              <StyledTableCell padding="none">Total Cost</StyledTableCell>
              <StyledTableCell padding="none">Position ID</StyledTableCell>
              <StyledTableCell padding="none">Opened At</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[0, 1, 2, 3, 4].map((row) => (
              <TableRow key={row}>
                <TableCell size="small" component="th" sx={{ width: '8%' }}>
                  <Grid item container xs={12}>
                    <Skeleton variant="rounded" height={20} width={'20%'} sx={{ ml: 0.5, mr: 2 }} />
                    <Skeleton animation="wave" height={20} width="40%" />
                  </Grid>
                </TableCell>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row) => (
                  <TableCell size="small" component="th" sx={{ width: '8%' }} key={row}>
                    <Skeleton animation="wave" height={20} width="80%" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
