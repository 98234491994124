import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { OptionsPlayTextField } from '../../styled';

interface OrderQuantityProps {
  orderTicketQuantity: string;
}

export const OrderTicketQuantityWidget: React.FC<OrderQuantityProps> = ({
  orderTicketQuantity,
}: OrderQuantityProps) => {
  const theme = useTheme();

  return (
    <Grid container>
      <Typography variant="captionSmall">Quantity</Typography>
      <Typography color={theme.palette.error.main} lineHeight={1.2}>
        *
      </Typography>
      <OptionsPlayTextField
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        id="order_quantity"
        name="order_quantity"
        value={orderTicketQuantity}
        variant="filled"
        sx={{
          width: '100%',
          borderRadius: '8px',
          backgroundColor: 'D9D9D9',
          '& .MuiInputBase-input': {
            paddingY: '3px',
            paddingX: '8px',
          },
        }}
      />
    </Grid>
  );
};
