import {
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { BillingTransactions } from '@op/shared/src/models';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { PaymentsService } from '@op/shared/src/services';
import { currentSubscriptionState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { TableBodyCell, TableHeaderCell } from '../styled';

export const SkeletonLoadingBillingHistory: React.FC = () => {
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell padding="none">Invoices</TableCell>
          <TableCell padding="none">Amount</TableCell>
          <TableCell padding="none">Date</TableCell>
          <TableCell padding="none">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[0, 1, 2, 3].map((row) => (
          <TableRow key={row}>
            <TableCell size="small" component="th" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
            <TableCell padding="none" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
            <TableCell padding="none" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
            <TableCell padding="none" sx={{ width: '25%' }}>
              <Skeleton animation="wave" height={30} width="80%" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const BillingHistory: React.FC = () => {
  const { t } = React.useContext(LocalizationContext);
  const [loading, setLoading] = React.useState(false);
  const [transactions, setTransactions] = React.useState(new BillingTransactions());
  const theme = useTheme();
  const CurrentSubscriptionData = useRecoilValue(currentSubscriptionState);
  const planType = CurrentSubscriptionData.currentSubscription.billingModel;

  const fetchTransactions = async () => {
    setLoading(true);
    const response = await PaymentsService.instance.billingTransactions();

    if (response.data) {
      setTransactions(response.data || new BillingTransactions());
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const renderContent = () => {
    return (
      <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 0 }}>
        <TableContainer sx={{ maxHeight: 210 }}>
          {loading ? (
            <SkeletonLoadingBillingHistory />
          ) : (
            <Table stickyHeader aria-label="simple table" sx={{ lineHeight: '5rem' }}>
              <TableHead sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#2E3439' }}>
                <TableRow>
                  <TableHeaderCell align="left" padding="none">
                    {t('subscriptions.invoices')}
                  </TableHeaderCell>
                  <TableHeaderCell align="left" padding="none">
                    {t('subscriptions.amountTitle')}
                  </TableHeaderCell>
                  <TableHeaderCell align="left" padding="none">
                    {t('subscriptions.date')}
                  </TableHeaderCell>
                  <TableHeaderCell align="left" padding="none">
                    {t('subscriptions.statusTitle')}
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              {transactions.data.length <= 0 && (
                <TableBody>
                  <Typography variant="body1" sx={{ marginX: 2 }}>
                    {t('subscriptions.noBillingHistory')}
                  </Typography>
                </TableBody>
              )}
              <TableBody sx={{ backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : '#2E3439' }}>
                {transactions.data.map((row, index) => {
                  return (
                    <TableRow>
                      <TableBodyCell align="left" size="small" scope="row">
                        {`OP ${planType} Subscription `}
                      </TableBodyCell>
                      <TableBodyCell padding="none">{`$${row.amount}`}</TableBodyCell>
                      <TableBodyCell align="left" size="small" scope="row" sx={{ width: 200 }}>
                        {DateTimeHelper.format(row.dateSubmitted)}
                      </TableBodyCell>
                      <TableBodyCell padding="none" sx={{ color: theme.palette.success.main }}>
                        {row.status}
                      </TableBodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    );
  };

  return <Grid container>{renderContent()}</Grid>;
};
