import ApplicationContext from './application-context';
import { ICallOptimal } from './put-optimal';

export class CoveredCallExtensions {
  static getPriorityByTimeFrameAndAggressiveness = (timeFrame: string, aggressiveness: string) => {
    const mappings = ApplicationContext.configuration.coveredCallOptimalPriorityMapping;
    const priorityMapping = mappings.find((mapping) => {
      return mapping.timeFrame === timeFrame && mapping.aggressiveness === aggressiveness;
    });
    if (priorityMapping) {
      return priorityMapping.priority;
    }
    return 0;
  };

  static getMappingByPriority = (priority: number) => {
    return ApplicationContext.configuration.coveredCallOptimalPriorityMapping.find((comb) => {
      return comb.priority === priority;
    });
  };

  //TODO: This should return ICallOptimal or IPutOptical accrodingly.
  static findOptimal = (optimals: ICallOptimal[], priority?: number) => {
    if (!optimals || optimals.length === 0) {
      return undefined;
    }
    if (!priority) {
      //here we can have only one legType for all coveredCalls
      const legType = optimals[0].legType;
      const coveredCallConfiguration = ApplicationContext.configuration.applicationConfiguration.coveredCall;
      const defaultAggressiveness = coveredCallConfiguration[legType.toLowerCase()].aggressiveness;
      const defaultTimeFrame = coveredCallConfiguration[legType.toLowerCase()].timeFrame;
      priority = CoveredCallExtensions.getPriorityByTimeFrameAndAggressiveness(defaultTimeFrame, defaultAggressiveness);
    }
    const suitableStrategy = optimals.find((coveredCall) => {
      return coveredCall.priority === priority;
    });
    if (suitableStrategy) {
      return suitableStrategy;
    }
    const higherPriorityStrategy = optimals.find((coveredCall) => {
      if (!priority) {
        return false;
      }
      return coveredCall.priority > priority;
    });
    if (higherPriorityStrategy) {
      return higherPriorityStrategy;
    }
    let lowerPriorityStrategy: ICallOptimal | undefined;
    for (let i = optimals.length - 1; i >= 0; i--) {
      const call = optimals[i];
      if (call.priority < priority) {
        lowerPriorityStrategy = call;
      }
    }
    return lowerPriorityStrategy;
  };
}
