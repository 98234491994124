import { ICommissionResponceProps } from '.';

export class CommissionSchedule implements ICommissionResponceProps {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: CommissionSchedule): CommissionSchedule => {
    const model = new CommissionSchedule();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
