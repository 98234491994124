import { Grid, Typography } from '@mui/material';
import { selectedSymbolState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';
import { TrendingWidget } from '../../common';
import { OPBoldTypograpghy } from '../../styled';
import { IdeaMoreDetails } from '../idea-more-details';
import { OPTradeIdeaStyledHoverCard } from '../op-styled-card';
import { ICreditSpreadsProps } from '../pager';
import { FirstRow } from '../row-first';
import { ForthRow } from '../row-forth';
import { SecondRow } from '../row-second';
import { StrategyTypeWidget } from './strategy-type';

interface Props {
  //TODO: accept ICreditSpread itself, no need of anotehr prop interface.
  data: ICreditSpreadsProps;
  onItemSelected: (symbol: string, hash: string) => void;
}
export const CreditSpread: React.FC<Props> = ({ data, onItemSelected }: Props) => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const idea = data;
  const isSelected = selectedSymbol?.toUpperCase() === idea.symbol.toUpperCase();

  return (
    <OPTradeIdeaStyledHoverCard selected={isSelected} container alignItems={'center'}>
      <Grid item xs={12}>
        <TrendingWidget symbol={idea.symbol} sentiment={idea.strategy}>
          <Grid
            container
            onClick={() => {
              const hashIndex = idea.url.indexOf('HASH=');
              const hash = idea.url.substring(hashIndex + 5);
              onItemSelected(idea.symbol, hash);
            }}>
            <Grid item xs={12} container justifyContent="space-between" pr={1}>
              <FirstRow data={idea} />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between" pr={1}>
              <StrategyTypeWidget data={idea} />
            </Grid>
            <Grid item xs={12} justifyContent="space-between" pr={1}>
              <SecondRow data={idea} />
            </Grid>
            <Grid item container xs={12} justifyContent="space-between" pr={1}>
              <Grid item xs={8}>
                <Typography variant="caption" pr={1}>
                  Premium vs Width
                </Typography>
                <OPBoldTypograpghy variant="caption">{idea.premiumPercentage}</OPBoldTypograpghy>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="caption" pr={1}>
                  Earnings
                </Typography>
                <OPBoldTypograpghy variant="caption">{idea.hasEarnings}</OPBoldTypograpghy>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="space-between" alignItems="center">
              <ForthRow data={idea} />
            </Grid>
          </Grid>
        </TrendingWidget>
      </Grid>
      <Grid item xs={12}>
        <IdeaMoreDetails data={idea} signals={data.signals} />
      </Grid>
    </OPTradeIdeaStyledHoverCard>
  );
};
