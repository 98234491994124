import { LanguageType } from '@op/shared/src/models';
import { configurationState, globalizationState } from '@op/shared/src/states';
import React from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useRecoilValue } from 'recoil';
import { OptionsPlayInputWidgetProps } from './options-play-input-widget-props';

export const OptionsPlayCurrencyFormatInput = React.forwardRef<typeof NumericFormat, OptionsPlayInputWidgetProps>(
  function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
    const configuration = useRecoilValue(configurationState);
    const globalization = useRecoilValue(globalizationState);
    const { onChange, ...other } = props;
    const prefix = configuration?.showCurrencySymbolForNumbers ? '$' : '';
    const thousandSeparator = globalization?.active?.name === LanguageType.Swedish ? ' ' : ',';
    const decimalSeparator = globalization?.active?.name === LanguageType.Swedish ? ',' : '.';

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
            values: values,
          });
        }}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowedDecimalSeparators={['.', ',']}
        decimalScale={2}
        valueIsNumericString
        prefix={prefix}
      />
    );
  },
);

//TODO: Should be able to pass props to this compoment
export const OptionsPlayCurrencyNonNegativeFormatInput = React.forwardRef<
  typeof NumericFormat,
  OptionsPlayInputWidgetProps
>(function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
  const { onChange, ...other } = props;
  const configuration = useRecoilValue(configurationState);
  const globalization = useRecoilValue(globalizationState);
  const prefix = configuration?.showCurrencySymbolForNumbers ? '$' : '';
  const thousandSeparator = globalization?.active?.name === LanguageType.Swedish ? ' ' : ',';
  const decimalSeparator = globalization?.active?.name === LanguageType.Swedish ? ',' : '.';

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
          values: values,
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowedDecimalSeparators={['.', ',']}
      valueIsNumericString
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      prefix={prefix}
    />
  );
});

export const OptionsPlayNumberFormatInput = React.forwardRef<typeof NumericFormat, OptionsPlayInputWidgetProps>(
  function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
    const { onChange, ...other } = props;
    const globalization = useRecoilValue(globalizationState);
    const thousandSeparator = globalization?.active?.name === LanguageType.Swedish ? ' ' : ',';
    const decimalSeparator = globalization?.active?.name === LanguageType.Swedish ? ',' : '.';

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
            values: values,
          });
        }}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowedDecimalSeparators={['.', ',']}
        decimalScale={2}
        valueIsNumericString
      />
    );
  },
);

export const OptionsPlayNonDecimalNonNegativeNumberFormatInput = React.forwardRef<
  typeof NumericFormat,
  OptionsPlayInputWidgetProps
>(function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
  const { onChange, ...other } = props;
  const globalization = useRecoilValue(globalizationState);
  const thousandSeparator = globalization?.active?.name === LanguageType.Swedish ? ' ' : ',';

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
          values: values,
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalScale={0}
      allowNegative={false}
      valueIsNumericString
    />
  );
});

export const OptionsPlayPercentageFormatInput = React.forwardRef<typeof NumericFormat, OptionsPlayInputWidgetProps>(
  function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
    const { onChange, ...other } = props;
    const MIN_VAL = 0;
    const MAX_VAL = 100;
    const withValueLimit = ({ floatValue, value }: any) => {
      if (value.length === 0) {
        return true;
      }
      return floatValue >= MIN_VAL && floatValue <= MAX_VAL;
    };
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
            values: values,
          });
        }}
        isAllowed={withValueLimit}
        step={1}
        allowNegative={false}
        decimalScale={2}
        valueIsNumericString
        suffix="%"
      />
    );
  },
);

export const OptionsPlayMaskedFormatInput = React.forwardRef<typeof NumericFormat, OptionsPlayInputWidgetProps>(
  function NumberFormatCustom(props: OptionsPlayInputWidgetProps, ref) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
            values: values,
          });
        }}
        format={'####'}
      />
    );
  },
);
