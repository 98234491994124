import { Box, CardHeader, Checkbox, Grid, Typography } from '@mui/material';
import { Permissions } from '@op/shared/src/models/enums/enums';
import { ProfileDetailsService } from '@op/shared/src/services';
import { accountState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDivider } from '../styled/options-play-components';

const cardHeader = {
  paddingTop: 0,
  paddingBottom: 0,
};
export const AllowAccessWidget: React.FC = () => {
  const { t } = React.useContext(LocalizationContext);
  const [account] = useRecoilState(accountState);
  const [checkApplication, setCheckApplication] = useState(false);
  const [checkPortfolio, setCheckPortfolio] = useState(false);
  const [ApplicationAccessDate, setApplicationAccessDate] = useState<any>('');
  const [portfolioAccessDate, setPortfolioAccessDate] = useState<any>('');

  const handleApplicationChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckApplication((prevstate) => !prevstate);
    // const applicationDate = checkApplication ? Date.toString : '';
    // const field = {
    //   type: 'ApplicationAccess',
    //   isSet: !checkApplication,
    // };
    // const editResponse = await ProfileDetailsService.instance.SetAccess(field);
  };
  const handlePortfolioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPortfolio((prevstate) => !prevstate);
    // const field = {
    //   type: 'PortfolioLogAccess',
    //   isSet: !checkPortfolio,
    // };
    // const editResponse = await ProfileDetailsService.instance.SetAccess(field);
  };
  const fetchApplicationAccess = async () => {
    try {
      const response = await ProfileDetailsService.instance.GetAccess('ApplicationAccess');
      if (!response.hasErrors) {
        if (response.data) {
          setCheckApplication(true);
          setApplicationAccessDate(response.data);
        } else setCheckApplication(false);
      } else {
        console.error('useCommissions: error fetching data: ', response.getErrors);
      }
    } catch (error) {
      console.error('useCommissions: error fetching data: ', error);
    }
  };
  const fetchPortfolioAccess = async () => {
    try {
      const response = await ProfileDetailsService.instance.GetAccess('PortfolioLogAccess');
      if (!response.hasErrors) {
        if (response.data) {
          setCheckPortfolio(true);
          setPortfolioAccessDate(response.data);
        } else setCheckPortfolio(false);
      } else {
        console.error('useCommissions: error fetching data: ', response.getErrors);
      }
    } catch (error) {
      console.error('useCommissions: error fetching data: ', error);
    }
  };

  useEffect(() => {
    fetchApplicationAccess();
    fetchPortfolioAccess();
  }, []);

  const hasPlaidAccess = account?.securityModel?.hasPermission(Permissions.LINK_PORTFOLIO);
  const isViewOPPEnabled = account?.isViewOPPEnabled;

  return (
    <>
      <CardHeader
        title={t('profile.changePassword.titles.allowAdminAccess')}
        style={{ ...cardHeader, paddingBottom: 0 }}
      />
      <OptionsPlayDivider />
      <Box px={4} py={2}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body1" sx={{ paddingRight: 'auto', fontWeight: 700 }}>
              {t('profile.changePassword.labels.applicationAccess')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox checked={checkApplication} onChange={handleApplicationChange} color="primary" />
          </Grid>
          {checkApplication ? (
            <Grid item xs={8} textAlign="left">
              <Typography variant="body1">
                {` ${t('profile.changePassword.labels.adminAccessExpires')} ${
                  portfolioAccessDate ? portfolioAccessDate : ''
                }.${t('profile.changePassword.labels.uncheckToRevokeAccess')}`}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={8} textAlign="left">
              <Typography variant="body1">{t('profile.changePassword.labels.checkToProvideAccess')}</Typography>
            </Grid>
          )}
          <br />
          <Grid container>
            <Typography variant="body1">{t('profile.changePassword.labels.applicationAccessNotification')}</Typography>
          </Grid>
        </Grid>
        <br />
        {isViewOPPEnabled && hasPlaidAccess ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="body1" style={{ paddingRight: 'auto', fontWeight: 700 }}>
                {t('profile.changePassword.labels.PortfolioLogAccess')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Checkbox checked={checkPortfolio} onChange={handlePortfolioChange} color="primary" />
            </Grid>
            {checkPortfolio ? (
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body1">
                  {` ${t('profile.changePassword.labels.adminAccessExpires')} ${
                    portfolioAccessDate ? portfolioAccessDate : ''
                  }.${t('profile.changePassword.labels.uncheckToRevokeAccess')}`}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="body1">{t('profile.changePassword.labels.checkToProvideAccess')}</Typography>
              </Grid>
            )}
            <br />
            <Grid item>
              <Typography variant="body1">
                {t('profile.changePassword.labels.portfolioLogAccessNotification')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container></Grid>
        )}
      </Box>
    </>
  );
};
