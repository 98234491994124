import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, useTheme } from '@mui/material';
import { Account as AccountModel, ISubscriptionFeature, ISubscriptionFeatures } from '@op/shared/src/models';
import { EventType, SubscriptionStatus, UserSubscriptionPeriod } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import {
  accountState,
  isDeleteRequested,
  selectedSubscriptionState,
  selectedSymbolState,
  stepperUpdaterState,
  subscriptionCurrentScreenState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy } from '../styled';
import { ActiveSubscriptionWidget } from './active-subscription';
import { AddOn } from './add-on';
import { BillingHistoryNew } from './billing-history-new';
import { CanceledSubscriptionWidget } from './canceled-subscription';
import { ExpiredSubscriptionWidget } from './expired-subscription';
import { FreeSubscriptionWidget } from './free-subscription';
import { PendingSubscriptionWidget } from './pending-subscription';
import { SubscriptionFooterWidget } from './subscription-footer-widget';
import { SuspendedSubscriptionWidget } from './suspended-subscription';

export const SubscriptionCurrentWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const account = useRecoilValue<AccountModel | undefined>(accountState);
  const setSubscriptionCurrentScreen = useSetRecoilState(subscriptionCurrentScreenState);
  const userCachedSubscription = useRecoilValue(userSubscriptionFeaturesState);
  const deleteRequested = useRecoilValue(isDeleteRequested);
  const setActiveStep = useSetRecoilState(stepperUpdaterState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const setSelectedSubscription = useSetRecoilState(selectedSubscriptionState);

  const [features, setFeatures] = useState<ISubscriptionFeatures[]>();

  useEffect(() => {
    if (!userCachedSubscription || userCachedSubscription.features.length === 0) {
      return;
    }
    const features = userCachedSubscription.features;
    const currentSubscriptions = features.find((f) => f.title === UserSubscriptionPeriod.CURRENT).subscriptionList;
    const pastSubscriptions = features.find((f) => f.title === UserSubscriptionPeriod.PAST).subscriptionList;
    const upcomingSubscriptions = features.find((f) => f.title === UserSubscriptionPeriod.UPCOMING).subscriptionList;

    const mergedSubscriptions = [...currentSubscriptions, ...pastSubscriptions, ...upcomingSubscriptions];
    const updatedFeatures = features.map((feature) => {
      if (feature.title === UserSubscriptionPeriod.CURRENT) {
        return { ...feature, subscriptionList: mergedSubscriptions };
      } else if (feature.title === UserSubscriptionPeriod.PAST || feature.title === UserSubscriptionPeriod.UPCOMING) {
        return { ...feature, subscriptionList: [] };
      } else {
        return feature;
      }
    });
    setFeatures(updatedFeatures);
  }, [userCachedSubscription]);

  if (!userCachedSubscription || !account) {
    return null;
  }

  const logActivity = (controlType: string, controlName: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      '',
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const renderActiveTrialView = (feature: ISubscriptionFeature) => {
    const endDate = DateTimeHelper.resolveDate(feature.endDate);
    const daysRemaining = NumberFormatHelper.floor(DateTimeHelper.daysFromNow(endDate));
    const activeTrailText = `${t('subscriptions.yourFreeTrialHas')} ${daysRemaining} ${t(
      'subscriptions.daysRemaining',
    )}. ${t('subscriptions.subscriptionBeginsAtEndOfFreeTrial')} ${DateTimeHelper.format(feature.endDate)}.`;

    /**
     * !Note : As per the discussion Subscribe now button should be
     * hidden when there is active trail and upcoming bundle
     */

    const hasPendingSubscription = features?.some((feature) =>
      feature?.subscriptionList?.some((subscription) => subscription.subscriptionStatus === SubscriptionStatus.PENDING),
    );

    const showSubscribeNow =
      !(hasPendingSubscription && feature.subscriptionStatus === SubscriptionStatus.ACTIVE_TRIAL) && !deleteRequested;

    return (
      <Grid container spacing={1} px={1}>
        <Grid item xs={12}>
          <Typography
            style={{
              color: theme.palette.success.main,
              fontSize: 16,
              fontWeight: 700,
            }}>
            {activeTrailText}
          </Typography>
        </Grid>
        {showSubscribeNow && (
          <Grid item container xs={12} justifyContent={'flex-end'}>
            <Grid xs={4}>
              <Button
                color="success"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.success.main,
                  ':hover': { backgroundColor: theme.palette.success.main },
                }}
                onClick={() => {
                  logActivity('BUTTON', 'paymentsChangeSubscriptionButton');
                  setSubscriptionCurrentScreen('ChangeSubscription');
                  setActiveStep('changePlan');
                  setSelectedSubscription(feature);
                }}>
                <OPBoldTypograpghy variant="button">{t('subscriptions.subscribeNow')}</OPBoldTypograpghy>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderFeatureHeader = (index: number, firstHeaderIndextoShow: number) => {
    /**
     * Header should be shown when the bundle starts showing without active trail.
     */
    if (firstHeaderIndextoShow !== index) {
      return;
    }
    return (
      <Grid container px={1} ml={1}>
        <Grid item xs={11.6} container>
          <Grid item xs={2.5}>
            <OPBoldTypograpghy variant="subtitle2">
              {t('subscriptions.subscription')} {t('subscriptions.plans')}
            </OPBoldTypograpghy>
          </Grid>
          <Grid item xs={2}>
            <OPBoldTypograpghy variant="subtitle2">{t('subscriptions.billingCycle')}</OPBoldTypograpghy>
          </Grid>
          <Grid item xs={2.5}>
            <OPBoldTypograpghy variant="subtitle2">{t('subscriptions.startDate')}</OPBoldTypograpghy>
          </Grid>
          <Grid item xs={2.5}>
            <OPBoldTypograpghy variant="subtitle2">{t('subscriptions.endDate')}</OPBoldTypograpghy>
          </Grid>
          <Grid item xs={2.5}>
            <OPBoldTypograpghy variant="subtitle2">{t('subscriptions.nextBillingDate')}</OPBoldTypograpghy>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderFeatureDetails = (feature: ISubscriptionFeature, index: number) => {
    switch (feature.subscriptionStatus) {
      case SubscriptionStatus.ACTIVEFREE:
        return <FreeSubscriptionWidget plan={feature} index={index} />;
      case SubscriptionStatus.ACTIVE_TRIAL:
        return renderActiveTrialView(feature);
      case SubscriptionStatus.ACTIVE:
        return <ActiveSubscriptionWidget plan={feature} index={index} />;
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.CANCELED_NOT_EXPIRED:
        return <CanceledSubscriptionWidget plan={feature} index={index} />;
      case SubscriptionStatus.EXPIRED:
      case SubscriptionStatus.EXPIRED_TRIAL:
        return <ExpiredSubscriptionWidget plan={feature} index={index} />;
      case SubscriptionStatus.SUSPENDED:
      case SubscriptionStatus.SUSPENDED_NOT_EXPIRED:
        return <SuspendedSubscriptionWidget plan={feature} index={index} />;
      case SubscriptionStatus.PENDING:
        return <PendingSubscriptionWidget plan={feature} index={index} />;
      default:
        return null;
    }
  };

  const renderContent = (userFeatures: ISubscriptionFeature[]) => {
    const firstHeaderIndextoShow = userFeatures.findIndex(
      (f) => f.subscriptionStatus !== SubscriptionStatus.ACTIVE_TRIAL,
    );
    return (
      <>
        {userFeatures.map((feature, index) => (
          <Grid container key={index}>
            {renderFeatureHeader(index, firstHeaderIndextoShow)}
            {renderFeatureDetails(feature, index)}
          </Grid>
        ))}
      </>
    );
  };

  // NOTE: Don't remove commented code
  // const renderSubscriptionAccordion = () => {
  //   if (features.length === 0) {
  //     return null; // Return null if there are no features
  //   }

  //   const hasCurrent = features.some(
  //     (feature) => feature.title === UserSubscriptionPeriod.CURRENT && feature.subscriptionList.length > 0,
  //   );

  //   return features.map((userFeature: ISubscriptionFeatures, index: number) => {
  //     if (userFeature.subscriptionList.length === 0) {
  //       return null; // Return null if the subscription list is empty
  //     }

  //     const isCurrent = userFeature.title === UserSubscriptionPeriod.CURRENT;
  //     const isPast = userFeature.title === UserSubscriptionPeriod.PAST;

  //     const isEnabled = isCurrent ? isCurrent : isPast && !hasCurrent ? isPast : false;
  //     return (
  //       <Grid item xs={12} key={index}>
  //         <Accordion
  //           sx={{ width: '100%' }}
  //           disableGutters
  //           expanded={isEnabled || expanded === userFeature.title}
  //           onChange={(event, isExpanded) => {
  //             if (!isEnabled) {
  //               setExpanded(isExpanded ? userFeature.title : false);
  //             }
  //           }}>
  //           <AccordionSummary
  //             id={`${userFeature.title}-header`}
  //             expandIcon={!isEnabled && <ArrowDropDownIcon sx={{ color: theme.palette.timeFrame.contrastText }} />}
  //             sx={{
  //               backgroundColor: theme.palette.primary.main,
  //               paddingY: 0,
  //               borderRadius: 1,
  //             }}>
  //             <OPBoldTypograpghy variant="subtitle1" sx={{ color: theme.palette.timeFrame.contrastText }}>
  //               {userFeature.title}
  //             </OPBoldTypograpghy>
  //           </AccordionSummary>
  //           <AccordionDetails>{renderContent(userFeature.subscriptionList)}</AccordionDetails>
  //         </Accordion>
  //       </Grid>
  //     );
  //   });
  // };

  const renderSubscriptionAccordion2 = () => {
    if (!features || features.length === 0) {
      return null; // Return null if there are no features
    }

    return features.map((userFeature: ISubscriptionFeatures, index: number) => {
      if (userFeature.subscriptionList.length === 0) {
        return null; // Return null if the subscription list is empty
      }

      return (
        <Grid item xs={12} key={index}>
          <Accordion sx={{ width: '100%' }} disableGutters expanded={true}>
            <AccordionSummary
              id={`${userFeature.title}-header`}
              sx={{
                backgroundColor: theme.palette.primary.main,
                paddingY: 0,
                borderRadius: 1,
              }}>
              <OPBoldTypograpghy variant="subtitle1" sx={{ color: theme.palette.timeFrame.contrastText }}>
                {t(`subscriptions.${userFeature.title}`)}
              </OPBoldTypograpghy>
            </AccordionSummary>
            <AccordionDetails>{renderContent(userFeature.subscriptionList)}</AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };

  return (
    <Grid container rowSpacing={1.5} p={1.5}>
      <Grid item xs={12}>
        {/* {renderSubscriptionAccordion()} */}
        {renderSubscriptionAccordion2()}
      </Grid>
      <Grid item xs={12}>
        <AddOn />
      </Grid>
      <Grid item xs={12}>
        {/* <BillingHistory /> */}
        <BillingHistoryNew />
      </Grid>
      <Grid item xs={12}>
        <SubscriptionFooterWidget sx={{ paddingY: 0, paddingX: 0.2 }} />
      </Grid>
    </Grid>
  );
};
