import React from 'react';
import { Grid, Skeleton, useTheme } from '@mui/material';

export const OrderConfirmTextSeleton: React.FC = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        borderRight: `1px dotted ${theme.palette.divider}`,
      }}
      columnSpacing={1}
      marginY={1}
      paddingX={1}>
      <Grid item xs={6}>
        <Skeleton variant="rounded" height={25} />
      </Grid>
      <Grid item xs={6} textAlign={'right'}>
        <Skeleton variant="rounded" height={25} />
      </Grid>
    </Grid>
  );
};
export const OrderConfirmationSkeleton: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid container alignItems={'center'}>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={12}
        xl={10}
        m={0.5}
        p={2}
        rowSpacing={1}
        sx={{ backgroundColor: theme.palette.secondary.light, borderRadius: 2 }}>
        <Grid
          item
          container
          xs={12}
          sx={{ backgroundColor: theme.palette.header.default, borderRadius: 1, boxShadow: 1, padding: 1 }}>
          <Grid item xs={12} margin={1}>
            <Skeleton variant="rounded" height={25} width={'60%'} />
          </Grid>

          <Grid item container>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((row) => (
              <Grid item xs={3.5} md={2} lg={2} xl={1.5} key={row}>
                <OrderConfirmTextSeleton />
              </Grid>
            ))}
          </Grid>

          <Grid item container xs={12} columnSpacing={2}>
            {[0, 1, 2, 3].map((row) => (
              <Grid key={row} item xs={3}>
                <Skeleton height={120} />
              </Grid>
            ))}
          </Grid>
          <Grid item container xs={12} md={12} lg={12} xl={10} justifyContent={'flex-end'} columnSpacing={2}>
            <Skeleton variant="rounded" height={30} width={'15%'} sx={{ marginRight: 1 }} />
            <Skeleton variant="rounded" height={30} width={'15%'} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
