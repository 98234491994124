export const timeInForceDurationOptions = ['DAY', 'DAY+', 'GTC', 'GTC+', 'GTD'];
export const routingOptions = ['Intelligent'];
export const orderTypes = ['Market', 'Limit', 'Stop Market', 'Stop Limit'];
export const orderSides = ['SELL TO OPEN', 'BUY TO OPEN', 'SELL TO CLOSE', 'BUY TO CLOSE'];

// TODO: optional properties need to be removed.
export interface ICommonOrderRequest {
  accountID: string;
  quantity?: string;
  tradeAction?: string;
  symbol?: string;
  orderType?: string;
  buyingPowerWarning?: 'Enforce' | 'Preconfirmed' | 'Confirmed';
  timeInForce: string;
  gtdTime?: Date;
  route: string;
  stopPrice?: string;
  limitPrice?: string;
  legs: IPlaceOrderLeg[];
}

export interface IMarketOrderRequest extends ICommonOrderRequest {}

export interface ILimitOrderRequest extends IMarketOrderRequest {
  limitPrice: string;
}

export interface IStopOrderRequest extends IMarketOrderRequest {
  stopPrice: string;
}

export interface IStopLimitOrderRequest extends IMarketOrderRequest {
  stopPrice: string;
  limitPrice: string;
}
export interface IPlaceOrderLeg {
  symbol: string;
  expression: string;
  tradeAction: string;
  quantity: string;
  expiryDate: string;
  actionSymbol?: string;
  strikePrice: string;
}

export interface IConfirmOrderTicket {
  confirmations: IConfirmation[];
}

export interface IConfirmation {
  orderAssetCategory: string;
  currency: string;
  displayName: string;
  route: string;
  timeInForce: ITimeInForce;
  accountID: string;
  orderConfirmID: string;
  estimatedPrice: string;
  estimatedCost: string;
  debitCreditEstimatedCost: string;
  estimatedCommission: string;
  summaryMessage: string;
}

export type TimeInForceDurationType = 'DAY' | 'DAY+' | 'GTC' | 'GTC+' | 'GTD';
export interface ITimeInForce {
  duration: string;
  expiration: string;
}

export interface IMarketPlaceOrderRequest extends IMarketOrderRequest {
  orderConfirmID: string;
}

export interface ILimitPlaceOrderRequest extends IMarketPlaceOrderRequest {
  limitPrice: string;
}

export interface IStopPlaceOrderRequest extends IMarketPlaceOrderRequest {
  stopPrice: string;
}

export interface IStopLimitPlaceOrderRequest extends IMarketPlaceOrderRequest {
  limitPrice: string;
  stopPrice: string;
}
export interface IPlaceOrderTicket {
  orders: IPlaceOrder[];
  errors: IPlaceOrderError[];
}

export interface IPlaceOrderError {
  error: string;
  message: string;
  orderId: string;
}

export interface IPlaceOrder {
  message: string;
  orderId: string;
}
