// @ts-nocheck
import 'chartiq/examples/feeds/symbolLookupChartIQ';
import quoteFeed from 'chartiq/examples/feeds/quoteFeedSimulator';
import defaultConfig from 'chartiq/js/defaultConfiguration';

// Creates a complete customised configuration object
function getConfig(resources = {}) {
  if (!resources.quoteFeed && resources.quoteFeed !== null) resources.quoteFeed = quoteFeed;
  return defaultConfig(resources);
}

// Creates a complete customised configuration object
function getCustomConfig({ onChartReady, resources } = any) {
  const config = getConfig(resources);
  config.chartId = '_core-chart';
  config.initialSymbol = {
    symbol: 'AAPL',
    name: 'Apple Inc',
    exchDisp: 'NASDAQ',
  };
  config.menuPeriodicity = [
    {
      type: 'item',
      label: '1 D',
      cmd: "Layout.setPeriodicity(1,1,'day')",
      value: { period: 1, interval: 1, timeUnit: 'day' },
    },
    {
      type: 'item',
      label: '1 W',
      cmd: "Layout.setPeriodicity(1,1,'week')",
      value: { period: 1, interval: 1, timeUnit: 'week' },
    },
    {
      type: 'item',
      label: '1 Mo',
      cmd: "Layout.setPeriodicity(1,1,'month')",
      value: { period: 1, interval: 1, timeUnit: 'month' },
    },
  ];
  config.themes.defaultTheme = 'ciq-day';
  config.quoteFeeds = null;
  config.onChartReady = onChartReady;
  // Disable crossSection plugins by default
  // It can throw errors if when loaded with time series charts.
  config.plugins.crossSection = null;
  //To Disable the Keyboard shortcuts
  config.hotkeyConfig.hotkeys = [];
  config.enabledAddOns.shortcuts = resources.enableCiqPreferences;

  return config;
}

export { getConfig, getCustomConfig };
