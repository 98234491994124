import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

export const useMinWidthBreakpoint = () => {
  //@1366 this will activate.
  const ismin = useMediaQuery('(min-width:1367px)');
  return ismin;
};

export const useTwoPanelLayoutBreakpoint = () => {
  return useMediaQuery('(max-width:991px)');
};
