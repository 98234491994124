import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ThemeType, { getThemeType } from '@op/shared/src/models/theme-type';
import { themeState, userSettingState } from '@op/shared/src/states';
import { useRecoilState, useSetRecoilState } from 'recoil';

import React from 'react';
import { cloneDeep } from '@op/shared/src/models';

export const ThemeSettingWidget: React.FC = () => {
  const themes = [
    {
      theme: ThemeType.LIGHT,
      name: 'Light',
    },
    {
      theme: ThemeType.MLDARK,
      name: 'Dark',
    },
  ];
  const [setting, setSetting] = useRecoilState(userSettingState);
  const setTheme = useSetRecoilState(themeState);

  if (!setting) {
    return <></>;
  }

  const onChange = (value: string): void => {
    const theme = getThemeType(value);
    const clone = cloneDeep(setting);
    clone.theme = theme;
    setSetting(clone);
    setTheme(theme);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="activeTheme"
        value={setting.theme}
        onChange={(_, value) => onChange(value)}
        sx={{ flex: 1, flexDirection: 'row' }}>
        {themes.map((t) => (
          <FormControlLabel
            key={t.theme}
            labelPlacement="end"
            value={t.theme}
            control={<Radio color="primary" />}
            label={t.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
