import { Grid, Tabs } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { StyledTab } from '../styled';
import { TSTabTypes } from './states/trade-station-states';
import { tsOrderPlacementState } from './states/ts-ticket-states';

interface TradeStationProps {
  onChangeCallBack: (index: string) => void;
  onClickCallBack: (index: string) => void;
  indexVal: TSTabTypes;
}
export const TSTabsWidget: React.FC<TradeStationProps> = ({
  onChangeCallBack,
  onClickCallBack,
  indexVal,
}: TradeStationProps) => {
  const tsOrder = useRecoilValue(tsOrderPlacementState);

  const handleTabChange = (event, newValue) => {
    onChangeCallBack(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs value={indexVal} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <StyledTab
            id="positions"
            value={'positions'}
            label="Positions"
            onClick={() => onClickCallBack('positions')}
            isselected={(indexVal === 'positions').toString()}
          />
          <StyledTab
            id="orders"
            value={'orders'}
            label="Orders"
            onClick={() => onClickCallBack('orders')}
            isselected={(indexVal === 'orders').toString()}
          />
          <StyledTab
            id="history"
            value={'history'}
            label="History"
            onClick={() => onClickCallBack('history')}
            isselected={(indexVal === 'history').toString()}
          />
          <StyledTab
            id="account_summary"
            value={'accountSummary'}
            label="Account Summary"
            onClick={() => onClickCallBack('accountSummary')}
            isselected={(indexVal === 'accountSummary').toString()}
          />
          {tsOrder && (
            <StyledTab
              id="order_ticket"
              value={'orderTicket'}
              label="Order Ticket"
              onClick={() => onClickCallBack('orderTicket')}
              isselected={(indexVal === 'orderTicket').toString()}
            />
          )}
        </Tabs>
      </Grid>
    </Grid>
  );
};
