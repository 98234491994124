import { IPortfolioPositionAccount } from './portfolio-position-account-interface';

export class PortfolioPositionAccount implements IPortfolioPositionAccount {
  institution = '';
  branch = '';
  accountIdentifier = '';
  accountType = '';
  accountName = '';
  portfolioAccountId = -1;
  tradeType = '';

  public static fromData = (data: any) => {
    const account = new PortfolioPositionAccount();
    account.institution = data.institution;
    account.branch = data.branch;
    account.accountIdentifier = data.accountIdentifier;
    account.accountType = data.accountType;
    account.accountName = data.accountName;
    account.portfolioAccountId = data.portfolioAccountId;
    account.tradeType = data.tradeType;
    return account;
  };
}
