import { Box, Button, Grid, Typography } from '@mui/material';
import { EditCommission } from '@op/shared/src/models/account/commission-request';
import { ProfileDetailsService } from '@op/shared/src/services';
import { CommissionState } from '@op/shared/src/states/commission-state';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { ProfileCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayLebal, OptionsPlayTextField } from '../styled/options-play-components';

export const Commissions: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const [fields, setFields] = useRecoilState(CommissionState);
  const [, setErrors] = React.useState<string[]>([]);
  const { t } = React.useContext(LocalizationContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const fetchCommissions = async (): Promise<void> => {
    try {
      const response = await ProfileDetailsService.instance.Commission();

      if (!response.hasErrors) {
        setFields(response.data || new EditCommission());
      } else {
        console.error('useCommissions: error fetching data: ', response.getErrors);
        setErrors(response.getErrors);
      }
    } catch (error) {
      console.error('useCommissions: error fetching data: ', error);
      //setErrors([error]);
    }
  };
  useEffect(() => {
    fetchCommissions();
  }, []);
  const updateCommission = async (): Promise<void> => {
    if (fields.stockTradeCommission <= 0) {
      setNotifications([{ type: 'error', content: 'stockTradeCommission is Required' }]);
    } else if (fields.perOptionCommission <= 0) {
      setNotifications([{ type: 'error', content: 'perOptionCommission is Required' }]);
    } else if (fields.perShareCommission <= 0) {
      setNotifications([{ type: 'error', content: 'perShareCommission  is Required' }]);
    } else {
      try {
        const editResponse = await ProfileDetailsService.instance.UpdateCommission(fields);
        if (editResponse.data) {
          setNotifications([{ type: 'success', content: 'Commission Updated Successfully' }]);
        }
      } catch (error) {
        setNotifications([{ type: 'error', content: 'Commission Update Failed' }]);
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('profile.commissions.labels.stockTradeCommission')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              id="stockTradeCommission"
              name="stockTradeCommission"
              onChange={handleChange}
              value={fields.stockTradeCommission}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('profile.commissions.labels.perOption')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              id="perOptionCommission"
              name="perOptionCommission"
              onChange={handleChange}
              value={fields.perOptionCommission}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('profile.commissions.labels.perShare')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              id="perShareCommission"
              name="perShareCommission"
              onChange={handleChange}
              value={fields.perShareCommission}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button id="pw-update" onClick={updateCommission} color="primary" variant="contained">
            <Typography variant="button">{t('common.buttons.update')}</Typography>
          </Button>
        </Box>
      </>
    );
  };

  return <ProfileCardWidget title={t('profile.commissions.titles.commissions')} content={renderContent()} />;
};
