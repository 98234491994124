import { Grid } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { OrderTicketWidget } from './order-ticket/order-ticket-widget';
import { TSTabTypes, tsDockState } from './states/trade-station-states';
import { TSHistoryWidget } from './ts-history-widget';
import { TSOrdersWidget } from './ts-orders-widget';
import { TSPositionsWidget } from './ts-positions-widget';
import { TSAccountSummaryWidget } from './ts-account-summary-widget';

interface TradePanelWidgetProps {
  selectedValue: TSTabTypes;
}

export const TSTabPanelWidget: React.FC<TradePanelWidgetProps> = ({ selectedValue }: TradePanelWidgetProps) => {
  const isDockOpen = useRecoilValue(tsDockState);

  /**
   * Below condition is required as Tab panel loads on default.
   * Triggering the panel useeffects only once the dock state is open.
   */
  if (!isDockOpen) {
    return;
  }

  return (
    <Grid>
      {selectedValue === 'positions' && <TSPositionsWidget />}
      {selectedValue === 'orders' && <TSOrdersWidget />}
      {selectedValue === 'history' && <TSHistoryWidget />}
      {selectedValue === 'accountSummary' && <TSAccountSummaryWidget />}
      {selectedValue === 'orderTicket' && <OrderTicketWidget />}
    </Grid>
  );
};
