import { IChangePasswordResponseProps } from './';

export class ChangePasswordResponse implements IChangePasswordResponseProps {
  errorCode = '';
  formattedMessage = '';
  isSuccess = true;

  public static fromJson = (data: ChangePasswordResponse): ChangePasswordResponse => {
    const model = new ChangePasswordResponse();
    model.errorCode = data.errorCode;
    model.formattedMessage = data.formattedMessage;
    model.isSuccess = data.isSuccess;
    return model;
  };
}
