import { Grid, InputAdornment, useTheme } from '@mui/material';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconComponent } from '../common';
import { MenuProps, OPSelectDropDownHeader, OPSelectField, StyledDropdownItemWithBorder } from '../styled';
import { tsAccountIdsSelectorState, tsAccountsDataState, tsSelectedAccountState } from './states/trade-station-states';
import { ITradeStationAccount } from './models';
import lodash from 'lodash';

interface Props {
  isOrderTicket?: boolean;
}

export const TSAccountWidget: React.FC<Props> = ({ isOrderTicket: orderTicket }: Props) => {
  const theme = useTheme();
  const selectedAccount = useRecoilValue(tsSelectedAccountState);
  const tsAccountData = useRecoilValue(tsAccountsDataState);
  const [ids, setIds] = useRecoilState(tsAccountIdsSelectorState);

  const getSelectFieldValue = () => {
    if (!tsAccountData || !selectedAccount || selectedAccount.length === 0) {
      return 'No Account Found';
    }
    if (ids.length === 1) {
      return selectedAccount[0].accountID;
    }
    if (selectedAccount.length === ids.length) {
      return 'All';
    }
    return selectedAccount[0].accountID;
  };

  const formatAccountName = (account: ITradeStationAccount) => {
    const str = account.accountID.slice(-4);
    const maskNum = lodash.padStart(str, 8, '*');
    return `${account.accountType} ${maskNum}`;
  };

  if (!tsAccountData) {
    return <></>;
  }

  return (
    <Grid container>
      <OPSelectField
        variant="outlined"
        value={getSelectFieldValue()}
        renderValue={getSelectFieldValue() === 'All' && orderTicket ? () => 'Select Account' : undefined}
        onChange={(e) => {
          if ((e.target.value as string) === 'All') {
            setIds(tsAccountData.map((a) => a.accountID));
            return;
          }
          setIds([e.target.value as string]);
        }}
        sx={{
          width: '100%',
          height: orderTicket ? 24 : 35,
          '& .MuiSvgIcon-root': {
            color: theme.palette.selectAndTextField.main,
          },
          '& .MuiInputBase-input': {
            paddingX: orderTicket ? '8px' : '0px',
          },
          color:
            selectedAccount && selectedAccount.length > 1 && orderTicket
              ? theme.palette.text.disabled
              : theme.palette.selectAndTextField.main,
        }}
        startAdornment={
          !orderTicket && (
            <InputAdornment position="start">
              <IconComponent
                name="userIcon"
                stroke={theme.palette.info.light}
                style={{ cursor: 'pointer' }}
                size={20}
              />
            </InputAdornment>
          )
        }
        MenuProps={MenuProps}>
        <OPSelectDropDownHeader type="tsAccount" />

        {!tsAccountData || !selectedAccount ? (
          <StyledDropdownItemWithBorder disabled color="default" value="No Account Found">
            No Account Found
          </StyledDropdownItemWithBorder>
        ) : orderTicket ? null : (
          tsAccountData.length > 1 && (
            <StyledDropdownItemWithBorder color="default" value="All">
              All
            </StyledDropdownItemWithBorder>
          )
        )}

        {tsAccountData?.map((item) => {
          return (
            <StyledDropdownItemWithBorder color="default" key={item.accountID} value={item.accountID}>
              {orderTicket ? item.accountID : formatAccountName(item)}
            </StyledDropdownItemWithBorder>
          );
        })}
      </OPSelectField>
    </Grid>
  );
};
