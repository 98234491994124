import { IOptionChain } from '.';
import ApplicationContext from './application-context';
import DateTimeHelper from './date-time-helper';
import formatting from './formatting';
import helpers from './helpers';
import NumberFormatHelper from './number-format-helper';

// details of the call and put
export class OptionChainRow {
  ulSymbol: string = '';
  strike: number = 0;
  strikePrice: number = 0;
  expiry: Date | undefined;
  expiryDate: Date | undefined;
  optionType: string = 'S';
  useForwardPrice = false;
  callBid = 0;
  callAsk = 0;
  callVolume = 0;
  callOpenInt = 0;
  callPremiumMult: number; //getSecurityQuantity(optionType);
  getSecurityQuantity: number; // getSecurityQuantity(optionType);
  callDelta: number = 0;
  callGamma: number = 0;
  callTheta: number = 0;
  callVega = 0;
  callImpliedVolatility = 0;
  callMid = 0;
  callShortCode = '';

  putSymbol = '';
  putShortCode = '';
  putBid = 0;
  putAsk = 0;
  putVolume = 0;
  putOpenInt = 0;
  putPremiumMult: number; //getSecurityQuantity(optionType);
  putDelta: number = 0;
  putGamma: number = 0;
  putTheta: number = 0;
  putVega: number = 0;
  putImpliedVolatility = 0;
  putMid = 0;

  constructor(ulSymbol: string, strikePrice: number, expiryDate: Date | undefined, optionType: string, id: number) {
    this.ulSymbol = ulSymbol;
    this.strike = strikePrice;
    this.strikePrice = strikePrice;
    this.expiry = expiryDate;
    this.expiryDate = expiryDate;
    this.optionType = optionType;
    //this.id = id;
    this.useForwardPrice = false;
    this.callPremiumMult = helpers.getSecurityQuantity(optionType);
    this.putPremiumMult = helpers.getSecurityQuantity(optionType);
    this.getSecurityQuantity = helpers.getSecurityQuantity(optionType);
    this.initialize();
  }

  private initialize = () => {
    if (
      ApplicationContext.configuration &&
      ApplicationContext.configuration.indicesForOptionsFuture &&
      ApplicationContext.configuration.indicesForOptionsFuture.indexOf(this.ulSymbol) > -1
    ) {
      this.useForwardPrice = true;
    }
  };

  // this needs to come from the server
  // which should set this data based on
  // whether an index should use a forward adjusted price
  forwardPrice = () => {
    return this.callMid + this.strike - this.putMid;
  };
}

export class OptionChain {
  rows: OptionChainRow[] = []; //ko.observableArray([]);
  ulSymbol: string = '';
  last: number = 0;
  rowsMap = new Map<string, OptionChainRow>();
  expiryList: Date[] = [];
  strikesByExpiry = new Map<string, number[]>();
  miniExpiryList: Date[] = [];
  miniStrikesByExpiry = new Map<string, number[]>();
  zeroPremium = false; //ko.observable(false);
  //language = ko.observable(localizer.locale()).extend({ notify: 'always' });
  hasMini = false; //ko.observable(false);
  closestStrike = 0; //ko.observable(0);
  // localizer.locale.subscribe(function (language) {
  //   this.language(language);
  //   this.computeExpiryStrike();
  //   this.closestStrike.valueHasMutated();
  // });
  private constructor() {}

  static fromData = (ulSymbol: string, optionChainData: IOptionChain[], last: number) => {
    const model = new OptionChain();
    model.readOptionChain(ulSymbol, optionChainData, last);
    return model;
  };

  static fromSelf = (self: OptionChain) => {
    const chain = new OptionChain();
    chain.rows = self.rows;
    chain.ulSymbol = self.ulSymbol;
    chain.last = self.last;
    chain.rowsMap = self.rowsMap;
    chain.expiryList = self.expiryList;
    chain.strikesByExpiry = self.strikesByExpiry;
    chain.miniExpiryList = self.miniExpiryList;
    chain.miniStrikesByExpiry = self.miniStrikesByExpiry;
    chain.zeroPremium = self.zeroPremium;
    chain.hasMini = self.hasMini;
    chain.closestStrike = self.closestStrike;
    return chain;
  };

  // This does not required here.
  static getSecurityQuantity = (type?: string) => {
    return helpers.getSecurityQuantity(type);
  };

  expirySelectOptions = (furthestExpiration?: Date) => {
    const expiryOptions: { dateOnSelector: string; date: Date; isGreaterThanMaxExpiry: boolean }[] = [];
    for (let i = 0; i < this.expiryList.length; i++) {
      const date = this.expiryList[i];
      let dateOnSelector = DateTimeHelper.format(date);
      const days = DateTimeHelper.daysFromNow(date);
      dateOnSelector += ' (' + days + ')';
      const checkWeeklyExpiry = DateTimeHelper.isWeekly(date);
      if (checkWeeklyExpiry) {
        dateOnSelector = dateOnSelector + ' (W)';
      }
      const isGreaterThanMaxExpiry = !furthestExpiration && DateTimeHelper.sameDate(date, furthestExpiration);
      expiryOptions.push({ dateOnSelector, date, isGreaterThanMaxExpiry });
    }
    return expiryOptions;
  };
  // take out today if market is closed today.

  computeExpiryStrike = () => {
    let cheapestCall = Number.MAX_VALUE;
    for (let item of this.rows) {
      let row = item;
      let expiry = row.expiryDate;
      let expiryKey = DateTimeHelper.toExpiryKey(row.expiryDate) as string;
      let strike = row.strikePrice;
      let type = row.optionType;
      //TODO: Secrity type does not ahve expiry. May be that check is better to throw error than just warning.
      if (!expiry) {
        console.warn('returning from expiry');
        continue;
      }
      if (type === 'M') {
        this.hasMini = true;
        const isExisting = this.miniStrikesByExpiry.has(expiryKey);
        if (!isExisting) {
          this.miniExpiryList.push(expiry);
          this.miniStrikesByExpiry.set(expiryKey, [strike]);
        } else {
          let strikes = this.miniStrikesByExpiry.get(expiryKey) as number[];
          strikes.push(strike);
        }
      } else {
        const isExisting = this.strikesByExpiry.has(expiryKey);
        if (!isExisting) {
          this.expiryList.push(expiry);
          this.strikesByExpiry.set(expiryKey, [strike]);
        } else {
          let strikes = this.strikesByExpiry.get(expiryKey) as number[];
          strikes.push(strike);
        }
      }
      if (row.callBid < cheapestCall) {
        this.closestStrike = strike;
      }
    }
  };

  findRow = (idOrStrike: string, expiryDate: Date | undefined, type: string | undefined) => {
    let id;
    let strike: string = '';
    let result: OptionChainRow | undefined;
    let price: number = 0;
    type = type || 'S';
    // return new OptionChainRow('', 0, new Date(), 'S', 0);
    if (!expiryDate && !type) {
      id = idOrStrike;
      result = this.rowsMap.get(id.substring(id.indexOf('|') + 1));
    } else {
      strike = idOrStrike;
      let expiryStr = DateTimeHelper.toExpiryKey(expiryDate);
      if (helpers.isString(strike)) {
        price = parseFloat(strike);
      }
      let strikePrice = this.findStrike(price, expiryDate as Date, 0, type);
      result = this.rowsMap.get(expiryStr + '|' + strikePrice + '|' + type);
    }
    return result;
  };

  findStrike = (
    price: number | undefined,
    date: Date | undefined,
    level?: number,
    type?: string,
    strikes?: number[],
  ) => {
    if (!strikes) {
      let expiry = this.findExpiry(date, 0, 0, type);
      strikes = this.getStrikeListForExpiry(expiry, type);
    }
    if (!strikes) {
      throw new Error('strikes are undefined');
    }
    if (!price) {
      //throw new Error('price are undefined');
      price = -1;
    }

    let i = 0;
    for (; i < strikes.length; i++) {
      if (strikes[i] >= price) {
        break;
      }
    }
    level = level ? level : 0;
    if (i + level < 0) {
      return strikes[0];
    } else if (i + level >= strikes.length) {
      return strikes[strikes.length - 1];
    }
    return strikes[i + level];
  };

  findExpiry = (date: Date | undefined, level: number | undefined | null, distance?: number, type?: string) => {
    distance = distance || 0;
    level = level || 0;
    date = date || formatting.getCurrentDate();
    let exList = this.getFullExpiryList(type);
    if (!exList || exList.length === 0) {
      return date;
    }
    let i = 0;
    for (; i < exList.length; i++) {
      if (exList[i].valueOf() - date.valueOf() > (distance - 1) * 24 * 3600000) {
        break;
      }
    }
    let levelIndex = i + level;
    // If index is out of array bounds
    if (levelIndex < 0) {
      levelIndex = 0;
    }
    if (levelIndex > exList.length) {
      levelIndex = exList.length - 1;
    }
    return exList[levelIndex];
  };

  getStrikeListForExpiry = (expiry: Date | undefined, type?: string) => {
    type = type || 'S';
    let formattedExpiry: string | undefined = '';
    if (helpers.isDate(expiry)) {
      formattedExpiry = DateTimeHelper.toExpiryKey(expiry) || '';
    }
    return type === 'M' ? this.miniStrikesByExpiry.get(formattedExpiry) : this.strikesByExpiry.get(formattedExpiry);
  };

  getFullExpiryList = (type: string | undefined) => {
    type = type || 'S';
    return type === 'M' ? this.miniExpiryList : this.expiryList;
  };

  impliedVolatility = (date: Date | undefined, optionSizeType?: string | undefined) => {
    date = this.findExpiry(date, 0, 0, optionSizeType);
    let k0 = this.findStrike(this.last, date, -1, optionSizeType),
      k1 = this.findStrike(this.last, date, 1, optionSizeType);
    let row0 = this.findRow(k0.toString(), date, optionSizeType),
      row1 = this.findRow(k1.toString(), date, optionSizeType);
    if (!row0 || !row1) {
      return 0;
      // throw new Error('Row is undefined');
    }
    var vol = ((row0.putImpliedVolatility + row1.callImpliedVolatility) / 2) * 100;
    return NumberFormatHelper.roundNumber(vol);
  };

  private readOptionChain = (ulSymbol: string, optionChainData: IOptionChain[], last: number) => {
    if (!ulSymbol || !optionChainData || !last) {
      return undefined;
    }

    this.ulSymbol = ulSymbol;
    this.last = last;
    this.hasMini = false;
    this.rowsMap = new Map();
    this.rows = [];
    let optionCount = 0;
    let zeroPremiumCount = 0;

    // loop through all the chains and processes them
    for (let i = 0; i < optionChainData.length; i++) {
      let currentRow = optionChainData[i];
      let strike = parseFloat(currentRow.strikePrice);
      let expiry = DateTimeHelper.resolveDate(currentRow.expiry);
      let expiryStr = currentRow.expiry;
      if (!expiry) {
        console.warn('current row does not have expiry: ', currentRow);
        continue;
      }
      let key = `${expiryStr}|${strike}|${currentRow.optionType}`;
      let row = this.rowsMap.get(key);
      if (currentRow.callOption && currentRow.putOption && !row) {
        //TODO: OptionChaniRow required id. I guess it just UI numbering
        row = new OptionChainRow(ulSymbol, strike, expiry, currentRow.optionType, i);
        this.rows.push(row);
        this.rowsMap.set(key, row);
      }

      // is this a call option? Then we load the call side of the option
      if (row && currentRow.callOption) {
        row.callBid = currentRow.callOption.bid;
        row.callAsk = currentRow.callOption.ask;
        row.callVolume = currentRow.callOption.volume;
        row.callOpenInt = currentRow.callOption.openInterest;
        // row.callSymbol = currentRow.callOption.occSymbol;
        row.callPremiumMult = currentRow.callOption.premiumMultiplier;
        row.callDelta = currentRow.callOption.delta;
        row.callGamma = currentRow.callOption.gamma;
        row.callTheta = currentRow.callOption.theta;
        row.callVega = currentRow.callOption.vega;
        row.callImpliedVolatility = currentRow.callOption.impliedVolatility;
        row.callShortCode = currentRow.callOption.shortCode;
        row.callMid = (row.callBid + row.callAsk) / 2;
        row.useForwardPrice = true; //currentRow.callOption.useForwardPrice;
        if (!row.callBid && !row.callAsk) {
          zeroPremiumCount++;
        }
        optionCount += 1;
      }

      // id this a put option? Then we load the put side of the option
      if (row && currentRow.putOption) {
        row.putBid = currentRow.putOption.bid;
        row.putAsk = currentRow.putOption.ask;
        row.putVolume = currentRow.putOption.volume;
        row.putOpenInt = currentRow.putOption.openInterest;
        row.putSymbol = currentRow.putOption.occSymbol;
        row.putPremiumMult = currentRow.putOption.premiumMultiplier;
        row.putDelta = currentRow.putOption.delta;
        row.putGamma = currentRow.putOption.gamma;
        row.putTheta = currentRow.putOption.theta;
        row.putVega = currentRow.putOption.vega;
        row.putImpliedVolatility = currentRow.putOption.impliedVolatility;
        row.putShortCode = currentRow.putOption.shortCode;
        row.putMid = (row.putBid + row.putAsk) / 2;
        row.useForwardPrice = true; //currentRow.callOption.useForwardPrice;
        if (!row.putBid && !row.putAsk) {
          zeroPremiumCount++;
        }
        optionCount += 1;
      }
    }
    this.zeroPremium = zeroPremiumCount / optionCount > 0.8;
    this.computeExpiryStrike();
    return self;
  };
}
