import { previousLocationState } from '@op/shared/src/states';
import React, { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

//THIS IS THE CUSTOM HOOK USED TO SAVE PREVIOUS LOCATION

export const usePreviousLocation = <T>(value: T): T => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  const setPreviousLocation = useSetRecoilState(previousLocationState);
  // Store current value in ref
  useEffect(() => {
    if ((ref.current as Location)?.pathname === (value as unknown as Location)?.pathname) {
      return;
    }
    setPreviousLocation((ref.current as Location)?.pathname);
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
