import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';

export interface IOptionStrike {
  spreadType: string;
  strikes: Array<string[]>;
}

export interface IOptionExpiry {
  expirations: IExpiration[];
}

export interface IExpiration {
  date: string;
  type: IExpiryType;
}

// interface to contain strikeList by expiry
export interface IOptionExpiryStrike {
  expiry: string;
  strikeList: string[];
}

export enum IExpiryType {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Quarterly = 'Quarterly',
  EOM = 'EOM',
  Other = 'Other',
}

export const formatExpiryList = (expiryList: IExpiration[]): IExpiration[] => {
  if (!expiryList) {
    return;
  }
  const expList: IExpiration[] = expiryList.map((e) => {
    return {
      date: DateTimeHelper.format(e.date),
      type: e.type,
    };
  });
  return expList;
};

export const getPositionExpiration = (expirations: IExpiration[], positionExpiry: Date) => {
  return expirations.find((e) =>
    DateTimeHelper.sameDate(DateTimeHelper.resolveDate(e.date), DateTimeHelper.resolveDate(positionExpiry)),
  );
};
