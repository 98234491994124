import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { ChangeEmailResponse, EditChangeEmail } from '@op/shared/src/models/account/';
import { ProfileDetailsService } from '@op/shared/src/services';
import { accessTokenKey, accountState } from '@op/shared/src/states';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { ResponseViewModel } from '@op/shared/src/view-models/responses/response-viewmodel';
import React, { useState } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import LocalizationContext from '../react-i18next/localization-context';
import { useOPNavigate } from '../states/use-navigate-search';
import { OptionsPlayDivider, OptionsPlayLebal, OptionsPlayTextField } from '../styled/options-play-components';

export const getErrorMsg = (editResponse: ResponseViewModel<ChangeEmailResponse | null>) => {
  if (editResponse.errors.length > 0) {
    return `${editResponse.errors[editResponse.errors.length - 1].message.toString()}`;
  } else {
    throw new Error('No Error Message');
  }
};

export const ChangeEmail: React.FC = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const [fields, setFields] = useState(new EditChangeEmail());
  const { t } = React.useContext(LocalizationContext);
  const [account, setAccount] = useRecoilState(accountState);
  const navigate = useOPNavigate();
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const initialState: EditChangeEmail = {
    oldEmailAddress: '',
    newEmailAddress: '',
    password: '',
  };

  const onLogout = async (): Promise<void> => {
    setAccount(undefined);
    sessionStorage.removeItem('lightbox_state');
    localStorage.removeItem(accessTokenKey);
    navigate('/login');
  };

  const resetForm = (): void => {
    setFields({ ...initialState });
  };

  const changeEmail = async (): Promise<void> => {
    if (!fields.oldEmailAddress) {
      setNotifications([{ type: 'error', content: 'Old Email is Required' }]);
    } else if (!fields.newEmailAddress) {
      setNotifications([{ type: 'error', content: 'NewEmail is Required' }]);
    } else if (!fields.password) {
      setNotifications([{ type: 'error', content: 'Password  is Required' }]);
    } else if (!pattern.test(fields.oldEmailAddress)) {
      setNotifications([{ type: 'error', content: 'Invalid email format' }]);
    } else if (!pattern.test(fields.newEmailAddress)) {
      setNotifications([{ type: 'error', content: 'Invalid email format' }]);
    } else if (fields.oldEmailAddress === fields.newEmailAddress) {
      setNotifications([{ type: 'error', content: 'New Email Address must be not equal to Old Email Address' }]);
    } else {
      try {
        const editResponse = await ProfileDetailsService.instance.ChangeEmail(fields);
        if (editResponse?.data?.isSuccess === true) {
          setNotifications([{ type: 'success', content: 'Verification Email Sent' }]);
          const newEmail = fields.newEmailAddress;
          localStorage.setItem('newEmail', newEmail);
          onLogout();
        } else {
          setNotifications([{ type: 'error', content: getErrorMsg(editResponse) }]);
        }
      } catch (error) {
        setNotifications([{ type: 'error', content: 'Email Reset Failed' }]);
      }
    }
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h6">{t('profile.changeEmailAddress.titles.changeYourEmailAddress')}</Typography>}
        sx={{ pb: 0 }}
      />
      <OptionsPlayDivider />
      <CardContent>
        <Grid container spacing={2}>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('profile.changeEmailAddress.labels.currentEmailAddress')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              // label={t('profile.changeEmailAddress.labels.currentEmailAddress')}
              id="oldEmailAddress"
              name="oldEmailAddress"
              onChange={handleChange}
              value={fields.oldEmailAddress}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('profile.changeEmailAddress.labels.newEmailAddress')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              // label={t('profile.changeEmailAddress.labels.newEmailAddress')}
              id="newEmailAddress"
              data-newEmail={fields.newEmailAddress}
              name="newEmailAddress"
              onChange={handleChange}
              value={fields.newEmailAddress}
              variant="outlined"
              size="small"
            />
          </Grid>
          <OptionsPlayLebal item xs={3}>
            <Typography>{t('profile.changeEmailAddress.labels.password')}</Typography>
          </OptionsPlayLebal>
          <Grid item xs={9}>
            <OptionsPlayTextField
              fullWidth
              // label={t('profile.changeEmailAddress.labels.password')}
              id="password"
              name="password"
              type="password"
              onChange={handleChange}
              value={fields.password}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </CardContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button id="pw-update" color="primary" onClick={changeEmail} variant="contained">
          <Typography variant="button">{t('profile.changeEmailAddress.buttons.change')}</Typography>
        </Button>
        &nbsp;
        <Button onClick={resetForm} color="primary" id="pw-reset" variant="contained">
          <Typography variant="button">{t('profile.changeEmailAddress.buttons.resetForm')}</Typography>
        </Button>
      </Box>
    </Card>
  );
};
