import { selectedSymbolState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';
import { Grid, Typography } from '@mui/material';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { TrendingWidget } from '../../common';
import { OPBoldTypograpghy } from '../../styled';
import { OPTradeIdeaStyledHoverCard } from '../op-styled-card';
import { ICoveredCallsProps } from '../pager';
import { FirstRow } from '../row-first';
import { ForthRow } from '../row-forth';
import { SecondRow } from '../row-second';

interface Props {
  data: ICoveredCallsProps;
  onItemSelected: (symbol: string, hash: string) => void;
}
export const CoveredCall: React.FC<Props> = ({ data, onItemSelected }: Props) => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const idea = data;
  const isSelected = selectedSymbol?.toUpperCase() === idea.symbol.toUpperCase();

  return (
    <OPTradeIdeaStyledHoverCard selected={isSelected} container alignItems={'center'}>
      <Grid item xs={12}>
        <TrendingWidget symbol={idea.symbol}>
          <Grid
            container
            onClick={() => {
              const hashIndex = idea.url.indexOf('HASH=');
              const hash = idea.url.substring(hashIndex + 5);
              onItemSelected(idea.symbol, hash);
            }}>
            <Grid item xs={12} container justifyContent="space-between" pr={1}>
              <FirstRow data={idea} />
            </Grid>
            <Grid item xs={12} justifyContent="space-between" pr={1}>
              <SecondRow data={idea} />
            </Grid>
            <Grid item container xs={12} justifyContent="space-between" pr={1}>
              <Grid item xs={4}>
                <Typography variant="caption" pr={0.5}>
                  Strike
                </Typography>
                <OPBoldTypograpghy variant="caption">
                  {NumberFormatHelper.toCurrency((data as ICoveredCallsProps).strike)}
                </OPBoldTypograpghy>
              </Grid>
              <Grid item xs={4} pl={1.5}>
                <Typography variant="caption" pr={0.5}>
                  APY
                </Typography>
                <OPBoldTypograpghy variant="caption">{idea.annualizedReturn}</OPBoldTypograpghy>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="caption" pr={0.5}>
                  Earnings
                </Typography>
                <OPBoldTypograpghy variant="caption">{idea.hasEarnings}</OPBoldTypograpghy>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="space-between" alignItems="center">
              <ForthRow data={idea} />
            </Grid>
          </Grid>
        </TrendingWidget>
      </Grid>
    </OPTradeIdeaStyledHoverCard>
  );
};
