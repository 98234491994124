import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { ResetModel } from '@op/shared/src/models';
import { ProfileDetailsService } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';

const textField = { width: 380 };

export const ResetWidget: React.FC<{}> = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const [values, setValues] = useState(new ResetModel());
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  var url_string = window.location.href;
  var url = new URL(url_string);
  var hash = url.searchParams.get('hash');
  var salt = url.searchParams.get('salt');
  values.hash = hash != null ? hash : '';
  values.salt = salt != null ? salt : '';
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme<Theme>();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const onHomepage = async (): Promise<void> => {
    navigate('/login', { replace: true });
  };
  const onSubmit = async (): Promise<void> => {
    const temp = { password: '', confirmPassword: '' };
    if (!values.password) {
      temp.password = 'New Password is required';
      setErrors({ ...temp });
    } else if (values.password.length < 6) {
      temp.password = 'Password needs to be 6 characters or more';
      setErrors({ ...temp });
    } else if (!values.confirmPassword) {
      temp.confirmPassword = 'Confirm New Password  is required';
      setErrors({ ...temp });
    } else if (values.confirmPassword !== values.password) {
      temp.confirmPassword = 'Confirm Password do not match';
      setErrors({ ...temp });
    } else {
      const response = await ProfileDetailsService.instance.ResetPassword(values);
      if (!response) {
        throw new Error('TradingStrategiesHow is undefined');
      }
      try {
        if (response.isSuccess === true) {
          setNotifications([{ type: 'success', content: 'User password has been updated' }]);
          onHomepage();
        }
      } catch (error) {
        setNotifications([{ type: 'error', content: 'Reset Password Field' }]);
      }
    }
  };
  return (
    <>
      <Grid
        direction="column"
        alignItems="center"
        container
        justifyContent="center"
        style={{ flex: 1, minHeight: '88vh' }}>
        <Grid item>
          <Paper>
            <Container style={{ padding: theme.spacing(5, 7), textAlign: 'center', maxWidth: '500px' }} maxWidth="xs">
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <img alt="logo" src="https://app.optionsplay.com/Content/images/OptionsPlay/logo-big-blue.png" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Enter your New Password here</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={handleChange}
                      value={values.password}
                      type="password"
                      name="password"
                      label="New Password"
                      id="New Password"
                      variant="outlined"
                      style={textField}
                      error={errors.password}
                      helperText={<span>{errors.password}</span>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      onChange={handleChange}
                      value={values.confirmPassword}
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      id="Confirm Password"
                      variant="outlined"
                      style={textField}
                      helperText={<span>{errors.confirmPassword}</span>}
                      error={errors.confirmPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      style={{ width: 380, height: 60 }}
                      color="primary"
                      type="submit"
                      onClick={onSubmit}>
                      <Typography variant="button">{t('password.reset.buttons.submit')}</Typography>
                      {loader && (
                        <CircularProgress
                          style={{ color: '#fff', display: 'flex', justifyContent: 'center', textAlign: 'center' }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
