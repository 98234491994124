import { selectedSymbolState } from '@op/shared/src/states';
import { useRecoilValue } from 'recoil';
import { Grid } from '@mui/material';
import React from 'react';
import { TrendingWidget } from '../../common';
import { IdeaMoreDetails } from '../idea-more-details';
import { OPTradeIdeaStyledHoverCard } from '../op-styled-card';
import { IRowProps } from '../pager';
import { FirstRow } from '../row-first';
import { ForthRow } from '../row-forth';
import { SecondRow } from '../row-second';

interface Props {
  data: IRowProps;
  onItemSelected: (symbol: string) => void;
}
export const TechnicalIdea: React.FC<Props> = ({ data, onItemSelected }: Props) => {
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const isSelected = selectedSymbol?.toUpperCase() === data.symbol.toUpperCase();
  return (
    <OPTradeIdeaStyledHoverCard
      selected={isSelected}
      container
      alignItems={'center'}
      sx={{
        width: '100%',
        background: (theme) => {
          return isSelected ? theme.palette.selection.light : theme.palette.symbolCard.default;
        },
      }}>
      <Grid item xs={12}>
        <TrendingWidget symbol={data.symbol} sentiment={data.sentiment}>
          <Grid container onClick={() => onItemSelected(data.symbol)}>
            <Grid item xs={12} container justifyContent="space-between" pr={1}>
              <FirstRow data={data} />
            </Grid>
            <Grid item xs={12} justifyContent="space-between" pr={1}>
              <SecondRow data={data} isTechnicalIdea={true} />
            </Grid>
            <Grid item xs={12} justifyContent="space-between" alignItems="center">
              <ForthRow data={data} />
            </Grid>
          </Grid>
        </TrendingWidget>
      </Grid>
      <Grid item xs={12}>
        <IdeaMoreDetails data={data} signals={(data as IRowProps).signals} />
      </Grid>
    </OPTradeIdeaStyledHoverCard>
  );
};
