import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import {
  IPortfolioAccount,
  IPortfoliouploadData,
  PortfolioSaveTradePosition,
  PortfolioUploadedDataList,
  PortfoliouploadData,
} from '@op/shared/src/models';
import { AccountTypeStatus, EventType, PortfolioType } from '@op/shared/src/models/enums/enums';
import {
  accountState,
  configurationState,
  portfolioSaveTradeAccountsState,
  portfoliosDataState,
  selectedSymbolState,
  tradesCachedState,
  watchListsCachedState,
} from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MenuProps, OPBoldTypograpghy, OPSelectField, StyledDropdownItemWithBorder } from '../styled';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import TDAmeritradeIcon from '@op/shared/assets/images/TDAmeritrade.png';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { PortfolioPositionsService } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { coveredCallDataCachedState } from '@op/shared/src/states/reports/covered-call-states';
import { creditSpreadDataCachedState } from '@op/shared/src/states/reports/credit-spread-states';
import { shortPutDataCachedState } from '@op/shared/src/states/reports/short-put-states';
import { debounce } from 'lodash';
import { SaveTradeDialogs } from '.';
import { OPDialogWidget, ProfileCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { SaveAccountsDialog } from './portfolio-save-accounts-dialogbox';

export const IndeterminateCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const SkeletonLoading: React.FC = () => {
  return (
    <>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell padding="none">Type</TableCell>
            <TableCell padding="none">Name</TableCell>
            <TableCell padding="none">Enabled</TableCell>
            <TableCell padding="none">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[0, 1, 2, 3].map((row) => (
            <TableRow key={row}>
              <TableCell size="small" component="th" sx={{ width: '10%' }}>
                <Skeleton animation="wave" variant="circular" width={30} height={30} />
              </TableCell>
              <TableCell padding="none" sx={{ width: '40%' }}>
                <Skeleton animation="wave" height={30} width="80%" />
              </TableCell>
              <TableCell padding="none" sx={{ width: '10%' }}>
                <Skeleton animation="wave" variant="circular" width={30} height={30} />
              </TableCell>
              <TableCell padding="none" sx={{ width: '40%' }}>
                <Skeleton variant="rectangular" width={80} height={20} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export interface IPortfolioTabWidgetProps {
  isAdmin: boolean;
  hasManualPortfolioUpoadAccess: boolean;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const PortfolioTabWidget: React.FC<IPortfolioTabWidgetProps> = (props: IPortfolioTabWidgetProps) => {
  let arrayformat: any[] = [];
  const [porfolioSaveTradeAccounts, setPortfolioSaveTradeAccounts] = useRecoilState(portfolioSaveTradeAccountsState);
  const [filename, setFilename] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState<any | null>(null);
  const [selectedPortfolioPositions, setselectedPortfolioPositions] = React.useState<
    PortfolioSaveTradePosition | undefined
  >(new PortfolioSaveTradePosition());
  const [validationError, setValidationError] = React.useState(arrayformat);
  const [loading, setLoading] = React.useState(false);
  const [globalloading, setGlobalLoading] = React.useState(false);
  const [skeletonLoading, setSkeletonLoading] = React.useState(false);
  const [editData, setEditData] = useState(false);
  const [isNewPortfolio, setIsNewPortfolio] = useState<boolean>(false);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme();
  const setNotifications = useSetRecoilState(notificationsState);
  const resetPortfoliosData = useResetRecoilState(portfoliosDataState);
  const resetWatchlists = useResetRecoilState(watchListsCachedState);
  const resetTradeIdeas = useResetRecoilState(tradesCachedState);
  const resetCreditSpreads = useResetRecoilState(creditSpreadDataCachedState);
  const resetCoveredCalls = useResetRecoilState(coveredCallDataCachedState);
  const resetShortPuts = useResetRecoilState(shortPutDataCachedState);
  const location: any = useLocation();
  const [accountsLoading, setAccountsLoading] = React.useState(false);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editAccounts, setEditAccounts] = useState(false);
  const [accountData, setAccountData] = React.useState<IPortfolioAccount[]>([]);
  const [selectedBroker, setSelectedBroker] = React.useState('');
  const configuration = useRecoilValue(configurationState);
  const [account] = useRecoilState(accountState);

  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [portfolioIndex, setPortfolioIndex] = useState(0);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [isYesClicked, setIsYesClicked] = useState(false);

  const fetchPortfolioList = async () => {
    setSkeletonLoading(true);
    const portfolioUploadedList = await PortfolioPositionsService.instance.InvestmentPositions();
    if (portfolioUploadedList.hasErrors) {
      setSkeletonLoading(false);
      return;
    }
    if (portfolioUploadedList.data) {
      setPortfolioSaveTradeAccounts(portfolioUploadedList.data.data);
      setSkeletonLoading(false);
    }
  };

  const clearSyncState = () => {
    if (searchParams.has('code')) searchParams.delete('code');
    if (searchParams.has('error_description')) searchParams.delete('error_description');
    if (searchParams.has('error')) searchParams.delete('error');
    setSearchParams(searchParams);
  };

  const getAccountsData = async (code: string = '') => {
    setAccountsLoading(true);
    try {
      const accResponse = await PortfolioPositionsService.instance.getAccounts(code);
      if (accResponse.hasErrors || !accResponse.data) {
        setNotifications([{ type: 'error', content: 'An error occured' }]);
        return;
      }
      setAccountData(accResponse.data!);
      setEditAccounts(true);
    } catch (error) {
      setNotifications([{ type: 'error', content: 'An error occured' }]);
    } finally {
      setAccountsLoading(false);
      clearSyncState();
    }
  };

  const ameritradeAuthenticate = async () => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // protocol and domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // OR ip (v4) address,  port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
      'i',
    ); // query string
    const redirectUrl = `${window.location.origin}/profile`;
    const tdAuthURL = `${configuration?.ameritradeAuthUrl}?client_id=${
      configuration?.ameritradeConsumerKey
    }%40AMER.OAUTHAP&response_type=code&redirect_uri=${encodeURIComponent(redirectUrl)}`;
    if (!tdAuthURL || !pattern.test(tdAuthURL)) return;
    //expired or doesnot exist
    setSelectedBroker('');
    window.open(tdAuthURL);
  };

  const syncAmeritradeData = async (code: string = '') => {
    try {
      const response = await PortfolioPositionsService.instance.getAccountStatus();
      if (response.hasErrors || !response.data) {
        return;
      }
      const tdStatus = response.data;
      if (code !== '' || tdStatus === AccountTypeStatus.Active) {
        getAccountsData(code);
      } else if (tdStatus === AccountTypeStatus.Inactive || tdStatus === AccountTypeStatus.Pending) {
        ameritradeAuthenticate();
      } else {
        return;
      }
    } catch (error) {
      setNotifications([{ type: 'error', content: 'An error occured' }]);
    }
  };

  const debouncedsyncAmeritradeData = debounce(syncAmeritradeData, 1000);

  useEffect(() => {
    if (searchParams.has('error_description')) searchParams.delete('error_description');
    if (searchParams.has('error')) searchParams.delete('error');
    setSearchParams(searchParams);
    const code = searchParams.get('code');
    if (code) {
      const decodedCode = encodeURIComponent(code);
      setSelectedBroker('TD');
      syncAmeritradeData(decodedCode);
    }
  }, []);

  useEffect(() => {
    fetchPortfolioList();
  }, []);

  useEffect(() => {
    if (!porfolioSaveTradeAccounts) {
      return;
    }
    if (location.state && typeof location.state == 'object') {
      const data = location.state.data;
      fileEdit(data);
    }
  }, [porfolioSaveTradeAccounts, location]);

  if (!account || !account.securityModel) {
    return <></>;
  }

  const isAdmin = props.isAdmin;
  const hasManualPortfolioUpoadAccess = props.hasManualPortfolioUpoadAccess;

  if (!porfolioSaveTradeAccounts) {
    return null;
  }

  const fileEdit = async (selectedPortfolio: PortfoliouploadData) => {
    setIsNewPortfolio(false);
    setDisableEdit(selectedPortfolio.portfolioTypeStatus === PortfolioType.TDAmeritrade);
    setselectedPortfolioPositions(new PortfolioSaveTradePosition());
    const response = await PortfolioPositionsService.instance.editPortfolio(selectedPortfolio);
    if (response.hasErrors || !response.data) {
      return undefined;
    }
    let selectedPortfolioData: PortfolioSaveTradePosition = {
      id: selectedPortfolio.id,
      name: selectedPortfolio.name,
      portfolioPositions: response.data,
    };
    setEditData(true);
    const PortfolioPositions = PortfolioSaveTradePosition.fromData(selectedPortfolioData);
    if (!PortfolioPositions) {
      return;
    }
    setselectedPortfolioPositions(PortfolioPositions);
    location.state = '';
  };

  const handleCreatePosition = () => {
    setIsNewPortfolio(true);
    setselectedPortfolioPositions(new PortfolioSaveTradePosition());
    setEditData(true);
  };

  const clearState = () => {
    setSelectedFile(null);
    setFilename('');
  };

  const handleselectFile = (event: React.FormEvent<HTMLInputElement>) => {
    const filevalue = event.currentTarget.files;
    if (!filevalue) {
      return null;
    }
    const name = filevalue?.[0].name;
    const lastDot = name.lastIndexOf('.');
    const ext = name.substring(lastDot + 1);
    if (ext !== 'csv') {
      setNotifications([{ type: 'error', content: 'Accept only CSV Files...!' }]);
    } else {
      setSelectedFile(filevalue?.[0]);
      setFilename(filevalue?.[0].name);
    }
  };

  const uploadFiles = async () => {
    let formData = new FormData();
    formData.append('files', selectedFile);
    setLoading(true);
    try {
      const response = await PortfolioPositionsService.instance.Upload(formData);
      if (response?.isFileSaved === true) {
        const Fileresponse = await PortfolioPositionsService.instance.InvestmentPositions();
        if (Fileresponse.hasErrors || !Fileresponse || !Fileresponse.data) {
          return;
        }
        setPortfolioSaveTradeAccounts(Fileresponse.data.data);
        setValidationError(response.validationErrors);
        setNotifications([{ type: 'success', content: 'Your portfolio is now ready' }]);
        resetPortfoliosData();
        resetTradeIdeas();
        resetCreditSpreads();
        resetCoveredCalls();
        resetShortPuts();
        resetWatchlists();
      } else {
        setNotifications([
          { type: 'error', content: 'Your portfolio was not uploaded because no valid records were found' },
        ]);
      }
    } catch (error) {
      setNotifications([{ type: 'error', content: 'An error occured' }]);
    } finally {
      setLoading(false);
      clearState();
    }
  };

  const uploadFilesGlobal = async () => {
    let formData = new FormData();
    formData.append('file', selectedFile);
    setGlobalLoading(true);
    try {
      const response = await PortfolioPositionsService.instance.UploadGlobal(formData);
      if (response?.isFileSaved === true) {
        const Fileresponse = await PortfolioPositionsService.instance.InvestmentPositions();
        // setUploadedPortfolioList(investement.data || new PortfolioUploadedDataList());
        if (Fileresponse.hasErrors || !Fileresponse || !Fileresponse.data) {
          return;
        }
        setPortfolioSaveTradeAccounts(Fileresponse.data.data);
        setValidationError(response.validationErrors);
        setGlobalLoading(false);
        setNotifications([{ type: 'success', content: 'Your portfolio is now ready' }]);
        resetPortfoliosData();
        resetTradeIdeas();
        resetCreditSpreads();
        resetCoveredCalls();
        resetShortPuts();
        resetWatchlists();
      } else {
        setNotifications([
          { type: 'error', content: 'Your portfolio was not uploaded because no valid records were found' },
        ]);
      }
    } catch (error) {
      setNotifications([{ type: 'error', content: 'Invalid file' }]);
    } finally {
      setLoading(false);
      clearState();
    }
  };

  const handleClose = () => {
    setIsYesClicked(false);
    setRemoveDialog(false);
    setPortfolioIndex(0);
  };

  const logActivity = (value: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      'deletePortfolio',
      'deletePortfolio',
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const fileRemove = async (row: IPortfoliouploadData) => {
    const data = row;
    const response = await PortfolioPositionsService.instance.DeletePortfolio(data);
    if (response?.data?.isSuccess) {
      const Fileresponse = await PortfolioPositionsService.instance.InvestmentPositions();
      if (Fileresponse.hasErrors || !Fileresponse || !Fileresponse.data) {
        return;
      }
      const logValue = {
        portfolioId: data.id,
        portfolioName: data.name,
        isGlobal: data.isGlobal,
        userId: account.securityModel.userID,
      };
      logActivity(logValue);
      // setUploadedPortfolioList(investement.data || new PortfolioUploadedDataList());
      setPortfolioSaveTradeAccounts(Fileresponse.data.data);
      setNotifications([{ type: 'success', content: 'File Removed Successfully' }]);
      resetPortfoliosData();
      resetTradeIdeas();
      resetCreditSpreads();
      resetCoveredCalls();
      resetShortPuts();
      resetWatchlists();
      handleClose();
    } else {
      setIsYesClicked(false);
      return null;
    }
  };

  const changeState = async (event: React.ChangeEvent<HTMLInputElement>, row: IPortfoliouploadData) => {
    const value = event.target.checked;
    let data = {
      id: row.id,
      name: row.name,
      isGlobal: row.isGlobal,
      enabled: value,
      portfolioTypeStatus: row.portfolioTypeStatus,
    };
    const response = await PortfolioPositionsService.instance.ChangeState(data);
    if (response.hasErrors) {
      return;
    }
    const Fileresponse = await PortfolioPositionsService.instance.InvestmentPositions();
    if (Fileresponse.hasErrors || !Fileresponse || !Fileresponse.data) {
      return;
    }
    // setUploadedPortfolioList(investement.data || new PortfolioUploadedDataList());
    setPortfolioSaveTradeAccounts(Fileresponse.data.data);
    setNotifications([{ type: 'success', content: 'Changed State Successfully' }]);
    resetPortfoliosData();
    resetTradeIdeas();
    resetCreditSpreads();
    resetCoveredCalls();
    resetShortPuts();
    resetWatchlists();
  };

  const renderRemoveDialog = (portfolioList: PortfoliouploadData) => {
    if (!removeDialog) {
      return;
    }

    const renderDialogTitle = () => {
      return (
        <Grid container>
          <OPBoldTypograpghy style={{ marginLeft: 8, fontSize: '16px', color: '#fff' }}>
            {t('profile.testPortfolio.titles.deletePortfolio')}
          </OPBoldTypograpghy>
        </Grid>
      );
    };

    const renderDialogAction = () => {
      return (
        <Grid container spacing={1} marginBottom={2}>
          <Grid item xs={6} textAlign={'center'}>
            <Button variant="text" onClick={handleClose}>
              <OPBoldTypograpghy sx={{ color: '#666666' }}>{t('common.buttons.cancel')}</OPBoldTypograpghy>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={isYesClicked}
              variant="contained"
              size="large"
              sx={{
                backgroundColor: theme.palette.error.main,
                ':hover': {
                  backgroundColor: theme.palette.error.main,
                },
              }}
              onClick={() => {
                setIsYesClicked(true);
                fileRemove(portfolioList);
              }}>
              <OPBoldTypograpghy>{t('common.buttons.yes')}</OPBoldTypograpghy>
            </Button>
          </Grid>
        </Grid>
      );
    };

    const renderDialogContent = () => {
      return (
        <Grid container paddingX={4} marginTop={4}>
          <OPBoldTypograpghy>
            {t('profile.testPortfolio.labels.doYouWantToDeletePortfolio')} {portfolioList.name} ?
          </OPBoldTypograpghy>
        </Grid>
      );
    };

    return (
      <OPDialogWidget
        open={removeDialog}
        onHandleClose={handleClose}
        dialogAction={renderDialogAction()}
        dialogContent={renderDialogContent()}
        title={renderDialogTitle()}
        dialogdividers
        dialogTitleStyle={{
          backgroundColor: theme.palette.error.main,
          color: '#fff',
        }}
      />
    );
  };

  const renderUploadedPortfolioList = (portfolioList: PortfoliouploadData, index: number) => {
    if (!portfolioList) {
      return;
    }
    return (
      <TableRow key={index}>
        <TableCell size="small" component="th" padding="none">
          {portfolioList.isGlobal ? (
            <PublicIcon fontSize="small" />
          ) : portfolioList.portfolioTypeStatus === PortfolioType.TDAmeritrade ? (
            <img
              alt="logo"
              src={TDAmeritradeIcon}
              style={{ width: 25, height: 25, position: 'relative', top: 3 }}></img>
          ) : (
            <PersonIcon fontSize="small" />
          )}
        </TableCell>
        <TableCell padding="none">{portfolioList.name}</TableCell>
        <TableCell padding="none">
          <Checkbox
            size="small"
            checked={portfolioList.enabled}
            onChange={(event) => changeState(event, portfolioList)}
          />
        </TableCell>
        <TableCell padding="none">
          <Button
            onClick={() => {
              setRemoveDialog(true);
              setPortfolioIndex(index);
            }}>
            <Typography variant="button">{t('profile.testPortfolio.js.removeButton')}</Typography>
          </Button>
        </TableCell>
        <TableCell padding="none">
          {portfolioList.portfolioTypeStatus === 'UserUpload' || portfolioList.portfolioTypeStatus === 'Global' ? (
            <Button onClick={() => fileEdit(portfolioList)}>
              <Typography variant="button">{t('profile.testPortfolio.js.editButton')}</Typography>
            </Button>
          ) : portfolioList.portfolioTypeStatus === PortfolioType.TDAmeritrade ? (
            <Button onClick={() => fileEdit(portfolioList)}>
              <Typography variant="button">{'View'}</Typography>
            </Button>
          ) : (
            ''
          )}
        </TableCell>
      </TableRow>
    );
  };

  const onCloseDialog = (value: any) => {
    if (isNewPortfolio) {
      fetchPortfolioList();
    }
    let modifiedPortfolioList: PortfoliouploadData[] = porfolioSaveTradeAccounts.map(
      (porfolioSaveTradeAccount, index) => {
        const clonedPortfolioUploadData = PortfoliouploadData.fromSelf(porfolioSaveTradeAccount);
        if (value && clonedPortfolioUploadData.id === value.id && value.name) {
          clonedPortfolioUploadData.name = value.name;
        }
        return clonedPortfolioUploadData;
      },
    );
    let newObj: PortfolioUploadedDataList = {
      data: modifiedPortfolioList,
      recordsFiltered: modifiedPortfolioList.length,
      recordsTotal: modifiedPortfolioList.length,
    };
    // setUploadedPortfolioList(newObj);
    setPortfolioSaveTradeAccounts(newObj.data);
  };

  const renderSelectButtonOnPermission = () => {
    if (isAdmin || hasManualPortfolioUpoadAccess) {
      return (
        <div>
          <input onChange={handleselectFile} accept=".csv,text/csv" hidden id="contained-button-file" type="file" />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              <Typography variant="button">{t('profile.testPortfolio.buttons.select')}</Typography>
            </Button>
          </label>
          {filename}
        </div>
      );
    } else {
      return;
    }
  };

  const renderUploadButtonOnPermission = () => {
    if (isAdmin || hasManualPortfolioUpoadAccess) {
      return (
        <Button
          startIcon={<CloudUploadIcon fontSize="small" />}
          disabled={selectedFile == null}
          variant="contained"
          color="primary"
          component="span"
          onClick={uploadFiles}>
          <Typography variant="button">{t('profile.testPortfolio.buttons.upload')}</Typography>&ensp;
          {loading && <IndeterminateCircularProgress size={25} />}
        </Button>
      );
    } else {
      return;
    }
  };

  const renderUploadGlobalButton = () => {
    if (!isAdmin) {
      return;
    }
    return (
      <Button
        startIcon={<CloudUploadIcon fontSize="small" />}
        disabled={selectedFile == null}
        variant="contained"
        color="secondary"
        component="span"
        onClick={uploadFilesGlobal}>
        <Typography variant="button">{t('profile.testPortfolio.buttons.uploadGlobal')}</Typography>
        {globalloading && <IndeterminateCircularProgress size={25} />}
      </Button>
    );
  };

  const renderErrorCard = () => {
    if (validationError.length <= 0) {
      return;
    }
    return (
      <Card
        sx={{
          p: '15px',
          borderLeft: `5px solid ${theme.palette.error.main}`,
          borderRadius: theme.shape.borderRadius,
        }}>
        <Typography color="secondary" variant="h6">
          Your portfolio was uploaded with a few exceptions
        </Typography>
        <List>
          {validationError.map((values, index) => {
            return (
              <ListItem key={index}>
                <Typography variant="body1">
                  Row # <strong>{values.rowNumber}</strong>
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="body1">
                  Symbol: <strong>{values.symbol}</strong>
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="body1">
                  Issue: <strong>{values.message}</strong>
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Card>
    );
  };

  const renderTableContent = () => {
    if (skeletonLoading) {
      return <SkeletonLoading />;
    }
    if (porfolioSaveTradeAccounts.length === 0) {
      return (
        <Typography variant="body1" sx={{ p: 5, textAlign: 'center' }}>
          No data available
        </Typography>
      );
    }
    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell padding="none">{t('profile.testPortfolio.js.typeLabel')}</TableCell>
            <TableCell padding="none">{t('profile.testPortfolio.js.nameLabel')}</TableCell>
            <TableCell padding="none">{t('profile.testPortfolio.js.enabledLabel')}</TableCell>
            <TableCell padding="none">{t('profile.testPortfolio.js.actionLabel')}</TableCell>
            <TableCell padding="none">{t('profile.testPortfolio.js.actionLabel')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {porfolioSaveTradeAccounts.map((portfolioList, index) => renderUploadedPortfolioList(portfolioList, index))}
        </TableBody>
      </Table>
    );
  };

  const onSelectBroker = (e: any) => {
    setSelectedBroker(e.target.value);
  };

  const renderAmeriTradeButtonOnPermission = () => {
    if (account.isAllowAmeritadeAccountLinking && selectedBroker === 'TD') {
      return (
        <Button variant="contained" color="primary" id="syncAmeritrade" onClick={() => debouncedsyncAmeritradeData()}>
          <Typography variant="button">{t('Sync')}</Typography>
          {accountsLoading && <IndeterminateCircularProgress size={25} />}
        </Button>
      );
    } else {
      return;
    }
  };

  const renderBrokerSelectOnPermission = () => {
    if (!account.isAllowAmeritadeAccountLinking) {
      return;
    }
    return (
      <OPSelectField
        value={selectedBroker}
        variant="outlined"
        onChange={(e: any) => onSelectBroker(e)}
        style={{ height: 34, width: 175 }}
        displayEmpty
        id="broker-select"
        MenuProps={MenuProps}>
        <StyledDropdownItemWithBorder color="default" key={-1} value={''}>
          <Grid container direction="row">
            <Grid item xs={12} sm={10} md={10} lg={10} sx={{ pt: '2px' }}>
              <Typography variant="inherit" style={{ paddingLeft: 13 }}>
                {'Select Broker Account'}
              </Typography>
            </Grid>
          </Grid>
        </StyledDropdownItemWithBorder>
        <StyledDropdownItemWithBorder color="default" key={'TD'} value={'TD'}>
          <Grid container direction="row">
            <Grid item xs={12} sm={10} md={10} lg={10} sx={{ pt: '2px' }}>
              <Typography variant="inherit" style={{ paddingLeft: 13, marginTop: -5 }}>
                <img
                  alt="logo"
                  src={TDAmeritradeIcon}
                  style={{ width: 18, height: 18, position: 'relative', top: 4, marginRight: 5 }}></img>
                {'TD Ameritrade'}
              </Typography>
            </Grid>
          </Grid>
        </StyledDropdownItemWithBorder>
      </OPSelectField>
    );
  };

  const onCloseAccountsDialog = () => {
    clearSyncState();
    setSelectedBroker('');
    setEditAccounts(false);
  };

  const onUpdateAccounts = async (accounts: string[]) => {
    setAccountsLoading(true);
    try {
      const response = await PortfolioPositionsService.instance.saveAccountsInfo(accounts);
      if (!response.hasErrors && response.data) {
        const Fileresponse = await PortfolioPositionsService.instance.InvestmentPositions();
        if (Fileresponse.hasErrors || !Fileresponse || !Fileresponse.data) {
          return;
        }
        setEditAccounts(false);
        setPortfolioSaveTradeAccounts(Fileresponse.data.data);
        setNotifications([{ type: 'success', content: 'Ameritrade Data Synced Successfully' }]);
        resetPortfoliosData();
        resetTradeIdeas();
        resetCreditSpreads();
        resetCoveredCalls();
        resetShortPuts();
        resetWatchlists();
      } else {
        setNotifications([{ type: 'error', content: 'An error occured' }]);
        return null;
      }
    } catch (error) {
      setNotifications([{ type: 'error', content: 'An error occured' }]);
    } finally {
      setAccountsLoading(false);
      setSelectedBroker('');
      clearSyncState();
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={2} md={2}>
            {renderSelectButtonOnPermission()}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            {renderBrokerSelectOnPermission()}
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            {renderAmeriTradeButtonOnPermission()}
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            {renderUploadButtonOnPermission()}
          </Grid>
          <Grid item container justifyContent="flex-end" xs={12} sm={3} md={3}>
            {renderUploadGlobalButton()}
          </Grid>
        </Grid>
        <div>{renderErrorCard()}</div>
        <TableContainer sx={{ pt: 3 }}>{renderTableContent()}</TableContainer>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button id="createNewPortfolio" color="primary" variant="contained" onClick={() => handleCreatePosition()}>
            <Typography variant="button">{t('profile.testPortfolio.buttons.CreateNewPortfolio')}</Typography>
          </Button>
        </Box>

        <div>
          <React.Suspense fallback={<div>Loading</div>}>
            {removeDialog && renderRemoveDialog(porfolioSaveTradeAccounts[portfolioIndex])}
          </React.Suspense>
        </div>

        <div>
          <React.Suspense fallback={<div>Loading</div>}>
            {editData && (
              <SaveTradeDialogs
                selectedPortfolioPositions={selectedPortfolioPositions}
                setEditPortfolioData={(v) => setEditData(v)}
                isNewPortfolio={isNewPortfolio}
                disableEdit={disableEdit}
                onCloseDialog={(v) => onCloseDialog(v)}
              />
            )}
          </React.Suspense>
        </div>
        <div>
          <React.Suspense fallback={<div>Loading</div>}>
            {editAccounts && (
              <SaveAccountsDialog
                selectedAccountData={accountData!}
                onUpdateAccounts={(v) => onUpdateAccounts(v)}
                onCloseAccountsDialog={() => onCloseAccountsDialog()}
              />
            )}
          </React.Suspense>
        </div>
      </>
    );
  };

  return <ProfileCardWidget title={t('profile.testPortfolio.titles.portfolio')} content={renderContent()} />;
};
