import { isHubInitiatedState, portfolioSelectedAccountIdsSelectorState } from '@op/shared/src/states';
import {
  fetchSentiments,
  sentimentAtomFamily,
  sentimentsUpdater,
  subscribeToSentiments,
  unsubscribeFromSentiments,
} from '@op/shared/src/states/sentiment-hub-states';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/*
 * The hook will fetch the sentiemnt from singalR Hub if already not present in the recoil and udpate store.
 * @param symbol
 */
export const useHubSentiment = (symbol: string) => {
  const hubSentiment = useRecoilValue(sentimentAtomFamily(symbol));
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  React.useEffect(() => {
    if (!isHubInitialized || !symbol) {
      return;
    }
    (async () => {
      if (!hubSentiment) {
        await fetchSentiment();
      }
      await subscribeToSentiments([symbol]);
    })();
    //Clearing function
    return () => {
      (async () => {
        await unsubscribeFromSentiments([symbol]); //TODO: unsubscribe in cleanup
      })();
    };
  }, [isHubInitialized, symbol]);

  const fetchSentiment = async () => {
    const fetchedSentiments = await fetchSentiments([symbol]);
    if (!fetchedSentiments || fetchedSentiments.length === 0) {
      return;
    }
    setSentiments(fetchedSentiments);
  };
};

/*
 * The hook will fetch the sentiments from singalR Hub if already not present in the recoil and udpate store.
 * @param symbol
 */
export const useHubSentiments = (symbols: string[]) => {
  //TODO: Performance: use this to get all symbols which are alredy available.
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  const portfolioSelectedAccountIds = useRecoilValue(portfolioSelectedAccountIdsSelectorState);
  React.useEffect(() => {
    if (!isHubInitialized || symbols.length === 0) {
      return;
    }
    (async () => {
      await fetchedSentiments();
    })();
  }, [isHubInitialized, portfolioSelectedAccountIds]);

  const fetchedSentiments = async () => {
    const fetchedSentiments = await fetchSentiments(symbols);

    if (!fetchedSentiments || fetchedSentiments.length === 0) {
      return;
    }
    setSentiments(fetchedSentiments);
  };
};
