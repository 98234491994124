import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Grid, IconButton, Menu, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { OPBoldTypograpghy } from '../styled';
import { switchToDemo, switchToLive } from './services';
import { tsEnvironmentState } from './states/trade-station-states';
import { TSEnvSelectionWidget } from './ts-env-selection-widget';
import { TsLogoutWidget } from './ts-logout-widget';
import ApplicationContext from '@op/shared/src/models/how/application-context';

export const TSMenuWidget: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openEnv, setOpenEnv] = useState(false);
  const [tsEnv, setTsEnv] = useRecoilState(tsEnvironmentState);
  const setNotifications = useSetRecoilState(notificationsState);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = () => {
    if (tsEnv === 'none') {
      return;
    }
    setAnchorEl(null);
    switchTSAccount();
    // setTsEnv(tsEnv === 'live' ? 'sim' : tsEnv === 'sim' ? 'live' : 'none');
  };

  const switchTSAccount = async () => {
    if (tsEnv === 'sim') {
      const response = await switchToLive();
      if (response.hasErrors) {
        return;
      }
      setNotifications([
        {
          type: 'success',
          content: 'Switched to Live account successfully.',
          // icon: <IconComponent name="tsLogo" />,
          isTradeStation: true,
        },
      ]);
      ApplicationContext.tradeStationToken = undefined;
      ApplicationContext.tradeStationBaseURL = undefined;
      setTsEnv('live');
    }
    if (tsEnv === 'live') {
      const response = await switchToDemo();
      if (response.hasErrors) {
        return;
      }
      setNotifications([
        {
          type: 'success',
          content: 'Switched to Simulated account successfully.',
          // icon: <IconComponent name="tsLogo" />,
          isTradeStation: true,
        },
      ]);
      ApplicationContext.tradeStationToken = undefined;
      ApplicationContext.tradeStationBaseURL = undefined;
      setTsEnv('sim');
    }
  };

  const renderMoreIcon = () => {
    return (
      <Grid container>
        <Grid item xs={12} textAlign={'right'}>
          <IconButton onClick={handleClick}>
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={opened}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: {
                padding: 0,
                borderRadius: 1,
                border: `2px solid ${theme.palette.secondary.dark}`,
              },
            }}>
            <Grid container spacing={0.3}>
              <Grid item xs={12}>
                <Button
                  sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    height: 40,
                    ':hover': {
                      backgroundColor: theme.palette.error.main,
                    },
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() => setOpen(!open)}>
                  <OPBoldTypograpghy>Log Out</OPBoldTypograpghy>
                </Button>
              </Grid>
              <Grid item xs={12} alignItems="center">
                <Button
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    height: 40,
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenEnv(!openEnv)}>
                  <OPBoldTypograpghy>Switch to {tsEnv === 'sim' ? 'Live' : 'Simulated'}</OPBoldTypograpghy>
                  <IconComponent
                    name="switchIcon"
                    stroke="white"
                    style={{
                      alignItems: 'center',
                      paddingLeft: '4px',
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Menu>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Grid item xs={12}>
        {renderMoreIcon()}
      </Grid>
      <Grid item xs={12}>
        {open && <TsLogoutWidget open={open} setOpen={setOpen} />}
        {openEnv && (
          <TSEnvSelectionWidget
            open={openEnv}
            setOpen={setOpenEnv}
            changeEnvironment={handleSwitch}
            tsCurrentEnv={tsEnv}
            tsChangeEnvironment
          />
        )}
      </Grid>
    </Grid>
  );
};
