import { PowerhouseProAdditionalPositionDataModel } from '@op/shared/src/hubs/powerhouse-pro-additional-position-data-model';
import { PowerhouseProAlertModel } from '@op/shared/src/hubs/powerhouse-pro-alert-model';
import { PowerhouseProHub } from '@op/shared/src/hubs/powerhouse-pro-hub';
import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { ExpandedQuote, IExpandedQuote } from '@op/shared/src/models';
import { PhpHubEvents } from '@op/shared/src/models/enums/enums';
import { expandedQuotesUpdater, marketWorkTimeDetailsCachedState } from '@op/shared/src/states';
import { portfolioAlertsState } from '@op/shared/src/states/portfolio-alerts-hub-states';
import { portfolioPositionsDataHubState } from '@op/shared/src/states/portfolio-hub-states';
import { sentimentsUpdater } from '@op/shared/src/states/sentiment-hub-states';
import { useSetRecoilState } from 'recoil';

export const useRegisterHubListeners = () => {
  const setExpandedQuotes = useSetRecoilState(expandedQuotesUpdater);
  const setSentiments = useSetRecoilState(sentimentsUpdater);
  const setPortfolioPositionsData = useSetRecoilState(portfolioPositionsDataHubState);
  const setPortfolioAlert = useSetRecoilState(portfolioAlertsState);
  const setMarketWorkTimeDetails = useSetRecoilState(marketWorkTimeDetailsCachedState);

  const registerHubListeners = (powerhouseProHub: PowerhouseProHub) => {
    if (powerhouseProHub === undefined) {
      throw new Error('Hub is undefined');
    }

    const registerQuotesHubListener = () => {
      // below setInterval - only for simulating signalr updates
      // const quote = ExpandedQuote.fromSample(['PM', 'AAON']);
      // window.setInterval(() => {
      //   setExpandedQuotes(quote);
      // }, 5000);
      if (!powerhouseProHub) {
        return;
      }
      powerhouseProHub.socketConnection.on(PhpHubEvents.QUOTES, {
        name: `quotes-hub-listener`,
        callback: (data: IExpandedQuote[]) => {
          setExpandedQuotes(data);
        },
      });
    };

    const registerSentimentsHubListener = () => {
      if (!powerhouseProHub) {
        return;
      }
      powerhouseProHub.socketConnection.on(PhpHubEvents.SYRAH_SENTIMENT, {
        name: `sentiments-hub-listener`,
        callback: (data: { symbol: string; syrahShortSentiment: number; syrahLongSentiment: number }[]) => {
          const syrahSentiments = data.map((s) => SentimentModel.fromData(s));
          setSentiments(syrahSentiments);
        },
      });
    };

    const registerMarketIndexSentimentHubListener = () => {
      if (!powerhouseProHub) {
        return;
      }
      powerhouseProHub.socketConnection.on(PhpHubEvents.MARKET_INDEX_SENTIMENT, {
        name: `market-index-hub-listener`,
        callback: (data: { symbol: string; syrahShortSentiment: number; syrahLongSentiment: number }) => {
          if (!data) {
            return;
          }
          const syrahSentiment = SentimentModel.fromData(data);
          setSentiments([syrahSentiment]);
        },
      });
    };

    const registerMarketWorkTimeDetailsHubListener = () => {
      if (!powerhouseProHub) {
        return;
      }
      powerhouseProHub.socketConnection.on(PhpHubEvents.MARKET_WORK_TIME, {
        name: `market-work-time-details-hub-listener`,
        callback: (data: any) => {
          if (!data) {
            return;
          }
          setMarketWorkTimeDetails(data);
        },
      });
    };

    const registerPortfolioPositionDataHubListener = () => {
      if (!powerhouseProHub) {
        return;
      }
      powerhouseProHub.socketConnection.on(PhpHubEvents.POSITION_DATA, {
        name: `porfolio-position-data-hub-listener`,
        callback: (data: PowerhouseProAdditionalPositionDataModel[]) => {
          if (!data) {
            return;
          }
          setPortfolioPositionsData(data);
        },
      });
    };

    const registerPortfolioAlertsHubListener = () => {
      // const alert = PowerhouseProAlertModel.fromSample();
      // setInterval(() => {
      //   setPortfolioAlert([alert]);
      // }, 5000);
      if (!powerhouseProHub) {
        return;
      }
      powerhouseProHub.socketConnection.on(PhpHubEvents.PORTFOLIO_ALERTS, {
        name: `porfolio-alerts-hub-listener`,
        callback: (data: PowerhouseProAlertModel[]) => {
          if (!data) {
            return;
          }
          setPortfolioAlert(data);
        },
      });
    };

    registerQuotesHubListener();
    registerSentimentsHubListener();
    registerMarketIndexSentimentHubListener();
    registerPortfolioPositionDataHubListener();
    registerPortfolioAlertsHubListener();
    registerMarketWorkTimeDetailsHubListener();
  };

  return registerHubListeners;
};
