import { Button, Grid, Paper, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { OPDialogWidget } from '../../common';
import { OutlinedButton } from '../../styled';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  goBack: () => void;
  sendOrder: () => void;
}

export const BuyingPowerWarning: React.FC<Props> = ({ open, setOpen, goBack, sendOrder }) => {
  const theme = useTheme();
  const [isSendClicked, setISSendClicked] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    handleClose();
    goBack();
  };

  const renderDialogAction = () => {
    return (
      <Grid container justifyContent={'flex-end'} spacing={2}>
        <Grid item>
          <OutlinedButton variant="contained" size="medium" onClick={onCancel}>
            <Typography sx={{ color: theme.palette.text.primary }}>Cancel</Typography>
          </OutlinedButton>
        </Grid>
        <Grid item>
          <Button
            disabled={isSendClicked}
            variant="contained"
            size="medium"
            onClick={() => {
              setISSendClicked(true);
              sendOrder();
            }}>
            <Typography>Send</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderDialogContent = () => {
    return (
      <Grid item container xs={12} spacing={1} paddingX={2} sx={{ width: '450px' }}>
        <Grid item xs={12}>
          Please review the following information:
        </Grid>
        <Grid item xs={12} container>
          <Paper elevation={2} sx={{ padding: 1, marginBottom: 2, height: 100 }}>
            <Grid item xs={12}>
              This order will exceed your Overnight Buying Power. If this position is held overnight, you may incur a
              margin call.
            </Grid>
            <Grid item xs={12} pt={1}>
              Select Send to place the order or Cancel to edit.
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const renderDialogTitle = () => {
    return (
      <div id="customized-dialog-title">
        <img
          src="/images/trade-station.svg"
          style={{
            width: 150,
            height: 25,
            alignItems: 'center',
          }}
          alt="trade_station_logo"
        />
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          Buying Power Warning
        </Typography>
      </div>
    );
  };

  return (
    <OPDialogWidget
      open={open}
      onHandleClose={handleClose}
      dialogAction={renderDialogAction()}
      dialogContent={renderDialogContent()}
      title={renderDialogTitle()}
    />
  );
};
