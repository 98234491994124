import { Skeleton, Tooltip } from '@mui/material';
import { expandedQuoteAtomFamily } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { OPSemiBoldTypograpghy } from '../styled';

type Props = {
  symbol: string;
  hasQuotes: boolean;
  companyName?: string;
};

export const CompanyTitleWidget: React.FC<Props> = ({ symbol, hasQuotes, companyName }: Props) => {
  const expandedQuote = useRecoilValue(expandedQuoteAtomFamily(symbol));

  /**
   * First check for comapnyName.
   * If no companyName check for expanded Quote
   */

  if (companyName && companyName.trim() !== '') {
    return (
      <Tooltip title={companyName} placement="top-start">
        <OPSemiBoldTypograpghy
          className="js-porfolio-accounts-company-name"
          sx={{
            textTransform: 'capitalize',
            lineHeight: 1,
            maxWidth: { xs: '70%', lg: '75%' },
            display: 'inline-block',
            paddingBottom: '1px',
          }}>
          {companyName}
        </OPSemiBoldTypograpghy>
      </Tooltip>
    );
  }

  if (!expandedQuote && hasQuotes) {
    return (
      <OPSemiBoldTypograpghy
        sx={{
          display: 'inline-block',
        }}>
        <Skeleton animation="wave" variant="rectangular" height={15} width={200} />
      </OPSemiBoldTypograpghy>
    );
  }

  return (
    <Tooltip title={expandedQuote?.name} placement="top-start">
      <OPSemiBoldTypograpghy
        className="js-porfolio-accounts-company-name"
        sx={{
          textTransform: 'capitalize',
          lineHeight: 1,
          maxWidth: { xs: '70%', lg: '75%' },
          display: 'inline-block',
          paddingBottom: '1px',
        }}>
        {expandedQuote?.name || 'Not Supported'}
      </OPSemiBoldTypograpghy>
    </Tooltip>
  );
};
