import { Call, ICall, IPut } from '.';

export interface ICoveredCall {
  call: ICall;
  put: IPut;
  [index: string]: ICall;
}

export class CoveredCall implements ICoveredCall {
  call!: ICall;
  put!: ICall;
  [index: string]: ICall;

  static fromSelf = (self: ICoveredCall) => {
    const clone = new CoveredCall();
    clone.core = self.core;
    clone.call = Call.fromSelf(self.call);
    clone.put = Call.fromSelf(self.put);
    return clone;
  };
}
