import { IExpandedQuote, OptionChain, Position, Predictions, StandardDeviation } from '.';
import { CombinationType, LegType, PriceCalculationMethod } from '../enums/enums';
import ApplicationContext from './application-context';
import Combination, { IOptions } from './combination';
import formatting from './formatting';
import HowDataModel from './how-data-model';
import { Leg } from './leg';
import { Resources } from './resources';

export class IncomeCombination extends Combination {
  coveredCallAlternativeIncomeFoundWarningText =
    "OptionsPlay has not found a {optionType} based on your {defaultTimeFrame} term {defaultAggressiveness} preferences, we're displaying to you a {realTimeFrame} term {realAggressiveness} as the next best alternative.";
  combinationContext: HowDataModel | undefined;
  merrillIncomeCombinationModel: any;
  showCurrencySymbolForNumbers = ApplicationContext.configuration.showCurrencySymbolForNumbers;

  options: IOptions | undefined;
  combinationType = CombinationType.INCOME;
  priority: number | undefined;

  private constructor() {
    super();
  }

  // TODO: Below code is copy pasted from Combination.fromLegs. Directly calling it here not letting typcast to IncomeCombination.
  static fromData = (
    legs: Leg[] | undefined,
    data: { quote: IExpandedQuote; chain: OptionChain; stdDev: StandardDeviation; predictions: Predictions },
    priceCalucationMethod: PriceCalculationMethod,
    priority: number,
  ) => {
    const combination = new IncomeCombination();
    combination.fromData(legs, data, priceCalucationMethod);
    combination.priority = priority;
    //TODO: Refactor: combination context should not be cached
    combination.combinationType = CombinationType.INCOME;
    //TODO:  Search what is the use of this flag.
    combination.isIncomeSpecific = this.getIsCoveredCall(legs);
    combination.initialize(legs);
    return combination;
  };

  static fromSelf = (self: IncomeCombination) => {
    const clone = new IncomeCombination();
    clone.fromSelf(self);
    clone.positions = self.positions.map((p) => Position.fromSelf(p));
    clone.options = self.options;
    clone.predictions = self.predictions;
    clone.stdDev = self.stdDev;
    clone.priceCalculationMethod = self.priceCalculationMethod;
    clone.combinationContext = self.combinationContext;
    clone.isRoll = self.isRoll;
    clone.implementation = self.implementation;
    clone.stdDev = self.stdDev;
    clone.lastHighersChanged = self.lastHighersChanged;
    clone.lastHigherChanged = self.lastHigherChanged;
    clone.combinationType = self.combinationType;
    clone.sentiments = self.sentiments.map((s) => s);
    clone.sentimentProfile = self.sentimentProfile;
    //TODO: remove priority. priority should not be combination props. It is already available in recois `selectedPriorityCombinationState`
    clone.priority = self.priority;
    return clone;
  };

  static getIsCoveredCall = (legs: Leg[] | undefined) => {
    if (!legs) {
      return false;
    }
    if (legs.length > 2) {
      throw new Error('In Income legs will not be more than 2');
    }
    const isCall = legs.find((l) => l.legType === LegType.CALL);
    if (isCall) {
      return true;
    }
    return false;
  };

  private getFormattedUTCDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.getUTCFullYear() + '-' + date.getUTCMonth() + '-' + date.getUTCDate();
    return formattedDate;
  };

  private isCombinationMatchTo = (combinationToComare: any) => {
    const combinationToComareExpiry = combinationToComare && this.getFormattedUTCDate(combinationToComare.expiry);
    const combinationToComareStrike = combinationToComare && combinationToComare.strike;
    const currentExpiry = this.basePosition() && this.getFormattedUTCDate(this.basePosition().expiry());
    const currentStrike = this.basePosition() && this.basePosition().strike;
    const result =
      combinationToComareExpiry &&
      combinationToComareStrike &&
      currentExpiry &&
      currentStrike &&
      currentExpiry === combinationToComareExpiry &&
      currentStrike === combinationToComareStrike;
    return result;
  };

  isDefaultCombination = () => {
    if (!this.options) {
      throw new Error('Options are not defined');
    }
    let result = this.isCombinationMatchTo(this.options.defaultCombination);
    return result;
  };

  private isAlternativeCombination = () => {
    if (!this.options) {
      throw new Error('Options are not defined');
    }
    let result = this.isCombinationMatchTo(this.options.alternativeCombination);
    return result;
  };

  coveredCallAlternativeIncomeFoundWarning = () => {
    if (!this.options || !this.options.alternativeCombination) {
      throw new Error('Options are not defined');
    }
    if (!ApplicationContext.configuration.applicationConfiguration) {
      throw new Error('Application Configuration are not defined');
    }

    if (this.isAlternativeCombination()) {
      let appropriateConfigurations;
      if (this.options.alternativeCombination.legType === LegType.CALL) {
        appropriateConfigurations = ApplicationContext.configuration.applicationConfiguration?.coveredCall.call;
      } else {
        appropriateConfigurations = ApplicationContext.configuration.applicationConfiguration?.coveredCall.put;
      }
      let formatedString = formatting.formatStr(this.coveredCallAlternativeIncomeFoundWarningText, {
        defaultTimeFrame: appropriateConfigurations?.timeFrame,
        realTimeFrame: this.options.alternativeCombination.timeFrame,
        defaultAggressiveness: appropriateConfigurations?.aggressiveness,
        realAggressiveness: this.options.alternativeCombination.aggressiveness,
        optionType: this.options.alternativeCombination.legType,
      });
      return formatedString;
    }
    return null;
  };

  language = () => 'en-US'; //ko.observable(this.localizer.locale()).extend({ notify: 'always' });

  private uncoveredIncomeWarning = () => {
    if (this.isNakedPut()) {
      return Resources.Merrill.uncoveredIncomeWarning;
    }
    return null;
  };

  private basePosition = () => {
    let position: any = this.positions.find((element: any) => {
      return element.expiry && element.expiry();
    });
    return position;
  };
  //TODO:Need to create
  // combinationHeadingTemplate.data = {
  //   text: this.fullNameWithoutSymbol,
  //   uncoveredIncomeWarning: this.uncoveredIncomeWarning,
  //   coveredCallAlternativeIncomeFoundWarning: this.coveredCallAlternativeIncomeFoundWarning,
  //   coveredCallDefaultIncomeFound: this.isDefaultCombination
  // }

  rawReturn = () => {
    const extrinsic = this.extrinsicValue();
    if (extrinsic > 0) {
      if (this.isPut()) {
        const pos = this.extractedPositions[0];
        const strikePrice = pos.strikePrice;
        const premium = pos.price();
        if (!strikePrice) {
          return 0;
        }
        return (extrinsic / (strikePrice - premium)) * 100;
      }
      if (this.isCoveredCall() && this.extractedPositions.length === 2) {
        const pos = this.extractedPositions[1];
        const lastPrice = this.quote.last;
        const premium = pos.price();
        return (extrinsic / (lastPrice - premium)) * 100;
      }
    }
    return 0;
  };

  annualizedReturn = () => {
    // Above rawreturn will be in percentage.
    const rawReturnValue = this.rawReturn() / 100;
    if (rawReturnValue > 0) {
      const DTE = this.daysToExpiry() || 0.1;
      return (Math.pow(1 + rawReturnValue, 365 / DTE) - 1) * 100;
    }
    return 0;
  };

  twelveMonthProjectedDividendYield = () => {
    let divYield = this.quote.yield;
    if (divYield == null) divYield = 0;
    return this.annualizedReturn() + divYield;
  };

  worthlessProb = () => {
    let prob: any = this.predictions.getProb(this.expiration());
    if (this.isCoveredCall()) {
      return prob.getCumulativeProbability(this.strikes()[0]);
    }
    if (this.isNakedPut()) {
      return 1 - prob.getCumulativeProbability(this.strikes()[0]);
    }
    return 0;
  };

  //overwrttien from BasicCombination.ts
  costWithoutOwned = () => {
    // const buyablePositionsWithoutSecurity = this.buyablePositions.filter((p) => p.type !== LegType.SECURITY);
    const buyablePositionsWithoutSecurity = this.positions.filter((position) => {
      return !position.isOwned();
    });
    let result = this.costCalculator((pos) => {
      return pos.price();
    }, buyablePositionsWithoutSecurity);

    return result;
  };
}
