import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Grid, IconButton, Theme, useTheme } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { groupBy as rowGrouper } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Column, RenderSortStatusProps, SortColumn, TreeDataGrid } from 'react-data-grid';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IconComponent } from '../common';
import { OrderSkeleton } from './components/order-skeleton';
import { TsSpan } from './components/ts-styled-components';
import { useFetchandSetOrderStream } from './components/useStream';
import { IOrder } from './models';
import { getOrders } from './services';
import {
  getAllTSOrdersState,
  openStatus,
  tsEnvironmentState,
  tsOrdersDataState,
  tsOrdersUpdater,
  tsRefreshAccountTokenState,
  tsSelectedAccountIdsState,
} from './states/trade-station-states';
import './styles/styles.css';
import { TsCancelOrderWidget } from './ts-cancel-order-widget';
import { TsOOPS } from './ts-oops';
import { TsOrderHeader } from './ts-orders-header';

export interface OrderRow {
  id: number;
  groupTime?: Date;
  orderId: string;
  underlyingSymbol: string;
  symbol: string;
  side: string;
  type: string;
  qty: string;
  filledQty: string;
  limitPrice: string;
  stopPrice: string;
  avgFillPrice: string;
  status: string;
  openTime: Date;
  closeTime: Date;
  routing: string;
  duration: string;
  statusShort?: string;
  commissionFee?: string;
  routeFee?: string;
  openOrClose?: string;
}

const renderColor = (status: string, theme: Theme) => {
  if (status === 'Filled') {
    return theme.palette.success.main;
  }
  if (status === 'Queued') {
    return '#999999';
  }
  if (status === 'Rejected') {
    return theme.palette.error.main;
  }
  return theme.palette.warning.main;
};

const getColumns = (
  theme: Theme,
  isAllGroupExpanded: boolean,
  headerCall: () => void,
  handleCancelConfirm: (orderID) => void,
) => {
  const columns = [
    {
      key: 'groupTime',
      name: '',
      sortable: false,
      editable: false,
      maxWidth: 30,
      renderHeaderCell: () => (
        <div onClick={headerCall} style={{ cursor: 'pointer' }}>
          {isAllGroupExpanded ? (
            <KeyboardDoubleArrowDownIcon style={{ verticalAlign: 'middle', color: '#0078D4' }} />
          ) : (
            <KeyboardDoubleArrowRightIcon
              style={{ verticalAlign: 'middle', color: theme.palette.mode === 'light' ? '#999999' : '#ddd' }}
            />
          )}
        </div>
      ),
      renderGroupCell({ groupKey, row }) {
        return row && row.isExpanded ? (
          <KeyboardDoubleArrowDownIcon style={{ verticalAlign: 'middle', color: '#0078D4' }} />
        ) : (
          <KeyboardDoubleArrowRightIcon
            style={{ verticalAlign: 'middle', color: theme.palette.mode === 'light' ? '#999999' : '#ddd' }}
          />
        );
      },
    },
    {
      key: 'underlyingSymbol',
      name: 'Symbol',
      sortable: false, // TO BE FIXED
      editable: false,
      minWidth: 150,
      headerCellClass: 'symbol-header-cell',
      renderGroupCell({ childRows }) {
        const symbolName = childRows[0].underlyingSymbol;
        return <TsSpan>{symbolName}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{row.symbol}</TsSpan>;
      },
    },
    {
      key: 'side',
      name: 'Side',
      editable: false,
      minWidth: 80,
      // renderGroupCell({ childRows }) {
      //   const side = childRows[0].side;
      //   return <TsSpan color={side === 'Buy' ? theme.palette.success.main : theme.palette.error.main}>{side}</TsSpan>;
      // },
      renderCell({ row }) {
        return (
          <TsSpan color={row.side && row.side === 'Buy' ? theme.palette.success.main : theme.palette.error.main}>
            {row.side ? row.side : 'N/A'}
          </TsSpan>
        );
      },
    },
    {
      key: 'type',
      name: 'Type',
      editable: false,
      minWidth: 100,
      renderGroupCell({ childRows }) {
        const type = childRows[0].type ? childRows[0].type : 'N/A';
        return <TsSpan>{type}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'qty',
      name: 'Qty',
      editable: false,
      // renderGroupCell({ childRows }) {
      //   const quantity = childRows[0].qty;
      //   return <TsSpan>{quantity}</TsSpan>;
      // },
      renderCell({ row }) {
        return <TsSpan>{row.qty ? NumberFormatHelper.roundNumber(row.qty) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'filledQty',
      name: 'Filled Qty',
      editable: false,
      // renderGroupCell({ childRows }) {
      //   const filledQty = childRows[0].filledQty;
      //   return <TsSpan>{filledQty}</TsSpan>;
      // },
      renderCell({ row }) {
        return <TsSpan>{row.filledQty ? NumberFormatHelper.roundNumber(row.filledQty) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'limitPrice',
      name: 'Limit Price',
      editable: false,
      renderGroupCell({ childRows }) {
        const limitPrice = childRows[0].limitPrice;
        const limitOrType = limitPrice ? NumberFormatHelper.roundNumber(limitPrice) : childRows[0].type;
        const value = limitOrType ? limitOrType : 'N/A';
        return <TsSpan>{value}</TsSpan>;
      },
      renderCell({ row }) {
        const limitPrice = row.limitPrice;
        const limitOrType = limitPrice ? NumberFormatHelper.roundNumber(limitPrice) : row.type;
        const value = limitOrType ? limitOrType : 'N/A';
        return <TsSpan>{value}</TsSpan>;
      },
    },
    {
      key: 'stopPrice',
      name: 'Stop Price',
      editable: false,
      renderGroupCell({ childRows }) {
        const stopPrice = childRows[0].stopPrice;
        const stopOrType = stopPrice ? NumberFormatHelper.roundNumber(stopPrice) : childRows[0].type;
        const value = stopOrType ? stopOrType : 'N/A';
        return <TsSpan>{value}</TsSpan>;
      },
      renderCell({ row }) {
        const stopPrice = row.stopPrice;
        const stopOrType = stopPrice ? NumberFormatHelper.roundNumber(stopPrice) : row.type;
        const value = stopOrType ? stopOrType : 'N/A';
        return <TsSpan>{value}</TsSpan>;
      },
    },
    {
      key: 'avgFillPrice',
      name: 'Avg Fill Price',
      editable: false,
      renderGroupCell({ childRows }) {
        const total: number = childRows.reduce((prev: number, { avgFillPrice }: any) => prev + Number(avgFillPrice), 0);
        const avgFilledPrice: any = total / childRows.length;
        return <TsSpan>{avgFilledPrice !== undefined ? NumberFormatHelper.roundNumber(avgFilledPrice) : 'N/A'}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{row.avgFillPrice ? NumberFormatHelper.roundNumber(row.avgFillPrice) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'status',
      name: 'Status',
      minWidth: 100,
      editable: false,
      renderGroupCell({ childRows }) {
        const status = childRows[0].status;
        return <TsSpan color={status && renderColor(status, theme)}>{status ? status : 'N/A'}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'openTime',
      name: 'Open Time',
      editable: false,
      minWidth: 150,
      renderGroupCell({ childRows }) {
        const openTime: Date = childRows[0].openTime;
        return <TsSpan>{openTime ? DateTimeHelper.getLocalTime(openTime) : 'N/A'}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'closeTime',
      name: 'Close Time',
      editable: false,
      minWidth: 150,
      renderGroupCell({ childRows }) {
        const closeTime: Date = childRows[0].closeTime;
        return <TsSpan>{closeTime ? DateTimeHelper.getLocalTime(closeTime) : 'N/A'}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'routing',
      name: 'Routing',
      editable: false,
      minWidth: 100,
      renderGroupCell({ childRows }) {
        const routing = childRows[0].routing;
        return <TsSpan>{routing ? routing : 'N/A'}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'duration',
      name: 'Duration',
      editable: false,
      renderGroupCell({ childRows }) {
        const duration = childRows[0].duration;
        return <TsSpan>{duration ? duration : 'N/A'}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'commissionFee',
      name: 'Commission Fee',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.commissionFee ? NumberFormatHelper.roundNumber(row.commissionFee) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: 'routeFee',
      name: 'Route Fee',
      editable: false,
      renderCell({ row }) {
        return <TsSpan>{row.routeFee ? NumberFormatHelper.roundNumber(row.routeFee) : 'N/A'}</TsSpan>;
      },
    },
    {
      key: '',
      name: 'Action',
      editable: false,
      renderGroupCell({ childRows }) {
        const isOpenOrder = openStatus.includes(childRows[0].statusShort);
        // const openClose = childRows[0].openOrClose;
        const orderId = childRows[0].orderId;
        return isOpenOrder ? (
          <IconButton onClick={() => handleCancelConfirm(orderId)}>
            <IconComponent name={'crossDelete'} stroke={'#999999'} size={20} hoverColor={theme.palette.error.main} />
          </IconButton>
        ) : null;
      },
      renderCell() {
        return null;
      },
    },
  ];
  return columns;
};

export const TSOrdersWidget = () => {
  const selectedOptions = ['groupTime'] as const;
  const fetchandSetOrders = useFetchandSetOrderStream();
  const ordersDataState = useRecoilValue(tsOrdersDataState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);

  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set<unknown>(['groupTime']));
  const [rows, setRows] = useState<OrderRow[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const theme = useTheme();
  const [column, setColumn] = useState<Column<any, any>[]>([]);
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const [isAllGroupExpanded, setIsAllGroupExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>(undefined);

  //hub
  const setOrders = useSetRecoilState(tsOrdersUpdater);
  const orders = useRecoilValue(getAllTSOrdersState);
  const [refreshAccountToken, setRefreshAccountToken] = useRecoilState(tsRefreshAccountTokenState);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0 || isFetchingOrders) {
      return;
    }
    // if (orders && orders.length > 0) {
    //   return;
    // }
    // Set the state to indicate that orders are being fetched (async call is in progress)
    setIsFetchingOrders(true);
    (async () => {
      const response = await getOrders(tsEnv, selectedAccountIds);
      if (response.hasErrors || !response.data) {
        resetExpandedIds();
        setSelectedOrderId(undefined);
        return;
      }
      setOrders(response.data.orders);
      // Reset the state after fetching orders is completed
      setIsFetchingOrders(false);
      resetExpandedIds();
      setSelectedOrderId(undefined);
    })();
  }, [selectedAccountIds, ordersDataState]);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0) {
      return;
    }
    if (!ordersDataState || ordersDataState.length === 0) {
      return;
    }
    const orderController = new AbortController();
    (async () => {
      await fetchandSetOrders(orderController, selectedAccountIds);
    })();
    return () => {
      setTimeout(() => {
        try {
          if (orderController.signal.aborted) {
            return;
          }
          orderController.abort();
          if (refreshAccountToken) {
            setRefreshAccountToken(false);
          }
        } catch (e) {
          if (e.name === 'AbortError') {
          } else {
            console.error('Error fetching stream:', e);
          }
        }
      }, 100);
    };
  }, [selectedAccountIds, ordersDataState, refreshAccountToken]);

  useEffect(() => {
    const headerCall = expandAllGroupCell;
    const columnsObj = getColumns(theme, isAllGroupExpanded, headerCall, renderCancelOrder);
    setColumn(columnsObj);
    const groupRowOrderIds = new Set(['groupTime', ...sortedRows.map((row) => row.groupTime)]);
    const newExpandedIds = isAllGroupExpanded ? groupRowOrderIds : new Set(['groupTime']);
    setExpandedGroupIds(newExpandedIds);
  }, [theme, isAllGroupExpanded]);

  useEffect(() => {
    if (!orders) {
      return;
    }
    createOrderRows(orders);
  }, [selectedAccountIds, orders]);

  useEffect(() => {
    if (expandedGroupIds.size === 1) {
      setIsAllGroupExpanded(false);
      return;
    }
    const groupRowOrderIds = new Set(['groupTime', ...sortedRows.map((row) => row.groupTime)]);
    if (!isAllGroupExpanded && expandedGroupIds.size !== groupRowOrderIds.size) {
      return;
    }
    setIsAllGroupExpanded(true);
  }, [expandedGroupIds]);

  const expandAllGroupCell = () => {
    setIsAllGroupExpanded(!isAllGroupExpanded);
  };

  const resetExpandedIds = () => {
    setExpandedGroupIds(new Set(['groupTime']));
    setIsAllGroupExpanded(false);
  };

  const sortedRows = useMemo((): OrderRow[] => {
    if (sortColumns.length === 0) return rows;
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        console.log('sort', sort, a, b);
        if (sort.columnKey !== 'symbol') {
          return 0;
        }
        if (sort.direction === 'ASC') {
          return a.underlyingSymbol.localeCompare(b.underlyingSymbol);
        }
        if (sort.direction === 'DESC') {
          return b.underlyingSymbol.localeCompare(a.underlyingSymbol);
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const renderCancelOrder = (orderID: string) => {
    setOpen(true);
    setSelectedOrderId(orderID);
  };

  const rowKeyGetter = (row: OrderRow) => {
    return row.id;
  };

  const createOrderRows = (orders: IOrder[]) => {
    const rows: OrderRow[] = [];
    const newOrders = orders.flatMap(({ legs, ...order }) => legs.map((leg) => ({ leg, ...order })));
    for (let i = 0; i < newOrders.length; i++) {
      rows.push({
        id: i, //Number(orders[i].accountID),
        groupTime: newOrders[i].openedDateTime,
        orderId: newOrders[i].orderID,
        underlyingSymbol:
          newOrders[i].leg?.underlying ||
          newOrders[i].leg.symbol?.substring(
            0,
            newOrders[i].leg.symbol?.indexOf(' ') === -1
              ? newOrders[i].leg.symbol.length
              : newOrders[i].leg.symbol?.indexOf(' '),
          ),
        symbol: newOrders[i].leg.symbol,
        side: newOrders[i].leg.buyOrSell,
        type: newOrders[i].orderType,
        qty: newOrders[i].leg.quantityOrdered,
        filledQty: newOrders[i].leg.execQuantity,
        limitPrice: newOrders[i].limitPrice,
        stopPrice: newOrders[i].stopPrice,
        avgFillPrice: newOrders[i].filledPrice,
        status: newOrders[i].statusDescription,
        openTime: newOrders[i].openedDateTime,
        closeTime: newOrders[i].closedDateTime,
        routing: newOrders[i].routing,
        duration: newOrders[i].duration,
        statusShort: newOrders[i].status,
        commissionFee: newOrders[i].commissionFee,
        routeFee: newOrders[i].unbundledRouteFee,
        openOrClose: newOrders[i].leg.openOrClose,
      });
    }
    const sortedRows = rows.sort((r1, r2) => new Date(r2.groupTime).getTime() - new Date(r1.groupTime).getTime());
    setRows(sortedRows);
  };

  const renderSortStatus = ({ sortDirection, priority }: RenderSortStatusProps) => {
    return (
      <>
        {sortDirection !== undefined ? (
          sortDirection === 'ASC' ? (
            <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          ) : (
            <SouthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          )
        ) : (
          <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: '#BFE0FA' }} />
        )}
        <span>{priority}</span>
      </>
    );
  };

  const renderOOPSImage = () => {
    return <TsOOPS name="Orders" />;
  };

  if (!selectedAccountIds || selectedAccountIds.length === 0) {
    return renderOOPSImage();
  }

  if (!orders || isFetchingOrders) {
    return (
      <Grid container item xs={12} justifyContent={'center'} alignContent={'center'}>
        <OrderSkeleton isOrders />
      </Grid>
    );
  }

  const renderOrdersDataGrid = () => {
    if (orders.length === 0) {
      return renderOOPSImage();
    }
    return (
      <TreeDataGrid
        key={'orders'}
        className={theme.palette.mode === 'dark' ? 'rdg-dark' : 'rdg-light'}
        columns={column}
        rows={sortedRows}
        rowKeyGetter={rowKeyGetter}
        groupBy={selectedOptions}
        rowGrouper={rowGrouper}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        defaultColumnOptions={{ resizable: false }}
        direction={'ltr'}
        // renderers={{
        //   renderSortStatus, // TO BE FIXED
        // }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
    );
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <TsOrderHeader />
      </Grid>
      <Grid item xs={12}>
        {renderOrdersDataGrid()}
      </Grid>
      <Grid item xs={12}>
        {open && selectedOrderId && <TsCancelOrderWidget setOpen={setOpen} selectedOrderId={selectedOrderId} />}
      </Grid>
    </Grid>
  );
};
