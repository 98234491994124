import { Tabs } from '@mui/material';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { accountState, selectedSymbolState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { useOPNavigate } from '../states/use-navigate-search';
import { StyledTab } from '../styled';

export const NavigationWidget: React.FC = () => {
  const navigate = useOPNavigate();
  const watchlistRoute = useMatch('/watchlist');
  const portfolioRoute = useMatch('/portfolio');
  const shareRoute = useMatch('/share');
  const [componentNav, setComponentNav] = React.useState('0');
  const { t } = React.useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const location = useLocation();

  useEffect(() => {
    const ideaPatterns = ['/', '/idea', '/idea/covered-call', '/idea/credit-spread', '/idea/short-put'];
    const tradeIdeasRoute = ideaPatterns.find((p) => p === location.pathname);
    if (shareRoute || tradeIdeasRoute) {
      setComponentNav('0');
      return;
    }
    if (watchlistRoute) {
      setComponentNav('1');
      return;
    }
    if (portfolioRoute) {
      setComponentNav('2');
    }
  }, [location]);

  const handleChange = (value: string) => {
    const tab = value;
    setComponentNav(tab);
    if (tab === '0') {
      navigate('/');
      logActivity('tradeIdeasTab', `${t('what.menu.tradeIdeas')}`);
    } else if (tab === '2') {
      navigate('/portfolio');
      logActivity('portfolioTab', `${t('what.menu.portfolio')}`);
    } else {
      navigate('/watchlist');
      logActivity('watchListTab', `${t('what.menu.watchlists')}`);
    }
  };

  const logActivity = (name: string, value: string) => {
    ApplicationContext.userActivityHub?.logActivity(name, name, value, 'click', selectedSymbol);
  };

  if (!account) {
    return null;
  }

  return (
    <Tabs
      value={componentNav}
      onChange={(_, value) => handleChange(value)}
      aria-label="Navigation options"
      variant="scrollable"
      scrollButtons="auto">
      {account.canWorkWithTradeIdeas ? (
        <StyledTab label={t('what.menu.tradeIdeas')} value="0" isselected={(componentNav === '0').toString()} />
      ) : null}
      {account.canWorkWithWatchLists ? (
        <StyledTab label={t('what.menu.watchlists')} value="1" isselected={(componentNav === '1').toString()} />
      ) : null}
      {account.canWorkWithPortfolios ? (
        <StyledTab label={t('what.menu.portfolio')} value="2" isselected={(componentNav === '2').toString()} />
      ) : null}
    </Tabs>
  );
};
