import { Permissions } from '@op/shared/src/models/enums/enums';
import { accountState } from '@op/shared/src/states';
import {
  fetchMarketWorkTimeDetails,
  isHubInitiatedState,
  marketWorkTimeDetailsCachedState,
} from '@op/shared/src/states/hub-states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import { setTimeout } from 'timers';
import Tock, { TockOptions } from 'tocktimer';
import { Theme, Typography, useTheme } from '@mui/material';
import { notificationsState } from '@op/shared/src/states/notification-states';
import LocalizationContext from '../react-i18next/localization-context';

export const MarketWorkTimeDetailsWidget: React.FC<any> = () => {
  const [marketWorkTimeDetails, setMarketWorkTimeDetails] = useRecoilState(marketWorkTimeDetailsCachedState);
  const isHubInitialized = useRecoilValue(isHubInitiatedState);
  const account = useRecoilValue(accountState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [marketState, setMarketState] = useState<string | undefined>();
  const { t } = React.useContext(LocalizationContext);
  const [, setTickSeconds] = useState(0);
  const [timeoutCount, setTimeoutCount] = useState(0);
  const theme = useTheme<Theme>();
  const permissions = account?.securityModel?.permissions || [];
  const useRealTimeQuotes = permissions.find((p) => p === Permissions.USE_REAL_TIME_QUOTES);

  const marketOpenTitle = 'header.js.marketWorkTimeDetails.marketOpen';
  const marketClosedTitle = 'header.js.marketWorkTimeDetails.marketClosed';
  const marketOpensInTitle = 'header.js.marketWorkTimeDetails.marketOpensIn';
  const marketClosesInTitle = 'header.js.marketWorkTimeDetails.marketClosesIn';
  const marketAboutToCloseTitle = 'header.js.marketWorkTimeDetails.marketIsAboutToClose';
  const marketOpenForTrading = 'header.js.marketWorkTimeDetails.marketInNowOpenForTrading';
  const marketClosingIn5MinsForTrading = 'header.js.marketWorkTimeDetails.marketInClosingIn5MinutesForTrading';

  const millisecondsBeforeShowingCountdownMs = 60 * 60 * 1000; // 1 hour;
  const aboutToCloseNotificationTimeInSeconds = 5 * 60; // 5 minutes
  const aboutToCloseNotificationTimeInMilliseconds = aboutToCloseNotificationTimeInSeconds * 1000;

  const checkAndRaiseNotifications = () => {
    const details = marketWorkTimeDetails;
    var openTime = details?.utcOpenTime,
      closeTime = details?.utcCloseTime;

    var notificationThreshold = 500;

    // today is a holiday or a weekend
    if (closeTime == null || openTime == null) {
      return;
    }

    var openTimeStamp = openTime.getTime();
    var closeTimeStamp = closeTime.getTime();
    var currentTimeStamp = details?.adjustUtcTime();

    if (Math.abs(currentTimeStamp - openTimeStamp) < notificationThreshold) {
      setNotifications([
        { type: 'info', content: t(marketOpenForTrading)?.toString() || '' },
        { type: 'info', content: t(marketOpenTitle)?.toString() || '' },
      ]);
      return;
    }

    var toClose = closeTimeStamp - currentTimeStamp;
    if (Math.abs(toClose) < notificationThreshold) {
      setNotifications([
        {
          type: 'info',
          content: `${t(marketClosingIn5MinsForTrading)?.toString() || ''}. ${
            t(marketAboutToCloseTitle)?.toString() || ''
          }`,
        },
      ]);
      return;
    }

    if (Math.abs(toClose - aboutToCloseNotificationTimeInMilliseconds) < notificationThreshold) {
      setNotifications([
        {
          type: 'info',
          content: `${t(marketClosingIn5MinsForTrading)?.toString() || ''}. ${
            t(marketAboutToCloseTitle)?.toString() || ''
          }`,
        },
      ]);
      return;
    }
  };

  const [countdown, _] = useState(() => {
    const timerOptions: TockOptions = {
      countdown: true,
      interval: 1000,
      callback: () => {
        let seconds = Math.round(countdown.lap() / 1000);
        setTickSeconds(seconds);
      },
      complete: checkAndRaiseNotifications,
    };
    return Tock(timerOptions);
  });

  useEffect(() => {
    const delay = 30 * 1000;
    let timeoutId: NodeJS.Timeout;
    if (!isHubInitialized) {
      return () => clearTimeout(timeoutId);
    }
    (async () => {
      await initialize();
      timeoutId = setTimeout(() => {
        setTimeoutCount((previous) => previous + 1);
      }, delay);
    })();
    return () => clearTimeout(timeoutId);
  }, [isHubInitialized, timeoutCount]);

  const initialize = async () => {
    const details = await fetchMarketWorkTimeDetails();
    const marketStateAndTiming = getMarketStateAndTiming(details);
    countdown.stop();
    countdown.start(marketStateAndTiming.countDownTimeInMilliSeconds);
    setMarketWorkTimeDetails(details);
    setMarketState(marketStateAndTiming.marketStateTitle);
  };

  const getMarketStateAndTiming = (details: any) => {
    const result = {
      marketStateTitle: '',
      countDownTimeInMilliSeconds: 0,
    };
    const openTime = details?.utcOpenTime;
    const closeTime = details?.utcCloseTime;

    // today is a holiday or a weekend
    if (closeTime == null || openTime == null) {
      result.marketStateTitle = marketClosedTitle;
      return result;
    }

    const openTimeStamp = openTime.getTime();
    const closeTimeStamp = closeTime.getTime();
    const currentTimeStamp = details?.adjustUtcTime();

    // market is closed
    if (currentTimeStamp >= closeTimeStamp) {
      result.marketStateTitle = marketClosedTitle;
      return result;
    }

    if (currentTimeStamp < openTimeStamp) {
      const millisecondsToOpen = openTimeStamp - currentTimeStamp;
      if (millisecondsToOpen <= millisecondsBeforeShowingCountdownMs) {
        result.countDownTimeInMilliSeconds = millisecondsToOpen;
        result.marketStateTitle = marketOpensInTitle;
      } else {
        result.countDownTimeInMilliSeconds = millisecondsToOpen - millisecondsBeforeShowingCountdownMs;
        result.marketStateTitle = marketClosedTitle;
      }
      return result;
    }

    const millisecondsFromOpen = currentTimeStamp - openTimeStamp;
    if (millisecondsFromOpen >= millisecondsBeforeShowingCountdownMs) {
      const millisecondsToClose = closeTimeStamp - currentTimeStamp;
      result.countDownTimeInMilliSeconds = millisecondsToClose;
      result.marketStateTitle = marketClosesInTitle;
    } else {
      result.countDownTimeInMilliSeconds = millisecondsBeforeShowingCountdownMs - millisecondsFromOpen;
      result.marketStateTitle = marketOpenTitle;
    }
    return result;
  };

  const renderCountDown = () => {
    const remainingTimeInMilliSeconds = countdown.lap();
    if (remainingTimeInMilliSeconds === aboutToCloseNotificationTimeInMilliseconds) {
      checkAndRaiseNotifications();
    }
    if (marketState !== marketOpensInTitle && marketState !== marketClosesInTitle) {
      return null;
    }
    if (remainingTimeInMilliSeconds <= 0) {
      return null;
    }

    return (
      <Typography variant="body1" component="span" sx={{ pl: 1 }}>
        {countdown.msToTimecode(remainingTimeInMilliSeconds)}
      </Typography>
    );
  };

  if (!marketState) {
    return null;
  }

  const renderDelayedMessage = () => {
    if (useRealTimeQuotes) {
      return (
        <Typography
          variant="body1"
          sx={{ color: 'primary.main', fontWeight: (theme) => theme.typography.fontWeightBold }}>
          {t('header.text.realTimeMarketData')}
        </Typography>
      );
    }

    return (
      <Typography
        variant="body1"
        sx={{ color: 'warning.main', fontWeight: (theme) => theme.typography.fontWeightBold }}>
        {t('header.text.marketDataDelayed')}
      </Typography>
    );
  };

  return (
    <>
      <Typography variant="body1" component="span" color={theme.palette.text.primary}>
        {t(marketState)} {renderCountDown()}
      </Typography>
      <Typography variant="body1" component="span" sx={{ pl: 1 }}>
        {marketWorkTimeDetails?.closeReason}
      </Typography>
      <div>{renderDelayedMessage()}</div>
    </>
  );
};
