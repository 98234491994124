import { Grid, Typography } from '@mui/material';
import { IData, IncomeCombination } from '@op/shared/src/models';
import Combination from '@op/shared/src/models/how/combination';
import formatting from '@op/shared/src/models/how/formatting';
import helpers from '@op/shared/src/models/how/helpers';
import React from 'react';
import { PlChartWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';

export interface IRiskAnalysisWidgetProps extends IData<Combination | IncomeCombination> {}

export const RiskAnalysisWidget: React.FC<IRiskAnalysisWidgetProps> = ({ data }: IRiskAnalysisWidgetProps) => {
  const { t } = React.useContext(LocalizationContext);
  if (!data) {
    return null;
  }

  //TODO: refactor: duplicated in explain-strategy-greeks.
  const riskProfile = () => {
    let risk = data.maxRisk();
    return helpers.isNumber(risk)
      ? t('how.howPanel.basicCombinationModel.limited')?.toString() || ''
      : t('how.howPanel.basicCombinationModel.unlimited')?.toString() || '';
  };

  const combinationSentiment = data.sentiment();
  const sentiment =
    combinationSentiment !== 'unknown'
      ? formatting.capitaliseFirstLetter(combinationSentiment.toString())
      : 'Unknown_Sentiment';
  const riskProfileFormatted = formatting.capitaliseFirstLetter(riskProfile()) + '_risk';

  return (
    <Grid container textAlign="center">
      <Grid item xs={12}>
        <PlChartWidget data={data} showAxis={true} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          {`${t(`how.howPanel.deltaDetails.sentiment.${sentiment}`)} ${t('how.howPanel.deltaDetails.with')} ${t(
            `how.howPanel.deltaDetails.riskProfile.${riskProfileFormatted}`,
          )}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
