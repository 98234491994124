import { IUserSubscriptionFeatures } from '@op/shared/src/models';
import { getSubscriptionPricingModels, getUserSubscriptions } from '@op/shared/src/services';
import {
  accountState,
  customizationState,
  subscriptionCurrentScreenState,
  subscriptionPlanPricesModelState,
  userSubscriptionFeaturesState,
} from '@op/shared/src/states';
import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ProfileCardWidget } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { CancelSubscriptionWidget } from './cancel-subscription';
import { ChangeSubscriptionWidget } from './change-subscription';
import { SubscriptionCurrentWidget } from './subscription-current-widget';
import { getModifiedUserCachedSubscription, prepareSubscriptionPlan } from './subscription-helper';
import { CurrentSubscriptionSkeletonWidget } from './subscription-skeleton-widget';

export const SubscriptionWidget: React.FC = () => {
  const { t } = useContext(LocalizationContext);
  const account = useRecoilValue(accountState);
  const customization = useRecoilValue(customizationState);
  const subscriptionCurrentScreen = useRecoilValue(subscriptionCurrentScreenState);
  const [userSubscriptionFeatures, setUserSubscriptionFeatures] = useRecoilState(userSubscriptionFeaturesState);
  const [subscriptionPlanPrices, setSubscriptionPlanPricesModel] = useRecoilState(subscriptionPlanPricesModelState);
  const [loading, setLoading] = useState(false);

  const fetchUserSubscriptions = async () => {
    const response = await getUserSubscriptions();
    if (response.hasErrors) {
      return;
    }
    const modifiedUserSubscription = getModifiedUserCachedSubscription(response.data);
    setUserSubscriptionFeatures(modifiedUserSubscription);
  };

  const fetchSubscriptionPricingModels = async (features: IUserSubscriptionFeatures) => {
    // TODO: need to be removed
    const response = await getSubscriptionPricingModels();
    if (response.hasErrors) {
      return;
    }
    // TODO: if API is already called, restrict the duplicate call
    const modifiedPlan = prepareSubscriptionPlan(response.data, features);
    setSubscriptionPlanPricesModel(modifiedPlan);
    setLoading(false);
  };

  useEffect(() => {
    if (userSubscriptionFeatures) {
      return;
    }
    setLoading(true);
    (async () => {
      await fetchUserSubscriptions();
    })();
  }, [userSubscriptionFeatures]);

  useEffect(() => {
    if (!userSubscriptionFeatures) {
      return;
    }
    if (subscriptionPlanPrices) {
      return;
    }
    (async () => {
      await fetchSubscriptionPricingModels(userSubscriptionFeatures);
    })();
  }, [userSubscriptionFeatures]);

  if (!account || !customization) {
    return <></>;
  }

  const renderContent = () => {
    switch (subscriptionCurrentScreen) {
      case 'CurrentSubscription':
        return <SubscriptionCurrentWidget />;
      case 'ChangeSubscription':
        return <ChangeSubscriptionWidget />;
      case 'Cancelsubscription':
        return <CancelSubscriptionWidget />;
      default:
        return <SubscriptionCurrentWidget />;
    }
  };

  if (loading || !userSubscriptionFeatures || !subscriptionPlanPrices) {
    return <CurrentSubscriptionSkeletonWidget />;
  }

  return <ProfileCardWidget isStepper title={t('subscriptions.subscription')} content={renderContent()} />;
};
