import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ILeg, Leg, Position, StrategyHelpers } from '@op/shared/src/models';
import {
  ActionTypes,
  BuyOrSell,
  EventType,
  LegType,
  PriceCalculationMethod,
  StrategyConstructor,
} from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import Combination from '@op/shared/src/models/how/combination';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { customizationState, selectedSymbolState, sharesState, strategyModifyState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlaySwitch } from '../styled/options-play-components';
import { AddCallWidget } from './add-call';
import { AddPutWidget } from './add-put';
import { AddStockWidget } from './add-stock';
import { canHideLegButton } from './modify';
import { StrategyConstructorWidget } from './strategy-constructor-widget';
import { QuantityField } from './quantity-field';
import { GuideItem } from '../side-menu';

export const CombinationEditorTable = styled('div')(({ theme }) => ({
  '& .MuiTableCell-root': {
    border: '1px solid #ddd',
    textAlign: 'center',
    padding: '0px',
  },
  '& .MuiTableCell-head': {
    background: theme.palette.primary.light,
    textAlign: 'center',
    fontWeight: 'normal',
  },
  '& .MuiTableCell-body': {
    textAlign: 'center',
    cursor: 'pointer',
  },
  '& .MuiSvgIcon-root': {
    display: 'inline-flex',
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}));

const menuHeight = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};

export interface ICombinationEditorWidgetProps {
  onEditCombinationCallback: (action: string, legs?: ILeg[]) => void;
  shouldIncludeStrategyConstructors?: boolean;
}

export const CombinationEditorWidget: React.FC<ICombinationEditorWidgetProps> = ({
  shouldIncludeStrategyConstructors,
  onEditCombinationCallback,
}: ICombinationEditorWidgetProps) => {
  const combination = useRecoilValue(strategyModifyState);
  const shares = useRecoilValue(sharesState);
  const resetStrategyModify = useResetRecoilState(strategyModifyState);
  const { t } = React.useContext(LocalizationContext);
  const theme = useTheme<Theme>();
  const [selectedPrice, setselectedPrice] = useState(PriceCalculationMethod.BID_ASK);
  const [priceBy, setPriceBy] = useState(0);
  const customization = useRecoilValue(customizationState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  library.add(faTimesCircle);

  // useEffect(() => {
  //   return () => resetStrategyModify();
  // }, []);

  useEffect(() => {
    if (!combination) return;
    // const positions = combination.positions;
    // setQuantities(() => {
    //   if (!positions) return [];
    //   const legQuantities: number[] = [];
    //   positions.map((p, i) => {
    //     return legQuantities.push(p.quantity);
    //   });
    //   return legQuantities;
    // });
    setselectedPrice(combination.priceCalculationMethod);
    setPriceBy(combination.priceCalculationMethod === PriceCalculationMethod.BID_ASK ? 0 : 1);
  }, [combination]);

  if (!combination) {
    return (
      <Grid container justifyContent="center" sx={{ margin: 10 }}>
        <Typography>Please select a strategy to modify.</Typography>
      </Grid>
    );
  }

  if (!customization) {
    return null;
  }

  const positions = combination.positions;
  const chain = combination.chain;
  const isRoll = combination.isRoll; //TODO: This is not set, should be removed.

  const canIncreaseQuantity = () => {
    return StrategyHelpers.canIncreaseQuantity(combination, shares);
  };

  const canDecreaseQuantity = () => {
    return StrategyHelpers.canDecreaseQuantity(combination);
  };

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };

  const isSingleSecurityLeg = () => {
    const buyablePositions = combination.buyablePositions;
    return buyablePositions.length === 1 && buyablePositions[0].type === LegType.SECURITY;
  };

  const canRemovePosition = () => {
    //return !combination.isRoll && (this.isRecommendationEditor || combination.buyablePositions.length > 1);
    //return combination.buyablePositions.length > 1;
    if (combination.isPortfolio) {
      return !combination.isRoll;
    }
    return !combination.isRoll && combination.buyablePositions.length > 1;
  };

  const onRemovePosition = (positionToRemove: Position, index: number) => {
    logActivity('BUTTON', 'editorRemovePositionButton', 'Security');
    if (!canRemovePosition()) {
      return;
    }
    const legs = getLegs();
    legs.splice(index, 1);
    onEditCombinationCallback('', legs);
  };

  const getLegs = () => {
    const legs = combination.positions.map((p) => Leg.fromPosition(p));
    return legs;
  };

  const buySellEvent = (position: Position) => {
    logActivity('ICON', 'editorBuySellChangeButton', position.buyOrSell);
    const posIndex = positions.indexOf(position);
    const buyOrSell = position.buyOrSell === BuyOrSell.BUY ? BuyOrSell.SELL : BuyOrSell.BUY;

    let newLeg = {
      buyOrSell: buyOrSell,
      quantity: position.quantity,
      legType: position.legType,
      expiry: position.expiry,
      strikePrice: position.strike,
    };
    const leg = combination.canUpdatePosition(newLeg);
    if (!leg) {
      return;
    }
    const legs = getLegs();
    legs.splice(posIndex, 1, leg);
    onEditCombinationCallback('', legs);
  };

  const callPutType = (position: Position) => {
    logActivity('ICON', 'editorCallPutChange', position.type);
    if (position.type === LegType.SECURITY) {
      return;
    }
    const posIndex = positions.indexOf(position);
    const positionType = position.type === LegType.CALL ? LegType.PUT : LegType.CALL;

    let newLeg = {
      buyOrSell: position.buyOrSell,
      quantity: position.quantity,
      legType: positionType,
      expiry: position.expiry,
      strikePrice: position.strike,
    };
    const leg = combination.canUpdatePosition(newLeg);
    if (!leg) {
      return;
    }
    const legs = getLegs();
    legs.splice(posIndex, 1, leg);
    onEditCombinationCallback('', legs);
  };

  const expiryList = chain === undefined ? [] : chain.expirySelectOptions();

  const handleChangeExpiry = (value: { date: Date; dateonSelector: string }, position: Position) => {
    logActivity('ICON', 'editorExpiryChange', position.expiry);
    if (!chain) {
      throw new Error('chain is undefined');
    }
    if (value && value.date) {
      var changedExpiry = new Date(value.date);
      const posIndex = positions.indexOf(position);
      const newStrikePrice = chain.findStrike(position.strike, changedExpiry, 0);
      let newLeg = {
        buyOrSell: position.buyOrSell,
        quantity: position.quantity,
        legType: position.legType,
        expiry: changedExpiry,
        strikePrice: newStrikePrice,
      };
      const newCombination = Combination.fromSelf(combination);
      const leg = newCombination.canUpdatePosition(newLeg);
      if (!leg) {
        return;
      }
      const legs = getLegs();
      legs.splice(posIndex, 1, leg);
      newCombination.replacePosition(leg, posIndex);
      onEditCombinationCallback('', getLegsFromCombination(newCombination));
    }
  };

  const handelChangeStrike = (value: number, position: Position) => {
    logActivity('ICON', 'editorStrikeChange', position.strike);
    if (value) {
      const changedStrike = parseFloat(value.toString());
      const posIndex = positions.indexOf(position);
      let newLeg = {
        buyOrSell: position.buyOrSell,
        quantity: position.quantity,
        legType: position.legType,
        expiry: position.expiry,
        strikePrice: changedStrike,
      };
      const leg = combination.canUpdatePosition(newLeg);
      if (!leg) {
        return;
      }
      const legs = getLegs();
      legs.splice(posIndex, 1, leg);
      onEditCombinationCallback('', legs);
    }
  };

  //TODO: this can be wrong.
  const flipBS = () => {
    const legs = getLegs();
    for (let j = 0; j < combination.positions.length; j++) {
      const position = combination.positions[j];
      const newBuyOrSell = position.buyOrSell === BuyOrSell.BUY ? BuyOrSell.SELL : BuyOrSell.BUY;

      let newLeg = {
        buyOrSell: newBuyOrSell,
        quantity: position.quantity,
        legType: position.legType,
        expiry: position.expiry,
        strikePrice: position.strike,
      };
      const leg = combination.canUpdatePosition(newLeg);
      if (!leg) {
        return;
      }
      legs.splice(j, 1, leg);
    }
    logActivity('BUTTON', 'editorFlipStrategyButton', '');
    onEditCombinationCallback('', legs);
  };

  const reset = () => {
    logActivity('BUTTON', 'editorResetLegsButton', '');
    onEditCombinationCallback(ActionTypes.Reset, combination.originalLegs);
  };
  const quantityChange = (isIncremment: boolean) => {
    logActivity('ICON', 'editorQuantityHeaderChange', isIncremment ? 'Right' : 'Left');
    const legs = getLegs();
    if (legs.length < 1) {
      return;
    }
    let intialLegQuantity = getIntialLegQuantity();
    let updatedQuantity: number[] | undefined = undefined;
    if (combination.hasOnlyStx()) {
      updatedQuantity = legs.map((item) => {
        const quantity = item.quantity;
        const sign = Math.sign(quantity);
        if (!isIncremment && Math.abs(quantity) <= 100) {
          return sign * 100;
        }
        const absQty = isIncremment ? Math.abs(quantity) + 100 : Math.abs(quantity) - 100;
        return sign * absQty;
      });
    } else {
      const gcd = StrategyHelpers.calculateGCD(intialLegQuantity);
      updatedQuantity = legs.map((item) => {
        const quantity = item.quantity;
        const sign = Math.sign(quantity);
        if (item.legType !== 'Security') {
          return isIncremment ? sign * Math.abs(quantity + quantity / gcd) : sign * Math.abs(quantity - quantity / gcd);
        }
        return isIncremment ? sign * (Math.abs(quantity) + 100) : sign * (Math.abs(quantity) - 100);
      });
    }
    if (!updatedQuantity) {
      return;
    }
    const newCombination = Combination.fromSelf(combination);
    for (let j = 0; j < newCombination.positions.length; j++) {
      const position = newCombination.positions[j];
      position.isPortfolio = true;
      let newLeg = {
        buyOrSell: position.buyOrSell,
        quantity: updatedQuantity[j],
        legType: position.legType,
        expiry: position.expiry,
        strikePrice: position.strike,
      };
      const leg = newCombination.canUpdatePosition(newLeg);
      if (leg) {
        newCombination.replacePositionByLeg(leg, j);
        if (!leg) {
          return;
        }
        legs.splice(j, 1, leg);
      }
    }
    onEditCombinationCallback('', getLegsFromCombination(newCombination));
  };

  const getIntialLegQuantity = () => {
    const legs = getLegs();
    let optionLegs = [];
    for (let i = 0; i < legs.length; i++) {
      if (legs[i].legType !== 'Security') {
        optionLegs.push(Math.abs(legs[i].quantity));
      }
    }

    return optionLegs;
  };

  const getLegsFromCombination = (combination: Combination) => {
    const newLegs = combination.positions.map((p) => Leg.fromPosition(p));
    return newLegs;
  };

  const shrinkWidth = () => {
    const newCombination = StrategyHelpers.changeWidth(combination, false);
    if (!newCombination) {
      return;
    }
    logActivity('BUTTON', 'editorShrinkWingspanButton', '');
    onEditCombinationCallback('', getLegsFromCombination(newCombination));
  };

  const expandWidth = () => {
    const newCombination = StrategyHelpers.changeWidth(combination, true);
    if (!newCombination) {
      return;
    }
    logActivity('BUTTON', 'editorExpandWingspanButton', '');
    onEditCombinationCallback('', getLegsFromCombination(newCombination));
  };

  const shrinkWingspan = () => {
    const newCombination = StrategyHelpers.changeWingspan(combination, false);
    if (!newCombination) {
      return;
    }
    logActivity('BUTTON', 'editorShrinkWingspanButton', '');
    onEditCombinationCallback('', getLegsFromCombination(newCombination));
  };

  const expandWingspan = () => {
    const newCombination = StrategyHelpers.changeWingspan(combination, true);
    if (!newCombination) {
      return;
    }
    logActivity('BUTTON', 'editorExpandWingspanButton', '');
    onEditCombinationCallback('', getLegsFromCombination(newCombination));
  };

  const openedPositionsFilter = (p: any) => {
    return !combination.isClosePosition(p);
  };

  const changePrice = (value: PriceCalculationMethod) => {
    if (value === PriceCalculationMethod.BID_ASK.toString()) {
      setselectedPrice(PriceCalculationMethod.BID_ASK);
      logActivity('BUTTON', 'editorStrategyPriceBySelector', PriceCalculationMethod.BID_ASK);
      onEditCombinationCallback(ActionTypes.Change_Price_Bid_Ask);
    } else {
      setselectedPrice(PriceCalculationMethod.MID);
      logActivity('BUTTON', 'editorStrategyPriceBySelector', PriceCalculationMethod.MID);
      onEditCombinationCallback(ActionTypes.Change_Price_Mid);
    }
  };

  const strikeChange = (isIncremment: boolean) => {
    logActivity('ICON', 'editorStrikeHeaderChange', isIncremment ? 'Right' : 'Left');
    const legs = getLegs();
    const newCombination = Combination.fromSelf(combination);
    let j = 0;
    let positions: Position[] = isRoll
      ? newCombination.positions.filter(openedPositionsFilter)
      : newCombination.positions;

    // positions.forEach((pos: Position) => {
    for (let i in positions) {
      let pos = positions[i];
      if (pos.legType === LegType.SECURITY) {
        j++;
        continue;
      }
      // if (isSingleSecurityLeg() && pos.isSecurityType()) {
      //   continue;
      // }

      let strikeList = pos.strikeList();
      let newStrike = pos.strike;
      if (!newStrike) {
        throw new Error('strike is undefined');
      }

      if (strikeList === null) {
        throw new Error('strikeList is undefined');
      }
      if (!strikeList) {
        throw new Error('strike is undefined');
      }
      let idx = strikeList.indexOf(newStrike) + (isIncremment ? 1 : -1);
      if (idx >= 0 && idx <= strikeList.length - 1) {
        newStrike = strikeList[idx];
      }
      let newLeg = {
        buyOrSell: pos.buyOrSell,
        quantity: pos.quantity,
        legType: pos.legType,
        expiry: pos.expiry,
        strikePrice: newStrike,
      };

      const leg = combination.canUpdatePosition(newLeg);
      if (!leg) {
        continue;
      }
      legs.splice(j, 1, leg);
      j++;
    }
    onEditCombinationCallback('', legs);
  };

  const rawPosGetter = (p: Position) => {
    return p.getRawPosition();
  };

  //TODO: Need to fix this.
  const expiryChange = (isIncrement: boolean) => {
    logActivity('ICON', 'editorExpiryHeaderChange', isIncrement ? 'Right' : 'Left');
    const newCombination = Combination.fromSelf(combination);
    if (!chain) {
      throw new Error('chain is undefined');
    }
    let rawPositions;
    let allPosition = combination.positions;
    let additionalPositions: ILeg[] = [];
    if (!isRoll) {
      rawPositions = allPosition.map(rawPosGetter);
    } else {
      additionalPositions = allPosition.filter!(openedPositionsFilter).map(rawPosGetter);
      rawPositions = allPosition.filter(openedPositionsFilter).map(rawPosGetter);
    }
    let strikeAdjustmentRequired = false;
    let oldStrikeIndices = [];
    for (let i = 0; i < rawPositions.length; i++) {
      let rawPos = rawPositions[i];
      if (rawPos.legType === LegType.SECURITY) {
        continue;
      }
      let expDate = expiryList.map((i) => i.date);
      let idx = expDate.indexOf(rawPos.expiry as Date) + (isIncrement ? 1 : -1);
      let oldStrike = rawPos.strikePrice;
      let strikeList = combination.getFullStrikeList(rawPos.expiry);
      if (!strikeList) {
        throw new Error('Strikelist is undefined');
      }
      if (!oldStrike) {
        throw new Error('oldStrike is undefined');
      }
      let oldStrikeIndex = strikeList.indexOf(oldStrike);
      oldStrikeIndices.push(oldStrikeIndex);
      if (idx >= 0 && idx < expiryList.length) {
        rawPos.expiry = expiryList[idx].date;
        rawPos.strikePrice = chain.findStrike(oldStrike, rawPos.expiry, 0);
        if (oldStrike !== rawPos.strikePrice) {
          strikeAdjustmentRequired = true;
        }
        let newLeg = {
          buyOrSell: rawPos.buyOrSell,
          quantity: rawPos.quantity,
          legType: rawPos.legType,
          expiry: rawPos.expiry,
          strikePrice: rawPos.strikePrice,
        };
        newCombination.replacePosition(newLeg, i);
        // const newTradingStrategies = TradingStrategies.fromSelf(tradingStrategies);
        // newTradingStrategies.allCombinations[parseInt(selectedCombinationId)] = newCombination;
        // setTradingStrategies(newTradingStrategies);
        const newLegs = newCombination.positions.map((p) => Leg.fromPosition(p));
        onEditCombinationCallback('', newLegs);
      }
    }
    if (strikeAdjustmentRequired && oldStrikeIndices.length > 0) {
      let optionLegs = rawPositions.filter((p: any) => {
        return p.legType !== LegType.SECURITY;
      });
      for (let i = 1; i < oldStrikeIndices.length; i++) {
        let currPos = optionLegs[i];
        let oldIndexDiff = oldStrikeIndices[i] - oldStrikeIndices[i - 1];
        currPos.strikePrice = chain.findStrike(optionLegs[i - 1].strikePrice, currPos.expiry, oldIndexDiff);
      }
    }
  };

  const roundOfToTwoDecimals = (value: any) => {
    if (!value) {
      return 0;
    }
    return value.toFixed(2);
  };

  const strikeInside1SDforPut = (selectedStrategy: String, thePosition: Position) => {
    if (!thePosition.isPortfolio) {
      // TODO: if (!combination.isShareTrader) {
      if (
        (StrategyConstructor.LONG_CALL_VERTICAL.toString() === selectedStrategy ||
          StrategyConstructor.LONG_PUT_VERTICAL.toString() === selectedStrategy) &&
        //onPageLoad &&
        thePosition.buyOrSell === BuyOrSell.SELL &&
        thePosition.isPutType()
      ) {
        return true;
      }
      //}
    }
  };

  const strikeInside1SDforCall = (selectedStrategy: string, thePosition: Position) => {
    if (!thePosition.isPortfolio) {
      //if (!combination.isShareTrader) {
      if (
        (StrategyConstructor.LONG_CALL_VERTICAL.toString() === selectedStrategy ||
          StrategyConstructor.LONG_PUT_VERTICAL.toString() === selectedStrategy) &&
        thePosition.buyOrSell === 'Sell' &&
        thePosition.isCallType()
      ) {
        return true;
      }
      //}
    }
  };

  const printFormat = (num: number, intValue: number, decimal: number) => {
    let ret = '';
    let intNum = Math.floor(num);
    let decNum = num - intNum;
    let n;
    ret += intNum.toString();
    if (decNum !== 0) {
      ret += decNum.toFixed(decimal - 1).substring(1);
      return parseFloat(ret).toFixed(2);
    }

    for (n = 0; n < decimal; n++) {
      if (n === 0) {
        ret += '.';
      } else {
        ret += '0';
      }
    }
    return parseFloat(ret).toFixed(1);
  };

  const checkFormat = (strikeValue: number, bidValue: number, askValue: number) => {
    let strikeInt = 0;
    let strikeDecimal = 0;
    let bidInt = 0;
    let bidDecimal = 0;
    let askInt = 0;
    let askDecimal = 0;

    let sint = strikeValue.toFixed().length;
    let sdec = strikeValue.toString().length - sint;
    let bint = bidValue.toFixed().length;
    let bdec = bidValue.toString().length - bint;
    let aint = askValue.toFixed().length;
    let adec = askValue.toString().length - aint;
    if (sint > strikeInt) {
      strikeInt = sint;
    }
    if (sdec > strikeDecimal) {
      strikeDecimal = sdec;
    }
    if (bint > bidInt) {
      bidInt = bint;
    }
    if (bdec > bidDecimal) {
      bidDecimal = bdec;
    }
    if (aint > askInt) {
      askInt = aint;
    }
    if (adec > askDecimal) {
      askDecimal = adec;
    }

    return {
      strikeInt: strikeInt,
      strikeDecimal: strikeDecimal,
      bidInt: bidInt,
      bidDecimal: bidDecimal,
      askInt: askInt,
      askDecimal: askDecimal,
    };
  };

  const getStrikeDetails = (position: Position) => {
    const strikeList = position.strikeList();
    if (!strikeList || strikeList.length === 0) {
      return undefined;
    }
    let detailList = [];
    const chain = combination.chain;
    if (!chain) {
      throw new Error('Chain in undefined');
    }
    for (let strike of strikeList) {
      let theRow = chain.findRow(strike.toString(), position.expiry, position.optionType);
      if (!theRow) {
        throw new Error('The chain row is undefined');
      }
      if (position.isCallType()) {
        const data = {
          strike: strike,
          bid: theRow.callBid,
          ask: theRow.callAsk,
          delta: roundOfToTwoDecimals(theRow.callDelta),
          values: {
            strikeInt: 0,
            strikeDecimal: 0,
            bidInt: 0,
            bidDecimal: 0,
            askInt: 0,
            askDecimal: 0,
          },
        };
        const values = checkFormat(strike, theRow.callBid, theRow.callAsk);
        data.values = values;
        detailList.push(data);
      } else {
        const data = {
          strike: strike,
          bid: theRow.putBid,
          ask: theRow.putAsk,
          delta: roundOfToTwoDecimals(theRow.putDelta),
          values: {
            strikeInt: 0,
            strikeDecimal: 0,
            bidInt: 0,
            bidDecimal: 0,
            askInt: 0,
            askDecimal: 0,
          },
        };
        const values = checkFormat(strike, theRow.putBid, theRow.putAsk);
        data.values = values;
        detailList.push(data);
      }
    }

    return detailList;
  };

  const getDropdown = (str: string) => {
    let strikeStr = str.split(','); // split string on comma space
    return (
      <Table>
        <TableBody>
          <TableRow hover>
            {strikeStr.map((strike, index) => {
              return (
                <TableCell
                  key={index}
                  align="right"
                  sx={{
                    p: 1,
                    border: 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {strike}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const convertStrikeList = (position: Position) => {
    let selectedStrategy: string = '';
    const matchedTemplate = combination.matchedTemplate();
    if (matchedTemplate) {
      selectedStrategy = matchedTemplate.displayedName;
    }
    const detailList = getStrikeDetails(position);
    if (!detailList) {
      return null;
    }

    let formattedList = [];
    let stdDevs = combination.stdDev.getStdDevsByExpiry(combination.expiration());
    let lastPrice;
    let leftBound;
    let leftFlag;
    let rightBound;
    let rightFlag;
    if (stdDevs) {
      lastPrice = stdDevs[3];
      leftBound = stdDevs[2];
      rightBound = stdDevs[4];
      leftFlag = false;
      rightFlag = false;
    }
    for (let strikeDetail of detailList) {
      let strike = strikeDetail.strike;
      let bid = strikeDetail.bid;
      let ask = strikeDetail.ask;
      let delta = strikeDetail.delta;
      const strikeInt = strikeDetail.values.strikeInt;
      const strikeDecimal = strikeDetail.values.strikeDecimal;
      const askInt = strikeDetail.values.askInt;
      const askDecimal = strikeDetail.values.askDecimal;
      const bidInt = strikeDetail.values.bidInt;
      const bidDecimal = strikeDetail.values.bidDecimal;

      let strikeListVal = `${printFormat(strike, strikeInt, strikeDecimal)},
        ${ApplicationContext.configuration.showCurrencySymbolForNumbers === false ? '' : '$'}
        ${position.isBuyPosition ? printFormat(ask, askInt, askDecimal) : printFormat(bid, bidInt, bidDecimal)},
        ${delta}`;
      let str = getDropdown(strikeListVal);

      let strikeEntry = { strike: strike, strikeStr: str, strikeClass: 'strike-below-last' };

      if (stdDevs) {
        if (lastPrice && lastPrice < strike) {
          strikeEntry.strikeClass = 'strike-over-last';
        }

        if (leftBound && leftBound <= strike && !leftFlag) {
          if (strikeInside1SDforPut(selectedStrategy, position)) {
            //TODO: setting the strike price, we have to update the recoil state to let other components know about it.
            // console.warn(
            //   '//TODO: setting the strike price, we have to update the recoil state to let other components know about it.',
            // );
            //TODO: Why are we changing the strike of position itself. Recoil readonly throws error for it.
            //position.strike = detailList[j].strike;
          }

          formattedList.push({
            strike: strike,
            strikeStr: '-1SD',
            strikeClass: 'strike-of-sd',
          });
          leftFlag = true;
        }

        if (rightBound && rightBound < strike && !rightFlag) {
          if (strikeInside1SDforCall(selectedStrategy, position)) {
            //TODO: setting the strike price, we have to update the recoil state to let other components know about it.
            // console.warn(
            //   '//TODO: setting the strike price, we have to update the recoil state to let other components know about it.',
            // );
            //TODO: Why are we changing the strike of position itself. Recoil readonly throws error for it.
            //position.strike = detailList[j - 1].strike;
          }

          formattedList.push({
            strike: strike,
            strikeStr: '+1SD',
            strikeClass: 'strike-of-sd',
          });
          rightFlag = true;
        }
      }

      formattedList.push(strikeEntry);
    }

    return formattedList;
  };

  const strikeOptionsBackgroundColor = (
    strikeStr: string | JSX.Element,
    positionStrike: number | undefined,
    optionStike: number,
  ) => {
    if (positionStrike && strikeStr && positionStrike === optionStike) {
      return theme.palette.primary.light;
    }
    if (strikeStr === '-1SD' || strikeStr === '+1SD') {
      return theme.palette.primary.light;
    } else {
      return 'transparent';
    }
  };

  const renderStrikes = (position: Position, index: number) => {
    if (isSingleSecurityLeg() && position.isSecurityType()) {
      return null;
    }
    let strikes = convertStrikeList(position) || [];
    return (
      <TableCell sx={{ width: '19%', alignItems: 'center' }}>
        <GuideItem selector=".shadedStrikePrices_helpPinPlaceholder" canShow={index === 0} />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={strikes.find((v) => v.strike === (position.strike as number))}
          onChange={(e: any, value: any) => handelChangeStrike(e.target.value, position)}
          renderValue={(value: any) => value.strike}
          MenuProps={menuHeight}
          disableUnderline>
          {strikes.map((option, index) => {
            return (
              <MenuItem
                autoFocus={option.strike === position.strike}
                key={index}
                value={option.strike}
                sx={{
                  background: strikeOptionsBackgroundColor(option.strikeStr, position.strike, option.strike),
                  justifyContent: 'center',
                  color: theme.palette.text.primary,
                  p: 0,
                }}>
                <Typography>{option.strikeStr}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
    );
  };

  const renderExpiry = (position: Position) => {
    if (isSingleSecurityLeg() && position.isSecurityType()) {
      return null;
    }

    return (
      <TableCell sx={{ width: '34%', p: 0 }}>
        <Autocomplete
          openOnFocus
          disableClearable
          size="small"
          options={expiryList.map((option) => option)}
          value={expiryList.find((v) => v.date === position.expiry)}
          getOptionLabel={(option) => option.dateOnSelector}
          onChange={(e, value: any) => handleChangeExpiry(value, position)}
          PaperComponent={({ children }) => <Paper sx={{ width: 250, minHeight: 'auto' }}>{children}</Paper>}
          isOptionEqualToValue={(option, value) => option.dateOnSelector === value.dateOnSelector}
          renderInput={(params) => (
            <TextField
              autoFocus={false}
              {...params}
              margin="none"
              size="small"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                sx: { pr: 22, width: '100%' },
              }}
              inputProps={{
                ...params.inputProps,
                sx: { textAlign: 'center', textOverflow: 'clip!important', cursor: 'pointer' },
              }}
            />
          )}
        />
      </TableCell>
    );
  };

  const getActionTextColor = (position: Position) => {
    if (position.buyOrSell === BuyOrSell.BUY) {
      return `${theme.palette.legButtons.light}`;
    } else {
      return `${theme.palette.legButtons.dark}`;
    }
  };

  const getLegTypeTextColor = (position: Position) => {
    if (position.type === LegType.CALL) {
      return `${theme.palette.legButtons.light}`;
    } else if (position.type === LegType.PUT) {
      return `${theme.palette.legButtons.dark}`;
    } else {
      return `${theme.palette.text.primary}`;
    }
  };

  const renderActionCell = (position: Position) => {
    return (
      <TableCell
        className="action-cell"
        sx={{
          width: '10%',
          p: 0,
          color: getActionTextColor(position),
        }}
        onClick={() => (combination.isIncome ? null : buySellEvent(position))}>
        {`${t(`how.ediLegsText.prefix.${position.buyOrSell}`)}`}
        {combination.isIncome ? null : <ArrowDropDownIcon sx={{ color: theme.palette.info.light }} />}
      </TableCell>
    );
  };

  const renderLegTyleArrowKey = (positionType: string) => {
    if (positionType.toUpperCase() !== LegType.SECURITY.toString().toUpperCase()) {
      if (!combination.isIncome) {
        return <ArrowDropDownIcon sx={{ color: theme.palette.info.light }} />;
      } else return null;
    } else return null;
  };

  const renderLegTypeCell = (position: Position) => {
    return (
      <TableCell
        className="type-cell"
        onClick={() => (combination.isIncome ? null : callPutType(position))}
        sx={{
          width: '14%',
          color: getLegTypeTextColor(position),
        }}>
        {`${t(`how.combinationTextGenration.prefix.${position.type}`)}`}
        {renderLegTyleArrowKey(position.type)}
      </TableCell>
    );
  };

  const renderStrategyLeg = (position: Position, index: number) => {
    // TODO: check isRoll && combination.isClosePosition(position)

    if (position.isOwned()) {
      return null;
    }

    if (combination.isIncome && position.isSecurityType()) {
      return null;
    }

    return (
      <TableRow key={index} sx={{ width: '100%' }}>
        {renderActionCell(position)}
        <QuantityField
          combination={combination}
          position={position}
          index={index}
          onEditCombinationCallback={onEditCombinationCallback}
        />
        {position.type === LegType.SECURITY ? (
          <TableCell className="type-cell" sx={{ width: '20%' }}></TableCell>
        ) : (
          renderExpiry(position)
        )}
        {position.type === LegType.SECURITY ? (
          <TableCell className="type-cell" sx={{ width: '10%' }}></TableCell>
        ) : (
          renderStrikes(position, index)
        )}
        {renderLegTypeCell(position)}
        <TableCell className="premium-cell" colSpan={4} sx={{ width: '10%' }}>
          <Typography>{NumberFormatHelper.toCurrency(position.price(selectedPrice))}</Typography>
        </TableCell>
        {renderDeleteIcon(position, index)}
      </TableRow>
    );
  };

  const renderDeleteIcon = (position: Position, index: number) => {
    if (combination.isPortfolio) {
      return (
        <TableCell onClick={() => onRemovePosition(position, index)} sx={{ width: '10%' }}>
          <DeleteRoundedIcon
            cursor="pointer"
            sx={{
              '&:hover': {
                color: theme.palette.error.main,
              },
            }}
          />
        </TableCell>
      );
    }
    if (positions.length > 1 && !combination.isIncome) {
      return (
        <TableCell onClick={() => onRemovePosition(position, index)} sx={{ width: '10%' }}>
          <DeleteRoundedIcon
            cursor="pointer"
            sx={{
              '&:hover': {
                color: theme.palette.error.main,
              },
            }}
          />
        </TableCell>
      );
    }
  };

  const renderPositions = () => {
    return positions.map((position, index) => renderStrategyLeg(position, index));
  };

  const renderLegButtons = () => {
    let flag = 0;
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].legType === LegType.SECURITY.toString()) flag++;
      continue;
    }
    if (flag) {
      return (
        <Grid container direction="row" spacing={1}>
          <Grid item justifyContent="center">
            <AddCallWidget combination={combination} callback={onEditCombinationCallback} logActivity={logActivity} />
          </Grid>
          <Grid item justifyContent="center">
            <AddPutWidget combination={combination} callback={onEditCombinationCallback} logActivity={logActivity} />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <>
          <Grid container direction="row" spacing={1}>
            <Grid item justifyContent="center">
              <AddCallWidget combination={combination} callback={onEditCombinationCallback} logActivity={logActivity} />
            </Grid>
            <Grid item justifyContent="center">
              <AddPutWidget combination={combination} callback={onEditCombinationCallback} logActivity={logActivity} />
            </Grid>
            <Grid item justifyContent="center">
              <AddStockWidget
                combination={combination}
                callback={onEditCombinationCallback}
                logActivity={logActivity}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const renderLegControls = () => {
    if (combination.isIncome) {
      return undefined;
    }
    if (!combination.canAddPositions(customization.maximumLegs)) {
      return undefined;
    }
    if ((!combination.chain || combination.chain.rows.length === 0) && combination.positions.length === 1) {
      return;
    }
    return (
      <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center" width="100%">
        <Grid item xs={5}>
          {/*
           * canHideLegButton is used with option legtype as
           * when no option shown then text also should be hidden
           * LegType.CALL or LegType.PUT
           * can be passed for hiding the label
           */}
          <GuideItem selector=".combinationEditorAddLeg_helpPinPlaceholder" />
          {!canHideLegButton(combination, LegType.CALL) && (
            <Typography variant="body1" textAlign="right">
              {t('how.combinationEditor.labels.addLeg')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={7}>
          {renderLegButtons()}
        </Grid>
      </Grid>
    );
  };

  const priceByHandleChange = () => {
    if (priceBy) {
      setPriceBy(0);
      changePrice(PriceCalculationMethod.BID_ASK);
    } else {
      setPriceBy(1);
      changePrice(PriceCalculationMethod.MID);
    }
  };

  const canRollUp = () => {
    return combination.canRoll(0, 1, !isRoll);
  };

  const canRollDown = () => {
    return combination.canRoll(0, -1, !isRoll);
  };

  const canRollOut = () => {
    return combination.canRoll(1, 0, !isRoll);
  };

  const canRollIn = () => {
    return combination.canRoll(-1, 0, !isRoll);
  };

  const renderStrategyConstructorIfPossible = () => {
    return (
      <Grid container alignItems="center" sx={{ p: 0.5 }}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}>
            {renderStrategyConstructor()}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}>
            {renderPriceControls()}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderStrategyConstructor = () => {
    if (!shouldIncludeStrategyConstructors) {
      return null;
    }
    return <StrategyConstructorWidget />;
  };

  const renderPriceControls = () => {
    if (!shouldIncludeStrategyConstructors && !combination.isIncome) {
      return undefined;
    }
    return (
      <>
        <Grid item container xs={3}>
          <Typography variant="body1">{t('how.incomeStrategies.labels.priceBy')}</Typography>
          <GuideItem selector=".priceBy_helpPinPlaceholder" />
        </Grid>
        <Grid item xs={9}>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body1">{t('how.incomeStrategies.labels.bidask')}</Typography>
              </Grid>
              <Grid item>
                <OptionsPlaySwitch
                  value={priceBy}
                  checked={priceBy === 1}
                  onChange={priceByHandleChange}
                  name="checkedC"
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">{t('how.incomeStrategies.labels.mid')}</Typography>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </>
    );
  };

  const renderWidthControlIfPossible = () => {
    if (!shouldIncludeStrategyConstructors) {
      return undefined;
    }
    if (!combination.canCustomizeWidth()) {
      return undefined;
    }
    return (
      <Grid item sx={combination.canCustomizeWidth() ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <GuideItem selector=".combinationEditorWidthAndWingspan_helpPinPlaceholder" />
        <Button
          color="primary"
          variant="outlined"
          startIcon={
            <RemoveIcon
              sx={{
                color: !combination.canShrinkWidth()
                  ? theme.palette.optionGridDisabled.contrastText
                  : theme.palette.info.light,
              }}
              onClick={() => (combination.canShrinkWidth() ? shrinkWidth() : null)}
            />
          }
          endIcon={
            <AddIcon
              sx={{
                color: !combination.canExpandWidth()
                  ? theme.palette.optionGridDisabled.contrastText
                  : theme.palette.info.light,
              }}
              onClick={() => (combination.canExpandWidth() ? expandWidth() : null)}
            />
          }>
          <Typography variant="button">{t('how.combinationEditor.labels.width')}</Typography>
        </Button>
      </Grid>
    );
  };

  const renderWingSpanControlIfPossible = () => {
    if (!shouldIncludeStrategyConstructors) {
      return undefined;
    }
    if (!combination.canCustomizeWingspan()) {
      return undefined;
    }
    return (
      <Grid item sx={combination.canCustomizeWingspan() ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <Button
          color="primary"
          variant="outlined"
          startIcon={
            <RemoveIcon
              sx={{
                color: !combination.canShrinkWingspan()
                  ? theme.palette.optionGridDisabled.contrastText
                  : theme.palette.info.light,
              }}
              onClick={() => (combination.canShrinkWingspan() ? shrinkWingspan() : null)}
            />
          }
          endIcon={
            <AddIcon
              sx={{
                color: !combination.canExpandWingspan()
                  ? theme.palette.optionGridDisabled.contrastText
                  : theme.palette.info.light,
              }}
              onClick={() => (combination.canExpandWingspan() ? expandWingspan() : null)}
            />
          }>
          <Typography variant="button">{t('how.combinationEditor.labels.wingspan')}</Typography>
        </Button>
      </Grid>
    );
  };

  const renderInformationAboutStrategies = () => {
    if (!customization.showStratgiesInformation) {
      return null;
    }
    return (
      <Grid container justifyContent={'center'}>
        <Typography variant="button" textAlign={'center'} sx={{ p: 1 }}>
          <a href="/strategies-information.html" target="_blank" data-bind="href: redirectUrl">
            <span style={{ color: 'rgb(71, 144, 199)' }}>
              {t('how.toolbox.combinationEditor.labels.strategyDesctiptions')}
            </span>
          </a>
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid container sx={{ pb: 0.5 }}>
      {renderStrategyConstructorIfPossible()}
      {renderInformationAboutStrategies()}
      <Grid container>
        <Grid item xs={12}>
          <CombinationEditorTable>
            <TableContainer sx={{ height: '100%', overflowY: 'hidden' }}>
              <Table aria-label="legs">
                <TableHead sx={{ width: '100%' }}>
                  <TableRow sx={{ background: '#D8D8D8', width: '100%' }}>
                    <TableCell sx={{ width: '10%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <GuideItem selector=".combinationEditorActions_helpPinPlaceholder" />
                        {t('how.combinationEditor.labels.action')}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: '17%' }}>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowLeftIcon
                          sx={!canDecreaseQuantity() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                          onClick={() => quantityChange(false)}
                        />
                        <GuideItem selector=".combinationEditorQuantity_helpPinPlaceholder" />
                        {t('how.combinationEditor.labels.quantity')}
                        <ArrowRightIcon
                          sx={!canIncreaseQuantity() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                          onClick={() => quantityChange(true)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: '30%' }}>
                      <GuideItem selector=".combinationEditorExpiry_helpPinPlaceholder" />
                      <ArrowLeftIcon
                        sx={!canRollIn() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        onClick={() => expiryChange(false)}
                      />
                      {t('common.labels.expiry')}
                      <ArrowRightIcon
                        sx={!canRollOut() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        onClick={() => expiryChange(true)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '25%' }}>
                      <GuideItem selector=".combinationEditorStrike_helpPinPlaceholder" />
                      <ArrowLeftIcon
                        sx={!canRollDown() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        onClick={() => strikeChange(false)}
                      />
                      {t('how.combinationEditor.labels.strike')}
                      <ArrowRightIcon
                        sx={!canRollUp() ? { visibility: 'hidden' } : { visibility: 'visible' }}
                        onClick={() => strikeChange(true)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: '15%' }}>
                      <GuideItem selector=".combinationEditorEype_helpPinPlaceholder" />
                      {t('how.combinationEditor.labels.type')}
                    </TableCell>
                    <TableCell sx={{ width: '25%' }} colSpan={positions?.length >= 1 ? 6 : 0}>
                      <GuideItem selector=".combinationEditorPremium_helpPinPlaceholder" />
                      {t('how.combinationEditor.labels.premium')}
                    </TableCell>
                    {/* {positions.length > 1 && !combination.isIncome ? <TableCell sx={{ width: '10%' }}></TableCell> : null} */}
                  </TableRow>
                </TableHead>
                <TableBody>{renderPositions()}</TableBody>
              </Table>
            </TableContainer>
          </CombinationEditorTable>
        </Grid>
        <Grid container sx={{ justifyContent: 'center', p: 1 }}>
          {renderLegControls()}
        </Grid>
      </Grid>
      <Grid item xs={12} container sx={{ justifyContent: 'center' }}>
        <Grid container direction="row" spacing={1} justifyContent="center">
          {combination.isIncome || combination.isPortfolio ? undefined : (
            <Grid item>
              <Button variant="contained" onClick={flipBS}>
                <Typography variant="button">{t('how.combinationEditor.buttons.flipStrategy')}</Typography>
              </Button>
            </Grid>
          )}
          <Grid item>
            <GuideItem selector=".combinationEditorResetLegs_helpPinPlaceholder" />
            <Button color="primary" variant="outlined" onClick={reset}>
              <Typography variant="button">{t('how.combinationEditor.buttons.resetLegs')}</Typography>
            </Button>
          </Grid>
          <Grid item>{renderWidthControlIfPossible()}</Grid>
          <Grid item>{renderWingSpanControlIfPossible()}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
