import { ValidationErrors } from './validation';

export class FileSaveResponse {
  isFileSaved: boolean = false;
  validationErrors: ValidationErrors[] = [];

  public static fromJson = (data: FileSaveResponse): FileSaveResponse => {
    const model = new FileSaveResponse();
    model.isFileSaved = data.isFileSaved;
    model.validationErrors = data.validationErrors;
    return model;
  };
}
