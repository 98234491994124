import { IYesterdayQuote } from '.';

export class YesterdayQuote implements IYesterdayQuote {
  symbol = '';
  name = '';
  last = -1;
  change = -1;
  percentageChange = -1;
  volume = -1;
  week52Low = -1;
  week52High = -1;
  div = -1;
  yield = -1;
  eps = -1;
  beta = -1;
  peRatio = -1;
  ask = -1;
  bid = -1;

  public static fromText = (data: YesterdayQuote): YesterdayQuote => {
    const value = new YesterdayQuote();
    value.symbol = data.symbol;
    value.name = data.name;
    value.last = data.last;
    value.change = data.change;
    value.percentageChange = data.percentageChange;
    value.volume = data.volume;
    value.week52Low = data.week52Low;
    value.week52High = data.week52High;
    value.div = data.div;
    value.yield = data.yield;
    value.eps = data.eps;
    value.beta = data.beta;
    value.peRatio = data.peRatio;
    value.ask = data.ask;
    value.bid = data.bid;
    return value;
  };
}
