import { Box, Button, Grid, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { Aggressiveness, TimeFrame } from '@op/shared/src/models/enums/enums';
import { IBuilderData, ICallOptimal, IncomeBuilder } from '@op/shared/src/models/how';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { CoveredCallExtensions } from '@op/shared/src/models/how/coveredCallExtensions';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import { configurationState, howDataState, incomeCombinationSelectedIdState } from '@op/shared/src/states';
import {
  incomeCombinationAtomFamilyState,
  resetIncomeState,
  selectedCombinationPriorityState,
  selectedOptionGridState,
} from '@op/shared/src/states/how/income-how-states';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconComponent } from '../common';
const barSize = 12;

export const RiskToleranceBar = styled(Box)(() => ({
  width: barSize,
  height: '100%',
  borderRadius: 3,
}));

export const TimeFrameBlueBar = styled(Box)(({ color }) => ({
  width: '100%',
  borderRadius: 3,
  background: `${color}`,
  height: barSize,
}));

export const OptionGridIconButton = styled('div')(() => ({
  position: 'absolute',
  top: '-6px',
  right: '8px',
  height: '10px',
  width: '10px',
}));

export const OptionGridColumnTitle = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '35px',
}));

export const OptionGridWidget: React.FC<{}> = () => {
  const howData = useRecoilValue(howDataState);
  const configuration = useRecoilValue(configurationState);
  const incomeCombinationSelectedId = useRecoilValue(incomeCombinationSelectedIdState);
  const resetIncome = useRecoilValue(resetIncomeState);
  const [combination, setCombination] = useRecoilState(
    incomeCombinationAtomFamilyState(incomeCombinationSelectedId.toString()),
  );
  const [priority, setPriority] = useRecoilState(selectedCombinationPriorityState(incomeCombinationSelectedId));
  const [selectedOption, setSelectedOption] = useRecoilState(selectedOptionGridState(incomeCombinationSelectedId));
  const aggressivesses = [Aggressiveness.CONSERVATIVE, Aggressiveness.OPTIMAL, Aggressiveness.AGGRESSIVE];
  const timeframes = [TimeFrame.SHORT, TimeFrame.MEDIUM, TimeFrame.LONG];
  const theme = useTheme();

  const isCall = incomeCombinationSelectedId === 0;
  const noneAvailable = 'None Available';

  const getOption = (timeframe: string, aggressive: string) => {
    if (!howData) {
      return undefined;
    }
    const optimals = isCall ? howData.callOptimals : howData.putOptimals;
    return optimals.find((option) => option.timeFrame === timeframe && option.aggressiveness === aggressive);
  };

  const getOptionByPriority = (priority: number) => {
    if (!howData) {
      return undefined;
    }
    const optimals = isCall ? howData.callOptimals : howData.putOptimals;
    return optimals.find((option) => option.priority === priority);
  };

  useEffect(() => {
    if (!howData || !configuration) {
      return;
    }
    // if (!resetIncome.shouldReset) {
    //   return;
    // }
    // const config = configuration.applicationConfiguration.coveredCall;
    // const optimalConfig = isCall ? config.call : config.put;
    let optimal = getOptionByPriority(priority || -1); //getOption(optimalConfig.timeFrame, optimalConfig.aggressiveness);
    if (!optimal) {
      optimal = getOptionByPriority(priority || -1);
    }
    //TODO: if optimal not found then look for alternative.
    setSelectedOption(optimal);
  }, [howData, configuration, priority]);

  if (!combination || !howData || !configuration) {
    return null;
  }

  // const mappings = configuration.coveredCallOptimalPriorityMapping;
  const config = configuration.applicationConfiguration.coveredCall;
  const optimalSettingOfUser = isCall ? config.call : config.put;
  const optimals = isCall ? howData.callOptimals : howData.putOptimals;
  const optimal = getOption(optimalSettingOfUser.timeFrame, optimalSettingOfUser.aggressiveness);

  const getUserPriority = () => {
    const _priorities = configuration.coveredCallOptimalPriorityMapping;
    if (!_priorities) {
      throw new Error('Priorities are undefined. Consider configuring by `withPriorities`');
    }
    const optimal = _priorities.find(
      (op) =>
        op.timeFrame === optimalSettingOfUser.timeFrame && op.aggressiveness === optimalSettingOfUser.aggressiveness,
    );
    return optimal?.priority || -1;
  };

  const getUserAltPriority = () => {
    if (optimal) {
      return -1;
    }
    const userPriority = getUserPriority();
    const altOptimal = CoveredCallExtensions.findOptimal(optimals, userPriority);
    if (!altOptimal) {
      return -1;
    }
    return altOptimal.priority;
  };

  const getName = (option: ICallOptimal | undefined) => {
    if (option === undefined) {
      return undefined;
    }
    const date = DateTimeHelper.resolveDate(option.expiry);
    const expiry = DateTimeHelper.format(date);
    const name = [expiry, option.strike, option.legType].join(' ');
    return name;
  };

  const onButtonPressed = (option: ICallOptimal | undefined) => {
    if (!option) {
      return;
    }
    const timeframe = option.timeFrame as 'Short' | 'Medium' | 'Long';
    const aggressive = option.aggressiveness as 'Conservative' | 'Optimal' | 'Aggressive';
    const securityPosition = combination?.positions.find((p) => p.isSecurityType());
    const shares = !securityPosition ? 100 : securityPosition.absQuantity;
    const costBasis = combination.costBasis();
    const priorities = ApplicationContext.configuration.coveredCallOptimalPriorityMapping;
    const builder = new IncomeBuilder(howData as IBuilderData)
      .withShares(shares)
      .withCostBasisPerUnit(costBasis)
      .withTimeframe(timeframe)
      .withAggressiveness(aggressive)
      .withPriorities(priorities);
    const strategy = isCall
      ? builder.withOptimals(howData.callOptimals).buildCall()
      : builder.withOptimals(howData.putOptimals).buildPut();
    setSelectedOption(option);
    setCombination(strategy);
    setPriority(strategy?.priority);
  };

  /*
   * An option is optimal, if the timeframe and aggresiveness matches.
   * Also the priority of this optimal is save as generated combination.
   */
  const isOptimal = (option: ICallOptimal | undefined, timeframe: string, aggressive: string) => {
    if (!option) {
      return false;
    }
    if (option.priority !== getUserPriority()) {
      return false;
    }
    // if (option.priority !== priority) {
    //   return false;
    // }
    // if (timeframe !== optimalSettingOfUser.timeFrame) {
    //   return false;
    // }
    // if (aggressive !== optimalSettingOfUser.aggressiveness) {
    //   return false;
    // }
    return true;
  };

  /*
   * Since at time of combination geneation is not tracked separately,
   * If the option is not optical and combination priorities matches then it altOptical.
   * Note it has nothing to do with `alternativeCall` data points of howData
   */
  const isAltOptimal = (option: ICallOptimal | undefined, timeframe: string, aggressive: string) => {
    if (!option) {
      return false;
    }
    if (option.priority !== getUserAltPriority()) {
      return false;
    }
    // if (isOptimal(option, timeframe, aggressive)) {
    //   return false;
    // }
    // if (option.priority !== priority) {
    //   return false;
    // }
    return true;
  };

  const renderIcon = (option: ICallOptimal | undefined, timeframe: string, aggressive: string) => {
    if (isOptimal(option, timeframe, aggressive)) {
      return (
        <OptionGridIconButton>
          <IconComponent
            name="optionsGridTick"
            fill={theme.palette.success.main}
            stroke={theme.palette.background.default}
          />
        </OptionGridIconButton>
      );
    }
    //If optimal exist, do not render altOptimcal icon.
    if (isAltOptimal(option, timeframe, aggressive)) {
      return (
        <OptionGridIconButton>
          <IconComponent
            name="optionsGridWarning"
            fill={theme.palette.error.main}
            stroke={theme.palette.background.default}
          />
        </OptionGridIconButton>
      );
    }
    return null;
  };

  const getTooltip = (option: ICallOptimal | undefined, timeframe: string, aggressive: string) => {
    if (isOptimal(option, timeframe, aggressive)) {
      return `Matches your Income Settings.`;
    }
    if (isAltOptimal(option, timeframe, aggressive)) {
      return `We didn't find your Option Call.This is the closest one.`;
    }
    return '';
  };

  const optionGridTextColor = (name: string, isActive: boolean) => {
    if (name === noneAvailable) {
      return theme.palette.optionGridDisabled.contrastText;
    }
    if (isActive) {
      return theme.palette.selection.contrastText;
    }
    return theme.palette.text.primary;
  };

  const renderOptions = (timeframe: string, aggressive: string) => {
    const option = getOption(timeframe, aggressive);
    const name = getName(option) || noneAvailable;
    const isActive = option && selectedOption === option;
    const border = isActive ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.secondary.dark}`;
    const background = isActive ? theme.palette.selection.main : theme.palette.info.dark;

    return (
      <Tooltip title={getTooltip(option, timeframe, aggressive)} placement="top" arrow>
        <Button
          variant="contained"
          sx={{
            px: 0,
            py: 1,
            whiteSpace: 'nowrap',
            backgroundColor: background,
            border: border,
            '&:focus': {
              boxShadow: `0 1px 9px 3px ${theme.palette.primary.light}`,
              backgroundColor: background,
            },
            '&:hover': {
              boxShadow: `0 1px 9px 3px ${theme.palette.primary.light}`,
              backgroundColor: background,
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.optionGridDisabled.main,
              borderColor: theme.palette.optionGridDisabled.light,
            },
          }}
          disableElevation={!isActive}
          disabled={name === noneAvailable}
          fullWidth
          onClick={() => onButtonPressed(option)}
          endIcon={renderIcon(option, timeframe, aggressive)}>
          <Typography variant="body1" color={optionGridTextColor(name, isActive)}>
            {name}
          </Typography>
        </Button>
      </Tooltip>
    );
  };

  const getBackgroundColor = (aggressiveNess: Aggressiveness) => {
    if (aggressiveNess.toString().toUpperCase() === Aggressiveness.AGGRESSIVE.toUpperCase()) {
      return theme.palette.tolerance.dark;
    } else if (aggressiveNess.toString().toUpperCase() === Aggressiveness.OPTIMAL.toUpperCase()) {
      return theme.palette.tolerance.main;
    } else {
      return theme.palette.tolerance.light;
    }
  };

  const getToleranceBarTooltip = (aggressive: Aggressiveness) => {
    if (aggressive.toString().toUpperCase() === Aggressiveness.AGGRESSIVE.toUpperCase()) {
      return Aggressiveness.AGGRESSIVE;
    } else if (aggressive.toString().toUpperCase() === Aggressiveness.OPTIMAL.toUpperCase()) {
      return Aggressiveness.OPTIMAL;
    } else {
      return Aggressiveness.CONSERVATIVE;
    }
  };

  const renderDays = () => {
    return (
      <Grid container pl={6} columnSpacing={1}>
        <Grid item xs={4}>
          <OptionGridColumnTitle>
            <Typography variant="body1" textAlign="center">
              {`${optimalSettingOfUser.shortTimeFrameLowerBound}-${optimalSettingOfUser.shortTimeFrameHigherBound} days`}
            </Typography>
          </OptionGridColumnTitle>
          <TimeFrameBlueBar color={theme.palette.timeFrame.light} />
        </Grid>
        <Grid item xs={4}>
          <OptionGridColumnTitle>
            <Typography variant="body1" textAlign="center">
              {`${optimalSettingOfUser.mediumTimeFrameLowerBound}-${optimalSettingOfUser.mediumTimeFrameHigherBound} days`}
            </Typography>
          </OptionGridColumnTitle>
          <TimeFrameBlueBar color={theme.palette.timeFrame.main} />
        </Grid>
        <Grid item xs={4}>
          <OptionGridColumnTitle>
            <Typography variant="body1" textAlign="center">
              {`${optimalSettingOfUser.longTimeFrameLowerBound}-${optimalSettingOfUser.longTimeFrameHigherBound} days`}
            </Typography>
          </OptionGridColumnTitle>
          <TimeFrameBlueBar color={theme.palette.timeFrame.dark} />
        </Grid>
      </Grid>
    );
  };

  const renderToleranceAndTimeframeGrid = () => {
    return aggressivesses.map((aggressive, index) => (
      <Grid container key={index} columnSpacing={1} mb={1}>
        <Grid item xs="auto" className="riskTolerance">
          <Tooltip title={getToleranceBarTooltip(aggressive)} placement="right-end" arrow>
            <RiskToleranceBar sx={{ backgroundColor: getBackgroundColor(aggressive) }} />
          </Tooltip>
        </Grid>
        <Grid item xs container columnSpacing={1} ml={0}>
          {timeframes.map((timeframe) => (
            <Grid item xs={4} key={`${timeframe}-${index}`}>
              {renderOptions(timeframe, aggressive)}
            </Grid>
          ))}
        </Grid>
      </Grid>
    ));
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 1 }}>
        {renderDays()}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs="auto" sx={{ pt: 2.4 }}>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              WebkitWritingMode: 'vertical-rl',
              msWritingMode: 'vertical-rl',
              writingMode: 'vertical-rl',
              transform: 'rotate(-180deg)',
              width: 20, //do not remove this width. It is required for safari browser
            }}>
            Risk Tolerance
          </Typography>
        </Grid>
        <Grid item xs>
          {renderToleranceAndTimeframeGrid()}
        </Grid>
      </Grid>
    </Grid>
  );
};
