import { Grid, useTheme } from '@mui/material';
import { customizationState, howWhyRanksDataState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { SentimentLongTermWidget, SentimentShortTermWidget, TrendingWidget } from '../common';
import { HeightWidget } from '../routes';
import { BetaWidget } from './components/beta-widget';
import { DivYieldWidget } from './components/div-yield-widget';
import { EarningsDateWidget } from './components/earnings-date-widget';
import { EpsWidget } from './components/eps-widget';
import { VolumeWidget } from './components/volume-widget';
import { WeekRangeWidget } from './components/week-range';
import { QuotebarSearchWidget } from './quote-bar-search-widget';
import { GuideItem } from '../side-menu';

export const QuotebarVariant2Widget: React.FC = () => {
  const theme = useTheme();
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);
  const customization = useRecoilValue(customizationState);
  if (!howWhyRanksData.how || !howWhyRanksData.symbol || !customization) {
    return null;
  }
  const symbolWidgetHeight = customization.isMerrill ? 100 : 125;

  return (
    <HeightWidget id="symbol-widget-content" className="quotebar-variant2" height={symbolWidgetHeight} noScroll>
      <TrendingWidget sentiment={howWhyRanksData.how.originalSentiment} symbol={howWhyRanksData.symbol || ''}>
        <Grid container rowSpacing={0.5}>
          <GuideItem selector=".symbolInput_helpPinPlaceholder" />
          <Grid item xs={12}>
            <QuotebarSearchWidget />
          </Grid>
          <Grid item container xs={12} pt={0.5}>
            <Grid item xs={4} sx={{ borderRight: `1px solid ${theme.palette.divider}`, pr: 1 }} id="quotebar-section-1">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <WeekRangeWidget />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <DivYieldWidget />
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ borderRight: `1px solid ${theme.palette.divider}`, pl: 1, pr: 1 }}
              id="quotebar-section-2">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <VolumeWidget />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <EpsWidget />
              </Grid>
              {!customization.isMerrill && (
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                  <EarningsDateWidget />
                </Grid>
              )}
            </Grid>
            <Grid item xs={4} sx={{ pl: 1, pr: 1 }} id="quotebar-section-3">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <GuideItem selector=".termAnalysis_helpPinPlaceholder" />
                <SentimentShortTermWidget isQuoteBar symbol={howWhyRanksData.symbol} />
                <SentimentLongTermWidget isQuoteBar symbol={howWhyRanksData.symbol} />
              </Grid>
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <BetaWidget />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TrendingWidget>
    </HeightWidget>
  );
};
