export interface ISignUpModelProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  stateOrProvince: string;
  privacyPolicyAccepted: boolean;
}

export class SignUpModel implements ISignUpModelProps {
  firstName = '';
  lastName = '';
  email = '';
  password = '';
  confirmPassword = '';
  stateOrProvince = '';
  privacyPolicyAccepted = false;

  public static fromJson = (json: ISignUpModelProps): SignUpModel => {
    const signup = new SignUpModel();
    signup.firstName = json.firstName;
    signup.lastName = json.lastName;
    signup.email = json.email;
    signup.password = json.password;
    signup.confirmPassword = json.confirmPassword;
    signup.stateOrProvince = json.stateOrProvince || '';
    signup.privacyPolicyAccepted = json.privacyPolicyAccepted;
    return signup;
  };
}
