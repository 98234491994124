import { atom, selector } from 'recoil';
import { guardRecoilDefaultValue, sortsTradesState } from '..';
import { IFilter2, IIdea, sortedTradeIdeas, Trades } from '../../models';

import Fuse from 'fuse.js';
import lodash from 'lodash';
import { IFilterGroup } from '../../models/what/i-filter-group';
import { FuseConfig } from '../configuration/fuse-search-config';

/* =================== FILTERS ================= */

const BILLION = 1000000000;
const sentimentFilter = {
  title: 'Sentiment',
  name: 'sentiment',
  translationKey: 'what.tradeIdeas.ideas.sentiment',
  filters: [
    {
      title: 'Bullish',
      name: 'bullish',
      translationKey: 'what.tradeIdeas.ideas.bullish',
      value: 'Bullish',
      selected: true,
    },
    {
      title: 'Bearish',
      name: 'bearish',
      translationKey: 'what.tradeIdeas.ideas.bearish',
      value: 'Bearish',
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterBySentiment = (groups: IFilterGroup[], data: IIdea[]) => {
  if (!data) {
    return;
  }
  const group = groups.find((g) => g.name === 'sentiment');
  if (!group) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const filter of group.filters) {
    if (!filter.selected) {
      continue;
    }
    if (filter.name === 'all') {
      filtered = data;
      continue;
    }
    const items = data.filter((d) => d.sentiment === filter.value);
    filtered = filtered.concat(items);
  }
  return filtered;
};

const MarketCapFilter = {
  title: 'Cap Size',
  name: 'marketCap',
  translationKey: 'what.tradeIdeas.ideas.capSize',
  filters: [
    {
      title: 'Small Cap',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.smallCap',
      value: 2 * BILLION,
      selected: true,
    },
    {
      title: 'Medium Cap',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.mediumCap',
      value: 10 * BILLION,
      selected: true,
    },
    {
      title: 'Large Cap',
      name: 'range3',
      translationKey: 'what.tradeIdeas.ideas.largeCap',
      value: 200 * BILLION,
      selected: true,
    },
    {
      title: 'Mega Cap',
      name: 'range4',
      translationKey: 'what.tradeIdeas.ideas.megaCap',
      value: Infinity,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByMarketCap = (groups: IFilterGroup[], data: IIdea[]) => {
  if (!data) {
    return data;
  }
  const group = groups.find((g) => g.name === 'marketCap');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    if (f.name === 'range1') {
      const items = data.filter(
        (t) => t.marketCap.valueOf() > -Infinity && t.marketCap.valueOf() < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range2') {
      const items = data.filter(
        (t) => t.marketCap.valueOf() >= 2 * BILLION && t.marketCap.valueOf() < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range3') {
      const items = data.filter(
        (t) => t.marketCap.valueOf() >= 10 * BILLION && t.marketCap.valueOf() < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
    if (f.name === 'range4') {
      const items = data.filter(
        (t) => t.marketCap.valueOf() >= 200 * BILLION && t.marketCap.valueOf() < (f.value as number),
      );
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const IvRankFilter = {
  title: 'IV Rank',
  name: 'ivRank',
  translationKey: 'what.tradeIdeas.ideas.ivRank',
  filters: [
    {
      title: 'Less Than 50',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.lessThan50',
      value: 50,
      selected: true,
    },
    {
      title: 'Greater Than Or Equal To 50',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.greaterEqualTo50',
      value: 50,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByIVRank = (groups: IFilterGroup[], data: IIdea[]) => {
  if (!data) {
    return data;
  }
  const group = groups.find((g) => g.name === 'ivRank');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    if (f.name === 'range1') {
      const items = data.filter((t) => {
        return Math.round(t.impliedVolatilityRank) < 50;
        // const signal = t.signals?.find((s) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase());
        // if (signal) {
        //   return Math.round(signal.value) < 50;
        // }
      });
      filtered = filtered.concat(items);
    }
    if (f.name === 'range2') {
      const items = data.filter((t) => {
        return Math.round(t.impliedVolatilityRank) >= 50;
        // const signal = t.signals?.find((s) => s?.name?.toUpperCase() === 'ImpliedVolatilityRank'.toUpperCase());
        // if (signal) {
        //   return Math.round(signal.value) >= 50;
        // }
      });
      filtered = filtered.concat(items);
    }
  }
  return filtered;
};

const liquidityFilter = {
  title: 'Liquidity',
  name: 'liquidity',
  translationKey: 'what.tradeIdeas.ideas.liquidity',
  filters: [
    {
      title: 'Not Liquid',
      name: 'range1',
      translationKey: 'what.tradeIdeas.ideas.notLiquid',
      value: 3,
      selected: true,
    },
    {
      title: 'Somewhat Liquid',
      name: 'range2',
      translationKey: 'what.tradeIdeas.ideas.somewhatLiquid',
      value: 2,
      selected: true,
    },
    {
      title: 'Very Liquid',
      name: 'range3',
      translationKey: 'what.tradeIdeas.ideas.veryLiquid',
      value: 1,
      selected: true,
    },
  ] as IFilter2[],
} as IFilterGroup;

const filterByLiquidity = (groups: IFilterGroup[], data: IIdea[]) => {
  const group = groups.find((g) => g.name === 'liquidity');
  if (!group) {
    return data;
  }
  const isAllSelected = group.filters.every((f) => f.selected);
  if (isAllSelected) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    const items = data.filter((t) => {
      if (t.liquidityRank > 0) {
        return Math.round(t.liquidityRank) === (f.value as number);
      }
      return t;
      // const signal = t.signals?.find((s) => s?.name?.toUpperCase() === 'Liquidity'.toUpperCase());
      // if (signal) {
      //   return signal.value === (f.value as number);
      // }
    });
    filtered = filtered.concat(items);
  }
  return filtered;
};

const filterBySector = (groups: IFilterGroup[], data: IIdea[]) => {
  if (!data) {
    return data;
  }
  const group = groups && groups.find((g) => g.name === 'sector');
  if (!group) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    const items =
      f.name === 'allSectors'
        ? data
        : data.filter((t) => t.sector && t.sector.toLowerCase().trim() == f.value.toString().toLowerCase().trim());
    filtered = filtered.concat(items);
  }
  return filtered;
};

const filterByScan = (groups: IFilterGroup[], data: IIdea[]) => {
  if (!data) {
    return data;
  }
  const group = groups.find((g) => g.name === 'scan');
  if (!group) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    if (!f.selected) {
      continue;
    }
    const items = data.filter((t) => t?.rules.some((rule) => rule.reason === f.value));
    filtered = filtered.concat(items);
  }
  const uniqueFiltered = lodash.uniqBy(filtered, 'symbol');
  return uniqueFiltered;
};

const linkedPortfoliosFilter = {
  name: 'linkedPortfolios',
  title: 'Portfolio',
  translationKey: 'what.tradeIdeas.ideas.availableInPortfolio',
  filters: [
    {
      name: 'linkedPortfolios',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedPortfolios = (groups: IFilterGroup[], data: IIdea[]) => {
  const group = groups.find((g) => g.name === 'linkedPortfolios');
  if (!group) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.filter((t) => t.portfolios.length > 0) : data;
    filtered = filtered.concat(items);
  }
  return filtered;
};

const linkedWatchListsFilter = {
  name: 'linkedWatchLists',
  title: 'Watchlist',
  translationKey: 'what.tradeIdeas.ideas.availableInWatchlist',
  filters: [
    {
      name: 'linkedWatchLists',
      value: 0,
      title: '',
      selected: false,
    },
  ],
} as IFilterGroup;

const filterByLinkedWatchLists = (groups: IFilterGroup[], data: IIdea[]) => {
  const group = groups.find((g) => g.name === 'linkedWatchLists');
  if (!group) {
    return data;
  }
  let filtered: IIdea[] = [];
  for (const f of group.filters) {
    const items = f.selected ? data.filter((t) => t.watchlists.length > 0) : data;
    filtered = filtered.concat(items);
  }
  return filtered;
};

export let technicalIdeaFilters = [
  sentimentFilter,
  MarketCapFilter,
  IvRankFilter,
  liquidityFilter,
  linkedPortfoliosFilter,
  linkedWatchListsFilter,
] as IFilterGroup[];

/* END of Filters */

export const tradesCachedState = atom<Trades | undefined>({
  key: 'tradesState',
  default: undefined,
});

export const reportSearchState = atom({
  key: 'reportSearchStateKey',
  default: '',
});

export const ideaMoreDetailsState = atom({
  key: 'ideaMoreDetailsStateKey',
  default: false,
});
/**
 * filtersTradesNewState renmae
 */
export const filtersTradesNewState = atom({
  key: 'filtersTradesStateKey',
  default: technicalIdeaFilters,
});

export const sectorFilterState = atom<IFilterGroup | undefined>({
  key: 'sectorFilterStateKey',
  default: undefined,
});

export const scanFilterState = atom<IFilterGroup | undefined>({
  key: 'scanFilterState1Key',
  default: undefined,
});

export const tradesState = selector<IIdea[] | undefined>({
  key: 'tradesFiltersState',
  get: ({ get }) => {
    const ideas = get(tradesCachedState);
    const filters = get(filtersTradesNewState);
    const sorts = get(sortsTradesState);
    const query = get(reportSearchState);
    const sectors = get(sectorFilterState);
    const scans = get(scanFilterState);
    if (!ideas) {
      return undefined;
    }
    const clone = ideas.tradeIdeas;
    let ids = filterBySentiment(filters, clone);
    ids = filterByMarketCap(filters, ids);
    ids = filterByIVRank(filters, ids);
    ids = filterByLiquidity(filters, ids);
    ids = filterByLinkedPortfolios(filters, ids);
    ids = filterByLinkedWatchLists(filters, ids);
    if (sectors) {
      ids = filterBySector([sectors], ids);
    }
    if (scans) {
      ids = filterByScan([scans], ids);
    }
    const fuse = new Fuse(ids, {
      ...FuseConfig,
      keys: ['symbol', 'companyName'],
    });
    let items = query.trim() !== '' ? fuse.search(query).map((i) => i.item) : ids;
    const searchedFilteredIdeas = [...items];
    const sortedTrades = sortedTradeIdeas(searchedFilteredIdeas, sorts); //searchedFilteredIdeas.sort(sorts);
    return sortedTrades;
  },
});

export const tradesSetState = selector<Trades | undefined>({
  key: 'tradesSetState',
  get: ({ get }) => {
    throw new Error('newTrades in not compatible type');
  },
  set: ({ set }, newTrades) => {
    if (guardRecoilDefaultValue(newTrades)) {
      throw new Error('newTrades in not compatible type');
    }
    set(tradesCachedState, newTrades);
  },
});

// export const tradesCountState = selector<number>({
//   key: 'tradesCountState',
//   get: ({ get }) => {
//     const trades = get(tradesState);
//     if (!trades) {
//       return 0;
//     }
//     return trades.totalIdeas;
//   },
// });

export const tradePortfolioPositionsCountState = selector<number>({
  key: 'tradePortfolioPositionsCountState',
  get: ({ get }) => {
    const trades = get(tradesState);
    if (!trades) {
      return 0;
    }
    const items = trades;
    const count = items
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
    return count;
  },
});

export const tradeWatchlistQuotesCountState = selector<number>({
  key: 'tradeWatchlistQuotesCountState',
  get: ({ get }) => {
    const trades = get(tradesState);
    if (!trades) {
      return 0;
    }
    const items = trades;
    const count = items
      .filter((e) => e.watchlists && e.watchlists.length > 0)
      .reduce((a, i) => a + i.watchlists.length, 0);
    return count;
  },
});

/*=================== HELPERS ==================== */
export const prepareSectors = (sectors: string[]) => {
  const data: IFilter2[] = [
    {
      name: 'allSectors',
      title: 'All Sectors',
      value: 'All Sectors',
      selected: true,
    } as IFilter2,
  ];
  for (const sector of sectors) {
    const f = {
      name: sector.toLowerCase(),
      title: sector,
      value: sector,
      selected: false,
    } as IFilter2;
    data.push(f);
  }
  return {
    filters: data,
    name: 'sector',
    title: 'Sector',
    translationKey: 'what.tradeIdeas.ideas.sector',
  } as IFilterGroup;
};

export const prepareScans = (scans: string[]) => {
  const data: IFilter2[] = [];
  for (const scan of scans) {
    const f = {
      name: scan.toLowerCase(),
      title: scan,
      value: scan,
      selected: true,
    } as IFilter2;
    data.push(f);
  }
  return {
    name: 'scan',
    title: 'Scan',
    translationKey: 'what.tradeIdeas.ideas.scan',
    filters: data,
  } as IFilterGroup;
};
