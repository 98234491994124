import { OptionsPlayBreakpoints, fontSizeMediaQuery } from './options-play-breakpoints';
import { ThemeOptions } from '@mui/material';

//TODO: Extract common settings like Typography, shape, breakpoints
declare module '@mui/material/styles' {
  interface Palette {
    timeFrame: Palette['primary'];
    tolerance: Palette['primary'];
    collapsible: Palette['primary'];
    selectAndTextField: Palette['primary'];
    selection: Palette['primary'];
    globalSelect: Palette['primary'];
    personalSelect: Palette['primary'];
    legButtons: Palette['primary'];
    orangeButton: Palette['primary'];
    symbolCard: Palette['background'];
    header: Palette['background'];
    highChart?: Palette['primary'];
    slider?: Palette['primary'];
    optionGridDisabled?: Palette['primary'];
    greyShades: Palette['primary'];
  }
  interface PaletteOptions {
    timeFrame?: PaletteOptions['primary'];
    tolerance?: PaletteOptions['primary'];
    collapsible?: PaletteOptions['primary'];
    selectAndTextField?: PaletteOptions['primary'];
    selection?: PaletteOptions['primary'];
    globalSelect: PaletteOptions['primary'];
    personalSelect: PaletteOptions['primary'];
    legButtons?: PaletteOptions['primary'];
    orangeButton?: PaletteOptions['primary'];
    symbolCard?: PaletteOptions['background'];
    header?: PaletteOptions['background'];
    highChart?: PaletteOptions['primary'];
    slider?: PaletteOptions['primary'];
    optionGridDisabled?: PaletteOptions['primary'];
    greyShades?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    captionSmall: React.CSSProperties;
    subHeader: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionSmall?: React.CSSProperties;
    subHeader?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionSmall: true;
    subHeader: true;
  }
}

export const nasdaqTheme: ThemeOptions = {
  breakpoints: {
    values: OptionsPlayBreakpoints,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0092bc',
      light: '#99B9EF',
      dark: 'rgba(0, 0, 0, 0.54)',
    },
    secondary: {
      main: '#f50057',
      light: 'rgba(217,217,217,0.5)',
      dark: '#99B9EF',
    },
    background: {
      default: '#fafafa',
    },
    info: {
      main: '#AEB4C4',
      light: '#9AAABC',
      dark: '#f5f5fa', // Used for the heading background
    },
    success: {
      main: '#04823d',
      light: '#00eb80',
    },
    error: {
      main: '#cc1c27',
      light: '#ff3f4a',
    },
    warning: {
      main: '#ffdb8d',
      light: '#ffe4aa',
      dark: '#E6740E',
    },
    text: {
      primary: '#08062a',
      secondary: '#6e6e83',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    timeFrame: {
      light: '#84BDF1',
      main: '#5BA0E0',
      dark: '#3585CE',
      contrastText: '#FFFFFF',
    },
    tolerance: {
      light: '#F39B95',
      main: '#F0776D',
      dark: '#E84429',
      contrastText: '#FFFFFF',
    },
    collapsible: {
      main: '#E0E0E0',
    },
    selectAndTextField: {
      light: '#F5F5FA',
      main: '#08062A',
    },
    selection: {
      main: '#BFE0FA',
      dark: '#9E9E9E',
      contrastText: '#08062a',
    },
    globalSelect: {
      main: '#04823d',
      light: '#04823d',
      dark: '#00A94F',
    },
    personalSelect: {
      main: '#0092bc',
      light: '#0092bc',
    },
    legButtons: {
      main: '#0092bc', //stock
      light: '#04823d', //call
      dark: '#cc1c27', //put
    },
    greyShades: {
      main: '#D9D9D9',
      light: '#FAFAFA',
      dark: '#333333',
      contrastText: '#888888',
    },
    orangeButton: {
      main: 'rgb(223, 150, 65, 0.2)', // backgroundColr
      light: '#DF9641', //selected border Color
      dark: '#BFE0FA', //  Border color
    },
    symbolCard: {
      default: '#FFFFFF',
    },
    header: {
      default: '#FFFFFF',
    },
    highChart: {
      main: '#FFFFFFF2',
      dark: '#99B9EF61',
      light: '#FFFFFF',
      contrastText: '#000000',
    },
    slider: {
      main: '#99B9EF',
    },
    optionGridDisabled: {
      main: '#E0E0E0',
      light: '#99B9EF',
      contrastText: '#08062A',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    captionSmall: {
      fontSize: '0.75rem',
      lineHeight: 2,
    },
    body1: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    body2: {
      [fontSizeMediaQuery('xl', false)]: {
        fontSize: '0.80rem',
      },
      [fontSizeMediaQuery('xl')]: {
        fontSize: '0.90rem',
      },
    },
    subHeader: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '20px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 5,
          minHeight: 20,
          textTransform: 'capitalize',
          color: '#08062a',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5fa',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: 'none !important',
          },
          '&:hover fieldset': {
            border: 'none !important',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          // color: 'yellow',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          textAlign: 'center',
        },
        contained: {
          boxShadow: '4px 4px 0 -1px #0092bc, 4px 4px 0 #08062a',
          backgroundColor: '#08062a',
          borderRadius: '2px, 0',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#0092bc',
            color: '#08062a',
          },
        },
        outlined: {
          boxShadow: '4px 4px 0 -1px #f5f5fa, 4px 4px 0 #08062a',
          borderRadius: '2px, 0',
          borderColor: '#08062a',
          color: '#08062a',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#0092bc !important',
            color: '#f5f5fa',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px 8px 16px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily:
            'Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
        },
        button: {
          textTransform: 'capitalize',
          [fontSizeMediaQuery('xl', false)]: {
            fontSize: '0.80rem',
          },
          [fontSizeMediaQuery('xl')]: {
            fontSize: '0.90rem',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {},
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
        listbox: {
          padding: 0,
        },
        option: {
          fontFamily:
            'Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
          ':hover': {
            backgroundColor: '#99B9EF !important',
          },
          "&[aria-selected='true']": {
            fontWeight: 'bold',
            backgroundColor: '#99B9EF !important',
            ':hover': {
              backgroundColor: '#99B9EF !important',
            },
          },
        },
      },
    },
  },
};
