import { SentimentModel } from '@op/shared/src/hubs/sentiment-model';
import { BuyOrSell, LegType, SentimentType } from '@op/shared/src/models/enums/enums';
import { ILeg, ITradingStrategy, Leg, OpScore, Position } from '.';
import { ILegSettings } from '../configuration/leg';
import ApplicationContext from './application-context';
import BasicCombination from './basic-combination';
import Combination, { IOptions } from './combination';
import { ExpandedQuote } from './expanded-quote';
import formatting from './formatting';
import HowDataModel from './how-data-model';
import NumberFormatHelper from './number-format-helper';
import { OptionChain } from './option-chain-model';
import { IStrategyTemplate, StrategiesProvider } from './strategies-provider';
import { TradingRangeSimulator } from './trading-range-simulator';

export interface IChecklistItem {
  type: 'POW' | 'AnnualizedReturn' | 'StockTrend' | 'MarketTrend' | 'OpScore' | 'EarningsDate' | 'SpreadAndLiquidity';
  title: string;
  className: string;
  sentence: string;
  color: string;
  sortOrder: number;
}

export interface strategyLegs {
  hasLegs: boolean;
  legs: ILeg[] | undefined;
}

export class StrategyHelpers {
  private static buildInitialLegs = (
    strategyLegs: ILegSettings[],
    buyOrSell: string,
    date: Date,
    optionType: string,
    quote: ExpandedQuote,
    chain: OptionChain,
  ) => {
    const commonQuantityMultiplier = buyOrSell === BuyOrSell.BUY ? 1 : -1;
    const legs = [];
    for (let i = 0; i < strategyLegs.length; i++) {
      const legTemplate = strategyLegs[i];
      const expiry = chain.findExpiry(date, legTemplate.expiry, 0, optionType);
      const strike = chain.findStrike(quote.last, expiry, legTemplate.strike, optionType);
      const legQuantityMultiplier = legTemplate.buyOrSell === BuyOrSell.BUY ? 1 : -1;
      let quantity = legTemplate.quantity;
      const row = chain.findRow(strike.toString(), expiry, optionType);
      if (!row) {
        throw new Error('Row is undefined');
      }
      if (legTemplate.legType.trim().toUpperCase() === LegType.SECURITY.toUpperCase()) {
        const securityQuantity = OptionChain.getSecurityQuantity(legTemplate.legType);
        quantity = (quantity * row.callPremiumMult) / securityQuantity;
      }
      quantity *= legQuantityMultiplier * commonQuantityMultiplier;
      const leg: ILeg = {
        quantity: quantity,
        legType: legTemplate.legType,
        expiry: expiry,
        strikePrice: strike,
      };
      legs.push(leg);
    }
    return legs;
  };

  private static buildCombinationLegs = (
    strategyLegs: ILegSettings[],
    buyOrSell: string,
    date: Date,
    optionType: string,
    quote: ExpandedQuote,
    chain: OptionChain,
  ) => {
    const legs = StrategyHelpers.buildInitialLegs(strategyLegs, buyOrSell, date, optionType, quote, chain);
    // make some adjustments if strategy was built incorrect
    for (let i = 1; i < legs.length; i++) {
      const leg = legs[i];
      const prevLeg = legs[i - 1];
      const legTemplate = strategyLegs[i];
      const prevLegTemplate = strategyLegs[i - 1];
      const levelDifference = legTemplate.strike - prevLegTemplate.strike;
      const strikeDifference = (leg.strikePrice || 0) - (prevLeg.strikePrice || 0);
      // if strikes chosen do not match template values (due to difference between available strikes for each expiry)
      // adjust only current strike price to match template definition
      if (Math.sign(levelDifference) !== Math.sign(strikeDifference)) {
        leg.strikePrice = chain.findStrike(prevLeg.strikePrice, leg.expiry, levelDifference, optionType);
      }
      // if we should have chosen equal strikes, but they are actually different
      if (levelDifference === 0 && Math.abs(strikeDifference) > 0.001) {
        const allStrikesFirst = chain.getStrikeListForExpiry(leg.expiry, optionType);
        const allStrikesSecond = chain.getStrikeListForExpiry(prevLeg.expiry, optionType);
        if (!allStrikesFirst || !allStrikesSecond) {
          throw new Error('allStrikesFirst or allStrikesSecond is undefined');
        }
        const intersection = allStrikesFirst.filter((n: any) => {
          return allStrikesSecond.indexOf(n) !== -1;
        });
        leg.strikePrice = prevLeg.strikePrice = chain.findStrike(
          prevLeg.strikePrice,
          undefined,
          0,
          optionType,
          intersection,
        );
      }
    }
    return legs;
  };

  private static assembleByLegs = (
    legs: Leg[],
    combinationContext: { howDataModel: HowDataModel },
    options?: IOptions,
  ) => {
    const combination = Combination.fromLegs(legs, combinationContext.howDataModel, options);
    return combination;
  };

  static assembleCombination = (
    tradingStrategyOrStrategyName: ITradingStrategy | string,
    combinationContext: { howDataModel: HowDataModel },
    Constructor: 'Combination' | 'IncomeCombination',
    options?: IOptions,
  ) => {
    // It is ensured that tradingStrategy is of type ITradingStrategy;
    const tradingStrategy = tradingStrategyOrStrategyName as ITradingStrategy;
    const combination = StrategyHelpers.assembleByLegs(tradingStrategy.legs, combinationContext, options);
    if (!combination) {
      throw new Error('Combination is undefined');
    }
    combination.noCallOptimalCombinationTitle = tradingStrategy.strategyName;
    return combination;
  };

  // static assembleIncomeCombination = (
  //   incomeStrategyTemplate: ICallOptimal,
  //   combinationContext: { howDataModel: HowDataModel },
  //   positionInfo: { shares: number | undefined; costBasis: number | undefined } | undefined,
  //   defaultCombination: ICallOptimal | undefined,
  //   alternativeCombination: ICallOptimal | undefined,
  // ) => {
  //   let shares: number | undefined = 0;
  //   let costBasis: number | undefined;
  //   //TODO: This is UI elemnet data
  //   if (positionInfo) {
  //     shares = positionInfo.shares;
  //     costBasis = positionInfo.costBasis;
  //   }
  //   const howData = combinationContext.howDataModel;
  //   //TODO: optionType of howData is hardcoded to be 100. From there this should be updated.
  //   const multiplier = OptionChain.getSecurityQuantity(howData.optionType);
  //   shares = shares || multiplier;

  //   const legType = incomeStrategyTemplate.legType;
  //   const buyOrSell = legType === LegType.CALL ? BuyOrSell.BUY : BuyOrSell.SELL;

  //   const optionQty = Math.floor(shares / multiplier) || 1;
  //   const tradingStrategy: ITradingStrategy = {
  //     strategyName: incomeStrategyTemplate.legType,
  //     buyOrSell: buyOrSell,
  //     legs: [
  //       {
  //         legType: incomeStrategyTemplate.legType,
  //         strikePrice: incomeStrategyTemplate.strike,
  //         quantity: -optionQty,
  //         expiry: DateTimeHelper.resolveExpiry(incomeStrategyTemplate.expiry),
  //       },
  //     ],
  //   };
  //   if (legType === LegType.CALL) {
  //     tradingStrategy.legs.push({
  //       legType: LegType.SECURITY,
  //       strikePrice: undefined,
  //       quantity: shares,
  //       expiry: undefined,
  //     });
  //     tradingStrategy.strategyName = 'Covered Call';
  //   }
  //   const combination = StrategyHelpers.assembleCombination(tradingStrategy, combinationContext, 'IncomeCombination', {
  //     defaultDaysToFindExpiry: undefined,
  //     defaultCombination: defaultCombination,
  //     alternativeCombination: alternativeCombination,
  //   }) as IncomeCombination;
  //   if (costBasis !== undefined && legType === LegType.CALL) {
  //     const position = combination.positions[1];
  //     position.costBasis = costBasis;
  //   }

  //   //TODO: Income: where is this warning shown
  //   // if (legType === LegType.PUT) {
  //   //   comb.uncoveredIncomeWarning = Resources.uncoveredIncomeWarning;
  //   // }
  //   // Apply special rules for income covered call strategy
  //   if (legType === LegType.CALL) {
  //     combination.isIncomeSpecific = true;
  //   }

  //   return combination;
  // };

  // static assembleIncomeCombination = (
  //   incomeStrategyTemplate: any,
  //   combinationContext: { howDataModel: HowDataModel },
  //   positionInfo: any,
  //   defaultCombination: any,
  //   alternativeCombination: any,
  // ) => {
  //   const shares = 0,
  //     costBasis = undefined;
  //   if (positionInfo) {
  //     shares = positionInfo.shares;
  //     costBasis = positionInfo.costBasis;
  //   }

  //   const multiplier = OptionChain.getSecurityQuantity(); //OptionChain.getSecurityQuantity(combinationContext.optionType);
  //   shares = shares || multiplier;

  //   var legType = incomeStrategyTemplate.legType;
  //   var buyOrSell = legType === LegType.CALL ? BuyOrSell.BUY : BuyOrSell.SELL;

  //   var optionQty = Math.floor(shares / multiplier) || 1;
  //   var tradingStrategy: ITradingStrategy = {
  //     strategyName: incomeStrategyTemplate.legType,
  //     buyOrSell: buyOrSell,
  //     legs: [
  //       {
  //         legType: incomeStrategyTemplate.legType,
  //         strikePrice: incomeStrategyTemplate.strike,
  //         quantity: -optionQty,
  //         expiry: incomeStrategyTemplate.expiry,
  //       },
  //     ],
  //   };
  //   if (legType === LegType.CALL) {
  //     tradingStrategy.legs.push({
  //       legType: LegType.SECURITY,
  //       strikePrice: undefined,
  //       quantity: shares,
  //       expiry: undefined,
  //     });
  //     tradingStrategy.strategyName = 'Covered Call';
  //   }
  //   var comb = StrategyHelpers.assembleCombination(tradingStrategy, combinationContext, 'IncomeCombination', {
  //     defaultCombination: defaultCombination,
  //     alternativeCombination: alternativeCombination,
  //   });

  //   if (!comb) {
  //     throw new Error('combination is undefined');
  //   }

  //   if (costBasis !== undefined && legType === LegType.CALL) {
  //     var position = comb.positions[1];
  //     position.costBasis = costBasis;
  //   }
  //   if (legType === LegType.PUT) {
  //     //comb.uncoveredIncomeWarning = 'TODO resource warning'; //resources.uncoveredIncomeWarning;
  //   }

  //   comb.outlookPriceBounds = comb.stockPriceRange;

  //   // Apply special rules for income covered call strategy
  //   if (legType === LegType.CALL) {
  //     comb.isIncomeSpecific = true;
  //   }

  //   return comb;
  // };

  /* TODO: Vivek This function should be refacto#ff0000.
   * MatchedTemplate funciton shoudl be moved out of combinaiton.
   * So that it can be called from here as well without creating dummy combination.
   * Or Strategy Helper it self shoudl be refacto#ff0000 or not requi#ff0000.
   * We can follow builder pattern to carete combination with specific data and condition.
   * Also, fluent API will be nice to have.
   * Return type can be combination | undefined instead of boolean.
   */
  static rebuildCombination(
    currentCombination: Combination,
    newStrategy: IStrategyTemplate,
    combinationContext: HowDataModel,
  ) {
    if (!combinationContext.chain) {
      return undefined;
    }
    const date = combinationContext.chain.findExpiry(
      currentCombination.expiration(),
      -1,
      0,
      combinationContext.optionType,
    );
    const legs = StrategyHelpers.buildCombinationLegs(
      newStrategy.template.legs,
      newStrategy.buyOrSell,
      date,
      combinationContext.optionType,
      combinationContext.quote,
      combinationContext.chain,
    );
    const combination = Combination.fromLegs(
      legs,
      combinationContext,
      undefined,
      currentCombination.priceCalculationMethod,
    );
    const constructedStrategy = combination.matchedTemplate();
    //TODO: Verify: IStrategyTemplate does not have strategy. may be from special strategy.
    //if (constructedStrategy && constructedStrategy.strategy.template.name === newStrategy.displayedName) {
    if (constructedStrategy && constructedStrategy.displayedName === newStrategy.displayedName) {
      return combination;
    } else {
      return undefined;
    }
  }

  static changeWidth(comb: Combination, upOrDown: boolean) {
    if (!StrategyHelpers.canCustomizeWidth(comb)) {
      return undefined;
    }
    const combination = Combination.fromSelf(comb);
    const extractedPoses = combination.extractedPositions;
    const changingPoses = [];
    switch (extractedPoses.length) {
      case 2:
        // if one leg is buy while the other is sell
        if (extractedPoses[0].quantity * extractedPoses[1].quantity < 0) {
          if (extractedPoses[0].quantity < 0) {
            changingPoses.push(extractedPoses[0]);
            combination.lastHigherChanged = false;
          } else {
            changingPoses.push(extractedPoses[1]);
            combination.lastHigherChanged = true;
          }
        } else {
          // if both legs are buy or sell
          if (combination.lastHigherChanged) {
            changingPoses.push(extractedPoses[0]);
            combination.lastHigherChanged = false;
          } else {
            changingPoses.push(extractedPoses[1]);
            combination.lastHigherChanged = true;
          }
        }
        break;
      case 3:
      case 4:
        if (combination.lastHighersChanged) {
          changingPoses.push(extractedPoses[0]);
          changingPoses.push(extractedPoses[1]);
          combination.lastHighersChanged = false;
        } else {
          changingPoses.push(extractedPoses[extractedPoses.length - 2]);
          changingPoses.push(extractedPoses[extractedPoses.length - 1]);
          combination.lastHighersChanged = true;
        }
        break;
      default:
    }
    let diff = 0;
    if (upOrDown) {
      if (StrategyHelpers.canExpandWidth(combination)) {
        if (changingPoses.length === 1) {
          diff = combination.lastHigherChanged ? 1 : -1;
        } else {
          diff = combination.lastHighersChanged ? 1 : -1;
        }
      }
    } else {
      if (StrategyHelpers.canShrinkWidth(combination)) {
        if (changingPoses.length === 1) {
          diff = combination.lastHigherChanged ? -1 : 1;
        } else {
          diff = combination.lastHighersChanged ? -1 : 1;
        }
      }
    }
    if (diff) {
      if (diff > 0) {
        changingPoses.reverse();
      }

      changingPoses.forEach(function (extractedPos) {
        combination.positions.forEach((pos, index) => {
          if (!extractedPos.strikePrice) {
            throw new Error('StrikePrice is undefined');
          }
          if (
            pos.expiry &&
            pos.strike === extractedPos.strikePrice &&
            formatting.toExpiryKey(pos.expiry) === formatting.toExpiryKey(extractedPos.expiry)
          ) {
            const newStrike =
              combination.chain &&
              combination.chain.findStrike(extractedPos.strikePrice, extractedPos.expiry, diff, combination.optionType);
            // pos.strike = newStrike;
            // Instead of updating strike value change strike value in cloned Combination
            let newLeg = {
              buyOrSell: pos.buyOrSell,
              quantity: pos.absQuantity,
              legType: pos.legType,
              expiry: pos.expiry,
              strikePrice: newStrike,
            };
            StrategyHelpers.replacePosition(combination, newLeg, index);
          }
        });
      });
    }
    return combination;
  }

  static changeWingspan(comb: Combination, upOrDown: boolean) {
    const combination = Combination.fromSelf(comb);
    if (
      (upOrDown && StrategyHelpers.canExpandWingspan(combination)) ||
      (!upOrDown && StrategyHelpers.canShrinkWingspan(combination))
    ) {
      const strikes = combination.strikes().filter(function (s) {
        return typeof s === 'number';
      });
      const lowestStrike = Math.min(...strikes);
      const highestStrike = Math.max(...strikes);
      const changingPoses = combination.extractedPositions.filter(function (extractedPos) {
        return extractedPos.strikePrice === lowestStrike || extractedPos.strikePrice === highestStrike;
      });
      changingPoses.sort(function (a, b) {
        if (!a.strikePrice) {
          throw new Error('strikePrice is undefined');
        }

        if (!b.strikePrice) {
          throw new Error('strikePrice is undefined');
        }
        return a.strikePrice - b.strikePrice;
      });
      changingPoses.forEach(function (extractedPos) {
        let diff = 0;
        if (extractedPos.strikePrice === lowestStrike) {
          diff = upOrDown ? -1 : 1;
        }
        if (extractedPos.strikePrice === highestStrike) {
          diff = upOrDown ? 1 : -1;
        }
        combination.positions.forEach((pos, index) => {
          if (!extractedPos.strikePrice) {
            throw new Error('strikePrice is undefined');
          }

          if (!combination.chain) {
            throw new Error('chain is undefined');
          }
          if (
            pos.expiry &&
            pos.strike === extractedPos.strikePrice &&
            formatting.toExpiryKey(pos.expiry) === formatting.toExpiryKey(extractedPos.expiry)
          ) {
            const newStrike = combination.chain.findStrike(
              extractedPos.strikePrice,
              extractedPos.expiry,
              diff,
              combination.optionType,
            );
            // pos.strike = newStrike;
            // Instead of updating strike value change strike value in cloned Combination
            let newLeg = {
              buyOrSell: pos.buyOrSell,
              quantity: pos.absQuantity,
              legType: pos.legType,
              expiry: pos.expiry,
              strikePrice: newStrike,
            };
            StrategyHelpers.replacePosition(combination, newLeg, index);
          }
        });
      });
    }
    return combination;
  }

  static getAnnualizedReturn = (combination: BasicCombination) => {
    if (!combination.isIncome) {
      return undefined;
    }
    //check list - annualized return
    let returnItem: IChecklistItem = {
      type: 'AnnualizedReturn',
      title: 'Annualized Return',
      className: '',
      sentence: '',
      color: '',
      sortOrder: 2,
    };
    const aRetrun = combination.annualizedReturn();
    const sentencePart = `${'The strategy you have selected to sell has'} ${formatting.aOrAn(
      aRetrun,
    )} ${NumberFormatHelper.toPercentage(aRetrun)}`;
    if (aRetrun >= 5) {
      returnItem.className = 'check-square';
      returnItem.color = 'green'; //NOTE: BE AWARE, this color code is used in logic as well for `allGreen` to show op logo. DO NOT CHANGE IT.
      returnItem.sentence = `${sentencePart} annualized return which is very good.`;
    } else if (aRetrun >= 2) {
      returnItem.className = 'exclamation-triangle';
      returnItem.color = '#ffad33';
      returnItem.sentence = `${sentencePart} annualized return which is average.`;
    } else {
      returnItem.className = 'times-circle';
      returnItem.color = 'red';
      returnItem.sentence = `${sentencePart} annualized return which is very low.`;
    }
    return returnItem;
  };

  static getPOWChecklistItem = (combination: BasicCombination) => {
    if (!combination.isIncome) {
      return undefined;
    }
    //check list - POW
    const powItem: IChecklistItem = {
      type: 'POW',
      title: 'Probability Of Worthless',
      className: '',
      sentence: '',
      color: '',
      sortOrder: 1,
    };
    const pow = combination.worthlessProb();
    const sentencePart = `${'The strategy you have selected to sell has'} ${formatting.aOrAn(
      pow * 100,
    )} ${NumberFormatHelper.toPercentage(pow * 100)}`;
    if (pow >= 0.75) {
      powItem.className = 'check-square';
      powItem.color = 'green';
      powItem.sentence = `${sentencePart} chance of expirying worthless which is very good.`;
    } else if (pow >= 0.55) {
      powItem.className = 'exclamation-triangle';
      powItem.color = '#ffad33';
      powItem.sentence = `${sentencePart} chance of expirying worthless which is lower than optimal.`;
    } else {
      powItem.className = 'times-circle';
      powItem.color = 'red';
      powItem.sentence = `${sentencePart} chance of expirying worthless which is very low.`;
    }
    return powItem;
  };

  private static trendSentiment = (trend: string, localizer?: (translate: string) => any) => {
    let key = '';
    if (trend.toLowerCase() === 'bearish') {
      key = 'common.labels.bearish';
    } else if (trend.toLowerCase() === 'bullish') {
      key = 'common.labels.bullish';
    } else {
      key = 'common.labels.neutral';
    }
    return StrategyHelpers.localizedString(key, localizer).toLowerCase();
  };

  private static localizedString = (title: string, localizer?: (translate: string) => any) => {
    if (!localizer) {
      return title;
    }
    // Localizer returns ReactNode, toString ensure that string representation is returned.
    return localizer(title).toString();
  };

  //TODO: This should be moved to basicCombination.sentiment() function itself.
  static sentimentLocalizedKey = (sentiment: string) => {
    return `how.tradeIdeaPanel.${sentiment}`;
  };

  static sentimentLocalized = (sentiment: string, localizer?: (translate: string) => any) => {
    const key = StrategyHelpers.sentimentLocalizedKey(sentiment);
    return StrategyHelpers.localizedString(key, localizer).toLowerCase();
  };

  static getStockTrend = (
    combination: BasicCombination,
    syrahSentiment: SentimentModel | undefined,
    localizer?: (translate: string) => any,
  ) => {
    if (!combination.quote.isTradeable || combination.isIncome) {
      return undefined;
    }
    //TODO: combination.sentiment will always give some value. it will never go to non-profitable.
    const tradeSentiment = combination.sentiment() || 'non-profitable';
    const localizedSentiment = StrategyHelpers.sentimentLocalized(tradeSentiment, localizer);
    const syrahShortSentiment = syrahSentiment?.syrahShortSentiment?.sentiment.toString() || tradeSentiment;
    const syrahLongSentiment = syrahSentiment?.syrahLongSentiment?.sentiment.toString() || tradeSentiment;
    const symbol = combination.symbol;
    var stockTrendItem: IChecklistItem = {
      type: 'StockTrend',
      title: StrategyHelpers.localizedString('how.singleTrade.titles.stockTrend', localizer),
      className: '',
      sentence: '',
      color: '',
      sortOrder: 3,
    };
    let months;
    let stockTrend: string;
    if (combination.daysToExpiry() < 60 && combination.daysToExpiry() !== -999999) {
      months = '1m';
      stockTrend = syrahShortSentiment;
    } else {
      months = '6m';
      stockTrend = syrahLongSentiment;
    }
    const sentencePart = `${StrategyHelpers.localizedString(
      'tradeTicket.strategyChosenIs',
      localizer,
    )} ${localizedSentiment} ${StrategyHelpers.localizedString(
      'how.howPanel.deltaDetails.andOrBut.and',
      localizer,
    )} ${formatting.symbolDotExchangeToSymbol(symbol)}${StrategyHelpers.localizedString(
      'tradeTicket.apostropheS',
      localizer,
    )} ${months} ${StrategyHelpers.localizedString('tradeTicket.trendIs', localizer)} ${StrategyHelpers.trendSentiment(
      stockTrend,
      localizer,
    )}.`;
    if (
      (tradeSentiment.match(/bearish/i) && stockTrend.match(/bearish/i)) ||
      (tradeSentiment.match(/bullish/i) && stockTrend.match(/bullish/i)) ||
      (tradeSentiment.match(/neutral/i) && stockTrend.match(/neutral/i))
    ) {
      stockTrendItem.className = 'check-square';
      stockTrendItem.color = 'green';
      stockTrendItem.sentence = sentencePart;
    } else if (
      (tradeSentiment.match(/bearish/i) && stockTrend.match(/bullish/i)) ||
      (tradeSentiment.match(/bullish/i) && stockTrend.match(/bearish/i)) ||
      (tradeSentiment.match(/bearish/i) && stockTrend.match(/neutral/i)) ||
      (tradeSentiment.match(/bullish/i) && stockTrend.match(/neutral/i))
    ) {
      stockTrendItem.className = 'times-circle';
      stockTrendItem.color = 'red';
      stockTrendItem.sentence = `${StrategyHelpers.localizedString(
        'tradeTicket.strategyChosenIs',
        localizer,
      )}${localizedSentiment}${StrategyHelpers.localizedString(
        'tradeTicket.however',
        localizer,
      )} ${formatting.symbolDotExchangeToSymbol(symbol)}${StrategyHelpers.localizedString(
        'tradeTicket.apostropheS',
        localizer,
      )} ${months} ${StrategyHelpers.localizedString(
        'tradeTicket.trendIs',
        localizer,
      )} ${StrategyHelpers.trendSentiment(stockTrend, localizer)}${StrategyHelpers.localizedString(
        'tradeTicket.againstTheTrend',
        localizer,
      )}`;
    } else {
      stockTrendItem.className = 'check-square';
      stockTrendItem.color = 'green';
      stockTrendItem.sentence = sentencePart;
    }
    return stockTrendItem;
  };

  static getMarketTrendChecklistItem = (
    combination: BasicCombination,
    marketTone: SentimentModel | undefined, //TODO: we can remove undefined after refactoring.
    localizer?: (text: string) => any,
  ) => {
    if (!combination.quote.isTradeable || !marketTone || combination.isIncome) {
      return undefined;
    }
    const indexStr =
      ApplicationContext.configuration.marketRegion === 'US'
        ? 'S&P 500'
        : ApplicationContext.configuration.marketRegion === 'CA'
        ? 'S&P/TSX Comp'
        : ApplicationContext.configuration.marketRegion === 'NO'
        ? 'STOCKHOLM 30 INDEX'
        : 'S&P 500';

    var marketTrendItem: IChecklistItem = {
      type: 'MarketTrend',
      title: StrategyHelpers.localizedString('how.singleTrade.titles.marketTrend', localizer),
      className: '',
      sentence: '',
      color: '',
      sortOrder: 4,
    };
    const tradeSentiment = combination.sentiment() || 'non-profitable';
    const localizedSentiment = StrategyHelpers.sentimentLocalized(tradeSentiment, localizer);
    let marketSyrahShortSentiment = marketTone.syrahShortSentiment?.sentiment.toString() || tradeSentiment;
    let marketSyrahLongSentiment = marketTone.syrahLongSentiment?.sentiment.toString() || tradeSentiment;
    let months = '6m';
    if (combination.daysToExpiry() < 60 && combination.daysToExpiry() !== -999999) {
      months = '1m';
    }
    const marketTrend = months === '1m' ? marketSyrahShortSentiment : marketSyrahLongSentiment; // Here is error as we get the SPY trend not the symbol
    const trendSentiment = StrategyHelpers.trendSentiment(marketTrend, localizer);
    const localizedTrendSentiment = trendSentiment;
    // StrategyHelpers.localizedString(trendSentiment, localizer).toLowerCase();
    const sentencePart = `${StrategyHelpers.localizedString(
      'tradeTicket.strategyChosenIs',
      localizer,
    )} ${localizedSentiment} ${StrategyHelpers.localizedString(
      'tradeTicket.currentlyThe',
      localizer,
    )} ${indexStr}${StrategyHelpers.localizedString(
      'tradeTicket.apostropheS',
      localizer,
    )} ${months} ${StrategyHelpers.localizedString('tradeTicket.trendIs', localizer)} ${localizedTrendSentiment}.`;
    if (
      (tradeSentiment.match(/bearish/i) && marketTrend.match(/bearish/i)) ||
      (tradeSentiment.match(/bullish/i) && marketTrend.match(/bullish/i)) ||
      (tradeSentiment.match(/neutral/i) && marketTrend.match(/neutral/i))
    ) {
      marketTrendItem.className = 'check-square';
      marketTrendItem.color = 'green';
      marketTrendItem.sentence = sentencePart;
    } else if (
      (tradeSentiment.match(/bearish/i) && marketTrend.match(/bullish/i)) ||
      (tradeSentiment.match(/bullish/i) && marketTrend.match(/bearish/i))
    ) {
      marketTrendItem.className = 'times-circle';
      marketTrendItem.color = 'red';
      marketTrendItem.sentence = `${StrategyHelpers.localizedString(
        'tradeTicket.strategyChosenIs',
        localizer,
      )} ${localizedSentiment}${StrategyHelpers.localizedString(
        'tradeTicket.howeverThe',
        localizer,
      )}${indexStr}${StrategyHelpers.localizedString(
        'tradeTicket.apostropheS',
        localizer,
      )} ${months} ${StrategyHelpers.localizedString(
        'tradeTicket.trendIs',
        localizer,
      )}${localizedTrendSentiment}${StrategyHelpers.localizedString('tradeTicket.againstTheTrend', localizer)}`;
    } else {
      marketTrendItem.className = 'check-square';
      marketTrendItem.color = 'green';
      marketTrendItem.sentence = sentencePart;
    }
    return marketTrendItem;
  };

  static getEarningsDateChecklistItem = (
    combination: BasicCombination,
    earningsDate: Date | undefined,
    localizer?: (translate: string) => any,
  ) => {
    if (combination.hasOnlyStx()) {
      return undefined;
    }
    const earningsDateItem: IChecklistItem = {
      type: 'EarningsDate',
      title: StrategyHelpers.localizedString('how.singleTrade.titles.earningsDate', localizer),
      className: '',
      sentence: '',
      color: '',
      sortOrder: 6,
    };
    const expiry = combination.expiry();
    const hasEarningsDate = expiry && earningsDate && earningsDate < expiry;
    if (hasEarningsDate) {
      earningsDateItem.className = 'exclamation-triangle';
      earningsDateItem.color = '#ffad33';
      earningsDateItem.sentence = `${StrategyHelpers.localizedString(
        'tradeTicket.beAware',
        localizer,
      )} ${formatting.formatDate(earningsDate, 'T dd yyyy')} ${StrategyHelpers.localizedString(
        'tradeTicket.priorToExpiration',
        localizer,
      )}`;
    } else {
      earningsDateItem.className = 'check-square';
      earningsDateItem.color = 'green';
      earningsDateItem.sentence = `${StrategyHelpers.localizedString('tradeTicket.noEarnings', localizer)}`;
    }
    return earningsDateItem;
  };

  static getSpreadAndLiquidityChecklistItem = (
    combination: BasicCombination,
    localizer?: (translate: string) => any,
  ) => {
    var spreadItem: IChecklistItem = {
      type: 'SpreadAndLiquidity',
      title: StrategyHelpers.localizedString('how.singleTrade.titles.spreadLiquidity', localizer),
      className: '',
      sentence: '',
      color: '',
      sortOrder: 7,
    };

    //TODO: really need to make this highly configurable for now it is assumed US and Other regions defaults to US region
    let green = 5;
    let yellow = 10;
    if (ApplicationContext.configuration.marketRegion === 'CA') {
      green = 10;
      yellow = 20;
    } else if (ApplicationContext.configuration.marketRegion === 'NO') {
      green = 15;
      yellow = 30;
    }

    const spreadPercent = parseFloat(combination.spreadPercent());
    if (spreadPercent < green) {
      spreadItem.className = 'check-square';
      spreadItem.color = 'green';
      spreadItem.sentence = `${StrategyHelpers.localizedString('tradeTicket.lowSpread', localizer)}`;
    } else if (spreadPercent < yellow) {
      spreadItem.className = 'exclamation-triangle';
      spreadItem.color = '#ffad33';
      spreadItem.sentence = `${StrategyHelpers.localizedString('tradeTicket.sizeableSpread', localizer)}`;
    } else {
      spreadItem.className = 'times-circle';
      spreadItem.color = 'red';
      spreadItem.sentence = `${StrategyHelpers.localizedString('tradeTicket.largeSpread', localizer)}`;
    }
    return spreadItem;
  };

  //TODO: Refactor: This function uses translation. Must be in functional component or hook.
  static checkCombination = (
    howData: HowDataModel,
    combination: BasicCombination | undefined,
    tradingRangeSimulator: TradingRangeSimulator,
    earningsDate: Date | undefined,
    syrahSentiment: SentimentModel | undefined,
    marketTone?: SentimentModel | undefined,
  ) => {
    if (!combination) {
      throw new Error('combination is undefined');
    }
    const score = OpScore.fromData(
      combination,
      tradingRangeSimulator.outlookPercentBoundsForPlChart(howData),
      tradingRangeSimulator.outlookPriceBoundsForPlChart(),
    );
    const powItem = StrategyHelpers.getPOWChecklistItem(combination);
    const returnItem = StrategyHelpers.getAnnualizedReturn(combination);
    const stockTrendItem = StrategyHelpers.getStockTrend(combination, syrahSentiment);
    const marketTrendItem = StrategyHelpers.getMarketTrendChecklistItem(combination, marketTone);
    const opScoreItem = {
      type: 'OpScore',
      title: '',
      className: 'check-square',
      sentence: '',
      color: score.css,
      sortOrder: 5,
    } as IChecklistItem;
    const earningsDateItem = StrategyHelpers.getEarningsDateChecklistItem(combination, earningsDate);
    const spreadItem = StrategyHelpers.getSpreadAndLiquidityChecklistItem(combination);
    const list = [powItem, returnItem, stockTrendItem, marketTrendItem, opScoreItem, earningsDateItem, spreadItem];
    return list.filter((l): l is IChecklistItem => l !== undefined);
  };

  static addPositionByLeg = (combination: Combination, leg: ILeg) => {
    const position: Position = Position.fromLeg(
      leg,
      combination.chain,
      combination.quote,
      combination.optionType,
      combination.priceCalculationMethod,
      false, //this.isPortfolio,
    );
    combination.positions.push(position);
    return position;
  };

  static addPosition = (
    combination: Combination,
    buyOrSell: BuyOrSell | undefined,
    quantity: number,
    type: string,
    expiry?: any,
    strikePrice?: any,
    costBasis?: any,
  ) => {
    if (!combination.quote.isTradeable && type === LegType.SECURITY) {
      return undefined;
    }
    quantity = Math.abs(quantity);
    var leg = {
      legType: type,
      quantity: buyOrSell === BuyOrSell.BUY ? quantity : -quantity,
      expiry: expiry,
      strikePrice: strikePrice,
      costBasis: costBasis,
    };
    return StrategyHelpers.addPositionByLeg(combination, leg);
  };

  static replacePositionByLeg = (combination: Combination, leg: ILeg, index: number) => {
    const position: Position = Position.fromLeg(
      leg,
      combination.chain,
      combination.quote,
      combination.optionType,
      combination.priceCalculationMethod,
      false, //this.isPortfolio,
    );
    combination.positions.splice(index, 1, position);
    return position;
  };

  static replacePosition = (combination: Combination, newLeg: ILeg, index: number) => {
    if (!combination.quote.isTradeable && newLeg.legType === LegType.SECURITY) {
      return undefined;
    }
    newLeg.quantity = Math.abs(newLeg.quantity);
    var leg = {
      legType: newLeg.legType,
      quantity: newLeg.buyOrSell === BuyOrSell.BUY ? newLeg.quantity : -newLeg.quantity,
      expiry: newLeg.expiry,
      strikePrice: newLeg.strikePrice,
      costBasis: newLeg.costBasis,
    };
    return StrategyHelpers.replacePositionByLeg(combination, leg, index);
  };

  static canUpdatePosition = (combination: Combination, newLeg: ILeg) => {
    if (!combination.quote.isTradeable && newLeg.legType === LegType.SECURITY) {
      return undefined;
    }
    newLeg.quantity = Math.abs(newLeg.quantity);
    var leg: ILeg = {
      legType: newLeg.legType,
      quantity: newLeg.buyOrSell === BuyOrSell.BUY ? newLeg.quantity : -newLeg.quantity,
      expiry: newLeg.expiry,
      strikePrice: newLeg.strikePrice,
      costBasis: newLeg.costBasis,
      buyOrSell: newLeg.buyOrSell,
    };
    return leg;
  };

  static removePosition = (combinatoin: Combination, position: Position, index: number) => {
    //const newPositions = this.positions.map((p) => Position.fromSelf(p));
    combinatoin.positions.splice(index, 1);
  };

  static setAbsQuantity = (combination: Combination, newVal: number) => {
    // newVal = parseInt(newVal.toString());
    // if (newVal < 1) {
    //   return;
    // }
    const combinationQty = combination.absQuantity();

    const addedLegs = combination.extractedPositions
      .map((pos) => {
        if (pos.ownedQuantity === 0) {
          return undefined;
        }
        const addedQuantity =
          parseInt(((Math.abs(pos.quantity) * newVal) / combinationQty).toString()) -
          parseInt((((pos.quantity - pos.ownedQuantity) * newVal) / combinationQty).toString()) -
          pos.ownedQuantity;
        return {
          legType: pos.legType,
          expiry: pos.expiry,
          strikePrice: pos.strikePrice,
          buyOrSell: pos.ownedQuantity > 0 ? BuyOrSell.BUY : BuyOrSell.SELL,
          quantity: addedQuantity,
          existing: pos.quantity !== pos.ownedQuantity,
        };
      })
      .filter((pos) => pos != undefined);

    combination.buyablePositions.forEach((item) => {
      const currentQty = item.absQuantity;
      let newQuantity = parseInt(((currentQty * newVal) / combinationQty).toString());
      addedLegs.forEach((leg) => {
        if (!leg) {
          throw new Error('Leg is null');
        }
        if (
          (leg.legType === LegType.SECURITY && item.type === LegType.SECURITY) ||
          (leg.strikePrice === item.strike &&
            leg.legType === item.type &&
            formatting.toExpiryKey(leg.expiry) === formatting.toExpiryKey(item.expiry))
        ) {
          newQuantity += leg.quantity;
        }
      });
      item.absQuantity = newQuantity;
    });

    addedLegs.forEach((leg) => {
      if (!leg) {
        throw new Error('Leg is null');
      }
      if (!leg.existing) {
        StrategyHelpers.addPosition(combination, leg.buyOrSell, leg.quantity, leg.legType, leg.expiry, leg.strikePrice);
      }
    });
  };

  static width = (combination: Combination) => {
    if (!combination.expiry()) {
      return 0;
    }
    let left: number, right: number;
    switch (combination.strikes().length) {
      case 2:
        left = combination.strikes()[0];
        right = combination.strikes()[1];
        break;
      case 4:
        left = combination.strikes()[1];
        right = combination.strikes()[2];
        break;
      default:
        return 0;
    }
    if (!combination.chain) {
      throw new Error('chain is undefined');
    }

    var strikeList = combination.chain.getStrikeListForExpiry(combination.expiry());
    var width = 0;
    strikeList &&
      strikeList.forEach((strike) => {
        if (left <= strike && strike < right) {
          width++;
        }
      });
    return width;
  };

  static canCustomizeWidth = (combination: Combination) => {
    const matchedTemplate = combination.matchedTemplate();
    if (matchedTemplate) {
      const result = matchedTemplate.template.canCustomizeWidth;
      return result;
    } else {
      return false;
    }
  };

  static canShrinkWidth = (combination: Combination) => {
    const matchedTemplate = combination.matchedTemplate();
    if (matchedTemplate) {
      const result = matchedTemplate.template.canCustomizeWidth;
      return result && StrategyHelpers.width(combination) > 1;
    } else {
      return false;
    }
  };

  static canExpandWidth = (combination: Combination) => {
    const matchedTemplate = combination.matchedTemplate();
    if (!matchedTemplate) {
      return false;
    }
    const result = matchedTemplate.template.canCustomizeWidth;
    if (!result || !combination.expiry()) {
      return result;
    }
    if (!combination.chain) {
      throw new Error('chain is undefined');
    }
    const strikes = combination.strikes();
    const strikeList = combination.chain.getStrikeListForExpiry(combination.expiry());
    if (!strikeList) {
      throw new Error('strikeList is undefined');
    }
    // Below Line changed since '+' Button of width is not hiding for last strike value.
    //if (strikeList[0] === strikes[0] && strikeList[strikeList.length - 1] === strikes[strikes.length - 1]) {
    if (strikeList[strikeList.length - 1] === strikes[strikes.length - 1]) {
      return false;
    }
    return true;
  };

  static wingspanLeft = (combination: Combination) => {
    if (!combination.expiry()) {
      return 0;
    }
    let left: number, right: number;
    switch (combination.strikes().length) {
      case 3:
      case 4:
        left = combination.strikes()[0];
        right = combination.strikes()[1];
        break;
      default:
        return 0;
    }
    if (!combination.chain) {
      throw new Error('chain is undefined');
    }
    var strikeList = combination.chain.getStrikeListForExpiry(combination.expiry());
    var width = 0;
    strikeList &&
      strikeList.forEach((strike) => {
        if (left <= strike && strike < right) {
          width++;
        }
      });
    return width;
  };

  static wingspanRight = (combination: Combination) => {
    if (!combination.expiry()) {
      return 0;
    }
    let left: number, right: number;
    switch (combination.strikes().length) {
      case 3:
        left = combination.strikes()[1];
        right = combination.strikes()[2];
        break;
      case 4:
        left = combination.strikes()[2];
        right = combination.strikes()[3];
        break;
      default:
        return 0;
    }

    if (!combination.chain) {
      throw new Error('chain is undefined');
    }
    var strikeList = combination.chain.getStrikeListForExpiry(combination.expiry());
    var width = 0;
    strikeList &&
      strikeList.forEach((strike) => {
        if (left <= strike && strike < right) {
          width++;
        }
      });
    return width;
  };

  static canCustomizeWingspan = (combination: Combination) => {
    var result = combination.matchedTemplate() && combination.matchedTemplate()?.template.canCustomizeWingspan;
    return result;
  };

  static canShrinkWingspan = (combination: Combination) => {
    var result = StrategyHelpers.canCustomizeWingspan(combination);
    return result && (StrategyHelpers.wingspanLeft(combination) > 1 || StrategyHelpers.wingspanRight(combination) > 1);
  };

  static canExpandWingspan = (combination: Combination) => {
    var result = StrategyHelpers.canCustomizeWingspan(combination);
    if (!result || !combination.expiry()) {
      return result;
    }
    var extractedPositions = combination.extractedPositions;
    for (var i = 0; i < extractedPositions.length; i++) {
      var pos = extractedPositions[i];
      var expiry = pos.expiry;
      if (expiry) {
        var strike = pos.strikePrice;
        if (!combination.chain) {
          throw new Error('chain is undefined');
        }
        var strikeList = combination.chain.getStrikeListForExpiry(expiry);

        if (!strikeList) {
          throw new Error('strikeList is undefined');
        }
        if (!strike) {
          throw new Error('strike is undefined');
        }
        if (strikeList.indexOf(strike) === 0 || strikeList.indexOf(strike) === strikeList.length - 1) {
          return false;
        }
      }
    }
    return result;
  };

  /**
   * For only security leg checking leg quantity / 100 should not go less than 100
   * For option legs checking by GCD funcion as the change of quanitites happens through GCD
   */
  static canDecreaseQuantity = (combination: Combination) => {
    if (combination.positions.length === 0) {
      return false;
    }
    if (combination.hasOnlyStx()) {
      const securities = combination.positions.filter((p): p is Position => p.isSecurityType());
      // .filter((p) => p.absQuantity <= 100);
      if (securities.length <= 0) {
        return false;
      }
      const securityQty = securities[0]._quantity / 100;
      return Math.floor(securityQty) > 1;
    }
    // If there is any option quantity which reached minimum threashold i.e (1) then we cannot further decrease.
    const options = combination.positions.filter((p): p is Position => p.isOptionType);
    // .filter((p) => p.absQuantity <= 1);
    const gcd = this.calculateGCD(options.map((p) => p._quantity));
    return gcd > 1;
  };

  static canIncreaseQuantity = (combination: Combination, shares?: number | undefined) => {
    if (!combination.isIncome) {
      return !combination.isRoll;
    }
    const security = combination.originalLegs?.find((p): p is ILeg => p.legType == LegType.SECURITY);
    const call = combination.positions.find((p) => p.isCallType());
    //PUT are allowed
    if (!call || !shares) {
      return true;
    }
    if (!security) {
      return false;
    }
    return call.absQuantity < Math.abs(NumberFormatHelper.floor(shares / 100));
  };

  static calculateGCD = (arr: number[]) => {
    const len = arr.length;
    if (!len) {
      return null;
    }
    let a = arr[0];
    for (var i = 1; i < len; i++) {
      let b = arr[i];
      a = this.gcd(a, b);
    }
    return a;
  };

  static gcd = (m: number, n: number) => {
    let x = Math.abs(m);
    let y = Math.abs(n);
    while (y) {
      var t = y;
      y = x % y;
      x = t;
    }
    return x;
  };

  static generateCombinationByStrategyName = (
    strategyDisplayedName: string,
    howData: HowDataModel | undefined,
    combination: Combination,
    strategySentiment?: SentimentType,
  ) => {
    const strategy = this.getLegsByStrategyName(howData, strategyDisplayedName, strategySentiment);
    if (strategy.hasLegs && strategy.legs) {
      let positions: Position[] = [];
      const newCombination = Combination.fromSelf(combination);
      for (let i = 0; i < strategy.legs.length; i++) {
        let newPosition = newCombination.replacePositionByLeg(strategy.legs[i], i);
        positions.push(newPosition);
      }
      newCombination.positions = positions;
      return newCombination;
    }
    for (let option of Array.from(StrategiesProvider.sentiments)) {
      const templates = StrategiesProvider.strategyTemplatesBySentiment.get(option);
      if (!templates) {
        throw new Error('template is undefined');
      }
      for (let template of templates) {
        if (template.displayedName.trim().toUpperCase() === strategyDisplayedName.trim().toUpperCase()) {
          if (!howData) {
            throw new Error('howDataModel is undefined');
          }
          const newCombination = this.rebuildCombination(combination, template, howData);
          if (!newCombination) {
            return undefined;
          }
          return newCombination;
        }
      }
    }
  };

  static getLegsByStrategyName = (
    howData: HowDataModel | undefined,
    strategyDisplayedName: string,
    strategySentiment: SentimentType | undefined,
  ): strategyLegs => {
    if (!strategySentiment || !howData) {
      return { hasLegs: false, legs: undefined };
    }
    const isLong = strategyDisplayedName.includes('Long');
    const isShort = strategyDisplayedName.includes('Short');
    const isCallVertical = strategyDisplayedName.includes('Call Vertical');
    const isPutVertical = strategyDisplayedName.includes('Put Vertical');
    let sentiment = undefined;
    let hasLegs = false;
    let index = 2;
    /**
     *  Bullish -> long call vertical -> 2nd item
     *  Bearish -> long put vertical -> 2nd item
     *  Neutral -> short put vertical -> 0th item
     *  Neutral -> short call vertical -> 2nd item
     */
    if (isLong && isCallVertical) {
      sentiment = SentimentType.BULLISH;
      hasLegs = true;
    }
    if (isLong && isPutVertical) {
      sentiment = SentimentType.BEARISH;
      hasLegs = true;
    }
    if (isShort && isCallVertical) {
      sentiment = SentimentType.NEUTRAL;
      hasLegs = true;
    }
    if (isShort && isPutVertical) {
      sentiment = SentimentType.NEUTRAL;
      hasLegs = true;
      index = 0;
    }
    if (!hasLegs) {
      return { hasLegs: false, legs: undefined };
    }
    const strategy = howData.tradingStrategiesRaw.get(sentiment);
    const findVertical = strategy[index];
    if (findVertical.legs === null) {
      return { hasLegs: false, legs: undefined };
    }
    return { hasLegs: true, legs: findVertical.legs };
  };
}
