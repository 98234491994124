import React, { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

export const SubscriptionPageWidget: React.FC<{}> = () => {
  const isSubscriptionRoute = useMatch('/subscription');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSubscriptionRoute) {
      return;
    }
    navigate('/profile/subscriptions', { replace: true });
    return;
  }, []);

  return null;
};
