import lodash from 'lodash';
import { ISignal, LinkedPortfolio, LinkedWatchlist, SortingType } from '..';
import { orderByAscending, orderByDescending } from '../how';
import { ICollection } from '../what/collection';
import { Sorts } from '../what/filters';
export interface ICreditSpreadReport {
  symbol: string;
  companyName: string;
  strategy: string;
  type: 'Call' | 'Put';
  price: number;
  strike: {
    sell: number;
    buy: number;
  };
  expiry: string;
  premium: {
    value: number;
    width: number;
    percentage: string;
  };
  signals: ISignal[];
  impliedVolatilityRank: string;
  earningsDate: string;
  url: string;
  portfolios: LinkedPortfolio[];
  watchlists: LinkedWatchlist[];
}

export class CreditSpreads implements ICollection<ICreditSpreadReport> {
  data: ICreditSpreadReport[] = [];
  creditSpreads: ICreditSpreadReport[] = [];
  created = '';

  public constructor(response: CreditSpreads) {
    this.data = response.creditSpreads;
    this.creditSpreads = response.creditSpreads;
    this.created = response.created;
  }

  public static fromSelf = (creditSpreads: CreditSpreads): CreditSpreads => {
    if (!creditSpreads) {
      throw Error('creditSpread is null or undefined');
    }
    const model = new CreditSpreads(creditSpreads);
    return model;
  };

  public get creditSpreadCount(): number {
    return this.creditSpreads.length;
  }

  public get totalPortfolios(): number {
    return this.creditSpreads
      .filter((e) => e.portfolios && e.portfolios.length > 0)
      .reduce((a, i) => a + i.portfolios.length, 0);
  }

  public get totalWatchlists(): number {
    return this.creditSpreads
      .filter((e) => e.watchlists && e.watchlists.length > 0)
      .reduce((a, i) => a + i.watchlists.length, 0);
  }

  public sort = (sorts: Sorts): CreditSpreads => {
    if (!sorts) {
      return this;
    }
    const creditSpreads = this.sortByPremiumPercentage(sorts)
      .sortBySymbol(sorts)
      .sortByCompanyName(sorts)
      .sortByIVRank(sorts)
      .sortByEarningDate(sorts);
    return creditSpreads;
  };

  private sortByPremiumPercentage = (sorts: Sorts): CreditSpreads => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'premiumPercentage') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.creditSpreads = lodash.orderBy(
          this.creditSpreads,
          [(e) => Number(e.premium.percentage.replace('%', ''))],
          ['asc'],
        );
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.creditSpreads = lodash.orderBy(
          this.creditSpreads,
          [(e) => Number(e.premium.percentage.replace('%', ''))],
          ['desc'],
        );
      }
    }
    return this;
  };

  private sortBySymbol = (sorts: Sorts): CreditSpreads => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'symbol') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.creditSpreads = orderByAscending(this.creditSpreads, 'symbol');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.creditSpreads = orderByDescending(this.creditSpreads, 'symbol');
      }
    }
    return this;
  };

  private sortByCompanyName = (sorts: Sorts): CreditSpreads => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'companyName') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.creditSpreads = orderByAscending(this.creditSpreads, 'companyName');
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.creditSpreads = orderByDescending(this.creditSpreads, 'companyName');
      }
    }
    return this;
  };

  private sortByIVRank = (sorts: Sorts): CreditSpreads => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'ivRank') {
        continue;
      }
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        this.creditSpreads = lodash.orderBy(
          this.creditSpreads,
          [(e) => Number(e.impliedVolatilityRank.replace('%', ''))],
          ['asc'],
        );
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        this.creditSpreads = lodash.orderBy(
          this.creditSpreads,
          [(e) => Number(e.impliedVolatilityRank.replace('%', ''))],
          ['desc'],
        );
      }
    }
    return this;
  };

  private sortByEarningDate = (sorts: Sorts): CreditSpreads => {
    if (!sorts) {
      return this;
    }
    for (const sort of sorts.data) {
      if (sort.name !== 'earningDate') {
        continue;
      }
      const clone = this.creditSpreads.slice();
      let yesHasEarnings = clone.filter((e) => e.earningsDate);
      const noHasEarnings = clone.filter((e) => !e.earningsDate);
      if (sort.order.trim().toUpperCase() === SortingType.ASCENDING.toString().toUpperCase()) {
        yesHasEarnings = lodash.orderBy(
          yesHasEarnings,
          [(e) => new Date(e.earningsDate.replace('AM', '').replace('PM', ''))],
          ['asc'],
        );
        this.creditSpreads = [...yesHasEarnings, ...noHasEarnings];
      } else if (sort.order.trim().toUpperCase() === SortingType.DESCENDING.toString().toUpperCase()) {
        yesHasEarnings = lodash.orderBy(
          yesHasEarnings,
          [(e) => new Date(e.earningsDate.replace('AM', '').replace('PM', ''))],
          ['desc'],
        );
        this.creditSpreads = [...yesHasEarnings, ...noHasEarnings];
      }
    }
    return this;
  };
}
