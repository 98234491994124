import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { IconComponent } from '../common';

interface Props {
  children?: JSX.Element;
  name: string;
}

export const TsOOPS: React.FC<Props> = ({ children, name }: Props) => {
  const theme = useTheme();
  return (
    <Grid container item xs={12} alignItems={'center'} display={'grid'}>
      {children}
      <Grid container item xs={12} alignContent={'center'} py={5}>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <IconComponent name="tradeEmpty" />
        </Grid>

        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.grey[500],
            }}>
            No {name} Found
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
