export interface ISignUpResponseProps {
  googleConversionId?: string;
  googleConversionLabel?: string;
}

export class SignUpResponse implements ISignUpResponseProps {
  public googleConversionId?: string = '';
  public googleConversionLabel?: string = '';

  public static fromJson = (json: ISignUpResponseProps): SignUpResponse => {
    const model = new SignUpResponse();
    model.googleConversionId = json.googleConversionId;
    model.googleConversionLabel = json.googleConversionLabel;

    return model;
  };
}
