import { IChangeEmailProps } from '.';

export class EditChangeEmail implements IChangeEmailProps {
  oldEmailAddress = '';
  newEmailAddress = '';
  password = '';

  public static fromJson = (data: EditChangeEmail): EditChangeEmail => {
    const model = new EditChangeEmail();
    model.oldEmailAddress = data.oldEmailAddress;
    model.newEmailAddress = data.newEmailAddress;
    model.password = data.password;
    return model;
  };
}
