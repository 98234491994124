import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Grid, Theme, useTheme } from '@mui/material';
import DateTimeHelper from '@op/shared/src/models/how/date-time-helper';
import NumberFormatHelper from '@op/shared/src/models/how/number-format-helper';
import { groupBy as rowGrouper } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Column, RenderSortStatusProps, SortColumn, TreeDataGrid } from 'react-data-grid';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OrderSkeleton } from './components/order-skeleton';
import { TsSpan } from './components/ts-styled-components';
import { IHistoricalOrder } from './models/getOrderHistory';
import { getHistoricalOrders } from './services';
import {
  tsEnvironmentState,
  tsHistoricalOrdersAccountState,
  tsHistoricalOrdersState,
  tsSelectedAccountIdsState,
} from './states/trade-station-states';
import './styles/styles.css';
import { TsHistoricalOrderHeader } from './ts-historical-orders-header';
import { TsOOPS } from './ts-oops';
import { OrderRow } from './ts-orders-widget';

const renderColor = (status: string, theme: Theme) => {
  if (status === 'Filled') {
    return theme.palette.success.main;
  }
  if (status === 'Queued') {
    return '#999999';
  }
  if (status === 'Rejected') {
    return theme.palette.error.main;
  }
  return theme.palette.warning.main;
};

const getColumns = (theme: Theme, isAllGroupExpanded: boolean, headerCall: () => void) => {
  const columns = [
    {
      key: 'groupTime',
      name: '',
      sortable: false,
      editable: false,
      maxWidth: 30,
      renderHeaderCell: () => (
        <div onClick={headerCall} style={{ cursor: 'pointer' }}>
          {isAllGroupExpanded ? (
            <KeyboardDoubleArrowDownIcon style={{ verticalAlign: 'middle', color: '#0078D4' }} />
          ) : (
            <KeyboardDoubleArrowRightIcon
              style={{ verticalAlign: 'middle', color: theme.palette.mode === 'light' ? '#999999' : '#ddd' }}
            />
          )}
        </div>
      ),
      renderGroupCell({ groupKey, row }) {
        return row && row.isExpanded ? (
          <KeyboardDoubleArrowDownIcon style={{ verticalAlign: 'middle', color: '#0078D4' }} />
        ) : (
          <KeyboardDoubleArrowRightIcon
            style={{ verticalAlign: 'middle', color: theme.palette.mode === 'light' ? '#999999' : '#ddd' }}
          />
        );
      },
    },
    {
      key: 'underlyingSymbol',
      name: 'Symbol',
      sortable: false, // TO BE FIXED
      editable: false,
      minWidth: 150,
      headerCellClass: 'symbol-header-cell',
      renderGroupCell({ childRows }) {
        const symbolName = childRows[0].underlyingSymbol;
        return <TsSpan>{symbolName}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{row.symbol}</TsSpan>;
      },
    },
    {
      key: 'side',
      name: 'Side',
      editable: false,
      // renderGroupCell({ childRows }) {
      //   const side = childRows[0].side;
      //   return <TsSpan color={side === 'Buy' ? theme.palette.success.main : theme.palette.error.main}>{side}</TsSpan>;
      // },
      renderCell({ row }) {
        return (
          <TsSpan color={row.side === 'Buy' ? theme.palette.success.main : theme.palette.error.main}>{row.side}</TsSpan>
        );
      },
    },
    {
      key: 'type',
      name: 'Type',
      editable: false,
      minWidth: 100,
      renderGroupCell({ childRows }) {
        const type = childRows[0].type;
        return <TsSpan>{type}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'qty',
      name: 'Qty',
      editable: false,
      // renderGroupCell({ childRows }) {
      //   const quantity = childRows[0].qty;
      //   return <TsSpan>{quantity}</TsSpan>;
      // },
    },
    {
      key: 'filledQty',
      name: 'Filled Qty',
      editable: false,
      // renderGroupCell({ childRows }) {
      //   const filledQty = childRows[0].filledQty;
      //   return <TsSpan>{filledQty}</TsSpan>;
      // },
      renderCell({ row }) {
        return <TsSpan>{NumberFormatHelper.roundNumber(row.filledQty)}</TsSpan>;
      },
    },
    {
      key: 'limitPrice',
      name: 'Limit Price',
      editable: false,
      renderGroupCell({ childRows }) {
        const limitPrice = childRows[0].limitPrice;
        const type = childRows[0].type;
        return <TsSpan>{limitPrice ? NumberFormatHelper.roundNumber(limitPrice) : type}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{row.limitPrice ? NumberFormatHelper.roundNumber(row.limitPrice) : row.type}</TsSpan>;
      },
    },
    {
      key: 'stopPrice',
      name: 'Stop Price',
      editable: false,
      renderGroupCell({ childRows }) {
        const stopPrice = childRows[0].stopPrice;
        const type = childRows[0].type;
        return <TsSpan>{stopPrice ? NumberFormatHelper.roundNumber(stopPrice) : type}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{row.stopPrice ? NumberFormatHelper.roundNumber(row.stopPrice) : row.type}</TsSpan>;
      },
    },
    {
      key: 'avgFillPrice',
      name: 'Avg Fill Price',
      editable: false,
      renderGroupCell({ childRows }) {
        const total: number = childRows.reduce((prev: number, { avgFillPrice }: any) => prev + Number(avgFillPrice), 0);
        const avgFillPrice = total / childRows.length;
        return <TsSpan>{NumberFormatHelper.roundNumber(avgFillPrice)}</TsSpan>;
      },
      renderCell({ row }) {
        return <TsSpan>{NumberFormatHelper.roundNumber(row.avgFillPrice)}</TsSpan>;
      },
    },
    {
      key: 'status',
      name: 'Status',
      minWidth: 100,
      editable: false,
      renderGroupCell({ childRows }) {
        const status = childRows[0].status;
        return <TsSpan color={renderColor(status, theme)}>{status}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'openTime',
      name: 'Open Time',
      editable: false,
      minWidth: 160,
      renderGroupCell({ childRows }) {
        const openTime: Date = childRows[0].openTime;
        return <TsSpan>{DateTimeHelper.getLocalTime(openTime)}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'closeTime',
      name: 'Close Time',
      editable: false,
      minWidth: 160,
      renderGroupCell({ childRows }) {
        const closeTime: Date = childRows[0].closeTime;
        return <TsSpan>{DateTimeHelper.getLocalTime(closeTime)}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'routing',
      name: 'Routing',
      editable: false,
      minWidth: 120,
      renderGroupCell({ childRows }) {
        const routing = childRows[0].routing;
        return <TsSpan>{routing}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
    {
      key: 'duration',
      name: 'Duration',
      editable: false,
      renderGroupCell({ childRows }) {
        const duration = childRows[0].duration;
        return <TsSpan>{duration}</TsSpan>;
      },
      renderCell() {
        return null;
      },
    },
  ];
  return columns;
};

export const TSHistoryWidget = () => {
  const selectedOptions = ['groupTime'] as const;
  const [historicalOrders, setHistoricalOrders] = useRecoilState(tsHistoricalOrdersState);
  const historicalOrdersByAccount = useRecoilValue(tsHistoricalOrdersAccountState);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const selectedAccountIds = useRecoilValue(tsSelectedAccountIdsState);

  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set<unknown>(['groupTime']));
  const [rows, setRows] = useState<OrderRow[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const theme = useTheme();
  const [column, setColumn] = useState<Column<any, any>[]>([]);
  const [isFetchingHistoricalOrders, setIsFetchingHistoricalOrders] = useState(false);
  const [isAllGroupExpanded, setIsAllGroupExpanded] = useState(false);

  useEffect(() => {
    const headerCall = expandAllGroupCell;
    const columnsObj = getColumns(theme, isAllGroupExpanded, headerCall);
    setColumn(columnsObj);
    const groupRowOrderIds = new Set(['groupTime', ...sortedRows.map((row) => row.groupTime)]);
    const newExpandedIds = isAllGroupExpanded ? groupRowOrderIds : new Set(['groupTime']);
    setExpandedGroupIds(newExpandedIds);
  }, [theme, isAllGroupExpanded]);

  useEffect(() => {
    if (!selectedAccountIds || selectedAccountIds.length === 0 || isFetchingHistoricalOrders) {
      return;
    }

    // Set the state to indicate that historicalOrders are being fetched (async call is in progress)
    setIsFetchingHistoricalOrders(true);

    (async () => {
      const response = await getHistoricalOrders(tsEnv, selectedAccountIds, 90);
      if (!response.hasErrors) {
        setHistoricalOrders(response.data.orders);
      }
      // Reset the state after fetching historicalOrders is completed
      setIsFetchingHistoricalOrders(false);
      resetExpandedIds();
    })();
  }, [selectedAccountIds, historicalOrders]);

  useEffect(() => {
    if (!historicalOrdersByAccount) {
      return;
    }
    createOrderRows(historicalOrdersByAccount);
  }, [historicalOrdersByAccount]);

  useEffect(() => {
    if (expandedGroupIds.size === 1) {
      setIsAllGroupExpanded(false);
      return;
    }
    const groupRowOrderIds = new Set(['groupTime', ...sortedRows.map((row) => row.groupTime)]);
    if (!isAllGroupExpanded && expandedGroupIds.size !== groupRowOrderIds.size) {
      return;
    }
    setIsAllGroupExpanded(true);
  }, [expandedGroupIds]);

  const expandAllGroupCell = () => {
    setIsAllGroupExpanded(!isAllGroupExpanded);
  };

  const resetExpandedIds = () => {
    setExpandedGroupIds(new Set(['groupTime']));
    setIsAllGroupExpanded(false);
  };

  const sortedRows = useMemo((): OrderRow[] => {
    if (sortColumns.length === 0) return rows;
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        if (sort.columnKey !== 'symbol') {
          return 0;
        }
        if (sort.direction === 'ASC') {
          return a.underlyingSymbol.localeCompare(b.underlyingSymbol);
        }
        if (sort.direction === 'DESC') {
          return b.underlyingSymbol.localeCompare(a.underlyingSymbol);
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  const rowKeyGetter = (row: OrderRow) => {
    return row.id;
  };

  const createOrderRows = (historicalOrders: IHistoricalOrder[]) => {
    const rows: OrderRow[] = [];
    const newOrders = historicalOrders.flatMap(({ legs, ...order }) => legs.map((leg) => ({ leg, ...order })));
    for (let i = 0; i < newOrders.length; i++) {
      rows.push({
        id: i,
        groupTime: newOrders[i].openedDateTime,
        orderId: newOrders[i].orderID,
        underlyingSymbol:
          newOrders[i].leg?.underlying ||
          newOrders[i].leg.symbol?.substring(
            0,
            newOrders[i].leg.symbol?.indexOf(' ') === -1
              ? newOrders[i].leg.symbol.length
              : newOrders[i].leg.symbol?.indexOf(' '),
          ),
        symbol: newOrders[i].leg.symbol,
        side: newOrders[i].leg.buyOrSell,
        type: newOrders[i].orderType,
        qty: newOrders[i].leg.quantityOrdered,
        filledQty: newOrders[i].leg.execQuantity,
        limitPrice: newOrders[i].limitPrice,
        stopPrice: newOrders[i].stopPrice,
        avgFillPrice: newOrders[i].filledPrice,
        status: newOrders[i].statusDescription,
        openTime: newOrders[i].openedDateTime,
        closeTime: newOrders[i].closedDateTime,
        routing: newOrders[i].routing,
        duration: newOrders[i].duration,
      });
    }
    const sortedRows = rows.sort((r1, r2) => new Date(r2.groupTime).getTime() - new Date(r1.groupTime).getTime());
    setRows(sortedRows);
  };

  const renderSortStatus = ({ sortDirection, priority }: RenderSortStatusProps) => {
    return (
      <>
        {sortDirection !== undefined ? (
          sortDirection === 'ASC' ? (
            <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          ) : (
            <SouthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: theme.palette.primary.main }} />
          )
        ) : (
          <NorthIcon style={{ verticalAlign: 'middle', paddingBottom: '5px', color: '#BFE0FA' }} />
        )}
        <span>{priority}</span>
      </>
    );
  };

  const renderHistoricalOrderHeader = () => {
    return (
      <Grid item xs={12}>
        <TsHistoricalOrderHeader />
      </Grid>
    );
  };

  const renderOOPSImage = () => {
    return <TsOOPS name="Historical Orders" />;
  };

  if (!selectedAccountIds || selectedAccountIds.length === 0) {
    return renderOOPSImage();
  }

  if (!historicalOrders || isFetchingHistoricalOrders) {
    return (
      <Grid container item xs={12} justifyContent={'center'} alignContent={'center'}>
        <OrderSkeleton />
      </Grid>
    );
  }

  const renderHistoryDataGridTable = () => {
    if (historicalOrders.length === 0 || !historicalOrdersByAccount || historicalOrdersByAccount.length === 0) {
      return renderOOPSImage();
    }
    return (
      <TreeDataGrid
        className={theme.palette.mode === 'dark' ? 'rdg-dark' : 'rdg-light'}
        columns={column}
        rows={sortedRows}
        rowKeyGetter={rowKeyGetter}
        groupBy={selectedOptions}
        rowGrouper={rowGrouper}
        expandedGroupIds={expandedGroupIds}
        onExpandedGroupIdsChange={setExpandedGroupIds}
        defaultColumnOptions={{ resizable: false }}
        direction={'ltr'}
        // renderers={{
        //   renderSortStatus,  // TO BE FIXED
        // }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
      />
    );
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <TsHistoricalOrderHeader />
      </Grid>
      <Grid item xs={12}>
        {renderHistoryDataGridTable()}
      </Grid>
    </Grid>
  );
};
