import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { EditChangePassword } from '@op/shared/src/models';
import { ProfileDetailsService } from '@op/shared/src/services';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useState } from 'react';

import { useSetRecoilState } from 'recoil';
import { AllowAccessWidget, getErrorMsg } from '.';
import LocalizationContext from '../react-i18next/localization-context';
import { OptionsPlayDivider, OptionsPlayLebal, OptionsPlayTextField } from '../styled/options-play-components';


export const ChangePassword: React.FC = () => {
  const [fields, setFields] = useState(new EditChangePassword());
  const { t } = React.useContext(LocalizationContext);
  const setNotifications = useSetRecoilState(notificationsState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFields({ ...fields, [event.target.name]: event.target.value });
  };

  const initialState: EditChangePassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const resetForm = (): void => {
    setFields({ ...initialState });
  };

  const handleResetPassword = async (): Promise<void> => {
    if (!fields.oldPassword) {
      setNotifications([{ type: 'error', content: 'OldPassword is Required' }]);
    } else if (!fields.newPassword) {
      setNotifications([{ type: 'error', content: 'NewPassword is Required' }]);
    } else if (!fields.confirmPassword) {
      setNotifications([{ type: 'error', content: 'ConfirmPassword  is Required' }]);
    } else if (fields.newPassword !== fields.confirmPassword) {
      setNotifications([{ type: 'error', content: 'Password and ConfirmPassword are Not Matching' }]);
    } else {
      try {
        const editResponse = await ProfileDetailsService.instance.ChangePassword(fields);
        if (editResponse?.data?.isSuccess === true) {
          setNotifications([{ type: 'success', content: 'Password Updated Successfully' }]);
        } else {
          setNotifications([{ type: 'error', content: getErrorMsg(editResponse) }]);
        }
      } catch (error) {
        setNotifications([{ type: 'error', content: 'Password Reset Failed' }]);
      }
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={<Typography variant="h6">{t('profile.changePassword.titles.changeYourPassword')}</Typography>}
          sx={{ pb: 0 }}
        />
        <OptionsPlayDivider />
        <CardContent>
          <Grid container spacing={2}>
            <OptionsPlayLebal item xs={4}>
              <Typography>{t('profile.changePassword.labels.currentPassword')}</Typography>
            </OptionsPlayLebal>
            <Grid item xs={8}>
              <OptionsPlayTextField
                fullWidth
                id="oldPassword"
                name="oldPassword"
                onChange={handleChange}
                value={fields.oldPassword}
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
            <OptionsPlayLebal item xs={4}>
              <Typography>{t('profile.changePassword.labels.newPassword')}</Typography>
            </OptionsPlayLebal>
            <Grid item xs={8}>
              <OptionsPlayTextField
                fullWidth
                id="newPassword"
                name="newPassword"
                onChange={handleChange}
                value={fields.newPassword}
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
            <OptionsPlayLebal item xs={4}>
              <Typography>{t('profile.changePassword.labels.confirmNewPassword')}</Typography>
            </OptionsPlayLebal>
            <Grid item xs={8}>
              <OptionsPlayTextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                onChange={handleChange}
                value={fields.confirmPassword}
                variant="outlined"
                size="small"
                type="password"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button id="pw-update" color="primary" onClick={handleResetPassword} variant="contained">
            <Typography variant="button">{t('common.buttons.update')}</Typography>
          </Button>
          &nbsp;
          <Button onClick={resetForm} color="primary" id="pw-reset" variant="contained">
            <Typography variant="button">{t('profile.changePassword.buttons.resetForm')}</Typography>
          </Button>
        </Box>
        <AllowAccessWidget />
      </Card>
    </>
  );
};
