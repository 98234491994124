import { ConvertKeysToLowerCaseFirst } from '@op/shared/src/models';
import { LegType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { notificationsState } from '@op/shared/src/states/notification-states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TSEnvironmentTypes, tsAuthenticationStatusState, tsEnvironmentState, tsRefreshAccountTokenState } from '..';
import { IBidMidAskProps } from '../models/ts-helpers';
import { getTSToken, logOutTradeStation } from '../services';
import {
  tsOptionQuotesUpdater,
  tsOrdersUpdaterByOrderID,
  tsPositionsUpdaterByPosID,
  tsQuotesUpdater,
} from '../states/ts-hub-states';

const fetchRefreshToken = async (tsEnv: TSEnvironmentTypes, setRefreshToken: (data: boolean) => void) => {
  const response = await getTSToken(tsEnv);
  if (response.hasErrors) {
    // setTsAuthenticationStatus(false);
    return;
  }
  if (!response.data) {
    return;
  }
  try {
    const decodedToken = atob(response.data?.token);
    ApplicationContext.tradeStationToken = decodedToken;
    ApplicationContext.tradeStationBaseURL = response.data?.baseUrl;
    setRefreshToken(true);
  } catch (error) {
    console.log('error', error);
  }
};

const fetchAndSetStreamByType = async (
  type: 'positions' | 'quotes' | 'orders' | 'streamQuotes',
  streamEndPoint: string,
  controller: AbortController,
  setter: (data: any) => void,
  tsEnv: TSEnvironmentTypes,
  setRefreshToken: (data: boolean) => void,
  setNotifications?: (data: any) => void,
  setTsAuthenticationStatus?: (data: any) => void,
  isSecurity?: boolean,
) => {
  const signal = controller.signal;

  // Set the access token for authentication
  const accessToken = ApplicationContext.tradeStationToken;

  // Get baseURL from tokenAPI
  const baseURL = ApplicationContext.tradeStationBaseURL;

  if (!accessToken || !baseURL) {
    return;
  }

  //set Stream URL
  const streamUrl = `${baseURL}v3/${streamEndPoint}`;

  // Set the options for the fetch request to include the authorization header with the access token and the signal
  let fetch_options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    signal: signal, // Attach the signal to the fetch options
  };

  // Make a GET request to retrieve the newline delimited JSON stream
  try {
    const response = await fetch(streamUrl, fetch_options);
    // Create a new ReadableStream from the response body
    const reader = response.body.getReader();
    // Read the stream and parse the JSON objects
    const readStream = async () => {
      try {
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            // The stream has ended
            console.log(`${type} stream ended`);
            return;
          }
          // Convert the stream chunk to a string and split it into individual JSON objects using the newline character
          const messages = new TextDecoder().decode(value).split('\n');
          // Parse each JSON object and log it to the console
          messages.forEach((message) => {
            try {
              if (message.length > 0) {
                if (message.includes('Access token has expired.')) {
                  fetchRefreshToken(tsEnv, setRefreshToken);
                  return;
                }
                const data: any = JSON.parse(message);
                if ('Heartbeat' in data) {
                  return;
                }
                if (type === 'positions') {
                  // console.log(`${type} ->`, data);
                  setter(ConvertKeysToLowerCaseFirst(data));
                }
                if (type === 'orders') {
                  // console.log(`${type} ->`, data);
                  setter(ConvertKeysToLowerCaseFirst(data));
                }
                if ('Last' in data && type === 'quotes') {
                  // console.log(`${type} ->`, data);
                  setter(ConvertKeysToLowerCaseFirst(data));
                  return;
                }
                if (type === 'streamQuotes') {
                  streamBidAskMidValues(tsEnv, data, isSecurity, setter, setNotifications, setTsAuthenticationStatus);
                }
              }
            } catch (error) {
              console.error(`Error parsing JSON (${type}):`, error);
            }
          });
          // Read the next chunk of the stream
          await readStream();
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log(`Stream reading aborted (${type})`);
        } else {
          console.error(`Error reading stream (${type}):`, error);
        }
      }
    };
    // Start reading the stream
    await readStream();
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log(`Fetch aborted (${type}):`, error.message);
    } else {
      console.error(`Error fetching stream (${type}):`, error);
    }
  }
};

// Function to update the stream quotes data
const streamBidAskMidValues = async (
  tsEnv: TSEnvironmentTypes,
  data: any,
  isSecurity: boolean,
  setter: (data: any) => void,
  setNotifications: (data: any) => void,
  setTsAuthenticationStatus?: (data: any) => void,
) => {
  const updatedData = ConvertKeysToLowerCaseFirst(data);
  // NOTE: on dual logon error, logout from TS
  if (updatedData.error) {
    setNotifications([{ type: 'error', content: updatedData.message, isTradeStation: true }]);
    setTimeout(async () => {
      await tSLogoutOnDualLogon(tsEnv, setNotifications, setTsAuthenticationStatus);
    }, 5000);
    return;
  }
  /**
   * 1. If security symbol is part of positions data
   * 2. Response data should have both ask and bid in data object, then only calculate mid, and update the state
   */
  const { ask, bid, mid, legs } = updatedData;
  if (isSecurity && ask !== undefined && bid !== undefined) {
    const securityBidMidAskValues = calculateBidMidAskFromStreamQuotes(ask, bid);
    setter(securityBidMidAskValues);
    return;
  }
  /**
   * 1. Legs information should be there on successful connection for options stream
   * 2. It will have both data related to option symbol and security symbol if security is part of positions data
   */
  if (!legs) {
    return;
  }
  const optionsBidMidAskValues = calculateBidMidAskFromStreamQuotes(ask, bid, mid);
  setter(optionsBidMidAskValues);
};

// Calculate BIDMIDASK From streamQuotes
const calculateBidMidAskFromStreamQuotes = (ask: number, bid: number, mid?: number): IBidMidAskProps => {
  return {
    ask: Math.abs(Number(ask)).toFixed(2),
    bid: Math.abs(Number(bid)).toFixed(2),
    mid: mid !== undefined ? Math.abs(Number(mid)).toFixed(2) : Math.abs((Number(ask) + Number(bid)) / 2).toFixed(2),
  };
};

// Function to do auto logout from TS if dual logon appears
const tSLogoutOnDualLogon = async (
  tsEnv: TSEnvironmentTypes,
  setNotifications: (data: any) => void,
  setTsAuthenticationStatus: (data: any) => void,
) => {
  const logoutRes = await logOutTradeStation(tsEnv);
  if (logoutRes.hasErrors || logoutRes.data === '') {
    // setNotifications([
    //   {
    //     type: 'error',
    //     content: logoutRes.errors[0].message,
    //     isTradeStation: true,
    //   },
    // ]);
    return;
  }
  const url = logoutRes.data;
  window.localStorage.setItem('tsRedirectURL', window.location.pathname + window.location.search);
  window.localStorage.removeItem('tsEnv');
  setTsAuthenticationStatus(false);
  window.open(url, '_self');
};

export const useFetchandSetPositionStream = () => {
  const setTSPositionsByPosId = useSetRecoilState(tsPositionsUpdaterByPosID);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setRefreshAccountToken = useSetRecoilState(tsRefreshAccountTokenState);

  const fetchStream = async (controller: AbortController, selectedAccountIds: string[]) => {
    // Set the stream URL
    const streamEndPoint = `brokerage/stream/accounts/${selectedAccountIds.join(',')}/positions`;
    fetchAndSetStreamByType(
      'positions',
      streamEndPoint,
      controller,
      setTSPositionsByPosId,
      tsEnv,
      setRefreshAccountToken,
    );
  };

  return fetchStream;
};

export const useFetchandSetQuotesStream = () => {
  const setTsQuotesUpdater = useSetRecoilState(tsQuotesUpdater);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setRefreshAccountToken = useSetRecoilState(tsRefreshAccountTokenState);

  const fetchStream = async (controller: AbortController, symbols: string[]) => {
    // Set the stream URL
    const streamEndPoint = `marketdata/stream/quotes/${symbols.join(',')}`;
    fetchAndSetStreamByType('quotes', streamEndPoint, controller, setTsQuotesUpdater, tsEnv, setRefreshAccountToken);
  };

  return fetchStream;
};

export const useFetchandSetOptionQuotesStream = () => {
  const setTsQuotesUpdater = useSetRecoilState(tsOptionQuotesUpdater);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setRefreshAccountToken = useSetRecoilState(tsRefreshAccountTokenState);
  const setTsAuthenticationStatus = useSetRecoilState(tsAuthenticationStatusState);
  const setNotifications = useSetRecoilState(notificationsState);

  const fetchStream = async (controller: AbortController, symbolData: any[]) => {
    /**
     * If positions data having security symbol, then call marketdata/stream/quotes API endpoint
     * NOTE: no need to call options streaming if only security position
     */
    const securitySymbol = symbolData.find((s) => s.type === LegType.SECURITY);
    if (securitySymbol && symbolData.length === 1) {
      const streamEndPoint = `marketdata/stream/quotes/${securitySymbol.symbol}`;
      fetchAndSetStreamByType(
        'streamQuotes',
        streamEndPoint,
        controller,
        setTsQuotesUpdater,
        tsEnv,
        setRefreshAccountToken,
        setNotifications,
        setTsAuthenticationStatus,
        true,
      );
      return;
    }
    /**
     * 1. If positions data having both security and option symbols or only option symbols, then call marketdata/stream/options/quotes API endpoint
     * 2. Query Params: https://sim-api.tradestation.com/v3/marketdata/stream/options/quotes?legs[0].Symbol=AAPL%20241018C220&legs[0].Ratio=1&legs[1].Symbol=AAPL%20241018C245&legs[1].Ratio=-1
     *    i. index - [0, 1, 2, 3]
     *   ii. symbol - ['AAPL 241018C220', 'AAPL 241018C245', 'AAPL']
     *  iii. ratio - [1, -2, 100] -> 1 represents Buy 1 unit/qty, -2 represents Sell 2 unit/qty, 100 represents Buy 100 unit/qty
     */
    const legs = symbolData
      .map((value, index) => `legs[${index}].Symbol=${value.symbol}&legs[${index}].Ratio=${Number(value.ratio)}`)
      .join('&');
    const streamEndPoint = `marketdata/stream/options/quotes?${legs}`;
    fetchAndSetStreamByType(
      'streamQuotes',
      streamEndPoint,
      controller,
      setTsQuotesUpdater,
      tsEnv,
      setRefreshAccountToken,
      setNotifications,
      setTsAuthenticationStatus,
    );
  };

  return fetchStream;
};

export const useFetchandSetOrderStream = () => {
  const setTsOrdersByOrderID = useSetRecoilState(tsOrdersUpdaterByOrderID);
  const tsEnv = useRecoilValue(tsEnvironmentState);
  const setRefreshAccountToken = useSetRecoilState(tsRefreshAccountTokenState);

  const fetchStream = async (controller: AbortController, selectedAccountIds: string[]) => {
    const accountIds = selectedAccountIds.join(',');
    // Set the stream URL
    let streamEndPoint = `brokerage/stream/accounts/${accountIds}/orders`;
    fetchAndSetStreamByType('orders', streamEndPoint, controller, setTsOrdersByOrderID, tsEnv, setRefreshAccountToken);
  };

  return fetchStream;
};
