import { Box, Grid, Zoom } from '@mui/material';
import { howWhyRanksDataState, selectedSymbolState, subViewState, viewState } from '@op/shared/src/states';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { HowTabsWidget } from '../how';
import { IncomeSingleStrategyWidget } from '../income/income-single-strategy-widget';
import { TradeSingleStrategyWidget } from '../single-trade/trade-single-strategy-widget';
import { QuoteBarWidget } from '../quoteBar/quote-bar-widget';
import { EmbeddedFooterWidget } from './embedder-footer-widget';
import { TradeTicketWidget } from '../trade-ticket';
import { HeightWidget } from '../routes';
import { useSearchParams } from 'react-router-dom';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';

export const ProStrategiesWidget: React.FC = () => {
  const subView = useRecoilValue(subViewState);
  const view = useRecoilValue(viewState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const fetchAndSetData = useFetchAndSetData();
  const [searchParams] = useSearchParams();
  const howWhyRanksData = useRecoilValue(howWhyRanksDataState);

  //TODO: This is duplicate of app-container.tsx's useEffect to load howWhyRank data.
  //Consider refactoring to common place.
  useEffect(() => {
    const symbol = searchParams.get('symbol');
    /* Technical-Idea, Covered-Call, SP and CC url cantains symbols.
     * If symbol is present in url, it should load/api call for that symbol.
     * The respective widget make its own api call for how/why and ranks apis.
     */
    // if (symbol && symbol.trim() !== '') {
    //   return;
    // }
    /*
     * This useEffect is for initial load only. Other time resective widget's onItemSelected
     * function will pull how/why/ranks data.
     * However, for navigation change i.e watchilst -> Portfolio -> watchlist. making this uncessary call.
     * Hence, if these data are present then should not make this call.
     */
    if (howWhyRanksData.how !== undefined) {
      return;
    }
    /**
     * Ideally, `fetchAndSetData` should be called in HowWidget.
     * However, quotebar also needs some parts of `How` and `Why` data.
     * So, in cases where only Quotebar or HowWidget is renderd, like embedder with only HowWidget,
     * the data will not be called. Hence, this call is present in grand-parent widget i.e AppContainer.
     */
    (async () => {
      await fetchAndSetData(symbol || selectedSymbol);
    })();
  }, []);

  const renderRequiredView = () => {
    if (subView === 'tradeTicket') {
      return (
        <Zoom in={subView === 'tradeTicket'} unmountOnExit>
          <Box sx={{ pt: '7px' }}>
            <TradeTicketWidget />
          </Box>
        </Zoom>
      );
    }
    /**
     * View condition is important as once income share is loaded
     * then on click of trade tab also becuase of route match combination type is income
     * the strategy is loading the income combination on expand of trade.
     */
    if (subView === 'expand' && true && view === 'trade') {
      return <TradeSingleStrategyWidget />;
    }
    if (subView === 'expand' && view === 'income') {
      return <IncomeSingleStrategyWidget />;
    }
    return <HowTabsWidget />;
  };

  return (
    <Grid container maxWidth="xs" rowSpacing={1}>
      <Grid item xs={12}>
        <QuoteBarWidget />
      </Grid>
      <Grid item xs={12}>
        <HeightWidget height={730}>{renderRequiredView()}</HeightWidget>
      </Grid>
      <Grid item xs={12} container style={{ position: 'fixed', bottom: 0 }}>
        <EmbeddedFooterWidget />
      </Grid>
    </Grid>
  );
};
