/* eslint-disable */
// @ts-nocheck
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import { useTheme } from '@mui/material';
import { EventType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { customizationState, selectedSymbolState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IconComponent } from '../common';
import { useWindowSize } from '../states/use-window-size';
import { defaultChartAreaHeight, getChartHeight, historicalChartState } from './chart-state';
import { GuideItem } from '../side-menu';
/**
 * NOTE: Sections commented are:
 * Title, signaliq, hotkeys(keyboard shortcuts), show-ciq-range, comparison.
 * table view
 */
export const AdvancedTemplate: React.FC = () => {
  const windowSize = useWindowSize();
  const [settings, setSettings] = useRecoilState(historicalChartState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const customization = useRecoilValue(customizationState);
  const activeBorder = '1.5px solid blue';
  const theme = useTheme();

  const logActivity = (controlType: string, controlName: string, value?: any) => {
    ApplicationContext.userActivityHub?.logActivity(
      controlType,
      controlName,
      value,
      EventType.Click,
      selectedSymbol as string,
    );
  };
  const onSizeChange = (sizeName: string) => {
    const height = getChartHeight(sizeName, windowSize);
    setSettings({ ...settings, modalSize: sizeName, chartAreaHeight: height });
  };

  const onExpand = () => {
    const height = settings.isExpanded ? defaultChartAreaHeight : getChartHeight(settings.modalSize, windowSize);
    setSettings({
      ...settings,
      isExpanded: !settings.isExpanded,
      chartAreaHeight: height,
      // showSRLine: !settings.showSRLine,
    });
    logActivity('BUTTON', 'fullButtonOnChart', ' ');
  };

  const onClickShowSRLine = () => {
    const prevSR = settings.showSRLine;
    setSettings({ ...settings, showSRLine: !prevSR });
  };

  const renderSRIcon = () => {
    const color = theme.palette.mode === 'light' ? '#000000' : '#ffffff';
    return (
      <cq-clickable>
        <cq-tooltip>{`${settings.showSRLine ? 'Hide' : 'Show'} support/resistance lines`}</cq-tooltip>
        {!settings.showSRLine ? (
          <IconComponent name="srOpen" alt="S/R Open" stroke={color} fill={color} strokeWidth={0} />
        ) : (
          <IconComponent name="srClose" alt="S/R Close" stroke={color} fill={color} strokeWidth={0} />
        )}
      </cq-clickable>
    );
  };

  const appliedHeight = settings.chartAreaHeight || defaultChartAreaHeight;

  return (
    <>
      <cq-chart-instructions role="contentinfo"></cq-chart-instructions>
      <cq-color-picker></cq-color-picker>

      <div className="ciq-nav full-screen-hide" role="navigation">
        {/* <!-- enables the more button when in break-sm mode --> */}
        <div className="sidenav-toggle ciq-toggles">
          <cq-toggle
            class="ciq-sidenav"
            cq-member="sidenav"
            cq-toggles="sidenavOn,sidenavOff"
            cq-toggle-classes="active,"
            keyboard-navigation="false">
            <span></span>
            <cq-tooltip>More</cq-tooltip>
          </cq-toggle>
        </div>

        {/* <cq-clickable role="button" class="symbol-search" cq-selector="cq-lookup-dialog" cq-method="open">
          <span className="ciq-lookup-icon"></span>
          <cq-tooltip>Symbol Search</cq-tooltip>
        </cq-clickable> */}

        {/* <cq-clickable role="button" class="symbol-search" cq-selector="cq-lookup-dialog" cq-method="open" comparison="true">
          <span className="ciq-comparison-icon"></span>
          <cq-tooltip>Add Comparison</cq-tooltip>
        </cq-clickable> */}

        {/* <!-- any entry in this div will be shown in the side navigation bar in break-sm mode --> */}
        <cq-side-nav cq-on="sidenavOn">
          <div className="icon-toggles ciq-toggles">
            <cq-toggle class="ciq-draw" cq-member="drawing">
              <cq-help help-id="drawing_tools_toggle"></cq-help>
              <span></span>
              <cq-tooltip>Draw</cq-tooltip>
            </cq-toggle>

            <cq-info-toggle-dropdown>
              <cq-toggle class="ciq-CH" cq-member="crosshair">
                <span></span>
                <cq-tooltip>
                  Crosshair<span> (Alt + \)</span>
                </cq-tooltip>
              </cq-toggle>

              <cq-menu class="ciq-menu toggle-options collapse">
                <span></span>
                <cq-menu-dropdown>
                  <cq-item cq-member="crosshair">
                    Hide Heads-Up Display
                    <span className="ciq-radio">
                      <span></span>
                    </span>
                  </cq-item>
                  <cq-item cq-member="headsUp-static">
                    Show Heads-Up Display
                    <span className="ciq-radio">
                      <span></span>
                    </span>
                  </cq-item>
                </cq-menu-dropdown>
              </cq-menu>
            </cq-info-toggle-dropdown>

            <cq-info-toggle-dropdown>
              <cq-toggle class="ciq-HU" cq-member="headsUp">
                <span></span>
                <cq-tooltip>Info</cq-tooltip>
              </cq-toggle>

              <cq-menu class="ciq-menu toggle-options collapse tooltip-ui">
                <span></span>
                <cq-menu-dropdown>
                  <cq-item cq-member="headsUp-dynamic">
                    Show Dynamic Callout
                    <span className="ciq-radio">
                      <span></span>
                    </span>
                  </cq-item>
                  <cq-item cq-member="headsUp-floating">
                    Show Tooltip
                    <span className="ciq-radio">
                      <span></span>
                    </span>
                  </cq-item>
                </cq-menu-dropdown>
              </cq-menu>
            </cq-info-toggle-dropdown>

            {/* <cq-toggle class="ciq-DT tableview-ui" cq-member="tableView" role="button" aria-pressed="false">
              <span></span>
              <cq-tooltip>Table View</cq-tooltip>
            </cq-toggle> */}
          </div>
        </cq-side-nav>

        <div className="ciq-menu-section">
          <div className="ciq-dropdowns">
            <cq-menu class="ciq-menu ciq-display collapse">
              <cq-clickable cq-tooltip-activator="" stxbind="Layout.chartType" ciq-no-icon-text="Display">
                <span ciq-menu-icon=""></span>
                <cq-tooltip></cq-tooltip>
              </cq-clickable>
              <cq-menu-dropdown>
                <cq-menu-dropdown-section class="chart-types">
                  <cq-heading>Chart Types</cq-heading>
                  <cq-menu-container cq-name="menuChartStyle"></cq-menu-container>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section class="chart-aggregations advanced-ui">
                  <cq-separator></cq-separator>
                  <cq-heading>Aggregated Types</cq-heading>
                  <cq-menu-container cq-name="menuChartAggregates"></cq-menu-container>
                </cq-menu-dropdown-section>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-period">
              <span>
                <cq-clickable stxbind="Layout.periodicity">1D</cq-clickable>
              </span>
              <cq-menu-dropdown>
                <cq-menu-container cq-name="menuPeriodicity"></cq-menu-container>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu" style={{ textAlign: 'center' }}>
              <div><GuideItem selector=".monthControl_helpPinPlaceholder" /></div>
              <span>
                <cq-clickable>
                  <cq-tooltip>Range</cq-tooltip>
                  {settings.range}
                </cq-clickable>
              </span>
              <cq-menu-dropdown style={{ textAlign: 'left' }}>
                <cq-item
                  onClick={() => {
                    setSettings({ ...settings, range: '1M' });
                    logActivity('BUTTON', 'monthButtonOnChart', '1M');
                  }}
                  stxtap="Layout.setOpRange('1M')">
                  1M
                </cq-item>
                <cq-item
                  onClick={() => {
                    setSettings({ ...settings, range: '3M' });
                    logActivity('BUTTON', 'monthButtonOnChart', '3M');
                  }}
                  stxtap="Layout.setOpRange('3M')">
                  3M
                </cq-item>
                <cq-item
                  onClick={() => {
                    setSettings({ ...settings, range: '6M' });
                    logActivity('BUTTON', 'monthButtonOnChart', '6M');
                  }}
                  stxtap="Layout.setOpRange('6M')">
                  6M
                </cq-item>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu
              class="ciq-menu"
              onClick={() => onClickShowSRLine()}
              style={{ textAlign: 'center', paddingTop: 8 }}>
              <span>{renderSRIcon()}</span>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-studies collapse" cq-focus="input">
              <span>Studies</span>
              <cq-menu-dropdown>
                <cq-study-menu-manager></cq-study-menu-manager>
              </cq-menu-dropdown>
            </cq-menu>

            <cq-menu class="ciq-menu ciq-views collapse">
              <span>Views</span>
              <cq-menu-dropdown>
                <cq-views></cq-views>
              </cq-menu-dropdown>
            </cq-menu>

            {/* <cq-menu class="ciq-menu stx-markers collapse">
              <span>Events</span>
              <cq-menu-dropdown>
                <div className="signaliq-ui">
                  <cq-heading>SignalIQ</cq-heading>
                  <cq-item keyboard-selectable="false"><cq-clickable cq-selector="cq-signaliq-dialog" cq-method="open" keyboard-selectable="true"><cq-plus></cq-plus> New Signal</cq-clickable></cq-item>
                  <cq-separator></cq-separator>
                  <cq-study-legend cq-signal-studies-only="" cq-no-close="">
                    <cq-section-dynamic>
                      <cq-study-legend-content>
                        <template cq-study-legend="">
                          <cq-item>
                            <cq-label class="click-to-edit"></cq-label>
                            <div className="ciq-icon ciq-close"></div>
                          </cq-item>
                        </template>
                      </cq-study-legend-content>
                    </cq-section-dynamic>
                    <cq-separator></cq-separator>
                  </cq-study-legend>
                </div>
                <div className="markers-ui">
                  <cq-heading>Chart Events</cq-heading>
                  <cq-item stxtap="Markers.showMarkers('square')" cq-no-close="">Simple Square<span className="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxtap="Markers.showMarkers('circle')" cq-no-close="">Simple Circle<span className="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxtap="Markers.showMarkers('callout')" cq-no-close="">Callouts<span className="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item class="ta_markers-ui" stxtap="Markers.showMarkers('trade')" cq-no-close="">Trade<span className="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item class="video_markers-ui" stxtap="Markers.showMarkers('video')" cq-no-close="">Video<span className="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxtap="Markers.showMarkers('helicopter')" cq-no-close="">Abstract<span className="ciq-switch"><span></span></span>
                  </cq-item>
                </div>
                <div className="timespanevent-ui">
                  <cq-separator></cq-separator>
                  <cq-heading>Panel Events</cq-heading>
                  <cq-item class="span-event" stxtap="TimeSpanEvent.showMarkers('Order')" cq-no-close="">Order<span className="ciq-switch ciq-active"><span></span></span></cq-item>
                  <cq-item class="span-event" stxtap="TimeSpanEvent.showMarkers('CEO')" cq-no-close="">CEO<span className="ciq-switch ciq-active"><span></span></span></cq-item>
                  <cq-item class="span-event" stxtap="TimeSpanEvent.showMarkers('News')" cq-no-close="">News<span className="ciq-switch ciq-active"><span></span></span></cq-item>
                </div>
              </cq-menu-dropdown>
            </cq-menu> */}

            <cq-menu class="ciq-menu ciq-preferences collapse">
              <span></span>
              <cq-menu-dropdown>
                {customization && customization.enableCiqPreferences && (
                  <div>
                    <cq-menu-dropdown-section class="chart-preferences">
                      <cq-heading>Chart Preferences</cq-heading>
                      <cq-menu-container cq-name="menuChartPreferences"></cq-menu-container>
                      <cq-separator></cq-separator>
                    </cq-menu-dropdown-section>
                    <cq-menu-dropdown-section class="y-axis-preferences">
                      <cq-heading>Y-Axis Preferences</cq-heading>
                      <cq-menu-container cq-name="menuYAxisPreferences"></cq-menu-container>
                      <cq-separator></cq-separator>
                    </cq-menu-dropdown-section>
                    <cq-menu-dropdown-section class="chart-addons">
                      <cq-heading>Additional Features</cq-heading>
                      <cq-menu-container cq-name="menuAddOns"></cq-menu-container>
                      <cq-separator></cq-separator>
                    </cq-menu-dropdown-section>
                    <cq-menu-dropdown-section class="chart-locale">
                      <cq-heading>Locale</cq-heading>
                      <cq-item keyboard-selectable="false">
                        <cq-clickable cq-selector="cq-timezone-dialog" cq-method="open" keyboard-selectable="true">
                          Change Timezone
                        </cq-clickable>
                      </cq-item>
                      <cq-item stxsetget="Layout.Language()">
                        <cq-flag></cq-flag>
                        <cq-language-name>Change Language</cq-language-name>
                      </cq-item>
                    </cq-menu-dropdown-section>
                  </div>
                )}
                <cq-menu-dropdown-section class="chart-theme">
                  <cq-heading>Themes</cq-heading>
                  <cq-themes></cq-themes>
                  <cq-separator></cq-separator>
                </cq-menu-dropdown-section>
              </cq-menu-dropdown>
            </cq-menu>

            {settings.isExpanded && (
              <cq-menu
                class="ciq-menu"
                onClick={() => onSizeChange('sm')}
                style={{
                  display: settings.isExpanded ? 'block' : 'block',
                  borderBottom: settings.modalSize === 'sm' && activeBorder,
                }}>
                <span>Sm</span>
              </cq-menu>
            )}

            {settings.isExpanded && (
              <cq-menu
                class="ciq-menu"
                onClick={() => onSizeChange('md')}
                style={{
                  display: settings.isExpanded ? 'block' : 'block',
                  borderBottom: settings.modalSize === 'md' && activeBorder,
                }}>
                <span>Md</span>
              </cq-menu>
            )}

            {settings.isExpanded && (
              <cq-menu
                class="ciq-menu"
                onClick={() => onSizeChange('lg')}
                style={{
                  display: settings.isExpanded ? 'block' : 'block',
                  borderBottom: settings.modalSize === 'lg' && activeBorder,
                }}>
                <span>Lg</span>
              </cq-menu>
            )}

            <cq-menu class="ciq-menu" style={{ textAlign: 'center', paddingTop: 5 }} onClick={() => onExpand()}>
              <span style={{ fontWeight: 'bold' }}>
                {settings.isExpanded ? (
                  <FullscreenExitSharpIcon fontSize="medium" />
                ) : (
                  <FullscreenSharpIcon fontSize="medium" />
                )}
              </span>
            </cq-menu>
          </div>
          <div className="trade-toggles ciq-toggles">
            <cq-toggle class="tfc-ui sidebar stx-trade" cq-member="tfc">
              <span></span>
              <cq-tooltip>Trade</cq-tooltip>
            </cq-toggle>
            <cq-toggle class="analystviews-ui stx-analystviews" cq-member="analystviews">
              <span></span>
              <cq-tooltip>Analyst Views</cq-tooltip>
            </cq-toggle>
            <cq-toggle class="technicalinsights-ui stx-technicalinsights" cq-member="technicalinsights">
              <span></span>
              <cq-tooltip>Technical Insights</cq-tooltip>
            </cq-toggle>
          </div>
        </div>
      </div>
      {/* <!-- End Navbar --> */}

      {/* <!-- custom tag for scriptiq only works when plugin is loaded --> */}
      <cq-scriptiq class="scriptiq-ui"></cq-scriptiq>

      {/* <!-- custom tag for analystviews only works when plugin is loaded --> */}
      <cq-analystviews
        class="analystviews-ui"
        token="eZOrIVNU3KR1f0cf6PTUYg=="
        partner="1000"
        disabled=""></cq-analystviews>

      {/* <!-- custom tag for technicalinsights only works when plugin is loaded --> */}
      <cq-technicalinsights uid="" lang="en" disabled=""></cq-technicalinsights>

      <div className="ciq-chart-area" role="main" style={{ height: appliedHeight }}>
        <div className="ciq-chart">
          <cq-message-toaster
            defaultdisplaytime="10"
            defaulttransition="slide"
            defaultposition="top"></cq-message-toaster>

          <cq-palette-dock>
            <div className="palette-dock-container">
              <cq-drawing-palette
                class="palette-drawing grid palette-hide"
                docked="true"
                orientation="vertical"
                min-height="300"
                cq-drawing-edit="none"></cq-drawing-palette>
              <cq-drawing-settings
                class="palette-settings"
                docked="true"
                hide="true"
                orientation="horizontal"
                min-height="40"
                cq-drawing-edit="none"></cq-drawing-settings>
            </div>
          </cq-palette-dock>

          <div className="chartContainer">
            {/* <!-- tooltip markup is required only if addon tooltip is used and customization is required --> */}
            <table className="hu-tooltip">
              <caption>Tooltip</caption>
              <tbody>
                <tr hu-tooltip-field="" className="hu-tooltip-sr-only">
                  <th>Field</th>
                  <th>Value</th>
                </tr>
                <tr hu-tooltip-field="DT">
                  <td className="hu-tooltip-name">Date/Time</td>
                  <td className="hu-tooltip-value"></td>
                </tr>
                <tr hu-tooltip-field="Close">
                  <td className="hu-tooltip-name"></td>
                  <td className="hu-tooltip-value"></td>
                </tr>
              </tbody>
            </table>

            {/* <cq-chart-title cq-marker="" cq-browser-tab="" cq-activate-symbol-search-on-click=""></cq-chart-title> */}

            <cq-chartcontrol-group class="full-screen-show" cq-marker=""></cq-chartcontrol-group>

            <cq-chart-legend></cq-chart-legend>

            <cq-loader></cq-loader>
          </div>
          {/* <!-- End Chart Container --> */}
        </div>
        {/* <!-- End Chart Box --> */}
      </div>
      {/* <!-- End Chart Area --> */}

      {/* <!-- Markers/Events --> */}
      <cq-abstract-marker cq-type="helicopter"></cq-abstract-marker>
      {/* <!-- End Marker/Events --> */}

      {/* <!-- Attribution component --> */}
      <cq-attribution></cq-attribution>

      {/* <div className="ciq-footer full-screen-hide">
        <cq-share-button></cq-share-button>
        <div className="shortcuts-ui ciq-shortcut-button" stxtap="Layout.showShortcuts()" title="Toggle Shortcut Legend"></div>
        <div className="help-ui ciq-help-button" stxtap="Layout.toggleHelp()" title="Toggle Interactive Help"></div>
        <cq-show-range></cq-show-range> 
        https://documentation.chartiq.com/WebComponents.cq-show-range.html#main
      </div> */}

      {/* <!-- End Footer --> */}

      <cq-dialogs>
        <cq-dialog>
          <cq-drawing-context></cq-drawing-context>
        </cq-dialog>

        <cq-dialog>
          <cq-study-context></cq-study-context>
        </cq-dialog>
      </cq-dialogs>

      <cq-side-panel></cq-side-panel>
    </>
  );
};

{
  /* 
/* Uncomment the following import statement to enable the Option Sentiment by Strike study. */
  //import "./js/extras/options/sentimentByStrike.js";
  /*
   * Uncomment the following import statement to access the option chain simulator for option-based
   * functionality, such as the Option Sentiment By Strike study.
   *
   * Make the option chain simulator the chart data source by setting the quoteFeed property (in
   * the object parameter of the getDefaultConfig function call below) to the optionfeed variable,
   * for example:
   *
   *     const config = getDefaultConfig({
   *         markerFeed: marker.MarkersSample,
   *         scrollStyle: PerfectScrollbar,
   *         quoteFeed: optionfeed,  // Provides simulated quote data and option data.
   *         forecastQuoteFeed: forecastfeed,
   *         nameValueStore: CIQ.NameValueStore
   *     });
   */
}

{
  /* //import optionfeed from "./examples/feeds/optionChainSimulator.js"; */
}

{
  /* // Create and customize default configuration */
}

{
  /* const config = getDefaultConfig({
  markerFeed: marker.MarkersSample,
  scrollStyle: PerfectScrollbar,
  quoteFeed: quotefeed,
  forecastQuoteFeed: forecastfeed,
  nameValueStore: CIQ.NameValueStore
});

// Create the chart...
    let stxx = config.createChart();

    // Simulate L2 data
    // In your implementation, you must instead load L2 data
    // using https://documentation.chartiq.com/CIQ.ChartEngine.html#updateCurrentMarketData
    //CIQ.simulateL2({ stx: stxx, onInterval: 1300, onTrade: true });

    //...then add whatever code you wish!</script> */
}
